import React, {useCallback, useMemo} from 'react';
import styled from "styled-components";
import {ArrowLeftIcon, DotsIcon} from "../../UI/Svg";
import {Link, useNavigate} from "react-router-dom";
import {IChat, IChatDetail} from "../../store/chat/types";
import {UserEntity} from "../../types";
import {useAuthState} from "../../store/auth/hooks";
import {mediaUrl} from "../../api";
import imgEmptyAvatar from "../../assets/img/no-avatar.png";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {getTitleChat} from "./Contacts";
import {T, useTranslate} from "@tolgee/react";
import {useChatAction, useChatState} from "../../store/chat/hooks";
import SelectedHeaderComponent from "../../components/ChatComponents/SelectedHeaderComponent";
import {getUserShortName} from "../../utils/user";
import _ from 'lodash';
import Dots from "../../UI/Dots";


const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 16px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom: 0.5px solid ${({theme}) => theme.colors.mainGray};
  background: ${({theme}) => theme.colors.white};
  z-index: 9;

  ${({theme}) => theme.mediaQueries.md} {
    position: fixed;
    top: 0;
  }
`


const ButtonIcon = styled.div`
  cursor: pointer;

  svg {
    min-width: 24px;
    height: 24px;
  }

  ${({theme}) => theme.mediaQueries.md} {
  }
`

const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 14px;
`
const DotsButton = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  cursor: pointer;
`


export const getPictureChat = (chat: IChat, user: UserEntity | null) => {
  if (chat.avatar) return chat.avatar.id
  if (chat.event && chat.event.photo?.id) return chat.event.photo?.id
  if (!user) return null
  if (chat.members.length === 0) return null
  const otherUser = chat.members.filter((member) => member.id !== user.id)[0]
  return otherUser?.avatar?.id
}

export const getLinkChat = (chat: IChat, user: UserEntity | null) => {
  if (chat.event) return `/about-the-event/${chat.event.id}`
  if (!user) return null
  if (chat.members.length === 0) return null
  if (chat.members.length > 1 && chat.members[0].id !== user.id) {
    return `/profile/${chat.members[0]?.id}`
  } else if (chat.members[0].id === user.id) return null
  const otherUser = chat.members.filter((member) => member.id !== user.id)[0]
  return `/profile/${otherUser?.id}`
}

const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  min-width: 36px;
  height: 36px;
  border-radius: 50%;
  overflow: hidden;
  background: #9b9b9b;

  img {
    width: auto;
    max-width: unset;
    height: 100%;
  }

  ${({theme}) => theme.mediaQueries.md} {
    display: none;
  }
`

const AvatarMobile = styled(Avatar)`
  display: none;
  align-items: center;
  justify-content: center;
  min-width: 36px;
  height: 36px;
  overflow: hidden;
  background: #9b9b9b;

  img {
    width: auto;
    max-width: unset;
    height: 100%;
  }

  ${({theme}) => theme.mediaQueries.md} {
    display: flex;
  }
`

const Center = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  overflow: hidden;

  ${({theme}) => theme.mediaQueries.md} {
    align-items: flex-start;
  }
`

const WrapCenter = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  overflow: hidden;

  ${({theme}) => theme.mediaQueries.md} {
    flex: 1;
  }
`

const Title = styled(Text)`
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  display: inline;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  ${({theme}) => theme.mediaQueries.md} {
    text-align: left;
  }
`
const Status = styled(Text)`
  text-align: center;
  display: inline;
`

function HeaderDialogView({chat, onShowModal}: { onShowModal: () => void, chat: IChat }) {
  const navigate = useNavigate()
  const {t} = useTranslate()
  const {user} = useAuthState()

  const pictureUrl = useMemo(() => getPictureChat(chat, user), [chat, user])

  const {usersOnline, checkedMessages, currentUsersTyping} = useChatState()
  const {onDeleteChat} = useChatAction()


  const onDelete = useCallback(() => {
    if (window.confirm(t('chat.header.deleteConfirm','Вы действительно хотите удалить чат?'))) {
      onDeleteChat(chat.id)
      navigate('/chat')
    }
  }, [chat.id, navigate, onDeleteChat, t])

  const otherMember = useMemo(() => chat.members.filter((item) => item.id !== user?.id)[0] || null, [chat, user])

  const isUserOnline = useMemo(() => {
    if (otherMember) {
      return _.includes(usersOnline.map((item) => item.id), otherMember.id)
    }
    return false
  }, [otherMember, usersOnline])

  const userTyping = useMemo(() => {
    if (chat.id in currentUsersTyping) {
      const members = chat.members.map((item) => item.id)
      const users = currentUsersTyping[chat.id].filter((item) => item.id !== user?.id && _.includes(members, item.id))
      if (users.length > 0) {
        if (users.length === 1) {
          if (members.length > 2) {
            return t('chat.header.typingOne',`${getUserShortName(users[0])} печатает...`, {name:getUserShortName(users[0])})
          } else {
            return t('chat.header.typingSingle', 'Печатает...')
          }
        } else {
          return t('chat.header.typing',
            `${users.slice(0, 3).map((user) => getUserShortName(user)).join(', ')} печатают...`,
            {users: users.slice(0, 3).map((user) => getUserShortName(user)).join(', ')})
        }
      }
    }
    return ''
  }, [chat.id, chat.members, currentUsersTyping, t, user?.id])

  return (
    <Header>
      {
        checkedMessages.length > 0
          ? <SelectedHeaderComponent onShowModal={onShowModal}/>
          : <>
            <ButtonIcon onClick={() => navigate('/chat')}>
              <ArrowLeftIcon/>
            </ButtonIcon>
            <WrapCenter>
              {
                chat.type === 'private' && otherMember
                  ? <AvatarMobile as={Link} to={`/profile/${otherMember.id}`}>
                    <img src={pictureUrl ? `${mediaUrl}file/${pictureUrl}` : imgEmptyAvatar} alt=""/>
                  </AvatarMobile>
                  : <AvatarMobile>
                    <img src={pictureUrl ? `${mediaUrl}file/${pictureUrl}` : imgEmptyAvatar} alt=""/>
                  </AvatarMobile>
              }
              <Center>
                <Title variant={ETextVariants.Body16}>{getTitleChat(chat, user)}</Title>
                <Status
                  variant={ETextVariants.Body14}>
                  {
                    userTyping
                    ? userTyping
                    : (chat.type === 'event' || chat.type === 'group')
                        ? <> {chat.members.length} {chat.members.length === 1
                          ? <T keyName="chat.header.participant">участник</T>
                          : <T keyName="chat.header.participants">участников</T>}</>
                        : isUserOnline ? 'online' : 'offline'}</Status>
              </Center>
            </WrapCenter>
            <Right>
              {chat.type !== 'event' && <Dots
                items={[{title: t("chat.header.delete", "Удалить чат"), action: onDelete}]}
              />}
              {
                chat.type === 'private' && otherMember
                ? <Avatar as={Link} to={`/profile/${otherMember.id}`}>
                <img src={pictureUrl ? `${mediaUrl}file/${pictureUrl}` : imgEmptyAvatar} alt=""/>
              </Avatar>
              : <Avatar>
                  <img src={pictureUrl ? `${mediaUrl}file/${pictureUrl}` : imgEmptyAvatar} alt=""/>
                </Avatar>
              }
            </Right>
          </>
      }
    </Header>
  );
}

export default HeaderDialogView;
