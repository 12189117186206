import React from 'react';
import {useAuthState} from "../../store/auth/hooks";
import {useParams} from "react-router-dom";
import styled from "styled-components";
import {SpinnerIcon} from "../../UI/Svg";
import ContentLoader from "react-content-loader";
import {Container} from "../../components/Templates/Container";
import {Card} from "../../components/Templates/Card";
import {useCompanyState} from "../../store/company/hooks";

const LoadingIcon = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  width: 100%;

  svg {
    display: none;
    width: 44px;
    height: 44px;

    ${({theme}) => theme.mediaQueries.md} {
      display: flex;
    }
  }
`


const ContainerHeader = styled(Container)`
`

const MainContainer = styled(Container)`
  margin-top: 20px;
  gap: 30px;
`

const LoadingBlockStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({theme}) => theme.mediaQueries.md} {
    display: none;
  }
`


const Aside = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 343px;
  flex: 1;

  ${({theme}) => theme.mediaQueries.md} {
    display: none;
  }
`

const Main = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;
  width: 100%;
`


function LoadingCompany() {
  const {selectedUserCompany} = useCompanyState();
  const {id} = useParams();
  return (
    <>
      <ContainerHeader>
        <LoadingBlockStyled>
          <ContentLoader
            speed={2}
            width="100%"
            height={248}
            backgroundColor="#FFF"
            foregroundColor="#ecebeb"
            style={{borderRadius: '1rem'}}
          >
            <rect x="0" y="0" rx="0" ry="0" width="1300" height="427"/>
          </ContentLoader>
        </LoadingBlockStyled>
      </ContainerHeader>
      <MainContainer>
        <Main>
          <LoadingBlockStyled>
            <ContentLoader
              speed={2}
              width="100%"
              height={136}
              backgroundColor="#FFF"
              foregroundColor="#ecebeb"
              style={{borderRadius: '1rem',}}
            >
              <rect x="0" y="0" rx="0" ry="0" width="939" height="427"/>
            </ContentLoader>
          </LoadingBlockStyled>
          <LoadingBlockStyled>
              <ContentLoader
                  speed={2}
                  width="100%"
                  height={284}
                  backgroundColor="#FFF"
                  foregroundColor="#ecebeb"
                  style={{borderRadius: '1rem'}}
              >
                  <rect x="0" y="0" rx="0" ry="0" width="939" height="427"/>
              </ContentLoader>
          </LoadingBlockStyled>
          {(!id || String(selectedUserCompany?.id) === id) && <LoadingBlockStyled>
              <ContentLoader
                  speed={2}
                  width="100%"
                  height={77}
                  backgroundColor="#FFF"
                  foregroundColor="#ecebeb"
                  style={{borderRadius: '1rem'}}
              >
                  <rect x="0" y="0" rx="0" ry="0" width="939" height="427"/>
              </ContentLoader>
          </LoadingBlockStyled>}
          <LoadingBlockStyled>
              <ContentLoader
                  speed={2}
                  width="100%"
                  height={77}
                  backgroundColor="#FFF"
                  foregroundColor="#ecebeb"
                  style={{borderRadius: '1rem'}}
              >
                  <rect x="0" y="0" rx="0" ry="0" width="939" height="427"/>
              </ContentLoader>
          </LoadingBlockStyled>
          <LoadingBlockStyled>
            <ContentLoader
              speed={2}
              width="100%"
              height={638}
              backgroundColor="#FFF"
              foregroundColor="#ecebeb"
              style={{borderRadius: '1rem', marginBottom: '2rem'}}
            >
              <rect x="0" y="0" rx="0" ry="0" width="939" height="833"/>
            </ContentLoader>
          </LoadingBlockStyled>
        </Main>

        <Aside>
          <LoadingBlockStyled>
            <ContentLoader
              speed={2}
              width="100%"
              height={456}
              backgroundColor="#FFF"
              foregroundColor="#ecebeb"
              style={{borderRadius: '1rem'}}
            >
              <rect x="0" y="0" rx="0" ry="0" width="547" height="520"/>
            </ContentLoader>
          </LoadingBlockStyled>
        </Aside>
      </MainContainer>
      <LoadingIcon>
        <SpinnerIcon/>
      </LoadingIcon>
    </>
  );
}

export default LoadingCompany;
