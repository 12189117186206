import React, {ReactNode, useEffect, useMemo, useRef, useState} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {IChatMessage} from "../../store/chat/types";
import {mediaUrl} from "../../api";
import imgEmptyAvatar from "../../assets/img/no-avatar.png";
import {getUserName} from "../../utils/user";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import dayjs from "dayjs";
import {useAuthState} from "../../store/auth/hooks";
import pngChecked from "../../assets/img/checked.svg";
import {useChatAction, useChatState} from "../../store/chat/hooks";
import _ from "lodash";
import MessageForward from "./MessageForward";
import {FileApi} from "../../api/file";
import Waveform from "./Waveform";

const Wrapper = styled.div<{ $isSender: boolean }>`
  display: flex;
  width: 60%;
  gap: 10px;
  align-items: flex-start;
  cursor: pointer;
  justify-content: ${({$isSender}) => $isSender ? 'flex-end' : 'flex-start'};
  ${({theme}) => theme.mediaQueries.md} {
    width: 90%;
  }

`
const UserAvatar = styled.div`
  width: 60px;
  height: 60px;
  min-width: 60px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #9b9b9b;

  img {
    width: auto;
    height: 100%;
  }

  ${({theme}) => theme.mediaQueries.md} {
    width: 32px;
    min-width: 32px;
    height: 32px;
  }
`

const Content = styled.div<{ $isSender: boolean }>`
  display: flex;
  flex-direction: column;
  // align-items: ${({$isSender}) => $isSender ? 'flex-end' : 'flex-start'};
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  gap: 6px;

  ${({theme}) => theme.mediaQueries.md} {
    background: ${({theme, $isSender}) => $isSender ? theme.colors.lightPurple2 : theme.colors.lightGray7};
    border-radius: 18px;
    padding: 8px 16px 8px 16px;
  }
`

const MessageText = styled.p`
  width: 100%;
  text-align: left;
  font-size: 16px;
  padding-right: 30px;
  color: ${({theme}) => theme.colors.black};
  word-break: break-all;
  ${({theme}) => theme.mediaQueries.md} {
    color: ${({theme}) => theme.colors.black};
  }
`

const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 14px;
  cursor: pointer;

  ${({theme}) => theme.mediaQueries.md} {
    min-width: 134px;
  }
`

const Title = styled(Text)`
  font-weight: 500;
  color: ${({theme}) => theme.colors.mainPurple};

  ${({theme}) => theme.mediaQueries.md} {
    display: none;
  }
`
const DateText = styled(Text)`
  color: ${({theme}) => theme.colors.lightGray5};

  ${({theme}) => theme.mediaQueries.md} {
    position: absolute;
    right: 14px;
    bottom: 5px;
    font-size: 12px;
  }
`

const LinkStyled = styled(Link)`
  ${({theme}) => theme.mediaQueries.md} {
    display: none;
  }
`

const FilesBlock = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: flex-start;

  img {
    width: 75%;
  }
`


export const CheckboxItem = styled.div`
  width: 24px;
  height: 24px;
  min-width: 24px;
  border-radius: 50%;
  user-select: none;
  margin-top: 10px;
  border: 1px solid ${({theme}) => theme.colors.lightGray};
  cursor: pointer;
  ${({theme}) => theme.mediaQueries.md} {
    margin-top: 0;
  }
`

const WrapperChecked = styled.div<{ $isSender: boolean, $isChecked: boolean, $isChecking: boolean }>`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
  justify-content: ${({$isSender, $isChecking}) => $isSender ? $isChecking ?  'space-between' : 'flex-end' : 'flex-start'};
  flex-direction: row;
  background: ${({theme, $isChecked}) => $isChecked ? theme.colors.lightWhite : 'transparent'};
  padding: 12px 16px;
`

const MobileWrapper = styled.div`
  display: none;

  ${({theme}) => theme.mediaQueries.md} {
    display: block;
  }
`
const DesctopWrapper = styled.div`
  display: block;

  ${({theme}) => theme.mediaQueries.md} {
    display: none;
  }
`

function MessageBase({children, message}: { children?: ReactNode, message: IChatMessage }) {
  const {sender, date, id} = message;
  const {user} = useAuthState()
  const {checkedMessages} = useChatState()
  const messageRef = useRef<HTMLDivElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const {onCheckMessage} = useChatAction()
  const isChecked = useMemo(() => _.includes(checkedMessages, id), [id, checkedMessages])
  const isSender = useMemo(() => user?.id === sender.id, [sender.id, user?.id])
  const [voiceUrl, setVoiceUrl] = useState<string | null>(null);

  useEffect(() => {
    if (message.files.length > 0) {
      const file = message.files[0]
      if (file.mimetype.split('/')[0] === 'audio') {
        FileApi.getFileById(file.id).then(({data}) => {
          const blob = new Blob([data], { type: 'audio/mp3' });
          const url = URL.createObjectURL(blob);
          setVoiceUrl(url)
        })
      }
    }
  }, [message.files])

  return (
    <WrapperChecked  $isSender={isSender} $isChecked={isChecked} $isChecking={checkedMessages.length > 0} ref={messageRef} onClick={(event) => {
      if (event.target === messageRef.current || event.target === wrapperRef.current) {
        onCheckMessage(id)
      }
    }}>
      {checkedMessages.length > 0 && <CheckboxItem onClick={() => onCheckMessage(id)}>
        {isChecked && <img src={pngChecked} alt="Checked"/>}
      </CheckboxItem>}
      <Wrapper $isSender={isSender} ref={wrapperRef}>
        {!isSender && <Link to={`/profile/${sender.id}`}>
            <UserAvatar>
                <img
                    src={sender.avatar ? `${mediaUrl}file/${sender.avatar.id}` : imgEmptyAvatar}
                    alt=""/>
            </UserAvatar>
        </Link>}
        <Content $isSender={isSender}>
          {
            message.forward && <MobileWrapper><MessageForward message={message.forward}/></MobileWrapper>
          }
          <ContentHeader >
            <LinkStyled to={`/profile/${sender.id}`}>
              <Title variant={ETextVariants.Body16}>{getUserName(sender)}</Title>
            </LinkStyled>
            {!voiceUrl && <DateText variant={ETextVariants.Body16}>
              {dayjs(date).format('HH:mm')}
            </DateText>}
          </ContentHeader>
          {
            message.forward && <DesctopWrapper><MessageForward message={message.forward}/></DesctopWrapper>
          }
          <MessageText>
            {children}
          </MessageText>
          {message.files.length > 0 && <FilesBlock>
            {message.files.map((file) => {
              const typeFile = file.mimetype.split('/')[0]

              if (typeFile === 'image') {
                return (
                  <img key={file.id} src={`${mediaUrl}file/${file.id}`} alt=""/>
                )
              } else if (typeFile === 'audio' && voiceUrl) {
                return (
                  <Waveform key={file.id} audio={voiceUrl} uid={id} />
                )
              }
              return null
            })}

          </FilesBlock>}
        </Content>
      </Wrapper>
    </WrapperChecked>
  );
}

export default MessageBase;
