import Stripe from "stripe";
import {useCallback, useMemo} from "react";
import { STRIPE_SK_KEY } from "../config/stripe";

const stripe = new Stripe(STRIPE_SK_KEY)

export function useUpdatePaymentIntent() {
  const updatePaymentIntent = useCallback((paymentIntentId: string, updateData: Stripe.PaymentIntentUpdateParams) => {
    stripe.paymentIntents.update(paymentIntentId, updateData)
  }, [])

  return useMemo(() => ({
    updatePaymentIntent,
  }), [
    updatePaymentIntent,
  ])

}
