import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="20" height="20" viewBox="0 0 20 20"  fill="none" {...props} >
          <path d="M19.7951 7.30354C19.4418 6.41744 18.6741 5.75056 17.4504 5.26404C17.3514 5.22427 17.246 5.19005 17.1378 5.15952C16.0454 4.85522 14.6599 5.03928 13.6591 5.83935C13.216 4.63693 12.1264 3.76379 11.035 3.45856C10.9259 3.42803 10.8177 3.40398 10.7131 3.38641C9.41359 3.16905 8.41188 3.34479 7.65158 3.91918C6.8959 4.48894 6.44268 5.42961 6.30024 6.71527C5.95709 9.83787 10.1508 16.1728 10.3293 16.4401C10.4107 16.5622 10.53 16.6454 10.6632 16.6824C10.7964 16.7204 10.9425 16.7102 11.0757 16.6464C11.3652 16.5095 18.1839 13.2444 19.5038 10.3947C20.0467 9.22094 20.1448 8.18131 19.7951 7.30354ZM18.5862 10.6379C18.5862 10.6379 19.2661 7.37291 16.5356 6.15291C16.5356 6.15198 20.093 6.60521 18.5862 10.6379ZM5.62781 7.23787C5.63243 7.20272 5.63798 7.16942 5.64168 7.13427C4.96833 7.37476 4.32179 7.94729 4.07021 8.7076C3.4024 8.20813 2.49781 8.11748 1.79485 8.33947C1.72548 8.36074 1.65704 8.38664 1.59414 8.41347C0.807018 8.75477 0.322349 9.20429 0.111462 9.78793C-0.0975746 10.366 -0.0124801 11.0403 0.365821 11.7923C1.28521 13.6181 5.81742 15.5901 6.00981 15.6733C6.09768 15.7103 6.19295 15.714 6.27804 15.6872C6.36406 15.6595 6.43991 15.603 6.49078 15.5207C6.53888 15.443 7.06147 14.5856 7.60718 13.4794C6.5213 11.4112 5.44745 8.88056 5.62781 7.23787ZM1.13815 11.7414C-0.00600551 10.5973 1.13815 9.3791 1.13815 9.3791C0.341772 10.7721 1.13815 11.7414 1.13815 11.7414Z" fill="#6C27AB"/>
        </Svg>
    );

export default Icon;
