import { UserEntity } from "../types";
import {IAuthor} from "../store/events/types";

export function getUserName({ firstName, lastName, login }: UserEntity | IAuthor): string {
  return firstName && lastName ? `${firstName} ${lastName}` : login;
}

export function getUserShortName({ firstName, lastName, login }: UserEntity | IAuthor): string {
  return firstName && lastName ? `${firstName} ${lastName[0]}` : login;
}
