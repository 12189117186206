import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} >
          <path d="M9.36709 3.99967C10.0182 4.12672 10.6167 4.44518 11.0858 4.9143C11.5549 5.38342 11.8734 5.98185 12.0004 6.63301M9.36709 1.33301C10.7199 1.4833 11.9815 2.08912 12.9446 3.05101C13.9077 4.0129 14.5151 5.27368 14.6671 6.62634M6.81841 9.24172C6.01736 8.44067 5.38483 7.53491 4.92084 6.56849C4.88092 6.48537 4.86097 6.4438 4.84564 6.39121C4.79115 6.20431 4.83029 5.9748 4.94363 5.81652C4.97552 5.77197 5.01363 5.73387 5.08984 5.65766C5.32291 5.42459 5.43944 5.30806 5.51563 5.19087C5.80296 4.74894 5.80296 4.17922 5.51563 3.7373C5.43944 3.62011 5.32291 3.50358 5.08984 3.2705L4.95992 3.14059C4.60563 2.7863 4.42848 2.60915 4.23823 2.51292C3.85985 2.32154 3.41301 2.32154 3.03464 2.51292C2.84438 2.60915 2.66723 2.7863 2.31294 3.14059L2.20785 3.24568C1.85477 3.59876 1.67823 3.7753 1.54339 4.01533C1.39378 4.28166 1.28621 4.69532 1.28712 5.00081C1.28793 5.2761 1.34134 5.46425 1.44814 5.84055C2.02212 7.86281 3.1051 9.77105 4.69709 11.363C6.28908 12.955 8.19732 14.038 10.2196 14.612C10.5959 14.7188 10.784 14.7722 11.0593 14.773C11.3648 14.7739 11.7785 14.6664 12.0448 14.5167C12.2848 14.3819 12.4614 14.2054 12.8145 13.8523L12.9195 13.7472C13.2738 13.3929 13.451 13.2158 13.5472 13.0255C13.7386 12.6471 13.7386 12.2003 13.5472 11.8219C13.451 11.6317 13.2738 11.4545 12.9195 11.1002L12.7896 10.9703C12.5566 10.7372 12.44 10.6207 12.3228 10.5445C11.8809 10.2572 11.3112 10.2572 10.8693 10.5445C10.7521 10.6207 10.6355 10.7372 10.4025 10.9703C10.3263 11.0465 10.2882 11.0846 10.2436 11.1165C10.0853 11.2298 9.85582 11.269 9.66892 11.2145C9.61633 11.1992 9.57476 11.1792 9.49164 11.1393C8.52523 10.6753 7.61946 10.0428 6.81841 9.24172Z" stroke="#9B9B9B" strokeLinecap="round" strokeLinejoin="round"/>
        </Svg>
    );

export default Icon;
