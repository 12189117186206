import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {CompanyApi} from "../../api/company";

const fetchApproveCompany = createAsyncThunk<void,
  number,
  {
    state: RootState
  }>(
  'adminCompanies/fetchApproveCompany',
  async (id, ThunkApi) => {
    const authState = ThunkApi.getState().auth
    if (authState.auth) {
      const [companyRes, companyErr] = await handle(CompanyApi.putApproveCompany(id))
      if (companyRes) {
        return companyRes
      }
      if (companyErr) {
        console.log('companyErr',companyRes, companyErr)
        throw {message: companyErr.message[0] || companyErr.message}
      }
    }
    throw {message: 'No phone number'}
  }
)

export default fetchApproveCompany
