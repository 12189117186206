import React, {useCallback, useMemo, useRef} from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import styled from "styled-components";
import {Card} from "../Templates/Card";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {EventResponse} from "../../store/events/types";
import {CalendarIcon, GeoGrayIcon, SmileIcon} from "../../UI/Svg";

import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import {T} from "@tolgee/react";
import {mediaUrl} from "../../api";
import _ from "lodash";
import {useAppState} from "../../store/app/hooks";
import {getTextStyle} from "../../UI/Text/utils";
import {useEventsAction} from "../../store/events/hooks";

const Wrapper = styled(Card)`
  width: 100%;
  display: flex;
  cursor: pointer;
  align-items: stretch;
  gap: 20px;
  overflow: unset;
  ${({theme}) => theme.mediaQueries.md} {
  }
`

const EventImage = styled.div`
  display: flex;
  align-items: center;
  background: ${({theme}) => theme.colors.bg};
  justify-content: center;
  width: 35%;
  border-radius: 18px;
  overflow: hidden;
  height: 266px;
  max-width: 323px;

  img {
    width: auto;
    height: 100%;
  }
  ${({theme}) => theme.mediaQueries.md} {
    height: 113px;
    min-width: 97px;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 65%;
  flex: 1;
  ${({theme}) => theme.mediaQueries.md} {
    gap: 7px;
  }
`

const Categories = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  ${({theme}) => theme.mediaQueries.md} {
    display: none;
  }
`

const Category = styled(Text)`
  padding: 5px 10px;
  background: ${({theme}) => theme.colors.lightGray2};
  border-radius: 5px;
`

const Description = styled(Text)`
  max-height: 60px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  width: 65%;
  ${({theme}) => theme.mediaQueries.md} {
    display: none;
  }
`

const List = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
`

const Item = styled.li`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  overflow: hidden;
  width: 100%;
  svg {
    min-width: 24px;
    height: 24px;
  }
  ${({theme}) => theme.mediaQueries.md} {
    svg {
      min-width: 20px;
      height: 20px;
    }
  }
`

const TextStyled = styled(Text) `
  text-overflow: ellipsis;
  overflow: hidden;
  ${({theme}) => theme.mediaQueries.md} {
    line-height: 1;
    font-size: 12px;
  }
`

const LinkStyled = styled.div`
  ${({theme}) => getTextStyle(theme, ETextVariants.Body16)}
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  color: ${({theme}) => theme.colors.mainPurple};
  width: 100%;
  white-space: nowrap;
  ${({theme}) => theme.mediaQueries.md} {
    line-height: 1;
    font-size: 12px;
  }
`

const Members = styled(TextStyled)`
  white-space: pre;
  color: ${({theme}) => theme.colors.mainPurple};
  overflow: hidden;
  ${({theme}) => theme.mediaQueries.md} {
    width: 65%;
  }
`

const Title = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

function ShortEvent({id, categories, date, description, address, maxMembers, members, photo, title}: EventResponse) {
  const navigate = useNavigate();
  const {onChangeFilter} = useEventsAction()
  const {pathname} = useLocation()
  const {isScroll} = useAppState()

  const isInvite = useMemo(() => _.includes(pathname.split('/'), 'invites'), [pathname])

  const goToEvents = useCallback((id: number): void => {
    if (isInvite) {
      navigate(`/invites/event/${id}`);
    } else {
      navigate(`/about-the-event/${id}`);
    }
  }, [navigate, isInvite]);

  const linkRef = useRef<any>(null)


  return (
    <Wrapper onClick={(event) => {
      if (!isScroll && event.target !== linkRef.current) goToEvents(id)
    }}>
      <EventImage>
        <img src={photo ? `${mediaUrl}file/${photo.id}` : ''} alt=""/>
      </EventImage>
      <Content>
        <Title variant={ETextVariants.Heading} as={'h2'}>{title}</Title>
        <Categories>
          {categories.map((category) => <Category key={`category-${category.id}`} variant={ETextVariants.MiniDark}>
            <T keyName={`category.list.${category.id}`}>{category.title}</T>
          </Category>)}
        </Categories>
        <Description variant={ETextVariants.Body16}>{description}</Description>
        <List>
          <Item>
            <CalendarIcon/>
            <TextStyled variant={ETextVariants.Body16}> {dayjs(date).format('D MMMM / HH:mm')}</TextStyled>
          </Item>
          <Item>
            <GeoGrayIcon/>
            <LinkStyled ref={linkRef} onClick={() => {
              onChangeFilter({
                latitude: Number(address.latitude),
                longitude: Number(address.longitude)
              })
              navigate(`/events/map?is-event=${true}&id=${id}`)
            }}>
              {address.fullString}
            </LinkStyled>
          </Item>
          <Item>
            <SmileIcon/>
            <Members variant={ETextVariants.Body16}>
              {maxMembers > 0
                ? <T
                  keyName="event.numberOfMembers1"
                  params={{
                    numberOfMembers: members.length,
                    maxNumberOfMembers: maxMembers
                  }}
                  defaultValue={`${members.length} из ${maxMembers} участников уже присоединились`}
                />
                : <T keyName="event.numberOfMembers2"
                     params={{numberOfMembers: members.length}}
                     defaultValue={`${members.length} участников уже присоединились`}
                />
              }
            </Members>
          </Item>
        </List>
      </Content>
    </Wrapper>
  );
}

export default ShortEvent;
