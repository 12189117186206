import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useAcquaintanceAction, useAcquaintanceState} from "../../store/acquaintance/hooks";
import styled from "styled-components";
import img1 from '../../assets/img/heart-rounded.png'
import img2 from '../../assets/img/match.png'
import img3 from '../../assets/img/x-close.png'
import {getTextStyle} from "../../UI/Text/utils";
import {ETextVariants} from "../../UI/Text/types";
import {useNavigate} from "react-router-dom";
import {T} from "@tolgee/react";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 67.5px;
  ${({theme}) => theme.mediaQueries.md} {
    gap: 50px;
  }
`

const ButtonWrapper = styled.button`
  outline: none;
  border: none;
  background: ${({theme}) => theme.colors.white};
  width: 81px;
  height: 81px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.10);
  img {
    width: 40.5px;
  }
  &:hover {
    background: ${({theme}) => theme.colors.mainGray};
  }
  ${({theme}) => theme.mediaQueries.md} {
    width: 60px;
    height: 60px;
    img {
      width: 30px;
      
    }
  }
`

const ActionsDialog = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, -100%);
  width: 100%;
  max-width: 360px;
  display: flex;
  flex-direction: column;
  top: -40px;
  z-index: 1000;
  filter: drop-shadow( 0 0 5px rgba(34,34,34,.2));
`


const Action = styled.div`
  display: flex;
  padding: 18px 58px 18px 60px;
  justify-content: center;
  align-items: center;
  border-bottom: 0.5px solid #DBDBDB;
  background: ${({theme}) => theme.colors.white};
  ${({theme}) => getTextStyle(theme, ETextVariants.Body16)};
  color: ${({theme}) => theme.colors.mainPurple};
  text-align: center;
  cursor: pointer;
  user-select: none;
  &:first-child {
    border-radius: 18px 18px 0 0;
  }
  &:last-child {
    border-radius: 0 0 18px 18px;
    border-bottom: none;
  }
  &:hover {
    background: ${({theme}) => theme.colors.mainPurple};
    color: ${({theme}) => theme.colors.white};
  }
`

function AcquaintanceActionsComponent() {
  const navigate = useNavigate()
  const {suggestion, isLoading} = useAcquaintanceState()
  const {onLike, onUnLike} = useAcquaintanceAction()
  const block = useRef<HTMLDivElement>(null)
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false);

  const handleClickOutside = useCallback((e: any) => {
    const path = e.path || (e.composedPath && e.composedPath());
    if (!path.includes(block.current)) {
      setIsShowDialog(false)
    }
  }, [])
  useEffect(() => {
    const rootEl = document.getElementById('root')
    if (rootEl && window) {
      window.addEventListener('click', handleClickOutside, false);
      return () => {
        window.removeEventListener('click', handleClickOutside, false);
      }
    }
  }, [handleClickOutside])

  if (!suggestion) return null

  return (
    <Wrapper ref={block}>
      {isShowDialog && <ActionsDialog>
        <Action onClick={() => navigate('/invites')}>
            <T keyName="acquaintanceActions.invite">Пригласить на ивент из списка</T>
        </Action>
        <Action onClick={() => navigate('/invites/create')}>
            <T keyName="acquaintanceActions.createAndInvite">Создать ивент и пригласить</T>
        </Action>
      </ActionsDialog>}
      <ButtonWrapper disabled={isLoading} onClick={() => {
        if (suggestion && !isLoading) onUnLike(suggestion.id)
      }}>
        <img src={img3} alt=""/>
      </ButtonWrapper>
      <ButtonWrapper disabled={isLoading} onClick={() => setIsShowDialog(prevState => !prevState)}>
        <img src={img2} alt=""/>
      </ButtonWrapper>
      <ButtonWrapper disabled={isLoading} onClick={() => {
        if (suggestion && !isLoading) onLike(suggestion.id)
      }}>
        <img src={img1} alt=""/>
      </ButtonWrapper>
    </Wrapper>
  );
}

export default AcquaintanceActionsComponent;
