import React, {Fragment} from 'react';
import styled from "styled-components";
import {generateArrayFrom} from "../../utils";
import {ETextVariants} from "../../UI/Text/types";
import {T} from "@tolgee/react";
import Text from "../../UI/Text";


const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 5px;
  list-style: disc;
`
const TextStyled = styled(Text)`
  width: 100%;
  text-align: justify;
  display: inline-block;
  vertical-align: baseline;
`

const array = generateArrayFrom(1, 7)

function PostsRulesList() {
  return (
    <List>
      {
        array.map((item, id) => {
          return <Fragment key={id}>
            <li>
              <TextStyled variant={ETextVariants.Body16}>
                <T keyName={`terms.items.postsList.${item}`}/></TextStyled>
            </li>
          </Fragment>
        })
      }
    </List>
  );
}

export default PostsRulesList;
