import {useDispatch, useSelector} from "react-redux";
import {useCallback, useMemo} from "react";
import {AppDispatch, RootState} from "../index";
import fetchSendSms from "./fetchSendSms";
import {useNavigate} from "react-router-dom";
import fetchLogin from "./fetchLogin";
import fetchRegister from "./fetchRegister";
import {changeLanguage, logout, setUserData} from "./authSlice";
import {UserEntity} from "../../types";

export const getAuth = (state: RootState) => state.auth

export function useAuthAction() {
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const onSendSms = useCallback((phoneNumber: string) => {
    dispatch(fetchSendSms(phoneNumber))
      .unwrap()
      .then(({nextStep}) => {
        if (nextStep === 'login') {
          navigate(`/auth/login`)
        }
        if (nextStep === 'register') {
          navigate(`/auth/register`)
        }
      })

  }, [dispatch, navigate])
  const onLogout = useCallback(() => {
    dispatch(logout())
  },[dispatch])
  const onSetUserData = useCallback((data: UserEntity | null) => {
    dispatch(setUserData(data))
  },[dispatch])
  const onChangeLanguage = useCallback((data: string) => {
    dispatch(changeLanguage(data))
  },[dispatch])
  const onLogin = useCallback((code: string) => {
    dispatch(fetchLogin(code))
      .unwrap()
      .then(() => {
        navigate(`/events`)
      }).catch((error) => console.log(error))

  }, [dispatch, navigate])
  const onRegister = useCallback((data:{ code: string, login: string }) => {
    dispatch(fetchRegister(data))
      .unwrap()
      .then(() => {
        navigate(`/events`)
      }).catch((error) => console.log(error))

  }, [dispatch, navigate])
  return useMemo(() => ({
    onSendSms,
    onRegister,
    onLogin,
    onLogout,
    onSetUserData,
    onChangeLanguage,
  }), [
    onSendSms,
    onRegister,
    onLogin,
    onLogout,
    onSetUserData,
    onChangeLanguage,
  ])
}

export function useAuthState() {
  return useSelector(getAuth)
}
