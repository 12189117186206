import {useDispatch, useSelector} from "react-redux";
import {useCallback, useMemo} from "react";
import {AppDispatch, RootState} from "../index";
import {setTitle, toggleCart, toggleScroll, toggleSearch} from "./appSlice";

export const getApp = (state: RootState) => state.app

export function useAppAction() {
  const dispatch = useDispatch<AppDispatch>()
  const onSetTitle = useCallback((title: string) => {
    dispatch(setTitle(title))
  }, [dispatch])
  const onToggleCart = useCallback(() => {
    dispatch(toggleCart())
  }, [dispatch])
  const onToggleScroll = useCallback(() => {
    dispatch(toggleScroll())
  }, [dispatch])
  const onToggleSearch = useCallback(() => {
    dispatch(toggleSearch())
  }, [dispatch])
  return useMemo(() => ({
    onSetTitle,
    onToggleCart,
    onToggleSearch,
    onToggleScroll,
  }), [
    onSetTitle,
    onToggleCart,
    onToggleSearch,
    onToggleScroll,
  ])
}

export function useAppState() {
  return useSelector(getApp)
}
