import React, {useCallback, useContext, useState} from 'react';
import styled from "styled-components";
import {Card} from "../../components/Templates/Card";
import ReactTextareaAutosize from "react-textarea-autosize";
import {PaperClipIcon, SendIcon} from "../../UI/Svg";
import ModalPostPhoto from "../../components/Modals/ModalPostPhoto";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {handle} from "../../api";
import {ProfileApi} from "../../api/profile";
import {useWallAction} from "../../store/wall/hooks";
import {ProfileContext} from "../../contexts/ProfileContext";
import CreateEventModal from "../../components/Modals/CreateEventModal";
import {T, useTranslate} from "@tolgee/react";
import {WallApi} from "../../api/wall";
import {handleImageUpload} from "../../utils";

const CardStyled = styled(Card)`
  display: flex;
  gap: 10px;
  flex-direction: column;
  padding: 16px 30px;

  ${({theme}) => theme.mediaQueries.md} {
    padding: 12px 16px;
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
`

const ButtonAttach = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  border-radius: 10px;
  cursor: pointer;
  height: 40px;
  background: ${({theme}) => theme.colors.bg};
`

const ButtonSend = styled(ButtonAttach)`
`

const TextAreaStyled = styled(ReactTextareaAutosize)`
  resize: none;
  padding: 11.5px 20px;
  border-radius: 10px;
  background: ${({theme}) => theme.colors.bg};
  border: none;
  font-size: 14px;
  width: 100%;
  max-height: 40px !important;
`


function CreatePostView() {
  const {t} = useTranslate()
  const [text, setText] = useState<string>('');
  const [loadFile, setLoadFile] = useState<File | null>(null);
  const [isShowModal, setIsShowModal] = useState<boolean>(false);

  const [isShowErrorModal, setIsShowErrModal] = useState<boolean>(false);
  const [bodyModal, setBodyModal] = useState<{ title: string, body: string }>(
    {title: '', body: ''}
  );
  const {onFetchWall} = useWallAction()
  const {profile} = useContext(ProfileContext)

  const onSend = useCallback(async () => {
    const compressedFile = await handleImageUpload(loadFile)
    if (text.trim().length === 0 && !compressedFile) return
    let content = text.trim().length === 0 ? "" : text

    const [postRes, postErr] = await handle(ProfileApi.postCreatePost({body: content}))
    if (postRes) {
      if (compressedFile !== null) {
        const [_, fileErr] = await handle(ProfileApi.putSetPhoto(postRes.id, compressedFile))

        // if (fileRes) console.log(fileRes);
        if (fileErr) {
          setBodyModal({
            title: t('common.errorImage','Ошибка загрузки изображения'),
            body: fileErr.message
          });
          setLoadFile(null)
          setIsShowErrModal(true);
          if (!content) {
            await handle(WallApi.deletePost(postRes.id))
          }
          return
        }
      }

    }
    if (postErr) {
      console.log(postErr);
      return
    }
    setLoadFile(null)
    setText('')
    if (profile) {
      onFetchWall(profile.id)
    }
  }, [loadFile, onFetchWall, profile, t, text]);

  return (
    <>
      <CardStyled>
        <Row>
          <ButtonAttach onClick={() => setIsShowModal(true)}>
            <PaperClipIcon/>
          </ButtonAttach>
          <TextAreaStyled
            onChange={(e) => setText(e.target.value.substring(0, 2000))}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                onSend()
              }
            }}
            minRows={1}
            value={text}
            placeholder={t('modalPost.placeholder', "Что у вас нового?")}
          />
          <ButtonSend onClick={onSend}>
            <SendIcon/>
          </ButtonSend>
        </Row>

        {isShowModal && <ModalPostPhoto
            loadFile={loadFile}
            onClose={() => setIsShowModal(false)}
            onChange={(file) => {
              if (file) {
                setLoadFile(file)
                setIsShowModal(false)
              }
            }}
        />}
        {loadFile && (
          <Text variant={ETextVariants.Body16}>
            {loadFile
              ? <T keyName="modalPost.value"
                   defaultValue={`Имя обложки: ${loadFile.name}`}
                   params={{name: loadFile.name}}/>
              : ''}
          </Text>
        )}
      </CardStyled>
      {isShowErrorModal &&
          <CreateEventModal isModalClose={() => setIsShowErrModal(false)} title={bodyModal.title} body={bodyModal.body}/>}
    </>
  );
}

export default CreatePostView;
