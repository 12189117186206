import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {IAdminForbiddenWordsFilter, IForbiddenWord} from "./types";
import {ForbiddenApi} from "../../api/forbidden";

const fetchWords = createAsyncThunk<IForbiddenWord[],
  void,
  {
    state: RootState
  }>(
  'adminForbiddenWords/fetchWords',
  async (_, ThunkApi) => {
    const authState = ThunkApi.getState().auth
    const {filter} = ThunkApi.getState().adminForbiddenWords
    const filterData: IAdminForbiddenWordsFilter = {}
    for (const filterKey in filter) {
      const field = filterKey as keyof IAdminForbiddenWordsFilter
      if (filter[field] !== undefined) {
        filterData[field] = filter[field]
      }
    }
    if (authState.auth) {
      const [wordsRes, wordsErr] = await handle(ForbiddenApi.getWords(filterData))
      if (wordsRes) {
        return wordsRes
      }
      if (wordsErr) {
        throw {message: wordsErr.message || wordsErr.message[0]}
      }
    }
    throw {message: 'No user state'}
  }
)

export default fetchWords
