import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IAdminUsersFilter} from "./types";
import {UserEntity} from "../../types";
import fetchUsers from "./fetchUsers";

export type AdminUsersState = {
    filter: IAdminUsersFilter,
    users: UserEntity[],
    isLoading: boolean,
    isLastPage: boolean
}

const LIMIT = 20

const initialState: AdminUsersState = {
    filter: {limit: LIMIT, page: 1},
    users: [],
    isLoading: true,
    isLastPage: false,
}

export const adminUsersSlice = createSlice({
    name: 'adminUsers',
    initialState,
    reducers: {
        changeFilter: (state, action: PayloadAction<IAdminUsersFilter>) => {
            state.filter = {...state.filter, ...action.payload}
        },
        changePage: (state, action: PayloadAction<number>) => {
            state.filter.page = action.payload
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchUsers.pending, (state, ) => {
            state.isLoading = true
        })
        builder.addCase(fetchUsers.rejected, (state, ) => {
            state.isLoading = false
        })
        builder.addCase(fetchUsers.fulfilled, (state, action: PayloadAction<UserEntity[]>) => {
            state.users = [...action.payload]
            state.isLoading = false
            state.isLastPage = action.payload.length < (state.filter.limit || LIMIT)
        })
    }
})

export const {changeFilter, changePage} = adminUsersSlice.actions
export default adminUsersSlice.reducer
