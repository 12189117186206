import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {CartApi} from "../../api/cart";
import {ICartItem} from "./types";
import _ from "lodash";
import {checkItem} from "./cartSlice";

const fetchAddItem = createAsyncThunk<string[],
  {favorId: number, quantity: number, withSelect?: boolean},
  {
    state: RootState
  }>(
  'cart/fetchAddItem',
  async ({favorId, withSelect, quantity}, ThunkApi) => {
    const authState = ThunkApi.getState().auth
    if (authState.auth) {
      const [cartRes, cartErr] = await handle(CartApi.postAddItem({favorId, quantity}))
      if (cartRes) {
        const items: ICartItem[] = cartRes.items
        const needItem: ICartItem | undefined = _.find(items, ({favor}) => favor.id === favorId)
        if (needItem) {
          ThunkApi.dispatch(checkItem(needItem.id))
        }
        return cartRes
      }
      if (cartErr) {
        throw {message: cartErr.message[0] || cartErr.message}
      }
    }
    throw {message: 'No user state'}
  }
)

export default fetchAddItem
