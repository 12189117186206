import React, {useEffect} from 'react';
import ProfileComponent from "../../components/AcquaintanceComponents/ProfileComponent";
import AcquaintanceActionsComponent from "../../components/AcquaintanceComponents/AcquaintanceActionsComponent";
import {useAppAction} from "../../store/app/hooks";
import StackComponent from "../../components/AcquaintanceComponents/StackComponent";
import {useAcquaintanceState} from "../../store/acquaintance/hooks";
import {useTranslate} from "@tolgee/react";

function MainPage({onSetTab}:{onSetTab: (tab: number) => void}) {
  useEffect(() => {
    onSetTab(0)
  },[onSetTab])
  const {t} = useTranslate()
  const {suggestion, suggestions} = useAcquaintanceState()
  const {onSetTitle} = useAppAction()
  useEffect(() => {
    onSetTitle(t('navbar.acquaintance', 'Знакомства'))
  }, [onSetTitle, t])
  return (
    <>
      <StackComponent>
        {suggestion && <ProfileComponent suggestion={suggestion}/>}
        {
          suggestions.map((user, id) => <ProfileComponent key={`User-${id}`} suggestion={user}/>)
        }
      </StackComponent>
      <AcquaintanceActionsComponent/>
    </>
  );
}

export default MainPage;
