import React, {useEffect} from 'react';
import styled from "styled-components";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {Button, EButtonVariants} from "../../UI/Button";
import imgComplete from '../../assets/img/CompletePayment.png'
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {useCartAction} from "../../store/cart/hooks";
import {useStripePaymentIntent} from "../../hooks/useStripePaymentIntent";
import {T, useTranslate} from "@tolgee/react";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex: 1;

  ${({theme}) => theme.mediaQueries.md} {
  }
`

const Desc = styled.div`

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  & * {
    text-align: center;
  }
`

const ButtonStyled = styled(Button)`
  width: 343px;

  ${({theme}) => theme.mediaQueries.md} {
`

const Image = styled.img`
  width: 500px;

  ${({theme}) => theme.mediaQueries.md} {
    max-width: 100%;
  }
`
const translateFunc = (content: any) => {
  return <span>{content}</span>
};
function CompletePaymentPage() {
  const {t} = useTranslate()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const {onClearCheckedItems, onChangePhone} = useCartAction()
  useEffect(() => {
    return () => {
      onClearCheckedItems()
      onChangePhone('')
    }
  }, [])

  const {getPaymentIntent} = useStripePaymentIntent()

  useEffect(() => {
    const paymentIntentId = searchParams.get('payment_intent')
    if (paymentIntentId) {
      getPaymentIntent(paymentIntentId)
    }
  }, [getPaymentIntent, searchParams])

  return (
    <Wrapper>
      <Image src={imgComplete} alt={'Complete'}/>
      <Desc>
        <Text variant={ETextVariants.Heading} as={'h2'}><T keyName="completePayment.title">Оплата прошла
          успешно</T></Text>
        <Text variant={ETextVariants.Body16} as={'p'}>
          <T keyName="completePayment.text"
             params={{
               br: <br/>
             }}
             defaultValue={"Билет отправлен вам на почту!<br/> Детали мероприятия можно узнать в билете или на странице фирмы."}
          />
        </Text>
      </Desc>
      <ButtonStyled variant={EButtonVariants.Primary} onClick={() => {
        navigate('/')
      }}><T keyName="completePayment.backToApp">Вернуться в приложение</T></ButtonStyled>
    </Wrapper>
  );
}

export default CompletePaymentPage;
