import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {AcquaintanceApi} from "../../api/acquaintance";
import {UserEntity} from "../../types";

const fetchNextSuggestion = createAsyncThunk<UserEntity,
  void,
  {
    state: RootState
  }>(
  'acquaintance/fetchNextSuggestion',
  async (_, ThunkApi) => {
    const authState = ThunkApi.getState().auth
    if (authState.auth) {
      const [userRes, userErr] = await handle(AcquaintanceApi.getNextSuggestion())
      if (userRes) {
        return userRes
      }
      if (userErr) {
        throw {message: userErr.message || userErr.message[0]}
      }
    }
    throw {message: 'No user state'}
  }
)

export default fetchNextSuggestion
