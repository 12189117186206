import React, {useCallback} from 'react';
import AdminHeader from "../../../components/AdminComponents/AdminHeader";
import ForbiddenFilterView from "../../../views/ForbiddenViews/ForbiddenFilterView";
import {
  useAdminForbiddenWords,
  useAdminForbiddenWordsAction,
  useAdminForbiddenWordsState
} from "../../../store/adminForbiddenWords/hooks";
import styled from "styled-components";
import Table from "../../../UI/Table";
import FormView from "../../../views/ForbiddenViews/FormView";
import {useTranslate} from "@tolgee/react";

const TableWrapper = styled.div`
  width: calc(100% + 80px);
  margin: 0 -40px;
  margin-top: 40px;
`

function Index() {
  useAdminForbiddenWords()
  const {t} = useTranslate()
  const {words} = useAdminForbiddenWordsState()
  const {onDeleteWord} = useAdminForbiddenWordsAction()
  return (
    <>
      <AdminHeader title={t('admin.menu.stopWords', 'Стоп слова')} />
      <ForbiddenFilterView/>
      <FormView/>
      <TableWrapper>
        <Table
          heading={[
            {key: 'id', value: '№'},
            {key: 'word', value: t('admin.stopWords.word','Слово')},
          ]}
          actions={[
            {title: t('common.delete','Удалить'), callback:(id: string) => onDeleteWord(parseInt(id)), dataKey: 'id'},
            // {title: 'Заблокировать', callback: onBlock, dataKey: 'id'},
          ]}
          data={words}
        />
      </TableWrapper>
    </>
  );
}

export default Index;
