import React, {useEffect, useMemo, useState} from 'react';
import styled from "styled-components";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import Select from "../../UI/Select";
import {useAdminPaymentsAction, useAdminPaymentsState} from "../../store/adminPayments/hooks";
import {DataPickerRange} from "../../UI/DataPickerRange";
import Input from "../../UI/Input";
import {SearchIcon} from "../../UI/Svg";
import {T, useTranslate} from "@tolgee/react";

const Wrapper = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
`

const FilterWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

const Filters = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  position: relative;
`


const DataPickerRangeStyled = styled(DataPickerRange)`
  min-width: 178px;
`

const InputStyled = styled(Input)`
  background: ${({theme}) => theme.colors.white};
`

function PaymentsAllFilterView() {
  const {t} = useTranslate()
  const {filter} = useAdminPaymentsState()
  const {onChangeFilter} = useAdminPaymentsAction()
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const [startDate, endDate] = useMemo(() => dateRange, [dateRange]);


  useEffect(() => {
    if (startDate && endDate) {
      onChangeFilter({
        dateFrom: startDate.toString(),
        dateTo: endDate.toString()
      })
    } else if (startDate) {
      onChangeFilter({
        dateFrom: startDate.toString(),
      })
    } else if (endDate) {
      onChangeFilter({
        dateTo: endDate.toString()
      })
    } else {
      onChangeFilter({
        dateFrom: undefined,
        dateTo: undefined,
      })
    }
  }, [startDate, endDate, onChangeFilter])

  return (
    <Wrapper>
      <Text variant={ETextVariants.Body16}><T keyName="admin.filter">Фильтр:</T></Text>
      <FilterWrapper>
        <Filters>
          <DataPickerRangeStyled
            setDateRange={setDateRange}
            startDate={startDate}
            endDate={endDate}
            bg
            isClearButton={true}
            isIcon={!startDate && !endDate}
            typeArrowPurple={true}
            mobileVersion={true}
          />

          <InputStyled
            placeholder={t('common.search', "Поиск")}
            icon={<SearchIcon/>}
            value={filter.search}
            onChange={(event) => onChangeFilter({search: event.target.value})}
          />
        </Filters>
      </FilterWrapper>
    </Wrapper>
  );
}

export default PaymentsAllFilterView;
