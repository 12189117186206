import React, {useCallback, useMemo} from 'react';
import {useChatAction, useChatState} from "../../store/chat/hooks";
import styled from "styled-components";
import {Button, EButtonVariants} from "../../UI/Button";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {CloseIcon} from "../../UI/Svg";
import {useAuthState} from "../../store/auth/hooks";
import _ from 'lodash';
import {T} from "@tolgee/react";

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  align-items: stretch;
  ${({theme}) => theme.mediaQueries.md} {
    display: none;
  }
`

const CountBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  ${({theme}) => theme.mediaQueries.md} {
    width: 100%;
    justify-content: space-between;
  }
`

const ButtonClear = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 12px;
    height: 12px;

    path {
      stroke: ${({theme}) => theme.colors.mainGray};
    }
  }
`

const TextDesktop = styled(Text)`
  display: inline;
  ${({theme}) => theme.mediaQueries.md} {
    display: none;
  }
`
const TextMobile = styled(Text)`
  display: none;
  color: #000;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px; /* 113.636% */
  letter-spacing: -0.24px;
  ${({theme}) => theme.mediaQueries.md} {
    display: inline;
  }
`

function SelectedHeaderComponent({onShowModal}:{onShowModal: () => void}) {

  const {user} = useAuthState()
  const {checkedMessages, currentChat} = useChatState()
  const {onClearCheckedMessages, onDeleteMessages} = useChatAction()

  const isCanDelete = useMemo(() => {
    if (currentChat && user) {
      const msg = currentChat.messages.find((msg) => _.includes(checkedMessages, msg.id) && msg.sender.id !== user.id)
      return !msg
    }
    return false
  }, [checkedMessages, currentChat, user])

  const onDelete = useCallback(() => {
    if (isCanDelete) onDeleteMessages(checkedMessages)
  }, [checkedMessages, isCanDelete, onDeleteMessages])

  return (
    <>
      <CountBlock>
        <div/>
        <TextDesktop variant={ETextVariants.Body16}>
          <T keyName="chat.selectedHeader.messages" params={{count:checkedMessages.length}} defaultValue={`${checkedMessages.length} сообщение`}/>
        </TextDesktop>
        <TextMobile variant={ETextVariants.Body16}>
          <T keyName="chat.selectedHeader.selected" params={{count: checkedMessages.length}} defaultValue={`Выбрано: ${checkedMessages.length}`}/>
        </TextMobile>
        <ButtonClear onClick={onClearCheckedMessages}>
          <CloseIcon/>
        </ButtonClear>
      </CountBlock>
      <Buttons>
        <Button disabled={!isCanDelete} variant={EButtonVariants.Error} onClick={onDelete}><T keyName="common.delete">Удалить</T></Button>
        <Button variant={EButtonVariants.Primary} onClick={onShowModal}><T keyName="chat.selectedHeader.buttons.resend">Переслать</T></Button>
      </Buttons>
    </>
  );
}

export default SelectedHeaderComponent;
