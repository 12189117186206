import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="18" height="25" viewBox="0 0 18 25" fill="none" {...props} >
          <path fillRule="evenodd" clipRule="evenodd" d="M2.99128 6C2.99128 2.68629 5.67954 0 8.99566 0C12.3118 0 15 2.68629 15 6V11C15 14.3137 12.3118 17 8.99566 17C5.67954 17 2.99128 14.3137 2.99128 11V6ZM8.99566 2C6.78491 2 4.99274 3.79086 4.99274 6V11C4.99274 13.2091 6.78491 15 8.99566 15C11.2064 15 12.9986 13.2091 12.9986 11V6C12.9986 3.79086 11.2064 2 8.99566 2Z" fill="#6C27AB"/>
          <path d="M17.8883 15.5865C18.1418 15.0957 17.9492 14.4925 17.458 14.2392C16.9669 13.9859 16.3633 14.1784 16.1098 14.6692C15.4365 15.9727 14.4176 17.0661 13.1644 17.8299C11.9112 18.5937 10.472 18.9985 9.00405 19C7.53613 19.0015 6.09605 18.5997 4.84125 17.8386C3.58645 17.0774 2.56523 15.9861 1.88924 14.6841C1.63472 14.1938 1.03069 14.0026 0.540091 14.2569C0.0494953 14.5113 -0.141884 15.1149 0.112635 15.6051C0.95762 17.2327 2.23415 18.5967 3.80265 19.5482C5.08165 20.3241 6.51465 20.8012 7.99493 20.9499V24C7.99493 24.5523 8.44298 25 8.99566 25C9.54835 25 9.99639 24.5523 9.99639 24V20.9499C11.4838 20.8005 12.9232 20.3195 14.2066 19.5373C15.7731 18.5826 17.0468 17.2158 17.8883 15.5865Z" fill="#6C27AB"/>
        </Svg>
    );

export default Icon;
