import {axiosInstance} from "./index";
import {IEventsFilter, IPostCreateEvent} from "../store/events/types";
import {IAdminEventsFilter} from "../store/adminEvents/types";

export const EventsApi = {
    getCategories: async () => await axiosInstance.get('category'),
    postCategories: async (title: string) => await axiosInstance.post('category', {title}),
    putCategory: async (id: number, title: string) => await axiosInstance.put(`category/${id}`, {title}),
    deleteCategory: async (id: number) => await axiosInstance.delete(`category/${id}`),
    getEvents: async (data: IEventsFilter) => await axiosInstance.get('event', {params: data}),
    getEvent: async (id: string) => await axiosInstance.get(`event/${id}`, ),
    getEventsById: async (id: number) => await axiosInstance.get(`event/user/${id}/joined`, ),
    getAddress: async (lat: string, long: string) => await axiosInstance.get(`event/address`, {params: {lat, long}}),
    postCreateEvent: async (data: IPostCreateEvent) => await axiosInstance.post(`event`, data ),
    putUpdateEvent: async (id: number, data: IPostCreateEvent) => await axiosInstance.put(`event/${id}`, data ),
    putJoinEvent: async (id: string) => await axiosInstance.put(`event/${id}/join` ),
    putLeaveEvent: async (id: string) => await axiosInstance.put(`event/${id}/leave` ),
    putPhotoEvent: async (eventId: string, file: File) => await axiosInstance.put(`event/${eventId}/photo`, {file}, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    } ),
    getAdminEvents: async (data: IAdminEventsFilter) => await axiosInstance.get('event/all', {params: data}),
    putAdminApproveEvent: async (id: string) => await axiosInstance.put(`event/${id}/approve`, ),
    putAdminRejectEvent: async (id: string) => await axiosInstance.put(`event/${id}/reject`, ),
    deleteAdminEvent: async (id: string) => await axiosInstance.delete(`event/${id}`, ),
    deleteUserEvent: async (id: string) => await axiosInstance.delete(`event/${id}/my`, ),
    postInviteEvent: async (eventId: string, userId: number) => await axiosInstance.post(`event/${eventId}/invite`, {targetId: userId}),
}
