import React, {ReactNode} from "react";
import PoliticsTable from "../components/PoliticsComponents/PoliticsTable";

export interface IPolitic {
  isDescription?: boolean,
  descriptionParams?: {[key: string]: any},
  items: Array<{keyName: string, params?: {[key: string]: any}, componentAfter?: ReactNode}>,
  baseKey: string
}

const politics: Array<IPolitic> = [
  {
    baseKey: '1',
    items: [
      {keyName: '1'},
      {keyName: '2'},
      ]
  },
  {
    baseKey: '2',
    isDescription: true,
    items: [{keyName: '1', componentAfter: <PoliticsTable/>}, {keyName: '2'}, {keyName: '3'}, {keyName: '4'}, {keyName: '5'},]
  },
  {
    baseKey: '3',
    items: [{keyName: '1'}, {keyName: '1.1'}, {keyName: '1.2'}]
  },
  {
    baseKey: '4',
    items: [{keyName: '1'}, {keyName: '1.1'}, {keyName: '1.2'}, {keyName: '1.3'}, {keyName: '1.4'}, {keyName: '2'}]
  },
  {
    baseKey: '5',
    items: [
      {keyName: "1"}, {keyName: "1.1"},
      {keyName: "2"},
      {keyName: "3"}, {keyName: "3.1"}, {keyName: "3.2"}, {keyName: "3.3"}, {keyName: "3.4"}, {keyName: "3.5"},
      {keyName: "4"}, {keyName: "4.1"}, {keyName: "4.2"}, {keyName: "4.3"},
      {keyName: "5"}, {keyName: "5.1"}, {keyName: "5.2"}, {keyName: "5.3"}, {keyName: "5.4"},
      {keyName: "6"}, {keyName: "7"}, {keyName: "8", params: {email: 'email'}},
    ]
  },
  {
    baseKey: '6',
    items: [],
    isDescription: true,
    descriptionParams: {
      'a': <a href={window.origin} target={'_blank'} rel={'noreferrer'}/>,
    }
  },
]

export {politics}
