import React, {useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import {Container} from "../../components/Templates/Container";
import {Link, useLocation, useNavigate} from "react-router-dom";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import Input from "../../UI/Input";
import {T, useTranslate} from "@tolgee/react";
import {useForm} from "react-hook-form";
import {
  ArrowLeftIcon,
  BagIcon,
  ChatIcon,
  HeartsIcon, LoginIcon,
  PlusIcon,
  ProfileIcon,
  SearchIcon,
  SettingsIcon
} from "../../UI/Svg";
import {langs} from "../../config";
import {useLanguage} from "../../hooks/useLanguage";
import {useAuthAction, useAuthState} from "../../store/auth/hooks";
import NotificationsMessages from "../../components/NavbarComponents/NotificationsMessages";
import {useAppAction, useAppState} from "../../store/app/hooks";
import {useEventsAction} from "../../store/events/hooks";
import {useCompanyAction, useCompanyState} from "../../store/company/hooks";
import {useGeoAction} from "../../store/geo/hooks";
import {useCartAction} from "../../store/cart/hooks";
import ModalCart from "../../components/Modals/ModalCart";
import {useChatAction} from "../../store/chat/hooks";
import _ from "lodash";
import {useNotifications} from "../../store/notifications/hooks";

const Desktop = styled.div`
  display: flex;
  align-items: center;
  background: ${({theme}) => theme.colors.white};
  margin-bottom: 3rem;
  width: 100%;
  height: 6rem;

  ${({theme}) => theme.mediaQueries.md} {
    display: none;
  }
;
`
const Mobile = styled.div`
  display: none;
  position: relative;
  height: 4.4rem;
  width: 100%;
  background: ${({theme}) => theme.colors.white};
  align-items: center;
  padding: 0 1.8rem;
  justify-content: center;

  ${({theme}) => theme.mediaQueries.md} {
    display: flex;
  }
`

const MobileRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
`

const Buttons = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  left: 0;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ContainerStyled = styled(Container)<{ maxWidth?: number }>`
  display: flex;
  ${({maxWidth}) => maxWidth ? `max-width: ${maxWidth}px;` : ''}
  justify-content: space-between;
  align-items: center;
`

const LeftBlock = styled.div`
  display: flex;
  align-items: center;
`
const RightBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
`

const Logo = styled(Link)`
  display: flex;
  align-items: center;

  div {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 10px;
    background-color: ${({theme}) => theme.colors.mainPurple};
  }
`

const TextStyled = styled(Text)`

  margin-left: 1.4rem;
  margin-right: 4rem;
  display: flex;
  align-items: center;
  text-decoration: none;;
`

const Langs = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`


const LangItem = styled(Text)`
  cursor: pointer;
`;

const LinkStyled = styled(Link)`
  display: flex;
  position: relative;
  cursor: pointer;
  align-items: center;
  gap: 8px;

  svg {
    width: 24px;
    height: 24px;
  }
`

const Acquaintance = styled(Text)`
  color: ${({theme}) => theme.colors.mainPurple};
`


const Title = styled(Text)`
  font-size: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration: none;
  max-width: 80%;
`

const ButtonIcon = styled.button`
  width: 24px;
  height: 24px;
  border: none;
  background: ${({theme}) => theme.colors.white};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;

  &:active {
    opacity: .5;
  }

  svg {
    width: 24px;
    height: 24px;

    path {
      stroke: ${({theme}) => theme.colors.mainPurple};
    }
  }
`

const ButtonIconHeart = styled(ButtonIcon)`
  svg path {
    stroke: none;
  }
`

const ButtonSetting = styled(ButtonIcon)`
  padding: 0;

  svg {
    width: 24px;
    height: 24px;
  }
`

const arrMax = ['about-the-event', 'auth', 'service', 'events', 'acquaintance', 'invites', 'profile/events']

function Index() {
  useNotifications()
  const {t} = useTranslate();
  const {isLogIn} = useAuthState()
  const {pathname} = useLocation()
  const {selectedUserCompany} = useCompanyState()
  const {title} = useAppState()
  const {onToggleCart} = useAppAction()
  const {onFetchUserChats, onToggleIsCompany} = useChatAction()
  const navigate = useNavigate()
  const {onChangeLanguage} = useAuthAction()
  const {onFetchCountries} = useGeoAction()
  const {user} = useAuthState()

  useEffect(() => {
    onToggleIsCompany(!!selectedUserCompany)
  }, [onToggleIsCompany, selectedUserCompany])

  const maxWidth = useMemo(() => {
    const currentPath = pathname.split('/')
    if (_.includes(arrMax, currentPath[1])) return 1433
    else if (_.includes(arrMax, `${currentPath[1]}/${currentPath[2]}`)) return 1433
    else if (currentPath[1] === 'chat') return 1261
    return 1140
  }, [pathname])

  const {onFetchUserCompanies} = useCompanyAction()

  const isProfilePage = useMemo(() => pathname === '/profile', [pathname])
  const isChatPage = useMemo(() => pathname === '/chat', [pathname])
  const isAuthPage = useMemo(() => pathname.includes('auth'), [pathname])
  const isPoliticsOrTermsPage = useMemo(() => pathname === '/politics' || pathname === '/terms', [pathname])
  const isAcquaintance = useMemo(() => pathname === '/acquaintance', [pathname])

  useEffect(() => {
    onFetchCountries()
    onFetchUserCompanies()
  }, [onFetchCountries, onFetchUserCompanies, user])
  useEffect(() => {
    onFetchUserChats()
  }, [onFetchUserChats, user, selectedUserCompany])

  const {register} = useForm();
  return (
    <>
      <Desktop>
        <ContainerStyled maxWidth={maxWidth}>
          <LeftBlock>
            <Logo to={'/'}>
              <div/>
              <TextStyled as='h1' variant={ETextVariants.Heading}>TripHouse</TextStyled>
            </Logo>
            {!isLogIn && <Input
              {...register('search')}
              placeholder={t('common.search', 'Поиск')}
              icon={<SearchIcon/>}
            />}
          </LeftBlock>
          <RightBlock>
            {!isLogIn ? <Langs>
                {langs.map((lang, lid) => (
                  <LangItem variant={ETextVariants.Body16} key={`Lang-${lid}`}
                            onClick={() => onChangeLanguage(lang.value)}>
                    {lang.name}
                  </LangItem>
                ))}
                {isPoliticsOrTermsPage && <LinkStyled to={'/auth'}>
                  <LoginIcon/>
                </LinkStyled>}
              </Langs>
              : (
                <>
                  <LinkStyled to={'/cart'} onClick={(event) => {
                    event.preventDefault()
                    onToggleCart()
                  }}>
                    <BagIcon/>
                    <Text variant={ETextVariants.Body16}><T keyName="navbar.cart">Корзина</T></Text>
                  </LinkStyled>
                  <LinkStyled to={'/create'}>
                    <PlusIcon/>
                    <Text variant={ETextVariants.Body16}><T keyName="navbar.create">Создать</T></Text>
                  </LinkStyled>
                  <LinkStyled to={'/chat'}>
                    <NotificationsMessages/>
                    <ChatIcon/>
                    <Text variant={ETextVariants.Body16}><T keyName="navbar.chats">Чаты</T></Text>
                  </LinkStyled>
                  <LinkStyled to={'/profile'}>
                    <ProfileIcon/>
                    <Text variant={ETextVariants.Body16}><T keyName="navbar.profile">Профиль</T></Text>
                  </LinkStyled>

                  {!selectedUserCompany && <LinkStyled to={'/acquaintance'}>
                      <HeartsIcon/>
                      <Acquaintance variant={ETextVariants.Body16}><T
                          keyName="navbar.acquaintance">Знакомства</T></Acquaintance>
                  </LinkStyled>}
                </>
              )}
          </RightBlock>
        </ContainerStyled>
      </Desktop>
      {user ? <Mobile>
          <Buttons>
            {!isProfilePage ? !isChatPage && <ButtonIcon onClick={() => navigate(-1)}>
                <ArrowLeftIcon/>
            </ButtonIcon>
              : !selectedUserCompany && <ButtonIconHeart onClick={() => navigate('/acquaintance')}>
                <HeartsIcon/>
            </ButtonIconHeart>}
            {isProfilePage && <MobileRight>
              {!selectedUserCompany && <ButtonSetting onClick={onToggleCart}>
                  <BagIcon/>
              </ButtonSetting>}
                <ButtonSetting onClick={() => navigate('/profile/settings')}>
                    <SettingsIcon/>
                </ButtonSetting>
            </MobileRight>}
            {isAcquaintance && <MobileRight>
                <ButtonSetting onClick={() => navigate('/acquaintance/settings')}>
                    <SettingsIcon/>
                </ButtonSetting>
            </MobileRight>}
          </Buttons>
          <Title variant={ETextVariants.Heading}>{title}</Title>
        </Mobile>
        : <Mobile>
          <Buttons>
            {isPoliticsOrTermsPage && <ButtonIcon onClick={() => navigate(-1)}>
                <ArrowLeftIcon/>
            </ButtonIcon>
            }
          </Buttons>
          <Title variant={ETextVariants.Heading}>{title}</Title>
        </Mobile>
      }
      <ModalCart/>
    </>
  );
}

export default Index;
