import React, {useEffect, useMemo, useState} from 'react';
import styled from "styled-components";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {ArrowLeftIcon, CloseIcon} from "../../UI/Svg";
import 'react-image-gallery/styles/css/image-gallery.css';
import './modalProfileGallery.css';
import {T} from "@tolgee/react";
import {useProfileAction, useProfileState} from "../../store/profile/hooks";
import ImageGallery from 'react-image-gallery';
import {mediaUrl} from "../../api";

export interface ModalUserPhotoProps {
  onClose: () => void;
}

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  padding-top: 60px;
  z-index: 100000;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    content: '';
    position: absolute;
    background: ${({theme}) => theme.colors.transparentGray};
    width: 100%;
    height: 100%;
    padding-bottom: 100%;
    top: 0;
  }

  ${({theme}) => theme.mediaQueries.md} {
    position: absolute;
    border-radius: 0;
    width: 100%;
    height: 100%;
    padding-top: 0;
    margin-bottom: 71px;
  }
`

const Modal = styled.div`
  min-height: 300px;
  max-width: 1191px;
  height: 84vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  top: 0;
  right: 0;
  background: ${({theme}) => theme.colors.bg};
  z-index: 2;
  border-radius: 10px;

  &::-webkit-scrollbar {
    width: 0;
  }

  ${({theme}) => theme.mediaQueries.xxl} {
    width: 968px;
  }

  ${({theme}) => theme.mediaQueries.lg} {
    width: 770px;
  }

  ${({theme}) => theme.mediaQueries.md} {
    border-radius: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    overflow: scroll;

  }
`
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 32px 18px 70px;
  width: 100%;
  border-bottom: 1px solid ${({theme}) => theme.colors.darkWhite};
  position: relative;
  gap: 20px;
  background: ${({theme}) => theme.colors.white};

  ${({theme}) => theme.mediaQueries.md} {
    justify-content: center;
    padding: 8px 32px 10px;
    border-bottom: none;
  }
`

const CloseButton = styled.div`
  width: 20px;
  height: 20px;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    width: 12px;

    &:first-child {

      ${({theme}) => theme.mediaQueries.md} {
        display: none;
      }
    }

    &:nth-child(2) {

      display: none;

      ${({theme}) => theme.mediaQueries.md} {
        display: block;
      }
    }
  }
  ${({theme}) => theme.mediaQueries.md} {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 16px;
    svg {
      min-width: 24px;
    }
  }
`

const Content = styled.div`
  margin-top: 32px;
  display: flex;
  gap: 20px;
  width: 100%;
  padding: 0 16px 60px;
  flex: 1;
  overflow: auto;

  ${({theme}) => theme.mediaQueries.md} {
    margin-top: 10px;
    padding-bottom: 0;
  }
`

const WrapPhotos = styled.div<{ isGallery: boolean }>`
  display: ${({isGallery}) => (!isGallery ? 'grid' : '')};
  grid-template-columns: ${({isGallery}) => (!isGallery ? 'repeat(5, 1fr)' : '')};
  grid-template-rows: ${({isGallery}) => (!isGallery ? '0fr' : '')};
  width: ${({isGallery}) => (isGallery ? '100%' : '')};
  grid-column-gap: 14px;
  grid-row-gap: 14px;
  ${({theme}) => theme.mediaQueries.xxl} {
    grid-template-columns: repeat(4, 1fr);
  }
  ${({theme}) => theme.mediaQueries.lg} {
    grid-template-columns: repeat(3, 1fr);
  }
  ${({theme}) => theme.mediaQueries.md} {
    grid-template-columns: repeat(3, 1fr);
    margin: 0 auto;
  }
  ${({theme}) => theme.mediaQueries.sm} {
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 71px;
  }
  ${({theme}) => theme.mediaQueries.xs} {
    grid-template-columns: repeat(3, 1fr);
  }
`

const Photo = styled.div`
  width: 213px;
  height: 213px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: #9b9b9b;
  ${({theme}) => theme.mediaQueries.md} {
    width: 203px;
    height: 203px;
  }
  ${({theme}) => theme.mediaQueries.sm} {
    width: 111px;
    height: 111px;
    border-radius: 10px;
  }
  ${({theme}) => theme.mediaQueries.xs} {
    width: 81px;
    height: 81px;
  }
  img {
    max-height: 100%;
    width: auto;
  }
`

type GeneratedForGallery = {original: string; thumbnail: string;}

function ModalUsers({onClose}: ModalUserPhotoProps) {
  const [isGallery, setIsGallery] = useState<boolean>(false);
  const {photos} = useProfileState()
  const {onFetchProfileData} = useProfileAction()

  useEffect(() => {
    onFetchProfileData()
  }, [onFetchProfileData])


  const images = useMemo(() => {
    return photos.map((item) => ({ original: `${mediaUrl}file/${item.id}`, thumbnail: `${mediaUrl}file/${item.id}` }))
  },[ photos])

  return (
    <Wrapper>
      <Modal>
        <Header>
          <Text variant={ETextVariants.Heading}>
            <T keyName={'profile.photos'}>Фотографии</T>
          </Text>
          <CloseButton onClick={() => {
            if (isGallery) {
              setIsGallery(false)
            } else {
              onClose()
            }
          }}>
            <CloseIcon/>
            <ArrowLeftIcon/>
          </CloseButton>
        </Header>
        <Content>
          <WrapPhotos isGallery={isGallery}>
            {isGallery ? (
              <ImageGallery
                items={images}
                showPlayButton={false}
                showThumbnails={false}
                showFullscreenButton={false}
                infinite={false}
                slideDuration={0}
              />
            ) : (
              images.map((item, i) => {
                return <Photo key={i} onClick={() => setIsGallery(true)}>
                  <img src={item.thumbnail} alt=""/>
                </Photo>;
              })
            )}
          </WrapPhotos>
        </Content>
      </Modal>
    </Wrapper>
  );
}

export default ModalUsers;
