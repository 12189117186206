import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} >
          <path d="M22.2507 5.16482H15.8978L14.615 3.00972C14.4895 2.79902 14.2625 2.66992 14.0173 2.66992H9.98258C9.73736 2.66992 9.51025 2.79902 9.38483 3.00972L8.102 5.16482H6.01734V4.68983C6.01734 4.30567 5.70588 3.99422 5.32172 3.99422H2.74786C2.36362 3.99422 2.05225 4.30567 2.05225 4.68983V5.16482H1.74923C0.783084 5.16482 0 5.94798 0 6.91397V19.5787C0 20.5448 0.783084 21.3279 1.74915 21.3279H22.2507C23.2168 21.3279 23.9999 20.5448 23.9999 19.5787V6.91397C24 5.94798 23.2168 5.16482 22.2507 5.16482ZM11.9999 18.2253C9.25011 18.2253 7.02097 15.9962 7.02097 13.2463C7.02097 10.4965 9.25011 8.26732 11.9999 8.26732C14.7497 8.26732 16.9789 10.4965 16.9789 13.2463C16.9789 15.9962 14.7497 18.2253 11.9999 18.2253ZM21.1594 8.77105H18.9797C18.5955 8.77105 18.284 8.4596 18.284 8.07536C18.284 7.69112 18.5955 7.37967 18.9797 7.37967H21.1594C21.5437 7.37967 21.8551 7.69112 21.8551 8.07536C21.8551 8.4596 21.5436 8.77105 21.1594 8.77105Z" fill="#6C27AB"/>
          <path d="M11.9998 16.7242C13.9208 16.7242 15.4781 15.1669 15.4781 13.2459C15.4781 11.3249 13.9208 9.76758 11.9998 9.76758C10.0788 9.76758 8.52148 11.3249 8.52148 13.2459C8.52148 15.1669 10.0788 16.7242 11.9998 16.7242Z" fill="#6C27AB"/>
        </Svg>
    );

export default Icon;
