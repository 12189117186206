import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IAdminServicesFilter, ServiceResponse} from "./types";
import fetchServices from "./fetchServices";
import fetchDeleteService from "./fetchDeleteService";

export type AdminServicesState = {
    filter: IAdminServicesFilter,
    services: ServiceResponse[],
    isLoading: boolean
    isLastPage: boolean
}

const LIMIT = 20

const initialState: AdminServicesState = {
    filter: {limit: LIMIT, page: 1},
    services: [],
    isLoading: true,
    isLastPage: true
}

export const adminServicesSlice = createSlice({
    name: 'adminServices',
    initialState,
    reducers: {
        changeFilter: (state, action: PayloadAction<IAdminServicesFilter>) => {
            state.filter = {...state.filter, ...action.payload}
        },
        changePage: (state, action: PayloadAction<number>) => {
            state.filter.page = action.payload
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchServices.pending, (state, ) => {
            state.isLoading = true
        })
        builder.addCase(fetchDeleteService.rejected, (state, ) => {
            console.log('failed')
            state.isLoading = false
        })
        builder.addCase(fetchServices.rejected, (state, ) => {
            state.isLoading = false
        })
        builder.addCase(fetchServices.fulfilled, (state, action: PayloadAction<ServiceResponse[]>) => {
            state.services = [...action.payload]
            state.isLoading = false
            state.isLastPage = action.payload.length < (state.filter.limit || LIMIT)
        })
    }
})

export const {changeFilter, changePage} = adminServicesSlice.actions
export default adminServicesSlice.reducer
