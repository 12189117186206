import React, {useEffect, useRef} from 'react';
import {useEventsAction, useEventsState} from "../../store/events/hooks";
import ShortEvent from "../../components/HomeComponents/ShortEvent";
import styled from "styled-components";
import {Button, EButtonVariants} from "../../UI/Button";
import Draggable from "../../components/Templates/Draggable";

const EventsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  justify-content: flex-start;

  ${({theme}) => theme.mediaQueries.md} {
    padding: 0 10px;
    gap: 10px;
  }
`

const DivMore = styled.div`
  height: 0;
  width: 100%;
`

const ScrollWrapper = styled(Draggable)`
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 20px;
  &::-webkit-scrollbar {
    display: none;
  }
`

function HomeEvents() {
  const {all, isEnd, isLoadingMore} = useEventsState()
  const {onChangePage} = useEventsAction()
  const divMore = useRef<HTMLDivElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)

  const onVisibleDivMore = () => {
    const el = divMore.current
    const wrapper = wrapperRef.current
    if (el && wrapper) {
      const rect = el.getBoundingClientRect()
      const rectWrapper = wrapper.getBoundingClientRect()
      if (rect.bottom === rectWrapper.bottom) onChangePage()
      else if (rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)) onChangePage()
    }
  }

  const handleScroll = () => {
    onVisibleDivMore()
  };

  useEffect(() => {
    onVisibleDivMore()
  }, [])

  return (
    <ScrollWrapper onScroll={handleScroll} ourRef={wrapperRef}>
      <EventsList>
        {all.map((event, id) => <ShortEvent key={id} {...event}/>)}
        {(!isEnd && !isLoadingMore && all.length > 0) && <DivMore ref={divMore}/>}
      </EventsList>
    </ScrollWrapper>
  );
}

export default HomeEvents;
