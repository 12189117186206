import React from 'react';
import {useAuthState} from "../../store/auth/hooks";
import {useParams} from "react-router-dom";
import styled from "styled-components";
import {SpinnerIcon} from "../../UI/Svg";
import ContentLoader from "react-content-loader";

const LoadingIcon = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  width: 100%;

  svg {
    display: none;
    width: 44px;
    height: 44px;

    ${({theme}) => theme.mediaQueries.md} {
      display: flex;
    }
  }
`


const LoadingBlockStyled = styled.div`
  display: flex;
  flex-direction: column;

  ${({theme}) => theme.mediaQueries.md} {
    display: none;
  }
`


const Aside = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 343px;
  flex: 1;

  ${({theme}) => theme.mediaQueries.md} {
    display: none;
  }
`

const Main = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`


function LoadingProfile() {
  const {user} = useAuthState();
  const {id} = useParams();
  return (
    <>
      <Aside>
        <LoadingBlockStyled>
          <ContentLoader
            speed={2}
            width="100%"
            height={456}
            backgroundColor="#FFF"
            foregroundColor="#ecebeb"
            style={{borderRadius: '1rem'}}
          >
            <rect x="0" y="0" rx="0" ry="0" width="547" height="520"/>
          </ContentLoader>
        </LoadingBlockStyled>
      </Aside>
      <Main>
        <LoadingBlockStyled>
          <ContentLoader
            speed={2}
            width="100%"
            height={264}
            backgroundColor="#FFF"
            foregroundColor="#ecebeb"
            style={{borderRadius: '1rem', marginBottom: '2rem'}}
          >
            <rect x="0" y="0" rx="0" ry="0" width="939" height="427"/>
          </ContentLoader>
        </LoadingBlockStyled>
        {String(user?.id) !== id && <LoadingBlockStyled>
            <ContentLoader
                speed={2}
                width="100%"
                height={80}
                backgroundColor="#FFF"
                foregroundColor="#ecebeb"
                style={{borderRadius: '1rem', marginBottom: '2rem'}}
            >
                <rect x="0" y="0" rx="0" ry="0" width="939" height="427"/>
            </ContentLoader>
        </LoadingBlockStyled>}
        <LoadingBlockStyled>
          <ContentLoader
            speed={2}
            width="100%"
            height={638}
            backgroundColor="#FFF"
            foregroundColor="#ecebeb"
            style={{borderRadius: '1rem', marginBottom: '2rem'}}
          >
            <rect x="0" y="0" rx="0" ry="0" width="939" height="833"/>
          </ContentLoader>
        </LoadingBlockStyled>
      </Main>
      <LoadingIcon>
        <SpinnerIcon/>
      </LoadingIcon>
    </>
  );
}

export default LoadingProfile;
