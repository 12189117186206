import React, {useEffect} from 'react';
import {Outlet, useNavigate} from 'react-router-dom';
import styled from "styled-components";
import {useAuthState} from "../../store/auth/hooks";
import MenuAdmin from "../MenuAdmin";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  background: #F7F7FA;
`

const Main = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 40px 40px 40px 364px;
`

function Index() {
  const navigate = useNavigate();
  const {user} = useAuthState()

  useEffect(() => {
    if (!user) {
      navigate('/auth')
    } else if (user.role === 'user') {
      navigate('/events')
    }
  }, [navigate, user])

  return (
    <>
      <Wrapper>
        <MenuAdmin/>
        <Main>
          <Outlet/>
        </Main>
      </Wrapper>
    </>
  );
}

export default Index;
