import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {ProfileApi} from "../../api/profile";
import {handle} from "../../api";
import {EventResponse} from "../events/types";


const fetchMyEventsCreated = createAsyncThunk<EventResponse[],
  void,
  {
    state: RootState
  }>(
  'profile/fetchMyEventsCreated',
  async (_, ThunkApi) => {
    const authState = ThunkApi.getState().auth
    if (authState.auth) {
      const [eventsRes, eventsErr] = await handle(ProfileApi.getMyEventsCreated())
      if (eventsRes) {
        return eventsRes
      }
      if (eventsErr) {
        throw {message: eventsErr.message[0] || eventsErr.message}
      }
      throw {message: 'No user state'}
    }
  }
)

export default fetchMyEventsCreated
