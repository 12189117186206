import React, {useEffect, useMemo, useState} from 'react';
import {useChatAction, useChatState} from "../../store/chat/hooks";
import {useTranslate} from "@tolgee/react";
import styled from "styled-components";
import Input from "../../UI/Input";
import {SearchIcon, SpinnerIcon} from "../../UI/Svg";
import Contact from '../../components/ChatComponents/Contact';
import {IChat} from "../../store/chat/types";
import {UserEntity} from "../../types";
import {getUserName} from "../../utils/user";
import {useAuthState} from "../../store/auth/hooks";
import NotificationContact from "../../components/ChatComponents/NotificationContact";
import _ from "lodash";

const InputStyled = styled(Input)`
  background: ${({theme}) => theme.colors.white};
  height: 56px;

  ${({theme}) => theme.mediaQueries.md} {
    background: ${({theme}) => theme.colors.bg};
    height: 40px;
  }
`

const WrapperList = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
  padding: 16px;
  gap: 13px;
  background: ${({theme}) => theme.colors.white};
  ${({theme}) => theme.mediaQueries.md} {
    padding: 0;
  }
`

const List = styled.div<{ $isLoading: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  align-items: ${({$isLoading}) => $isLoading ? 'center' : 'flex-start'};
  justify-content: ${({$isLoading}) => $isLoading ? 'center' : 'flex-start'};
  overflow: auto;
  gap: 13px;


  ${({theme}) => theme.mediaQueries.md} {
    padding: 0;
    border-radius: 0;
  }
`

const LoadingBlock = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 44px;
    height: 44px;
  }
`
export const getTitleChat = (chat: IChat, user: UserEntity | null) => {
  if (chat.title) return chat.title
  if (chat.event) return chat.event.title
  if (!user) return 'No title'
  if (chat.members.length === 0) return 'No title'
  if (chat.members.length > 1 && chat.members[0].id !== user.id) {
    return getUserName(chat.members[0])
  }
  const otherUser = chat.members.filter((member) => member.id !== user.id)[0]
  if (otherUser) {
    return getUserName(otherUser)
  } else {
    return 'No title'
  }
}


function Contacts() {
  const {t} = useTranslate();
  const [search, setSearch] = useState<string>('');
  const {isLoading, chats} = useChatState()
  const {onJoinRoom} = useChatAction()
  const {user} = useAuthState()

  const [connectedChats, setConnectedChats] = useState<IChat[]>([])

  useEffect(() => {
    setConnectedChats(prevState => {
      return _.xorBy(chats, prevState, 'id')
    })
  }, [chats])

  useEffect(() => {
    for (const chat of connectedChats) {
      onJoinRoom(chat.id)
    }
  },[connectedChats, onJoinRoom])

  const filteredChats = useMemo(() => {
    return chats?.filter((item) => {
      return getTitleChat(item, user).toLowerCase().includes(search.toLowerCase())
    });
  }, [chats, user, search]);

  return (
    <>
      <InputStyled
        placeholder={t('common.search', 'Поиск')}
        onChange={(e) => setSearch(e.target.value)}
        wth="100%"
        icon={<SearchIcon/>}
      />
      <WrapperList>
        <NotificationContact/>
        <List $isLoading={isLoading}>
          {isLoading
            ? <LoadingBlock>
              <SpinnerIcon/>
            </LoadingBlock>
            : filteredChats.map((chat) => <Contact key={chat.id} {...chat}/>)
          }
        </List>
      </WrapperList>
    </>
  );
}

export default Contacts;
