import React from 'react';
import {T} from "@tolgee/react";
import {generateArrayFrom} from "../../utils";
import styled from "styled-components";

const array = generateArrayFrom(1, 16)

const TableWrapper = styled.div`
  width: 100%;
  overflow: auto;
`

const TableStyled = styled.table`
  th, td {
    border: 1px solid ${({theme}) => theme.colors.black};
    padding: 8px 12px;
    color: ${({theme}) => theme.colors.black};
  }
`

function PoliticsTable() {
  return (
    <TableWrapper>
      <TableStyled>
        <thead>
        <tr>
          <th>№</th>
          <th><T keyName={'politicsTable.header.first'}/></th>
          <th><T keyName={'politicsTable.header.second'}/></th>
          <th><T keyName={'politicsTable.header.third'}/></th>
        </tr>
        </thead>
        <tbody>
        {array.map((row) => <tr key={row}>
          <td>{row}.</td>
          <td><T keyName={`politicsTable.body.${row}.third`}/></td>
          <td><T keyName={`politicsTable.body.${row}.third`}/></td>
          <td><T keyName={`politicsTable.body.${row}.third`}/></td>
        </tr>)}
        </tbody>
      </TableStyled>
    </TableWrapper>
  );
}

export default PoliticsTable;
