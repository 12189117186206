import React, {useCallback, useRef} from 'react';
import styled from "styled-components";
import {Card} from "../Templates/Card";
import 'react-datepicker/dist/react-datepicker.css';
import {useEventsState} from "../../store/events/hooks";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {Button, EButtonVariants} from "../../UI/Button";
import {ArrowLeftMobileBlackIcon} from "../../UI/Svg";
import EventFilter from "../../views/HomeViews/EventFilter";

const CardStyled = styled(Card)`
  width: 100%;
  z-index: 101;
  height: 100vh;
  background: ${({theme}) => theme.colors.bg};
  gap: 10px;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 0 16px;

  ${({theme}) => theme.mediaQueries.md} {
    justify-content: space-between;
    background-color: ${({theme}) => theme.colors.white};
  }

`

const Header = styled.div`
  width: 100%;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({theme}) => theme.colors.white};
  position: relative;
`

const HeaderButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  padding-left: 16px;
`

const Buttons = styled.div`
  display: flex;
  padding: 0 16px;
`

const ButtonStyled = styled(Button)`
  justify-self: flex-end;
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  overflow: auto;
  background: ${({theme}) => theme.colors.white};
  padding-bottom: 16px;
`

const ButtonIcon = styled.button`
  width: 24px;
  height: 24px;
  border: none;
  background: ${({theme}) => theme.colors.white};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;

  &:active {
    opacity: .5;
  }

  svg {
    width: 18px;
    height: 18px;
  }
`

const ButtonReset = styled(Button)`
  border: none;
  color: ${({theme}) => theme.colors.black};
`

interface FilterModalProps {
  onClose: () => void;
}

function FilterMobile({onClose}: FilterModalProps) {

  const {all} = useEventsState()

  const refOnReset = useRef<() => void>(null)

  const handleResetFilter = useCallback(() => {
    if (refOnReset.current) refOnReset.current()
  }, [])


  return (
    <CardStyled>
      <Header>
        <HeaderButtons>
          <ButtonIcon onClick={onClose}>
            <ArrowLeftMobileBlackIcon/>
          </ButtonIcon>

          <ButtonReset
            variant={EButtonVariants.Secondary}
            onClick={handleResetFilter}
          >
            Сбросить
          </ButtonReset>
        </HeaderButtons>
        <Text
          variant={ETextVariants.Heading}
        >
          Фильтр
        </Text>

      </Header>
      <Content>
        <EventFilter refOnReset={refOnReset}/>
      </Content>
      <Buttons>
        <ButtonStyled $fullWidth variant={EButtonVariants.Primary}
                      onClick={onClose}>
          Показать {all?.length}
        </ButtonStyled>
      </Buttons>
    </CardStyled>
  );
}

export default FilterMobile;
