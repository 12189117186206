import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} >
          <rect width="24" height="24" rx="12" fill="#EDEDED"/>
          <path d="M13.7503 10.2503H13.7562M10.2503 10.2503H10.2562M17.8337 12.0003C17.8337 15.222 15.222 17.8337 12.0003 17.8337C8.77866 17.8337 6.16699 15.222 6.16699 12.0003C6.16699 8.77866 8.77866 6.16699 12.0003 6.16699C15.222 6.16699 17.8337 8.77866 17.8337 12.0003ZM14.042 10.2503C14.042 10.4114 13.9114 10.542 13.7503 10.542C13.5892 10.542 13.4587 10.4114 13.4587 10.2503C13.4587 10.0892 13.5892 9.95866 13.7503 9.95866C13.9114 9.95866 14.042 10.0892 14.042 10.2503ZM10.542 10.2503C10.542 10.4114 10.4114 10.542 10.2503 10.542C10.0892 10.542 9.95866 10.4114 9.95866 10.2503C9.95866 10.0892 10.0892 9.95866 10.2503 9.95866C10.4114 9.95866 10.542 10.0892 10.542 10.2503ZM12.0003 15.2087C13.459 15.2087 14.6253 14.1394 14.6253 13.167H9.37533C9.37533 14.1394 10.5417 15.2087 12.0003 15.2087Z" stroke="#6C27AB" strokeLinecap="round" strokeLinejoin="round"/>
        </Svg>
    );

export default Icon;
