import React, {useState} from 'react';
import {FirmsIcon, GeoIcon, MailIcon, PhoneIcon, QRcodeIcon, SiteIcon} from "../../UI/Svg";
import {ETextVariants} from "../../UI/Text/types";
import styled from "styled-components";
import {Card} from "../../components/Templates/Card";
import Text from "../../UI/Text";
import {ICompany, IUserCompany} from "../../store/company/types";
import {useCompanyState} from "../../store/company/hooks";
import {Button, EButtonVariants} from "../../UI/Button";
import ModalQRReader from "../../components/Modals/ModalQRReader";
import {T} from '@tolgee/react';


const DescriptionCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
`

const DescriptionItem = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;

  svg {
    width: 16px;
    height: 16px;

    path {
      stroke: ${({theme}) => theme.colors.textGray};
    }
  }
`

const DescriptionItemText = styled(Text)`
  color: ${({theme}) => theme.colors.textGray};
  line-height: 20px; /* 125% */
  letter-spacing: -0.24px;
`

const Buttons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
`
export const QRButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;

  ${({theme}) => theme.mediaQueries.md} {
    display: flex;
  }
`

function CompanyDescriptionView(company: IUserCompany | ICompany) {
  const {selectedUserCompany} = useCompanyState()
  const [isShowScanner, setIsShowScanner] = useState<boolean>(false);
  return (
    <>
      <DescriptionCard>
        {(company.showCompanyContacts || selectedUserCompany?.id === company.id) && <>{
          company.address && <DescriptionItem>
                <GeoIcon/>
                <DescriptionItemText variant={ETextVariants.Body16} as={'p'}>
                  {company.address?.fullString}
                </DescriptionItemText>
            </DescriptionItem>
        }
          <DescriptionItem>
            <PhoneIcon/>
            <DescriptionItemText variant={ETextVariants.Body16} as={'p'}>
              {company.phoneNumber}
            </DescriptionItemText>
          </DescriptionItem>
          {company.site && <DescriptionItem>
              <SiteIcon/>
              <DescriptionItemText variant={ETextVariants.Body16} as={'p'}>
                {company.site}
              </DescriptionItemText>
          </DescriptionItem>}
          <DescriptionItem>
            <MailIcon/>
            <DescriptionItemText variant={ETextVariants.Body16} as={'p'}>
              {company.email}
            </DescriptionItemText>
          </DescriptionItem>
        </>}
        <DescriptionItem>
          <FirmsIcon/>
          <DescriptionItemText variant={ETextVariants.Body16} as={'p'}>
            {company.description}
          </DescriptionItemText>
        </DescriptionItem>
        {company.id === selectedUserCompany?.id && <Buttons>
            <Button variant={EButtonVariants.Primary} disabled $fullWidth>
                <T keyName="company.description.statistic">Статистика</T>
            </Button>

            <QRButton onClick={() => setIsShowScanner(true)}>
                <QRcodeIcon/>
            </QRButton>
        </Buttons>}
      </DescriptionCard>
      {isShowScanner && <ModalQRReader onClose={() => setIsShowScanner(false)}/>}
    </>
  );
}

export default CompanyDescriptionView;
