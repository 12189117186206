import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {AcquaintanceApi} from "../../api/acquaintance";
import {UserEntity} from "../../types";

const fetchPostLike = createAsyncThunk<void,
  number,
  {
    state: RootState
  }>(
  'acquaintance/fetchPostLike',
  async (id, ThunkApi) => {
    const authState = ThunkApi.getState().auth
    if (authState.auth) {
      const [likeRes, likeErr] = await handle(AcquaintanceApi.postLike(id))
      if (likeRes) return
      if (likeErr) {
        throw {message: likeErr.message || likeErr.message[0]}
      }
    }
    throw {message: 'No like state'}
  }
)

export default fetchPostLike
