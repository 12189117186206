import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {EventsApi} from "../../api/events";

const fetchCreateCategory = createAsyncThunk<void,
  string,
  {
    state: RootState
  }>(
  'events/fetchCreateCategory',
  async (title, ThunkApi) => {
    const authState = ThunkApi.getState().auth
    if (authState.auth) {
      const [categoriesRes, categoriesErr] = await handle(EventsApi.postCategories(title))
      if (categoriesRes) {
        return categoriesRes
      }
      if (categoriesErr) {
        throw {message: categoriesErr.message[0] || categoriesErr.message}
      }
    }
    throw {message: 'No user state'}
  }
)

export default fetchCreateCategory
