import React from 'react';
import {ThemeProvider} from 'styled-components';
import {BrowserRouter} from "react-router-dom";
import {light} from "./theme";
import {store} from "./store";
import {Provider} from "react-redux";
import {enUS, lv, ru} from "date-fns/locale";
import {registerLocale} from "react-datepicker";
import {initTolgee} from "./utils/tolgee";
import {TolgeeProvider} from "@tolgee/react";


interface Props {
  children: React.ReactNode
}


registerLocale('en', enUS);
registerLocale('ru', ru);
registerLocale('lv', lv);

const tolgee = initTolgee();

function Providers({children}: Props) {
  return (
    <Provider store={store}>
      <ThemeProvider theme={light}>
        <TolgeeProvider tolgee={tolgee} fallback="Tolgee loading...">
          <BrowserRouter>
            {children}
          </BrowserRouter>
        </TolgeeProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default Providers;
