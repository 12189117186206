import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {T, useTranslate} from "@tolgee/react";
import {FileUploader} from "react-drag-drop-files";
import {AddDNDIcon, TrashIcon} from "../../UI/Svg";
import Select from "../../UI/Select";
import {DataPicker} from "../../UI/DataPicker";
import {Button, EButtonVariants} from "../../UI/Button";
import {useForm} from "react-hook-form";
import {initialStateFormTypes} from "../../types/form";
import {EventResponse, IPostCreateEvent} from "../../store/events/types";
import {handle, mediaUrl} from "../../api";
import {EventsApi} from "../../api/events";
import {useEventsAction, useEventsState} from "../../store/events/hooks";
import CreateEventModal from "../../components/Modals/CreateEventModal";
import {
  FormStyled,
  InputStyled,
  InpuRow,
  UploadStyled,
  UploadText,
  StyledCheckbox,
  ErrorForm,
  Label,
  TextAreaStyled,
  TAMini,
  FileTitle,
  HomeMapStyled, ImagePreview, DeleteHeader
} from './styled'
import {useAcquaintanceState} from "../../store/acquaintance/hooks";
import {useNavigate} from "react-router-dom";
import {Checkbox} from "../../UI/Input/Checkbox";
import {handleImageUpload} from "../../utils";


const fileTypes = ['JPEG', 'PNG', 'JPG'];

export const initialStateForm: initialStateFormTypes = {
  position: [0, 0],
  loadFile: null,
  textArea: '',
  startDate: null,
  addressMap: {country: '', city: '', detail: ''},
  categoriesChecked: [],
};


function FormEventView({
                         isCreate = true,
                         isInvite,
                         initialData,
                         callback
                       }: { isCreate?: boolean, initialData?: EventResponse, callback?: () => void, isInvite?: boolean }) {
  const {suggestion} = useAcquaintanceState()
  const navigate = useNavigate()
  const {t} = useTranslate();
  const {categories} = useEventsState()
  const {onFetchCategories} = useEventsAction()
  const select = useRef(null);
  const textAreaRef = useRef<HTMLDivElement | null>(null);
  const refDnD = useRef<HTMLDivElement | null>(null);
  const addressRef = useRef<HTMLInputElement>(null);
  const [isPending, setIsPending] = useState<boolean>(false);


  const [isUnlimited, setIsUnlimited] = useState<boolean>(false);
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [bodyModal, setBodyModal] = useState<{ title: string, body: string }>(
    {title: '', body: ''}
  );
  useEffect(() => {
    onFetchCategories()
  }, [onFetchCategories])

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: {errors},
  } = useForm({
    defaultValues: {
      maxNumberOfPeople: initialData?.maxMembers ? initialData?.maxMembers : 0,
      title: initialData?.title || '',
    }
  });


  const initialMemo: initialStateFormTypes = useMemo(() => {
    if (initialData) {
      return {
        categoriesChecked: initialData.categories ? initialData.categories.map((category) => String(category.id)) : [],
        position: initialData.address ? [parseFloat(initialData.address?.latitude), parseFloat(initialData.address?.latitude)] : [0, 0],
        loadFile: null,
        textArea: initialData.description,
        startDate: initialData.date ? new Date(initialData.date) : null,
        addressMap: initialData.address ? {
          country: initialData.address.country,
          city: initialData.address.city,
          detail: initialData.address.fullString
        } : {country: '', city: '', detail: ''},
      }
    }
    return initialStateForm
  }, [initialData])

  const [formState, setFormState] = useState<{ data: initialStateFormTypes, error: string }>({
    data: initialMemo,
    error: ''
  });

  const onResetFormState = () => setFormState({
    data: initialStateForm,
    error: ''
  })

  const onChangeFormState = (key: string, val: any) => {
    setFormState(prevState => ({
      ...prevState,
      data: {
        ...prevState.data,
        [key]: val
      },
      error: ''
    }))
  }
  const onChangeFormError = (error: string) => {
    setFormState(prevState => ({
      ...prevState,
      error: error
    }))
  }

  const handleChange = (file: File): void => {
    onChangeFormState('loadFile', file)
  };

  const checkedCategories = useCallback((e: any): void => {
    const {target} = e;
    const category = target.value;
    const isChecked = formState.data.categoriesChecked.includes(category);

    if (category && !isChecked) {
      const categoriesNew = [...formState.data.categoriesChecked, category];
      onChangeFormState('categoriesChecked', categoriesNew)
    } else if (isChecked) {
      const categoriesNew = formState.data.categoriesChecked.filter((id: string) => id !== category);
      onChangeFormState('categoriesChecked', categoriesNew)
    }

    target.value = '';
  }, [formState.data.categoriesChecked]);

  useEffect(() => {
    if (initialData) {
      setValue('maxNumberOfPeople', initialData?.maxMembers ? initialData?.maxMembers : 0)
      setValue('title', initialData?.title || '')
      setFormState({
        data: {
          categoriesChecked: initialData.categories ? initialData.categories.map((category) => String(category.id)) : [],
          position: initialData.address ? [parseFloat(initialData.address?.latitude), parseFloat(initialData.address?.latitude)] : [0, 0],
          loadFile: null,
          textArea: initialData.description,
          startDate: initialData.date ? new Date(initialData.date) : null,
          addressMap: initialData.address ? {
            country: initialData.address.country,
            city: initialData.address.city,
            detail: initialData.address.fullString
          } : {country: '', city: '', detail: ''},
        },
        error: ''
      })
      setIsUnlimited(initialData.maxMembers === 0)
    }
  }, [initialData, setValue])

  const categoriesText = categories
    ?.map((tag) => (formState.data.categoriesChecked.includes(`${tag.id}`)
      ?
      t(`category.list.${tag.id}`, tag.title) : null))
    .filter(Boolean)
    .join(', ');

  useEffect(() => {
    const addressValue = addressRef.current;
    if (addressValue) addressValue.value = formState.data.addressMap.detail;
  }, [formState.data.addressMap.detail]);

  const onInvite = useCallback(async (id: string) => {
    if (suggestion) {
      const [inviteRes, inviteErr] = await handle(EventsApi.postInviteEvent(id, suggestion.id))
      if (inviteRes) navigate('/acquaintance')
      if (inviteErr) console.log(inviteErr);
    }
  }, [navigate, suggestion])


  useEffect(() => {
    if (isUnlimited) setValue('maxNumberOfPeople', 0)
  }, [isUnlimited])

  const onSubmit = useCallback(async (data: any): Promise<any> => {
    setIsPending(true)
    onChangeFormError('');
    const maxNumberOfPeople = Number(data.maxNumberOfPeople || '0');

    const errorHandler = (message: string): void => onChangeFormError(message);

    const {loadFile, categoriesChecked, position, textArea, startDate} = formState.data

    const compressedFile = await handleImageUpload(loadFile)
    if (!compressedFile && isCreate) {
      errorHandler('null');
      refDnD.current?.scrollIntoView({behavior: 'smooth', block: 'start'});
      setIsPending(false)
      return;
    }
    if (!textArea.length) {
      errorHandler(t('eventForm.error.description', 'Введите описание ивента!'));
      textAreaRef.current?.scrollIntoView({behavior: 'smooth', block: 'start'});
      setIsPending(false)
      return;
    }
    if (maxNumberOfPeople < 1 && !isUnlimited) {
      errorHandler(t('eventForm.error.maxNumbers', 'Количество участвующих не должно быть меньше 1!'));
      setIsPending(false)
      return;
    }
    if (!addressRef.current?.value) {
      errorHandler(t('eventForm.error.location', 'Выберите место на карте!'));
      setIsPending(false)
      return;
    }
    if (!startDate) {
      errorHandler(t('eventForm.error.date', 'Укажите дату и время!'));
      setIsPending(false)
      return;
    }

    const obj: IPostCreateEvent = {
      ...data,
      description: textArea,
      maxMembers: Number(maxNumberOfPeople),
      categoryIds: categoriesChecked.map(Number),
      date: new Date(startDate),
      latitude: position[0],
      longitude: position[1]
    };

    const [eventRes, eventErr] = await handle((!isCreate && initialData)
      ? EventsApi.putUpdateEvent(initialData.id, obj)
      : EventsApi.postCreateEvent(obj)
    )

    if (eventErr) {
      setBodyModal({
        title: t('common.error', 'Ошибка'),
        body: (isCreate ? t('eventForm.error.create', `Ошибка при создании,проверьте заполнение всех полей`) : t('eventForm.error.update', 'Ошибка при редактировани,проверьте заполнение всех полей')) + ` ${eventErr.message}`
      });
      setIsShowModal(true);
      setIsPending(false);
      console.log('eventErr', eventErr.message)
      return
    }

    if (eventRes && compressedFile) {
      if (isInvite) {
        onInvite(eventRes.id)
      }
      const [_, fileErr] = await handle(EventsApi.putPhotoEvent(eventRes.id, compressedFile))
      if (fileErr) {
        setBodyModal({
          title: t('common.errorImage', 'Ошибка загрузки изображения'),
          body: fileErr.message
        });
        onChangeFormState('loadFile', null)
        setIsShowModal(true);
        setIsPending(false)
        if (isCreate) {
          // await handle(EventsApi.deleteUserEvent(eventRes.id))
        }
        return
      }
    }
    if (callback) callback()
    if (isCreate) {
      onResetFormState()
      reset();
      navigate(`/about-the-event/${eventRes.id}`)
    }
    setBodyModal({
      title: isCreate ? t('eventForm.create.title', 'Ивент создан') : t('eventForm.update.title', 'Ивент обновлен'),
      body: isCreate
        ? t('eventForm.create.text', 'Ваш ивент находится на проверке. После согласования мы направим вам уведомление.')
        : t('eventForm.update.text', 'Можете посмотреть его у себя в профиле'),
    });
    setIsShowModal(true);
    setIsPending(false)
  }, [callback, formState.data, initialData, isCreate, isInvite, isUnlimited, onInvite, reset, t]);
  return (
    <>
      <FormStyled onSubmit={handleSubmit(onSubmit)}>
        <InpuRow>
          <Text variant={ETextVariants.Body16}>
            <T keyName="eventForm.fields.name.label">Название ивента</T>
          </Text>
          <InputStyled
            placeholder={t('eventForm.fields.name.placeholder', "Введите название ивента")}
            wth="100%"
            error={errors.title ? t('eventForm.error.title', "Название должно быть не меньше трех символов") : undefined}
            {...register('title', {
              required: true,
              pattern: /[a-zA-Zа-яА-Я0-9 ]{3,50}/,
            })}
            aria-invalid={errors.title ? 'true' : 'false'}
          />
        </InpuRow>
        <InpuRow ref={refDnD}>
          <FileUploader
            handleChange={(file: File) => handleChange(file)}
            name="file"
            types={fileTypes}
            onTypeError={(err: string) => onChangeFormError(err)}
            maxSize="10"
            onSizeError={(file: string) => onChangeFormError(file)}
          >
            <UploadStyled>
              {formState.data.loadFile ?
                <ImagePreview src={URL.createObjectURL(formState.data.loadFile)}/>
                : initialData?.photo ?
                  <>
                    <ImagePreview src={initialData.photo ? `${mediaUrl}file/${initialData.photo.id}` : ''}/>
                    <DeleteHeader>
                      <TrashIcon/>
                    </DeleteHeader>
                  </>
                  : <>
                    <AddDNDIcon/>
                    <UploadText
                      variant={ETextVariants.Body16}
                    >
                      <T keyName="eventForm.fields.cover.title">Добавить обложку</T>
                    </UploadText>
                  </>}
            </UploadStyled>
          </FileUploader>
          {formState.error === 'File type is not supported' && (
            <ErrorForm variant={ETextVariants.Error}>
              <T keyName="eventForm.fields.cover.errors.typeIsNotSupported">Такой тип файла не подходит, допустимые
                файлы формата JPEG,PNG!</T>
            </ErrorForm>
          )}
          {formState.error === 'File size is too big' && (
            <ErrorForm variant={ETextVariants.Error}>
              <T keyName="eventForm.fields.cover.errors.sizeIsToBig">Большой размер файла, размер не должен превышать
                10МБ!</T>
            </ErrorForm>
          )}
          {formState.error === 'null' &&
              <ErrorForm variant={ETextVariants.Error}>
                  <T keyName="eventForm.fields.cover.errors.null">Добавьте обложку!</T>
              </ErrorForm>}
          <FileTitle variant={ETextVariants.Body16}>
            {
              formState.data.loadFile
                ? <T keyName="eventForm.fields.cover.value"
                     defaultValue={`Имя обложки: ${formState.data.loadFile.name}`}
                     params={{name: formState.data.loadFile.name}}/>
                : ''
            }
          </FileTitle>
        </InpuRow>
        <InpuRow>
          <Text variant={ETextVariants.Body16} ref={textAreaRef}>
            <T keyName="eventForm.fields.description.label">Описание ивента</T>
          </Text>
          <TAMini variant={ETextVariants.MiniGray}>
            {formState.data.textArea.length}/2000
          </TAMini>
          <TextAreaStyled
            onChange={(e) => onChangeFormState('textArea', e.target.value.substring(0, 2000))}
            minRows={2}
            value={formState.data.textArea}
            placeholder={t('eventForm.fields.description.placeholder', 'Опишите мероприятие')}
          />
          {formState.error === t('eventForm.errors.description', 'Введите описание ивента!') &&
              <ErrorForm variant={ETextVariants.Error}>{formState.error}</ErrorForm>}
        </InpuRow>
        <InpuRow>
          <Text variant={ETextVariants.Body16}>
            <T keyName="eventForm.fields.category.label">Категория</T>
          </Text>
          <Select ref={select} colorArrow style={{height: '4.5rem'}} onChange={(e) => checkedCategories(e)}>
            <option value="">{categoriesText || t('eventForm.fields.category.default', 'Выберите категории')}</option>
            {categories?.map((item) => (
              <option value={`${item.id}`} key={item.id}>
                <T keyName={`category.list.${item.id}`}>{item.title}</T>
              </option>
            ))}
          </Select>
        </InpuRow>
        <InpuRow>
          <Text variant={ETextVariants.Body16}>
            <T keyName="eventForm.fields.maxNumbers.label">Количество людей</T>
          </Text>
          {!isUnlimited && <><InputStyled
              placeholder={t('eventForm.fields.maxNumbers.placeholder', '13')}
              wth="100%"
              type='number'
              error={errors.maxNumberOfPeople?.type === 'required'
                ? t('inputs.required', 'Это поле обязательно для заполнения')
                : errors.maxNumberOfPeople?.type === 'max'
                  ? String(errors.maxNumberOfPeople?.message)
                  : ''}
              {...register('maxNumberOfPeople', {
                required: true, valueAsNumber: true
              })}
          />
          </>}
          <Checkbox label={t('eventForm.fields.unlimited.label', 'Неограниченное количество участников')}
                    checked={isUnlimited} disabled={!isCreate}
                    onChange={event => setIsUnlimited(event.target.checked)} id="unlimited"/>
        </InpuRow>

        <InpuRow>
          <Text variant={ETextVariants.Body16}>
            <T keyName="eventForm.fields.date.label">Дата и время ивента</T>
          </Text>
          <DataPicker
            startDate={formState.data.startDate}
            bg={true}
            typeArrowPurple={true} isClearButton={false}
            mobileVersion={false}
            setStartDate={(date: any) => onChangeFormState('startDate', date)}
          />
          {formState.error === t('eventForm.errors.date', 'Укажите дату и время!') &&
              <ErrorForm variant={ETextVariants.Error}>{formState.error}</ErrorForm>}
        </InpuRow>
        <InpuRow>
          <Text variant={ETextVariants.Body16}>
            <T keyName="eventForm.fields.location.label">Выберите место на карте</T>
          </Text>
          <HomeMapStyled
            createEvent
            position={formState.data.position}
            center={[55.751244, 37.618423]}
            setPosition={val => onChangeFormState('position', val)}
            setAddress={val => onChangeFormState('addressMap', val)}
          />
          {formState.error === t('eventForm.errors.location', 'Выберите место на карте!') &&
              <ErrorForm variant={ETextVariants.Error}>{formState.error}</ErrorForm>}
        </InpuRow>
        <InpuRow>
          <Text variant={ETextVariants.Body16}>
            <T keyName="eventForm.fields.location.valueLabel">Место проведения</T>
          </Text>
          <InputStyled
            placeholder={t('eventForm.fields.location.placeholder', 'Например, Тверская улица, дом 15, кв 43')}
            wth="100%"
            ref={addressRef}
            disabled={!formState.data.addressMap.detail}
          />
        </InpuRow>
        <InpuRow>
          <StyledCheckbox type="checkbox" id="myCheckbox" defaultChecked name="myCheckbox"/>
          <Label as={'label'} variant={ETextVariants.Body16} htmlFor={'myCheckbox'}>
            <T keyName="eventForm.fields.contacts.label">Опубликовать мои контакты</T>
          </Label>
        </InpuRow>
        <Button variant={EButtonVariants.Primary} $fullWidth type="submit" disabled={isPending}>
          {
            isInvite
              ? <T keyName="eventForm.buttons.createAndInvite">Создать и пригласить</T>
              : !isCreate
                ? <T keyName="common.update">Обновить</T>
                : <T keyName="common.create">Создать</T>
          }
        </Button>
      </FormStyled>
      {isShowModal &&
          <CreateEventModal isModalClose={() => setIsShowModal(false)} title={bodyModal.title} body={bodyModal.body}/>}
    </>
  );
}

export default FormEventView;
