import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useEventsState} from "../../store/events/hooks";
import markerPurple from '../../assets/img/marker-color.png';
import markerGray from '../../assets/img/marker-gary.png';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import {Card} from "../../components/Templates/Card";
import styled from "styled-components";
import {useLocation, useSearchParams} from "react-router-dom";
import {Circle, MapContainer, Marker, TileLayer, useMap, useMapEvents} from 'react-leaflet';
import './popup.style.css';
import {EventsMapApi} from "../../api/eventsMap";
import MapMarkers from "../../components/HomeComponents/MapMarkers";
import {EventResponse} from "../../store/events/types";
import MapMarkersServices, {iconPurple} from "../../components/HomeComponents/MapMarkersServices";
import {ServiceResponse} from "../../store/adminServices/types";
import {useServicesState} from "../../store/services/hooks";
import {useTranslate} from "@tolgee/react";

const Wrapper = styled(Card)`
  border-radius: 20px;
  z-index: 0;
  width: 100%;

  ${({theme}) => theme.mediaQueries.md} {

  }
`


const defaultProps = {
  createEvent: false,
  position: [0, 0],
  setPosition: (item: any) => {
    return item;
  },
  setAddress: (item: any) => {
    return item;
  },
};

const MapContainerStyled = styled(MapContainer)`
  max-height: 800px;
  height: 100%;
  width: 100%;

  ${({theme}) => theme.mediaQueries.md} {
    height: 60vh;
    margin: 0 auto;
  }
`

type TypeProps = {
  createEvent?: boolean;
  eventsData?: EventResponse[]
  servicesData?: ServiceResponse[]
  position?: L.LatLngExpression;
  center: L.LatLngExpression;
  setPosition?: (value: any) => void;
  setAddress?: (value: any) => void;
  isService?: boolean
  radius?: number
  isCenter?: boolean
} & typeof defaultProps;

function HomeMap({
                   createEvent,
                   position,
                   center,
                   eventsData,
                   servicesData,
                   isService,
                   setPosition,
                   setAddress, radius,
                   isCenter,
                   ...props
                 }: TypeProps) {
  const {t} = useTranslate()
  const {filter} = useEventsState()
  const {filter: filterService} = useServicesState()
  const [activeMarker, setActiveMarker] = useState<string | null>(null);
  const [isViewed, setIsViewed] = useState<boolean>(false);
  const checkedEvent = useRef<any>();
  const isUserCenter = useRef<boolean>(!!isCenter)
  const mapRef = useRef<any>(null);
  const range = useMemo(() => {
    return radius || (isService ? filterService?.radius : filter?.radius) || '0'
  }, [filter?.radius, filterService?.radius, isService, radius])

  const {pathname} = useLocation();
  const path = pathname.split('/').filter((item) => !!item);
  const handlerMarker = (id: string | null): void => {
    setActiveMarker(id);
  };

  const LocationFinderDummy = () => {
    useMapEvents({
      locationfound: (e) => {
        // console.log('locationfound',e);
      },
      click: async (e) => {
        const {latlng} = e;
        setAddress({
          country: '',
          city: '',
          detail: t('homeMap.processing', `Получаем точное местоположение...`),
        });
        try {
          const {data} = await EventsMapApi.getGeo(latlng.lat, latlng.lng);
          const detail = [
            data.address.shop,
            data.address.leisure,
            data.address.tourism,
            data.address.building,
            data.address.amenity,
            data.address.road,
            data.address.house_number,
          ]
            .filter(Boolean)
            .join(', ');

          setAddress({
            country: data.address.country,
            city: data.address.city || data.address.state,
            detail,
          });
          setPosition([latlng.lat, latlng.lng]);
        } catch (error) {
          console.error(error);
        }
      },
    });
    return null;
  };
  const LocationPosition = () => {
    useMapEvents({
      click: async (e) => {
        const {latlng} = e;
        try {
          setPosition([latlng.lat, latlng.lng]);
        } catch (error) {
          console.error(error);
        }
      },
    });
    return null;
  };


  const getUserLocation = () => {
    if (mapRef.current) {
      const map = mapRef.current
      map.locate().on("locationfound", function (e: any) {
        setPosition([
          e.latlng.lat,
          e.latlng.lng,
        ])
        map.flyTo(e.latlng, 14);
        isUserCenter.current = true
      });
    }
  }

  function LocationMarker() {
    const mMap: L.Map = useMap();
    mMap.whenReady(() => {
      mapRef.current = mMap;
      if (!isUserCenter.current) {
        getUserLocation()
      }
    });
    return null;
  }

  const onClickShowMarker = useCallback(() => {
    const map = mapRef.current;

    if (!map) {
      return;
    }

    const marker = checkedEvent.current;
    console.log('marker', marker)
    // {
    //     lat:55.7280767585615,
    //     lng:37.641434669494636
    // }
    if (marker) {
      map.flyTo(marker._latlng, 14);
      marker.openPopup();
      setIsViewed(true);
    }
  }, []);
  const [searchParams] = useSearchParams()

  useEffect(() => {
  }, [searchParams])

  useEffect(() => {

    const isEvent = searchParams.get('is-event')
    const eventId = searchParams.get('id')
    if (isEvent !== undefined && !isViewed && eventId) {
      isUserCenter.current = true
      handlerMarker(eventId);
      setTimeout(() => {
        onClickShowMarker()
      }, 1000)
    }
  }, [searchParams, isViewed, onClickShowMarker]);

  useEffect(() => {
    return () => setIsViewed(false);
  }, []);
  return (

    <Wrapper {...props}>
      {!createEvent ? (
        <MapContainerStyled
          center={center}
          zoom={11}
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
          {range !== '0' && (
            <Circle center={position} pathOptions={{color: '#F5F5F5', fillColor: '#576B72'}} radius={Number(range)}/>
          )}
          {
            isService
              ? <MapMarkersServices
                activeMarker={activeMarker}
                handlerMarker={handlerMarker}
                setActiveMarker={setActiveMarker}
                checkedEvent={checkedEvent}
                servicesData={servicesData}
              />
              : <MapMarkers
                activeMarker={activeMarker}
                handlerMarker={handlerMarker}
                setActiveMarker={setActiveMarker}
                checkedEvent={checkedEvent}
                eventsData={eventsData}
              />
          }
          <LocationPosition/>
          <LocationMarker/>
        </MapContainerStyled>
      ) : (
        <MapContainer center={center} zoom={12} style={{height: '40rem', width: '100%'}}>
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
          {range !== '0' && (
            <Circle center={position} pathOptions={{color: '#F5F5F5', fillColor: '#576B72'}} radius={Number(range)}/>
          )}
          <Marker icon={iconPurple} position={position}/>
          <LocationFinderDummy/>
          <LocationMarker/>
        </MapContainer>
      )}
    </Wrapper>
  );
}

HomeMap.defaultProps = defaultProps;
export default HomeMap;
