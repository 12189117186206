import React, {useCallback, useRef, useState} from 'react';
import styled from "styled-components";
import Text from "../../UI/Text";
import {FileUploader} from 'react-drag-drop-files';
import {ETextVariants} from "../../UI/Text/types";
import {Button, EButtonVariants} from "../../UI/Button";
import {T} from "@tolgee/react";

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  padding-top: 60px;
  z-index: 10;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    content: '';
    position: absolute;
    background: ${({theme}) => theme.colors.transparentGray};
    width: 100%;
    height: 100%;
    padding-bottom: 100%;
    top: 0;
  }

  ${({theme}) => theme.mediaQueries.md} {
    //position: absolute;
    border-radius: 0;
    width: 100%;
    height: 100%;
    padding-top: 0;
  }
`

const ModalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1076px;
  z-index: 100;
  background: ${({theme}) => theme.colors.bg};
  label {
    width: 100%;
  }

  ${({theme}) => theme.mediaQueries.sm} {
    max-width: 300px;
  }
`
const ModalInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 6px;
  padding: 20px;
  height: 346px;
  background: ${({theme}) => theme.colors.white};

  ${({theme}) => theme.mediaQueries.sm} {
    max-width: 300px;
  }
`
const Content = styled.div`
  border-radius: 8px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 60px;
  border: 1px dashed ${({theme}) => theme.colors.lightGray3};
`

const Title = styled.p`
  font-size: 38px;
  display: inline;
  text-align: center;
  line-height: 1;
  font-weight: 500;
  color: ${({theme}) => theme.colors.lightGray3};
`

const ErrorForm = styled(Text)`

`

const ButtonStyled = styled(Button)`
  width: 346px;
  padding: 20.5px 50px;
  font-size: 20px;
  ${({theme}) => theme.mediaQueries.md} {
    width: auto;
  }
`

const fileTypes = ['JPEG', 'PNG', 'JPG'];

function ModalPostPhoto({onChange, onClose}: { loadFile: File | null, onChange: (file: File) => void, onClose: () => void }) {

  const [error, setError] = useState<string | null>(null);

  const modal = useRef(null)

  const onCloseHandler = useCallback((event: any) => {
    if (event.target === modal.current) {
      onClose()
    }
  }, [onClose])

  return (
    <Wrapper ref={modal} onClick={onCloseHandler}>
      <ModalWrapper>
        <FileUploader
          handleChange={(file: File) => {
            onChange(file)
            setError(null)
          }}
          name="file"
          types={fileTypes}
          onTypeError={(err: string) => setError(err)}
          maxSize="10"
          onSizeError={(file: string) => setError(file)}
        >
          <ModalInner>
            <Content>
              <Title><T keyName="modalPost.title">Бросьте файл для загрузки сюда</T></Title>
              <ButtonStyled variant={EButtonVariants.Primary}><T keyName="modalPost.button">Загрузить файл</T></ButtonStyled>
            {error === 'File type is not supported' && (
              <ErrorForm variant={ETextVariants.Error}>
                <T keyName="modalPost.errors.typeIsNotSupported">Такой тип файла не подходит, допустимые файлы формата JPEG,PNG!</T>
              </ErrorForm>
            )}
            {error === 'File size is too big' && (
              <ErrorForm variant={ETextVariants.Error}>
                <T keyName="modalPost.errors.sizeIsToBig">Большой размер файла, размер не должен превышать 10МБ!</T>
              </ErrorForm>
            )}

            </Content>
          </ModalInner>
        </FileUploader>
      </ModalWrapper>
    </Wrapper>
  );
}

export default ModalPostPhoto;
