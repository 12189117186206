import React, {useCallback, useMemo} from 'react';
import styled from "styled-components";
import {ArrowLeftIcon, DotsIcon} from "../../UI/Svg";
import {useNavigate} from "react-router-dom";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";


const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 16px;
  border-bottom: 0.5px solid ${({theme}) => theme.colors.mainGray};
  background: ${({theme}) => theme.colors.white};
  z-index: 10;

  ${({theme}) => theme.mediaQueries.md} {
    position: fixed;
    top: 0;
  }
`


const ButtonIcon = styled.div`
  cursor: pointer;

  svg {
    min-width: 24px;
    height: 24px;
  }

  ${({theme}) => theme.mediaQueries.md} {
  }
`

const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 14px;
`

const AvatarMobile = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  overflow: hidden;
  border-radius: 50%;
  background: #CC5151;
  ${({theme}) => theme.mediaQueries.md} {
    display: flex;
  }
`

const Center = styled.div`
  display: flex;
  ${({theme}) => theme.mediaQueries.md} {
    align-items: center;
  }
`

const WrapCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  ${({theme}) => theme.mediaQueries.md} {
    flex: 1;
  }
`

const Title = styled(Text)`
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  display: inline;
`

function HeaderNotificationsView() {
  const navigate = useNavigate()

  return (
    <Header>
      <ButtonIcon onClick={() => navigate('/chat')}>
        <ArrowLeftIcon/>
      </ButtonIcon>
      <WrapCenter>
        <AvatarMobile/>
        <Center>
          <Title variant={ETextVariants.Body16}>Уведомления</Title>
        </Center>
      </WrapCenter>
      <Right/>
    </Header>
  );
}

export default HeaderNotificationsView;
