import React, {useCallback, useRef} from 'react';
import {Link} from "react-router-dom";
import styled from "styled-components";
import {Card} from "../Templates/Card";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {EventResponse} from "../../store/events/types";
import {CalendarIcon, GeoGrayIcon} from "../../UI/Svg";

import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import {mediaUrl} from "../../api";
import {Button, EButtonVariants} from "../../UI/Button";
import {useAdminEventsAction} from "../../store/adminEvents/hooks";
import {T, useTranslate} from "@tolgee/react";

const Wrapper = styled(Card)`
  width: 100%;
  max-width: calc(50% - 10px);
  display: flex;
  align-items: stretch;
  gap: 15px;
  flex-direction: column;

  ${({theme}) => theme.mediaQueries.md} {
    max-width: 100%;
  }
`

const EventImage = styled.div`
  display: flex;
  align-items: center;
  background: ${({theme}) => theme.colors.bg};
  justify-content: center;
  width: 148px;
  border-radius: 10px;
  overflow: hidden;
  height: 120px;

  img {
    width: auto;
    height: 100%;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;

  ${({theme}) => theme.mediaQueries.md} {
    gap: 7px;
  }
`

const Categories = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  ${({theme}) => theme.mediaQueries.md} {
  }
`

const Category = styled(Text)`
  padding: 5px 10px;
  background: ${({theme}) => theme.colors.lightGray2};
  border-radius: 5px;
`

const Description = styled(Text)`
  max-height: 60px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  width: 65%;

  ${({theme}) => theme.mediaQueries.md} {
  }
`

const List = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
`

const Item = styled.li`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  text-overflow: ellipsis;
  max-width: 180px;
  overflow: hidden;
  white-space: nowrap;

  svg {
    min-width: 24px;
    height: 24px;
  }

  ${({theme}) => theme.mediaQueries.md} {
    svg {
      min-width: 20px;
      height: 20px;
    }
  }
`

const TextStyled = styled(Text)`
  ${({theme}) => theme.mediaQueries.md} {
    font-size: 12px;
  }
`
const Creator = styled(TextStyled)`
`

const CreatorLink = styled(Link)`
  color: ${({theme}) => theme.colors.mainPurple};
`

const Details = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 20px;
  margin-top: 15px;

  ${({theme}) => theme.mediaQueries.md} {
    grid-column-gap: 10px;
  }
`

const DetailBlock = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
  flex-direction: column;
`

const Label = styled(Text)`
  color: ${({theme}) => theme.colors.mainGray};
`

const Buttons = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 15px;
`

const ButtonStyled = styled(Button)`
  padding: 11.5px 16px;
  font-size: 14px;
`

function AdminEvent({id, categories, date, description, address, author, photo, title, status}: EventResponse) {
  const linkRef = useRef(null)
  const {t} = useTranslate()
  const {onApproveEvent, onRejectEvent, onDeleteEvent} = useAdminEventsAction()

  const onApproveEventHandle = useCallback(() => {
    if (window.confirm(t('admin.event.confirmPublish','Вы действительно хотите опубликовать ивент?'))) {
      onApproveEvent(`${id}`)
    }
  }, [id, onApproveEvent, t])
  const onRejectEventHandle = useCallback(() => {
    if (window.confirm(t('admin.event.confirmDelete','Вы действительно хотите удалить ивент?'))) {
      onDeleteEvent(`${id}`)
    }
  }, [id, onDeleteEvent, t])
  // const interval = useRef<null | NodeJS.Timer>(null)


  return (
    <Wrapper>
      <Label variant={ETextVariants.Body14}>
        <T keyName="admin.event.text">Текст ивента:</T>
      </Label>
      <Content>
        <Text variant={ETextVariants.Heading} as={'h2'}>{title}</Text>
        <Categories>
          {categories.map((category) => <Category key={`category-${category.id}`}
                                                  variant={ETextVariants.MiniDark}>
            <T keyName={`category.list.${category.id}`}>{category.title}</T>
          </Category>)}
        </Categories>
        <Description variant={ETextVariants.Body16}>{description}</Description>
      </Content>
      <Details>
        <DetailBlock>
          <Label variant={ETextVariants.Body14}>
            <T keyName="profileEvent.details">Детали ивента:</T>
          </Label>
          <List>
            <Item>
              <CalendarIcon/>
              <TextStyled
                variant={ETextVariants.Body16}> {dayjs(date).format('D MMMM / HH:mm')}</TextStyled>
            </Item>
            <Item>
              <GeoGrayIcon/>
              <TextStyled variant={ETextVariants.Body16}>{address.fullString}</TextStyled>
            </Item>
          </List>
          <Creator variant={ETextVariants.Body16}>
            <T keyName="profileEvent.creator">Создатель:</T> <CreatorLink ref={linkRef} to={`/profile/${author.id}`}>{author.firstName}</CreatorLink>
          </Creator>
        </DetailBlock>
        <DetailBlock>
          <Label variant={ETextVariants.Body14}>
            <T keyName="profileEvent.photo">Фото ивента:</T>
          </Label>
          <EventImage>
            <img src={photo ? `${mediaUrl}file/${photo.id}` : ''} alt=""/>
          </EventImage>
        </DetailBlock>
        <DetailBlock>
          <Label variant={ETextVariants.Body14}/>
          <Buttons>
            {status !== 'approved' && <ButtonStyled variant={EButtonVariants.Success}
                           onClick={() => onApproveEventHandle()}>
                <T keyName="admin.event.skip">Пропустить</T>
            </ButtonStyled>}
            <ButtonStyled variant={EButtonVariants.Error} onClick={() => onRejectEventHandle()}><T keyName="common.delete">Удалить</T></ButtonStyled>
          </Buttons>
        </DetailBlock>
      </Details>
    </Wrapper>
  );
}

export default AdminEvent;
