import {useDispatch, useSelector} from "react-redux";
import {useCallback, useEffect, useMemo} from "react";
import {AppDispatch, RootState} from "../index";
import {changeSearch} from "./usersSlice";
import fetchUsers from "./fetchUsers";

export const getUsers = (state: RootState) => state.users

export function useUsersAction() {
  const dispatch = useDispatch<AppDispatch>()
  const onChangeSearch = useCallback((search: string) => {
    dispatch(changeSearch(search))
  }, [dispatch])
  const onFetchUsers = useCallback(() => {
    dispatch(fetchUsers())
  }, [dispatch])
  return useMemo(() => ({
    onChangeSearch,
    onFetchUsers,
  }), [
    onChangeSearch,
    onFetchUsers,
  ])
}

export function useUsersState() {
  return useSelector(getUsers)
}

export function useUsers() {
  const {loginSearch} = useUsersState()
  const {onFetchUsers} = useUsersAction()

  useEffect(() => {
    onFetchUsers()
  }, [loginSearch, onFetchUsers])
}
