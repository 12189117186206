import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {ServicesApi} from "../../api/services";

const fetchDeleteService = createAsyncThunk<void,
  number,
  {
    state: RootState
  }>(
  'adminServices/fetchDeleteService',
  async (id, ThunkApi) => {
    const authState = ThunkApi.getState().auth
    if (authState.auth) {
      const [servicesRes, servicesErr] = await handle(ServicesApi.deleteAdminService(id))
      if (servicesRes !== undefined) {
        return
      }
      if (servicesErr) {
        throw {message: servicesErr.message || servicesErr.message[0]}
      }
    }
    throw {message: 'No user state'}
  }
)

export default fetchDeleteService
