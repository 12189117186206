import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {UserEntity} from "../../types";
import fetchUsers from "./fetchUsers";

export type UsersState = {
    loginSearch: string
    users: UserEntity[],
    isLoading: boolean,
}


const initialState: UsersState = {
    loginSearch: '',
    users: [],
    isLoading: true,
}

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        changeSearch: (state, action: PayloadAction<string>) => {
            state.loginSearch = action.payload
            if (action.payload.length === 0) {
                state.users = []
            }
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchUsers.pending, (state, ) => {
            state.isLoading = true
        })
        builder.addCase(fetchUsers.rejected, (state, ) => {
            state.isLoading = false
        })
        builder.addCase(fetchUsers.fulfilled, (state, action: PayloadAction<UserEntity[]>) => {
            state.users = [...action.payload]
            state.isLoading = false
        })
    }
})

export const {changeSearch} = usersSlice.actions
export default usersSlice.reducer
