import React, {useMemo} from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import styled from "styled-components";
import {linksFooter} from "../../config/footer";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {T} from "@tolgee/react";
import * as IconModule from "../../UI/Svg";
import {SvgProps} from "../../UI/Svg";
import {useAuthState} from "../../store/auth/hooks";
import NotificationsMessages from "../../components/NavbarComponents/NotificationsMessages";

const Icons = (IconModule as unknown) as { [key: string]: React.FC<SvgProps> };

const Footer = styled.div<{isDialog: boolean}>`
  //position: fixed;
  //bottom: 0;
  //right: 0;
  //left: 0;
  width: 100%;
  padding: 10px 36px 10px;
  display: none;
  border-top: 1px solid ${({theme}) => theme.colors.mainGray};
  justify-content: space-between;
  align-items: stretch;
  z-index: 100;
  background: ${({theme}) => theme.colors.white};
  ${({theme}) => theme.mediaQueries.md} {
    display: ${({isDialog}) => isDialog ? 'none' : 'flex'};
  }
`

const LinkStyled = styled(Link)<{$isActive: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  color: ${({theme}) => theme.colors.mainGray};
  svg {
    width: 20px;
    height: 20px;
    path {
      stroke: ${({theme, $isActive}) => $isActive ? theme.colors.mainPurple : theme.colors.mainGray};
    }
  }
`

const TextStyled = styled(Text)<{$isActive: boolean}>`
  color: ${({theme, $isActive}) => $isActive ? theme.colors.mainPurple : theme.colors.mainGray};
`

const NotificationsMessagesStyled = styled(NotificationsMessages)`
  right: 0;
  left: unset;
`

function Index() {
  const {user} = useAuthState()
  const {pathname} = useLocation()
  const routName = useMemo(() => pathname.split('/').slice(1), [pathname]);
  const isDialog = useMemo(() => {
    if (routName[0] === 'chat') {
      return !!routName[1]
    }
    return false
  }, [routName])
  if (!user) return null
  return (
    <Footer isDialog={isDialog}>
      {
        linksFooter.map((link, id) => {
          const Icon = Icons[link.icon];
          const iconElement = <Icon/>;
          const isActive = link.href === `/${routName[0]}`
          return (<LinkStyled $isActive={isActive} to={link.href} key={id}>

            {link.key === 'navbar.chats' && <NotificationsMessagesStyled/>}
            {iconElement}
            <TextStyled $isActive={isActive} variant={ETextVariants.Body16}>
              <T keyName={link.key}>{link.title}</T>
            </TextStyled>
          </LinkStyled>)
        })
      }
    </Footer>
  );
}

export default Index;
