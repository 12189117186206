import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled from "styled-components";
import {Card} from "../../components/Templates/Card";
import {T, useTranslate} from "@tolgee/react";
import {Link, useNavigate, useParams} from "react-router-dom";
import LoadingAbout from "../../views/AboutViews/LoadingAbout";
import {handle, mediaUrl} from "../../api";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {ArrowRightIcon, CalendarIcon, DeleteTagIcon, EditIcon, GeoGrayIcon, PriceIcon, SmileIcon} from "../../UI/Svg";
import dayjs from "dayjs";
import imgEmptyAvatar from "../../assets/img/no-avatar.png";
import ModalReport from "../../components/Modals/ModalReport";
import {useAppAction} from "../../store/app/hooks";
import {ContainerMax} from "../../components/Templates/ContainerMax";
import {useServicesAction, useServicesState} from "../../store/services/hooks";
import Dots from "../../UI/Dots";
import {useCompanyState} from "../../store/company/hooks";
import {ServicesApi} from "../../api/services";
import CreateEventModal from "../../components/Modals/CreateEventModal";
import {Button, EButtonVariants} from "../../UI/Button";
import {currencyDesc} from "../../config/currency";
import {useCartAction, useCartState} from "../../store/cart/hooks";
import FormCountComponent from "../../components/ServicesComponents/FormCountComponent";
import ModalUsers from "../../components/Modals/ModalUsers";
import {getTextStyle} from "../../UI/Text/utils";
import FormBuyOneComponent from "../../components/ServicesComponents/FormBuyOneComponent";
import ConfirmModal from "../../components/Modals/ConfirmModal";
import {EventsApi} from "../../api/events";
import {useAcquaintanceState} from "../../store/acquaintance/hooks";
import {useProfileState} from "../../store/profile/hooks";

const ContainerStyled = styled(ContainerMax)`
  display: flex;
  align-items: flex-start;

  ${({theme}) => theme.mediaQueries.md} {
    flex-direction: column;
  }
`

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
  max-width: 1070px;

  ${({theme}) => theme.mediaQueries.md} {
    width: 100%;
    gap: 2px;
  }
`

const Aside = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
  width: 100%;
  max-width: 343px;

  ${({theme}) => theme.mediaQueries.md} {
    max-width: unset;
  }
`

const Buttons = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 30px 16px;
  gap: 20px;
`

const CardStyled = styled(Card)`
  display: flex;
  flex-direction: column;
  overflow: visible;

  ${({theme}) => theme.mediaQueries.md} {
    border-radius: 0;

    &:first-child {
      background: ${({theme}) => theme.colors.bg};
    }
  }
`
const CardProfile = styled(CardStyled)`
  margin-top: 22px;
  position: relative;
  gap: 16px;
  cursor: pointer;

  ${({theme}) => theme.mediaQueries.md} {
    margin-top: 8px;
  }
`

const IconArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
`

const ImageBlock = styled.div`
  width: 100%;
  height: 266px;
  border-radius: 18px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: auto;
  }

  ${({theme}) => theme.mediaQueries.md} {
    height: 234px;

    img {
      height: 100%;
      width: auto;
    }
  }
`

const ServiceTitleBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const ServiceTitle = styled(Text)`

`

const ActionBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
`

const DotsButton = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  cursor: pointer;
  z-index: 10;
`

const DotsModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 161px;
  height: 44px;
  padding: 12px 25px;
  background: ${({theme}) => theme.colors.white};
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  position: absolute;
  left: -134px;
  cursor: pointer;
  top: 36px;
  z-index: 1;

  &:after {
    content: "";
    position: absolute;
    left: 100%;
    top: 0;
    transform: translate(calc(-100% - 7px), -100%) rotate(180deg);
    border: 12px solid transparent;
    border-top: 20px solid ${({theme}) => theme.colors.white};
  }
`

const List = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
`

const Item = styled.li`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;

  svg {
    min-width: 24px;
    width: 24px;
    height: 24px;
  }

  ${({theme}) => theme.mediaQueries.md} {
    svg {
      min-width: 20px;
      width: 20px;
      height: 20px;
    }
  }
`

const TextStyled = styled(Text)`
  ${({theme}) => theme.mediaQueries.md} {
    font-size: 12px;
  }
`
const Members = styled(TextStyled)`
  white-space: pre;
  color: ${({theme}) => theme.colors.mainPurple};
  overflow: hidden;
  cursor: pointer;
`

const UserBlock = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
`

const UserContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1px;
`

const UserAvatar = styled.div`
  height: 47px;
  width: 47px;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
  }
`

const ActionItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: flex-start;

  svg {
    width: 16px;
    height: 16px;

    path {
      stroke: ${({theme}) => theme.colors.black};
    }
  }
`

const PhoneText = styled.div`
  font-size: 16px;
`


const LinkStyled = styled.div`
  ${({theme}) => getTextStyle(theme, ETextVariants.Body16)}
  text-overflow: ellipsis;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  color: ${({theme}) => theme.colors.mainPurple};

  ${({theme}) => theme.mediaQueries.md} {
    line-height: 1;
    font-size: 12px;
  }
`

function Index({isInvite}: { isInvite?: boolean }) {
  const navigate = useNavigate()
  const {t} = useTranslate()
  const {onToggleCart} = useAppAction()
  const {selectedUserCompany} = useCompanyState()
  const {id} = useParams()
  const {profile} = useProfileState()
  const {suggestion} = useAcquaintanceState()
  const {phone, items, dataOnePayment} = useCartState()
  const {onAddCartItem, onUpdateCartItem, onChangeDataOnePayment, onClearDataOnePayment} = useCartAction()
  const {currentService, isLoading} = useServicesState()
  const {onFetchService, onRemoveCurrentService, onChangeFilter} = useServicesAction()
  const [isShowReportModal, setIsShowReportModal] = useState<boolean>(false);
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [isShowMembersModal, setIsShowMembersModal] = useState<boolean>(false);
  const [bodyModal, setBodyModal] = useState({title: '', body: ''});
  const [isShowConfirmModal, setIsShowConfirmModal] = useState<boolean>(false);
  useEffect(() => {
    if (!suggestion && isInvite) {
      navigate('/events')
    }
  }, [isInvite, navigate, suggestion])


  const onInvite = useCallback(async (id: string) => {
    if (suggestion) {
      const [inviteRes, inviteErr] = await handle(EventsApi.postInviteEvent(id, suggestion.id))
      if (inviteRes) navigate('/acquaintance')
      if (inviteErr) console.log(inviteErr);
    }
  }, [navigate, suggestion])

  useEffect(() => {
    if (currentService) {
      onClearDataOnePayment()
    }
  }, [currentService, onClearDataOnePayment])

  const onDeleteService = useCallback(async () => {
    if (currentService) {
      if (window.confirm(t('service.delete', 'Вы уверены что хотите удалить услугу?'))) {
        const [delRes, delErr] = await handle(ServicesApi.deleteService(currentService.id))
        if (delRes !== undefined) {
          navigate('/profile')
          onRemoveCurrentService()
        }
        if (delErr) {
          console.log(delErr);
        }
      }
    }
  }, [currentService, navigate, onRemoveCurrentService, t])


  const {onSetTitle} = useAppAction()
  useEffect(() => {
    onSetTitle(t('common.service', 'Услуга'))
  }, [onSetTitle, t])

  useEffect(() => {
    return () => onRemoveCurrentService()
  }, [])
  useEffect(() => {
    if (typeof id !== "undefined") {
      onFetchService(parseInt(id, 10))
    }
  }, [id, onFetchService])

  const currentCartItem = useMemo(() => {
    if (currentService && items.length > 0) {
      return items.filter((item) => item.favor.id === currentService.id)[0] || null
    }
    return null
  }, [items, currentService])

  const isSold = useMemo(() => {
    return currentService ? currentService.maxCustomers !== 0 && currentService.maxCustomers - currentService.customers.length <= 0 : false
  }, [currentService])

  const isNavigate = useMemo(() => {
    if (currentService && !currentService.archived) {
      const now = new Date()
      if (!currentService.expirationDate) return true
      const date = new Date(currentService.expirationDate)
      if (!currentService.archived && (date.getTime() > now.getTime())) {
        return true
      }
    }
    return false
  }, [currentService])

  if (isLoading) {
    return (
      <ContainerStyled>
        <LoadingAbout/>
      </ContainerStyled>
    )
  }
  const FormatPhone = (): string | null => {
    const phone = currentService?.company.phoneNumber;
    const lenPhone = phone?.length;
    const tt = phone?.split('');
    if (lenPhone === 12 || lenPhone === 13) {
      tt?.splice(2, 0, ' (');
      tt?.splice(6, 0, ') ');
      tt?.splice(10, 0, '-');
      tt?.splice(13 + (lenPhone - 12), 0, '-');
    }
    return tt ? tt.join('') : null;
  };


  if (!currentService) return null

  return (
    <ContainerStyled>
      <Main>
        <CardStyled>
          <ImageBlock>
            <img src={currentService.header ? `${mediaUrl}file/${currentService.header.id}` : ''} alt=""/>
          </ImageBlock>
        </CardStyled>
        <CardStyled>
          <ServiceTitleBlock>
            <ServiceTitle variant={ETextVariants.Heading} as={'h2'}>{currentService.title}</ServiceTitle>
            <Dots
              items={
                selectedUserCompany?.id === currentService.company.id
                  ? [
                    {
                      title: <ActionItem><EditIcon/> <Text
                        variant={ETextVariants.Body16}>
                        <T keyName="common.edit">Редактировать</T>
                      </Text></ActionItem>,
                      action: () => navigate(`/edit/service/${currentService.id}`)
                    },
                    {
                      title: <ActionItem><DeleteTagIcon/> <Text
                        variant={ETextVariants.Body16}>
                        <T keyName="common.delete">Удалить</T></Text></ActionItem>,
                      action: onDeleteService
                    }
                  ]
                  : [{title: <T keyName="event.complain">Пожаловаться</T>, action: () => setIsShowReportModal(true)}]
              }
            />
          </ServiceTitleBlock>
        </CardStyled>

        <CardStyled>
          <Text variant={ETextVariants.Body16}>{currentService.description}</Text>
        </CardStyled>

        <CardStyled>
          <List>
            {currentService.expirationDate && <Item>
                <CalendarIcon/>
                <TextStyled
                    variant={ETextVariants.Body16}> {dayjs(currentService.expirationDate).format('D MMMM / HH:mm')}</TextStyled>
            </Item>}
            {currentService?.address && <Item>
                <GeoGrayIcon/>
                <LinkStyled onClick={() => {
                  if (isNavigate) {
                    onChangeFilter({
                      latitude: Number(currentService.address?.latitude),
                      longitude: Number(currentService.address?.longitude)
                    })
                    navigate(`/events/map?is-event=${false}&id=${id}`)
                  }
                }}>
                  {currentService?.address?.fullString}
                </LinkStyled>
            </Item>}

            <Item>
              <PriceIcon/>
              <TextStyled
                variant={ETextVariants.Body16}>{currentService.price}
                <T
                  keyName={`currencies.${currentService.currency}.symbol`}>{currencyDesc[currentService.currency].symbol}</T>
              </TextStyled>
            </Item>
            <Item onClick={() => setIsShowMembersModal(true)}>
              <SmileIcon/>

              <Members variant={ETextVariants.Body16}>
                {currentService.maxCustomers > 0
                  ? <T
                    keyName="event.numberOfMembers1"
                    params={{
                      numberOfMembers: currentService.customers.length,
                      maxNumberOfMembers: currentService.maxCustomers
                    }}
                    defaultValue={`${currentService.customers.length} из ${currentService.maxCustomers} участников уже присоединились`}
                  />
                  : <T keyName="event.numberOfMembers2"
                       params={{numberOfMembers: currentService.customers.length}}
                       defaultValue={`${currentService.customers.length} участников уже присоединились`}
                  />
                }
              </Members>
            </Item>
          </List>
        </CardStyled>

        <CardProfile onClick={() => navigate(`/company/${currentService?.company.id}`)}>
          <UserBlock>
            <UserAvatar>
              <img
                src={currentService.company.avatar ? `${mediaUrl}file/${currentService.company.avatar.id}` : imgEmptyAvatar}
                alt=""/>
            </UserAvatar>
            <UserContent>
              <Text variant={ETextVariants.Heading}>{currentService.company.title}</Text>
              {/*<Text variant={ETextVariants.Body16}>offline</Text>*/}
            </UserContent>
          </UserBlock>
          {currentService.showCompanyContacts && <PhoneText>{FormatPhone()}</PhoneText>}
          <IconArrow>
            <ArrowRightIcon/>
          </IconArrow>
        </CardProfile>
      </Main>
      <Aside>
        {
          currentService.expirationDate && dayjs(currentService.expirationDate).isBefore(new Date())
            ? <Text variant={ETextVariants.Body16}><T keyName="service.passed">Услуга прошла</T> </Text>
            : isInvite
              ? <Buttons>
                <Button variant={EButtonVariants.Primary} onClick={onInvite} $fullWidth>
                  <T keyName="common.invite">Пригласить</T>
                </Button>
              </Buttons>
              : selectedUserCompany?.id !== currentService.company.id && <Buttons>
              {dataOnePayment
                ? <FormBuyOneComponent service={currentService} onShowModal={() => setIsShowConfirmModal(true)}/>
                : currentCartItem ? <>
                    <Button variant={EButtonVariants.Secondary} $fullWidth onClick={onToggleCart}>
                      <T keyName={"service.inCart"}>В корзине</T>
                    </Button>
                    <FormCountComponent value={currentCartItem.quantity} label={t('common.tickets', 'Билеты')}
                                        onIncrement={() => onUpdateCartItem({
                                          itemId: currentCartItem.id,
                                          quantity: currentCartItem.quantity + 1
                                        })}
                                        onDecrement={() => {
                                          if (currentCartItem.quantity - 1 >= 1) {
                                            onUpdateCartItem({
                                              itemId: currentCartItem.id,
                                              quantity: currentCartItem.quantity - 1
                                            })
                                          }
                                        }}/>
                  </>
                  : (!profile?.customerId || !profile?.email)
                    ? <>
                      <Text variant={ETextVariants.Body16}>
                        <T keyName={'service.emailError'}>Обновите Ваш email в настройках для совершения покупки</T>
                      </Text>
                      <Button variant={EButtonVariants.Secondary} as={Link} to='/profile/settings'>
                        <T keyName={'service.buttons.settingsLink'}>Перейти в настройки</T>
                      </Button>
                    </>
                    : <> <Button
                      disabled={!currentService || (currentService.maxCustomers !== 0 && currentService.maxCustomers - currentService.customers.length <= 0)}
                      variant={EButtonVariants.Primary} $fullWidth
                      onClick={() => onChangeDataOnePayment({serviceId: currentService.id, quantity: 1})}>
                      {isSold ? <T keyName={'service.buttons.sold'}>Распродано</T> :
                        <T keyName={'service.buttons.buy'}>Купить билет</T>}
                    </Button>
                      <Button variant={EButtonVariants.Secondary} $fullWidth
                              disabled={!currentService || (currentService.maxCustomers !== 0 && currentService.maxCustomers - currentService.customers.length <= 0)}
                              onClick={() => onAddCartItem({favorId: currentService.id, quantity: 1})}>
                        {isSold ? <T keyName={'service.buttons.sold'}>Распродано</T> :
                          <T keyName={'service.buttons.addToCart'}>В корзину</T>}
                      </Button>
                    </>}
              </Buttons>
        }
      </Aside>

      {
        isShowConfirmModal && <ConfirmModal title={t('service.confirmModal.title', 'Оплата')}
                                            body={t('service.confirmModal.text', `Вы выбрали получателя с номером телефона «+${phone}» для оплаты услуги в подарок`, {phone})}
                                            confirmButtonText={t('service.confirmModal.confirm', "Хорошо")}
                                            onConfirm={() => {
                                              onAddCartItem({
                                                favorId: currentService.id,
                                                quantity: dataOnePayment?.quantity || 1,
                                                withSelect: true
                                              })
                                              onClearDataOnePayment()
                                              navigate('/payment/cart')
                                              setIsShowConfirmModal(false)
                                            }}
                                            onReject={() => {
                                              setIsShowConfirmModal(false)
                                            }}
          />
      }

      {
        (isShowMembersModal && currentService) && <ModalUsers users={currentService.customers} onClose={() => {
          setIsShowMembersModal(false)
        }} title={t('service.members', 'Участники')}/>
      }

      {isShowReportModal && <ModalReport
          onClose={() => setIsShowReportModal(false)}
          setModal={() => setIsShowModal(false)}
          setBodyModal={setBodyModal}
      />}
      {isShowModal &&
          <CreateEventModal isModalClose={() => setIsShowModal(false)} title={bodyModal.title} body={bodyModal.body}/>}
    </ContainerStyled>
  );
}

export default Index;
