import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {CartApi} from "../../api/cart";

const fetchUpdateItem = createAsyncThunk<string[],
  {itemId: number, quantity: number},
  {
    state: RootState
  }>(
  'cart/fetchUpdateItem',
  async (data, ThunkApi) => {
    const authState = ThunkApi.getState().auth
    if (authState.auth) {
      const [cartRes, cartErr] = await handle(CartApi.putUpdateItem(data.itemId, data.quantity))
      if (cartRes) {
        return cartRes
      }
      if (cartErr) {
        throw {message: cartErr.message[0] || cartErr.message}
      }
    }
    throw {message: 'No user state'}
  }
)

export default fetchUpdateItem
