import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {AcquaintanceApi} from "../../api/acquaintance";
import {UserEntity} from "../../types";

const maxTry = 20

const fetchSuggestions = createAsyncThunk<UserEntity[],
  void,
  {
    state: RootState
  }>(
  'acquaintance/fetchSuggestions',
  async (_, ThunkApi) => {
    const authState = ThunkApi.getState().auth
    const suggestions: UserEntity[] = []
    if (authState.auth) {
      let i = 0;
      let n = 0
      while (suggestions.length < 4 && n < maxTry) {
        const [userRes,] = await handle(AcquaintanceApi.getNextSuggestion())
        if (userRes) {
          suggestions[i] = userRes
          i++;
        }
        n++
      }
      return suggestions
    }
    throw {message: 'No user state'}
  }
)

export default fetchSuggestions
