import {useDispatch, useSelector} from "react-redux";
import {useCallback, useMemo} from "react";
import {AppDispatch, RootState} from "../index";
import fetchWall from "./fetchWall";
import fetchWallCompany from "./fetchWallCompany";
import {resetWall} from "./wallSlice";

export const getWall = (state: RootState) => state.wall

export function useWallAction() {
  const dispatch = useDispatch<AppDispatch>()
  const onResetWall = useCallback(() => {
    dispatch(resetWall())
  }, [dispatch])
  const onFetchWall = useCallback((profileId: number) => {
    dispatch(fetchWall(profileId))
  }, [dispatch])
  const onFetchWallCompany = useCallback((id: number) => {
    dispatch(fetchWallCompany(id))
  }, [dispatch])
  return useMemo(() => ({
    onFetchWall,
    onResetWall,
    onFetchWallCompany,
  }), [onFetchWall, onResetWall, onFetchWallCompany])
}

export function useWallState() {
  return useSelector(getWall)
}
