import React from 'react';
import {useAdminEventsState} from "../../store/adminEvents/hooks";
import styled from "styled-components";
import AdminEvent from "../../components/AdminComponents/AdminEvent";
import AdminService from "../../components/AdminComponents/AdminService";
import {useAdminServicesAction, useAdminServicesState} from "../../store/adminServices/hooks";
import {Button, EButtonVariants} from "../../UI/Button";
import {T} from "@tolgee/react";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  gap: 20px;
  margin-top: 20px;
  flex-wrap: wrap;
`

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: stretch;
  margin-top: 32px;
  width: 100%;
`
function ServicesListView() {
  const {services, isLastPage, filter} = useAdminServicesState()
  const {onChangePage} = useAdminServicesAction()
  return (
    <Wrapper>
      {
        services.map((service) => <AdminService key={service.id} {...service}/>)
      }
      <Buttons>
        <Button onClick={() => onChangePage(filter.page ? filter.page - 1 : 1)} variant={EButtonVariants.Primary} disabled={filter.page === 1}>
          <T keyName="pagination.prev">Предыдущая</T>
        </Button>
        <Button onClick={() => onChangePage(filter.page ? filter.page + 1 : 1)} variant={EButtonVariants.Primary} disabled={isLastPage}>
          <T keyName="pagination.next">Следующая</T>
        </Button>
      </Buttons>
    </Wrapper>
  );
}

export default ServicesListView;
