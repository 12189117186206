import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {CartApi} from "../../api/cart";

const fetchRemoveService = createAsyncThunk<string[],
  number,
  {
    state: RootState
  }>(
  'cart/fetchRemoveService',
  async (id, ThunkApi) => {
    const authState = ThunkApi.getState().auth
    if (authState.auth) {
      const [cartRes, cartErr] = await handle(CartApi.delService(id))
      if (cartRes) {
        return cartRes
      }
      if (cartErr) {
        throw {message: cartErr.message[0] || cartErr.message}
      }
    }
    throw {message: 'No user state'}
  }
)

export default fetchRemoveService
