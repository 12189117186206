import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export type PendingState = Record<string, boolean>

const initialState: PendingState = {}

export const pendingSlice = createSlice({
    name: 'pending',
    initialState,
    reducers: {
        startPending: (state, action: PayloadAction<string>) => {
            state[action.payload] = true
        },
        finishPending: (state, action: PayloadAction<string>) => {
            state[action.payload] = false
        },
    },
})

export const {startPending, finishPending} = pendingSlice.actions
export default pendingSlice.reducer
