import {UserEntity} from "../../types";
import {EventResponse} from "../events/types";
import {ServiceResponse} from "../adminServices/types";


export enum ENotificationType {
  Text = "text",
  EventInvitation = "eventInvitation",
  EventApproval = "eventApproval",
  AcquaintanceLike = "acquaintanceLike",
  FavorBuying = "favorBuying",
  FavorGift = "favorGift",
}

export interface INotification {
  id: string;
  user: UserEntity;
  type: ENotificationType;
  text: string;
  event: EventResponse;
  favor: ServiceResponse;
  connectedUser: UserEntity;
  date: string;
}
