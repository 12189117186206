import React, {ReactNode, useRef, useState} from 'react';
import {useAppAction} from "../../store/app/hooks";

function Draggable({children, onScroll, ourRef, ...props}: {children: ReactNode, onScroll?: any, ourRef: any}) {
  // const ourRef = useRef<any>(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const {onToggleScroll} = useAppAction()
  const mouseCoords = useRef({
    startX: 0,
    startY: 0,
    scrollLeft: 0,
    scrollTop: 0
  });
  const handleDragStart = (e: any) => {
    if (!ourRef.current) return
    const slider = ourRef.current.children[0];
    const startX = e.pageX - slider.offsetLeft;
    const startY = e.pageY - slider.offsetTop;
    const scrollLeft = slider.scrollLeft;
    const scrollTop = slider.scrollTop;
    mouseCoords.current = { startX, startY, scrollLeft, scrollTop }
    setIsMouseDown(true)
    setTimeout(onToggleScroll, 200)
    document.body.style.cursor = "grabbing"
  }
  const handleDragEnd = () => {
    setIsMouseDown(false)
    setTimeout(onToggleScroll, 200)
    if (!ourRef.current) return
    document.body.style.cursor = "default"
  }
  const handleDrag = (e: any) => {
    if (!isMouseDown || ! ourRef.current) return;
    e.preventDefault();
    const slider = ourRef.current;
    const x = e.pageX - slider.offsetLeft;
    const y = e.pageY - slider.offsetTop;
    const walkX = (x - mouseCoords.current.startX) * .1;
    const walkY = (y - mouseCoords.current.startY) * .1;
    slider.scrollLeft -= walkX;
    slider.scrollTop -= walkY;
  }
  return (
    <div ref={ourRef} onMouseDown={handleDragStart} onScroll={onScroll} onMouseUp={handleDragEnd} onMouseMove={handleDrag} {...props}>
      {children}
    </div>
  );
}

export default Draggable;
