import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ECompanyStatus, ICompany, IUserCompany} from "./types";
import fetchCreateCompany from "./fetchCreateCompany";
import fetchUserCompanies from "./fetchUserCompanies";
import fetchCompanyById from "./fetchCompanyById";
import fetchUpdateCompany from "./fetchUpdateCompany";
import {UserEntity} from "../../types";
import fetchSubscribers from "./fetchSubscribers";

export interface CompanyState {
  isPending: boolean
  userCompanies: IUserCompany[],
  selectedUserCompany: IUserCompany | null
  selectedCompany: ICompany | null,
  subscribers: UserEntity[]
  error: null | string,
}

export const initialState: CompanyState = {
  isPending: false,
  userCompanies: [],
  subscribers: [],
  selectedUserCompany: null,
  selectedCompany: null,
  error: null
}

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    selectUserCompany: (state, action: PayloadAction<number>) => {
      state.selectedUserCompany = state.userCompanies.filter((company) => company.status === ECompanyStatus.approved && company.id === action.payload)[0] || null
    },
    clearSelectedUserCompany: (state) => {
      state.selectedUserCompany = null
      state.subscribers = []
    },
    clearSelectedCompany: (state) => {
      state.selectedCompany = null
      state.subscribers = []
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchUserCompanies.fulfilled, (state, action: PayloadAction<IUserCompany[]>) => {
      state.userCompanies = action.payload
    })
    builder.addCase(fetchCompanyById.pending, (state) => {
      state.isPending = true
    })
    builder.addCase(fetchCompanyById.fulfilled, (state, action: PayloadAction<ICompany>) => {
      state.selectedCompany = action.payload
      state.isPending = false
    })
    builder.addCase(fetchCompanyById.rejected, (state, action: any) => {
      if (action.payload) {
        state.error = action.payload.errorMessage || "Some error: Try later!"
      } else {
        state.error = action.error
      }
      state.isPending = false
    })
    builder.addCase(fetchSubscribers.fulfilled, (state, action: PayloadAction<UserEntity[]>) => {
      state.subscribers = action.payload
    })
    builder.addCase(fetchCreateCompany.pending, (state) => {
      state.isPending = true
    })
    builder.addCase(fetchCreateCompany.fulfilled, (state, action: PayloadAction<IUserCompany>) => {
      state.isPending = false
    })
    builder.addCase(fetchCreateCompany.rejected, (state, action: any) => {
      if (action.payload) {
        state.error = action.payload.errorMessage || "Some error: Try later!"
      } else {
        state.error = action.error
      }
      state.isPending = false
    })
    builder.addCase(fetchUpdateCompany.pending, (state) => {
      state.isPending = true
    })
    builder.addCase(fetchUpdateCompany.fulfilled, (state, action: PayloadAction<IUserCompany>) => {
      state.selectedUserCompany = action.payload
      state.isPending = false
    })
    builder.addCase(fetchUpdateCompany.rejected, (state, action: any) => {
      if (action.payload) {
        state.error = action.payload.errorMessage || "Some error: Try later!"
      } else {
        state.error = action.error
      }
      state.isPending = false
    })
  }
})

export const {selectUserCompany, clearSelectedUserCompany, clearSelectedCompany} = companySlice.actions
export default companySlice.reducer
