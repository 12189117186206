import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {ChatApi} from "../../api/chat";

const fetchCreateChat = createAsyncThunk<string,
  number,
  {
    state: RootState
  }>(
  'chat/fetchCreateChat',
  async (id, ThunkApi) => {
    const authState = ThunkApi.getState().auth
    if (authState.auth) {
      const [chatRes, chatErr] = await handle(ChatApi.postCreatePrivateChat(id))
      if (chatRes) {
        return chatRes.id
      }
      if (chatErr) {
        throw {message: chatErr.message[0] || chatErr.message}
      }
    }
    throw {message: 'No phone number'}
  }
)

export default fetchCreateChat
