import React, {useCallback, useEffect, useState} from 'react';
import {PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {ConfirmPaymentData, StripePaymentElementOptions} from "@stripe/stripe-js";
import styled from 'styled-components';
import {Button, EButtonVariants} from "../../UI/Button";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {T, useTranslate} from "@tolgee/react";

const FormStyled = styled.form`
  width: 100%;
`

const ButtonStyled = styled(Button)`
  width: 100%;
  margin-top: 8px;
`

const ErrorText = styled(Text)`
  margin-top: 2px;
`

function CheckoutFormComponent({card}: { card: string }) {
  const {t} = useTranslate()
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({paymentIntent}) => {
      switch (paymentIntent?.status) {
        case "succeeded":
          setMessage(t("stripe.succeeded", "Payment succeeded!"));
          break;
        case "processing":
          setMessage(t("stripe.processing", "Your payment is processing."));
          break;
        case "requires_payment_method":
          setMessage(t("stripe.requiresPaymentMethod", "Your payment was not successful, please try again."));
          break;
        default:
          setMessage(t("stripe.error", "Something went wrong."));
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = useCallback(async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const confirmParams: ConfirmPaymentData = {
      // Make sure to change this to your payment completion page
      return_url: "https://triphouse.lv/payment/complete",
    }
    // if (card) confirmParams.payment_method = card

    const {error} = await stripe.confirmPayment({
      elements,
      confirmParams,
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message || 'Error');
    } else {
      setMessage(t('stripe.error',"An unexpected error occurred."));
    }

    setIsLoading(false);
  }, [elements, stripe, t]);

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: "tabs", paymentMethodOrder: card ? [card] : []
  }

  return (
    <FormStyled id="payment-form" onSubmit={handleSubmit}>

      <PaymentElement id="payment-element" options={paymentElementOptions}/>
      <ButtonStyled disabled={isLoading || !stripe || !elements} id="submit" variant={EButtonVariants.Primary}>
        {isLoading ? <T keyName="stripe.buttons.waiting">Waiting</T> : <T keyName="stripe.buttons.pay">Pay now</T>}
      </ButtonStyled>
      {/* Show any error or success messages */}
      {message && <ErrorText variant={ETextVariants.Error}>
        {message}
      </ErrorText>}
    </FormStyled>
  );
}

export default CheckoutFormComponent;
