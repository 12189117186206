import React, {useCallback, useEffect, useMemo} from 'react';
import {Container} from "../../components/Templates/Container";
import styled from "styled-components";
import {Row} from "../../components/Templates/Row";
import {Col} from "../../components/Templates/Col";
import {Card} from "../../components/Templates/Card";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {getUserOldSessions} from '../../utils/localStorage';
import {Navigate, useNavigate} from 'react-router-dom';
import {useAuthAction, useAuthState} from "../../store/auth/hooks";
import {ProfileCard} from '../../components/AuthComponents';
import {Route, Routes } from 'react-router-dom';

import MainAuthPage from "./MainAuthPage";
import LoginAuthPage from "./LoginAuthPage";
import RegisterAuthPage from "./RegisterAuthPage";
import AuthCarousel from "../../components/AuthComponents/AuthCarousel";
import { Carousel } from './styled';
import {ContainerMax} from "../../components/Templates/ContainerMax";
import {T} from "@tolgee/react";

const RowMain = styled(Row)`
  gap: 20px;
  ${({theme}) => theme.mediaQueries.md} {
    flex-direction: column-reverse;
    width: 100%;
  }
`

const ColLeft = styled(Col)`
  max-width: 806px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${({theme}) => theme.mediaQueries.md} {
    display: none;
    width: auto;
    height: 0;
    padding: 0;
  }
;
`
const ColRight = styled(Col)`
  ${({theme}) => theme.mediaQueries.md} {
    width: 100%;
    min-height: 100vh;
    padding: 0;
    display: flex;
    flex-direction: column;
  }
;
`

const CardMain = styled(Card)`
  ${({theme}) => theme.mediaQueries.md} {
    display: none;
  }
`
const CardForm = styled(Card)`
  position: relative;
  ${({theme}) => theme.mediaQueries.md} {
    flex: 1;
    display: flex;
    flex-direction: column; 
  }
`

const Subtitle = styled(Text)`
  color: ${({theme}) => theme.colors.mainGray};
`

function Index() {
  const navigate = useNavigate();
  const {isLogIn, oldSessions} = useAuthState()
  const {onSendSms} = useAuthAction()
  useEffect(() => {
    if (isLogIn) {
      navigate('/events');
    }
  }, [isLogIn, navigate]);

  const handleSessionClick = useCallback((phoneNumber: string): void => {
    onSendSms(phoneNumber.replace(/[ ]/g, ''));
  }, [onSendSms]);

  return (
    <ContainerMax>
      <RowMain>
        <ColLeft>
          <CardMain>
            <Row style={{marginBottom: "1.2rem"}}>
              <Col>
                <Text variant={ETextVariants.Heading}>
                  <T keyName="auth.recentlyVisited">Недавно заходили на сайт с этого устройства</T>
                </Text>
              </Col>
            </Row>
            <Row style={{marginBottom: "2rem"}}>
              <Col>
                <Subtitle variant={ETextVariants.Body16}>
                  <T keyName="auth.subtitle">Чтобы войти снова, нажмите на фотографию или имя</T>
                </Subtitle>
              </Col>
            </Row>
            <Row>
              {(oldSessions || []).length > 0 ? (
                oldSessions.map((user) => <ProfileCard key={user.id} userSession={user} onSelect={handleSessionClick}/>)
              ) : (
                <ProfileCard onSelect={handleSessionClick}/>
              )}
            </Row>
          </CardMain>
          <Carousel>
            <AuthCarousel/>
          </Carousel>
        </ColLeft>
        <ColRight>
          <CardForm>
            <Routes>
              <Route index element={<MainAuthPage />} />
              <Route path="/login" element={<LoginAuthPage />} />
              <Route path="/register/" element={<RegisterAuthPage />} />
              <Route path="*" element={<Navigate to="/auth" />} />
            </Routes>
          </CardForm>
        </ColRight>
      </RowMain>
    </ContainerMax>
  );
}

export default Index;
