import React, {forwardRef, useMemo} from 'react';
import styled from "styled-components";
import {SelectProps, SelectTagProps} from "./type";

import ArrowDownBlack from '../../assets/img/arrow-down-black.png';
import ArrowDownGray from '../../assets/img/arrow-down-gray.png';
import ArrowDownPurple from '../../assets/img/arrow-down-purple.png';
import {ETextVariants} from "../Text/types";
import {getTextStyle} from "../Text/utils";
import Text from "../Text";

const Wrapper = styled.div<{ mt?: string }>`
  display: flex;
  flex-direction: column;
  ${({mt}) => mt ? `margin-top: ${mt}` : ''};
`


const StyledSelect = styled.select<SelectTagProps>`
  display: inline-block;
  width: 100%;
  padding: 1rem 8rem 1rem 2rem;
  appearance: none;
  overflow: hidden;
  cursor: pointer;
  text-overflow: ellipsis;
  color: ${({theme}) => theme.colors.black};
  white-space: nowrap;
  background: url(${({
                       colorArrow,
                       blackArrow
                     }) => !colorArrow ? (blackArrow ? ArrowDownBlack : ArrowDownGray) : ArrowDownPurple}) no-repeat;
  background-position: ${({
                            colorArrow,
                            blackArrow
                          }) => !colorArrow ? (blackArrow ? '70% center' : '93% center') : '98% center'};
  border-radius: 10px;
  background-color: ${({theme, colorArrow}) => !colorArrow ? theme.colors.lightWhite0 : theme.colors.white};
  border: ${({theme, colorArrow}) => !colorArrow ? 0 : `2px solid ${theme.colors.bg}`};
  ${({theme}) => getTextStyle(theme, ETextVariants.Body16)}
`;

const ErrorText = styled(Text)`
  margin-top: 2px;
  margin-left: 10px;
`

const Index = forwardRef<HTMLSelectElement, SelectProps>(
  ({value, className, error, onChange, mt, children, colorArrow, blackArrow, ...props}, ref) => {
    const isError = useMemo(() => (error?.length || 0) > 0, [error])
    return (
      <Wrapper mt={mt}>
        <StyledSelect
          ref={ref}
          id={(props as any).name}
          value={value}
          error={error}
          className={className}
          onChange={onChange}
          colorArrow={colorArrow}
          blackArrow={blackArrow}
          {...props}
        >
          {children}
        </StyledSelect>
        {isError && (
          <ErrorText variant={ETextVariants.Error}>
            {error}
          </ErrorText>
        )}
      </Wrapper>
    );
  })

export default Index;
