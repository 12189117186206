import React, {useCallback, useMemo} from 'react';
import AdminHeader from "../../../components/AdminComponents/AdminHeader";
import FirmFormView from "../../../views/ProfileViews/FirmFormView";
import {useCompanyAction, useCompanyState} from "../../../store/company/hooks";
import styled from "styled-components";
import {CreateCompanyPayload, IUserCompany} from "../../../store/company/types";
import {useParams} from "react-router-dom";
import {useAdminCompaniesState} from "../../../store/adminCompanies/hooks";
import {useTranslate} from "@tolgee/react";


const FormWrapper = styled.div`
  width: 100%;
  margin-top: 40px;
  max-width: 1070px;
`

function EditFirmPage() {

  const {t} = useTranslate()

  const {id} = useParams()
  const {onUpdateCompany} = useCompanyAction()
  const {isPending} = useCompanyState()
  const {companies} = useAdminCompaniesState()

  const currentCompany: IUserCompany | undefined = useMemo(() => id ? companies.find((company) =>company.id === parseInt(id, 10)) : undefined, [companies, id])

  const onUpdate = useCallback((data: CreateCompanyPayload) => {
    if (id) {
      onUpdateCompany({...data, id: parseInt(id, 10)})
    }
  }, [id, onUpdateCompany])

  return (
    <>
      <AdminHeader title={t('admin.editFirm.title','Редактирование фирмы')}/>
      <FormWrapper>
        <FirmFormView data={currentCompany} onSubmitHandler={onUpdate} isLoading={isPending} initialAvatar={currentCompany?.avatar || null}
                      initialHeader={currentCompany?.header || null}/>
      </FormWrapper>
    </>
  );
}

export default EditFirmPage;
