import React, {useMemo} from 'react';
import {UserEntity} from "../../types";
import styled from "styled-components";
import {mediaUrl} from "../../api";
import noAvatar from "../../assets/img/no-avatar.png";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {getUserName} from "../../utils/user";
import {T} from "@tolgee/react";
import {useProfileState} from "../../store/profile/hooks";
import {useNavigate} from "react-router-dom";

const Wrapper = styled.div`
  padding: 8px 10px;
  display: flex;
  border-radius: 10px;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  background: ${({theme}) => theme.colors.white};
`

const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 50px; 
  width: 50px; 
  height: 50px; 
  overflow: hidden;
  border-radius: 50%;
  background: #9b9b9b;
  img {
    width: auto;
    height: 100%;
  }
`

const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1.5px;
`

const NameBlock = styled(Text)`
  font-weight: bold;
`

const InfoFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
`

const LoginBlock = styled(Text)`
  padding: 2.5px 0;
  color: ${({theme}) => theme.colors.mainPurple};
  background: rgba(108, 39, 171, 0.2);
  border-radius: 4px;
`

const SubscriptionText = styled(Text)`
  white-space: nowrap;
  color: ${({theme}) => theme.colors.lightGray5};
  position: relative;
  &:before {
    content: ''; 
    position: absolute;
    left: -4px;
    top: 50%;
    border-radius: 50%;
    transform: translate(-100%, -50%);
    width: 2px;  
    height: 2px;
    background: ${({theme}) => theme.colors.lightGray5};
  }
`

function UserFindComponent(user: UserEntity) {
  const navigate = useNavigate()
  const {subscriptions} = useProfileState()
  const isSubscription = useMemo(() => {
    if (user) {
      return subscriptions.filter((item) => item.id === user.id).length > 0
    }
    return false
  }, [subscriptions, user])
  return (
    <Wrapper onClick={() => navigate(`/profile/${user.id}`)}>
      <Avatar>
        <img src={user.avatar ? `${mediaUrl}file/${user.avatar.id}` : noAvatar} alt=""/>
      </Avatar>
      <InfoBlock>
        <NameBlock variant={ETextVariants.Body16}>{getUserName(user)}</NameBlock>
        <InfoFooter>
          <LoginBlock variant={ETextVariants.MiniDark}>{user.login}</LoginBlock>
          {isSubscription && <SubscriptionText variant={ETextVariants.MiniDark} as={'p'}>
            <T keyName={'profile.subscriptions'}>Подписки</T>
          </SubscriptionText>}
        </InfoFooter>
      </InfoBlock>
    </Wrapper>
  );
}

export default UserFindComponent;
