import React, {useEffect, useMemo, useRef} from 'react';
import styled from "styled-components";
import HeaderNotificationsView from "./HeaderNotificationsView";
import {useNotifications, useNotificationsState} from "../../store/notifications/hooks";
import NotificationItem from "../../components/ChatComponents/NotificationItem";
import dayjs from "dayjs";
import {ENotificationType, INotification} from "../../store/notifications/types";
import {useCompanyState} from "../../store/company/hooks";
import {useTranslate} from "@tolgee/react";


const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;

  ${({theme}) => theme.mediaQueries.md} {
  }
`

const List = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  flex: 1;
  overflow: auto;
  padding: 30px 25px;
  background: ${({theme}) => theme.colors.white};

  ${({theme}) => theme.mediaQueries.md} {
    padding: 24px 10px 10px;
    background: ${({theme}) => theme.colors.lightWhite1};
  }
`

const Group = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
`

const DateCloud = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  border-radius: 100px;
  margin: 0 auto;
  padding: 4px 8px;
  color: ${({theme}) => theme.colors.darkWhite};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.24px;
  background: transparent;

  ${({theme}) => theme.mediaQueries.md} {
    background: ${({theme}) => theme.colors.white};
  }
`

function NotificationsView() {
  const {notifications} = useNotificationsState()
  const {selectedUserCompany} = useCompanyState()
  useNotifications()
  const {t} = useTranslate()

  const notificationsFiltered = useMemo(() => {
    if (selectedUserCompany) {
      return notifications.filter(({type}) => type === ENotificationType.FavorBuying || type === ENotificationType.Text)
    }
    return notifications
  }, [notifications, selectedUserCompany])

  const groupsNotifications = useMemo(() => {
    const groups: { [key: string]: { notifications: INotification[], timestamp: number } } = {}
    const now = new Date()
    for (const notification of notificationsFiltered) {
      const date = new Date(notification.date)
      let key = ''
      if (date.getFullYear() !== now.getFullYear()) {
        key = dayjs(notification.date).format('D MMMM YYYY')
      } else if (date.getFullYear() === now.getFullYear() && date.getMonth() === now.getMonth() && date.getDate() === now.getDate()) {
        key = t('common.today','сегодня')
      } else {
        key = dayjs(notification.date).format('D MMMM')
      }
      if (key in groups) {
        groups[key].notifications.push(notification)
      } else {
        groups[key] = {
          notifications: [notification],
          timestamp: date.getTime()
        }
      }
    }
    return groups
  }, [notificationsFiltered, t])

  const keysGroups = useMemo(() => {
    return Object.keys(groupsNotifications).reverse()
  }, [groupsNotifications])

  const fictDevRef = useRef<HTMLDivElement | null>(null);


  const scrollBottom = (): void => {
    if (fictDevRef.current) {
      fictDevRef.current?.scrollIntoView({behavior: 'smooth'})
    }
  }

  useEffect(() => {
    scrollBottom()
  }, [keysGroups])

  return (
    <Wrapper>
      <HeaderNotificationsView/>
      <List>
        {
          keysGroups.map((key) => {
            const group = groupsNotifications[key]
            return (
              <Group key={key}>
                <DateCloud>{key}</DateCloud>
                {group.notifications.reverse().map((notification) => <NotificationItem key={notification.id}
                                                                             notification={notification}/>)}
              </Group>
            )
          })
        }
        <div ref={fictDevRef}/>
      </List>
    </Wrapper>
  );
}

export default NotificationsView;
