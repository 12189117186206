import {IAvatar, IPhoto, UserEntity} from "../../types";
import {EventResponse} from "../events/types";
import {ICompany} from "../company/types";

export enum EChatEvents {
  JOIN_ROOM = 'joinRoom',
  LEAVE_ROOM = 'leaveRoom',
  SEND_MESSAGE = 'sendMessage',
  START_TYPING = 'startTyping',
  STOP_TYPING = 'stopTyping',
  READ_MESSAGE = 'readMessage',
  REACT_EVENT_INVITATION = 'reactEventInvitation',
  DELETE_CHAT = 'deleteChat',
  DELETE_MESSAGE = 'deleteMessage',
}

export type TMessageType = 'message' | 'chat' | 'chatsList' | 'startTyping' | 'stopTyping' | 'usersOnline' | 'unreadMessages'

export type TChat = 'event' | 'private' | 'company' | 'group'

export interface IChatBase {
  id: string,
  title: string | null,
  type: TChat,
  event: EventResponse | null,
  company: ICompany | null,
  members: UserEntity[],
}

export type TMessageChatType = 'default' | "eventInvite" | "eventInviteNotification" | "eventInviteAccepted" | "eventInviteUnaccepted" | "company"

export interface IChatMessage {
  id: string,
  date: string,
  type: TMessageChatType
  text: string,
  chat: IChatBase
  sender: UserEntity,
  readers: UserEntity[],
  forward: IChatMessage | null,
  files: IPhoto[]
  event: null | EventResponse
}


export interface IChat extends IChatBase {
  avatar: IAvatar | null,
  lastMessage: IChatMessage,
  unreadMessagesNumber: number,
}

export interface IChatDetail extends IChatBase {
  messages: IChatMessage[]
}


export interface CreateGroupChatPayload {
  title: string,
  members: number[]
  loadFile?: File | null
  avatarId?: string
}
