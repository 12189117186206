import React from 'react';
import styled from "styled-components";
import {SpinnerIcon} from "../../UI/Svg";
import ContentLoader from "react-content-loader";

const LoadingIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  width: 100%;

  svg {
    display: none;
    width: 44px;
    height: 44px;

    ${({theme}) => theme.mediaQueries.md} {
      display: block;
    }
  }
`

const LoadingBlockStyled = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  width: calc(50% - 10px);
  ${({theme}) => theme.mediaQueries.md} {
    display: none;
  }
`

const skeletons = [...new Array(4)]


const LoadingBlock = () => {
  return (
    <LoadingBlockStyled>
      <ContentLoader
        speed={2}
        width="100%"
        height={410}
        backgroundColor="#FFF"
        foregroundColor="#ecebeb"
        style={{ borderRadius: '1rem' }}
      >
        <rect x="0" y="0" rx="0" ry="0" width="100%" height="875" />
      </ContentLoader>
    </LoadingBlockStyled>
  );
};

function LoadingProfileEvents() {
  return (
    <>
      {skeletons.map((_, index) => <LoadingBlock key={index}/>
      )}
      <LoadingIcon>
        <SpinnerIcon/>
      </LoadingIcon>
    </>
  );
}

export default LoadingProfileEvents;
