import React from 'react';
import styled from "styled-components";
import {Container} from "../../../components/Templates/Container";
import {Card} from "../../../components/Templates/Card";
import {ETextVariants} from "../../../UI/Text/types";
import {T} from "@tolgee/react";
import Text from "../../../UI/Text";
import FirmFormView from "../../../views/ProfileViews/FirmFormView";
import {useCompanyAction, useCompanyState} from "../../../store/company/hooks";
import {ArrowLeftIcon} from "../../../UI/Svg";
import {Link} from "react-router-dom";

const ContainerStyled = styled(Container)`
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  flex: 1;
`

const Main = styled.div`
  width: 100%;
  max-width: 777px;

  ${({theme}) => theme.mediaQueries.md} {
    flex: 1;
  }
`
const CardForm = styled(Card)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 17px;
  flex-direction: column;
  overflow: unset;
  //overflow: visible;
`

const Title = styled(Text)`
  font-size: 22px;

  ${({theme}) => theme.mediaQueries.md} {
    display: none;
  }
`


const LinkStyled = styled(Link)`
  margin-right: 16px;

  svg {
    path {
      stroke: ${({theme}) => theme.colors.mainPurple};
    }
  }
`

function CreateFirmPage() {
  const {onCreateCompany} = useCompanyAction()
  const {isPending} = useCompanyState()
  return (
    <ContainerStyled>
      <Main>
        <CardForm>
          <Title variant={ETextVariants.Heading} as={'h2'}><LinkStyled to={'/profile/settings'}><ArrowLeftIcon/></LinkStyled>
            <T keyName="createFirm.title">Регистрация фирмы</T>
          </Title>
          <FirmFormView initialAvatar={null} initialHeader={null} onSubmitHandler={onCreateCompany} isLoading={isPending}/>
        </CardForm>
      </Main>
    </ContainerStyled>
  );
}

export default CreateFirmPage;
