import React, {useContext, useEffect} from 'react';
import styled from "styled-components";
import {Container} from "../../components/Templates/Container";
import ProfileAside from "./ProfileAside";
import ProfileHeader from "./ProfileHeader";
import ProfileWall from "./ProfileWall";
import CreatePostView from "./CreatePostView";
import {useAuthState} from "../../store/auth/hooks";
import {useWallAction} from "../../store/wall/hooks";
import {ProfileContext} from "../../contexts/ProfileContext";


const Wrapper = styled(Container)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;

  ${({theme}) => theme.mediaQueries.md} {
    flex-direction: column;
    gap: 0;
  }
`

const Main = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  gap: 20px;
  ${({theme}) => theme.mediaQueries.md} {
    gap: 10px;
  }
`


function ProfileView() {
  const {onResetWall, onFetchWall} = useWallAction()
  const {user} = useAuthState()
  const {profile} = useContext(ProfileContext)
  useEffect(() => {
    if (profile) {
      onFetchWall(profile.id)
    }
    return () => onResetWall()
  }, [onFetchWall, profile])
  return (
    <Wrapper>
      <ProfileAside/>
      <Main>
        <ProfileHeader/>
        {user?.id === profile?.id && <CreatePostView/>}
        <ProfileWall/>
      </Main>
    </Wrapper>
  );
}

export default ProfileView;
