import React, {useCallback, useMemo} from 'react';
import {ProfileEntity} from "../../store/profile/types";
import styled from "styled-components";
import imgEmptyAvatar from '../../assets/img/no-avatar.png';
import {mediaUrl} from "../../api";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import UserStatusComponent from "./UserStatusComponent";
import {useChatState} from "../../store/chat/hooks";
import _ from "lodash";

const User = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 16px 36px 20px;
  gap: 16px;

  &:hover {
    text-decoration: underline;
  }

  ${({theme}) => theme.mediaQueries.md} {
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    background: ${({theme}) => theme.colors.white};
    padding: 0;
  }
`

const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 141px;
  height: 141px;
  border-radius: 50%;
  overflow: hidden;
  background: #9b9b9b;

  img {
    width: auto;
    height: 100%;
  }

  ${({theme}) => theme.mediaQueries.md} {
    width: 114px;
    height: 114px;
  }

  ${({theme}) => theme.mediaQueries.sm} {
    width: 50px;
    height: 50px;
  }
`

const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  ${({theme}) => theme.mediaQueries.sm} {
    align-items: flex-start;
  }
`

const StatusWrapper = styled.div`

  color: ${({theme}) => theme.colors.lightGray5};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.24px;
  display: none;
  ${({theme}) => theme.mediaQueries.sm} {
    display: inline;
  }
`

const TextStyled = styled(Text)`
  color: ${({theme}) => theme.colors.mainPurple};
`

function ChatMember({profile, onClick}: { profile: ProfileEntity, onClick?: any }) {

  const {usersOnline} = useChatState()
  const onClickHandler = useCallback(() => {
    if (onClick) {
      onClick()
    }
  }, [onClick])

  const isOnline = useMemo(() => {
    const ids = usersOnline.map(({id}) => id)
    return _.includes(ids, profile.id)
  }, [usersOnline, profile])

  return (
    <User onClick={onClickHandler}>
      <Avatar>
        <img src={profile.avatar ? `${mediaUrl}file/${profile.avatar.id}` : imgEmptyAvatar} alt=""/>
      </Avatar>
      <InfoBlock>
        <Text variant={ETextVariants.Body16}>
          {profile?.firstName ? `${profile.firstName} ${profile.lastName}` : profile?.login}
        </Text>
        <StatusWrapper>{isOnline ? <TextStyled variant={ETextVariants.Body14}>online</TextStyled> :
          <UserStatusComponent {...profile} />}</StatusWrapper>
      </InfoBlock>
    </User>
  );
}

export default ChatMember;
