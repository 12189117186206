import React, {useContext, useState} from 'react';
import styled from "styled-components";
import {Card} from "../../components/Templates/Card";
import ProfileTitle from "../../components/ProfileComponents/ProfileTitle";
import ProfileInfo from "../../components/ProfileComponents/ProfileInfo";
import ModalUserPhoto from "../../components/Modals/ModalUserPhoto";
import ProfileAside from "./ProfileAside";
import {ProfileContext} from "../../contexts/ProfileContext";
import {useAuthState} from "../../store/auth/hooks";

const CardStyled = styled(Card)`
  display: flex;
  flex-direction: column;
  position: relative;

  ${({theme}) => theme.mediaQueries.md} {
    gap: 16px;
    position: unset;
  }
`

const ProfileAsideStyled = styled(ProfileAside)<{isMyProfile: boolean}>`
  display: none;

  ${({theme}) => theme.mediaQueries.md} {
    display: ${({isMyProfile}) => isMyProfile ? 'none' : "flex"};
    max-width: unset;
    gap: 10px;

    & > section:first-child {
      & > div:first-child {
        display: none;
      }
    }
  }
`

function ProfileHeader() {
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const {profile} = useContext(ProfileContext)
  const {user} = useAuthState()
  return (
    <>
      <CardStyled>
        <ProfileTitle/>
        <ProfileInfo onShowModal={() => setIsShowModal(true)}/>
      </CardStyled>
      <ProfileAsideStyled isMyProfile={user?.id === profile?.id }/>
      {isShowModal && <ModalUserPhoto onClose={() => setIsShowModal(false)}/>}
    </>
  );
}

export default ProfileHeader;
