import {axiosInstance} from "./index";
import {EPaymentMethod} from "../store/cart/types";
import {IAdminPaymentsFilter} from "../store/adminPayments/types";

export const PurchaseApi = {
  getUserTickets: async () => await axiosInstance.get(`purchase/ticket`),
  getUserPayments: async () => await axiosInstance.get(`purchase/payment`),
  getAllPayments: async (data: IAdminPaymentsFilter) => await axiosInstance.get(`purchase/payment/all`, {params: data}),
  getAllCompanyPayments: async (id: number, filter: any) => await axiosInstance.get(`purchase/payment/company/${id}`, {params: filter}),
  getAllServicePayments: async (id: number, filter: any) => await axiosInstance.get(`purchase/payment/favor/${id}`, {params: filter}),
  postBuyOne: async (favorId: number, method: EPaymentMethod, quantity: number) => await axiosInstance.post(`purchase/self/one/${favorId}`, {
    "method": method,
    quantity
  }),
  postBuySelf: async (cartItemIds: number[], method: EPaymentMethod) => await axiosInstance.post(`purchase/self`, {
    "method": method,
    cartItemIds
  }),
  postBuyGift: async (cartItemIds: number[], phoneNumber: string) => await axiosInstance.post(`purchase/gift`, {
    phoneNumber,
    cartItemIds
  }),
  putConfirmPayment: async (id: string) => await axiosInstance.put(`purchase/payment/${id}/confirm`),
  putConfirmCashPayment: async (id: string) => await axiosInstance.put(`purchase/payment/${id}/confirmcash`),
  putStampTicket: async (id: string) => await axiosInstance.put(`purchase/ticket/${id}/stamp`),
  deleteTicket: async (id: number) => await axiosInstance.delete(`purchase/ticket/${id}`),
}
