import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {CompanyApi} from "../../api/company";
import {CreateCompanyPayload, IUserCompany} from "../company/types";

const fetchCreateCompanyAdmin = createAsyncThunk<IUserCompany,
  CreateCompanyPayload,
  {
    state: RootState
  }>(
  'adminCompanies/fetchCreateCompanyAdmin',
  async (data, ThunkApi) => {
    const authState = ThunkApi.getState().auth
    let avatar = data.avatar
    let header = data.header
    delete data.avatar
    delete data.header
    if (authState.auth) {
      const [companyRes, companyErr] = await handle(CompanyApi.postCreateAdmin(data))
      if (companyRes) {

        if (avatar) {
          const [_, avatarErr] = await handle(CompanyApi.putSetAvatar(avatar, companyRes.id))
          if (avatarErr) console.log(avatarErr)
        }
        if (header) {
          const [_, headerErr] = await handle(CompanyApi.putSetHeader(header, companyRes.id))
          if (headerErr) console.log(headerErr)
        }
        return companyRes
      }
      if (companyErr) {
        console.log('companyErr',companyRes, companyErr)
        throw {message: companyErr.message[0] || companyErr.message}
      }
    }
    throw {message: 'No phone number'}
  }
)

export default fetchCreateCompanyAdmin
