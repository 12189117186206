import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled from "styled-components";
import Text from "../../../UI/Text";
import {ETextVariants} from "../../../UI/Text/types";
import {T, useTranslate} from "@tolgee/react";
import {SearchIcon} from "../../../UI/Svg";
import Input from '../../../UI/Input';
import _ from 'lodash';
import {Button, EButtonVariants} from "../../../UI/Button";
import {useAppAction} from "../../../store/app/hooks";
import {useProfileAction, useProfileState} from "../../../store/profile/hooks";
import {IInterest} from "../../../store/profile/types";
import {Card} from "../../../components/Templates/Card";


const Title = styled(Text)`
  font-size: 22px;

  ${({theme}) => theme.mediaQueries.md} {
    display: none;
  }
`

const FormBlock = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 30px;

`
const InputRow = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  flex-direction: column;
  gap: 10px;
  justify-content: flex-start;
`

const Tags = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  flex-wrap: wrap;
`

const Tag = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  border-radius: 18px;
  padding: 5px 15px;
  cursor: pointer;
  background: ${({theme}) => theme.colors.bg};
  border: 1px solid ${({theme, isSelected}) => isSelected ? theme.colors.mainPurple : theme.colors.bg};

  ${({theme}) => theme.mediaQueries.md} {
    & > * {
      font-size: 14px;
    }
  }
`
const MobileButton = styled(Button)`
  display: none;
  position: absolute;
  right: 16px;
  top: 4px;
  border: none;

  ${({theme}) => theme.mediaQueries.md} {
    display: flex;
  }
`
const ButtonStyled = styled(Button)`
  ${({theme}) => theme.mediaQueries.md} {
    display: none;
  }
`
const CardForm = styled(Card)`
  display: flex;
  width: 100%;
  max-width: 1070px;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 17px;
  height: 100%;
  flex-direction: column;
  overflow: visible;
`

function InterestsPage() {
  const {t} = useTranslate()
  const {onSetTitle} = useAppAction()
  const {interests, profile} = useProfileState()
  const {onUpdateProfile} = useProfileAction()
  useEffect(() => {
    onSetTitle(t('navbar.acquaintance','Интересы'))
  }, [onSetTitle, t])
  const [search, setSearch] = useState<string>('');
  const [interestsSelected, setInterestsSelected] = useState<number[]>(profile?.interests?.map((item) => item.id) ?? []);
  const internalInterests = useMemo(
    () =>
      interests
        .filter((item) => new RegExp(search, 'gi').test(t(`interest.list.${item.id}`, item.title)))
    , [interests, search, t],
  );

  useEffect(() => {
    if (profile) {
      setInterestsSelected(profile?.interests?.map((item) => item.id) || []);
    }
  }, [profile])


  const onSelectTag = (tag: number) => {
    setInterestsSelected(prevState => {
      if (_.includes(prevState, tag)) {
        return prevState.filter((item) => item !== tag)
      } else {
        return [...prevState, tag]
      }
    })

  }

  const submitInterestHandler = useCallback((): void => {
    onUpdateProfile({interestIds: interestsSelected});
  }, [interestsSelected, onUpdateProfile]);

  return (
    <CardForm>
      <Title variant={ETextVariants.Heading} as={'h2'}>
        <T keyName="navbar.acquaintance">Интересы</T>
      </Title>
      <FormBlock>
        <InputRow>
          <Input
            wth={'100%'}
            value={search}
            onChange={event => setSearch(event.target.value)}
            placeholder={t('common.search', 'Поиск')}
            icon={<SearchIcon/>}
          />
        </InputRow>
        <Tags>
          {
            internalInterests.map((item, id) =>
              <Tag onClick={() => onSelectTag(item.id)} key={id} isSelected={_.includes(interestsSelected, item.id)}>
                <Text variant={ETextVariants.Body16}>
                  <T keyName={`interest.list.${item.id}`}>{item.title}</T>
                </Text>
              </Tag>)
          }
        </Tags>
        <ButtonStyled $fullWidth variant={EButtonVariants.Primary} onClick={submitInterestHandler}>
          <T keyName="common.done">Готово</T>
        </ButtonStyled>
        <MobileButton variant={EButtonVariants.Secondary} onClick={submitInterestHandler}>
          <T keyName="common.done">Готово</T>
        </MobileButton>
      </FormBlock>
    </CardForm>
  );
}

export default InterestsPage;
