export const currencyList: Array<{item: string, value: string}> = [
  {item: 'Доллар США (USD)', value: 'usd'},
  {item: 'Евро (EUR)', value: 'eur'},
  {item: 'Российский рубль (руб.)', value: 'rub'},
  // {item: 'Японская иена (JPY)', value: 'jpy'},
  // {item: 'Фунт стерлингов Великобритании (GBP)', value: 'gbp'},
  // {item: 'Швейцарский франк (CHF)', value: 'chf'},
]

export interface ICurrency {
  title: string,
  symbol: string,
  key: string
}

export const currencyDesc: {[key: string]: ICurrency} = {
  usd: {
    title: 'Доллар США (USD)',
    key: "usd",
    symbol: '$',
  },
  eur: {
    title: 'Евро (EUR)',
    key: "eur",
    symbol: '€',
  },
  rub: {
    title: 'Российский рубль (руб.)',
    key: "rub",
    symbol: 'руб.',
  }
}
