import React, {useCallback, useMemo} from 'react';
import styled from "styled-components";
import Table from "../../UI/Table";
import {useAdminPaymentsAction, useAdminPaymentsState} from "../../store/adminPayments/hooks";
import _ from "lodash";
import { IUserCompany } from '../../store/company/types';
import { Link } from 'react-router-dom';
import {useTranslate} from "@tolgee/react";

const Wrapper = styled.div`
  flex-wrap: wrap;
  width: calc(100% + 80px);
  margin: 0 -40px;
  margin-top: 40px;
`

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: stretch;
  margin-top: 32px;
`

const StyledLink = styled(Link)`
  color: ${({theme}) => theme.colors.mainPurple};
  font-size: 16px;
  line-height: 1;
`

function PaymentsListView() {
  const {t} = useTranslate()
  const {paymentsAll} = useAdminPaymentsState()
  const {onSelectCompany} = useAdminPaymentsAction()

  return (
    <Wrapper>
        <Table
          heading={[
            {key: 'id', value: '№'},
            {key: 'company', value: t('admin.allPayments.company','Продавец')},
            {key: 'salesNumber', value: t('admin.allPayments.salesNumber','Продано услуг')},
            {key: 'totalIncome', value: t('admin.allPayments.totalIncome','Сумма продаж')},
            {key: 'commission', value: t('admin.allPayments.commission','Комиссия сервиса')},
            {key: 'withdrawn', value: t('admin.allPayments.withdrawn','Выплачено продавцу')},
          ]}
          data={paymentsAll.map((payment, id) => ({
            ...payment,
            id: id + 1,
            totalIncome: `${payment.totalIncome} $`,
            commission: `${payment.commission} $`,
            withdrawn: `${payment.withdrawn} $`,
            company: <StyledLink onClick={() => onSelectCompany(payment.company)} to={`/admin/payments/company/${payment.company.id}`}>
              {payment.company.title}
            </StyledLink>
          }))}
        />
    </Wrapper>
  );
}

export default PaymentsListView;
