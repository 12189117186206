import React from 'react';
import AdminHeader from "../../../components/AdminComponents/AdminHeader";
import PaymentsFilterView from "../../../views/AdminPaymentsViews/PaymentsFilterView";
import {useAdminServicePayments, useAdminPaymentsState} from "../../../store/adminPayments/hooks";
import {Link, Navigate, useParams} from "react-router-dom";
import styled from "styled-components";
import {ArrowRightIcon} from "../../../UI/Svg";
import PaymentsServiceListView from '../../../views/AdminPaymentsViews/PaymentsServiceListView';
import {T} from "@tolgee/react";

const LinkStyled = styled(Link)`
  font-size: inherit;
  line-height: inherit;
  color: ${({theme}) => theme.colors.mainPurple};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;

  svg path {
    stroke: ${({theme}) => theme.colors.mainPurple};
  }
`

const TitleWrapper = styled.div`
  font-size: inherit;
  line-height: inherit;
  color: ${({theme}) => theme.colors.black};
`

function ServicePaymentsPage() {
  const {id} = useParams()
  useAdminServicePayments(id !== undefined ? parseInt(id) : undefined)
  const {selectedService, selectedCompany} = useAdminPaymentsState()
  if (!selectedService || !selectedCompany) return <Navigate to="/admin/payments"/>
  return (
    <>
      <AdminHeader title={<>
        <LinkStyled to={`/admin/payments`}><T keyName="admin.menu.payments">Платежи</T> <ArrowRightIcon/></LinkStyled>
        <LinkStyled to={`/admin/payments/company/${selectedCompany?.id}`}>{selectedCompany?.title}
          <ArrowRightIcon/></LinkStyled>
        <TitleWrapper>{selectedService ? ` ${selectedService.title}` : null}</TitleWrapper></>}/>
      <PaymentsServiceListView/>
    </>
  );
}

export default ServicePaymentsPage;
