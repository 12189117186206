import React, {useCallback} from 'react';
import {mediaUrl} from "../../api";
import {EPaymentStatus, ETicketStatus, ITicket} from "../../store/cart/types";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {CalendarIcon, ClockIcon, GeoGrayIcon, QRcodeIcon, SmileIcon, TrashIcon} from "../../UI/Svg";
import dayjs from "dayjs";
import {T, useTranslate} from "@tolgee/react";
import imgEmptyAvatar from "../../assets/img/no-avatar.png";
import {EButtonVariants} from "../../UI/Button";
import {useCartAction} from "../../store/cart/hooks";

import {
  ButtonsBlock,
  ButtonStyled,
  CompanyBlock,
  CompanyDesc,
  CompanyLogo,
  CompanySubtitle,
  CompanyTitle,
  Content,
  DeleteButton,
  Description,
  EventImage,
  Item,
  List,
  Members,
  QRButton,
  Row,
  TextStyled,
  TitleBlock,
  Wrapper
} from './styled';
import {Link, useNavigate} from 'react-router-dom';
import {useAppAction} from "../../store/app/hooks";

function TicketComponent({ticket: {id, favor, user, payment, status, createdAt}, onShowQRCode}: { ticket: ITicket, onShowQRCode: (data: string) => void }) {
  const {onDeleteTicket} = useCartAction()
  const {t} = useTranslate();
  const navigate = useNavigate()
  const {onToggleCart} = useAppAction()
  const onDelete = useCallback(() => {
    if (window.confirm(t('cart.ticket.confirm', "Вы действительно хотите удалить билет?"))) {
      onDeleteTicket(id)
    }
  }, [id, onDeleteTicket, t])
  return (
    <Wrapper>
      <Row>
        <EventImage>
          {favor.header && <img src={favor.header ? `${mediaUrl}file/${favor.header.id}` : ''} alt=""/>}
        </EventImage>
        <Content>
          <TitleBlock>
            <Text onClick={() => {
              onToggleCart()
              navigate(`/service/${favor.id}`)
            }} variant={ETextVariants.Heading} as={'h2'}>{favor.title}</Text>
            {favor.company && <CompanyBlock to={`/company/${favor.company.id}`}>
                <CompanyLogo>
                    <img src={favor.company.avatar ? `${mediaUrl}file/${favor.company.avatar.id}` : imgEmptyAvatar}
                         alt=""/>
                </CompanyLogo>

                <CompanyDesc>
                    <CompanyTitle variant={ETextVariants.Body14} as={'h4'}>{favor.company.title}</CompanyTitle>
                    <CompanySubtitle><T keyName="cart.ticket.company">фирма</T></CompanySubtitle>
                </CompanyDesc>
            </CompanyBlock>}
          </TitleBlock>
          <Description variant={ETextVariants.Body16}>{favor.description}</Description>

          <List>
            {favor.expirationDate && <Item>
              <CalendarIcon/>
              <TextStyled
                variant={ETextVariants.Body16}> {dayjs(favor.expirationDate).format('D MMMM / HH:mm')}</TextStyled>
            </Item>}
            {favor.address && <Item>
                <GeoGrayIcon/>
                <TextStyled variant={ETextVariants.Body16}>{favor.address.fullString}</TextStyled>
            </Item>}

            <Item>
              <SmileIcon/>
              <Members variant={ETextVariants.Body16}>
                {favor.maxCustomers > 0 ? <>
                    <T
                      keyName="event.numberOfMembers1"
                      params={{numberOfMembers: favor.customers.length, maxNumberOfMembers: favor.maxCustomers}}
                      defaultValue={`${favor.customers.length} из ${favor.maxCustomers} участников уже присоединились`}
                    />
                  </>
                  : <T keyName="event.numberOfMembers2"
                       params={{numberOfMembers: favor.customers.length}}
                       defaultValue={`${favor.customers.length} участников уже присоединились`}
                  />
                }
              </Members>
            </Item>
          </List>
        </Content>
      </Row>
      <ButtonsBlock>
          <ButtonStyled status={status} paymentStatus={payment.status} disabled $fullWidth variant={EButtonVariants.Primary}>
            {status === ETicketStatus.AwaitCash && <ClockIcon/>}
            {status === ETicketStatus.Used ? <T keyName={'cart.status.user'}>Посещено</T> : status === ETicketStatus.Available
              ? <T keyName={'cart.status.available'}>Оплачено</T>
              : status === ETicketStatus.AwaitCash
                ? <T keyName={'cart.status.awaitCash'}>Оплата на месте</T>
                :  payment.status === EPaymentStatus.Cancelled ? <T keyName={'cart.status.cancelled'}>Отменено</T> : <T keyName={'cart.status.pending'}>Ожидание</T>}
          </ButtonStyled>
        {payment.status === EPaymentStatus.AwaitCash && <DeleteButton onClick={onDelete}>
          <TrashIcon/>
        </DeleteButton>}
      </ButtonsBlock>
      <QRButton onClick={() => onShowQRCode(id.toString())}>
        <QRcodeIcon/>
      </QRButton>
    </Wrapper>
  );
}

export default TicketComponent;
