import React, {ReactNode} from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  height: 695px;
  position: relative;
  & > div {
    &:nth-child(1) {
      z-index: 12;
    }
    &:nth-child(2) {
      z-index: 11;
    }
    &:nth-child(3) {
      z-index: 10;
    }
  }

  ${({theme}) => theme.mediaQueries.md} {
    height: 487px;
  }
`

function StackComponent({children}:{children: ReactNode}) {
  return (
    <Wrapper>
      {children}
    </Wrapper>
  );
}

export default StackComponent;
