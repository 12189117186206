import React, {FormEvent, useEffect, useMemo, useRef, useState} from 'react';
import styled from "styled-components";
import Text from "../../../UI/Text";
import {ETextVariants} from "../../../UI/Text/types";
import {T, useTranslate} from "@tolgee/react";
import {IInterest, ProfileFormData} from "../../../store/profile/types";
import {Control, FieldErrors, UseFormRegister, useWatch} from "react-hook-form";
import {IAvatar, Option} from "../../../types";
import ImageUploader from "../../../UI/ImageUploader";
import {mediaUrl} from "../../../api";
import noAvatar from '../../../assets/img/no-avatar.png';
import Input from '../../../UI/Input';
import {Radio} from "../../../UI/Input/Radio";
import {Button, EButtonVariants} from "../../../UI/Button";
import {SpinnerIcon} from "../../../UI/Svg";
import {useAppAction} from "../../../store/app/hooks";
import ReactTextareaAutosize from "react-textarea-autosize";
import Dropdown from "../../../UI/Dropdown";
import {langs} from "../../../config";

const Title = styled(Text)`
  font-size: 22px;

  ${({theme}) => theme.mediaQueries.md} {
    display: none;
  }
`

interface GeneralFormProps {
  onSubmit: (e: FormEvent) => void;
  onChangeAvatar: (avatar: Option<File>) => void;
  register: UseFormRegister<ProfileFormData>;
  errors: FieldErrors<ProfileFormData>;
  control: Control<ProfileFormData>;
  initialAvatar: Option<IAvatar>;
  isLoading: boolean;
  locale: string,
  setLocale: (val: string) => void
}

const FormStyled = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 17px;
  align-items: flex-start;
`
const InputRow = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  flex-direction: column;
  gap: 10px;
  justify-content: flex-start;
`

const Radios = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;
`

const LoadingIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;

  svg {
    display: none;
    width: 44px;
    height: 44px;
  }
`

const MobileButton = styled(Button)`
  display: none;
  position: absolute;
  right: 16px;
  top: 4px;
  border: none;

  ${({theme}) => theme.mediaQueries.md} {
    display: flex;
  }
`

const InputHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const TextAreaStyled = styled(ReactTextareaAutosize)`
  resize: none;
  padding: 12px 16px;
  border-radius: 12px;
  font-size: 16px;
  width: 100%;
  background: ${({theme}) => theme.colors.white};
  border: 2px solid ${({theme}) => theme.colors.bg};
  color: ${({theme}) => theme.colors.black};
`


const ButtonStyled = styled(Button)`
  ${({theme}) => theme.mediaQueries.md} {
    display: none;
  }
`

const ErrorText = styled(Text)`
  margin-top: 2px;
  margin-left: 10px;
`

function GeneralForm({
                       onChangeAvatar,
                       initialAvatar,
                       onSubmit,
                       register,
                       isLoading,
                       errors, locale, setLocale
                     }: GeneralFormProps) {
  const {t} = useTranslate();

  const {onSetTitle} = useAppAction()
  useEffect(() => {
    onSetTitle(t('common.settings', 'Настройки'))
  }, [onSetTitle, t])

  const options = useMemo(() => {
    return langs.map((lang) => ({
      item: lang.name,
      value: lang.value
    }))
  }, [])
  return (
    <>
      <Title variant={ETextVariants.Heading} as={'h2'}><T keyName="common.settings">Настройки</T></Title>
      <FormStyled onSubmit={onSubmit}>
        <InputRow>
          <ImageUploader
            url={initialAvatar ? `${mediaUrl}file/${initialAvatar.id}` : noAvatar}
            title={t('profileForm.fields.avatar', 'Изменить аватар')}
            onChange={onChangeAvatar}
          />
        </InputRow>
        <InputRow>
          <Input
            placeholder={t('profileForm.fields.name.placeholder',"Введите имя")}
            wth={'100%'}
            error={errors.firstName ? errors.firstName.type === 'required' ? t('inputs.required',"This field is required") : errors.firstName.message : undefined}
            label={<T keyName="profileForm.fields.name.label">Имя</T>}
            {...register('firstName', {
              required: true,
              pattern: /[a-zA-Zа-яА-Я0-9 ]{2,50}/,
            })}
          />
        </InputRow>
        <InputRow>
          <Input
            placeholder={t("profileForm.fields.surname.placeholder","Введите фамилию")}
            wth={'100%'}
            error={errors.lastName ? errors.lastName.type === 'required' ? t('inputs.required',"This field is required") : errors.lastName.message : undefined}
            label={<T keyName="profileForm.fields.surname.label">Фамилия</T>}
            {...register('lastName', {required: true, pattern: /[a-zA-Zа-яА-Я0-9 ]{3,50}/})}
          />
        </InputRow>

        <InputRow>
          <InputHeader>
            <Text variant={ETextVariants.Body16}>
              <T keyName="profileForm.fields.description.label">Описание профиля</T>
            </Text>
          </InputHeader>
          <TextAreaStyled
            {...register('description')}
            minRows={2}
            placeholder={t("profileForm.fields.description.placeholder","Опишите профиль")}
          />
        </InputRow>
        <InputRow>
          <Input
            placeholder={t('profileForm.fields.email.label',"Введите email")}
            wth={'100%'}
            type="email"
            error={errors.email ? errors.email.type === 'required' ? t('inputs.required',"This field is required") : errors.email.message : undefined}
            label={t('profileForm.fields.email.label',"Email")}
            {...register('email', {
              required: true,
            })}
          />
        </InputRow>
        <InputRow>
          <Text variant={ETextVariants.Body16}>
            <T keyName="settings.gender">Пол</T>
          </Text>
        </InputRow>
        <InputRow>
          <Radios>
            <Radio label={t('settings.female', 'Женский')} value="F" id="female" {...register('sex')} />
            <Radio label={t('settings.male', 'Мужской')} value="M" id="male" {...register('sex')} />
          </Radios>
          {errors.sex && <ErrorText variant={ETextVariants.Error}>{errors.sex.type === 'required' ? t('inputs.required',"This field is required") : errors.sex.message}</ErrorText>}
        </InputRow>
        <InputRow>
          <Input
            placeholder={t("profileForm.fields.age.placeholder","Введите возраст")}
            wth={'100%'}
            error={errors.age ? errors.age.type === 'required' ? t('inputs.required',"This field is required") : errors.age.message : undefined}
            label={<T keyName="profileForm.fields.age.label">Возраст</T>}
            {...register('age', {
              valueAsNumber: true,
              required: true,
              min: {
                value: 1, message: t("profileForm.fields.age.min",'The age value must be greater than 1')
              }
            })}
          />
        </InputRow>

        <InputRow>
          <Dropdown label={t('profileForm.fields.locale.label','Выберите язык')} placeholder={t('profileForm.fields.locale.placeholder','Выберите язык')}
                    options={options} value={locale}
                    onSelect={(val) => setLocale(String(val))}/>
        </InputRow>

        {!isLoading
          ?
          <>
            <ButtonStyled $fullWidth variant={EButtonVariants.Primary}>
              <T keyName="common.done">Готово</T>
            </ButtonStyled>
            <MobileButton variant={EButtonVariants.Secondary}>
              <T keyName="common.done">Готово</T>
            </MobileButton>
          </>
          : <LoadingIcon>
            <SpinnerIcon/>
          </LoadingIcon>
        }
      </FormStyled>
    </>
  );
}

export default GeneralForm;
