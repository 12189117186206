import React, {useCallback, useEffect} from 'react';
import styled from 'styled-components';
import {Container} from "../../components/Templates/Container";
import {ETextVariants} from "../../UI/Text/types";
import Text from "../../UI/Text";
import FormServiceView from "../../views/CreateViews/FormServiceView";
import {Card} from "../../components/Templates/Card";
import {useServicesAction, useServicesState} from "../../store/services/hooks";
import {useNavigate, useParams} from "react-router-dom";
import {T} from "@tolgee/react";
import {useCompanyState} from "../../store/company/hooks";

const ContainerStyled = styled(Container)`
`

const CardStyled = styled(Card)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  flex-direction: column;
  gap: 30px;
`

function EditService() {

  const navigate = useNavigate()
  const {id} = useParams()
  const {onFetchService} = useServicesAction()
  const {selectedUserCompany} = useCompanyState()
  const {currentService, isLoading} = useServicesState()

  const fetchServiceHandler = useCallback(() => {
    if (typeof id !== "undefined") {
      onFetchService(parseInt(id, 10))
    }
  }, [id, onFetchService])

  useEffect(() => {
    fetchServiceHandler()
  }, [fetchServiceHandler])

  useEffect(() => {
    if (selectedUserCompany && !isLoading && currentService?.company.id !== selectedUserCompany.id) {
      navigate('-1')
    }
  }, [selectedUserCompany, currentService, navigate])

  return (
    <ContainerStyled>
      <CardStyled>
        <Text variant={ETextVariants.Heading} as={'h2'}>
          <T keyName="common.editing">Редактирование</T></Text>
        {(!isLoading && currentService) &&
            <FormServiceView callback={fetchServiceHandler} isCreate={false} initialData={currentService}/>}
      </CardStyled>
    </ContainerStyled>
  );
}

export default EditService;
