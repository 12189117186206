import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {InterestsApi} from "../../api/interests";
import {IInterest} from "./types";


const fetchInterests = createAsyncThunk<IInterest[],
  void,
  {
    state: RootState
  }>(
  'profile/fetchInterests',
  async (_, ThunkApi) => {
    const authState = ThunkApi.getState().auth
    if (authState.auth) {
      const [interestsRes, interestsErr] = await handle(InterestsApi.getInterests())
      if (interestsRes) {
        return interestsRes
      }
      if (interestsErr) {
        throw {message: interestsErr.message[0] || interestsErr.message}
      }
    }
    throw {message: 'No user state'}
  }
)

export default fetchInterests
