import React from 'react';
import styled from "styled-components";
import {useAdminSupportsState} from "../../store/adminSupports/hooks";
import AdminReport from "../../components/AdminComponents/AdminReport";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  gap: 20px;
  margin-top: 20px;
  flex-wrap: wrap;
`

function SupportListView() {
  const {reports} = useAdminSupportsState()
  return (
    <Wrapper>
      {
        reports.map((report) => <AdminReport key={report.id} {...report}/>)
      }
    </Wrapper>
  );
}

export default SupportListView;
