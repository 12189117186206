import React, {ReactNode, useEffect, useMemo, useState} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {IChatMessage} from "../../store/chat/types";
import {getUserName} from "../../utils/user";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import dayjs from "dayjs";
import {useChatAction} from "../../store/chat/hooks";
import {mediaUrl} from "../../api";
import {FileApi} from "../../api/file";
import Waveform from "./Waveform";

const Wrapper = styled.div<{isVoice: boolean}>`
  display: flex;
  width: 100%;
  gap: 10px;
  align-items: flex-start;
  cursor: pointer;
  justify-content: flex-start;
  flex-direction: ${({isVoice}) => isVoice ? 'column' : 'row'};
  flex-wrap: wrap;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  gap: 6px;
  white-space: pre-wrap;
  word-break: break-all;
  font-size: 16px;
  color: ${({theme}) => theme.colors.black};

`

const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 14px;
  cursor: pointer;

  ${({theme}) => theme.mediaQueries.md} {
    min-width: 134px;
  }
`

const Title = styled(Text)`
  font-weight: 500;
  color: ${({theme}) => theme.colors.mainPurple};
`


const WrapperChecked = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  justify-content: flex-start;
  flex-direction: row;
  background: rgba(255, 255, 255, 0.30);
  padding: 8px 12px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;

  &:before {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    content: '';
    width: 4px;
    background: ${({theme}) => theme.colors.mainPurple};
  }
`
const FilesBlock = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: flex-start;

  img {
    width: auto;
    height: 50px;
  }
`

function MessageForward({message}: { message: IChatMessage }) {
  const {sender, text, files, id} = message;
  const [voiceUrl, setVoiceUrl] = useState<string | null>(null);

  useEffect(() => {
    if (message.files.length > 0) {
      const file = message.files[0]
      if (file.mimetype.split('/')[0] === 'audio') {
        FileApi.getFileById(file.id).then(({data}) => {
          const blob = new Blob([data], {type: 'audio/mp3'});
          const url = URL.createObjectURL(blob);
          setVoiceUrl(url)
        })
      }
    }
  }, [message.files])
  return (
    <WrapperChecked>
      <Wrapper isVoice={!!voiceUrl}>
        {
          voiceUrl && <Title variant={ETextVariants.Body16}>{getUserName(sender)}</Title>
        }
        {files.length > 0 && <FilesBlock>
          {voiceUrl ? <Waveform audio={voiceUrl} uid={id}/> : <img src={`${mediaUrl}file/${files[0].id}`} alt=""/>}
        </FilesBlock>}
        {!voiceUrl && <Content>
            <ContentHeader>
                <Title variant={ETextVariants.Body16}>{getUserName(sender)}</Title>
            </ContentHeader>
          {text}
        </Content>}
      </Wrapper>
    </WrapperChecked>
  );
}

export default MessageForward;
