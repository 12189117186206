import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {IAdminSupportsFilter, IReport,} from "./types";
import {ReportApi} from "../../api/report";

const fetchReports = createAsyncThunk<IReport[],
  void,
  {
    state: RootState
  }>(
  'adminSupports/fetchReports',
  async (_, ThunkApi) => {
    const authState = ThunkApi.getState().auth
    const {filter} = ThunkApi.getState().adminSupports
    const filterData: IAdminSupportsFilter = {}
    for (const filterKey in filter) {
      const field = filterKey as keyof IAdminSupportsFilter
      if (filter[field] !== undefined) {
        filterData[field] = filter[field]
      }
    }
    if (authState.auth) {
      const [reportsRes, reportsErr] = await handle(ReportApi.getReports(filterData))
      if (reportsRes) {
        return reportsRes
      }
      if (reportsErr) {
        throw {message: reportsErr.message[0] || reportsErr.message}
      }
    }
    throw {message: 'No user state'}
  }
)

export default fetchReports
