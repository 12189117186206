import React, {useContext, useMemo, useState} from 'react';
import styled from "styled-components";
import {Card} from "../../components/Templates/Card";
import {useProfileAction, useProfileState} from "../../store/profile/hooks";
import {useAuthState} from "../../store/auth/hooks";
import {Button, EButtonVariants} from "../../UI/Button";
import {mediaUrl} from "../../api";
import imgEmptyAvatar from '../../assets/img/no-avatar.png';
import {T, useTranslate} from "@tolgee/react";
import {Link} from "react-router-dom";
import InviteModal from "../../components/Modals/InviteModal";
import {useChatAction} from "../../store/chat/hooks";
import {ProfileContext} from "../../contexts/ProfileContext";


const AsideCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  ${({theme}) => theme.mediaQueries.md} {
    padding: 12px 16px;
  }
`


const Aside = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 343px;
  flex: 1;
  gap: 20px;
  ${({theme}) => theme.mediaQueries.md} {
    display: none;
  }
`

const ProfileImage = styled.div`
  max-height: 350px;
  height: 350px;
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #9b9b9b;
  
  img {
    height: 100%;
    width: auto;
    max-width: unset;
  }
`

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`

function ProfileAside({...props}: any) {
  const {t} = useTranslate()
  const {isSendData, subscribers} = useProfileState()
  const {profile} = useContext(ProfileContext)
  const {onSubscribe, onUnsubscribe} = useProfileAction()
  const {onFetchCreateChat} = useChatAction()
  const {user} = useAuthState()
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const isSubscribe = useMemo(() => {
    if (user) {
      return subscribers.filter((item) => item.id === user.id).length > 0
    }
    return false
  }, [subscribers, user])
  if (!profile) return null
  return (
    <>
      <Aside {...props}>
        <AsideCard>
          <ProfileImage>
            <img src={profile.avatar ? `${mediaUrl}file/${profile.avatar.id}` : imgEmptyAvatar} alt=""/>
          </ProfileImage>
          <Buttons>
            {
              user?.id === profile.id
                ? <Button $fullWidth variant={EButtonVariants.Primary} as={Link} disabled={isSendData} to="/profile/settings">
                  <T keyName="common.settings">Настройки</T>
              </Button>
                : <>
                  <Button $fullWidth variant={EButtonVariants.Primary} disabled={isSendData} onClick={() => onFetchCreateChat(profile.id)}>
                    <T keyName="profile.message">Сообщение</T>
                  </Button>
                  <Button $fullWidth variant={EButtonVariants.Secondary} disabled={isSendData} onClick={() => setIsShowModal(true)}>
                    <T keyName="profile.invite">Пригласить на Ивент</T>
                  </Button>
                </>
            }
          </Buttons>
        </AsideCard>
        {user?.id !== profile.id && <AsideCard>
          {isSubscribe
            ? <Button onClick={onUnsubscribe} $fullWidth variant={EButtonVariants.Secondary}
                      disabled={isSendData}><T keyName="common.buttons.unsubscribe">Отписаться</T></Button>
            : <Button onClick={onSubscribe} $fullWidth variant={EButtonVariants.Primary}
                      disabled={isSendData}><T keyName="common.buttons.subscribe">Подписаться</T></Button>}
        </AsideCard>}
      </Aside>
      {isShowModal && <InviteModal onClose={() => setIsShowModal(false)} />}
    </>
  );
}

export default ProfileAside;
