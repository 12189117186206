import React, {useEffect, useMemo} from 'react';
import GlobalStyle from "./styles/Global";
import ResetCSS from "./styles/ResetCSS";
import Layout from "./views/Layout";
import Auth from "./app/auth";
import {Navigate, Route, Routes } from 'react-router-dom';
import Home from "./app/home";
import 'dayjs/locale/ru';
import 'dayjs/locale/en';
import 'dayjs/locale/lv';
import AboutEvent from "./app/aboutEvent";
import CreateEvent from "./app/CreateEvent";
import Profile from "./app/profile";
import Chat from "./app/chat";
import LayoutAdmin from "./views/LayoutAdmin";
import Admin from "./app/admin";
import AdminCategories from "./app/admin/AdminCategories";
import Services from "./app/admin/services";
import Support from "./app/admin/support";
import Forbidden from "./app/admin/forbidden";
import AdminInterests from "./app/admin/AdminInterests";
import Firms from "./app/admin/firms";
import Service from "./app/service";
import Company from "./app/company";
import EditService from "./app/edit/EditService";
import Payment from "./app/payment";
import Acquaintance from "./app/acquaintance";
import {useProfileAction, useProfileState} from "./store/profile/hooks";
import EditEvent from './app/edit/EditEvent';
import Users from "./app/admin/users";
import Payments from "./app/admin/payments";
import {useAuthAction, useAuthState} from "./store/auth/hooks";
import {useTolgee} from "@tolgee/react";
import {setDefaultLocale} from "react-datepicker";
import dayjs from "dayjs";
import {useCompanyAction, useCompanyState} from "./store/company/hooks";
import Politics from "./app/politics";
import Terms from "./app/terms";
function App() {
  const {lang, isLogIn} = useAuthState()
  const {profile} = useProfileState()
  const {onChangeLanguage} = useAuthAction()
  const {selectedUserCompany} = useCompanyState()
  const {onClearSelectedUserCompany} = useCompanyAction()

  useEffect(() => {
    if (profile && selectedUserCompany && selectedUserCompany.owner.id !== profile.id) onClearSelectedUserCompany()
  },[onClearSelectedUserCompany, profile, selectedUserCompany])

  const { getLanguage, changeLanguage } = useTolgee();
  useEffect(() => {
    const currentLang = profile?.locale || lang || 'en'
    setDefaultLocale(currentLang);
    dayjs.locale(currentLang)
    if (currentLang !== lang) {
      onChangeLanguage(currentLang);
    }
    if (currentLang && getLanguage() !== currentLang) {
      changeLanguage(currentLang);
    }
  }, [lang, getLanguage, changeLanguage, profile?.locale, onChangeLanguage]);
  const {onFetchProfile} = useProfileAction()

  useEffect(() => {
    if (isLogIn) {
      onFetchProfile()
    }
  }, [onFetchProfile,isLogIn])
  return (
    <>
      <ResetCSS/>
      <GlobalStyle/>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Navigate to="/events" />} />
          <Route path="events/*" element={<Home />} />
          <Route path="invites/*" element={<Home isInvite={true} />} />
          <Route path="about-the-event/:id" element={<AboutEvent />} />
          <Route path="service/:id" element={<Service />} />
          <Route path="invites/event/:id" element={<AboutEvent isInvite />} />
          <Route path="invites/service/:id" element={<Service isInvite />} />
          <Route path="invites/create/" element={<CreateEvent isInvite />} />
          <Route path="edit/service/:id" element={<EditService />} />
          <Route path="edit/event/:id" element={<EditEvent />} />
          <Route path="company/:id" element={<Company />} />
          <Route path="payment/*" element={<Payment />} />
          <Route path="create/" element={<CreateEvent />} />
          <Route path="profile/*" element={<Profile />} />
          <Route path="acquaintance/*" element={<Acquaintance />} />
          <Route path="auth/*" element={<Auth />} />
          <Route path="chat/*" element={<Chat />} />
          <Route path="politics/*" element={<Politics />} />
          <Route path="terms/*" element={<Terms />} />
          <Route path="*" element={<Navigate to="/auth" />} />
        </Route>
        <Route path="/admin/" element={<LayoutAdmin />}>
          <Route index element={<Admin/>} />
          <Route path='categories' element={<AdminCategories/>} />
          <Route path='interests' element={<AdminInterests/>} />
          <Route path='services' element={<Services/>} />
          <Route path='firms/*' element={<Firms/>} />
          <Route path='users/*' element={<Users/>} />
          <Route path='payments/*' element={<Payments/>} />
          <Route path='support' element={<Support/>} />
          <Route path='forbidden' element={<Forbidden/>} />
          <Route path="*" element={<Navigate to="/admin" />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
