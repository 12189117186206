import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {EventsApi} from "../../api/events";
import {EventResponse} from "./types";

const fetchEvent = createAsyncThunk<EventResponse,
  string,
  {
    state: RootState
  }>(
  'events/fetchEvent',
  async (id, ThunkApi) => {
    const authState = ThunkApi.getState().auth
    if (authState.auth) {
      const [eventRes, eventErr] = await handle(EventsApi.getEvent(id))
      if (eventRes) {
        return eventRes
      }
      if (eventErr) {
        throw {message: eventErr.message[0] || eventErr.message}
      }
    }
    throw {message: 'No user state'}
  }
)

export default fetchEvent
