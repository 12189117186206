import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled from "styled-components";
import Input from "../../UI/Input";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {ArrowLeftIcon, CloseIcon, SearchIcon} from "../../UI/Svg";
import {useNavigate} from 'react-router-dom';
import UserItem from '../ProfileComponents/UserItem';
import {useCartAction, useCartState} from "../../store/cart/hooks";
import Tabs from "../../UI/Tabs";
import CartItemComponent from "../CartComponents/CartItemComponent";
import TicketComponent from "../CartComponents/TicketComponent";
import _ from "lodash";
import {Button, EButtonVariants} from "../../UI/Button";
import PhoneModal from "./PhoneModal";
import ModalQRCode from "./ModalQRCode";
import {useAppAction, useAppState} from "../../store/app/hooks";
import {T, useTranslate} from "@tolgee/react";

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  padding-top: 60px;
  z-index: 100000;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    content: '';
    position: absolute;
    background: ${({theme}) => theme.colors.transparentGray};
    width: 100%;
    height: 100%;
    padding-bottom: 100%;
    top: 0;
  }

  ${({theme}) => theme.mediaQueries.md} {
    position: absolute;
    border-radius: 0;
    width: 100%;
    height: 100%;
    padding-top: 0;
  }
`

const Modal = styled.div`
  min-height: 300px;
  max-width: 1191px;
  position: relative;
  height: 84vh;
  width: 100%;
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
  top: 0;
  right: 0;
  background: ${({theme}) => theme.colors.white};
  z-index: 2;
  border-radius: 10px;

  &::-webkit-scrollbar {
    width: 0;
  }

  ${({theme}) => theme.mediaQueries.xxl} {
    width: 968px;
  }

  ${({theme}) => theme.mediaQueries.lg} {
    width: 770px;
  }

  ${({theme}) => theme.mediaQueries.md} {
    border-radius: 0;
    width: 100%;
    height: 100%;
    position: fixed;
  }
`

const InputBlock = styled.div`
  display: flex;

`
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 32px 18px 70px;
  width: 100%;
  border-bottom: 1px solid ${({theme}) => theme.colors.darkWhite};
  position: relative;
  gap: 20px;
  background: ${({theme}) => theme.colors.white};

  & > ${InputBlock} {
    display: none;
  }

  ${({theme}) => theme.mediaQueries.md} {
    justify-content: center;
    flex-direction: column;
    //padding: 8px 32px 10px;
    padding: 8px 16px 10px;
    border-bottom: none;

    & > ${InputBlock} {
      width: 100%;
      display: flex;
    }
  }
`


const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 43px;

  ${({theme}) => theme.mediaQueries.md} {
    padding-left: 30px;

    ${InputBlock} {
      display: none;
    }
  }
`

const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 32px;
`
const InputStyled = styled(Input)`
  height: 40px;
  width: 100%;
`

const CloseButton = styled.div`
  width: 20px;
  height: 20px;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    width: 12px;

    &:first-child {

      ${({theme}) => theme.mediaQueries.md} {
        display: none;
      }
    }

    &:nth-child(2) {

      display: none;

      ${({theme}) => theme.mediaQueries.md} {
        display: block;
      }
    }
  }

  ${({theme}) => theme.mediaQueries.md} {
    position: absolute;
    top: 16px;
    left: 16px;

    svg {
      min-width: 24px;
    }
  }
`

const ScrollWrapper = styled.div`
  flex: 1;
  width: 100%;
  overflow: auto;
`

const Content = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 12px 40px 130px;
  height: 100%;

  ${({theme}) => theme.mediaQueries.md} {
    padding: 10px 16px;
    background: ${({theme}) => theme.colors.bg};
  }
`

const ButtonsBlock = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 16px;
  background: ${({theme}) => theme.colors.white};
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-end;

  ${({theme}) => theme.mediaQueries.md} {
    padding: 8px;

    ${Button} {
      width: 100%;
      max-width: 250px;
    }
  }
`

function ModalCart() {
  const {t} = useTranslate();
  const {isShowCart} = useAppState()
  const {onToggleCart} = useAppAction()
  const bodyMain = document.querySelector('body');
  const [isShowPhoneModal, setIsShowPhoneModal] = useState<boolean>(false);
  const [isShowQRCode, setIsShowQRCode] = useState<boolean>(false);
  const [QRCodeData, setQRCodeData] = useState<string | null>(null);

  useEffect(() => {
    if (bodyMain) bodyMain.style.overflow = isShowCart ? 'hidden' : 'auto';

    return () => {
      if (bodyMain) bodyMain.style.overflow = 'auto';
    };
  }, [bodyMain, isShowCart]);
  const navigate = useNavigate()
  const {tickets, items, checkedItemsIds} = useCartState()
  const {onCheckItem, onFetchCart, onChangePhone, onClearCheckedItems} = useCartAction()
  useEffect(() => {
    onClearCheckedItems()
  }, [])
  useEffect(() => {
    onFetchCart()
  }, [onFetchCart, isShowCart])
  const [selectTab, setSelectTab] = useState<string>('cart');
  const [search, setSearch] = useState<string>('');

  const filteredItems = useMemo(() => {
    if (search !== '') {
      const data = items?.filter((item) => {
        return item.favor.title.toLowerCase().includes(search.toLowerCase())
      });
      if (data.length > 0) return data
    }
    return items
  }, [items, search]);
  const filteredTickets = useMemo(() => {
    if (search !== '') {
      const data = tickets?.filter((ticket) => {
        return ticket.favor.title.toLowerCase().includes(search.toLowerCase())
      });
      if (data.length > 0) return data
    }
    return tickets
  }, [search, tickets]);

  const onClose = useCallback(() => onToggleCart(), [onToggleCart])

  if (!isShowCart) return null

  return (
    <>
      <Wrapper>
        <Modal>
          <Header>
            <HeaderRow>
              <HeaderLeft>
                <Text variant={ETextVariants.Heading}>
                  <T keyName="cart.title">Билеты</T>

                </Text>
                <InputBlock>
                  <InputStyled
                    placeholder={t('common.search', 'Поиск')}
                    wth="34.3rem"
                    onChange={(e) => setSearch(e.target.value)}
                    icon={<SearchIcon/>}
                  />
                </InputBlock>
              </HeaderLeft>
              <HeaderRight>
                <Tabs
                  value={selectTab}
                  onSelect={(val) => {
                    setSelectTab(val)
                    onClearCheckedItems()
                  }}
                  tabs={[
                    {name: t('cart.tabs.cart', 'Корзина'), value: 'cart'},
                    {name: t('cart.tabs.tickets', 'Мои билеты'), value: 'tickets'},
                  ]}/>
                <CloseButton onClick={onClose}>
                  <CloseIcon/>
                  <ArrowLeftIcon/>
                </CloseButton>
              </HeaderRight>
            </HeaderRow>
            <InputBlock>
              <InputStyled
                placeholder={t('common.search', 'Поиск')}
                wth="100%"
                onChange={(e) => setSearch(e.target.value)}
                icon={<SearchIcon/>}
              />
            </InputBlock>
          </Header>
          <ScrollWrapper>
            <Content>
              {
                selectTab === 'cart'
                  ? filteredItems.map((item) => <CartItemComponent onCheck={onCheckItem}
                                                                   isChecked={_.includes(checkedItemsIds, item.id)}
                                                                   key={item.id} item={item}/>)
                  : filteredTickets.map((item) => <TicketComponent key={item.id} ticket={item} onShowQRCode={(data) => {
                    setQRCodeData(data)
                    setIsShowQRCode(true)
                  }}/>)
              }
            </Content>
          </ScrollWrapper>
          {checkedItemsIds.length > 0 && <ButtonsBlock>
              <Button variant={EButtonVariants.Secondary} onClick={() => setIsShowPhoneModal(true)}><T keyName={'cart.buttons.gift'}>Подарок</T></Button>
              <Button variant={EButtonVariants.Primary} onClick={() => {
                onClose()
                navigate('/payment/cart')
                onChangePhone('')
              }}><T keyName={'cart.buttons.pay'}>Оплатить</T></Button>
          </ButtonsBlock>}
        </Modal>
      </Wrapper>

      {(isShowQRCode && QRCodeData) && <ModalQRCode data={QRCodeData} onClose={() => {
        setIsShowQRCode(false)
        setQRCodeData(null)
      }}/>}
      {isShowPhoneModal && <PhoneModal onClose={() => setIsShowPhoneModal(false)} onCloseAll={() => {
        setIsShowPhoneModal(false)
        onClose()
      }}/>}
    </>
  );
}

export default ModalCart;
