import {Colors} from "./types";

export const baseColors = {
    white: '#FFF',
    black: '#000',
    bg: '#F5F5F5',

    lightWhite: '#F4F3FE',
    lightWhite0: '#F5F5F5',
    lightWhite1: '#F7F7FA',
    darkWhite: '#BCBCBC',

    mainPurple: '#6C27AB',
    lightPurple0: '#7200DB',
    lightPurple1: '#5F7E88',
    lightPurple2: '#D0CCFF',
    lightPurple: '#6D11C0',
    darkPurple0: '#2A4753',
    darkPurple: '#256479',

    mainOrange: '#E6A057',
    lightOrange: '#ECAD6B',
    darkOrange: '#D88F43',
    mainGray: '#BCBCBC',
    lightGray0: '#E2EBEE',

    lightGray1: '#C4D7DE',
    lightGray: '#C5C0DB',
    lightGray2: '#EDEDED',
    lightGray3: '#858585',
    lightGray4: '#EAEAEA',
    lightGray5: '#A4A4A4',
    lightGray6: '#E1E3E6',
    lightGray7: '#ECEBF0',
    darkGray0: '#576B72',
    darkGray1: '#CDCDCD',
    darkGray: '#545454',
    mainRed: '#EB5757',

    mainGreen: '#27AE60',

    textGray: '#9B9B9B',

    backgroundHover: '#f4f4f478',
    transparentGray: 'rgba(0, 0, 0, 0.15)',
    successButtonBg: '#27AB44',
    successButtonBgDisabled: '#61ea7f',
    successButtonBgHover: '#34ea5c',
    successButtonBgActive: '#186c2a',
    errorButtonBg: '#AB2727',
    errorButtonBgDisabled: '#e76e6e',
    errorButtonBgHover: '#e33434',
    errorButtonBgActive: '#6e1919',
};


export const lightColors: Colors = {
    ...baseColors,
};
