import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {CompanyApi} from "../../api/company";
import {IUserCompany} from "./types";

const fetchUserCompanies = createAsyncThunk<IUserCompany[],
  void,
  {
    state: RootState
  }>(
  'company/fetchUserCompanies',
  async (_, ThunkApi) => {
    const authState = ThunkApi.getState().auth
    if (authState.auth) {
      const [companiesRes, companiesErr] = await handle(CompanyApi.getCompanies())
      if (companiesRes) {
        return companiesRes
      }
      if (companiesErr) {
        console.log('companiesErr',companiesRes, companiesErr)
        throw {message: companiesErr.message[0] || companiesErr.message}
      }
    }
    throw {message: 'No phone number'}
  }
)

export default fetchUserCompanies
