import React, {useEffect, useMemo} from 'react';
import styled from "styled-components";
import {Card} from "../../components/Templates/Card";
import ServiceListItem from "../../components/ServicesComponents/ServiceListItem";
import {useServicesAction, useServicesState} from "../../store/services/hooks";
import {ICompany, IUserCompany} from "../../store/company/types";

const CardStyled = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 10px;
  max-width: 737px;
  
`

function CompanyServicesListView({company, search}: {search: string, company: ICompany | IUserCompany }) {
  const {onFetchCompanyServices} = useServicesAction()
  const {companyServices} = useServicesState()
  useEffect(() => {
    onFetchCompanyServices(company.id)
  }, [company.id, onFetchCompanyServices])
  const filteredServices = useMemo(() => {
    return companyServices?.filter((item) => {
      return item.title.toLowerCase().includes(search.toLowerCase())
    });
  }, [companyServices, search]);
  if (companyServices.length === 0) return null
  return (
    <CardStyled>
      {filteredServices.map((service) => <ServiceListItem key={`Service-${service.id}`} {...service}/>)}
    </CardStyled>
  );
}

export default CompanyServicesListView;
