import React, {useContext, useEffect} from 'react';
import {useProfileAction, useProfileState} from "../../store/profile/hooks";
import styled from "styled-components";
import {Container} from "../../components/Templates/Container";
import LoadingProfile from "../../views/ProfileViews/LoadingProfile";
import ProfileView from "../../views/ProfileViews/ProfileView";
import {useWallAction} from "../../store/wall/hooks";
import {useAppAction} from "../../store/app/hooks";
import {ProfileContext} from "../../contexts/ProfileContext";
import {useTranslate} from "@tolgee/react";


const Wrapper = styled(Container)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;

  ${({theme}) => theme.mediaQueries.md} {
    flex-direction: column;
    gap: 0;
  }
`

function MyProfilePage() {
  const {t} = useTranslate()
  const {profile, isLoading} = useProfileState()
  const {onFetchProfile} = useProfileAction()
  const {onSetProfile, onRemoveProfile} = useContext(ProfileContext)
  const {onSetTitle} = useAppAction()
  useEffect(() => {
    onSetTitle(t('navbar.profile','Профиль'))
  }, [t])
  useEffect(() => {
    if (profile) {
      onSetProfile(profile)
      return () => {
        onRemoveProfile()
      }
    }
  }, [profile])
  useEffect(() => {
    onFetchProfile()
  }, [onFetchProfile])
  if (!profile || isLoading) {
    return (
      <Wrapper>
        <LoadingProfile/>
      </Wrapper>
    );
  }
  return <ProfileView />
}

export default MyProfilePage;
