import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {ChatApi} from "../../api/chat";
import {IChat} from "./types";

const fetchUserChats = createAsyncThunk<IChat[],
  void,
  {
    state: RootState
  }>(
  'chat/fetchUserChats',
  async (_, ThunkApi) => {
    const authState = ThunkApi.getState().auth
    const companyState = ThunkApi.getState().company
    if (authState.auth) {
      const [chatRes, chatErr] = await handle(companyState.selectedUserCompany ? ChatApi.getCompanyChats(companyState.selectedUserCompany.id) : ChatApi.getUserChats())
      if (chatRes) {
        console.log('chatRes',chatRes)
        return chatRes
      }
      if (chatErr) {
        throw {message: chatErr.message[0] || chatErr.message}
      }
    }
    throw {message: 'No phone number'}
  }
)

export default fetchUserChats
