import React, {useEffect} from 'react';
import {Container} from "../../components/Templates/Container";
import styled from "styled-components";
import {ETextVariants} from "../../UI/Text/types";
import {T, useTranslate} from "@tolgee/react";
import Text from "../../UI/Text";
import {Card} from "../../components/Templates/Card";
import BlockComponent from "../../components/PoliticsComponents/BlockComponent";
import {politics} from "../../config/politics";
import {useAppAction} from "../../store/app/hooks";


const ContainerStyled = styled(Container)`
  display: flex;
  justify-content: flex-start;
  gap: 20px;
`

const Title = styled(Text)`
  font-size: 22px;
`

const CardStyled = styled(Card)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 17px;
  height: 100%;
  flex-direction: column;
  overflow: visible;
  width: 100%;
`

const DateText = styled(Text)`
  span   {
    font-style: inherit;
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    vertical-align: baseline;
    border-bottom: 1px solid ${({theme}) => theme.colors.black};
  }
`


function Index() {
  const {onSetTitle} = useAppAction()
  const {t} = useTranslate()

  useEffect(() => {
    onSetTitle(t('politics.shortTitle'))
  }, [onSetTitle, t])
  return (
    <ContainerStyled>
      <CardStyled>
        <Title variant={ETextVariants.Heading} as={'h2'}><T keyName="politics.title"/></Title>
        <Text variant={ETextVariants.Body16}><T keyName="politics.description"/></Text>
        {politics.map((item, id) => <BlockComponent key={id} {...item}/>)}
        <DateText variant={ETextVariants.Body16}><T keyName="date"/> <span>16.05.2024</span></DateText>
      </CardStyled>
    </ContainerStyled>
  );
}

export default Index;
