import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {ITicket} from "./types";
import {PurchaseApi} from "../../api/purchase";

const fetchTickets = createAsyncThunk<ITicket[],
  void,
  {
    state: RootState
  }>(
  'cart/fetchTickets',
  async (_, ThunkApi) => {
    const authState = ThunkApi.getState().auth
    if (authState.auth) {
      const [ticketsRes, ticketsErr] = await handle(PurchaseApi.getUserTickets())
      if (ticketsRes) {
        return ticketsRes
      }
      if (ticketsErr) {
        throw {message: ticketsErr.message[0] || ticketsErr.message}
      }
    }
    throw {message: 'No user state'}
  }
)

export default fetchTickets
