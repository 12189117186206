import React, {useCallback, useContext, useMemo, useState} from 'react';
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import dayjs from "dayjs";
import {IWall} from "../../store/wall/types";
import styled from "styled-components";
import {T, useTranslate} from "@tolgee/react";
import {Link, useNavigate} from "react-router-dom";
import {handle, mediaUrl} from "../../api";
import imgEmptyAvatar from "../../assets/img/no-avatar.png";
import {getUserShortName} from "../../utils/user";
import {GeoIcon} from "../../UI/Svg";
import {Button, EButtonVariants} from "../../UI/Button";
import ModalPostPhoto from "../Modals/ModalPostPhoto";
import CreateEventModal from "../Modals/CreateEventModal";
import {ProfileContext} from "../../contexts/ProfileContext";
import {useWallAction} from "../../store/wall/hooks";
import {ProfileApi} from "../../api/profile";
import {useAuthState} from "../../store/auth/hooks";
import {useEventsAction} from "../../store/events/hooks";
import {handleImageUpload} from "../../utils";


const LinkStyled = styled(Link)`
  font-size: inherit;
  vertical-align: text-bottom;
`

const TypePostStyled = styled.div`
  font-size: 14px;
  display: inline;
  vertical-align: inherit;
  gap: 8px;
`


const Post = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  svg {
    vertical-align: middle;
  }

  //padding: 16px 30px;

  ${({theme}) => theme.mediaQueries.md} {
    background: ${({theme}) => theme.colors.white};
    padding: 12px 16px;
  }
`

const PostHeader = styled.div`
  //display: flex;
  //flex-wrap: wrap;
  //align-items: center;
  display: inline-block;
  line-height: 24px;
  gap: 10px;
  vertical-align: text-top;
`

const Author = styled.div`
  //display: flex;
  //align-items: center;
  //justify-content: flex-start;
  display: inline-block;
  gap: 10px;
  cursor: pointer;
`

const AuthorImage = styled.div`
  //display: flex;
  //align-items: center;
  //justify-content: center;
  display: inline-block;
  text-align: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;
  background: #9b9b9b;
  margin-right: 5px;

  img {
    height: 100%;
    width: auto;
    max-width: unset;
  }
`

const TextName = styled(Text)`
  margin-right: 4px;

  &:hover {
    text-decoration: underline;
  }
`

const TextPost = styled(Text)`
  color: ${({theme}) => theme.colors.mainGray};
`

const PostContent = styled.div`
  margin-top: 8px;
`

const PostImageBlock = styled.div`
  display: flex;
  align-items: flex-start;

  ${({theme}) => theme.mediaQueries.md} {
    justify-content: center;
  }
`
const PostImage = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  max-height: 500px;
  height: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  max-width: 717px;
  overflow: hidden;

  ${({theme}) => theme.mediaQueries.xl} {
    max-width: 617px;
  }

  ${({theme}) => theme.mediaQueries.lg} {
    max-width: 517px;
  }

  ${({theme}) => theme.mediaQueries.md} {
    max-width: 417px;
  }

  ${({theme}) => theme.mediaQueries.sm} {
    max-width: 100%;
  }

  img {
    width: 100%;
  }
`


const ButtonStyled = styled(Button)`
  padding: 10px 20px;
  margin-bottom: 4px;
`

const ButtonIcon = styled.div`
  display: inline;
  cursor: pointer;
`

const TypePost = ({item}: { item: IWall }) => {
  return (
    <TypePostStyled>
      {item.type === 'planEvent'
        ? item.author?.sex !== 'F' ? (
          <><T keyName="profileWall.plannedToVisitMale">запланировал посещение ивента </T>&nbsp;<LinkStyled
            to={`/about-the-event/${item.event?.id}`}><Text
            variant={ETextVariants.Body14}>{item.event?.title}</Text></LinkStyled></>
        ) : (
          <><T keyName="profileWall.plannedToVisitFemale">запланировала посещение ивента </T>&nbsp;<LinkStyled
            to={`/about-the-event/${item.event?.id}`}><Text
            variant={ETextVariants.Body14}>{item.event?.title}</Text></LinkStyled></>
        )
        : item.photo && (!item.body || item.body === "")
          ? (
            <T keyName={"profileWall.newPhoto"}>новое фото пользователя</T>
          ) : item.type === 'default' ? (<>
            <T keyName={"profileWall.profilePost"}>пост созданный пользователем</T> <Text
            variant={ETextVariants.Body14}>
            {
              dayjs(item.date).format('DD.MM.YYYY') === dayjs(new Date()).format('DD.MM.YYYY')
                ? (dayjs(item.date).format('HH:mm'))
                : (dayjs(item.date).format('DD.MM.YYYY HH:mm'))
            }
          </Text>
          </>) : null}
      &nbsp;
    </TypePostStyled>
  )
}

function UserPostComponent({item}: { item: IWall }) {
  const {t} = useTranslate()
  const navigate = useNavigate()
  const {user} = useAuthState()
  const [loadFile, setLoadFile] = useState<File | null>(null);
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const {profile} = useContext(ProfileContext)
  const {onFetchWall} = useWallAction()
  const {onChangeFilter} = useEventsAction()


  const [isShowErrorModal, setIsShowErrModal] = useState<boolean>(false);
  const [bodyModal, setBodyModal] = useState<{ title: string, body: string }>(
    {title: '', body: ''}
  );

  const onSend = useCallback(async () => {
    const compressedFile = await handleImageUpload(loadFile)
    if (compressedFile !== null) {
      const [_, fileErr] = await handle(ProfileApi.putSetPhoto(item.id, compressedFile))

      if (fileErr) {
        setBodyModal({
          title: t('common.errorImage', 'Ошибка загрузки изображения'),
          body: fileErr.message
        });
        setIsShowErrModal(true);
      }
      setLoadFile(null)
      if (profile) {
        onFetchWall(profile.id)
      }
    }
  }, [loadFile, item.id, profile, t, onFetchWall]);

  const isNavigate = useMemo(() => {
    if (item.type === 'planEvent' && item.event) {
      const now = new Date()
      const date = new Date(item.event.date)
      if (item.event.status === 'approved' && date.getTime() > now.getTime()) {
        return true
      }
    }
    return false
  }, [item.event, item.type])

  const onGoToEvent = useCallback(() => {
    if (item.type === 'planEvent' && item.event?.address) {
      onChangeFilter({
        latitude: Number(item.event.address.latitude),
        longitude: Number(item.event.address.longitude)
      })
      navigate(`/events/map?is-event=${true}&id=${item.event.id}`)
    }
  }, [item.event, item.type, navigate, onChangeFilter])

  return (
    <>
      <Post>
        <PostHeader>
          <Author onClick={() => navigate(`/profile/${item.author?.id}`)}>
            <AuthorImage>
              <img src={item.author?.avatar ? `${mediaUrl}file/${item.author?.avatar.id}` : imgEmptyAvatar}
                   alt=""/>
            </AuthorImage>
            &nbsp;
            <TextName variant={ETextVariants.Body14}>{item.author ? getUserShortName(item.author) : ''}</TextName>
          </Author>
          <TextPost variant={ETextVariants.Body14}>
            <TypePost item={item}/>
          </TextPost>
          {isNavigate && (
            <ButtonIcon onClick={onGoToEvent}>
              <GeoIcon/>
            </ButtonIcon>
          )}
        </PostHeader>
        <PostContent>

          {item.body && <TextName variant={ETextVariants.Body14}>
            {item.body}
          </TextName>}
          {
            item.photo
              ?
              <PostImageBlock>
                <PostImage>
                  <img src={`${mediaUrl}file/${item.photo.id}`} alt=""/>
                </PostImage>
              </PostImageBlock>
              : item.author?.id === user?.id && <>
              {loadFile
                ? <>
                  <ButtonStyled onClick={onSend} $fullWidth variant={EButtonVariants.Secondary}>
                    <T keyName="common.send">Отправить</T>
                  </ButtonStyled>
                  {loadFile
                    ? <T keyName="profileWall.fileName"
                         defaultValue={`Имя обложки: ${loadFile.name}`}
                         params={{name: loadFile.name}}/>
                    : ''}
                </>
                : <ButtonStyled onClick={() => setIsShowModal(true)} $fullWidth variant={EButtonVariants.Secondary}>+
                  <T keyName="profileWall.addPhoto">Добавить фото</T>
                </ButtonStyled>}
            </>
          }
        </PostContent>
      </Post>

      {isShowModal && <ModalPostPhoto
          loadFile={loadFile}
          onClose={() => {
            setIsShowModal(false)
          }}
          onChange={(file) => {
            if (file) {
              setLoadFile(file)
              setIsShowModal(false)
            }
          }}
      />}
      {isShowErrorModal &&
          <CreateEventModal isModalClose={() => setIsShowErrModal(false)} title={bodyModal.title}
                            body={bodyModal.body}/>}
    </>
  );
}

export default UserPostComponent;
