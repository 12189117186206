import React from 'react';
import AdminHeader from "../../../components/AdminComponents/AdminHeader";
import PaymentsFilterView from "../../../views/AdminPaymentsViews/PaymentsFilterView";
import {useAdminCompanyPayments, useAdminPaymentsState} from "../../../store/adminPayments/hooks";
import {Link, Navigate, useParams} from "react-router-dom";
import styled from "styled-components";
import {ArrowRightIcon} from "../../../UI/Svg";
import PaymentsCompanyListView from '../../../views/AdminPaymentsViews/PaymentsCompanyListView';
import Text from "../../../UI/Text";
import {ETextVariants} from "../../../UI/Text/types";
import LoadingSpinner from "../../../UI/LoadingSpinner";
import {T} from "@tolgee/react";

const LinkStyled = styled(Link)`
  font-size: inherit;
  line-height: inherit;
  color: ${({theme}) => theme.colors.mainPurple};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  svg path {
    stroke: ${({theme}) => theme.colors.mainPurple};
  }
`

const TitleWrapper = styled.div`
  font-size: inherit;
  line-height: inherit;
  color: ${({theme}) => theme.colors.black};
`

const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
`

const InfoRow = styled(Text)`
  color: ${({theme}) => theme.colors.black};
  span {
    color: ${({theme}) => theme.colors.mainPurple};
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }
`

function CompanyPaymentsPage() {
  const {id} = useParams()
  useAdminCompanyPayments(id !== undefined ? parseInt(id) : undefined)
  const {selectedCompany, paymentsCompany, isLoading} = useAdminPaymentsState()
  if (!selectedCompany) return <Navigate to="/admin/payments" />
  return (
    <>
      <AdminHeader title={<><LinkStyled to={`/admin/payments`}><T keyName="admin.menu.payments">Платежи</T> <ArrowRightIcon/></LinkStyled>
        <TitleWrapper>{selectedCompany ? ` ${selectedCompany.title}` : null}</TitleWrapper></>}
                   rightContent={<InfoBlock>
                     <InfoRow variant={ETextVariants.Body16}>
                       <T keyName="admin.companyPayments.totalSales">Всего продаж:</T> <span>{paymentsCompany.salesNumber}</span></InfoRow>
                     <InfoRow variant={ETextVariants.Body16}>
                       <T keyName="admin.companyPayments.revenue">Выручка:</T> <span>{paymentsCompany.totalIncome} $</span></InfoRow>
                   </InfoBlock>}
      />
      <PaymentsFilterView/>
      {
        isLoading
          ? <LoadingSpinner/>
          : <PaymentsCompanyListView/>
      }
    </>
  );
}

export default CompanyPaymentsPage;
