import React, {ReactNode, useEffect, useMemo, useRef, useState} from 'react';
import styled from "styled-components";
import Text from "../../UI/Text";
import ReactTextareaAutosize from "react-textarea-autosize";
import {Button, EButtonVariants} from "../../UI/Button";
import ImageUploader from "../../UI/ImageUploader";
import {handle, mediaUrl} from "../../api";
import noAvatar from "../../assets/img/no-avatar.png";
import Input from "../../UI/Input";
import {T, useTranslate} from "@tolgee/react";
import {ETextVariants} from "../../UI/Text/types";
import {AddDNDIcon, SpinnerIcon, TrashIcon} from "../../UI/Svg";
import {useForm, useWatch} from "react-hook-form";
import {CreateCompanyPayload, FormCompanyData, IUserCompany} from "../../store/company/types";
import {IAvatar, UserEntity} from "../../types";
import {FileUploader} from "react-drag-drop-files";
import {Col} from "../../components/Templates/Col";
import {getTextStyle} from "../../UI/Text/utils";
import PhoneInput from "react-phone-input-2";
import {Checkbox} from "../../UI/Input/Checkbox";
import Dropdown from "../../UI/Dropdown";
import {currencyList} from "../../config/currency";
import HomeMap from "../../app/home/HomeMap";
import DropdownCustom from "../../UI/Dropdown/DropdownCustom";
import {UserApi} from "../../api/user";
import imgEmptyAvatar from "../../assets/img/no-avatar.png";
import {getUserName} from "../../utils/user";
import CreateEventModal from "../../components/Modals/CreateEventModal";
import {Link, useNavigate} from "react-router-dom";
import {handleImageUpload} from "../../utils";

const FormStyled = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 17px;
  align-items: flex-start;
`

const InputRow = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  flex-direction: column;
  gap: 10px;
  justify-content: flex-start;

  & > label {
    width: 100%;
  }
`

const InputHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const TextAreaStyled = styled(ReactTextareaAutosize)`
  resize: none;
  padding: 12px 16px;
  border-radius: 12px;
  width: 100%;
  font-size: 16px;
  background: ${({theme}) => theme.colors.white};
  border: 2px solid ${({theme}) => theme.colors.bg};
  color: ${({theme}) => theme.colors.black};
`
const LoadingIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;

  svg {
    display: none;
    width: 44px;
    height: 44px;
  }
`


export const HomeMapStyled = styled(HomeMap)`
  padding: 0;
`


const MobileButton = styled(Button)`
  display: none;
  position: absolute;
  right: 16px;
  top: 4px;
  border: none;

  ${({theme}) => theme.mediaQueries.md} {
    display: flex;
  }
`
const ButtonStyled = styled(Button)`
  ${({theme}) => theme.mediaQueries.md} {
    display: none;
  }
`

const ErrorForm = styled(Text)`

`

const UploadStyled = styled.div`
  width: 100%;
  position: relative;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  gap: 10px;
  background: ${({theme}) => theme.colors.bg};
  overflow: hidden;
  border: 2px solid ${({theme}) => theme.colors.bg};

  svg {
    width: 20px;
    height: 20px;
  }

  ${({theme}) => theme.mediaQueries.md} {
  }
`
const UploadText = styled(Text)`
  color: ${({theme}) => theme.colors.mainPurple};
  cursor: pointer;
`

const ImagePreview = styled.img`
  width: 100%;
  height: auto;
`

const FileTitle = styled(Text)`
  display: flex;
  justify-content: center;
`

const PhoneInputBlock = styled(Col)`
  & .form-control {
    width: 100%;
    height: 4rem;
    padding: 1rem 2rem 1rem 5rem;
    border-radius: 10px;
    background-color: ${({theme}) => theme.colors.white};
    border: 2px solid ${({theme}) => theme.colors.bg};
    ${({theme}) => getTextStyle(theme, ETextVariants.Body16)};
  }

  & .flag-dropdown {
    border: none;
    border-radius: 0px;
    background-color: transparent !important;
  }

  & .selected-flag {
    background-color: transparent !important;
    padding-left: 2rem;
  }
`

const DeleteHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  background: ${({theme}) => theme.colors.bg};
  cursor: pointer;
`

const DropdownStyled = styled(DropdownCustom)`
  & > div:nth-child(2) {
    background: ${({theme}) => theme.colors.white};
  }
`

interface FirmFormProps {
  onSubmitHandler: (data: CreateCompanyPayload) => void
  isLoading: boolean,
  initialAvatar: IAvatar | null,
  initialHeader: IAvatar | null,
  data?: IUserCompany,
  isCreateAdmin?: boolean
}

const fileTypes = ['JPEG', 'PNG', 'JPG'];

const UserItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
  
`
const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 37px;
  min-width: 37px;
  height: 37px;
  border-radius: 50%;
  overflow: hidden;
  background: #9b9b9b;
  img {
    width: auto;
    height: 100%;
  }
`


const LinkStyled = styled(Link)`
  color: ${({theme}) => theme.colors.mainPurple};
`
function FirmFormView({onSubmitHandler, isLoading, data, initialHeader, initialAvatar, isCreateAdmin}: FirmFormProps) {
  const refDnD = useRef<HTMLDivElement | null>(null);
  const {t} = useTranslate()
  const [avatar, setAvatar] = useState<File | null>(null);
  const [header, setHeader] = useState<File | null>(null);
  const [isAgree, setIsAgree] = useState<boolean>(false);
  const [position, setPosition] = useState<[number, number]>(data?.address ? [parseFloat(data.address?.latitude), parseFloat(data.address?.latitude)] : [0,0]);
  const [addressMap, setAddressMap] = useState<any>(null);
  const [isShowCompanyContacts, setIsShowCompanyContacts] = useState<boolean>(false);
  const [currency, setCurrency] = useState<string>('');
  const [formError, setFormError] = useState<string | null>(null);
  const [phoneNumberValue, setPhoneNumberValue] = useState<string>(data?.phoneNumber || '');
  const addressRef = useRef<HTMLInputElement>(null);

  const navigate = useNavigate()

  const isEdit = useMemo(() => !!data, [data])

  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [bodyModal, setBodyModal] = useState<{title: string, body: string}>({title: '', body: ''});

  const [userSearch, setUserSearch] = useState('');
  const [users, setUsers] = useState<UserEntity[]>([]);

  useEffect(() => {
    if (userSearch.length > 2) {
      handle(UserApi.getUsers({search: userSearch}))
        .then(([res, err]) => {
          if (res) {
            setUsers(res)
          }
          if (err) {
            console.log(err);
          }
        })
    }
  }, [userSearch])


  const {control, register, handleSubmit, setValue} = useForm<FormCompanyData>({
    defaultValues: {
      title: data?.title || '',
      description: data?.description || '',
      email: data?.email || '',
      taxPayerNumber: data?.taxPayerNumber || '',
      registrationNumber: data?.registrationNumber || '',
      site: data?.site || '',
      companyId: data?.companyId || '',
    },
  });

  useEffect(() => {
    if (data) {
      setPosition(data.address ? [parseFloat(data.address?.latitude), parseFloat(data.address?.latitude)] : [0,0])
      if (addressRef.current) addressRef.current.value = data?.address?.fullString || ''
      setPhoneNumberValue(data?.phoneNumber || '')
      setCurrency(data?.currency || '')
      setIsShowCompanyContacts(data?.showCompanyContacts || false)
      setValue('title', data?.title || '')
      setValue('description', data?.description || '')
      setValue('email', data?.email || '')
      setValue('taxPayerNumber', data?.taxPayerNumber || '')
      setValue('companyId', data?.companyId || '')
      setValue('registrationNumber', data?.registrationNumber || '')
      setValue('site', data?.site || '')
    }
  }, [data, setValue])

  const ownerPhoneNumber = useWatch({control, name: 'ownerPhoneNumber', defaultValue: ''})

  const onSubmit = handleSubmit(async (data) => {
    const payload: CreateCompanyPayload = {
      ...data,
      phoneNumber: phoneNumberValue.indexOf('+') !== -1
        ? phoneNumberValue.replace(/[ ]/g, '')
        : `+${phoneNumberValue.replace(/[ ]/g, '')}`,
      currency,
      showCompanyContacts: isShowCompanyContacts,
      latitude: position[0],
      longitude: position[1]
    };

    if (!currency) {
      setFormError(t("companyForm.errors.location",'Выберите место на карте!'));
      return;
    }
    if (!addressRef.current?.value) {
      setFormError(t("companyForm.errors.currency",'Выберите валюту!'));
      return;
    }
    const compressedAvatar = await handleImageUpload(avatar)
    if (compressedAvatar) {
      payload.avatar =  compressedAvatar;
    }
    const compressedHeader = await handleImageUpload(header)
    if (compressedHeader) {
      payload.header =  compressedHeader;
    }
    onSubmitHandler(payload)
    setIsShowModal(true)
    setBodyModal({title: t('companyForm.modal.title', 'Фирма'), body:
        isEdit
      ? t('companyForm.modal.editBody', 'Данные изменены')
      : t('companyForm.modal.body', 'Данные отправлены на модерацию')
    })
  });
  useEffect(() => {
    const addressValue = addressRef.current;
    if (addressValue && addressMap) addressValue.value = addressMap.detail;
  }, [addressMap, addressMap?.detail]);

  const usersOpts: Array<{item: ReactNode, value: string}> = useMemo(() => {
    if (users.length > 0) {
      return users.map((user) => ({
        item: <UserItem>
          <Avatar>
            <img src={user.avatar ? `${mediaUrl}file/${user.avatar.id}` : imgEmptyAvatar} alt=""/>
          </Avatar>
          <Text variant={ETextVariants.Body14}>
            {getUserName(user)}
          </Text>
        </UserItem>,
        value: user.phoneNumber
      }))
    }
    return []
  }, [users])

  const options = useMemo(() => {
    return currencyList.map((item) => ({
      item: t(`currencies.${item.value}.title`, item.item),
      value: item.value
    }))
  }, [t])

  return (
    <FormStyled onSubmit={onSubmit}>
      {isCreateAdmin && <InputRow>
        <DropdownStyled isSearch label={t('companyForm.fields.owner.label','Пользователь')}
                        placeholder={t('companyForm.fields.owner.placeholder','Пользователь')}
                        options={usersOpts}
                        customSearch={userSearch}
                        onSearch={setUserSearch}
                        value={ownerPhoneNumber || ''}
                        onSelect={(val) => {
                          setValue('ownerPhoneNumber', String(val))
                        }}/>
      </InputRow>}
      <InputRow>
        <Input
          placeholder={t('companyForm.fields.title.placeholder', 'Введите название')}
          wth={'100%'}
          label={t('companyForm.fields.title.label', 'Название')}
          {...register('title', {
            required: true,
            pattern: /[a-zA-Zа-яА-Я0-9 ]{2,50}/,
          })}
        />
      </InputRow>

      <InputRow>
        <ImageUploader
          url={initialAvatar ? `${mediaUrl}file/${initialAvatar.id}` : noAvatar}
          title={t('companyForm.fields.avatar', `Изменить аватар`)}
          onChange={setAvatar}
        />
      </InputRow>
      <InputRow ref={refDnD}>
        <FileUploader
          handleChange={(file: File) => {
            setFormError(null)
            setHeader(file)
          }}
          name="file"
          types={fileTypes}
          onTypeError={(err: string) => setFormError(err)}
          maxSize="10"
          onSizeError={(file: string) => setFormError(file)}
        >
          <UploadStyled>
            {initialHeader ?
              <>
                <ImagePreview src={initialHeader ? `${mediaUrl}file/${initialHeader.id}` : ''}/>
                <DeleteHeader>
                  <TrashIcon/>
                </DeleteHeader>
              </>
              : header ?
                <ImagePreview src={URL.createObjectURL(header)}/>
                : <>
                  <AddDNDIcon/>
                  <UploadText
                    variant={ETextVariants.Body16}
                  >
                    <T keyName="companyForm.fields.cover.title">Добавить обложку</T>
                  </UploadText>
                </>
            }
          </UploadStyled>
        </FileUploader>
        {formError === 'File type is not supported' && (
          <ErrorForm variant={ETextVariants.Error}>
            <T keyName="companyForm.fields.cover.errors.typeIsNotSupported">Такой тип файла не подходит, допустимые
              файлы формата JPEG,PNG!</T>
          </ErrorForm>
        )}
        {formError === 'File size is too big' && (
          <ErrorForm variant={ETextVariants.Error}>
            <T keyName="companyForm.fields.cover.errors.sizeIsToBig">Большой размер файла, размер не должен превышать
              10МБ!</T>
          </ErrorForm>
        )}
        <FileTitle variant={ETextVariants.Body16}>
          {header
            ? <T keyName="companyForm.fields.cover.value"
                 defaultValue={`Имя обложки: ${header.name}`}
                 params={{name: header.name}}/>
            : ''}
        </FileTitle>
      </InputRow>
      <InputRow>
        <InputHeader>
          <Text variant={ETextVariants.Body16}>
            <T keyName="companyForm.fields.description.label">Описание</T>
          </Text>
        </InputHeader>
        <TextAreaStyled
          {...register(('description'))}
          minRows={2}
          placeholder={t('companyForm.fields.description.placeholder',"Описание")}
        />
      </InputRow>

      <InputRow>
        <Input
          wth={'100%'}
          label={t('companyForm.fields.site.label','Сайт')}
          placeholder={t('companyForm.fields.site.placeholder','Введите сайт')}
          {...register('site', {
            required: true,
            pattern: /[a-zA-Z0-9 ]{2,50}/,
          })}
        />
      </InputRow>
      <InputRow>
        <Text variant={ETextVariants.Body16}>
          <T keyName="companyForm.fields.location.label">Выберите место на карте</T>
        </Text>
        <HomeMapStyled
          createEvent
          position={position}
          center={ [55.751244, 37.618423]}
          setPosition={val => {
            setPosition(val)
            setFormError(null)
          }}
          setAddress={value => {
            setAddressMap(value)
            setFormError(null)
          }}
        />
        {formError === t("companyForm.errors.location",'Выберите место на карте!') &&
            <ErrorForm variant={ETextVariants.Error}>{formError}</ErrorForm>}
      </InputRow>

      <InputRow>
        <Text variant={ETextVariants.Body16}>
          <T keyName="companyForm.fields.address.label">Адрес</T>
        </Text>
        <Input
          placeholder={t('companyForm.fields.address.placeholder', 'Например, Тверская улица, дом 15, кв 43')}
          wth="100%"
          ref={addressRef}
          onChange={() => {}}
          disabled={true}
        />
      </InputRow>
      <InputRow>

        <InputHeader>
          <Text variant={ETextVariants.Body16}>
            <T keyName="companyForm.fields.phone.label">Номер телефона</T>
          </Text>
        </InputHeader>
        <PhoneInputBlock>
          <PhoneInput
            country="lv"
            placeholder=""
            inputProps={{name: 'phoneNumber'}}
            value={phoneNumberValue}
            onChange={setPhoneNumberValue}
          />
        </PhoneInputBlock>
      </InputRow>
      <InputRow>
        <Input
          placeholder={t('companyForm.fields.email.label',"Введите email")}
          wth={'100%'}
          type="email"
          label={t('companyForm.fields.email.label',"Email")}
          {...register('email', {
            required: true,
          })}
        />
      </InputRow>

      <InputRow>
        <Input
          placeholder={t("companyForm.fields.registrationNumber.label", "Reg. NR")}
          wth={'100%'}
          label={t("companyForm.fields.registrationNumber.label", "Reg. NR")}
          {...register('registrationNumber', {
            required: true,
          })}
        />
      </InputRow>

      <InputRow>
        <Input
          placeholder={t("companyForm.fields.taxPayerNumber.label", "Банк, IBAN")}
          wth={'100%'}
          label={t("companyForm.fields.taxPayerNumber.label", "Банк, IBAN")}
          {...register('taxPayerNumber', {
            required: true,
          })}
        />
      </InputRow>
      <InputRow>
        <Input
          placeholder={t("companyForm.fields.companyId.label", "Банк, IBAN")}
          wth={'100%'}
          label={t("companyForm.fields.companyId.label", "Банк, IBAN")}
          {...register('companyId', {
          })}
        />
      </InputRow>
      <InputRow>
        <Dropdown label={t('common.currency','Валюта')} placeholder={t('common.currency','Валюта')} options={options} value={currency}
                  onSelect={(val) => setCurrency(String(val))}/>
        {formError === t("companyForm.errors.currency",'Выберите валюту') &&
            <ErrorForm variant={ETextVariants.Error}>{formError}</ErrorForm>}
      </InputRow>
      <InputRow>
        <Checkbox label={t('companyForm.fields.showCompanyContacts', 'Показывать контакты компании')} checked={isShowCompanyContacts}
                  onChange={event => setIsShowCompanyContacts(event.target.checked)} id="showCompanyContacts"/>
      </InputRow>
      <InputRow>
        <Checkbox label={
          <T
            keyName="companyForm.fields.agree"
            params={{
              a: <LinkStyled to={`/terms`}/>,
            }}
            defaultValue={`Я прочитал и согласен с <LinkStyled to={"/terms/"}>правилами </LinkStyled>`}
          />} checked={isAgree}
                  onChange={event => setIsAgree(event.target.checked)} id="agree"/>
      </InputRow>
      {!isLoading
        ?
        <>
          <ButtonStyled disabled={!isAgree} $fullWidth variant={EButtonVariants.Primary}>
            <T keyName="common.done">Готово</T>
          </ButtonStyled>
          <MobileButton disabled={!isAgree} variant={EButtonVariants.Secondary}>
            <T keyName="common.done">Готово</T>
          </MobileButton>
        </>
        : <LoadingIcon>
          <SpinnerIcon/>
        </LoadingIcon>
      }
      {isShowModal &&
          <CreateEventModal isModalClose={() => {
            setIsShowModal(false)
            if (!isEdit && !isCreateAdmin) {
              navigate('/profile/settings')
            }
          }} title={bodyModal.title} body={bodyModal.body}/>}
    </FormStyled>
  );
}

export default FirmFormView;
