import {useEffect, useMemo, useState} from "react";

const options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};
export default function useGeolocation() {
  const [geoLocation, setGeoLocation] = useState<[number | null, number | null]>([null, null])
  function success(pos: GeolocationPosition) {
    const crd = pos.coords;
    setGeoLocation([crd.latitude, crd.longitude])
  }
  function errors(err: GeolocationPositionError) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          if (result.state === "granted") {
            //If granted then you can directly call your function here
            navigator.geolocation.getCurrentPosition(success, errors, options);
          } else if (result.state === "prompt") {
            //If prompt then the user will be asked to give permission
          } else if (result.state === "denied") {
            //If denied then you have to show instructions to enable location
          }
        }).catch(e => console.log(e));
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, [])

  return useMemo(() => ({
    geoLocation
  }), [geoLocation])
}
