import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="20" height="22" viewBox="0 0 20 22" fill="none" {...props} >
          <path d="M19.1527 9.89945L10.1371 18.9151C8.08686 20.9653 4.76275 20.9653 2.71249 18.9151C0.662241 16.8648 0.662242 13.5407 2.71249 11.4904L11.7281 2.47483C13.0949 1.108 15.311 1.108 16.6779 2.47483C18.0447 3.84167 18.0447 6.05775 16.6779 7.42458L8.01579 16.0866C7.33238 16.7701 6.22434 16.7701 5.54092 16.0866C4.8575 15.4032 4.8575 14.2952 5.54092 13.6118L13.1423 6.01037" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </Svg>
    );

export default Icon;
