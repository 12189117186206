import React, {useCallback} from 'react';
import styled from "styled-components";
import {Card} from "../Templates/Card";
import Text from "../../UI/Text";
import {Button, EButtonVariants} from "../../UI/Button";
import {ETextVariants} from "../../UI/Text/types";
import {useAdminSupportsAction} from "../../store/adminSupports/hooks";
import {IReport} from "../../store/adminSupports/types";
import {Link} from "react-router-dom";
import {getUserName} from "../../utils/user";
import {T, useTranslate} from "@tolgee/react";

const Row = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  gap: 20px;
`

const Wrapper = styled(Card)`
  width: 100%;
  max-width: calc(50% - 10px);
  display: flex;
  align-items: stretch;
  gap: 15px;
  flex-direction: column;

  ${({theme}) => theme.mediaQueries.md} {
    max-width: 100%;
  }
`

const Label = styled(Text)`
  color: ${({theme}) => theme.colors.mainGray};
`

const Buttons = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 15px;
`

const ButtonStyled = styled(Button)`
  padding: 11.5px 16px;
  font-size: 14px;
`

const Desc = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
`

const LinkStyled = styled(Link)`
  color: ${({theme}) => theme.colors.mainPurple};
`

function AdminReport({id, body, event, author}: IReport) {
  const {t} = useTranslate()

  const {onRejectReport, onApproveReport} = useAdminSupportsAction()

  const onApproveEventHandle = useCallback(() => {
    if (window.confirm(t('admin.support.confirmApprove', 'Вы действительно хотите рассмотреть жалобу?'))) {
      onApproveReport(`${id}`)
    }
  }, [id, onApproveReport, t])
  const onRejectEventHandle = useCallback(() => {
    if (window.confirm(t('admin.support.confirmReject', 'Вы действительно хотите отклонить жалобу?'))) {
      onRejectReport(`${id}`)
    }
  }, [id, onRejectReport, t])
  return (
    <Row>
      <Wrapper>
        <Desc>
          <Label variant={ETextVariants.Body16}>№{id}</Label>
          <Text variant={ETextVariants.Body16}><T keyName="admin.support.author">Автор:</T> <LinkStyled
            to={`/profile/${author.id}`}>{getUserName(author)}</LinkStyled></Text>
          <Text variant={ETextVariants.Body16}>
            <T
              keyName="admin.support.complaint"
              params={{
                a: <LinkStyled to={`/about-the-event/${event.id}`}/>,
                event: event.id
              }}
              defaultValue={`Жалоба на: <LinkStyled to={"/about-the-event/" + event.id}>ивент №{event.id}</LinkStyled>`}
            />
          </Text>
        </Desc>
        <Buttons>
          <ButtonStyled variant={EButtonVariants.Success}
                        onClick={() => onApproveEventHandle()}>
            <T keyName="admin.support.approve">Рассмотреть</T>
          </ButtonStyled>
          <ButtonStyled variant={EButtonVariants.Error} onClick={() => onRejectEventHandle()}>
            <T keyName="admin.support.reject">Отклонить</T>
          </ButtonStyled>
        </Buttons>
      </Wrapper>
      <Wrapper>
        <Label variant={ETextVariants.Body16}>№{id}</Label>
        <Text variant={ETextVariants.Body16}><T keyName="admin.support.author">Автор:</T> <LinkStyled
          to={`/profile/${author.id}`}>{getUserName(author)}</LinkStyled></Text>
        <Text variant={ETextVariants.Body16}>{body}</Text>
        <Buttons>
          <ButtonStyled variant={EButtonVariants.Success}
                        onClick={() => onApproveEventHandle()}>
            <T keyName="admin.support.approve">Рассмотреть</T>
          </ButtonStyled>
          <ButtonStyled variant={EButtonVariants.Error} onClick={() => onRejectEventHandle()}>
            <T keyName="admin.support.reject">Отклонить</T>
          </ButtonStyled>
        </Buttons>
      </Wrapper>
    </Row>
  );
}

export default AdminReport;
