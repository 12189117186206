import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {useServicesAction, useServicesState} from "../../store/services/hooks";
import styled from "styled-components";
import {Container} from "../../components/Templates/Container";
import {Card} from "../../components/Templates/Card";
import {mediaUrl} from "../../api";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import Dots from "../../UI/Dots";
import {AddDNDIcon, CalendarIcon, DeleteIcon, GeoGrayIcon, PlusIcon, PriceIcon, SmileIcon} from "../../UI/Svg";
import {currencyDesc} from "../../config/currency";
import dayjs from "dayjs";
import {T} from "@tolgee/react";
import {Button, EButtonVariants} from "../../UI/Button";
import card1 from '../../assets/img/MIR.png'
import card2 from '../../assets/img/Visa.png'
import card3 from '../../assets/img/Mastercard.png'
import {useCartAction, useCartState} from "../../store/cart/hooks";
import FormCountComponent from "../../components/ServicesComponents/FormCountComponent";

const ContainerStyled = styled(Container)`
  flex-direction: column;
  gap: 30px;
`

const Cards = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  justify-content: flex-start;
`

const HeaderCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: flex-start;
  width: 100%;
  position: relative;
`

const HeaderService = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;
`

const ImageBlock = styled.div`
  width: 100%;
  max-width: 109px;
  height: 70px;
  border-radius: 18px;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
  }

  ${({theme}) => theme.mediaQueries.md} {
    height: 234px;
  }
`

const TitleBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
`

const Title = styled(Text)`
  font-size: 22px;
`

const DotsWrapper = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
`

const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const Price = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 3px;
`

const PriceTitle = styled(Text)`
  color: ${({theme}) => theme.colors.mainGray};
`

const CardDesc = styled(Card)`
  display: flex;
  flex-direction: column;
  overflow: visible;
  width: 100%;

  ${({theme}) => theme.mediaQueries.md} {
    border-radius: 0;

    &:first-child {
      background: ${({theme}) => theme.colors.bg};
    }
  }
`

const List = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
`

const Item = styled.li`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;

  svg {
    width: 24px;
    height: 24px;
  }

  ${({theme}) => theme.mediaQueries.md} {
    svg {
      width: 20px;
      height: 20px;
    }
  }
`

const TextStyled = styled(Text)`
  ${({theme}) => theme.mediaQueries.md} {
    font-size: 12px;
  }
`
const Members = styled(TextStyled)`
  white-space: pre;
  color: ${({theme}) => theme.colors.mainPurple};
  overflow: hidden;
  cursor: pointer;
`

const ButtonBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
`

const PaymentCards = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0;
  img {
    width: 100px;
    height: auto;
  }
`

function CreatePaymentPage() {
  const {id} = useParams()
  const {currentService, isLoading} = useServicesState()
  const {onAddCartItem} = useCartAction()
  const {isPending} = useCartState()
  const {onFetchService, onRemoveCurrentService} = useServicesAction()

  const [count, setCount] = useState<number>(1);

  useEffect(() => {
    return () => onRemoveCurrentService()
  }, [])
  useEffect(() => {
    if (typeof id !== "undefined") {
      onFetchService(parseInt(id, 10))
    }
  }, [id, onFetchService])

  const onChangeCount = (isAdd: boolean) => setCount(prevState => {
    if (isAdd) return prevState + 1
    return prevState - 1 < 1 ? 1 : prevState - 1
  })

  if (!currentService) return null
  return (
    <ContainerStyled>
      <Cards>
        <HeaderCard>
          <DotsWrapper>
            <Dots items={[]}/>
          </DotsWrapper>
          <HeaderService>
            <ImageBlock>
              <img src={currentService.header ? `${mediaUrl}file/${currentService.header.id}` : ''} alt=""/>
            </ImageBlock>
            <TitleBlock>
              <Title variant={ETextVariants.Heading} as={'h2'}>{currentService.title}</Title>
            </TitleBlock>
          </HeaderService>
          <FormRow>
            <FormCountComponent value={count} label={'Билеты'} onIncrement={() => onChangeCount(true)} onDecrement={() => onChangeCount(false)} />

            <Price>
              <PriceTitle variant={ETextVariants.Body16}>К оплате</PriceTitle>
              <Text variant={ETextVariants.Heading}>{count * currentService.price}
                <T keyName={`currencies.${currentService.currency}.symbol`}>{currencyDesc[currentService.currency].symbol}</T>
              </Text>
            </Price>
          </FormRow>
        </HeaderCard>
        <CardDesc>
          <List>
            {currentService.expirationDate && <Item>
              <CalendarIcon/>
              <TextStyled
                variant={ETextVariants.Body16}> {dayjs(currentService.expirationDate).format('D MMMM / HH:mm')}</TextStyled>
            </Item>}
            {currentService?.address && <Item>
              <GeoGrayIcon/>
              <TextStyled variant={ETextVariants.Body16}>{currentService?.address?.fullString}</TextStyled>
            </Item>}

            <Item>
              <PriceIcon/>
              <TextStyled variant={ETextVariants.Body16}>{currentService.price}
                <T keyName={`currencies.${currentService.currency}.symbol`}>{currencyDesc[currentService.currency].symbol}</T>
              </TextStyled>
            </Item>
            <Item>
              <SmileIcon/>
              <Members variant={ETextVariants.Body16}>
                <T
                  keyName="event.number_of_members_1"
                  params={{numberOfMembers: 0, maxNumberOfMembers: currentService.maxCustomers}}
                />
                &nbsp;<T keyName="event.number_of_members_2">участников уже присоединились</T>
              </Members>
            </Item>
          </List>
        </CardDesc>
      </Cards>
      <ButtonBlock>
        <Button disabled={isPending || count < 1} variant={EButtonVariants.Primary} onClick={() => onAddCartItem({favorId: currentService.id, quantity: count})} $fullWidth>Оплатить</Button>
        <PaymentCards>
          <img src={card1} alt=""/>
          <img src={card2} alt=""/>
          <img src={card3} alt=""/>
        </PaymentCards>
      </ButtonBlock>
    </ContainerStyled>
  );
}

export default CreatePaymentPage;
