import {useEffect, useRef, useState} from 'react';

import WaveSurfer from 'wavesurfer.js';
import styled from "styled-components";
import {PauseIcon, PlayIcon} from "../../UI/Svg";

const Wafe = styled.div`
  width: 200px;
  display: flex;
  align-items: center;
  gap: 10px;

  ${({theme}) => theme.mediaQueries.md} {
    max-width: 100%;
  }

`

const Btn = styled.button`
  min-width: 30px;
  min-height: 30px;
  display: flex;
  background: transparent;
  border: none;
  padding: 0;
  align-items: center;
  justify-content: center;

  svg {
    width: 24px;
    height: 24px;
  }

  ${({theme}) => theme.mediaQueries.md} {
    min-width: 30px;
    min-height: 30px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
`

const Road = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  ${({theme}) => theme.mediaQueries.md} {
    height: 100%;
  }
`


const Left = styled.div`
  width: 100%;
  cursor: pointer;
`
const Right = styled.div`
  color: #BCBCBC;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  letter-spacing: 0.28px;
`

const formWaveSurferOptions = (ref: any) => ({
  container: ref,
  waveColor: '#BCBCBC',
  progressColor: '#6a1b9a',
  cursorColor: '#6a1b9a',
  cursorWidth: 2,
  barWidth: 2,
  barHeight: 1,
  barRadius: 10,
  barGap: 1,
  responsive: true,
  height: 30,
  normalize: true,
  partialRender: true,
});

export default function Waveform({audio, ...props}: any) {
  const waveformRef = useRef<any>(null);
  const wavesurfer = useRef<any>(null);
  const [playing, setPlay] = useState<boolean>(false);

  useEffect(() => {
    setPlay(false);
    const options = formWaveSurferOptions(waveformRef.current);
    wavesurfer.current = WaveSurfer.create(options);
    wavesurfer.current.load(audio?.size ? URL?.createObjectURL(audio) : audio);

    wavesurfer.current.on('ready', function () {
      const el = document.querySelector(`[data-uid='${audio}']`)
      if (el) {
        el.textContent = minFormat(
          wavesurfer.current.getDuration(),
        );
      }
    });

    wavesurfer.current.on('audioprocess', function () {
      if (wavesurfer.current.isPlaying()) {
        const currentTime = wavesurfer.current.getCurrentTime();
        const el = document.querySelector(`[data-uid='${audio}']`)
        if (el) {
          el.textContent = minFormat(currentTime);
        }
      }
    });

    wavesurfer.current.on('finish', () => {
      setPlay((prev) => !prev);
    });

    wavesurfer.current.on('click', function () {
      const currentTime = wavesurfer.current.getCurrentTime();
      const el = document.querySelector(`[data-uid='${audio}']`)
      if (el) {
        el.textContent = minFormat(currentTime);
      }
    });
    return () => wavesurfer.current.destroy();

  }, [audio]);

  const minFormat = (sec: number) => {
    const m = Math.floor((sec % 3600) / 60)
      .toString()
      .padStart(2, '0');
    const s = Math.floor(sec % 60)
      .toString()
      .padStart(2, '0');
    return `${m}:${s}`;
  };

  const handlePlayPause = () => {
    setPlay(prevState => !prevState);
    try {
      wavesurfer.current.playPause()
        .then((res: any)=> console.log(res))
        .catch((err: any)=> console.log(err))
    }catch (e) {
      console.log(e);
    }
  };

  return (
    <Wafe {...props}>
      <Btn onClick={(event) => {
        event.preventDefault()
        handlePlayPause()
      }}>
        {!playing ? <PlayIcon/>
          : <PauseIcon/>}
      </Btn>
      <Road>
        <Left id="waveform" ref={waveformRef}/>
        <Right data-uid={audio}/>
      </Road>
    </Wafe>
  );
}
