import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IServicesFilter} from "./types";
import {ServiceResponse} from "../adminServices/types";
import fetchCompanyServices from "./fetchCompanyServices";
import fetchServices from "./fetchServices";
import fetchService from "./fetchService";
import _ from "lodash";

export type ServicesState = {
    all: ServiceResponse[],
    companyServices: ServiceResponse[],
    currentService: ServiceResponse | null,
    isLoading: boolean,
    isLoadingService: boolean,
    isLoadingMore: boolean,
    isEnd: boolean,
    filter: IServicesFilter
}

const LIMIT = 20

const initialState: ServicesState = {
    all: [],
    companyServices: [],
    isLoadingMore: false,
    isEnd: false,
    currentService: null,
    isLoading: true,
    isLoadingService: true,
    filter: { limit: LIMIT, page: 1 },
}

export const servicesSlice = createSlice({
    name: 'services',
    initialState,
    reducers: {
        changeFilter: (state, action: PayloadAction<IServicesFilter>) => {
            state.filter = {...state.filter, ...action.payload, page: 1}
            state.all = []
        },
        removeCurrentService: (state, ) => {
            state.currentService = null
        },
        changePage: (state,) => {
            if (state.filter.page !== undefined) {
                state.filter.page += 1
            } else {
                state.filter.page = 1
            }
        },
        resetFilter: (state, action: PayloadAction<IServicesFilter | undefined>) => {
            if (action.payload) {
                state.filter = action.payload
            } else {
                state.filter = initialState.filter
            }
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchServices.fulfilled, (state, action: PayloadAction<ServiceResponse[]>) => {
            state.isEnd = action.payload.length < (state.filter.limit || LIMIT)
            state.all = _.uniqBy([...state.all, ...action.payload], 'id')
            state.isLoading = false
            state.isLoadingMore = false
        })
        builder.addCase(fetchServices.pending, (state) => {
            if (state.filter.page && state.filter.page === 1) {
                state.isLoading = true
            } else {
                state.isLoadingMore = true
            }
        })
        builder.addCase(fetchServices.rejected, (state) => {
            state.isLoading = false
            state.isLoadingMore = false
        })
        builder.addCase(fetchCompanyServices.fulfilled, (state, action: PayloadAction<ServiceResponse[]>) => {
            state.companyServices = action.payload
            state.isLoading = false
        })
        builder.addCase(fetchCompanyServices.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchCompanyServices.rejected, (state) => {
            state.isLoading = false
        })
        builder.addCase(fetchService.fulfilled, (state, action: PayloadAction<ServiceResponse>) => {
            state.currentService = action.payload
            state.isLoading = false
        })
        builder.addCase(fetchService.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchService.rejected, (state) => {
            state.isLoading = false
        })
    }
})

export const {changeFilter, resetFilter, removeCurrentService, changePage} = servicesSlice.actions
export default servicesSlice.reducer
