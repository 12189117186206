import {ButtonTheme, EButtonVariants} from "./types";
import {lightColors} from "../../theme/colors";


export const light: ButtonTheme = {
    [EButtonVariants.Primary]: {
        backgroundColor: lightColors.mainPurple,
        border: 'none',
        color: lightColors.white,
        hover: {
            backgroundColor: lightColors.lightPurple,
            color: lightColors.white,
        },
        active: {
            backgroundColor: lightColors.lightPurple0,
            color: lightColors.white,
        },
        disabled: {
            backgroundColor: lightColors.lightWhite,
            color: lightColors.lightGray,
        }
    },
    [EButtonVariants.Secondary]: {
        backgroundColor: 'transparent',
        border: `1px solid ${lightColors.lightPurple}`,
        color: lightColors.mainPurple,
        hover: {
            backgroundColor: lightColors.mainPurple,
            color: lightColors.white,
        },
        active: {
            backgroundColor: 'transparent',
            color: lightColors.mainPurple,
        },
        disabled: {
            backgroundColor: 'transparent',
            color: lightColors.mainPurple,
        }
    },
    [EButtonVariants.Success]: {
        backgroundColor: lightColors.successButtonBg,
        border: 'none',
        color: lightColors.white,
        hover: {
            backgroundColor: lightColors.successButtonBgHover,
            color: lightColors.white,
        },
        active: {
            backgroundColor: lightColors.successButtonBgActive,
            color: lightColors.white,
        },
        disabled: {
            backgroundColor: lightColors.successButtonBgDisabled,
            color: lightColors.white,
        }
    },
    [EButtonVariants.Error]: {
        backgroundColor: lightColors.errorButtonBg,
        border: 'none',
        color: lightColors.white,
        hover: {
            backgroundColor: lightColors.errorButtonBgHover,
            color: lightColors.white,
        },
        active: {
            backgroundColor: lightColors.errorButtonBgActive,
            color: lightColors.white,
        },
        disabled: {
            backgroundColor: lightColors.errorButtonBgDisabled,
            color: lightColors.white,
        }
    },
};
