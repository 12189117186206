import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {ProfileEntity} from "./types";
import {ProfileApi} from "../../api/profile";
import {handle} from "../../api";
import {logout} from "../auth/authSlice";



const fetchProfile = createAsyncThunk<ProfileEntity,
  void,
  {
    state: RootState
  }>(
  'profile/fetchProfile',
  async (_, ThunkApi) => {
    const authState = ThunkApi.getState().auth
    if (authState.auth) {
      const [profileRes, profileErr] = await handle(ProfileApi.getProfile())
      if (profileRes) {
        return profileRes
      }
      if (profileErr) {
        ThunkApi.dispatch(logout())
        throw {message: profileErr.message[0] || profileErr.message}
      }
    } else {
      ThunkApi.dispatch(logout())
    }
  }
)

export default fetchProfile
