import React, {useEffect} from 'react';
import AdminHeader from "../../../components/AdminComponents/AdminHeader";
import PaymentsListView from "../../../views/AdminPaymentsViews/PaymentsListView";
import {useAdminPayments, useAdminPaymentsAction, useAdminPaymentsState} from "../../../store/adminPayments/hooks";
import PaymentsAllFilterView from "../../../views/AdminPaymentsViews/PaymentsAllFilterView";
import LoadingSpinner from '../../../UI/LoadingSpinner'
import {useTranslate} from "@tolgee/react";

function AllPaymentsPage() {
  const {t} = useTranslate()
  useAdminPayments()
  const {onClearSelectCompany, onClearSelectService} = useAdminPaymentsAction()
  const {isLoading} = useAdminPaymentsState()

  useEffect(() => {
    onClearSelectCompany()
    onClearSelectService()
  }, [])
  return (
    <>
      <AdminHeader title={t('admin.menu.payments','Платежи')}/>
      <PaymentsAllFilterView/>
      {
        isLoading
        ? <LoadingSpinner/>
          : <PaymentsListView/>
      }
    </>
  );
}

export default AllPaymentsPage;
