import {Middleware} from "@reduxjs/toolkit";
import {io, Socket} from "socket.io-client";
import {
  connectionEstablished,
  deleteChat,
  deleteMessage,
  disconnectSocket,
  joinRoom,
  leaveRoom,
  reactEventInvitation, readMessage,
  removeUserTyping,
  setChatList,
  setMessage,
  setUpdateChat,
  setUserOnline,
  setUserTyping,
  startConnecting,
  startTyping,
  stopTyping,
  submitMessage, updateUnreadChats
} from "./chatSlice";
import {EChatEvents, TMessageType} from "./types";

const wssUrl = 'wss://triphouse.lv'

const chatMiddleware: Middleware = store => {
  let socket: Socket | null;
  return next => action => {
    // @ts-ignore
    const isConnectionEstablished = socket && store.getState().chat.isConnected;
    const {token} = store.getState().auth
    if (startConnecting.match(action)) {
      if (!socket) {
        socket = io(wssUrl, {
          // autoConnect: false,
          extraHeaders: {
            Authorization: `Bearer ${token}`,
          }
        });
      } else {
        socket.connect()
      }
      socket.on('connect', () => {
        store.dispatch(connectionEstablished());
      })
      socket.on('onChatMessage', (event) => {
        const messageType: TMessageType = event.messageType
        switch (messageType) {
          case "chatsList":
            store.dispatch(setChatList(event.data))
            break
          case "chat":
            store.dispatch(setUpdateChat(event.data))
            break
          case "unreadMessages":
            store.dispatch(updateUnreadChats(event.data))
            break
          case "message":
            store.dispatch(setMessage(event.data))
            break
          default:
        }
      })
      socket.on('onUserOnline', (event) => {
        const messageType: TMessageType = event.messageType
        switch (messageType) {
          case "usersOnline":
            store.dispatch(setUserOnline(event.data))
            break
          default:
        }
      })
      socket.on('onChatTyping', (event) => {
        const messageType: TMessageType = event.messageType
        switch (messageType) {
          case "startTyping":
            store.dispatch(setUserTyping(event.data))
            break
          case "stopTyping":
            store.dispatch(removeUserTyping(event.data))
            break
          default:
        }
      })
      socket.on('disconnect', () => {
        socket?.removeAllListeners();
      });
    }

    if (disconnectSocket.match(action) && isConnectionEstablished) {
      socket?.disconnect()
      socket?.removeAllListeners()
      socket = null
    }
    if (joinRoom.match(action) && isConnectionEstablished) {
      socket?.emit(EChatEvents.JOIN_ROOM, {chatId: action.payload});
    }
    if (leaveRoom.match(action) && isConnectionEstablished) {
      socket?.emit(EChatEvents.LEAVE_ROOM, {chatId: action.payload});
    }
    if (submitMessage.match(action) && isConnectionEstablished) {
      socket?.emit(EChatEvents.SEND_MESSAGE, action.payload);
    }
    if (stopTyping.match(action) && isConnectionEstablished) {
      socket?.emit(EChatEvents.STOP_TYPING, action.payload);
    }
    if (startTyping.match(action) && isConnectionEstablished) {
      socket?.emit(EChatEvents.START_TYPING, action.payload);
    }
    if (deleteChat.match(action) && isConnectionEstablished) {
      socket?.emit(EChatEvents.DELETE_CHAT, action.payload);
    }
    if (deleteMessage.match(action) && isConnectionEstablished) {
      socket?.emit(EChatEvents.DELETE_MESSAGE, action.payload);
    }
    if (readMessage.match(action) && isConnectionEstablished) {
      socket?.emit(EChatEvents.READ_MESSAGE, action.payload);
    }
    if (reactEventInvitation.match(action) && isConnectionEstablished) {
      socket?.emit(EChatEvents.REACT_EVENT_INVITATION, action.payload);
    }

    next(action);
  }
}

export default chatMiddleware;
