import {useDispatch, useSelector} from "react-redux";
import {useCallback, useEffect, useMemo} from "react";
import {AppDispatch, RootState} from "../index";
import {changeFilter} from "./adminSupportsSlice";
import {IAdminSupportsFilter} from "./types";
import fetchReports from "./fetchReports";
import fetchApproveReport from "./fetchApproveReport";
import fetchRejectReport from "./fetchRejectReport";

export const getAdminSupports = (state: RootState) => state.adminSupports

export function useAdminSupportsAction() {
  const dispatch = useDispatch<AppDispatch>()
  const onChangeFilter = useCallback((data: IAdminSupportsFilter) => {
    dispatch(changeFilter(data))
  }, [dispatch])
  const onFetchReports = useCallback(() => {
    dispatch(fetchReports())
  }, [dispatch])
  const onApproveReport = useCallback((id: string) => {
    dispatch(fetchApproveReport(id))
      .unwrap()
      .then(() => onFetchReports())
      .catch(e => console.log(e.message || e))
  }, [dispatch, onFetchReports])
  const onRejectReport = useCallback((id: string) => {
    dispatch(fetchRejectReport(id))
      .unwrap()
      .then(() => onFetchReports())
      .catch(e => console.log(e.message || e))
  }, [dispatch, onFetchReports])
  return useMemo(() => ({
    onChangeFilter,
    onFetchReports,
    onApproveReport,
    onRejectReport,
  }), [
    onChangeFilter,
    onFetchReports,
    onApproveReport,
    onRejectReport,
  ])
}

export function useAdminSupportsState() {
  return useSelector(getAdminSupports)
}

export function useAdminSupports() {
  const {filter} = useAdminSupportsState()
  const {onFetchReports} = useAdminSupportsAction()

  useEffect(() => {
    onFetchReports()
  }, [filter, onFetchReports])

}
