import React, {useEffect, useRef} from 'react';
import {useServicesAction, useServicesState} from "../../store/services/hooks";
import ShortEvent from "../../components/HomeComponents/ShortEvent";
import styled from "styled-components";
import ShortService from "../../components/HomeComponents/ShortService";
import Draggable from "../../components/Templates/Draggable";

const ServicesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  overflow: auto;
  margin-top: 20px;

  ${({theme}) => theme.mediaQueries.md} {
    padding: 0 10px;
    gap: 10px;
  }
`
const DivMore = styled.div`
  height: 0;
  width: 100%;
`

const ScrollWrapper = styled(Draggable)`
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
`

function HomeServices() {
  const {all, isEnd, isLoadingMore} = useServicesState()
  const {onChangePage} = useServicesAction()

  const divMore = useRef<HTMLDivElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)

  const onVisibleDivMore = () => {
    const el = divMore.current
    const wrapper = wrapperRef.current
    if (el && wrapper) {
      const rect = el.getBoundingClientRect()
      const rectWrapper = wrapper.getBoundingClientRect()
      if (rect.bottom === rectWrapper.bottom) onChangePage()
      else if (rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)) onChangePage()
    }
  }

  const handleScroll = () => {
    onVisibleDivMore()
  };

  useEffect(() => {
    onVisibleDivMore()
  }, [])
  return (
    <ScrollWrapper onScroll={handleScroll} ourRef={wrapperRef}>
      <ServicesList>
        {all.map((service, id) => <ShortService key={id} {...service}/>)}
        {(!isEnd && !isLoadingMore) && <DivMore ref={divMore}/>}
      </ServicesList>
    </ScrollWrapper>
  );
}

export default HomeServices;
