import {axiosInstance} from "./index";

export const SubscriptionApi = {
    postSubscribe: async (id: number) => await axiosInstance.post(`sub/subscribe/user/${id}`),
    delSubscribe: async (id: number) => await axiosInstance.delete(`sub/unsubscribe/user/${id}`),
    getSubscribers: async (id: number) => await axiosInstance.get(`sub/subscribers/user/${id}`),
    getSubscriptions: async (id: number) => await axiosInstance.get(`sub/subscriptions/user/${id}`),
    postSubscribeCompany: async (id: number) => await axiosInstance.post(`sub/subscribe/company/${id}`),
    delSubscribeCompany: async (id: number) => await axiosInstance.delete(`sub/unsubscribe/company/${id}`),
    getSubscribersCompany: async (id: number) => await axiosInstance.get(`sub/subscribers/company/${id}`),
    getSubscriptionsCompany: async (id: number) => await axiosInstance.get(`sub/subscriptions/company/${id}`),
}
