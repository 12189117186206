import React from 'react';
import {useChatState} from "../../store/chat/hooks";
import styled from "styled-components";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";

const Wrapper = styled.div`
  min-width: 1.7rem;
  padding: 0.3rem;
  height: 1.7rem;
  border-radius: 100%;
  background: ${({theme}) => theme.colors.mainRed};
  position: absolute;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  left: 1.1rem;
  top: -0.9rem;
`

const TextStyled = styled(Text)`
  color: ${({theme}) => theme.colors.white};
`

function NotificationsMessages({...props}: any) {
  const {countUnread} = useChatState()
  if (countUnread > 0) {
    return (
      <Wrapper {...props}>
        <TextStyled variant={ETextVariants.MiniGray}>{countUnread}</TextStyled>
      </Wrapper>
    );
  }
  return null
}

export default NotificationsMessages;
