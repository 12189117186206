import {useCallback, useEffect, useMemo, useState} from "react";
import {loadStripe} from "@stripe/stripe-js";
import Stripe from "stripe";
import {useNavigate} from "react-router-dom";
import {STRIPE_PK_KEY, STRIPE_SK_KEY} from "../config/stripe";

const stripe = new Stripe(STRIPE_SK_KEY)

const stripePromise = loadStripe(STRIPE_PK_KEY);

export function useCustomStripe(customerId?: string | null) {
  const navigate = useNavigate()
  const [isPending, setIsPending] = useState<boolean>(false)
  const [clientSecret, setClientSecret] = useState("");
  const [message, setMessage] = useState<string | null>(null);
  const [payments, setPayments] = useState<Stripe.PaymentMethod[]>([]);
  const getPaymentIntent = useCallback(() => {
    if (customerId) {
      stripe.customers.listPaymentMethods(
        customerId,
        {
          type: 'card',
          limit: 10,
        }
      ).then((res) => setPayments(res.data));
    }
  }, [customerId])
  useEffect(() => {
    getPaymentIntent()
  }, [getPaymentIntent])
  const createPaymentIntent = useCallback((params: {
    amount: number,
    currency: string,
    customer: string,
    metadata: {[key: string]: string | number | null}
    payment_method?: string,
  }) => {
    setIsPending(true)
    setMessage(null)
    const data: Stripe.PaymentIntentCreateParams = {
      ...params,
      automatic_payment_methods: {
        enabled: true,
      }
    }
    if (params.payment_method) {
      data.confirm = true
      data.return_url = `${window.origin}/payment/complete`
    } else {
      data.setup_future_usage = "off_session"
    }
    stripe.paymentIntents.create(data)
      .then((paymentIntent) => {
        switch (paymentIntent.status) {
          case "succeeded":
            navigate(`/payment/complete?payment_intent=${paymentIntent.id}`)
            break;
          case "processing":
            setMessage("Your payment is processing.");
            break;
          case "requires_payment_method":
            if (paymentIntent.client_secret) setClientSecret(paymentIntent.client_secret)
            break;
          default:
            setMessage("Something went wrong.");
            break;
        }
      })
      .finally(() => setIsPending(false))
  }, [navigate])

  return useMemo(() => ({
    stripePromise,
    createPaymentIntent,
    payments,
    message,
    isPending,
    options: {
      clientSecret
    },
  }), [isPending,createPaymentIntent, payments, clientSecret, message])

}
