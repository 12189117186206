import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="59" height="59" viewBox="0 0 59 59" fill="none" {...props} >
          <circle cx="29.3114" cy="29.3114" r="29.3114" fill="#6A1B9A"/>
          <rect x="20" y="17" width="5" height="24" rx="2.5" fill="white"/>
          <rect x="33" y="17" width="5" height="24" rx="2.5" fill="white"/>
        </Svg>
    );

export default Icon;
