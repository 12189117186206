import React, {forwardRef, useMemo, useState} from 'react';
import {InputProps, InputTagProps} from "./type";
import styled from "styled-components";
import Text from "../Text";
import {ETextVariants} from "../Text/types";
import {getTextStyle} from "../Text/utils";
import {EPallets} from "../../theme/types";
import {HelperIcon} from "../Svg";
import {T, useTranslate} from "@tolgee/react";

const Wrapper = styled.div<{ mt?: string, wth?: string }>`
  display: flex;
  position: relative;
  flex-direction: column;
  ${({mt}) => mt ? `margin-top: ${mt}` : ''};
  ${({wth}) => wth ? `width: ${wth}` : ''};
`

const Label = styled(Text)`
  margin-bottom: 2px;
  position: relative;
`
const ErrorText = styled(Text)`
  margin-top: 2px;
  margin-left: 10px;
`


const IconContainer = styled.div({
  position: 'absolute',
  pointerEvents: 'none',
  bottom: 0,
  left: '2rem',
  height: '100%',
  width: '2rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const StyledInput = styled.input<InputTagProps>`
  position: relative;
  width: 100%;
  padding: 1rem 2rem 1rem ${({icon}) => icon ? '5rem' : '2rem'};
  border-radius: 10px;
  background-color: ${({theme}) => theme.colors.white};
  border: 2px solid ${({theme, error}) => error ? theme.colors.mainRed : theme.colors.bg};
  ${({theme}) => getTextStyle(theme, ETextVariants.Body16)};
  ${({theme, error}) => error ? `color: ${theme.colors.mainRed}` : ''};

  &:focus {
    outline: none;
  }

  & + ${IconContainer} {
    color: ${({theme}) => theme.colors.lightGray};
  }

  &:focus + ${IconContainer} {
    color: ${({theme}) => theme.pallets[EPallets.Secondary].default};
  }
`

const Helper = styled.div`
  display: inline-block;
  position: relative;
  margin-left: 9px;

  svg {
    width: 16px;
    cursor: pointer;
  }
`

const HelperHover = styled.div<{ isShow: boolean }>`
  position: absolute;
  opacity: ${({isShow}) => isShow ? 1 : 0};
  visibility: ${({isShow}) => isShow ? 'visible' : 'hidden'};
  top: 25px;
  left: -60px;
  padding: 12px 25px;
  background: ${({theme}) => theme.colors.white};
  color: ${({theme}) => theme.colors.black};
  border-radius: 5px;
  z-index: 5;
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.16);
  width: max-content;
  ${({theme}) => theme.mediaQueries.md} {
   max-width: 80vw; 
  }
`

const Index = forwardRef<HTMLInputElement, InputProps>(
  ({value, className, error, label = '', placeholder = 'Не указано', onChange, wth, mt, icon, ...props}, ref) => {
    const isError = useMemo(() => (error?.length || 0) > 0, [error]);
    const {t} = useTranslate()
    const [isMove, setIsMove] = useState<boolean>(false);
    return (
      <Wrapper mt={mt} wth={wth}>
        {label && <Label
            variant={ETextVariants.Body16}
            as="label"
            htmlFor={(props as any).name}
        >
          {label}
          {label === t('profileForm.email.label','Email') && (
            <Helper
              onMouseOver={() => setIsMove(true)}
              onMouseOut={() => setIsMove(false)}
            >
              <HelperIcon/>
              <HelperHover isShow={isMove}>
                <T keyName="profileSettings.emailClue">
                  Укажите почту, чтобы иметь возможность восстановить аккаунт при утере доступа к номеру телефона
                </T>
              </HelperHover>
            </Helper>
          )}
        </Label>}

        <StyledInput
          ref={ref}
          className={className}
          id={(props as any).name}
          value={value}
          error={error}
          placeholder={placeholder}
          onChange={onChange}
          icon={!!icon}
          {...props}
        />
        {icon ? <IconContainer>{icon}</IconContainer> : null}
        {isError && (
          <ErrorText variant={ETextVariants.Error}>
            {error}
          </ErrorText>
        )}
      </Wrapper>
    );
  })

export default Index;
