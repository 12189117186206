import React, {ReactNode} from 'react';
import styled from "styled-components";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  ${({theme}) => theme.mediaQueries.md} {
    flex-direction: column;
    margin-bottom: 13px;
  }
`

const ImageBlock = styled.div`
  position: relative;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30rem;
  margin-left: 5.8rem;
  ${({theme}) => theme.mediaQueries.md} {
    margin: 0;
    width: 27rem;
  }
`

const TitleBlock = styled.div`
  margin-bottom: 1.5rem;
`

const Title = styled(Text)`
  text-align: center;
`

const Description = styled(Text)`
  text-align: center;
  display: inline-block;
  width: 100%;
`

function AuthSlide({ img, title, description }: { img: ReactNode, title: ReactNode, description: ReactNode }) {
  return (
    <Wrapper>
      <ImageBlock>
        {img}
      </ImageBlock>
      <Content>
        <TitleBlock>
          <Title as={'h2'} variant={ETextVariants.Heading}>{title}</Title>
        </TitleBlock>
        <div>
          <Description variant={ETextVariants.Body16}>
            {description}
          </Description>
        </div>
      </Content>
    </Wrapper>
  );
}

export default AuthSlide;
