import React from 'react';
import AdminHeader from "../../../components/AdminComponents/AdminHeader";
import SupportFilterView from "../../../views/SupportViews/SupportFilterView";
import {useAdminSupports} from "../../../store/adminSupports/hooks";
import SupportListView from "../../../views/SupportViews/SupportListView";
import {useTranslate} from "@tolgee/react";

function Index() {
  const {t} = useTranslate()
  useAdminSupports()
  return (
    <>
      <AdminHeader title={t('admin.menu.support','Служба поддержки')}/>
      <SupportFilterView/>
      <SupportListView/>
    </>
  );
}

export default Index;
