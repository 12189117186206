import React, {useCallback, useMemo, useRef} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import styled from "styled-components";
import {Card} from "../Templates/Card";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {CalendarIcon, GeoGrayIcon, PriceIcon, SmileIcon} from "../../UI/Svg";

import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import {T} from "@tolgee/react";
import {mediaUrl} from "../../api";
import {ServiceResponse} from "../../store/adminServices/types";
import {currencyDesc} from "../../config/currency";
import imgEmptyAvatar from "../../assets/img/no-avatar.png";
import _ from "lodash";
import {useAppState} from "../../store/app/hooks";
import {getTextStyle} from "../../UI/Text/utils";
import {useServicesAction} from "../../store/services/hooks";

const Wrapper = styled(Card)`
  width: 100%;
  display: flex;
  cursor: pointer;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  overflow: unset;
  ${({theme}) => theme.mediaQueries.md} {
  }
`

const MainContent = styled.div`
  width: 100%;
  display: flex;
  align-items: stretch;
  gap: 20px;
`

const EventImage = styled.div`
  display: flex;
  align-items: center;
  background: ${({theme}) => theme.colors.bg};
  justify-content: center;
  width: 35%;
  border-radius: 18px;
  overflow: hidden;
  height: 266px;
  max-width: 323px;

  img {
    width: auto;
    height: 100%;
  }

  ${({theme}) => theme.mediaQueries.md} {
    height: 113px;
    min-width: 97px;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
  max-width: 65%;

  ${({theme}) => theme.mediaQueries.md} {
    gap: 7px;
  }
`


const Description = styled(Text)`
  max-height: 60px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  width: 65%;

  ${({theme}) => theme.mediaQueries.md} {
    display: none;
  }
`

const List = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
`

const Item = styled.li`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;
  svg {
    min-width: 24px;
    height: 24px;
  }

  ${({theme}) => theme.mediaQueries.md} {
    svg {
      min-width: 20px;
      height: 20px;
    }
  }
`

const TextStyled = styled(Text)`
  line-height: 1;
  ${({theme}) => theme.mediaQueries.md} {
    font-size: 12px;
  }
`
const Members = styled(TextStyled)`
  white-space: pre;
  color: ${({theme}) => theme.colors.mainPurple};
  overflow: hidden;

  ${({theme}) => theme.mediaQueries.md} {
    width: 65%;
  }
`

const CompanyBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
`

const CompanyLogo = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 50%;
  background: #9b9b9b;

  img {
    width: auto;
    height: 100%;
  }
`

const CompanyDesc = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1px;
`

const CompanyTitle = styled(Text)`
  color: ${({theme}) => theme.colors.black};
`
const CompanySubtitle = styled.p`
  color: ${({theme}) => theme.colors.black};
  font-size: 10px;
  font-weight: 300;
`
const LinkStyled = styled.div`
  ${({theme}) => getTextStyle(theme, ETextVariants.Body16)}
  text-overflow: ellipsis;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  color: ${({theme}) => theme.colors.mainPurple};
  ${({theme}) => theme.mediaQueries.md} {
    line-height: 1;
    font-size: 12px;
  }
`

const Title = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

function ShortService({
                        id,
                        expirationDate,
                        description,
                        address,
                        maxCustomers, customers,
                        company,
                        header,
                        title,
                        currency,
                        price
                      }: ServiceResponse) {
  const navigate = useNavigate();
  const {onChangeFilter} = useServicesAction()
  const {pathname} = useLocation()
  const {isScroll} = useAppState()

  const isInvite = useMemo(() => _.includes(pathname.split('/'), 'invites'), [pathname])

  const goToService = useCallback((id: number): void => {
    if (isInvite) {
      navigate(`/invites/service/${id}`);
    } else {
      navigate(`/service/${id}`);
    }
  }, [navigate, isInvite]);
  const linkRef = useRef<any>(null)

  return (
    <Wrapper onClick={(event) => {
      if (!isScroll && event.target !== linkRef.current) goToService(id)
    }}>
      <MainContent>
        <EventImage>
          <img src={header ? `${mediaUrl}file/${header.id}` : ''} alt=""/>
        </EventImage>
        <Content>
          <Title variant={ETextVariants.Heading} as={'h2'}>{title}</Title>
          <Description variant={ETextVariants.Body16}>{description}</Description>
          <List>
            {expirationDate && <Item>
              <CalendarIcon/>
              <TextStyled
                variant={ETextVariants.Body16}> {dayjs(expirationDate).format('D MMMM / HH:mm')}</TextStyled>
            </Item>}
            {address && <Item>
                <GeoGrayIcon/>
                <LinkStyled ref={linkRef} onClick={() => {
                  onChangeFilter({
                    latitude: Number(address.latitude),
                    longitude: Number(address.longitude)
                  })
                  navigate(`/events/map?is-event=${false}&id=${id}`)
                }}>
                  {address.fullString}
                </LinkStyled>
            </Item>}

            <Item>
              <PriceIcon/>
              <TextStyled variant={ETextVariants.Body16}>{price}
                <T keyName={`currencies.${currency}.symbol`}>{currencyDesc[currency].symbol}</T>
                </TextStyled>
            </Item>
          </List>
        </Content>
      </MainContent>
      <CompanyBlock>
        <CompanyLogo>
          <img src={company.avatar ? `${mediaUrl}file/${company.avatar.id}` : imgEmptyAvatar} alt=""/>
        </CompanyLogo>

        <CompanyDesc>
          <CompanyTitle variant={ETextVariants.Body14} as={'h4'}>{company.title}</CompanyTitle>
          <CompanySubtitle><T keyName={'service.company'}>фирма</T></CompanySubtitle>
        </CompanyDesc>
      </CompanyBlock>
    </Wrapper>
  );
}

export default ShortService;
