import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import fetchWords from "./fetchWords";
import {IAdminForbiddenWordsFilter, IForbiddenWord} from "./types";

export type AdminServicesState = {
    filter: IAdminForbiddenWordsFilter,
    words: IForbiddenWord[],
    isLoading: boolean
}

const initialState: AdminServicesState = {
    filter: {},
    words: [],
    isLoading: true
}

export const adminForbiddenWordsSlice = createSlice({
    name: 'adminForbiddenWords',
    initialState,
    reducers: {
        changeFilter: (state, action: PayloadAction<IAdminForbiddenWordsFilter>) => {
            state.filter = {...state.filter, ...action.payload}
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchWords.pending, (state, ) => {
            state.isLoading = true
        })
        builder.addCase(fetchWords.rejected, (state, ) => {
            state.isLoading = false
        })
        builder.addCase(fetchWords.fulfilled, (state, action: PayloadAction<IForbiddenWord[]>) => {
            state.words = [...action.payload]
            state.isLoading = false
        })
    }
})

export const {changeFilter} = adminForbiddenWordsSlice.actions
export default adminForbiddenWordsSlice.reducer
