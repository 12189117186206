import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {SubscriptionApi} from "../../api/subscription";


const fetchUnsubscribeCompany = createAsyncThunk<void,
  number,
  {
    state: RootState
  }>(
  'profile/fetchUnsubscribeCompany',
  async (id, ThunkApi) => {
    const authState = ThunkApi.getState().auth
    if (authState.auth) {
      const [, subscribeErr] = await handle(SubscriptionApi.delSubscribeCompany(id))
      if (subscribeErr) {
        throw {message: subscribeErr.message[0] || subscribeErr.message}
      }
      return
    }
    throw {message: 'No user state'}
  }
)

export default fetchUnsubscribeCompany
