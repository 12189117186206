export type Breakpoints = string[];
export type BreakpointsMap = {
    xs: number;
    sm: number;
    md: number;
    lg: number;
    xl: number;
    xxl: number;
    max: number;
};

export type MediaQueries = {
    xs: string;
    sm: string;
    md: string;
    lg: string;
    xl: string;
    xxl: string;
    max: string;
    nav: string;
};


// export type Gradients = {
//   bubblegum: string;
// };

export type Colors = {
    white: string,
    black: string,
    bg: string,

    lightWhite: string,
    lightWhite0: string,
    lightWhite1: string,
    darkWhite: string,

    mainPurple: string,
    lightPurple0: string,
    lightPurple1: string,
    lightPurple2: string,
    lightPurple: string,
    darkPurple0: string,
    darkPurple: string,

    mainOrange: string,
    lightOrange: string,
    darkOrange: string,

    mainGray: string,
    textGray: string,

    lightGray0: string,
    lightGray1: string,
    lightGray: string,
    lightGray2: string,
    lightGray3: string,
    lightGray4: string,
    lightGray5: string,
    lightGray6: string,
    lightGray7: string,
    darkGray0: string,
    darkGray1: string,
    darkGray: string,
    mainRed: string,

    mainGreen: string,

    backgroundHover: string,
    transparentGray: string,
    successButtonBg: string,
    successButtonBgDisabled: string,
    successButtonBgHover: string,
    successButtonBgActive: string,
    errorButtonBg: string,
    errorButtonBgDisabled: string,
    errorButtonBgHover: string,
    errorButtonBgActive: string,
};
export type ColorsKey = keyof Colors

export enum EPallets {
    Primary,
    Secondary
}

export type Pallets = {
    default: string
    hover: string
    active: string
    light: string
    dark: string
    disabled: string
}

export type PalletsTheme = {
    [key in EPallets]: Pallets;
};

export type Shadows = {
    // regular: string
};
