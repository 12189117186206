/* eslint-disable react/no-unused-prop-types */
import {forwardRef, FC} from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';

import 'react-datepicker/dist/react-datepicker.css';

import imgArrowDownGray from '../../assets/img/arrow-down-gray.png';
import imgArrowDownPurple from '../../assets/img/arrow-down-purple.png';

import './datapicker.style.css';
import {T} from '@tolgee/react';
import Text from "../Text";
import {ETextVariants} from "../Text/types";
import {addWeeks} from "date-fns";
import {useAuthState} from "../../store/auth/hooks";
import en from "date-fns/locale/en-GB";
import {localeLangs} from "../DataPickerRange";

const InputDate = styled.div<{ bg: boolean; mobileVersion: boolean; $typeArrowPurple: boolean }>`
  height: 4rem;
  border-radius: 1rem;
  border: 2px solid ${({theme}) => theme.colors.bg};
  padding: 1rem 2rem;
  min-width: 14rem;
  background: url(${({$typeArrowPurple}) => !$typeArrowPurple ? imgArrowDownGray : imgArrowDownPurple}) no-repeat;
  background-position: calc(100% - 16px) center;
  width: 100%;
  font-size: 1.6rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: ${({theme, bg}) => !bg ? theme.colors.bg : theme.colors.white};
  color: ${({theme}) => theme.colors.black};
  & > * {
    color: ${({theme}) => theme.colors.black};
  }

  ${({theme}) => theme.mediaQueries.md} {
    ${({theme, bg, mobileVersion}) => mobileVersion && ` {
    background-color: ${!bg ? theme.colors.bg : theme.colors.white};
    padding: 1rem 1.6rem 1.3rem 1rem;
    border-radius: 0;
    height: 5.9rem;
  }`}
  }
`;

interface TypeProps {
  setStartDate: (data: any) => void;
  startDate: any;
  typeArrowPurple: boolean;
  bg: boolean;
  isClearButton: boolean;
  mobileVersion: boolean;
}

const PlaceholderStyled = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
`

// eslint-disable-next-line react/no-unstable-nested-components
const CustomInputDatePicker = forwardRef(({value, onClick, typeArrowPurple, bg, mobileVersion}: any, ref: any) => (
  <InputDate
    className="example-custom-input"
    onClick={onClick}
    ref={ref}
    bg={bg}
    mobileVersion={mobileVersion}
    $typeArrowPurple={typeArrowPurple}
  >
    {value || (
      <PlaceholderStyled>
        <Text variant={ETextVariants.Body16}>
          <T keyName="filter.selectDate">Выберите дату</T>
        </Text>
      </PlaceholderStyled>
    )}
  </InputDate>
));

export const DataPicker: FC<TypeProps> = ({
                                            setStartDate,
                                            startDate,
                                            bg,
                                            typeArrowPurple,
                                            mobileVersion,
                                          }) => {
  const {lang} = useAuthState()
  return (
    <DatePicker
      selected={startDate}
      showTimeSelect
      minDate={new Date()}
      maxDate={addWeeks(new Date(), 2)}
      showDisabledMonthNavigation
      locale={localeLangs[lang] || en}
      popperClassName="popperDataPickerRange"
      dateFormat="d MMMM, в HH:mm"
      onChange={(date: any) => setStartDate(date)}

      customInput={<CustomInputDatePicker bg={bg} typeArrowPurple={typeArrowPurple} mobileVersion={mobileVersion}/>}
    />
  );
};
