import React, {useMemo, useRef} from 'react';
import {Link, useNavigate} from "react-router-dom";
import styled from "styled-components";
import {Card} from "../Templates/Card";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {EventResponse} from "../../store/events/types";
import {CalendarIcon, GeoGrayIcon} from "../../UI/Svg";

import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import {mediaUrl} from "../../api";
import {getUserShortName} from "../../utils/user";
import {T} from "@tolgee/react";
import {getTextStyle} from "../../UI/Text/utils";
import {useEventsAction} from "../../store/events/hooks";

const Wrapper = styled(Card)`
  width: 100%;
  max-width: calc(50% - 10px);
  display: flex;
  cursor: pointer;
  align-items: stretch;
  gap: 20px;
  flex-direction: column;

  ${({theme}) => theme.mediaQueries.md} {
    max-width: 100%;
  }
`

const EventImage = styled.div`
  display: flex;
  align-items: center;
  background: ${({theme}) => theme.colors.bg};
  justify-content: center;
  width: 148px;
  border-radius: 10px;
  overflow: hidden;
  height: 120px;

  img {
    width: auto;
    height: 100%;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;

  ${({theme}) => theme.mediaQueries.md} {
    gap: 7px;
  }
`

const Categories = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  ${({theme}) => theme.mediaQueries.md} {
  }
`

const Category = styled(Text)`
  padding: 5px 10px;
  background: ${({theme}) => theme.colors.lightGray2};
  border-radius: 5px;
`

const Description = styled(Text)`
  max-height: 60px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  width: 65%;

  ${({theme}) => theme.mediaQueries.md} {
  }
`

const List = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
`

const Item = styled.li`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;

  svg {
    min-width: 24px;
    height: 24px;
  }

  ${({theme}) => theme.mediaQueries.md} {
    svg {
      width: 20px;
      height: 20px;
    }
  }
`

const TextStyled = styled(Text)`
  ${({theme}) => theme.mediaQueries.md} {
    font-size: 12px;
  }
`
const Creator = styled(TextStyled)`
`

const CreatorLink = styled(Link)`
  color: ${({theme}) => theme.colors.mainPurple};
  font-size: inherit;
  vertical-align: baseline;
`

const Details = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 20px;

  ${({theme}) => theme.mediaQueries.md} {
    grid-column-gap: 10px;
    grid-template-columns: 1fr;
  }
`

const DetailBlock = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
  flex-direction: column;

  ${({theme}) => theme.mediaQueries.md} {
    &:nth-child(2) {
      display: none;
    }
  }
`

const Label = styled(Text)`
  color: ${({theme}) => theme.colors.mainGray};
`

const EventImageBlock = styled.div`
  width: 100%;
  height: 200px;
  overflow: hidden;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  display: none;

  img {
    width: auto;
    height: 100%;
  }

  ${({theme}) => theme.mediaQueries.md} {
    display: flex;
  }
`


const LinkStyled = styled.div`
  ${({theme}) => getTextStyle(theme, ETextVariants.Body16)}
  text-overflow: ellipsis;
  cursor: pointer;
  overflow: hidden;
  color: ${({theme}) => theme.colors.mainPurple};

  ${({theme}) => theme.mediaQueries.md} {
    line-height: 1;
    font-size: 12px;
  }
`


function ProfileEvent({
                        id,
                        categories,
                        date,
                        description,
                        address,
                        status,
                        author,
                        members,
                        photo,
                        title
                      }: EventResponse) {
  const navigate = useNavigate();
  const {onChangeFilter} = useEventsAction()

  const goToEvents = (id: number): void => {
    navigate(`/about-the-event/${id}`);
  };


  const isNavigate = useMemo(() => {
    const now = new Date()
    const dateEvent = new Date(date)
    return status === 'approved' && dateEvent.getTime() > now.getTime();

  }, [date, status])

  const linkRef = useRef(null)

  return (
    <Wrapper onClick={(event) => {
      if (event.target !== linkRef.current) {
        event.preventDefault()
        goToEvents(id)
      }
    }}>
      {photo && <EventImageBlock>
          <img src={photo ? `${mediaUrl}file/${photo.id}` : ''} alt=""/>
      </EventImageBlock>}
      <Content>
        <Text variant={ETextVariants.Heading} as={'h2'}>{title}</Text>
        <Categories>
          {categories.map((category) => <Category key={`category-${category.id}`}
                                                  variant={ETextVariants.MiniDark}>
            <T keyName={`category.list.${category.id}`}>{category.title}</T>
          </Category>)}
        </Categories>
        <Description variant={ETextVariants.Body16}>{description}</Description>
      </Content>
      <Details>
        <DetailBlock>
          <Label variant={ETextVariants.Body14}><T keyName="profileEvent.details">Детали ивента:</T></Label>
          <List>
            <Item>
              <CalendarIcon/>
              <TextStyled variant={ETextVariants.Body16}> {dayjs(date).format('D MMMM / HH:mm')}</TextStyled>
            </Item>
            <Item>
              <GeoGrayIcon/>
              <LinkStyled onClick={() => {
                if (isNavigate) {
                  onChangeFilter({
                    latitude: Number(address.latitude),
                    longitude: Number(address.longitude)
                  })
                  navigate(`/events/map?is-event=${true}&id=${id}`)
                }
              }}>
                {address.fullString}
              </LinkStyled>
            </Item>
          </List>
          <Creator variant={ETextVariants.Body16}>
            <T keyName="profileEvent.creator">Создатель:</T> <CreatorLink ref={linkRef}
                                                                          to={`/profile/${author.id}`}>{getUserShortName(author)}</CreatorLink>
          </Creator>
        </DetailBlock>
        {photo && <DetailBlock>
            <Label variant={ETextVariants.Body14}><T keyName="profileEvent.photo">Фото ивента:</T></Label>

            <EventImage>
                <img src={photo ? `${mediaUrl}file/${photo.id}` : ''} alt=""/>
            </EventImage>
        </DetailBlock>}
      </Details>
    </Wrapper>
  );
}

export default ProfileEvent;
