import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {CartApi} from "../../api/cart";
import {ICartItem} from "./types";

const fetchCart = createAsyncThunk<ICartItem[],
  void,
  {
    state: RootState
  }>(
  'cart/fetchCart',
  async (_, ThunkApi) => {
    const authState = ThunkApi.getState().auth
    if (authState.auth) {
      const [cartRes, cartErr] = await handle(CartApi.getUserCart())
      if (cartRes) {
        return cartRes.items
      }
      if (cartErr) {
        throw {message: cartErr.message[0] || cartErr.message}
      }
    }
    throw {message: 'No user state'}
  }
)

export default fetchCart
