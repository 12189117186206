import {useDispatch, useSelector} from "react-redux";
import {useCallback, useEffect, useMemo} from "react";
import {AppDispatch, RootState} from "../index";
import {changeFilter} from "./adminCompaniesSlice";
import {IAdminCompaniesFilter} from "./types";
import fetchCompanies from "./fetchCompanies";
import fetchDeleteCompany from "./fetchDeleteCompany";
import fetchApproveCompany from "./fetchApproveCompany";
import fetchRejectCompany from "./fetchRejectCompany";
import {CreateCompanyPayload} from "../company/types";
import fetchCreateCompanyAdmin from "./fetchCreateCompanyAdmin";

export const getAdminCompanies = (state: RootState) => state.adminCompanies

export function useAdminCompaniesAction() {
  const dispatch = useDispatch<AppDispatch>()
  const onChangeFilter = useCallback((data: IAdminCompaniesFilter) => {
    dispatch(changeFilter(data))
  }, [dispatch])
  const onFetchCompanies = useCallback(() => {
    dispatch(fetchCompanies())
  }, [dispatch])
  const onApproveCompany = useCallback((id: number) => {
    dispatch(fetchApproveCompany(id))
      .unwrap()
      .then(onFetchCompanies)
  }, [dispatch, onFetchCompanies])
  const onDeleteCompany = useCallback((id: number) => {
    dispatch(fetchDeleteCompany(id))
      .unwrap()
      .then(onFetchCompanies)
  }, [dispatch, onFetchCompanies])
  const onRejectCompany = useCallback((id: number) => {
    dispatch(fetchRejectCompany(id))
      .unwrap()
      .then(onFetchCompanies)
  }, [dispatch, onFetchCompanies])

  const onCreateCompanyAdmin = useCallback((data:CreateCompanyPayload) => {
    dispatch(fetchCreateCompanyAdmin(data))
      .unwrap()
      .then(onFetchCompanies).catch((error) => console.log(error))

  }, [dispatch, onFetchCompanies])
  return useMemo(() => ({
    onChangeFilter,
    onFetchCompanies,
    onApproveCompany,
    onDeleteCompany,
    onRejectCompany,
    onCreateCompanyAdmin,
  }), [
    onChangeFilter,
    onFetchCompanies,
    onApproveCompany,
    onDeleteCompany,
    onRejectCompany,
    onCreateCompanyAdmin,
  ])
}

export function useAdminCompaniesState() {
  return useSelector(getAdminCompanies)
}

export function useAdminCompanies() {
  const {filter} = useAdminCompaniesState()
  const {onFetchCompanies} = useAdminCompaniesAction()

  useEffect(() => {
    onFetchCompanies()
  }, [filter, onFetchCompanies])

}
