import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled from "styled-components";
import Input from "../../UI/Input";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {ChatUsersIcon, CloseIcon, SearchIcon} from "../../UI/Svg";
import {useProfileState} from "../../store/profile/hooks";
import {useChatAction} from "../../store/chat/hooks";
import ChatMember from "../ProfileComponents/ChatMember";
import {T, useTranslate} from "@tolgee/react";

export interface ModalChatUsersProps {
  onClose: () => void;
  onShowCreate: () => void;
}

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  padding-top: 60px;
  z-index: 100000;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    content: '';
    position: absolute;
    background: ${({theme}) => theme.colors.transparentGray};
    width: 100%;
    height: 100%;
    padding-bottom: 100%;
    top: 0;
  }

  ${({theme}) => theme.mediaQueries.md} {
    position: absolute;
    border-radius: 0;
    width: 100%;
    height: 100%;
    padding-top: 0;
    margin-bottom: 71px;
  }
`

const Modal = styled.div`
  min-height: 300px;
  max-width: 1191px;
  height: 84vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  top: 0;
  right: 0;
  background: ${({theme}) => theme.colors.bg};
  z-index: 2;
  border-radius: 10px;

  &::-webkit-scrollbar {
    width: 0;
  }

  ${({theme}) => theme.mediaQueries.xxl} {
    width: 968px;
  }

  ${({theme}) => theme.mediaQueries.lg} {
    width: 770px;
  }

  ${({theme}) => theme.mediaQueries.md} {
    border-radius: 20px 20px 0 0;
    background: ${({theme}) => theme.colors.white};
    width: 100%;
    top: unset;
    max-height: 60%;
    //max-height: 40%;
    padding: 16px;
    bottom: 0;
    right: 0;
    left: 0;
    position: fixed;
    overflow: hidden;
  }
`
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid ${({theme}) => theme.colors.darkWhite};
  position: relative;
  gap: 20px;
  background: ${({theme}) => theme.colors.white};
  padding: 18px 50px 18px 70px;

  ${({theme}) => theme.mediaQueries.md} {
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: none;
    padding: 0 0 16px;
    gap: 16px;

    & > ${Text} {
      width: 100%;
      text-align: center;
    }
  }
`

const InputBlock = styled.div`
  display: flex;

`
const InputStyled = styled(Input)`
  height: 40px;

`

const CloseButton = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    width: 12px;
  }

  ${({theme}) => theme.mediaQueries.md} {
    transform: unset;
    top: 0;
    right: 0;

    svg {
      min-width: 24px;

      path {
        stroke: rgba(0, 0, 0, 0.2);
      }
    }
  }
`

const WrapUsers = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 0fr;
  width: 100%;
  grid-column-gap: 14px;
  padding: 16px 35px;
  grid-row-gap: 14px;

  ${({theme}) => theme.mediaQueries.xxl} {
    grid-template-columns: repeat(4, 1fr);
  }

  ${({theme}) => theme.mediaQueries.lg} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${({theme}) => theme.mediaQueries.md} {
    border-top: 1px solid ${({theme}) => theme.colors.bg};
    background: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 0;
    gap: 16px;
    flex: 1;
    overflow: auto;
  }
`


const CreateChatButton = styled.button`
  display: flex;
  width: fit-content;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: ${({theme}) => theme.colors.mainPurple};
  cursor: pointer;
`

const IconWrapper = styled.div`
  min-width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: ${({theme}) => theme.colors.bg};
`

const Subtitle = styled(Text)`
  color: rgba(0, 0, 0, 0.40);
  font-weight: 700;
  display: none;
  ${({theme}) => theme.mediaQueries.md} {
    display: inline;
  }
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
  overflow: hidden;
  align-items: flex-start;
`


function ModalChatUsers({onClose, onShowCreate}: ModalChatUsersProps) {
  const {t} = useTranslate()
  const {subscribers} = useProfileState()
  const [search, setSearch] = useState<string>('');
  const filteredUsers = useMemo(() => {
    return subscribers?.filter((item) => {
      return `${item.firstName} ${item.lastName} ${item.login}`.toLowerCase().includes(search.toLowerCase())
    });
  }, [search, subscribers]);
  const {onFetchCreateChat} = useChatAction()

  const onCreateChat = useCallback((id: number) => {
    onFetchCreateChat(id)
    onClose()
  }, [onClose, onFetchCreateChat])
  const body = document.querySelector('body');

  useEffect(() => {
    if (body) body.style.overflow = 'hidden';

    return () => {
      if (body) body.style.overflow = 'auto';
    };
  }, [body]);

  return (
    <Wrapper>
      <Modal>
        <Header>
          <Text variant={ETextVariants.Heading}>
            <T keyName="chats.write">Написать</T>
          </Text>
          <InputBlock>
            <InputStyled
              placeholder={t('common.search', 'Поиск')}
              wth="34.3rem"
              onChange={(e) => setSearch(e.target.value)}
              icon={<SearchIcon/>}
            />
          </InputBlock>
          <CreateChatButton onClick={onShowCreate}>
            <IconWrapper><ChatUsersIcon/></IconWrapper>
            <T keyName="chats.buttons.createChat">Создать чат</T>
          </CreateChatButton>
          <CloseButton onClick={onClose}>
            <CloseIcon/>
          </CloseButton>
        </Header>
        <Content>
          <Subtitle variant={ETextVariants.Body16}><T keyName="chats.recent">Недавние</T></Subtitle>
          <WrapUsers>
            {subscribers
              ? !filteredUsers?.length
                ? subscribers?.map((item) => {
                  return <ChatMember key={item.id} profile={item} onClick={() => onCreateChat(item.id)}/>;
                })
                : filteredUsers?.map((item) => {
                  return <ChatMember key={item.id} profile={item} onClick={() => onCreateChat(item.id)}/>;
                })
              : null}
          </WrapUsers>
        </Content>
      </Modal>
    </Wrapper>
  );
}

export default ModalChatUsers;
