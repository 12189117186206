import {axiosInstance} from "./index";

export const AcquaintanceApi = {
    getNextSuggestion: async () => await axiosInstance.get(`acquaintance/next`),
    getUserLikes: async () => await axiosInstance.get(`acquaintance/likes`),
    getUserSympathies: async () => await axiosInstance.get(`acquaintance/sympathies`),
    getUserMatches: async () => await axiosInstance.get(`acquaintance/matches`),
    postLike: async (id: number) => await axiosInstance.post(`acquaintance/like/${id}`),
    deleteUnLike: async (id: number) => await axiosInstance.delete(`acquaintance/unlike/${id}`),
}
