import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IAdminPaymentsFilter, IPaymentsAll, IPaymentsCompany} from "./types";
import fetchAllPayments from "./fetchAllPayments";
import {IPayment} from "../cart/types";
import fetchCompanyPayments from "./fetchCompanyPayments";
import {IUserCompany} from "../company/types";
import {ServiceResponse} from "../adminServices/types";
import fetchServicePayments from "./fetchServicePayments";

export type AdminPaymentsState = {
  filter: IAdminPaymentsFilter,
  paymentsAll: IPaymentsAll[],
  paymentsCompany: IPaymentsCompany,
  paymentsService: IPayment[],
  selectedCompany: IUserCompany | null,
  selectedService: ServiceResponse | null,
  isLoading: boolean,
  isLastPage: boolean
}

const LIMIT = 20

const initialState: AdminPaymentsState = {
  filter: {limit: LIMIT, page: 1},
  paymentsAll: [],
  paymentsCompany: {
    totalIncome: 0, payments: [], salesNumber: 0
  },
  paymentsService: [],
  isLoading: true,
  isLastPage: false,
  selectedCompany: null,
  selectedService: null,
}

export const adminPaymentsSlice = createSlice({
  name: 'adminPayments',
  initialState,
  reducers: {
    changeFilter: (state, action: PayloadAction<IAdminPaymentsFilter>) => {
      state.filter = {...state.filter, ...action.payload}
    },
    changePage: (state, action: PayloadAction<number>) => {
      state.filter.page = action.payload
    },
    selectCompany: (state, action: PayloadAction<IUserCompany>) => {
      state.selectedCompany = action.payload
    },
    clearSelectCompany: (state) => {
      state.selectedCompany = null
      state.filter = {limit: LIMIT, page: 1}
    },
    selectService: (state, action: PayloadAction<ServiceResponse>) => {
      state.selectedService = action.payload
    },
    clearSelectService: (state) => {
      state.selectedService = null
      state.filter = {limit: LIMIT, page: 1}
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchAllPayments.pending, (state,) => {
      state.isLoading = true
    })
    builder.addCase(fetchAllPayments.rejected, (state,) => {
      state.isLoading = false
    })
    builder.addCase(fetchAllPayments.fulfilled, (state, action: PayloadAction<IPaymentsAll[]>) => {
      state.paymentsAll = [...action.payload]
      state.isLoading = false
    })
    builder.addCase(fetchCompanyPayments.pending, (state,) => {
      state.isLoading = true
    })
    builder.addCase(fetchCompanyPayments.rejected, (state,) => {
      state.isLoading = false
    })
    builder.addCase(fetchCompanyPayments.fulfilled, (state, action: PayloadAction<IPaymentsCompany>) => {
      state.paymentsCompany = action.payload
      state.isLoading = false
      state.isLastPage = action.payload.payments.length < (state.filter.limit || LIMIT)
    })
    builder.addCase(fetchServicePayments.pending, (state,) => {
      state.isLoading = true
    })
    builder.addCase(fetchServicePayments.rejected, (state,) => {
      state.isLoading = false
    })
    builder.addCase(fetchServicePayments.fulfilled, (state, action: PayloadAction<IPayment[]>) => {
      state.paymentsService = [...action.payload]
      state.isLoading = false
    })
  }
})

export const {
  changeFilter, changePage, selectCompany,
  clearSelectCompany,
  selectService,
  clearSelectService,
} = adminPaymentsSlice.actions
export default adminPaymentsSlice.reducer
