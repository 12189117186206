import React, {useEffect} from 'react';
import {IChatMessage} from "../../store/chat/types";
import MessageBase from "./MessageBase";
import styled from "styled-components";
import {Link} from "react-router-dom";
import {getUserShortName} from "../../utils/user";
import {useChatAction, useChatState} from "../../store/chat/hooks";
import _ from "lodash";

const TextStyled = styled.div`
  color: #454545;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.3px;
  width: 90%;
  margin: 0 auto;
  text-align: center;
  display: inline-block;
  vertical-align: sub;
`

const LinkStyled = styled(Link)`
  color: #6C27AB;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  vertical-align: baseline;
  letter-spacing: 0.3px;
`

function Message(message: IChatMessage) {
  const {text} = message;
  const {unreadMessagesIds} = useChatState()
  const {onReadMessage} = useChatAction()

  useEffect(() => {
    if (_.includes(unreadMessagesIds, message.id)) {
      onReadMessage(message.id)
    }
  }, [message.id, onReadMessage, unreadMessagesIds])

  switch (message.type) {
    case 'default':
      return <MessageBase message={message}>{text}</MessageBase>
    case 'company':
      return <MessageBase message={message}>{text}</MessageBase>
    case 'eventInviteNotification':
      return (
        <TextStyled>
          Пользователь <LinkStyled
          to={`/profile/${message.sender.id}`}>{getUserShortName(message.sender)}</LinkStyled> отправил приглашение
          на <LinkStyled to={`/about-the-event/${message.event?.id}`}>ивент</LinkStyled>
        </TextStyled>
      )
    case 'eventInviteAccepted':
      return (
        <TextStyled>
          Пользователь <LinkStyled
          to={`/profile/${message.sender.id}`}>{getUserShortName(message.sender)}</LinkStyled> принял приглашение
          на <LinkStyled to={`/about-the-event/${message.event?.id}`}>ивент</LinkStyled>
        </TextStyled>
      )
    case 'eventInviteUnaccepted':
      return (
        <TextStyled>
          Пользователь <LinkStyled
          to={`/profile/${message.sender.id}`}>{getUserShortName(message.sender)}</LinkStyled> отклонил приглашение
          на <LinkStyled to={`/about-the-event/${message.event?.id}`}>ивент</LinkStyled>
        </TextStyled>
      )
    // case 'eventInvite':
    //   return <MessageInvite {...message}/>
    default:
      return null
  }
}

export default Message;
