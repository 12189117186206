import React from 'react';
import {AddDNDIcon, DeleteIcon} from "../../UI/Svg";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import styled from "styled-components";

const FormBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 14px;
  width: 100%;
`

const InputCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  cursor: pointer;
  background: ${({theme}) => theme.colors.bg};
  svg {
    path {
      stroke: ${({theme}) => theme.colors.black};
    }
  }
`

const CountBlock = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

function FormCountComponent({onDecrement, label, value, onIncrement, ...props}: {onIncrement: () => void, onDecrement: () => void, label: string, value: number}) {
  return (
    <FormBlock {...props}>
      <InputCount onClick={onDecrement}>
        <DeleteIcon/>
      </InputCount>
      <CountBlock>
        <Text variant={ETextVariants.MiniGray}>{label}</Text>
        <Text variant={ETextVariants.Body16}>{value}</Text>
      </CountBlock>
      <InputCount onClick={onIncrement}>
        <AddDNDIcon/>
      </InputCount>
    </FormBlock>
  );
}

export default FormCountComponent;
