import React, {useEffect} from 'react';
import AdminHeader from "../../components/AdminComponents/AdminHeader";
import FormView from "../../views/CategoriesViews/FormView";
import ListCategories from "../../views/CategoriesViews/ListCategories";
import {useEventsAction} from "../../store/events/hooks";
import {useTranslate} from "@tolgee/react";

function AdminCategories() {
  const {onFetchCategories} = useEventsAction()
  const {t} = useTranslate()
  useEffect(() => {
    onFetchCategories()
  }, [onFetchCategories])
  return (
    <>
      <AdminHeader title={t('common.categories','Категории')} isBack={true}/>
      <FormView/>
      <ListCategories/>
    </>
  );
}

export default AdminCategories;
