import React, {ReactNode, useCallback, useEffect, useMemo, useState} from 'react';
import styled from "styled-components";
import {Container} from "../../components/Templates/Container";
import {Card} from "../../components/Templates/Card";
import Input from "../../UI/Input";
import DropdownCustom from "../../UI/Dropdown/DropdownCustom";
import {Button, EButtonVariants} from "../../UI/Button";
import {ETextVariants} from "../../UI/Text/types";
import Text from "../../UI/Text";
import {Link} from "react-router-dom";
import {getTextStyle} from "../../UI/Text/utils";
import {useCartAction, useCartState} from "../../store/cart/hooks";
import {UserApi} from "../../api/user";
import {UserEntity} from "../../types";
import _ from "lodash";
import {currencyDesc} from "../../config/currency";
import {getUserName} from "../../utils/user";
import {useAuthState} from "../../store/auth/hooks";
import {EPaymentMethod} from "../../store/cart/types";
import {Checkbox} from "../../UI/Input/Checkbox";
import {useServicesState} from "../../store/services/hooks";
import DataOneForm from "../../components/ServicesComponents/DataOneForm";
import {useCustomStripe} from "../../hooks/useCustomStripe";
import {Elements} from "@stripe/react-stripe-js";
import CheckoutFormComponent from "../../components/PaymentsComponent/CheckoutFormComponent";
import {T, useTranslate} from "@tolgee/react";
import {useProfileState} from "../../store/profile/hooks";


const ContainerStyled = styled(Container)`
  flex-direction: column;
  gap: 30px;

  ${({theme}) => theme.mediaQueries.md} {
    background: ${({theme}) => theme.colors.white};
    justify-content: space-between;
    flex: 1;
  }
`
const Cards = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  justify-content: flex-start;
`

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: flex-start;
  width: 100%;
  overflow: visible;
  position: relative;
`
const EmailCard = styled(StyledCard)`
`

const BankCard = styled(StyledCard)`
`
const FormCard = styled(StyledCard)`
  flex-direction: row;
  align-items: flex-end;
  flex-wrap: wrap;

  & > div:not(:last-child) {

    max-width: calc(100% / 3 - 30px * 2 / 3);
  }
`


const CardRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const CardText = styled.div`
  color: ${({theme}) => theme.colors.black};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.24px;
`

const ButtonBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;

  ${({theme}) => theme.mediaQueries.md} {
    padding: 0 16px 16px;
  }
`

const PriceBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2px;
`

const PriceLabel = styled(Text)`
  color: ${({theme}) => theme.colors.mainGray};
`
const PriceValue = styled(Text)`
  font-weight: 600;
`

const UserRow = styled(Text)`
  span {
    color: ${({theme}) => theme.colors.mainPurple};
  }
`

const UserText = styled(Text)`
  color: ${({theme}) => theme.colors.mainPurple};
`

const LinkStyled = styled(Link)`
  ${({theme}) => getTextStyle(theme, ETextVariants.Body16)};
  color: ${({theme}) => theme.colors.mainPurple};
  vertical-align: baseline;
`

const FooterBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`


const ErrorText = styled(Text)`
  margin-top: 2px;
`

// const customerId = 'cus_PXOn8lgCStJhMw'
// const companyId = 'acct_1Oo1Z8B9uHZ3y6cH'

function PaymentCartPage({isAvailablePaymentCash}: { isAvailablePaymentCash?: boolean }) {
  const {t} = useTranslate()
  const [email, setEmail] = useState<string>('');
  const [card, setCard] = useState('');
  const {profile} = useProfileState()
  const [userForPrize, setUserForPrize] = useState<null | UserEntity>(null);
  const {phone, checkedItemsIds, items, dataOnePayment} = useCartState()
  const {all: services} = useServicesState()
  const {user} = useAuthState()
  const {onFetchCreatePayment} = useCartAction()
  const [isPaymentCash, setIsPaymentCash] = useState<boolean>(false);

  const {options, stripePromise, isPending, createPaymentIntent, payments, message} = useCustomStripe(profile?.customerId)

  const cards: Array<{ item: ReactNode, value: string }> = useMemo(() => {
    return [
      ...payments.map((payment) => (
        {
          item: <CardRow>
            <CardText>XXXX XXXX XXXX {payment.card?.last4}</CardText> - {payment.card?.brand}
          </CardRow>,
          value: payment.id
        }
      )),
      {item: <CardRow><CardText><T keyName="payment.addCart">Добавить карту</T></CardText></CardRow>, value: ''},
    ]
  }, [payments])

  const currentService = useMemo(() => {
    if (dataOnePayment) {
      return services.filter((item) => item.id === dataOnePayment.serviceId)[0] || null
    }
    return null
  }, [services, dataOnePayment])

  const checkedItems = useMemo(() => {
    if (checkedItemsIds.length > 0 && items.length > 0) {
      return items.filter((item) => _.includes(checkedItemsIds, item.id))
    }
    return []
  }, [checkedItemsIds, items])

  const isMultiCurrency = useMemo(() => {
    return _.uniqBy(checkedItems, (item) => item.favor.currency).length > 1
  }, [checkedItems])

  const isMultiCompany = useMemo(() => {
    return _.uniqBy(checkedItems, (item) => item.favor.company.id).length > 1
  }, [checkedItems])

  const companyId = useMemo(() => {
    if (isMultiCompany) return null
    return checkedItems[0]?.favor.company.companyId
  }, [checkedItems, isMultiCompany])

  useEffect(() => {
    if (userForPrize?.email) setEmail(userForPrize.email)
    else if (user?.email) setEmail(user.email)
    else setEmail('')
  }, [user?.email, userForPrize?.email])

  const {result: totalCart, objSum} = useMemo(() => {
    let result = ''
    const objSum: { [currency: string]: number } = {}
    if (checkedItems.length > 0) {
      for (const checkedItem of checkedItems) {
        if (objSum[checkedItem.favor.currency]) {
          objSum[checkedItem.favor.currency] += checkedItem.favor.price * checkedItem.quantity
        } else {
          objSum[checkedItem.favor.currency] = checkedItem.favor.price * checkedItem.quantity
        }
      }
      let isFirst = true
      for (const currency in objSum) {
        if (isFirst) {
          isFirst = false
        } else {
          result += ' + '
        }
        result += `${objSum[currency]}${t(`currencies.${currency}.symbol`, currencyDesc[currency].symbol)}`
      }
    }
    return {result, objSum}
  }, [checkedItems, t])

  useEffect(() => {
    if (phone.length === 11) {
      UserApi.getUserByPhone(`+${phone}`)
        .then(res => setUserForPrize(res.data))
        .catch(console.log)
    }
  }, [phone])

  const onCreatePaymentIntent = useCallback(() => {
    if (profile?.customerId) {
      let currencies: string[] = []
      if (currentService) {
        currencies = [currentService.currency]
      } else {
        currencies = Object.keys(objSum)
      }
      if (currencies.length > 0) {
        const currency = currencies[0]
        const amount = dataOnePayment ? dataOnePayment.quantity * (currentService?.price || 0) : objSum[currency]
        const params: {
          amount: number,
          currency: string,
          customer: string,
          on_behalf_of?: string,
          payment_method?: string,
          metadata: { [key: string]: string | number | null }
          transfer_data?: {
            destination: string,
            amount: number
          }
        } = {
          amount: amount * 100,
          currency,
          customer: profile?.customerId,
          metadata: {
            items: checkedItemsIds.join(','),
            phone,
            userId: user?.id || 'none',
            dataOnePayment: dataOnePayment ? JSON.stringify(dataOnePayment) : null
          }
        }
        if (companyId) {
          params.on_behalf_of = companyId
          params.transfer_data = {
            destination: companyId,
            amount: amount * 100 * .9,
          }
        }
        if (card) {
          params.payment_method = card
        }
        createPaymentIntent(params)
      }
    }
  }, [card, checkedItemsIds, companyId, createPaymentIntent, currentService, dataOnePayment, objSum, phone, profile?.customerId, user?.id])
  return (
    <ContainerStyled>
      <Cards>
        {phone && <StyledCard>
            <UserRow variant={ETextVariants.Body16}>
                <T keyName="payment.recipient">Получатель подарка</T>:
              {userForPrize ? <LinkStyled
                  to={`/profile/${userForPrize.id}`}>{getUserName(userForPrize)}</LinkStyled> :
                <UserText variant={ETextVariants.Body16}>{phone}</UserText>}
            </UserRow>
        </StyledCard>}
        <EmailCard>
          <Input
            placeholder={t('payment.email.placeholder', "Введите email")}
            wth={'100%'}
            type="email"
            label={t('payment.email.label', "Email для получения билета")}
            value={email}
            onChange={event => setEmail(event.target.value)}
          />
        </EmailCard>
        <BankCard>
          {!isPaymentCash &&
              <DropdownCustom label={t('payment.dropdown.label', 'Банковская карта')}
                              placeholder={t('payment.dropdown.placeholder', 'Банковская карта')}
                              options={cards} value={card} disabled={options.clientSecret !== ""}
                              onSelect={(val) => setCard(String(val))}/>}
          {(isAvailablePaymentCash && currentService?.allowCashPayment) &&
              <Checkbox label={t('common.allowCashPayment', 'Оплата на месте')} checked={isPaymentCash}
                        onChange={event => setIsPaymentCash(event.target.checked)} id="cash"/>}
        </BankCard>
        {options.clientSecret && <FormCard>
            <Elements options={options} stripe={stripePromise}>
                <CheckoutFormComponent card={card}/>
            </Elements>
        </FormCard>}
      </Cards>
      <FooterBlock>
        {currentService && <StyledCard>
            <DataOneForm {...currentService}/>
        </StyledCard>}

        <ButtonBlock>

          {(items.length > 0 && !dataOnePayment) && <PriceBlock>
              <PriceLabel variant={ETextVariants.Body14}><T keyName="payment.priceTitle">К оплате</T></PriceLabel>
              <PriceValue variant={ETextVariants.Body16}>{totalCart}</PriceValue>
          </PriceBlock>}
          {
            !options.clientSecret && <Button onClick={() => {
              if ((checkedItemsIds.length === 0 && !dataOnePayment) || isMultiCurrency || isMultiCompany) return
              if (isPaymentCash) {
                onFetchCreatePayment({
                  method: isPaymentCash ? EPaymentMethod.Cash : EPaymentMethod.Online,
                  isOne: !!dataOnePayment
                })
              } else {
                onCreatePaymentIntent()
              }
            }}
                                             disabled={isPending || (checkedItemsIds.length === 0 && !dataOnePayment) || isMultiCurrency || isMultiCompany}
                                             variant={EButtonVariants.Primary}
                                             $fullWidth>
              {isMultiCurrency
                ? <T keyName="payment.multiCurrency">Выберите позиции с одной валютой</T>
                : isMultiCompany
                  ? <T keyName="payment.multiCompany">Выберите позиции от одной компании</T>
                  : isPending
                    ? <T keyName="payment.waiting">Ожидание</T>
                  : <T keyName="payment.pay">Оплатить</T>
              }
              </Button>
          }
          {message && <ErrorText variant={ETextVariants.Error}>
            {message}
          </ErrorText>}
        </ButtonBlock>
      </FooterBlock>
    </ContainerStyled>
  );
}

export default PaymentCartPage;
