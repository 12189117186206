import styled from "styled-components";

export const Card = styled.section`
  background-color: ${({theme}) =>theme.colors.white};
  padding: 3rem;
  border-radius: 1rem;
  overflow: hidden;
  ${({theme}) => theme.mediaQueries.md} {
    padding: 1.5rem;
  }
`
