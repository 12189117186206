import React, {useCallback} from 'react';
import {useAdminEventsState} from "../../store/adminEvents/hooks";
import styled from "styled-components";
import AdminEvent from "../../components/AdminComponents/AdminEvent";
import AdminService from "../../components/AdminComponents/AdminService";
import {useAdminCompaniesAction, useAdminCompaniesState} from "../../store/adminCompanies/hooks";
import Table from "../../UI/Table";
import {useTranslate} from "@tolgee/react";

const Wrapper = styled.div`
  flex-wrap: wrap;
  width: calc(100% + 80px);
  margin: 0 -40px;
  margin-top: 40px;
`

function FirmsListView() {
  const {companies} = useAdminCompaniesState()
  const {onDeleteCompany} = useAdminCompaniesAction()

  const {t} = useTranslate()

  const onDelete = useCallback((id: number) => {
    if (window.confirm(t('admin.firm.confirmDelete','Вы действительно хотите удалить компанию?'))) {
      onDeleteCompany(id)
    }
  }, [onDeleteCompany, t])
  return (
    <Wrapper>
        <Table
          heading={[
            {key: 'id', value: '№'},
            {key: 'title', value: t('admin.firm.title','Название фирмы')},
            {key: 'registrationNumber', value: t('admin.firm.registrationNumber','ИНН')},
            {key: 'taxPayerNumber', value: t('admin.firm.taxPayerNumber','КПП')},
          ]}
          actions={[
            {title: 'Удалить', callback: onDelete, dataKey: 'id'},
            // {title: 'Заблокировать', callback: onBlock, dataKey: 'id'},
          ]}
          data={companies}
        />
    </Wrapper>
  );
}

export default FirmsListView;
