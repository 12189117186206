import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="59" height="59" viewBox="0 0 59 59" fill="none" {...props} >
          <circle cx="29.3114" cy="29.3114" r="29.3114" fill="#6A1B9A"/>
          <path d="M40.012 26.5459C41.9921 27.775 41.9921 30.8477 40.012 32.0768L25.533 41.0644C23.5529 42.2935 21.0779 40.7571 21.0779 38.299L21.0779 20.3237C21.0779 17.8656 23.5529 16.3292 25.533 17.5583L40.012 26.5459Z" fill="white"/>
        </Svg>
    );

export default Icon;
