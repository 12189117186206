import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {useProfileAction, useProfileState} from "../../store/profile/hooks";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {T} from "@tolgee/react";
import ModalUsers from "../Modals/ModalUsers";
import {useNavigate} from "react-router-dom";
import {ProfileContext} from "../../contexts/ProfileContext";

const Row = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  gap: 60px;
  width: 100%;
  margin-top: 24px;
  ${({theme}) => theme.mediaQueries.md} {
    gap: 20px;
    margin-top: 0;
    justify-content: space-between;
  }
`

const Item = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
  gap: 16px;
  ${({theme}) => theme.mediaQueries.md} {
    gap: 5px;
  }
`

const ItemName = styled(Text)`
  ${({theme}) => theme.mediaQueries.md} {
    font-size: 14px;
  }
`

const ItemValue = styled(Text)`
  font-weight: 500;
  font-size: 30px;
  ${({theme}) => theme.mediaQueries.md} {
    font-size: 16px;
  }
`

function ProfileInfo({onShowModal}: { onShowModal: () => void }) {
  const navigate = useNavigate()
  const {events, subscribers, subscriptions, photos} = useProfileState()
  const {profile} = useContext(ProfileContext)

  const [isShowSubscriptionsModal, setIsShowSubscriptionsModal] = useState<boolean>(false);
  const [isShowSubscribersModal, setIsShowSubscribersModal] = useState<boolean>(false);

  const {onFetchProfileData} = useProfileAction()
  useEffect(() => {
    onFetchProfileData()
  }, [onFetchProfileData])
  if (!profile) return null
  return (
    <>
    <Row>
      <Item onClick={() => setIsShowSubscriptionsModal(true)}>
        <ItemValue variant={ETextVariants.Body16}>{subscriptions.length}</ItemValue>
        <ItemName variant={ETextVariants.Body16}>
          <T keyName="profile.subscriptions">Подписки</T>
        </ItemName>
      </Item>
      <Item onClick={() => setIsShowSubscribersModal(true)}>
        <ItemValue variant={ETextVariants.Body16}>{subscribers.length}</ItemValue>
        <ItemName variant={ETextVariants.Body16}>
          <T keyName="profile.subscribers">Подписчики</T>
        </ItemName>
      </Item >
      <Item onClick={() => navigate(`/profile/events/${profile.id}`)}>
        <ItemValue variant={ETextVariants.Body16}>{events.length}</ItemValue>
        <ItemName variant={ETextVariants.Body16}>
          <T keyName="common.events">Ивенты</T>
        </ItemName>
      </Item>
      <Item onClick={onShowModal}>
        <ItemValue variant={ETextVariants.Body16}>{photos.length}</ItemValue>
        <ItemName variant={ETextVariants.Body16}>
          <T keyName="profile.photos">Фотографии</T>
        </ItemName>
      </Item>
    </Row>
      {(isShowSubscribersModal || isShowSubscriptionsModal) && <ModalUsers users={isShowSubscribersModal ? [...subscribers] : [...subscriptions]} onClose={() => {
        setIsShowSubscribersModal(false)
        setIsShowSubscriptionsModal(false)
      }} title={isShowSubscribersModal ?
        <T keyName="profile.subscribers">Подписчики</T> : isShowSubscriptionsModal &&
          <T keyName="profile.subscriptions">Подписки</T>}/>}
    </>
  );
}

export default ProfileInfo;
