import React, {MutableRefObject, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {ProfileEntity} from "../../store/profile/types";
import {IChat} from "../../store/chat/types";
import styled from "styled-components";
import imgEmptyAvatar from '../../assets/img/no-avatar.png';
import {mediaUrl} from "../../api";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {useNavigate} from "react-router-dom";
import {useAuthState} from "../../store/auth/hooks";
import {getTextStyle} from "../../UI/Text/utils";
import {useChatAction} from "../../store/chat/hooks";
import {useAcquaintanceAction} from "../../store/acquaintance/hooks";

const User = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 16px 36px 20px;
  gap: 16px;

  &:hover {
    text-decoration: underline;
  }

  ${({theme}) => theme.mediaQueries.md} {
    padding: 0;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    width: 112px;
    height: 112px;
  }
`

const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 141px;
  height: 141px;
  border-radius: 50%;
  overflow: hidden;
  background: #9b9b9b;

  img {
    width: auto;
    height: 100%;
  }

  ${({theme}) => theme.mediaQueries.md} {
    width: 100%;
    height: 100%;
    border-radius: 0;

    img {
      width: 100%;
      height: auto;
    }
  }
`

const TextDesktop = styled(Text)`
  ${({theme}) => theme.mediaQueries.md} {
    display: none;
  }
`
const TextStyled = styled(Text)`
  display: none;

  ${({theme}) => theme.mediaQueries.md} {
    display: inline;
    position: absolute;
    bottom: 8px;
    left: 10px;
    color: ${({theme}) => theme.colors.white};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.24px;
    text-shadow: 0 0 2px black;
  }
`

const ActionsDialog = styled.div`
  width: 100%;
  max-width: 360px;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  filter: drop-shadow(0 0 5px rgba(34, 34, 34, .2));

  &:nth-child(2) {
    display: none;
  }

  ${({theme}) => theme.mediaQueries.md} {
    &:nth-child(2) {
      display: flex;
    }

    position: relative;
    top: unset;
    transform: unset;
    left: unset;
  }
`

const Shadow = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.35);
  z-index: 2;
`

const Action = styled.div`
  display: flex;
  padding: 18px 58px 18px 60px;
  justify-content: center;
  align-items: center;
  border-bottom: 0.5px solid #DBDBDB;
  background: ${({theme}) => theme.colors.white};
  ${({theme}) => getTextStyle(theme, ETextVariants.Body16)};
  color: ${({theme}) => theme.colors.mainPurple};
  text-align: center;
  cursor: pointer;
  user-select: none;

  &:first-child {
    border-top-right-radius: 18px;
    border-top-left-radius: 18px;
  }

  &:last-child {
    border-bottom-right-radius: 18px;
    border-bottom-left-radius: 18px;
    border-bottom: none;
  }

  &:hover {
    background: ${({theme}) => theme.colors.mainPurple};
    color: ${({theme}) => theme.colors.white};
  }
`

const ActionsWrapper = styled.div<{top: number, left: number, right: number}>`

  position: absolute;
  left: calc(50% - ${({left, right}) => left || right}px);
  transform: translate(-50%);
  top: calc(100% - 20px - ${({top}) => top}px);
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  z-index: 100;
  width: 320px;
  height: 245px;

  ${({theme}) => theme.mediaQueries.md} {
    height: unset;
    width: 100%;
    position: fixed;
    max-width: 100%;
    left: 50%;
    bottom: 10px;
    transform: translateX(-50%);
    top: unset;
  }
`

ActionsWrapper.defaultProps = {
  top: 0, left: 0, right: 0
}

const WrapperUser = styled.div`
  position: relative;

  ${({theme}) => theme.mediaQueries.md} {
    max-width: calc(100% / 3 - 10px * 2 / 3);
  }
`

function ProfileItem({profile, onClose, wrapDiv}: { profile: ProfileEntity, onClose: () => void, wrapDiv?: MutableRefObject<HTMLDivElement | null> }) {
  const navigate = useNavigate()
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false);

  const [coords, setCoords] = useState<{top: number, left: number, right: number}>({top: 0, left: 0, right: 0});

  const actionBlock = useRef<HTMLDivElement | null>(null)

  const {onFetchCreateChat} = useChatAction()
  const {onSetSuggestion} = useAcquaintanceAction()

  const onCreateChat = useCallback((id: number) => {
    onFetchCreateChat(id)
  }, [onFetchCreateChat])

  useEffect(() => {
    const currentCoords: {top: number, left: number, right: number} = {top: 0, left: 0, right: 0}
    if (isShowDialog) {
      if (actionBlock.current && wrapDiv?.current) {
        const wrapper = wrapDiv.current
        if (wrapper) {
          const wrapperCoords = wrapper.getBoundingClientRect()
          const actionBlockCoords = actionBlock.current.getBoundingClientRect()
          if (actionBlockCoords.bottom > wrapperCoords.bottom) currentCoords.top = actionBlockCoords.bottom - wrapperCoords.bottom
          if (actionBlockCoords.right > wrapperCoords.right) currentCoords.right = actionBlockCoords.right - wrapperCoords.right
          if (actionBlockCoords.left < wrapperCoords.left) currentCoords.left = actionBlockCoords.left - wrapperCoords.left
        }
      }
    }
    setCoords(currentCoords)
  }, [isShowDialog, wrapDiv])

  if (profile) {
    return (
      <WrapperUser>
        <User onClick={() => setIsShowDialog(true)}>
          <Avatar>
            <img src={profile.avatar ? `${mediaUrl}file/${profile.avatar.id}` : imgEmptyAvatar} alt=""/>
          </Avatar>
          <TextDesktop variant={ETextVariants.Body16}>
            {profile?.firstName ? `${profile.firstName} ${profile.lastName}` : profile?.login}
          </TextDesktop>
          <TextStyled variant={ETextVariants.Body16}>
            {profile?.firstName ? `${profile.firstName}` : profile?.login}{profile?.age ? `, ${profile.age}` : ''}
          </TextStyled>
        </User>
        {isShowDialog && <>
            <Shadow onClick={() => setIsShowDialog(false)}/>
            <ActionsWrapper ref={actionBlock} {...coords}>
                <ActionsDialog>
                    <Action onClick={() => navigate(`/profile/${profile.id}`)}>
                        Посмотреть профиль
                    </Action>
                    <Action onClick={() => onCreateChat(profile.id)}>
                        Начать чат
                    </Action>
                    <Action onClick={() => {
                      onSetSuggestion(profile)
                      navigate('/invites')
                    }}>
                        Пригласить на ивент из списка
                    </Action>
                    <Action onClick={() => {
                      onSetSuggestion(profile)
                      navigate('/invites/create')
                    }}>
                        Создать ивент и пригласить
                    </Action>
                </ActionsDialog>
                <ActionsDialog>
                    <Action onClick={() => setIsShowDialog(false)}>
                        Закрыть
                    </Action>
                </ActionsDialog>
            </ActionsWrapper>
        </>}
      </WrapperUser>
    );
  }
  return null
}

export default ProfileItem;
