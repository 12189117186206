import React, {useContext, useEffect} from 'react';
import {useProfileAction, useProfileState} from "../../store/profile/hooks";
import styled from "styled-components";
import {Container} from "../../components/Templates/Container";
import LoadingProfile from "../../views/ProfileViews/LoadingProfile";
import ProfileView from "../../views/ProfileViews/ProfileView";
import {useParams} from "react-router-dom";
import {useAppAction} from "../../store/app/hooks";
import {getUserName} from "../../utils/user";
import {ProfileContext} from "../../contexts/ProfileContext";
import {useWallAction} from "../../store/wall/hooks";
import {useTranslate} from "@tolgee/react";


const Wrapper = styled(Container)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;

  ${({theme}) => theme.mediaQueries.md} {
    flex-direction: column;
    gap: 0;
  }
`

function UserProfilePage() {
  const {t} = useTranslate()
  const {profileOther, isLoading} = useProfileState()
  const {onFetchProfileById, onClearProfile} = useProfileAction()
  const {userId} = useParams()
  const {onSetTitle} = useAppAction()
  const {onSetProfile, onRemoveProfile} = useContext(ProfileContext)

  useEffect(() => {
    if (profileOther) {
      onSetTitle(getUserName(profileOther))
      onSetProfile(profileOther)
      return () => {
        onRemoveProfile()
      }
    } else {
      onSetTitle(t('navbar.profile', 'Профиль'))
    }
  }, [onSetTitle, profileOther, t])
  useEffect(() => {
    return () => {
      onClearProfile()
    }
  },[])
  useEffect(() => {
    if (userId !== undefined) {
      onFetchProfileById(userId)
    }
  }, [onFetchProfileById, userId])
  if (!profileOther || isLoading) {
    return (
      <Wrapper>
        <LoadingProfile/>
      </Wrapper>
    );
  }
  return <ProfileView/>
}

export default UserProfilePage;
