import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import AllPaymentsPage from "./AllPaymentsPage";
import CompanyPaymentsPage from "./CompanyPaymentsPage";
import ServicePaymentsPage from "./ServicePaymentsPage";

function Index() {
  return (
    <Routes>
      <Route index element={<AllPaymentsPage/>} />
      <Route path='/company/:id' element={<CompanyPaymentsPage/>} />
      <Route path='/service/:id' element={<ServicePaymentsPage/>} />
      <Route path="*" element={<Navigate to="/admin/payments" />} />
    </Routes>
  );
}

export default Index;
