import {TextTheme, ETextVariants} from "./types";
import {lightColors} from "../../theme/colors";
import { fontWeight } from "../../theme/base";


export const light: TextTheme = {
  [ETextVariants.Button]: {
    fontSize: '1.6rem',
    fontWeight: fontWeight.regular,
    fontStyle: 'normal',
    lineHeight: '2rem',
    letterSpacing: '-0.24px',
    color: lightColors.white,
  },
  [ETextVariants.Body16]: {
    fontSize: '1.6rem',
    fontWeight: fontWeight.regular,
    fontStyle: 'normal',
    lineHeight: '2rem',
    letterSpacing: '-0.24px',
    color: lightColors.black,
    'placeholder': {
      color: lightColors.darkWhite,
      opacity: 1,
    },
  },
  [ETextVariants.Body14]: {
    fontSize: '1.4rem',
    fontWeight: fontWeight.light,
    fontStyle: 'normal',
    lineHeight: '1.7rem',
    letterSpacing: '-0.24px',
    color: lightColors.black,
    'placeholder': {
      color: lightColors.darkWhite,
      opacity: 1,
    },
  },
  [ETextVariants.MiniGray]: {
    fontSize: '1.2rem',
    fontWeight: fontWeight.regular,
    fontStyle: 'normal',
    lineHeight: '1.4rem',
    letterSpacing: '0.3px',
    color: lightColors.mainGray,
  },
  [ETextVariants.MiniDark]: {
    fontSize: '1.2rem',
    fontWeight: fontWeight.regular,
    fontStyle: 'normal',
    lineHeight: '1.4rem',
    letterSpacing: '0.3px',
    color: lightColors.black,
  },
  [ETextVariants.Heading]: {
    fontSize: '2.2rem',
    fontWeight: fontWeight.medium,
    fontStyle: 'normal',
    lineHeight: '2.6rem',
    letterSpacing: '-0.24px',
    color: lightColors.black,
  },
  [ETextVariants.Error]: {
    fontSize: '1.2rem',
    fontWeight: fontWeight.regular,
    fontStyle: 'normal',
    lineHeight: '1.4rem',
    letterSpacing: '0.3px',
    color: lightColors.mainRed,
  },
};
