import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="17" height="17" viewBox="0 0 17 17" fill="none" {...props} >
          <path d="M8.50065 4.50016V8.50016L11.1673 9.8335M15.1673 8.50016C15.1673 12.1821 12.1826 15.1668 8.50065 15.1668C4.81875 15.1668 1.83398 12.1821 1.83398 8.50016C1.83398 4.81826 4.81875 1.8335 8.50065 1.8335C12.1826 1.8335 15.1673 4.81826 15.1673 8.50016Z" stroke="black" strokeOpacity="0.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </Svg>
    );

export default Icon;
