import React, {useCallback} from 'react';
import {IChatMessage} from "../../store/chat/types";
import dayjs from "dayjs";
import styled from 'styled-components';
import {Link} from "react-router-dom";
import Text from "../../UI/Text";
import {CalendarIcon, GeoGrayIcon} from "../../UI/Svg";
import {ETextVariants} from "../../UI/Text/types";
import {Button, EButtonVariants} from "../../UI/Button";
import {useChatAction} from "../../store/chat/hooks";
import {useAuthState} from "../../store/auth/hooks";
import {T} from "@tolgee/react";

const MessageBlock = styled.div`
  width: 100%;
  padding: 16px;
  position: relative;
  //top: 60px;
  z-index: 9;
  display: flex;
  flex-direction: column;
  gap: 10px;
  ${({theme}) => theme.mediaQueries.md} {
    top: 0;
  }
`

const Wrapper = styled.div`
  padding: 16px;
  border-radius: 18px;
  border: 1px solid ${({theme}) => theme.colors.lightGray4};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background: ${({theme}) => theme.colors.white};
  gap: 16px;

`

const Item = styled.li`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;

  svg {
    min-width: 24px;
    height: 24px;
  }

  ${({theme}) => theme.mediaQueries.md} {
    svg {
      min-width: 20px;
      height: 20px;
    }
  }
`

const TextStyled = styled(Text)`
  ${({theme}) => theme.mediaQueries.md} {
    font-size: 12px;
  }
`

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`

const ButtonStyled = styled(Button)`
  padding: 5px 20px;
`

const LinkStyled = styled(Link)`
  color: ${({theme}) => theme.colors.mainPurple};
  text-decoration: underline;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.24px;
`

function MessageInvite({id, event, sender}: IChatMessage) {

  const {user} = useAuthState()

  const {onReactEventInvitation} = useChatAction()

  const onAcceptInvite = useCallback(() => {
    onReactEventInvitation(id, true)
  }, [id, onReactEventInvitation])

  const onRejectInvite = useCallback(() => {
    onReactEventInvitation(id, false)
  }, [id, onReactEventInvitation])


  if (!event) {
    return null;
  }

  return (
    <MessageBlock>
      <Wrapper>
        <Text variant={ETextVariants.Body16}>{event.title}</Text>
        <Item>
          <CalendarIcon/>
          <TextStyled
            variant={ETextVariants.Body16}> {dayjs(event.date).format('D MMMM / HH:mm')}</TextStyled>
        </Item>
        {event.address && <Item>
            <GeoGrayIcon/>
            <TextStyled variant={ETextVariants.Body16}>{event.address.fullString}</TextStyled>
        </Item>}
        <LinkStyled to={`/about-the-event/${event.id}`}>
          <T keyName="chat.invite.page">Страница ивента</T>
        </LinkStyled>
      </Wrapper>
      {user?.id !== sender.id && <Buttons>
        <ButtonStyled $fullWidth css={{marginRight: '1.3rem'}} variant={EButtonVariants.Primary}
                      onClick={onAcceptInvite}>
            <T keyName="chat.invite.buttons.accept">Принять</T>
        </ButtonStyled>
        <ButtonStyled $fullWidth variant={EButtonVariants.Secondary} onClick={onRejectInvite}>
            <T keyName="chat.invite.buttons.reject">Отказаться</T>
        </ButtonStyled>
      </Buttons>}
    </MessageBlock>
  );
}

export default MessageInvite;
