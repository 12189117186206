import React, {useCallback, useMemo} from 'react';
import styled from "styled-components";
import {Container} from "../../../components/Templates/Container";
import {useCompanyAction, useCompanyState} from "../../../store/company/hooks";
import {mediaUrl} from "../../../api";
import {Card} from "../../../components/Templates/Card";
import {ETextVariants} from "../../../UI/Text/types";
import {T, useTranslate} from "@tolgee/react";
import Text from "../../../UI/Text";
import {Link} from "react-router-dom";
import {ArrowLeftIcon} from "../../../UI/Svg";
import FirmFormView from "../../../views/ProfileViews/FirmFormView";
import {useAdminCompaniesAction} from "../../../store/adminCompanies/hooks";
import {CreateCompanyPayload} from "../../../store/company/types";
import {Button, EButtonVariants} from "../../../UI/Button";
import {useAuthAction} from "../../../store/auth/hooks";

const ContainerHeader = styled(Container)`
`

const HeaderCard = styled(Card)`
  border-radius: 10px;
  overflow: hidden;
  height: 248px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const Header = styled.img`
  width: 100%;
  height: auto;
`

const MainContainer = styled(Container)`
  margin-top: 20px;
  gap: 30px;
`

const MainCard = styled(Card)`
  width: 100%;
  max-width: 777px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`
const Title = styled(Text)`
  font-size: 22px;

  ${({theme}) => theme.mediaQueries.md} {
    display: none;
  }
`

const LinkStyled = styled(Link)`
  margin-right: 16px;

  svg {
    path {
      stroke: ${({theme}) => theme.colors.mainPurple};
    }
  }
`

const Buttons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
`

const TextButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`

const ButtonLogout = styled(Button)`
  border: none;
  width: 100%;
`
const ButtonDelete = styled(ButtonLogout)`
  color: ${({theme}) => theme.colors.mainRed};
`

function CompanySettingsPage() {
  const {selectedUserCompany} = useCompanyState()
  const {onUpdateCompany, onClearSelectedUserCompany} = useCompanyAction()
  const {onDeleteCompany} = useAdminCompaniesAction()
  const {isPending} = useCompanyState()
  const {onLogout} = useAuthAction()
  const {t} = useTranslate()

  const onUpdate = useCallback((data: CreateCompanyPayload) => {
    if (selectedUserCompany) {
      onUpdateCompany({...data, id: selectedUserCompany.id})
    }
  }, [onUpdateCompany, selectedUserCompany])
  const onDelete = useCallback((id: number) => {
    if (window.confirm(t('companySettings.deleteConfirm','Вы действительно хотите удалить компанию?'))) {
      onDeleteCompany(id)
    }
  }, [onDeleteCompany, t])
  if (!selectedUserCompany) return null
  return (
    <>
      {selectedUserCompany.header && <ContainerHeader>
        <HeaderCard>
          <Header src={selectedUserCompany.header ? `${mediaUrl}file/${selectedUserCompany.header.id}` : ''}/>
        </HeaderCard>
      </ContainerHeader>}
      <MainContainer>
        <MainCard>
          <Title variant={ETextVariants.Heading} as={'h2'}><LinkStyled to={'/profile'}>
            <ArrowLeftIcon/></LinkStyled>
            <T keyName="common.settings">Настройки</T>
          </Title>
          <FirmFormView data={selectedUserCompany} onSubmitHandler={onUpdate} isLoading={isPending}
                        initialAvatar={selectedUserCompany?.avatar || null}
                        initialHeader={selectedUserCompany?.header || null}/>
          <Buttons>
            <Button $fullWidth variant={EButtonVariants.Secondary} onClick={() => onClearSelectedUserCompany()}>
              <T keyName="companySettings.buttons.switchToUser">Переключиться на пользователя</T>
            </Button>
            <TextButtons>
              <ButtonLogout $fullWidth variant={EButtonVariants.Secondary} onClick={onLogout}>
                <T keyName="common.exit">Выйти</T>
              </ButtonLogout>
              {/*<ButtonDelete $fullWidth variant={EButtonVariants.Secondary}*/}
              {/*              onClick={() => onDelete(selectedUserCompany.id)}>*/}
              {/*  <T keyName="companySettings.buttons.deleteProfile">Удалить профиль</T>*/}
              {/*</ButtonDelete>*/}
            </TextButtons>
          </Buttons>
        </MainCard>
      </MainContainer>
    </>
  );
}

export default CompanySettingsPage;
