import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {ServiceResponse} from "../adminServices/types";
import {ServicesApi} from "../../api/services";

const fetchService = createAsyncThunk<ServiceResponse,
  number,
  {
    state: RootState
  }>(
  'services/fetchService',
  async (id, ThunkApi) => {
    const authState = ThunkApi.getState().auth
    if (authState.auth) {
      const [serviceRes, serviceErr] = await handle(ServicesApi.getOneService(id))
      if (serviceRes) {
        return serviceRes
      }
      if (serviceErr) {
        throw {message: serviceErr.message[0] || serviceErr.message}
      }
    }
    throw {message: 'No user state'}
  }
)

export default fetchService
