import React, {useEffect} from 'react';
import styled from "styled-components";
import Input from "../../UI/Input";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {ArrowLeftIcon, CloseIcon, SearchIcon} from "../../UI/Svg";
import {useForm} from "react-hook-form";
import {useParams} from "react-router-dom";
import {ReportApi} from "../../api/report";
import {Button, EButtonVariants} from "../../UI/Button";
import ReactTextareaAutosize from "react-textarea-autosize";
import {T, useTranslate} from "@tolgee/react";


interface ModalProps {
  onClose: () => void;
  setBodyModal: (data: any) => void;
  setModal: (data: boolean) => void;
}

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  padding-top: 60px;
  z-index: 10;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    content: '';
    position: absolute;
    background: ${({theme}) => theme.colors.transparentGray};
    width: 100%;
    height: 100%;
    padding-bottom: 100%;
    top: 0;
  }

  ${({theme}) => theme.mediaQueries.md} {
    position: absolute;
    border-radius: 0;
    width: 100%;
    height: 100%;
    padding-top: 0;
    margin-bottom: 71px;
  }
`

const Modal = styled.div`
  max-width: 500px;
  width: 100%;
  overflow: hidden;
  top: 0;
  right: 0;
  background: ${({theme}) => theme.colors.bg};
  z-index: 2;
  border-radius: 10px;

  &::-webkit-scrollbar {
    width: 0;
  }


  ${({theme}) => theme.mediaQueries.md} {
    width: 90%;
    border-radius: 0;

  }
`
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 32px 18px 70px;
  width: 100%;
  border-bottom: 1px solid ${({theme}) => theme.colors.darkWhite};
  position: relative;
  gap: 20px;
  background: ${({theme}) => theme.colors.white};

  ${({theme}) => theme.mediaQueries.md} {
    justify-content: center;
    padding: 8px 32px 10px;
    border-bottom: none;
  }
`

const CloseButton = styled.div`
  width: 20px;
  height: 20px;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    width: 12px;

    &:first-child {

      ${({theme}) => theme.mediaQueries.md} {
        display: none;
      }
    }

    &:nth-child(2) {

      display: none;

      ${({theme}) => theme.mediaQueries.md} {
        display: block;
      }
    }
  }

  ${({theme}) => theme.mediaQueries.md} {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 16px;
  }
`

const FormStyled = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  background: ${({theme}) => theme.colors.white};
  padding: 30px;
  height: 100%;
`

const TextAreaStyled = styled(ReactTextareaAutosize)`
  resize: none;
  padding: 12px 16px;
  border-radius: 12px;
  background: ${({theme}) => theme.colors.bg};
  border: none;
  font-size: 16px;
  width: 100%;
  color: #000;
`

function ModalReport({onClose, setModal, setBodyModal}: ModalProps) {
  const {register, handleSubmit, reset} = useForm();
  const {id} = useParams()
  const {t} = useTranslate()


  const onSubmit = (data: any): void => {
    if (id !== undefined) {
      ReportApi.postReport(id, data.text);
      reset();
      setBodyModal({title: t('report.title','Жалоба'), body: t('report.description','Жалоба отправлена на рассмотрение')});
      setModal(true);
      onClose()
    }
  };

  const body = document.querySelector('body');

  useEffect(() => {
    if (body) body.style.overflow = 'hidden';

    return () => {
      if (body) body.style.overflow = 'auto';
    };
  }, [body]);
  return (
    <Wrapper>
      <Modal>
        <Header>
          <Text variant={ETextVariants.Heading}>
            <T keyName={"report.input"}>Введите текст</T>
          </Text>
          <CloseButton onClick={onClose}>
            <CloseIcon/>
            <ArrowLeftIcon/>
          </CloseButton>
        </Header>
        <FormStyled onSubmit={handleSubmit(onSubmit)}>
          <TextAreaStyled
            minRows={4}
            maxRows={10}
            placeholder={t("report.placeholder","Опишите жалобу")}
            {...register('text', {
              required: true,
            })}
          />
          <Button variant={EButtonVariants.Primary}><T keyName={"common.send"}>Отправить</T></Button>
        </FormStyled>
      </Modal>
    </Wrapper>
  );
}

export default ModalReport;
