import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {ServiceResponse} from "../adminServices/types";
import {IServicesFilter} from "./types";
import {ServicesApi} from "../../api/services";
import {sleep} from "../../utils";

const fetchServices = createAsyncThunk<ServiceResponse[],
  void,
  {
    state: RootState
  }>(
  'services/fetchServices',
  async (_, ThunkApi) => {
    const authState = ThunkApi.getState().auth
    const {filter} = ThunkApi.getState().services
    const filterData: any = {}
    for (const filterKey in filter) {
      const field = filterKey as keyof IServicesFilter
      if (field === 'radius' && filter[field] === '0') {
        continue
      }
      if (filter[field] !== undefined && filter[field] !== '') {
        if (field === 'dateTo' || field === 'dateFrom') {
          const date = new Date(filter[field])
          date.setDate(date.getDate() + 1)
          if (field === 'dateTo') {
            date.setUTCHours(23,59,59,999)
          } else {
            date.setUTCHours(0,0,0,0)
          }
          filterData[field] = date
        } else if (field === 'radius') {
          // @ts-ignore
          filterData[field] = parseFloat(filter[field]) / 1000
        } else {
          filterData[field] = filter[field]
        }
      }
    }
    if (!filterData.country === undefined && filterData.city) {
      delete filterData.country
      delete filterData.city
    }
    if (!filterData['radius'] || filterData['radius'] === '0') {
      delete filterData['latitude']
      delete filterData['longitude']
    }
    if (authState.auth) {
      const [servicesRes, servicesErr] = await handle(ServicesApi.getServices(filterData))
      if (servicesRes) {
        await sleep(250)
        return servicesRes
      }
      if (servicesErr) {
        throw {message: servicesErr.message[0] || servicesErr.message}
      }
    }
    throw {message: 'No user state'}
  }
)

export default fetchServices
