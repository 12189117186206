import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container} from "../../components/Templates/Container";
import {Card} from "../../components/Templates/Card";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {useTranslate} from "@tolgee/react";
import Tabs from "../../UI/Tabs";
import './dnd.style.css';
import {useAppAction} from "../../store/app/hooks";
import FormEventView from "../../views/CreateViews/FormEventView";
import FormServiceView from "../../views/CreateViews/FormServiceView";
import {useCompanyState} from "../../store/company/hooks";

const Wrapper = styled(Container)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;

  ${({theme}) => theme.mediaQueries.md} {
    flex-direction: column;
    gap: 0;
  }
`

const Aside = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 343px;
  flex: 1;

  ${({theme}) => theme.mediaQueries.md} {
    display: none;
  }
`

const AsideCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 20px;
`

const Main = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`

function Index({isInvite}: { isInvite?: boolean }) {
  const {t} = useTranslate();
  const {selectedUserCompany} = useCompanyState()
  const [selectTab, setSelectTab] = useState<string>('event');

  const {onSetTitle} = useAppAction()

  useEffect(() => {
    if (!isInvite && selectedUserCompany) {
      setSelectTab('service')
    } else {
      setSelectTab('event')
    }
  }, [isInvite, selectedUserCompany])

  useEffect(() => {
    onSetTitle(t('eventForm.title', 'Создание'))
  }, [onSetTitle, t])

  return (
    <>
      <Wrapper>
        {!isInvite && <Aside>
            <AsideCard>
                <Text variant={ETextVariants.Heading} as={'h2'}>{t('eventForm.title', 'Создание')}</Text>
                <Tabs
                    value={selectTab}
                    onSelect={(tab) => {
                      if (!selectedUserCompany && tab === 'service') return
                      setSelectTab(tab)
                    }}
                    tabs={[
                      {name: t('common.event', 'Ивент'), value: 'event'},
                      {name: t('common.service', 'Услуга'), value: 'service'},
                    ]}/>
            </AsideCard>
        </Aside>}
        <Main>
          <Card>
            {
              selectTab === 'event'
                ? <FormEventView isInvite={isInvite}/>
                : <FormServiceView/>
            }
          </Card>
        </Main>
      </Wrapper>

    </>
  );
}

export default Index;
