import React from 'react';
import {useAdminEventsAction, useAdminEventsState} from "../../store/adminEvents/hooks";
import styled from "styled-components";
import AdminEvent from "../../components/AdminComponents/AdminEvent";
import {Button, EButtonVariants} from "../../UI/Button";
import {T} from "@tolgee/react";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  gap: 20px;
  margin-top: 20px;
  flex-wrap: wrap;
`

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: stretch;
  margin-top: 32px;width: 100%;
`
function EventsListView() {
  const {events, filter, isLastPage} = useAdminEventsState()
  const {onChangePage} = useAdminEventsAction()
  return (
    <Wrapper>
      {
        events.map((event) => <AdminEvent key={event.id} {...event}/>)
      }

      <Buttons>
        <Button onClick={() => onChangePage(filter.page ? filter.page - 1 : 1)} variant={EButtonVariants.Primary} disabled={filter.page === 1}>
          <T keyName="pagination.prev">Предыдущая</T>
        </Button>
        <Button onClick={() => onChangePage(filter.page ? filter.page + 1 : 1)} variant={EButtonVariants.Primary} disabled={isLastPage}>
          <T keyName="pagination.next">Следующая</T>
        </Button>
      </Buttons>
    </Wrapper>
  );
}

export default EventsListView;
