import {useDispatch, useSelector} from "react-redux";
import {useCallback, useEffect, useMemo} from "react";
import {AppDispatch, RootState} from "../index";
import {changeFilter} from "./adminEventsSlice";
import {IAdminEventsFilter} from "./types";
import fetchEvents from "./fetchEvents";
import fetchApproveEvent from "./fetchApproveEvent";
import fetchDeleteEvent from "./fetchDeleteEvent";
import fetchRejectEvent from "./fetchRejectEvent";
import {changePage} from "./adminEventsSlice";

export const getAdminEvents = (state: RootState) => state.adminEvents

export function useAdminEventsAction() {
  const dispatch = useDispatch<AppDispatch>()
  const onChangeFilter = useCallback((data: IAdminEventsFilter) => {
    dispatch(changeFilter(data))
  }, [dispatch])
  const onChangePage = useCallback((page: number) => {
    dispatch(changePage(page))
  }, [dispatch])
  const onFetchEvents = useCallback(() => {
    dispatch(fetchEvents())
  }, [dispatch])
  const onApproveEvent = useCallback((id: string) => {
    dispatch(fetchApproveEvent(id))
      .unwrap()
      .then(onFetchEvents)
  }, [dispatch, onFetchEvents])
  const onDeleteEvent = useCallback((id: string) => {
    dispatch(fetchDeleteEvent(id))
      .unwrap()
      .then(onFetchEvents)
  }, [dispatch, onFetchEvents])
  const onRejectEvent = useCallback((id: string) => {
    dispatch(fetchRejectEvent(id))
      .unwrap()
      .then(onFetchEvents)
  }, [dispatch, onFetchEvents])
  return useMemo(() => ({
    onChangeFilter,
    onFetchEvents,
    onApproveEvent,
    onDeleteEvent,
    onRejectEvent,
    onChangePage,
  }), [
    onChangeFilter,
    onFetchEvents,
    onApproveEvent,
    onDeleteEvent,
    onRejectEvent,
    onChangePage,
  ])
}

export function useAdminEventsState() {
  return useSelector(getAdminEvents)
}

export function useAdminEvents() {
  const {filter} = useAdminEventsState()
  const {onFetchEvents} = useAdminEventsAction()

  useEffect(() => {
    onFetchEvents()
  }, [filter, onFetchEvents])

}
