import {useDispatch, useSelector} from "react-redux";
import {useCallback, useEffect, useMemo} from "react";
import {AppDispatch, RootState} from "../index";
import {IAdminUsersFilter} from "./types";
import {changeFilter, changePage} from "./adminUsersSlice";
import fetchUsers from "./fetchUsers";

export const getAdminUsers = (state: RootState) => state.adminUsers

export function useAdminUsersAction() {
  const dispatch = useDispatch<AppDispatch>()
  const onChangeFilter = useCallback((data: IAdminUsersFilter) => {
    dispatch(changeFilter(data))
  }, [dispatch])
  const onFetchUsers = useCallback(() => {
    dispatch(fetchUsers())
  }, [dispatch])
  const onChangePage = useCallback((page: number) => {
    dispatch(changePage(page))
  }, [dispatch])
  // const onCreateWord = useCallback((word: string) => {
  //   dispatch(fetchPostWords(word))
  //     .unwrap().then(() => onFetchWords())
  // }, [dispatch, onFetchWords])
  return useMemo(() => ({
    onChangeFilter,
    onFetchUsers,
    onChangePage,
  }), [
    onChangeFilter,
    onFetchUsers,
    onChangePage,
  ])
}

export function useAdminUsersState() {
  return useSelector(getAdminUsers)
}

export function useAdminUsers() {
  const {filter} = useAdminUsersState()
  const {onFetchUsers} = useAdminUsersAction()

  useEffect(() => {
    onFetchUsers()
  }, [filter, onFetchUsers])
}
