import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {INotification} from "./types";
import fetchNotifications from "./fetchNotifications";

export type NotificationsState = {
    notifications: INotification[],
    isLoading: boolean
}

const initialState: NotificationsState = {
    notifications: [],
    isLoading: true,
}

export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(fetchNotifications.fulfilled, (state, action: PayloadAction<INotification[]>) => {
            state.notifications = action.payload
            state.isLoading = false
        })
        builder.addCase(fetchNotifications.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchNotifications.rejected, (state) => {
            state.isLoading = false
        })
    }
})

// export const {} = notificationsSlice.actions
export default notificationsSlice.reducer
