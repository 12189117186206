import React, {useCallback, useEffect, useState} from 'react';
import styled from "styled-components";
import {Container} from "../../components/Templates/Container";
import {useChatAction} from "../../store/chat/hooks";
import Contacts from "../../views/ChatViews/Contacts";
import {Button, EButtonVariants} from "../../UI/Button";
import {T, useTranslate} from "@tolgee/react";
import ModalUsers from "../../components/Modals/ModalUsers";
import {useProfileAction, useProfileState} from "../../store/profile/hooks";
import {useAppAction} from "../../store/app/hooks";
import {Navigate, Route, Routes} from 'react-router-dom';
import NoChat from "../../components/ChatComponents/NoChat";
import Dialog from './Dialog';
import {Navigation} from "swiper/types/modules";
import NotificationsView from "../../views/ChatViews/NotificationsView";
import {ChatUsersIcon, EditIcon, UsersIcon} from "../../UI/Svg";
import ModalChatUsers from "../../components/Modals/ModalChatUsers";
import ModalCreateChat from "../../components/Modals/ModalCreateChat";
import {useAuthState} from "../../store/auth/hooks";


const Wrapper = styled(Container)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  max-width: 1260px;
  height: calc(100vh - 110px);
  padding-bottom: 20px;

  ${({theme}) => theme.mediaQueries.md} {
    flex-direction: column;
    gap: 0;
    position: relative;
    //height: calc(100vh - 127px);
    height: unset;
    flex: 1;
    padding-bottom: 0;
    overflow: hidden;
    z-index: 1000;
  }
`

const Aside = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 464px;
  gap: 15px;
  height: 100%;

  ${({theme}) => theme.mediaQueries.md} {
    display: none;
  }
`

const ButtonStyled = styled(Button)`
  padding: 18px 20px;

  ${({theme}) => theme.mediaQueries.md} {
    display: none;
  }
`

const Main = styled.div`
  display: flex;
  width: 100%;
  min-height: 660px;
  height: 100%;
  background: ${({theme}) => theme.colors.white};
  border-radius: 10px;

  ${({theme}) => theme.mediaQueries.md} {
    //height: calc(100vh - 80px);
    min-height: unset;
  }
`

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  ${({theme}) => theme.mediaQueries.md} {
    display: none;
  }
`

const MobileContacts = styled.div`
  width: 100%;
  display: none;
  flex-direction: column;
  gap: 15px;
  height: 100%;
  padding: 16px;

  ${({theme}) => theme.mediaQueries.md} {
    display: flex;
  }
`

const OpenModalButton = styled.button`
  display: none;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border: none;
  background: transparent;
  outline: none;
  position: absolute;
  top: 8px;
  right: 16px;
  z-index: 1000;

  svg path {
    stroke: ${({theme}) => theme.colors.mainPurple};
  }

  ${({theme}) => theme.mediaQueries.md} {
    display: flex;
  }
`

function Index() {
  const {onSetTitle} = useAppAction()
  const {t} = useTranslate()

  useEffect(() => {
    onSetTitle(t('chats.title','Мои чаты'))
  }, [onSetTitle, t])
  const {onFetchProfile} = useProfileAction()
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [isShowCreateModal, setIsShowCreateModal] = useState<boolean>(false);
  const {onFetchUserChats} = useChatAction()

  useEffect(() => {
    onFetchProfile()
    onFetchUserChats()
  }, [])


  return (
    <>
      <OpenModalButton onClick={() => setIsShowModal(true)}>
        <EditIcon/>
      </OpenModalButton>
      <Wrapper>
        <Aside>
          <Contacts/>
          <ButtonStyled $fullWidth variant={EButtonVariants.Primary} onClick={() => setIsShowModal(true)}>
            <T keyName="chats.buttons.createChat">Создать чат</T>
          </ButtonStyled>
        </Aside>
        <Main>
          <Routes>
            <Route
              index
              element={
                <>
                  <EmptyWrapper>
                    <NoChat startCreateChat={() => setIsShowModal(true)}/>
                  </EmptyWrapper>
                  <MobileContacts>
                    <Contacts/>
                    <ButtonStyled $fullWidth variant={EButtonVariants.Primary} onClick={() => setIsShowModal(true)}>
                      <T keyName="chats.buttons.createChat">Создать чат</T>
                    </ButtonStyled>
                  </MobileContacts>
                  {/*<MobileContacts startCreateChat={startCreateChat} />*/}
                </>
              }
            />
            <Route
              path="/:chatId"
              element={
                <Dialog/>
              }
            />
            <Route
              path="/notification"
              element={
                <NotificationsView/>
              }
            />
            <Route
              path="*"
              element={
                <Navigate to={'/chat'}/>
              }
            />
          </Routes>
        </Main>
      </Wrapper>

      {
        isShowModal && <ModalChatUsers onClose={() => {
          setIsShowModal(false)
        }} onShowCreate={() => {
          setIsShowCreateModal(true)
          setIsShowModal(false)
        }}/>
      }
      {
        isShowCreateModal && <ModalCreateChat onClose={() => {
          setIsShowCreateModal(false)
        }}/>
      }
    </>
  );
}

export default Index;
