import styled from "styled-components";
import Text from "../../UI/Text";
import {Button} from "../../UI/Button";
import {Link} from "react-router-dom";
import {EPaymentStatus, ETicketStatus} from "../../store/cart/types";
import FormCountComponent from "../ServicesComponents/FormCountComponent";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  align-items: flex-start;
  padding: 30px;
  ${({theme}) => theme.mediaQueries.md} {
    padding: 12px 10px;
    background: ${({theme}) => theme.colors.white};
    border-radius: 18px;
  }
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  justify-content: flex-start;
  gap: 20px;
`

export const ItemRow = styled(Row)`
  ${({theme}) => theme.mediaQueries.md} {
    border-bottom: 1px solid ${({theme}) => theme.colors.lightWhite0};
    gap: 14px;
    padding-bottom: 12px;
  }
`

export const EventImage = styled.div`
  display: flex;
  align-items: center;
  background: ${({theme}) => theme.colors.bg};
  justify-content: center;
  border-radius: 18px;
  overflow: hidden;
  height: 266px;
  width: 323px;

  img {
    width: auto;
    height: 100%;
  }

  ${({theme}) => theme.mediaQueries.md} {
    height: 113px;
    width: 97px;
    min-width: 97px;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;

  ${({theme}) => theme.mediaQueries.md} {
    gap: 7px;
  }
`
export const Description = styled(Text)`
  max-height: 60px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  width: 65%;

  ${({theme}) => theme.mediaQueries.md} {
    display: none;
  }
`

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid ${({theme}) => theme.colors.lightWhite0};
  ${({theme}) => theme.mediaQueries.md} {
    border-bottom: none;
  }
`

export const Item = styled.li`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;

  svg {
    min-width: 24px;
    height: 24px;
  }

  ${({theme}) => theme.mediaQueries.md} {
    svg {
      min-width: 20px;
      height: 20px;
    }
  }
`

export const TextStyled = styled(Text)`
  text-overflow: ellipsis;
  width: 100%;
  ${({theme}) => theme.mediaQueries.md} {
    font-size: 12px;
  }
`
export const Members = styled(TextStyled)`
  white-space: pre;
  color: ${({theme}) => theme.colors.mainPurple};
  overflow: hidden;

  ${({theme}) => theme.mediaQueries.md} {
    width: 65%;
  }
`

export const CompanyBlock = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  ${({theme}) => theme.mediaQueries.md} {
    display: none;
  }
`

export const CompanyLogo = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 50%;
  background: #9b9b9b;

  img {
    width: auto;
    height: 100%;
  }
`

export const CompanyDesc = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1px;
`

export const CompanyTitle = styled(Text)`
  color: ${({theme}) => theme.colors.black};
`
export const CompanySubtitle = styled.p`
  color: ${({theme}) => theme.colors.black};
  font-size: 10px;
  font-weight: 300;
`

export const TitleBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 42px;
  ${({theme}) => theme.mediaQueries.md} {
    padding-right: 40px;
  }
`

export const ButtonsBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: fit-content;
  min-width: 323px;
`
export const ItemDesktopButtonsBlock = styled(ButtonsBlock)`
  display: flex;
  ${({theme}) => theme.mediaQueries.md} {
    display: none;
  }
`
export const ItemButtonsBlock = styled(ButtonsBlock)`
  display: none;
  ${({theme}) => theme.mediaQueries.md} {
    display: flex;
    justify-content: space-between;
    width: 100%;
    & > div {
      justify-content: flex-end;
    }
  }
`

export const DeleteButton = styled.button`
  padding: 0;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  cursor: pointer;

  svg path {
    stroke: ${({theme}) => theme.colors.mainPurple};
  }
`

export const ButtonStyled = styled(Button)<{ status?: ETicketStatus, paymentStatus?: EPaymentStatus }>`
  min-width: 289px;
  width: 100%;
  align-items: center;
  color: rgba(0, 0, 0, 0.50);

  background: ${({status, paymentStatus}) => status === ETicketStatus.Available
          ? 'rgba(90, 176, 85, 0.10)'
          : status === ETicketStatus.AwaitCash
                  ? 'rgba(0, 0, 0, 0.1)'
                  : paymentStatus === EPaymentStatus.Cancelled ? 'rgba(176,85,85,0.1)' : 'rgba(0, 0, 0, 0.1)'};

  &:disabled {
    opacity: 1;
    color: rgba(0, 0, 0, 0.50);
    background: ${({status, paymentStatus}) => status === ETicketStatus.Available
            ? 'rgba(90, 176, 85, 0.10)'
            : status === ETicketStatus.AwaitCash
                    ? 'rgba(0, 0, 0, 0.1)'
                    : paymentStatus === EPaymentStatus.Cancelled ? 'rgba(176,85,85,0.1)' : 'rgba(0, 0, 0, 0.1)'};
  }

  svg path {
    stroke: rgba(0, 0, 0, 0.50);
  }
`

export const QRButton = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  ${({theme}) => theme.mediaQueries.md} {
    top: 12px;
    right: 12px;
  }
`


export const CheckboxItem = styled.div`
  width: 24px;
  height: 24px;
  min-width: 24px;
  margin-right: 10px;
  border-radius: 50%;
  user-select: none;
  border: 1px solid ${({theme}) => theme.colors.lightGray};
  cursor: pointer;
  
`
