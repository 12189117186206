import React, {useCallback, useEffect, useRef} from 'react';
import styled from 'styled-components';
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {useNavigate} from "react-router-dom";
import {Col} from "../Templates/Col";
import {getTextStyle} from "../../UI/Text/utils";
import PhoneInput from "react-phone-input-2";
import {T} from "@tolgee/react";
import {useCartAction, useCartState} from "../../store/cart/hooks";

export interface IModal {
  onClose: () => void;
  onCloseAll: () => void;
}

const WrapModal = styled.div(({ theme }) => {
  return {
    position: 'fixed',
    width: '100%',
    height: '100%',
    zIndex: '100002',
    left: 0,
    top: 0,
    background: theme.colors.transparentGray,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
});

const WinModal = styled.div(({ theme }) => {
  return {
    maxWidth: 270,
    top: 0,
    right: 0,
    overflowY: 'hidden',
    overflowX: 'hidden',
    background: theme.colors.white,
    zIndex: 2,
    borderRadius: 15,
    paddingTop: 20,
  };
});

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Title = styled(Text)`
  font-size: 17px;
  font-weight: bold;
  text-align: center;
  padding: 4px;
`

const Content = styled.div`
  width: 100%;
  padding: 0 16px;
`

const ConfirmBlock = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.35);
  padding: 11px 0;
  display: flex;
  justify-content: center;
  gap: 10px;
`

const ConfirmText = styled(Text)`
  color: ${({theme}) => theme.colors.mainPurple};
  font-size: 17px;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  padding: 4px 16px;
  &:last-child {
    font-weight: bold;
  }
`

const PhoneInputBlock = styled(Col)`
  padding-bottom: 16px;
  & .form-control {
    width: 100%;
    height: 4rem;
    padding: 1rem 2rem 1rem 5rem;
    border-radius: 10px;
    background-color: ${({theme}) => theme.colors.lightWhite0};
    border: 0;
    ${({theme}) => getTextStyle(theme, ETextVariants.Body16)};
  };

  & .flag-dropdown {
    border: none;
    border-radius: 0px;
    background-color: transparent !important;
  };

  & .selected-flag {
    background-color: transparent !important;
    padding-left: 2rem;
  };
`
function PhoneModal({onClose, onCloseAll}: IModal) {
  const bodyMain = document.querySelector('body');
  const navigate = useNavigate()
  const {phone} = useCartState()
  const {onChangePhone} = useCartAction()

  useEffect(() => {
    if (bodyMain) bodyMain.style.overflow = 'hidden';

    return () => {
      if (bodyMain) bodyMain.style.overflow = 'auto';
    };
  }, [bodyMain]);
  const modal = useRef(null)

  const onCloseHandler = useCallback((event: any) => {
    if (event.target === modal.current) {
      onClose()
      onChangePhone('')
    }
  }, [onChangePhone, onClose])

  return (
    <WrapModal onClick={onCloseHandler} ref={modal}>
      <WinModal>
        <Header>
          <Title variant={ETextVariants.Heading} as={'h2'}>
            <T keyName={"phoneModal.title"}>Укажите номер получателя подарка</T>
          </Title>
        </Header>
        <Content>
          <PhoneInputBlock>
            <PhoneInput
              country="lv"
              placeholder=""
              inputProps={{ name: 'phoneNumber' }}
              value={phone}
              onChange={onChangePhone}
            />
          </PhoneInputBlock>
        </Content>
        <ConfirmBlock>
          <ConfirmText variant={ETextVariants.Body16} onClick={() => {
            onClose()
            onChangePhone('')
          }}>
            <T keyName={"common.cancel"}>Отмена</T>
          </ConfirmText>
          <ConfirmText variant={ETextVariants.Body16} onClick={() => {
            navigate('/payment/cart')
            onCloseAll()
          }}>
            <T keyName={"phoneModal.pay"}>К оплате</T>
          </ConfirmText>
        </ConfirmBlock>
      </WinModal>
    </WrapModal>
  );
}

export default PhoneModal;
