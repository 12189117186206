import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {ForbiddenApi} from "../../api/forbidden";

const fetchDeleteWord = createAsyncThunk<void,
  number,
  {
    state: RootState
  }>(
  'adminForbiddenWords/fetchDeleteWord',
  async (id, ThunkApi) => {
    const authState = ThunkApi.getState().auth
    if (authState.auth) {
      const [wordsRes, wordsErr] = await handle(ForbiddenApi.deleteWord(id))
      if (wordsRes) {
        return
      }
      if (wordsErr) {
        throw {message: wordsErr.message[0] || wordsErr.message}
      }
      return
    }
    throw {message: 'No user state'}
  }
)

export default fetchDeleteWord
