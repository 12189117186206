import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled from "styled-components";
import {Container} from "../../components/Templates/Container";
import {Card} from "../../components/Templates/Card";
import {mediaUrl} from "../../api";
import {useCompanyAction, useCompanyState} from "../../store/company/hooks";
import {Link} from "react-router-dom";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {T, useTranslate} from "@tolgee/react";
import {ArrowRightIcon, SearchIcon} from "../../UI/Svg";
import CompanyDescriptionView from "./CompanyDescriptionView";
import CreatePostOrServiceView from "./CreatePostOrServiceView";
import {ICompany, IUserCompany} from "../../store/company/types";
import Input from "../../UI/Input";
import CompanyServicesListView from "./CompanyServicesListView";
import {useProfileState} from "../../store/profile/hooks";
import {useWallAction} from "../../store/wall/hooks";
import CompanyWall from "./CompanyWall";
import {Button, EButtonVariants} from "../../UI/Button";
import {useAuthState} from "../../store/auth/hooks";
import {useChatAction} from "../../store/chat/hooks";
import imgEmptyAvatar from "../../assets/img/no-avatar.png";
import {useAppAction} from "../../store/app/hooks";
import {useCompanySubscribe} from "../../store/company/useCompanySubscribe";

const ContainerHeader = styled(Container)`
`

const HeaderCard = styled(Card)`
  border-radius: 10px;
  overflow: hidden;
  height: 248px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0;

  ${({theme}) => theme.mediaQueries.md} {
    height: 155px;
    border-radius: 0;
  }
`

const Header = styled.img`
  width: 100%;
  height: auto;
`
const MainContainer = styled(Container)`
  margin-top: 20px;
  gap: 30px;

  ${({theme}) => theme.mediaQueries.md} {
    gap: 2px;
    margin-top: 2px;
  }
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;

  ${({theme}) => theme.mediaQueries.md} {
    gap: 2px;
  }
`

const Aside = styled.div`
  width: 100%;
  max-width: 343px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;

  ${({theme}) => theme.mediaQueries.md} {
    display: none;
  }
`

const AsideLink = styled(Link)`
  background: ${({theme}) => theme.colors.white};
  width: 100%;
  padding: 16px 30px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > svg {
    path {
      stroke: ${({theme}) => theme.colors.textGray};
    }
  }
`

const AsideLinkLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
`

const TitleCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  width: 100%;
`

const Title = styled(Text)`
  font-size: 30px;
  font-weight: 500;
  width: 100%;
  line-height: 20px;
  letter-spacing: -0.24px;

  ${({theme}) => theme.mediaQueries.md} {
    font-size: 22px;
  }
`
const Subtitle = styled(Text)`
  color: ${({theme}) => theme.colors.textGray};
`


const CardSearch = styled(Card)`
  display: flex;
  width: 100%;
  gap: 10px;
  flex-direction: column;
  padding: 16px 30px;

  ${({theme}) => theme.mediaQueries.md} {
    padding: 15px;
  }
`

const InputStyled = styled(Input)`
  color: ${({theme}) => theme.colors.mainPurple};
  background: #F7F7FA;
  border: none;

  &::placeholder {
    color: ${({theme}) => theme.colors.mainPurple};
    font-weight: 400;
  }

  ${({theme}) => theme.mediaQueries.md} {
  }
`
const SearchStyled = styled.div`
  width: 20px;
  height: 20px;

  svg {
    width: 20px;
    height: 20px;

    path {
      stroke: ${({theme}) => theme.colors.mainPurple};
    }
  }
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
`
const ProfileImage = styled.div`
  max-height: 71px;
  height: 71px;
  width: 100%;
  max-width: 71px;
  overflow: hidden;
  border-radius: 50%;
  display: none;
  align-items: center;
  justify-content: center;
  background: #9b9b9b;

  img {
    height: 100%;
    width: auto;
    max-width: unset;
  }

  ${({theme}) => theme.mediaQueries.md} {
    display: flex;
  }
`

function CompanyView(company: IUserCompany | ICompany) {
  const {selectedUserCompany, subscribers} = useCompanyState()
  const {onFetchSubscribers} = useCompanyAction()
  const {t} = useTranslate()
  const {isSendData} = useProfileState()
  const {user} = useAuthState()
  const {onCreateCompanyChat} = useChatAction()
  const {onSubscribeCompany, onUnsubscribeCompany} = useCompanySubscribe()
  const {onFetchWallCompany, onResetWall} = useWallAction()
  const {onSetTitle} = useAppAction()

  useEffect(() => {
    if (selectedUserCompany?.id === company.id) {
      onSetTitle(t('company.my', 'Моя компания'))
    } else {
      onSetTitle(company.title)
    }
  }, [company.id, company.title, onSetTitle, selectedUserCompany?.id, t])

  const isSubscribe = useMemo(() => {
    if (user) {
      return subscribers.filter((item) => item.id === user.id).length > 0
    }
    return false
  }, [subscribers, user])
  useEffect(() => {
    if (selectedUserCompany) {
      onFetchWallCompany(company.id)
      return () => onResetWall()
    }
  }, [selectedUserCompany])

  const onSubscribeHandler = useCallback(() => {
    if (isSubscribe) {
      onUnsubscribeCompany(company.id)
    } else {
      onSubscribeCompany(company.id)
    }
    onFetchSubscribers(company.id)
  }, [company.id, isSubscribe, onFetchSubscribers, onSubscribeCompany, onUnsubscribeCompany])

  const [search, setSearch] = useState('');

  if (!company) return null
  return (
    <>
      {company.header && <ContainerHeader>
          <HeaderCard>
              <Header src={company.header ? `${mediaUrl}file/${company.header.id}` : ''}/>
          </HeaderCard>
      </ContainerHeader>}
      <MainContainer>
        <Content>
          <TitleCard>
            <TitleWrapper>
              <ProfileImage>
                <img src={company.avatar ? `${mediaUrl}file/${company.avatar.id}` : imgEmptyAvatar} alt=""/>
              </ProfileImage>
              <Title variant={ETextVariants.Heading} as={'h2'}>{company.title}</Title>
            </TitleWrapper>
            {/*<Subtitle variant={ETextVariants.Body16} as={'p'}>Скидки для детей в выходные дни*/}
            {/*  Абонементы в баню</Subtitle>*/}
            {(company.id !== selectedUserCompany?.id && company.owner.id !== user?.id) &&
                <>
                    <Button onClick={onSubscribeHandler} $fullWidth
                            variant={isSubscribe ? EButtonVariants.Secondary : EButtonVariants.Primary}
                            disabled={isSendData}>
                      {
                        isSubscribe
                          ? <T keyName="common.buttons.unsubscribe">Отписаться</T>
                          : <T keyName="common.buttons.subscribe">Подписаться</T>
                      }
                    </Button>
                    <Button onClick={() => onCreateCompanyChat(company.id)} $fullWidth
                            variant={EButtonVariants.Primary}>
                        <T keyName="company.buttons.sendMessage">Написать сообщение</T>
                    </Button>

                </>
            }
          </TitleCard>
          <CompanyDescriptionView {...company}/>
          {company.id === selectedUserCompany?.id && <CreatePostOrServiceView/>}
          <CardSearch>
            <InputStyled
              placeholder={t('company.search.placeholder', 'Поиск по услугам')}
              onChange={(e) => setSearch(e.target.value)}
              wth="100%"
              value={`${search}`}
              icon={
                <SearchStyled><SearchIcon/></SearchStyled>
              }
            />
          </CardSearch>
          <CompanyServicesListView company={company} search={search}/>
          <CompanyWall id={company.id}/>
        </Content>
        <Aside>
          {company.id === selectedUserCompany?.id && <AsideLink to={'/profile/settings'}>
              <AsideLinkLeft>
                  <Text variant={ETextVariants.Body16}><T keyName="common.settings">Настройки</T></Text>
              </AsideLinkLeft>
              <ArrowRightIcon/>
          </AsideLink>}
        </Aside>
      </MainContainer>
    </>
  );
}

export default CompanyView;
