import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {AuthApi} from "../../api/auth";

const fetchSendSms = createAsyncThunk<{ phoneNumber: string, nextStep: string },
  string,
  {
    state: RootState
  }>(
  'auth/fetchSendSms',
  async (phoneNumber) => {
    const [authRes, authErr] = await handle(AuthApi.postSendSms(phoneNumber))
    if (authRes) {
      return {phoneNumber, ...authRes}
    }
    if (authErr) {
      throw authErr
    }
  }
)

export default fetchSendSms
