import React from 'react';
import styled from "styled-components";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {useCompanyAction, useCompanyState} from "../../store/company/hooks";
import {ECompanyStatus} from "../../store/company/types";
import {ArrowLeftIcon, ArrowRightIcon, CloseIcon} from "../../UI/Svg";
import {T} from "@tolgee/react";

interface ModalSelectFirmProps {
  onClose: () => void;
}

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  padding-top: 60px;
  z-index: 110;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    content: '';
    position: absolute;
    background: ${({theme}) => theme.colors.transparentGray};
    width: 100%;
    height: 100%;
    padding-bottom: 100%;
    top: 0;
  }

  ${({theme}) => theme.mediaQueries.md} {
    
    //position: absolute;
    //border-radius: 0;
    //width: 100%;
    //height: 100%;
    //padding-top: 0;
    //margin-bottom: 71px;
  }
`

const Modal = styled.div`
  max-width: 600px;
  height: 100vh;
  max-height: 600px;
  width: 100%;
  overflow: auto;
  top: 0;
  right: 0;
  background: ${({theme}) => theme.colors.bg};
  z-index: 2;
  border-radius: 10px;

  &::-webkit-scrollbar {
    width: 0;
  }

  ${({theme}) => theme.mediaQueries.lg} {
    width: 770px;
  }

  ${({theme}) => theme.mediaQueries.md} {
    border-radius: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    overflow: scroll;
    max-height: unset;

  }
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px;
  width: 100%;
  border-bottom: 1px solid ${({theme}) => theme.colors.darkWhite};
  position: relative;
  gap: 20px;
  background: ${({theme}) => theme.colors.white};

  ${({theme}) => theme.mediaQueries.md} {
    justify-content: center;
    padding: 8px 32px 10px;
    border-bottom: none;
  }
`

const List = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

const FirmItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 8px 16px;
  background: ${({theme}) => theme.colors.white};
  border-bottom: 1px solid ${({theme}) => theme.colors.bg};
  &:hover {
    background: ${({theme}) => theme.colors.lightWhite};
  }
`

const FirmItemDesc = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  justify-content: flex-start;
`

const Status = styled(Text)<{status: ECompanyStatus}>`
  color: ${({theme, status}) => status === ECompanyStatus.pending 
          ? theme.colors.lightOrange 
          : status === ECompanyStatus.approved 
                  ? theme.colors.mainGreen
                  : theme.colors.mainRed };
`

const CloseButton = styled.div`
  width: 20px;
  height: 20px;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    width: 24px;

    &:first-child {

      ${({theme}) => theme.mediaQueries.md} {
        display: none;
      }
    }

    &:nth-child(2) {

      display: none;

      ${({theme}) => theme.mediaQueries.md} {
        display: block;
      }
    }
  }

  ${({theme}) => theme.mediaQueries.md} {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 16px;
  }
`


function ModalSelectFirm({onClose}: ModalSelectFirmProps) {
  const {userCompanies} = useCompanyState()
  const {onFetchSessionCompany} = useCompanyAction()

  return (
    <Wrapper>
      <Modal>
        <Header>
          <Text variant={ETextVariants.Heading}>
            <T keyName="modalSelectFirm.title">Мои фирмы</T>
          </Text>
          <CloseButton onClick={onClose}>
            <CloseIcon/>
            <ArrowLeftIcon/>
          </CloseButton>
        </Header>
        <List>
          {userCompanies.map((company) => <FirmItem onClick={() => {
            if (company.status === ECompanyStatus.approved) {
              onFetchSessionCompany(company.id)
              onClose()
            }
          }} key={company.id}>
            <FirmItemDesc>
              <Text as={'h4'} variant={ETextVariants.Body16}>{company.title}</Text>
              <Status as={'p'} variant={ETextVariants.Body14} status={company.status}>
                {
                  company.status === ECompanyStatus.pending
                  ? <T keyName="modalSelectFirm.status.pending">На модерации</T>
                    : company.status === ECompanyStatus.approved
                      ? <T keyName="modalSelectFirm.status.approved">Активна</T>
                      : <T keyName="modalSelectFirm.status.rejected">Отклонена</T>
                }
              </Status>
            </FirmItemDesc>
            <ArrowRightIcon/>
          </FirmItem>) }
        </List>
      </Modal>
    </Wrapper>
  );
}

export default ModalSelectFirm;
