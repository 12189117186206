import {DefaultTheme} from "styled-components";
import {ETextVariants} from "./types";

export const getTextStyle = (theme: DefaultTheme, variant: ETextVariants) => {
  return `
  display: inline;
  font-size: ${theme.text[variant].fontSize};
  font-weight:  ${theme.text[variant].fontWeight};
  font-style: ${theme.text[variant].fontStyle};
  line-height: ${theme.text[variant].lineHeight};
  letter-spacing: ${theme.text[variant].letterSpacing};
  color: ${theme.text[variant].color};
  ${theme.text[variant].placeholder ? `&::placeholder {
    color: ${theme.text[variant].placeholder?.color};
    opacity: ${theme.text[variant].placeholder?.opacity};
    font-weight: 500;
  }` : ''}
  `
}
