import {EPallets, PalletsTheme} from "./types";
import {lightColors} from './colors'

export const lightPallets: PalletsTheme = {
    [EPallets.Primary]: {
        default: lightColors.mainPurple,
        hover: lightColors.lightPurple,
        active: lightColors.lightPurple0,
        light: lightColors.lightPurple,
        dark: lightColors.black,
        disabled: lightColors.lightGray,
    },
    [EPallets.Secondary]: {
        default: lightColors.mainOrange,
        hover: lightColors.lightOrange,
        active: lightColors.darkOrange,
        light: lightColors.lightGray,
        dark: lightColors.black,
        disabled: lightColors.lightGray,
    },
}
