import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import fetchWall from "./fetchWall";
import {IWall} from "./types";
import fetchWallCompany from "./fetchWallCompany";

export interface WallState {
  wall: IWall[],
}

const initialState: WallState = {
  wall: [],
}

export const wallSlice = createSlice({
  name: 'wall',
  initialState,
  reducers: {
    resetWall: (state) => {
      state.wall = []
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchWall.fulfilled, (state, action: PayloadAction<IWall[]>) => {
      state.wall = action.payload
    })
    builder.addCase(fetchWallCompany.fulfilled, (state, action: PayloadAction<IWall[]>) => {
      state.wall = action.payload
    })
  }
})

export const {resetWall} = wallSlice.actions
export default wallSlice.reducer
