import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} >
          <rect width="24" height="24" rx="12" fill="#EDEDED"/>
          <path d="M11.9997 12.5837C12.9662 12.5837 13.7497 11.8002 13.7497 10.8337C13.7497 9.86716 12.9662 9.08366 11.9997 9.08366C11.0332 9.08366 10.2497 9.86716 10.2497 10.8337C10.2497 11.8002 11.0332 12.5837 11.9997 12.5837Z" stroke="#BCBCBC" strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M11.9997 17.8337C14.333 15.5003 16.6663 13.411 16.6663 10.8337C16.6663 8.25633 14.577 6.16699 11.9997 6.16699C9.42235 6.16699 7.33301 8.25633 7.33301 10.8337C7.33301 13.411 9.66634 15.5003 11.9997 17.8337Z" stroke="#BCBCBC" strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round"/>
        </Svg>
    );

export default Icon;
