import React from 'react';
import styled from "styled-components";
import {SpinnerIcon} from "../../UI/Svg";
import ContentLoader from "react-content-loader";
const LoadingBlockStyled = styled.div`
  display: flex;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.md} {
    display: none;
  }
`

const LoadingIcon = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  svg {
    display: none;
    width: 44px;
    height: 44px;
    ${({theme}) => theme.mediaQueries.md} {
      display: block;
    }
  }
  ${({theme}) => theme.mediaQueries.md} {
    display: flex;
  }
`
const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
`

const Aside = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
  max-width: 343px;
`

function LoadingAbout() {
  return (
    <>
      <Main>
        <ContentLoader
          speed={2}
          width="100%"
          height={326}
          backgroundColor="#fff"
          foregroundColor="#ecebeb"
          style={{ borderTopLeftRadius: '1rem', borderTopRightRadius: '1rem' }}
        >
          <rect x="0" y="0" rx="0" ry="0" width="100%" height="833" />
        </ContentLoader>
        <ContentLoader
          speed={2}
          width="100%"
          height={130}
          backgroundColor="#fff"
          foregroundColor="#ecebeb"
          style={{  }}
        >
          <rect x="0" y="0" rx="0" ry="0" width="100%" height="833" />
        </ContentLoader>
        <ContentLoader
          speed={2}
          width="100%"
          height={80}
          backgroundColor="#fff"
          foregroundColor="#ecebeb"
          style={{  }}
        >
          <rect x="0" y="0" rx="0" ry="0" width="100%" height="833" />
        </ContentLoader>

        <ContentLoader
          speed={2}
          width="100%"
          height={159}
          backgroundColor="#fff"
          foregroundColor="#ecebeb"
          style={{ marginBottom: 30, borderBottomLeftRadius: '1rem', borderBottomRightRadius: '1rem' }}
        >
          <rect x="0" y="0" rx="0" ry="0" width="100%" height="833" />
        </ContentLoader>
        <ContentLoader
          speed={2}
          width="100%"
          height={143}
          backgroundColor="#fff"
          foregroundColor="#ecebeb"
          style={{ borderRadius: '1rem' }}
        >
          <rect x="0" y="0" rx="0" ry="0" width="100%" height="833" />
        </ContentLoader>
      </Main>
      <Aside>
        <ContentLoader
          speed={2}
          width="100%"
          height={160}
          backgroundColor="#fff"
          foregroundColor="#ecebeb"
          style={{ borderRadius: '1rem' }}
        >
          <rect x="0" y="0" rx="0" ry="0" width="100%" height="201" />
        </ContentLoader>
      </Aside>
      <LoadingIcon>
        <SpinnerIcon/>
      </LoadingIcon>
    </>
  );
}

export default LoadingAbout;
