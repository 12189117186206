import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {INotification} from "./types";
import {NotificationsApi} from "../../api/notifications";

const fetchNotifications = createAsyncThunk<INotification[],
  void,
  {
    state: RootState
  }>(
  'notifications/fetchNotifications',
  async (_, ThunkApi) => {
    const authState = ThunkApi.getState().auth
    if (authState.auth) {
      const [notificationsRes, notificationsErr] = await handle(NotificationsApi.getUserNotification())
      if (notificationsRes) {
        return notificationsRes
      }
      if (notificationsErr) {
        throw {message: notificationsErr.message[0] || notificationsErr.message}
      }
    }
    throw {message: 'No user state'}
  }
)

export default fetchNotifications
