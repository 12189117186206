import React, {useRef} from 'react';
import styled from "styled-components";
import UserFindComponent from '../../components/HomeComponents/UserFindComponent';
import Draggable from "../../components/Templates/Draggable";
import {useUsersState} from "../../store/users/hooks";

const ScrollWrapper = styled(Draggable)`
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 20px;
  &::-webkit-scrollbar {
    display: none;
  }
`

const UsersList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;

  ${({theme}) => theme.mediaQueries.md} {
    padding: 0 10px;
  }
`

function UsersView() {
  const wrapperRef = useRef<HTMLDivElement>(null)

  const {users} = useUsersState()

  return (
    <ScrollWrapper ourRef={wrapperRef}>
      <UsersList>
        {users.map((user) => <UserFindComponent key={user.id} {...user}/>)}
      </UsersList>
    </ScrollWrapper>
  );
}

export default UsersView;
