import React, {FormEvent, useCallback, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import {Container} from "../../../components/Templates/Container";
import {useProfileAction, useProfileState} from "../../../store/profile/hooks";
import {Card} from "../../../components/Templates/Card";
import Text from "../../../UI/Text";
import {ETextVariants} from "../../../UI/Text/types";
import {Link, Route, Routes, useLocation} from "react-router-dom";
import {useAuthAction} from "../../../store/auth/hooks";
import GeneralForm from "./GeneralForm";
import {IInterest, ProfileFormData, ProfileSettingsPayload} from '../../../store/profile/types';
import {useForm} from "react-hook-form";
import CreateEventModal from "../../../components/Modals/CreateEventModal";
import InterestsPage from "./InterestsPage";
import {Button, EButtonVariants} from "../../../UI/Button";
import _ from "lodash";
import ModalSelectFirm from "../../../components/Modals/ModalSelectFirm";
import {AcquaintancePurpose, AcquaintanceShowMyselfToGender} from "../../../types";
import {useCompanyAction} from "../../../store/company/hooks";
import {T, useTranslate} from "@tolgee/react";
import {handleImageUpload} from "../../../utils";

const ContainerStyled = styled(Container)`
  display: flex;
  justify-content: flex-start;
  gap: 20px;
`

const Aside = styled.aside`
  width: 100%;
  max-width: 343px;

  ${({theme}) => theme.mediaQueries.md} {
    display: none;
  }
`

const AsideCard = styled(Card)`
  padding: 10px 0;
  display: flex;
  flex-direction: column;
`

const Item = styled.div<{ $isActive: boolean }>`
  padding: 17px 20px;
  width: 100%;
  background: ${({theme, $isActive}) => $isActive ? `rgba(108, 39, 171, 0.10)` : theme.colors.white};
  cursor: pointer;
`

const Main = styled.div`
  width: 100%;
  max-width: 777px;

  ${({theme}) => theme.mediaQueries.md} {
    //min-height: calc(100vh - 81px - 44px);
  }
`

const CardForm = styled(Card)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 17px;
  height: 100%;
  flex-direction: column;
  overflow: visible;
  min-height: calc(100vh - 120px);
`


const ButtonLogout = styled(Button)`
  display: none;
  border: none;
  margin: 0 auto;

  ${({theme}) => theme.mediaQueries.md} {
    display: flex;
  }
`

function Index() {
  const {t} = useTranslate()
  const {onFetchProfile, onUpdateProfile, onFetchInterests, onClearError} = useProfileAction()
  const {pathname} = useLocation()
  const {onFetchUserCompanies} = useCompanyAction()
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [isShowFirmModal, setIsShowFirmModal] = useState<boolean>(false);
  const [locale, setLocale] = useState('');

  useEffect(() => {
    onFetchUserCompanies()
  }, [onFetchUserCompanies])

  useEffect(() => {
    return () => onClearError()
  }, [onClearError])

  const {profile, isUpdateLoading} = useProfileState()

  const path = pathname.split('/').slice(1)
  const {onLogout} = useAuthAction()
  useEffect(() => {
    onFetchProfile()
    onFetchInterests()
  }, [onFetchInterests, onFetchProfile])

  const {control, register, handleSubmit, setValue, formState: {errors}} = useForm<ProfileFormData>({
    defaultValues: {
      firstName: profile?.firstName ?? '',
      lastName: profile?.lastName ?? '',
      email: profile?.email ?? '',
      sex: profile?.sex ?? 'M',
      city: profile?.city ?? '',
      age: profile?.age ?? 0,
      description: profile?.description ?? '',
    },
  });
  const [avatar, setAvatar] = useState<File | null>(null);


  useEffect(() => {
    if (profile) {
      setValue('description', profile?.description ?? '');
      setValue('firstName', profile?.firstName ?? '');
      setValue('lastName', profile?.lastName ?? '');
      setValue('email', profile?.email ?? '');
      setValue('sex', profile?.sex ?? 'M');
      setValue('city', profile?.city ?? '');
      setValue('age', profile?.age ?? 0);
      setLocale(profile.locale || '')
    }
  }, [profile, setValue]);


  const submitHandler = handleSubmit(async (data) => {
    onClearError()
    const payload: ProfileSettingsPayload = {
      ...data,
      locale: locale.split('-')[0]
    };
    const compressedAvatar = await handleImageUpload(avatar)
    if (compressedAvatar) {
      payload.avatar =  compressedAvatar;
    }
    onUpdateProfile(payload)
    setIsShowModal(true);
  });


  return (
    <>
      <ContainerStyled>
        <Main>
          <CardForm>
            <Routes>
              <Route
                index
                element={<GeneralForm
                  register={register}
                  locale={locale}
                  setLocale={setLocale}
                  initialAvatar={profile?.avatar || null}
                  isLoading={isUpdateLoading}
                  control={control}
                  onSubmit={submitHandler}
                  onChangeAvatar={setAvatar}
                  errors={errors}
                />}
              />
            </Routes>
            <Button variant={EButtonVariants.Secondary} onClick={() => setIsShowFirmModal(true)} $fullWidth>
              <T keyName="profileSettings.buttons.switchToCompany">Переключиться на фирму</T>
            </Button>
            <Button variant={EButtonVariants.Primary} as={Link} $fullWidth to={'/profile/firm-create'}>
              <T keyName="profileSettings.buttons.registerFirm">+ Регистрация фирмы</T>
            </Button>
            <ButtonLogout variant={EButtonVariants.Secondary} onClick={onLogout}>
              <T keyName="common.exit">Выйти</T>
            </ButtonLogout>
          </CardForm>
        </Main>
        <Aside>
          <AsideCard>
            <Item as={Link} to={'/profile/settings'} $isActive={path[1] === 'settings' && path.length === 2}>
              <Text variant={ETextVariants.Body16}>
                <T keyName="profileSettings.aside.base">Основные</T>
              </Text>
            </Item>
            {profile?.role === 'admin' && <Item as={Link} to={'/admin'} $isActive={false}>
                <Text variant={ETextVariants.Body16}>
                    <T keyName="profileSettings.aside.admin">Перейти в панель администратора</T>
                </Text>
            </Item>}
            <Item $isActive={false} onClick={onLogout}>
              <Text variant={ETextVariants.Body16}>
                <T keyName="common.exit">Выйти</T>
              </Text>
            </Item>
          </AsideCard>
        </Aside>
      </ContainerStyled>

      {isShowModal && (
        <CreateEventModal
          isModalClose={() => setIsShowModal(false)}
          title={t("profileSettings.createModal.title","Данные сохранены")}
          body={t("profileSettings.createModal.body","Ваши данные находятся на проверке. После согласования мы направим вам уведомление.")}
        />
      )}
      {isShowFirmModal && <ModalSelectFirm onClose={() => setIsShowFirmModal(false)}/>}
    </>
  );
}

export default Index;
