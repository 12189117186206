import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="25" height="16" viewBox="0 0 25 16" fill="none" {...props} >
          <rect x="0.25" y="0.25" width="24.5" height="15.5" rx="4.75" fill="white"/>
          <rect x="0.25" y="0.25" width="24.5" height="15.5" rx="4.75" stroke="#EAEAEA" strokeWidth="0.5"/>
          <path d="M13.9175 5.48693H11.0826V10.5404H13.9175V5.48693Z" fill="#FF5F00"/>
          <path d="M11.2626 8.01366C11.2622 7.52698 11.3734 7.04657 11.5878 6.60881C11.8021 6.17104 12.1141 5.7874 12.5001 5.48693C12.0221 5.11427 11.4481 4.88252 10.8437 4.81816C10.2392 4.75381 9.62872 4.85944 9.08194 5.123C8.53515 5.38656 8.07415 5.79741 7.75162 6.30858C7.42909 6.81975 7.25806 7.41062 7.25806 8.01366C7.25806 8.6167 7.42909 9.20757 7.75162 9.71874C8.07415 10.2299 8.53515 10.6408 9.08194 10.9043C9.62872 11.1679 10.2392 11.2735 10.8437 11.2092C11.4481 11.1448 12.0221 10.9131 12.5001 10.5404C12.1141 10.2399 11.8021 9.85627 11.5878 9.41851C11.3734 8.98075 11.2622 8.50034 11.2626 8.01366Z" fill="#EB001B"/>
          <path d="M17.7419 8.01366C17.742 8.61669 17.5709 9.20756 17.2484 9.71873C16.9259 10.2299 16.4649 10.6407 15.9182 10.9043C15.3714 11.1679 14.7609 11.2735 14.1564 11.2092C13.552 11.1448 12.978 10.9131 12.5001 10.5404C12.8857 10.2396 13.1974 9.85591 13.4118 9.41822C13.6261 8.98053 13.7375 8.50027 13.7375 8.01366C13.7375 7.52705 13.6261 7.04679 13.4118 6.6091C13.1974 6.17141 12.8857 5.78771 12.5001 5.48693C12.978 5.11427 13.552 4.88251 14.1564 4.81816C14.7609 4.7538 15.3714 4.85945 15.9182 5.12301C16.4649 5.38657 16.9259 5.79742 17.2484 6.30859C17.5709 6.81976 17.742 7.41063 17.7419 8.01366Z" fill="#F79E1B"/>
          <path d="M17.4328 10.0052V9.90169H17.4749V9.88061H17.3678V9.90169H17.4099V10.0052H17.4328ZM17.6408 10.0052V9.88041H17.6079L17.5702 9.96621L17.5324 9.88041H17.4996V10.0052H17.5227V9.91106L17.5581 9.99218H17.5822L17.6176 9.91085V10.0052H17.6408Z" fill="#F79E1B"/>
        </Svg>
    );

export default Icon;
