import React, {useCallback, useMemo} from 'react';
import {ProfileEntity} from "../../store/profile/types";
import {IChat} from "../../store/chat/types";
import styled from "styled-components";
import imgEmptyAvatar from '../../assets/img/no-avatar.png';
import {mediaUrl} from "../../api";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {useNavigate} from "react-router-dom";
import {getPictureChat} from "../../views/ChatViews/HeaderDialogView";
import {useAuthState} from "../../store/auth/hooks";
import {getTitleChat} from "../../views/ChatViews/Contacts";

const User = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  text-align: center;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 36px 20px;
  gap: 16px;
  &:hover {
    text-decoration: underline;
  }
  ${({theme}) => theme.mediaQueries.md} {
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    background: ${({theme}) => theme.colors.white};
    padding: 12px 16px;
  }
`

const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 141px;
  height: 141px;
  border-radius: 50%;
  overflow: hidden;
  background: #9b9b9b;
  img {
    width: auto;
    height: 100%;
  }
  ${({theme}) => theme.mediaQueries.md} {
    width: 114px;
    height: 114px;
  }
  ${({theme}) => theme.mediaQueries.sm} {
    width: 37px; 
    height: 37px; 
  }
`

function UserItem({profile, onClick, chat}:{profile?:ProfileEntity,onClick: any, chat?: IChat}) {
  const navigate = useNavigate()
  const {user} = useAuthState()
  const onClickHandler = useCallback(() => {
    if (onClick) {
      onClick()
    } else if (profile) {
      navigate(`/profile/${profile.id}`)
    }
  }, [navigate, onClick, profile])
  const pictureUrl = useMemo(() => chat ? getPictureChat(chat, user) : null, [chat, user])
  const titleChat = useMemo(() => chat ? getTitleChat(chat, user) : null, [chat, user])
  if (!profile && !chat) return null
  if (profile) {
    return (
      <User onClick={onClickHandler}>
        <Avatar>
          <img src={profile.avatar ? `${mediaUrl}file/${profile.avatar.id}` : imgEmptyAvatar} alt=""/>
        </Avatar>
        <Text variant={ETextVariants.Body16}>
          {profile?.firstName ? `${profile.firstName} ${profile.lastName}` : profile?.login}
        </Text>
      </User>
    );
  }
  if (chat) {
    return (
      <User onClick={onClickHandler}>
        <Avatar>
          <img src={pictureUrl ? `${mediaUrl}file/${pictureUrl}` : imgEmptyAvatar} alt=""/>
        </Avatar>
        <Text variant={ETextVariants.Body16}>
          {titleChat}
        </Text>
      </User>
    );
  }
  return null
}

export default UserItem;
