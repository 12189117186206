import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled from "styled-components";
import 'react-datepicker/dist/react-datepicker.css';
import {T, useTranslate} from "@tolgee/react";
import {useServicesAction, useServicesState} from "../../store/services/hooks";
import {useForm, useWatch} from "react-hook-form";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import Select from "../../UI/Select";
import {Button, EButtonVariants} from "../../UI/Button";
import { DataPickerRange } from '../../UI/DataPickerRange';
import {useGeoState} from "../../store/geo/hooks";

const StyledForm = styled.form<{isShow?: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  ${({theme}) => theme.mediaQueries.md} {
    display: ${({isShow}) => isShow ? 'flex' : 'none'};

    padding: 16px;
    background: ${({theme}) => theme.colors.white};
  }
`

const InputRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  position: relative;
  width: 100%;
`


const InputRange = styled.input(({ theme }) => {
  return {
    borderRadius: '1rem',
    WebkitAppearance: 'none',
    '&::-webkit-slider-thumb': {
      appearance: 'none',
      backgroundColor: theme.colors.mainPurple,
      cursor: 'pointer',
      height: ' 1.5rem',
      width: '1.5rem',
      borderRadius: '100%',
      marginTop: -5,
    },
    '&::-webkit-slider-runnable-track': {
      background: theme.colors.bg,
      height: 5,
      width: '100%',
      borderRadius: 10,
      marginTop: 10,
    },
  };
});

const ButtonStyled = styled(Button)`
  ${({theme}) => theme.mediaQueries.md} {
    display: none;
  }
`

function ServiceFilter({refOnReset}: {refOnReset?: any}) {
  const { t } = useTranslate();
  const {onChangeFilter, onResetFilter} = useServicesAction()
  const {filter} = useServicesState()
  const {cities,countries } = useGeoState()
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const [startDate, endDate] = useMemo(() => dateRange, [dateRange]);

  const range = useMemo(() => {
    // onChangeFilter({latitude: positionCenter[0], longitude: positionCenter[1]})
    return filter?.radius || 0
  }, [filter?.radius])

  const { register, handleSubmit, reset, control, setValue } = useForm();

  const onChange = useCallback((key: string, value: any) => {
    onChangeFilter({[key]: value})
  }, [onChangeFilter])

  const country = useWatch({control, name: 'country', defaultValue: ''})

  useEffect(() => {
    if (startDate && endDate) {
      onChangeFilter({
        dateFrom: startDate.toString(),
        dateTo: endDate.toString()
      })
    } else if (startDate) {
      onChangeFilter({
        dateFrom: startDate.toString(),
      })
    } if (endDate) {
      onChangeFilter({
        dateTo: endDate.toString()
      })
    }
  }, [startDate, endDate, onChangeFilter])

  useEffect(() => {
    if (country === '') {
      setValue('city', '')
      onChange('city', '')
    }
  }, [country, onChange, setValue])

  const handleResetFilter = useCallback(() => {
    reset()
    onResetFilter()
    setDateRange([null,null])
  }, [onResetFilter, reset])

  useEffect(() => {
    if (refOnReset) {
      refOnReset.current = handleResetFilter
    }
  }, [handleResetFilter])

  const rangeLabel = useMemo(() => `Расстояние:  ${Number(range) / 1000} км`, [range])

  return (
    <>

      <StyledForm isShow={refOnReset}  onChange={handleSubmit(onChangeFilter)}>
        <InputRow>
          <Text variant={ETextVariants.Body16}>
            {t('filter.location', 'Место проведения')}
          </Text>
          <Select {...register('country')}>
            <option value="">
              <T keyName={'filter.allCountries'}>Все страны</T>
            </option>
            {countries.map((item, i) => (
              <option key={i}>{`${item}`}</option>
            ))}
          </Select>
        </InputRow>
        <InputRow>
          <Text variant={ETextVariants.Body16}>
            {t('filter.location', 'Место проведения')}
          </Text>
          <Select {...register('city')}>
            <option value="">
              <T keyName="filter.wholeCountry">Вся страна</T>
            </option>
            {cities?.map((item, i) => (
              <option key={i}>{`${item}`}</option>
            ))}
          </Select>
        </InputRow>
        <InputRow>
          <Text variant={ETextVariants.Body16}>
            {t('filter.date', 'Дата проведения')}
          </Text>
          <DataPickerRange
            setDateRange={setDateRange}
            startDate={startDate}
            endDate={endDate}
            bg={false}
            isClearButton={false}
            typeArrowPurple={false}
            mobileVersion={false}
          />
        </InputRow>
        <InputRow>
          <Text variant={ETextVariants.Body16}>
            <T keyName="filter.range" params={{ distance: Number(range) / 1000 }}>
              {rangeLabel}
            </T>
          </Text>
          <InputRange
            type="range"
            value={range}
            min={Number(0)}
            max={90000}
            {...register('radius')}
            // onChange={(e: any) => onChange('radius',e.target.value)}
            step="200"
          />
        </InputRow>
      </StyledForm>

      <ButtonStyled style={{marginTop: 10}} $fullWidth variant={EButtonVariants.Secondary} onClick={handleResetFilter}>
        {t('common.reset', 'Сбросить')}
      </ButtonStyled>
    </>
  );
}

export default ServiceFilter;
