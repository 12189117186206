import React, {useEffect} from 'react';
import styled from 'styled-components';
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {T} from "@tolgee/react";

export interface IModal {
  isModalClose: (data: string) => void;
  title: string;
  body: string;
  children?: any
}

const WrapModalSuccess = styled.div(({theme}) => {
  return {
    position: 'fixed',
    width: '100%',
    height: '100%',
    zIndex: '10',
    left: 0,
    top: 0,
    background: theme.colors.transparentGray,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
});

const WinModalSuccess = styled.div(({theme}) => {
  return {
    maxWidth: 270,
    top: 0,
    right: 0,
    overflowY: 'hidden',
    overflowX: 'hidden',
    background: theme.colors.white,
    zIndex: 2,
    borderRadius: 15,
    paddingTop: 20,
  };
});

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
`

const Title = styled(Text)`
  font-size: 17px;
  font-weight: bold;
`

const Content = styled.div`
  width: 100%;
  padding: 0 16px;
`
const ContentText = styled(Text)`
  font-size: 13px;
  display: inline-block;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
  padding-bottom: 17px;
  letter-spacing: -0.078px;
  width: 100%;
`

const ConfirmBlock = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.35);
  padding: 11px 0;
  display: flex;
  justify-content: center;
`

const ConfirmText = styled(Text)`
  color: ${({theme}) => theme.colors.mainPurple};
  font-size: 17px;
  text-align: center;
  display: inline-block;
  cursor: pointer;
`

function CreateEventModal({isModalClose, body, title}: IModal) {
  const bodyMain = document.querySelector('body');

  useEffect(() => {
    if (bodyMain) bodyMain.style.overflow = 'hidden';

    return () => {
      if (bodyMain) bodyMain.style.overflow = 'auto';
    };
  }, [bodyMain]);
  return (
    <WrapModalSuccess onClick={(e: any) => isModalClose(e)}>
      <WinModalSuccess>
        <Header>
          <Title variant={ETextVariants.Heading} as={'h2'}>{title}</Title>
        </Header>
        <Content>
          <ContentText variant={ETextVariants.Body16} as={'p'}>
            {body}
          </ContentText>
        </Content>
        <ConfirmBlock>
          <ConfirmText variant={ETextVariants.Body16} onClick={(e: any) => isModalClose(e)}>
            <T keyName={'common.confirm'}>Хорошо</T>
          </ConfirmText>
        </ConfirmBlock>
      </WinModalSuccess>
    </WrapModalSuccess>
  );
}

export default CreateEventModal;
