import React, { forwardRef, ReactNode } from 'react';
import styled from 'styled-components';
import pngChecked from "../../assets/img/checked.svg";
import Text from "../Text";
import {ETextVariants} from "../Text/types";

type InputProps = JSX.IntrinsicElements['input'];

interface CheckboxProps extends InputProps {
  label?: ReactNode;
}

const StyledCheckbox = styled.input(() => {
  return {
    position: 'absolute',
    zIndex: -1,
    opacity: 0,
    '&:checked + label:before': {
      background: `url(${pngChecked}) center/cover no-repeat`,
    },
  };
});

const Label = styled(Text)`
  display: inline-flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  user-select: none;
  cursor: pointer;
  &:before {
    content: '';
    display: inline-block;
    width: 24px;
    height: 24px;
    min-width: 24px;
    margin-right: 10px;
    border-radius: 50%;
    border: 1px solid ${({theme}) => theme.colors.lightGray};
  }
`

const Wrapper = styled.div`
  display: flex;
  
`

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(({ label, name, ...rest }, ref) => {
  return (
    <Wrapper >
      <StyledCheckbox {...rest} ref={ref} type="checkbox" name={name} />
      <Label as={'label'} variant={ETextVariants.Body16} htmlFor={rest.id}>
        {label}
      </Label>
    </Wrapper>
  );
});
