import React from 'react';
import AdminHeader from "../../../components/AdminComponents/AdminHeader";
import FirmFormView from "../../../views/ProfileViews/FirmFormView";
import {useCompanyAction, useCompanyState} from "../../../store/company/hooks";
import styled from "styled-components";
import {useAdminCompaniesAction, useAdminCompaniesState} from "../../../store/adminCompanies/hooks";


const FormWrapper = styled.div`
  width: 100%;
  margin-top: 40px;
  max-width: 1070px;
`

function AddFirmPage() {

  const {onCreateCompanyAdmin} = useAdminCompaniesAction()
  const {isPending} = useAdminCompaniesState()
  return (
    <>
      <AdminHeader title={'Добавление фирмы'}/>
      <FormWrapper>
        <FirmFormView onSubmitHandler={onCreateCompanyAdmin} isLoading={isPending} initialAvatar={null}
                      isCreateAdmin={true}
                      initialHeader={null}/>
      </FormWrapper>
    </>
  );
}

export default AddFirmPage;
