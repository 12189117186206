import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled from "styled-components";
import EventFilter from "../../views/HomeViews/EventFilter";
import {ArrowLeftIcon, FilterIcon, GeoIcon, HeartsIcon, SearchIcon} from "../../UI/Svg";
import Input from "../../UI/Input";
import {Route, Routes, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {T, useTranslate} from "@tolgee/react";
import {useEvents, useEventsAction, useEventsState} from "../../store/events/hooks";
import {debounce} from "lodash";
import HomeLoading from '../../components/HomeComponents/HomeLoading';
import HomeEvents from "./HomeEvents";
import HomeMap from "./HomeMap";
import {useAppAction, useAppState} from "../../store/app/hooks";
import FilterModal from "../../components/Modals/FilterModal";
import {ContainerMax} from "../../components/Templates/ContainerMax";
import {Card} from "../../components/Templates/Card";
import Tabs from "../../UI/Tabs";
import ServiceFilter from "../../views/HomeViews/ServiceFilter";
import HomeServices from "./HomeServices";
import {useServices, useServicesAction, useServicesState} from "../../store/services/hooks";
import ServiceFilterModal from "../../components/Modals/ServiceFilterModal";
import {ETextVariants} from "../../UI/Text/types";
import Text from "../../UI/Text";
import {useAcquaintanceState} from "../../store/acquaintance/hooks";
import useGeolocation from "../../hooks/useGeolocation";
import {useUsers, useUsersAction} from "../../store/users/hooks";
import UsersView from "../../views/HomeViews/UsersView";

const Wrapper = styled(ContainerMax)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  height: calc(100vh - 110px);
  overflow: hidden;

  ${({theme}) => theme.mediaQueries.md} {
    flex-direction: column;
    gap: 0;
  }
`
const InviteWrapper = styled(ContainerMax)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  margin-bottom: 20px;

  ${({theme}) => theme.mediaQueries.md} {
    display: none;
  }
`

const Main = styled.div`
  display: flex;
  flex-direction: column;
  //gap: 20px;
  width: 100%;
  flex: 1;
  height: 100%;
  overflow: hidden;
`

const Settings = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  gap: 16px;

  flex: 0 1;

  ${({theme}) => theme.mediaQueries.md} {
    background: ${({theme}) => theme.colors.white};
  }
`

const Search = styled.div<{ isShowSearch: boolean }>`
  display: flex;
  color: ${({theme}) => theme.colors.white};
  width: 100%;

  ${({theme}) => theme.mediaQueries.md} {
    display: ${({isShowSearch}) => isShowSearch ? 'flex' : 'none'};
    padding: 10px 16px 16px;
    flex: 1;
  }
`

const InputStyled = styled(Input)`
  background: ${({theme}) => theme.colors.white};
  border-color: ${({theme}) => theme.colors.white};
  height: 56px;

  ${({theme}) => theme.mediaQueries.md} {
    background: ${({theme}) => theme.colors.bg};
    height: 40px;
  }
`

const ButtonIcon = styled.button`
  min-width: 56px;
  width: 56px;
  height: 56px;
  border-radius: 10px;
  border: none;
  background: ${({theme}) => theme.colors.white};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;

  &:active {
    opacity: .5;
  }

  svg {
    width: 24px;
    height: 24px;
  }

  ${({theme}) => theme.mediaQueries.md} {

    position: absolute;
    left: 14px;
    top: 8px;
    min-width: 32px;
    height: 32px;
  }
`

const ButtonHeart = styled(ButtonIcon)`
  display: none;
  left: unset;
  right: 16px;

  ${({theme}) => theme.mediaQueries.md} {
    display: flex;
    right: unset;
    left: calc(16px + 12px + 24px);
    background: none;
  }
`

const ButtonFilter = styled(ButtonIcon)`
  display: none;
  left: unset;
  right: 16px;

  ${({theme}) => theme.mediaQueries.md} {
    display: flex;
  }
`

const ButtonSearch = styled(ButtonIcon)`
  display: none;
  left: unset;
  right: calc(16px + 12px + 24px);

  svg path {
    stroke: ${({theme}) => theme.colors.mainPurple};
  }

  ${({theme}) => theme.mediaQueries.md} {
    display: flex;
    background: none;
  }
`

const CardStyled = styled(Card)`
  width: 100%;
  max-width: 343px;
  padding: 16px;
  gap: 30px;
  display: flex;
  flex-direction: column;

  ${({theme}) => theme.mediaQueries.md} {
    max-width: unset;
    margin: 0 auto;
    padding-top: 8px;
    overflow: unset;
    width: 100%;
  }
`

const Title = styled(Text)`
  font-size: 22px;

`

const HomeMapStyled = styled(HomeMap)`
  margin-top: 20px;
  flex: 1;
`

const InviteBack = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  ${({theme}) => theme.mediaQueries.md} {
    position: absolute;
    left: 54px;
    top: 8px;
    width: 32px;
    height: 32px;
  }
`

export const positionCenter: [number, number] = [55.751244, 37.618423]

function Index({isInvite}: { isInvite?: boolean }) {
  const {suggestion} = useAcquaintanceState()
  useEvents()
  useServices()
  useUsers()
  const {geoLocation} = useGeolocation()
  const navigate = useNavigate();
  useEffect(() => {
    if (!suggestion && isInvite) {
      navigate('/events')
    }
  }, [isInvite, navigate, suggestion])
  const {t} = useTranslate();
  const {onChangeFilter} = useEventsAction()
  const {onChangeFilter: onChangeServiceFilter} = useServicesAction()
  const {onChangeSearch} = useUsersAction()
  const {isLoading} = useEventsState()
  const {onSetTitle, onToggleSearch} = useAppAction()
  const {isShowSearch} = useAppState()
  useEffect(() => {
    if (isInvite) {
      onSetTitle(t('common.invite', 'Пригласить'))
    } else {
      onSetTitle(t('common.home', 'Главная'))
    }
  }, [isInvite, onSetTitle, t])

  const [selectTab, setSelectTab] = useState<string>('services');
  const [search, setSearch] = useState<string>('');
  const [isInputFocus, setIsInputFocus] = useState<boolean>(false);
  const [isShowFilter, setIsShowFilter] = useState(false);
  const [isMap, setIsMap] = useState<boolean>(false);


  const {filter: filterService} = useServicesState()
  const {filter} = useEventsState()

  const [position, setPosition] = useState<[number, number]>(positionCenter);

  useEffect(() => {
    setPosition([geoLocation[0] || positionCenter[0], geoLocation[1] || positionCenter[1]])
    onChangeFilter({
      latitude: geoLocation[0] || positionCenter[0],
      longitude: geoLocation[1] || positionCenter[1],
    })
    onChangeServiceFilter({
      latitude: geoLocation[0] || positionCenter[0],
      longitude: geoLocation[1] || positionCenter[1],
    })
  }, [onChangeFilter, onChangeServiceFilter, geoLocation])
  useEffect(() => {
    if (selectTab === 'events') {
      onChangeFilter({
        latitude: position[0],
        longitude: position[1],
      })
    } else {
      onChangeServiceFilter({
        latitude: position[0],
        longitude: position[1],
      })
    }
  }, [onChangeFilter, onChangeServiceFilter, position, selectTab])

  useEffect(() => {
    if (filter.search) {
      isTextSearch('')
    }
    if (filterService.search) {
      isTextSearchService('')
    }
  }, [])

  const isTextSearch = debounce((search) => {
    onChangeFilter({search});
  }, 100);
  const isTextSearchService = debounce((search) => {
    onChangeServiceFilter({search});
  }, 100);

  const {pathname} = useLocation()

  const isUsersFind = useMemo(() => search.startsWith('@', 0), [search])

  useEffect(() => {
    if (isUsersFind) {
      if (pathname !== '/events') {
        navigate('/events')
      }
    }
  }, [isUsersFind, navigate, pathname])

  useEffect(() => {
    if (isInputFocus) {
      if (search.length > 0) {
        if (search.startsWith('@', 0)) {
          onChangeSearch(search.slice(1))
        } else {
          onChangeSearch('')
          if (selectTab === 'events') {
            isTextSearch(search);
          } else {
            isTextSearchService(search);
          }
        }
      }
    }
  }, [selectTab, isInputFocus, search]);

  const location = useLocation().pathname.split('/');

  const goToMap = useCallback(() => {
    if (location[2]?.length) {
      navigate('/events');
      setIsMap(false);
    } else {
      navigate('/events/map');
      setIsMap((type) => !type);
    }
  }, [location, navigate]);

  useEffect(() => {
    if (isMap) {
      onChangeFilter({
        limit: 100,
        page: 1,
      });
    } else {
      onChangeFilter({
        limit: 20,
        page: 1,
      });
    }
  }, [isMap, onChangeFilter])

  const [searchParams] = useSearchParams()

  useEffect(() => {
    const isEvent = searchParams.get('is-event')
    const eventId = searchParams.get('id')
    if (isEvent && eventId) {
      setSelectTab(isEvent === 'true' ? 'events' : 'services')
    }
  }, [searchParams])

  return (
    <>
      {isInvite && <InviteWrapper>
          <InviteBack onClick={() => navigate(-1)}>
              <ArrowLeftIcon/>
          </InviteBack>
          <Title variant={ETextVariants.Heading} as={'h2'}>
              <T keyName={"common.invite"}>Пригласить</T>
          </Title>
      </InviteWrapper>}
      <Wrapper>
        <CardStyled>
          <Tabs
            value={selectTab}
            onSelect={setSelectTab}
            tabs={[
              {name: t('common.events', 'Ивенты'), value: 'events'},
              {name: t('common.services', 'Услуги'), value: 'services'},
            ]}/>

          {selectTab === 'events' ? <EventFilter/> : <ServiceFilter/>}
        </CardStyled>
        {isShowFilter ? (selectTab === 'events' ? <FilterModal onClose={() => setIsShowFilter(false)}/> :
          <ServiceFilterModal onClose={() => setIsShowFilter(false)}/>) : null}
        <Main>
          <Settings>
            <Search isShowSearch={isShowSearch}>
              <InputStyled
                placeholder={t('common.search', 'Поиск')}
                onClick={() => setIsInputFocus(true)}
                onBlur={() => setIsInputFocus(false)}
                onChange={(e) => setSearch(e.target.value)}
                wth="100%"
                value={`${search}`}
                icon={
                  <SearchIcon/>
                }
              />
            </Search>

            {isInvite && <InviteBack onClick={() => navigate(-1)}>
                <ArrowLeftIcon/>
            </InviteBack>}
            <ButtonIcon onClick={goToMap}>
              <GeoIcon/>
            </ButtonIcon>
            <ButtonHeart onClick={() => navigate('/acquaintance')}>
              <HeartsIcon/>
            </ButtonHeart>
            <ButtonFilter onClick={() => setIsShowFilter(prevState => !prevState)}>
              <FilterIcon/>
            </ButtonFilter>
            <ButtonSearch onClick={onToggleSearch}>
              <SearchIcon/>
            </ButtonSearch>
          </Settings>
          <Routes>
            <Route index
                   element={
                     isUsersFind ?
                       <UsersView/>
                       : isLoading ? <HomeLoading/> : selectTab === 'events' ? <HomeEvents/> : <HomeServices/>
                   }/>
            <Route path={'*'} element={<HomeMapStyled position={position} setPosition={setPosition}
                                                      isService={selectTab === 'services'}
                                                      isCenter={geoLocation[0] !== null && geoLocation[1] !== null}
                                                      center={position}/>}/>
          </Routes>
        </Main>
      </Wrapper>
    </>
  );
}

export default Index;
