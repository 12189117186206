import {useDispatch, useSelector} from "react-redux";
import {useCallback, useEffect, useMemo} from "react";
import {AppDispatch, RootState} from "../index";
import {IAdminPaymentsFilter} from "./types";
import fetchAllPayments from "./fetchAllPayments";
import {
  changeFilter,
  changePage,
  clearSelectCompany,
  clearSelectService,
  selectCompany,
  selectService
} from "./adminPaymentsSlice";
import fetchCompanyPayments from "./fetchCompanyPayments";
import {IUserCompany} from "../company/types";
import {ServiceResponse} from "../adminServices/types";
import fetchServicePayments from "./fetchServicePayments";

export const getAdminPayments = (state: RootState) => state.adminPayments

export function useAdminPaymentsAction() {
  const dispatch = useDispatch<AppDispatch>()
  const onChangeFilter = useCallback((data: IAdminPaymentsFilter) => {
    dispatch(changeFilter(data))
  }, [dispatch])
  const onFetchAllPayments = useCallback(() => {
    dispatch(fetchAllPayments())
  }, [dispatch])
  const onFetchCompanyPayments = useCallback((id: number) => {
    dispatch(fetchCompanyPayments(id))
  }, [dispatch])
  const onFetchServicePayments = useCallback((id: number) => {
    dispatch(fetchServicePayments(id))
  }, [dispatch])
  const onSelectCompany = useCallback((data: IUserCompany) => {
    dispatch(selectCompany(data))
  }, [dispatch])
  const onClearSelectCompany = useCallback(() => {
    dispatch(clearSelectCompany())
  }, [dispatch])
  const onSelectService = useCallback((data: ServiceResponse) => {
    dispatch(selectService(data))
  }, [dispatch])
  const onClearSelectService = useCallback(() => {
    dispatch(clearSelectService())
  }, [dispatch])
  const onChangePage = useCallback((page: number) => {
    dispatch(changePage(page))
  }, [dispatch])
  return useMemo(() => ({
    onChangeFilter,
    onFetchAllPayments,
    onChangePage,
    onFetchCompanyPayments,
    onSelectCompany,
    onClearSelectCompany,
    onSelectService,
    onClearSelectService,
    onFetchServicePayments,
  }), [onChangeFilter, onFetchAllPayments, onChangePage, onFetchCompanyPayments, onSelectCompany, onClearSelectCompany, onSelectService, onClearSelectService, onFetchServicePayments])
}

export function useAdminPaymentsState() {
  return useSelector(getAdminPayments)
}

export function useAdminPayments() {
  const {filter} = useAdminPaymentsState()
  const {onFetchAllPayments} = useAdminPaymentsAction()

  useEffect(() => {
    onFetchAllPayments()
  }, [filter, onFetchAllPayments])
}

export function useAdminCompanyPayments(id?: number) {
  const {filter} = useAdminPaymentsState()
  const {onFetchCompanyPayments} = useAdminPaymentsAction()

  useEffect(() => {
    if (id !== undefined) {
      onFetchCompanyPayments(id)
    }
  }, [filter, id, onFetchCompanyPayments])
}

export function useAdminServicePayments(id?: number) {
  const {filter} = useAdminPaymentsState()
  const {onFetchServicePayments} = useAdminPaymentsAction()

  useEffect(() => {
    if (id !== undefined) {
      onFetchServicePayments(id)
    }
  }, [filter, id, onFetchServicePayments])
}
