import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {CompanyApi} from "../../api/company";
import {IUserCompany, UpdateCompanyPayload} from "./types";

const fetchUpdateCompany = createAsyncThunk<IUserCompany,
  UpdateCompanyPayload,
  {
    state: RootState
  }>(
  'company/fetchUpdateCompany',
  async (data, ThunkApi) => {
    const authState = ThunkApi.getState().auth
    let avatar = null
    let header = null
    if (authState.auth) {
      const [companyRes, companyErr] = await handle(CompanyApi.putUpdate(data))
      if (companyRes) {

        if (data.avatar) {
          const [avatarRes, avatarErr] = await handle(CompanyApi.putSetAvatar(data.avatar, companyRes.id))
          if (avatarErr) console.log(avatarErr)
          if (avatarRes) avatar = avatarRes
        }
        if (data.header) {
          const [headerRes, headerErr] = await handle(CompanyApi.putSetHeader(data.header, companyRes.id))
          if (headerErr) console.log(headerErr)
          if (headerRes) header = headerRes
        }
        if (header) return header
        if (avatar) return avatar
        return companyRes
      }
      if (companyErr) {
        console.log('companyErr',companyRes, companyErr)
        throw {message: companyErr.message[0] || companyErr.message}
      }
    }
    throw {message: 'No phone number'}
  }
)

export default fetchUpdateCompany
