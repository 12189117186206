import Stripe from "stripe";
import {useCallback, useEffect, useMemo, useState} from "react";
import {useCartAction} from "../store/cart/hooks";
import {EPaymentMethod} from "../store/cart/types";
import {STRIPE_SK_KEY} from "../config/stripe";

const stripe = new Stripe(STRIPE_SK_KEY)

export function useStripePaymentIntent() {
  const [paymentIntent, setPaymentIntent] = useState<Stripe.PaymentIntent | null>(null);
  const {onFetchCreatePayment, onSetCheckItem, onChangeDataOnePayment, onChangePhone} = useCartAction()
  const getPaymentIntent = useCallback((paymentIntentId: string) => {
    stripe.paymentIntents.retrieve(paymentIntentId)
      .then((res) => {
        setPaymentIntent(res)
      })
  }, [])

  useEffect(() => {
    if (paymentIntent) {
      const {metadata} = paymentIntent
      const items = metadata.items ? metadata.items.split(',').map(parseInt) : []
      const phone = metadata.phone || ''
      const isConfirm = metadata.isConfirm || 0
      onChangePhone(phone)
      let dataOnePayment = null
      onSetCheckItem(items)
      if ('dataOnePayment' in metadata && metadata.dataOnePayment) {
        dataOnePayment = JSON.parse(metadata.dataOnePayment)
        onChangeDataOnePayment(dataOnePayment)
      }
      if (isConfirm === 0) {
        onFetchCreatePayment({method: EPaymentMethod.Online, isOne: !!dataOnePayment}, paymentIntent.id, {
          metadata: {
            ...paymentIntent.metadata,
            isConfirm: 1,
          }
        })
      }
    }
  }, [onChangeDataOnePayment, onChangePhone, onFetchCreatePayment, onSetCheckItem, paymentIntent])

  return useMemo(() => ({
    getPaymentIntent,
  }), [
    getPaymentIntent,
  ])

}
