import React, {Fragment} from 'react';
import styled from "styled-components";
import {ETextVariants} from "../../UI/Text/types";
import {T} from "@tolgee/react";
import Text from "../../UI/Text";
import {IPolitic} from "../../config/politics";

const Block = styled.div`
  width: 100%;
  flex-direction: column;
  display: flex;
  gap: 10px;
`

const Title = styled(Text)`
  font-size: 18px;
`

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

const TextStyled = styled(Text)`
  width: 100%;
  text-align: justify;
  display: inline-block;
  vertical-align: baseline;
`
const DescriptionText = styled(Text)`
  a {
    font-size: inherit;
    color: inherit;
    font-weight: inherit;
    vertical-align: baseline;
  }
`

function BlockComponent({isDescription, items, baseKey, descriptionParams}: IPolitic) {
  return (
    <Block>
      <Title variant={ETextVariants.Heading} as={'h4'}>{baseKey}. <T keyName={`politics.${baseKey}.title`}/></Title>
      {isDescription && <DescriptionText variant={ETextVariants.Body16}>
          <T keyName={`politics.${baseKey}.description`} params={descriptionParams}/>
      </DescriptionText>}
      <List>
        {
          items.map((item, id) => {
            return <Fragment key={id}>
              <li>
                <TextStyled variant={ETextVariants.Body16}>{baseKey}.{item.keyName}. <T
                  keyName={`politics.${baseKey}.items.${item.keyName}`} params={item.params}/></TextStyled>
              </li>
              {item.componentAfter}
            </Fragment>
          })
        }
      </List>
    </Block>
  );
}

export default BlockComponent;
