import {axiosInstance} from "./index";

export const CartApi = {
    getUserCart: async () => await axiosInstance.get(`cart`),
    getUserCartTotal: async () => await axiosInstance.get(`cart/total`),
    postAddItem: async (data: {favorId: number, quantity: number}) => await axiosInstance.post(`cart`, data),
    putUpdateItem: async (itemId: number, quantity: number) => await axiosInstance.put(`cart/${itemId}`, {quantity}),
    delService: async (id: number) => await axiosInstance.delete(`cart/${id}`, ),
    delClearCart: async () => await axiosInstance.delete(`cart` ),
}
