import React, {useMemo} from 'react';
import styled from "styled-components";
import {getTextStyle} from "../../UI/Text/utils";
import {ETextVariants} from "../../UI/Text/types";
import {useCartAction, useCartState} from "../../store/cart/hooks";
import PhoneInput from "react-phone-input-2";
import FormCountComponent from "./FormCountComponent";
import {Button, EButtonVariants} from "../../UI/Button";
import {ServiceResponse} from "../../store/adminServices/types";
import Text from "../../UI/Text";
import {currencyDesc} from "../../config/currency";
import {Link, useNavigate} from "react-router-dom";
import DataOneForm from "./DataOneForm";
import {T} from "@tolgee/react";


const PhoneInputBlock = styled.div`
  padding-bottom: 16px;
  width: 100%;

  & .form-control {
    width: 100%;
    height: 4rem;
    padding: 1rem 2rem 1rem 5rem;
    border-radius: 10px;
    background-color: ${({theme}) => theme.colors.lightWhite0};
    border: 0;
    ${({theme}) => getTextStyle(theme, ETextVariants.Body16)};
  }
;

  & .flag-dropdown {
    border: none;
    border-radius: 0px;
    background-color: transparent !important;
  }
;

  & .selected-flag {
    background-color: transparent !important;
    padding-left: 2rem;
  }
;
`

function FormBuyOneComponent({service, onShowModal}: { service: ServiceResponse, onShowModal: () => void }) {
  const {phone} = useCartState()
  const navigate = useNavigate()
  const {onChangePhone} = useCartAction()
  return (
    <>
      <PhoneInputBlock>
        <Text variant={ETextVariants.Body16}>
          <T keyName="service.gift">Хотите подарить услугу кому-то?</T>
        </Text>
        <PhoneInput
          country="lv"
          placeholder=""
          containerStyle={{marginTop: 20}}
          inputProps={{name: 'phoneNumber'}}
          value={phone}
          onChange={onChangePhone}
        />
      </PhoneInputBlock>
      <DataOneForm {...service}/>
      <Button variant={EButtonVariants.Secondary} $fullWidth onClick={() => {
        if (phone.length === 11) {
          onShowModal()
        } else {
          navigate(`/payment/one`)
        }
      }}>
        <T keyName={"service.buttons.pay"}>Оплатить</T>
      </Button>
    </>
  );
}

export default FormBuyOneComponent;
