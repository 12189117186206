import React, {useEffect, useMemo, useState} from 'react';
import styled from "styled-components";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {useAdminEvents, useAdminEventsAction, useAdminEventsState} from "../../store/adminEvents/hooks";
import Select from "../../UI/Select";
import {DataPickerRange} from "../../UI/DataPickerRange";
import {SearchIcon} from "../../UI/Svg";
import Input from "../../UI/Input";
import {useEventsState} from "../../store/events/hooks";
import {useGeoAction, useGeoState} from "../../store/geo/hooks";
import {T, useTranslate} from "@tolgee/react";

const Wrapper = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
`

const Filters = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  position: relative;
`

const SelectStyled = styled(Select)`
  width: 186px;
  padding: 11px 16px;
  background-position: 90%;
`

const DataPickerRangeStyled = styled(DataPickerRange)`
  min-width: 178px;
`

const InputStyled = styled(Input)`
  background: ${({theme}) => theme.colors.white};
`

function EventFilterView() {
  const {t} = useTranslate()
  useAdminEvents()
  const {filter} = useAdminEventsState()
  const {onChangeFilter} = useAdminEventsAction()
  const {cities, countries} = useGeoState()
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const [startDate, endDate] = useMemo(() => dateRange, [dateRange]);
  const {onClearCities, onFetchCities} = useGeoAction()

  useEffect(() => {
    if (startDate && endDate) {
      onChangeFilter({
        dateFrom: startDate.toString(),
        dateTo: endDate.toString()
      })
    } else if (startDate) {
      onChangeFilter({
        dateFrom: startDate.toString(),
      })
    } else if (endDate) {
      onChangeFilter({
        dateTo: endDate.toString()
      })
    } else {
      onChangeFilter({
        dateFrom: undefined,
        dateTo: undefined,
      })
    }
  }, [startDate, endDate, onChangeFilter])
  useEffect(() => {
    if (filter.country) {
      onFetchCities(filter.country.toLowerCase())
    } else {
      onChangeFilter({city: ''})
      onClearCities()
    }
    return () => {
      onChangeFilter({city: ''})
      onClearCities()
    }
  }, [filter.country, onChangeFilter, onClearCities, onFetchCities])

  return (
    <Wrapper>
      <Text variant={ETextVariants.Body16}><T keyName="admin.filter">Фильтр:</T></Text>
      <Filters>
        <DataPickerRangeStyled
          setDateRange={setDateRange}
          startDate={startDate}
          endDate={endDate}
          bg
          isClearButton={true}
          isIcon={false}
          typeArrowPurple={false}
          mobileVersion={true}
        />
        <SelectStyled value={filter.country}
                      onChange={(event) => onChangeFilter({country: event.target.value})}
                      colorArrow
        >
          <option value="">
            <T keyName={'filter.allCountries'}>Все страны</T>
          </option>
          {countries.map((item, i) => (
            <option key={i}>{`${item}`}</option>
          ))}
        </SelectStyled>
        <SelectStyled value={filter.city}
                      onChange={(event) => onChangeFilter({city: event.target.value})}
                      colorArrow>
          <option value="">
            <T keyName="filter.city">Город</T>
          </option>
          {cities?.map((item, i) => (
            <option key={i} value={item}>{`${item}`}</option>
          ))}
        </SelectStyled>
        <InputStyled
          placeholder={t('common.search', 'Поиск')}
          icon={<SearchIcon/>}
          value={filter.search}
          onChange={(event) => onChangeFilter({search: event.target.value})}
        />
      </Filters>
    </Wrapper>
  );
}

export default EventFilterView;
