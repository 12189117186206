import React, {useContext, useMemo} from 'react';
import {useProfileState} from "../../store/profile/hooks";
import styled from "styled-components";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {T, useTranslate} from "@tolgee/react";
import {mediaUrl} from "../../api";
import imgEmptyAvatar from "../../assets/img/no-avatar.png";
import UserStatusComponent from "./UserStatusComponent";
import {ProfileContext} from "../../contexts/ProfileContext";

const TitleBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  ${({theme}) => theme.mediaQueries.md} {
    gap: 5px;
  }
`

const Title = styled(Text)`
  font-weight: 500;
  font-size: 30px;

  ${({theme}) => theme.mediaQueries.md} {
    font-size: 20px;
  }
`
const Login = styled(Text)`
  color: ${({theme}) => theme.colors.mainPurple};

  ${({theme}) => theme.mediaQueries.md} {
    font-size: 14px;
  }
`


const Status = styled(Text)`
  color: ${({theme}) => theme.colors.mainGray};
  position: absolute;
  right: 30px;
  top: 30px;

  ${({theme}) => theme.mediaQueries.md} {
    font-size: 14px;
    position: relative;
    right: unset;
    top: unset;
  }
`
const Detail = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;

  ${({theme}) => theme.mediaQueries.md} {
    display: none;
  }
`

const DetailItem = styled(Text)`
  color: ${({theme}) => theme.colors.mainGray};

  ${({theme}) => theme.mediaQueries.md} {
    font-size: 14px;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
`

const ProfileImage = styled.div`
  max-height: 71px;
  height: 71px;
  width: 100%;
  max-width: 71px;
  overflow: hidden;
  border-radius: 50%;
  display: none;
  align-items: center;
  justify-content: center;
  background: #9b9b9b;
  
  img {
    height: 100%;
    width: auto;
    max-width: unset;
  }
  ${({theme}) => theme.mediaQueries.md} {
    display: flex;
  }
`


function ProfileTitle() {
  const {profile} = useContext(ProfileContext)
  const {t} = useTranslate()

  const years = useMemo(() => {
    const age = profile?.age || 0;
    let countAge;
    switch (age % 10) {
      case 1:
        countAge = age < 10 || age > 20 ? t('common.year', 'год') : t('common.years', 'лет');
        break;
      case 2:
      case 3:
      case 4:
        countAge = age < 10 || age > 20 ? t('common.ofTheYear', 'года') : t('common.years', 'лет');
        break;
      default:
        countAge = t('common.years', 'лет');
    }
    return profile?.age && `${age} ${countAge}`;
  }, [profile?.age, t])

  if (!profile) return null

  return (
    <TitleWrapper>
      <ProfileImage>
        <img src={profile.avatar ? `${mediaUrl}file/${profile.avatar.id}` : imgEmptyAvatar} alt=""/>
      </ProfileImage>
      <TitleBlock>
        {(profile.firstName && profile.lastName) && <Title variant={ETextVariants.Heading} as={'h2'}>
          {profile.firstName} {profile.lastName}
        </Title>}
        <Login variant={ETextVariants.Body16} as={'p'}>
          @{profile?.login}
        </Login>
        <Status variant={ETextVariants.Body16} as={'p'}>
          <UserStatusComponent {...profile}/>
        </Status>
        <Detail>
          <DetailItem variant={ETextVariants.Body16}>
            <T keyName="settings.gender">Пол</T>:{' '}
            {profile?.sex === 'F' ? (
              <T keyName="settings.female">женский</T>
            ) : (
              <T keyName="settings.male">мужской</T>
            )}
          </DetailItem>
          <DetailItem variant={ETextVariants.Body16}>
            <T keyName="profile.age">Возраст</T>: {years}
          </DetailItem>
        </Detail>
      </TitleBlock>
    </TitleWrapper>
  );
}

export default ProfileTitle;
