import React, {ReactNode, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import styled from 'styled-components';
import Text from "../Text";
import {ETextVariants} from "../Text/types";
import Input from "../Input";
import {getTextStyle} from "../Text/utils";
import {CloseIcon} from "../Svg";
import {BlockValue, DropdownBlock, DropdownInput, DropdownItem, DropdownMenu, DropdownWrapper, IconButton, Wrapper } from './styled';
import {useTranslate} from "@tolgee/react";
interface DropdownProps {
  placeholder?: string,
  value: string | number,
  label: string,
  options: Array<{ item: string, value: string | number }>,
  onSelect: (val: string | number) => void,
  isDisabled?: boolean
}

function Index({options, label, value, placeholder, onSelect, isDisabled}: DropdownProps) {
  const dropdown = useRef<HTMLDivElement>(null)
  const {t} = useTranslate()
  const [isShowMenu, setIsShowMenu] = useState<boolean>(false);

  const [search, setSearch] = useState<string>('');
  const handleClickOutside = useCallback((e: any) => {
    const path = e.path || (e.composedPath && e.composedPath());
    if (!path.includes(dropdown.current)) {
      setIsShowMenu(false)
    }
  }, [])

  const selectedItem = useMemo(() => options.filter((opt) => opt.value === value)[0] || null, [options, value])
  const filterOptions = useMemo(() => options.filter((opt) => opt.item.toLowerCase().includes(search.toLowerCase())), [options, search])

  useEffect(() => {
    const rootEl = document.getElementById('root')
    if (rootEl && window) {
      window.addEventListener('click', handleClickOutside, false);
      return () => {
        window.removeEventListener('click', handleClickOutside, false);
      }
    }
  }, [handleClickOutside])
  return (
    <Wrapper ref={dropdown}>
      <Text variant={ETextVariants.Body16}>
        {label}
      </Text>
      <DropdownWrapper>
        <BlockValue onClick={() => {
          if (!isDisabled) setIsShowMenu(prev => !prev)
        }}>
          <Text variant={ETextVariants.Body16}>
            {selectedItem?.item || placeholder}
          </Text>
        </BlockValue>

        {isShowMenu && <DropdownBlock>
            <DropdownInput placeholder={t('common.search', 'Поиск')} type='text' value={search}
                           onChange={event => setSearch(event.target.value)}
            />
            <IconButton onClick={() => setIsShowMenu(false)}><CloseIcon/></IconButton>
            <DropdownMenu>
              {
                filterOptions.length > 0
                  ? filterOptions.map((item) => <DropdownItem key={item.value} $isSelected={item.value === value}
                                                              onClick={() => onSelect(item.value)}>{item.item}</DropdownItem>)
                  : options.map((item) => <DropdownItem key={item.value} $isSelected={item.value === value}
                                                        onClick={() => onSelect(item.value)}>{item.item}</DropdownItem>)
              }
            </DropdownMenu>
        </DropdownBlock>}
      </DropdownWrapper>
    </Wrapper>
  );
}

export default Index;
