import {axiosInstance} from "./index";

export const GeoApi = {
    getCities: async (country: string) => await axiosInstance.get(`geo/cities/${country}`),
    getCountries: async () => await axiosInstance.get('geo/countries'),
    getCountriesDb: async () => await axiosInstance.get('geo/database/countries'),
    getCitiesDb: async (data: {
        "country": string,
        "district": string,
    }) => await axiosInstance.get('geo/database/cities', {params: data}),
}
