import React from 'react';
import styled from "styled-components";
import {useProfileState} from "../../store/profile/hooks";
import InterestItem from "../../components/AdminComponents/InterestItem";

const Wrapper = styled.div`
  width: calc(100% + 80px);
  display: flex;
  flex-direction: column;
  margin: 0 -40px;
  margin-top: 40px;
`

function ListInterests() {
  const {interests} = useProfileState()
  return (
    <Wrapper>
      {
        interests.map((item) => <InterestItem key={item.id} {...item} />)
      }
    </Wrapper>
  );
}

export default ListInterests;
