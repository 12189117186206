import React from 'react';
import styled from "styled-components";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {CalendarIcon, GeoGrayIcon, PriceIcon} from "../../UI/Svg";
import dayjs from "dayjs";
import {ServiceResponse} from "../../store/adminServices/types";
import {mediaUrl} from "../../api";
import {currencyDesc} from "../../config/currency";
import {useNavigate} from "react-router-dom";
import {T} from "@tolgee/react";

const Wrapper = styled.div`
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 25px;
  border-radius: 18px;
  padding: 12px 10px;
  background: ${({theme}) => theme.colors.lightWhite1};
`

const HeaderWrapper = styled.div`
  height: 121px;
  width: 275px;
  min-width: 275px;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({theme}) => theme.colors.bg};
  img {
    width: 100%;
    height: auto;
  }
  ${({theme}) => theme.mediaQueries.md} {
    width: 97px;
    min-width: 97px;
  }
`

const Desc = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: calc(100% - 300px);
`

const Title = styled(Text)`
  
`

const List = styled.ul`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
  justify-content: flex-start;
`

const Item = styled.li`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;
  svg {
    min-width: 24px;
    height: 24px;
  }
  ${({theme}) => theme.mediaQueries.md} {
    svg {
      min-width: 20px;
      height: 20px;
    }
  }
`

const TextStyled = styled(Text) `
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  ${({theme}) => theme.mediaQueries.md} {
    font-size: 12px;
  }
`

function ServiceListItem({title, id, header, address, expirationDate, price, currency}: ServiceResponse) {

  const navigate = useNavigate()

  return (
    <Wrapper onClick={() => navigate(`/service/${id}`)}>
      <HeaderWrapper>
        <img src={header ? `${mediaUrl}file/${header.id}` : ''} alt=""/>
      </HeaderWrapper>
      <Desc>
        <Title variant={ETextVariants.Body16} as={'h4'}>{title}</Title>
        <List>
          {expirationDate && <Item>
            <CalendarIcon/>
            <TextStyled
              variant={ETextVariants.Body16}> {dayjs(expirationDate).format('D MMMM / HH:mm')}</TextStyled>
          </Item>}
          {address && <Item>
            <GeoGrayIcon/>
            <TextStyled variant={ETextVariants.Body16}>{address?.fullString}</TextStyled>
          </Item>}
          <Item>
            <PriceIcon/>
            <TextStyled variant={ETextVariants.Body16}>{price}
              <T keyName={`currencies.${currency}.symbol`}>{currencyDesc[currency].symbol}</T>
              </TextStyled>
          </Item>
        </List>
      </Desc>
    </Wrapper>
  );
}

export default ServiceListItem;
