import {useDispatch, useSelector} from "react-redux";
import {useCallback, useEffect, useMemo} from "react";
import {AppDispatch, RootState} from "../index";
import {IAdminServicesFilter} from "./types";
import fetchServices from "./fetchServices";
import { changeFilter } from "./adminServicesSlice";
import {changePage} from "./adminServicesSlice";
import fetchDeleteService from "./fetchDeleteService";

export const getAdminServices = (state: RootState) => state.adminServices

export function useAdminServicesAction() {
  const dispatch = useDispatch<AppDispatch>()
  const onChangePage = useCallback((page: number) => {
    dispatch(changePage(page))
  }, [dispatch])
  const onChangeFilter = useCallback((data: IAdminServicesFilter) => {
    dispatch(changeFilter(data))
  }, [dispatch])
  const onFetchServices = useCallback(() => {
    dispatch(fetchServices())
  }, [dispatch])
  const onDeleteService = useCallback((id: number) => {
    dispatch(fetchDeleteService(id))
      .unwrap()
      .then(() => {
        onFetchServices()
      })
  }, [dispatch, onFetchServices])
  return useMemo(() => ({
    onChangeFilter,
    onFetchServices,
    onChangePage,
    onDeleteService,
  }), [
    onChangeFilter,
    onFetchServices,
    onChangePage,
    onDeleteService,
  ])
}

export function useAdminServicesState() {
  return useSelector(getAdminServices)
}

export function useAdminServices() {
  const {filter} = useAdminServicesState()
  const {onFetchServices} = useAdminServicesAction()

  useEffect(() => {
    onFetchServices()
  }, [filter, onFetchServices])

}
