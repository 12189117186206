import {useDispatch, useSelector} from "react-redux";
import {useCallback, useMemo} from "react";
import {AppDispatch, RootState} from "../index";
import fetchUpdateProfile from "./fetchUpdateProfile";
import {ProfileSettingsPayload} from "./types";
import fetchProfile from "./fetchProfile";
import fetchSubscribe from "./fetchSubscribe";
import fetchUnsubscribe from "./fetchUnsubscribe";
import fetchProfileById from "./fetchProfileById";
import fetchMyEventsCreated from "./fetchMyEventsCreated";
import fetchProfileData from "./fetchProfileData";
import fetchInterests from "./fetchInterests";
import fetchCreateInterests from "./fetchCreateInterests";
import {useWallAction} from "../wall/hooks";
import {useAuthAction, useAuthState} from "../auth/hooks";
import {clearError, clearProfile} from "./profileSlice";
import fetchDeleteInterests from "./fetchDeleteInterests";
import fetchUpdateInterests from "./fetchUpdateInterests";

export const getProfile = (state: RootState) => state.profile

export function useProfileAction() {
  const dispatch = useDispatch<AppDispatch>()
  const {user} = useAuthState()
  const {onSetUserData} = useAuthAction()
  const onUpdateProfile = useCallback((data: ProfileSettingsPayload) => {
    dispatch(fetchUpdateProfile(data))
      .unwrap()
      .then(({profile}) => {
        if (user?.id === profile.id) {
          onSetUserData(profile)
        }
      })
  }, [dispatch, onSetUserData, user?.id])
  const onFetchMyEventsCreated = useCallback(() => {
    dispatch(fetchMyEventsCreated())
  }, [dispatch])
  const onFetchInterests = useCallback(() => {
    dispatch(fetchInterests())
  }, [dispatch])

  const onFetchProfileData = useCallback(() => {
    dispatch(fetchProfileData())
  }, [dispatch])
  const onClearProfile = useCallback(() => {
    dispatch(clearProfile())
    onFetchProfileData()
  }, [dispatch, onFetchProfileData])
  const onClearError = useCallback(() => {
    dispatch(clearError())
  }, [dispatch])
  const onFetchCreateInterest = useCallback((title: string) => {
    dispatch(fetchCreateInterests(title))
      .unwrap()
      .then(() => {
        onFetchInterests()
      })
  }, [dispatch, onFetchInterests])

  const onFetchDeleteInterest = useCallback((id: number) => {
    dispatch(fetchDeleteInterests(id))
      .unwrap()
      .then(() => {
        onFetchInterests()
      })
  }, [dispatch, onFetchInterests])

  const onFetchUpdateInterest = useCallback((id: number, title: string) => {
    dispatch(fetchUpdateInterests({id, title}))
      .unwrap()
      .then(() => {
        onFetchInterests()
      })
  }, [dispatch, onFetchInterests])

  const onFetchProfile = useCallback(() => {
    dispatch(fetchProfile())
      .unwrap()
      .then(() => {
        onFetchProfileData()
      })
  }, [dispatch, onFetchProfileData])
  const onFetchProfileById = useCallback((id: string) => {
    dispatch(fetchProfileById(id))
      .unwrap()
      .then(() => {
        onFetchProfileData()
      })
  }, [dispatch, onFetchProfileData])
  const onSubscribe = useCallback(() => {
    dispatch(fetchSubscribe())
      .unwrap()
      .then(() => {
        onFetchProfileData()
      })
  }, [dispatch, onFetchProfileData])
  const onUnsubscribe = useCallback(() => {
    dispatch(fetchUnsubscribe())
      .unwrap()
      .then(() => {
        onFetchProfileData()
      })
  }, [dispatch, onFetchProfileData])
  return useMemo(() => ({
    onUpdateProfile,
    onFetchProfile,
    onSubscribe,
    onUnsubscribe,
    onFetchProfileById,
    onFetchMyEventsCreated,
    onFetchProfileData,
    onFetchInterests,
    onFetchCreateInterest,
    onClearProfile,
    onClearError,
    onFetchDeleteInterest,
    onFetchUpdateInterest,
  }), [
    onUpdateProfile,
    onFetchProfile,
    onSubscribe,
    onUnsubscribe,
    onFetchProfileById,
    onFetchMyEventsCreated,
    onFetchProfileData,
    onFetchInterests,
    onFetchCreateInterest,
    onClearProfile,
    onClearError,
    onFetchDeleteInterest,
    onFetchUpdateInterest,
  ])
}

export function useProfileState() {
  return useSelector(getProfile)
}
