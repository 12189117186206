import imageCompression from "browser-image-compression";

export function sleep(ms: number) {
  return new Promise(resolve => {
    setTimeout(resolve, ms)
  });
}

export function generateArrayFrom(startIndex: number, length: number) {
  return Array.from({length}, (_, i) => i + startIndex)
}

export async function handleImageUpload(imageFile: any) {
  if (!imageFile) return null

  // console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
  // console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

  const options = {
    maxWidthOrHeight: 1080,
    useWebWorker: true,
  }
  try {
    const compressedFile = await imageCompression(imageFile, options);
    // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
    // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

    return compressedFile
  } catch (error) {
    console.log(error);
    return null
  }

}
