import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {SubscriptionApi} from "../../api/subscription";


const fetchSubscribe = createAsyncThunk<void,
  void,
  {
    state: RootState
  }>(
  'profile/fetchSubscribe',
  async (_, ThunkApi) => {
    const authState = ThunkApi.getState().auth
    const {profileOther} = ThunkApi.getState().profile
    if (authState.auth && profileOther?.id) {
      const [, subscribeErr] = await handle(SubscriptionApi.postSubscribe(profileOther.id))
      if (subscribeErr) {
        throw {message: subscribeErr.message[0] || subscribeErr.message}
      }
      return
    }
    throw {message: 'No user state'}
  }
)

export default fetchSubscribe
