import React from 'react';
import styled from "styled-components";
import {Card} from "../Templates/Card";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {EventResponse} from "../../store/events/types";
import {CalendarIcon, GeoGrayIcon, SmileIcon} from "../../UI/Svg";

import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import {T} from "@tolgee/react";
import {mediaUrl} from "../../api";

const Wrapper = styled(Card)`
  max-width: calc(50% - 10px);
  display: flex;
  width: 100%;
  cursor: pointer;
  align-items: stretch;
  gap: 20px;
  ${({theme}) => theme.mediaQueries.md} {
    max-width: 100%;
  }
`

const EventImage = styled.div`
  display: flex;
  align-items: center;
  background: ${({theme}) => theme.colors.bg};
  justify-content: center;
  border-radius: 18px;
  overflow: hidden;

  height: 113px;
  min-width: 97px;

  img {
    width: 100%;
    height: auto;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  flex: 1;
`

const List = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
`

const Item = styled.li`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  svg {
    min-width: 20px;
    width: 20px;
    height: 20px;
  }
`

const TextStyled = styled(Text) `
  font-size: 12px;
`
const Members = styled(TextStyled)`
  white-space: pre;
  color: ${({theme}) => theme.colors.mainPurple};
  max-width: 65%;
  overflow: hidden;
`


function ListItemEvent({item:{id, date, address, maxMembers, members, photo, title}, invite}: { item: EventResponse, invite: (id: string) => void }) {

  const goToEvents = (id: number): void => {
    invite(`${id}`)
  };
  return (
    <Wrapper onClick={() => goToEvents(id)}>
      <EventImage>
        <img src={photo ? `${mediaUrl}file/${photo.id}` : ''} alt=""/>
      </EventImage>
      <Content>
        <Text variant={ETextVariants.Heading} as={'h2'}>{title}</Text>
        <List>
          <Item>
            <CalendarIcon/>
            <TextStyled variant={ETextVariants.Body16}> {dayjs(date).format('D MMMM / HH:mm')}</TextStyled>
          </Item>
          <Item>
            <GeoGrayIcon/>
            <TextStyled variant={ETextVariants.Body16}>{address.fullString}</TextStyled>
          </Item>
          <Item>
            <SmileIcon/>
            <Members variant={ETextVariants.Body16}>
              {maxMembers > 0
                ? <T
                  keyName="event.numberOfMembers1"
                  params={{
                    numberOfMembers: members.length,
                    maxNumberOfMembers: maxMembers
                  }}
                  defaultValue={`${members.length} из ${maxMembers} участников уже присоединились`}
                />
                : <T keyName="event.numberOfMembers2"
                     params={{numberOfMembers: members.length}}
                     defaultValue={`${members.length} участников уже присоединились`}
                />
              }
            </Members>
          </Item>
        </List>
      </Content>
    </Wrapper>
  );
}

export default ListItemEvent;
