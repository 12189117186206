import {useDispatch, useSelector} from "react-redux";
import {useCallback, useMemo} from "react";
import {AppDispatch, RootState} from "../index";
import fetchCities from "./fetchCities";
import fetchCountries from "./fetchCountries";
import {clearCities, clearCitiesDb} from "./geoSlice";
import fetchCitiesDb from "./fetchCitiesDb";
import fetchCountriesDb from "./fetchCountriesDb";

export const getGeo = (state: RootState) => state.geo

export function useGeoAction() {
  const dispatch = useDispatch<AppDispatch>()

  const onClearCities = useCallback(() => {
    dispatch(clearCities())
  }, [dispatch])
  const onClearCitiesDb = useCallback(() => {
    dispatch(clearCitiesDb())
  }, [dispatch])
  const onFetchCities = useCallback((country: string) => {
    dispatch(fetchCities(country))
  }, [dispatch])
  const onFetchCitiesDb = useCallback((data: {
    country: string,
    district: string,
  }) => {
    dispatch(fetchCitiesDb(data))
  }, [dispatch])
  const onFetchCountriesDb = useCallback(() => {
    dispatch(fetchCountriesDb())
  }, [dispatch])
  const onFetchCountries = useCallback(() => {
    dispatch(fetchCountries())
  }, [dispatch])
  return useMemo(() => ({
    onFetchCities,
    onFetchCountries,
    onClearCities,
    onClearCitiesDb,
    onFetchCitiesDb,
    onFetchCountriesDb,
  }), [
    onFetchCities,
    onFetchCountries,
    onClearCities,
    onClearCitiesDb,
    onFetchCitiesDb,
    onFetchCountriesDb,
  ])
}

export function useGeoState() {
  return useSelector(getGeo)
}
