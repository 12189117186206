import React from 'react';
import AdminHeader from "../../../components/AdminComponents/AdminHeader";
import {Navigate, Route, Routes} from "react-router-dom";
import {useAdminUsers, useAdminUsersState} from "../../../store/adminUsers/hooks";
import UsersFilterView from "../../../views/AdminUsersViews/UsersFilterView";
import UsersListView from "../../../views/AdminUsersViews/UsersListView";
import LoadingSpinner from "../../../UI/LoadingSpinner";
import {useTranslate} from "@tolgee/react";

function Index() {
  const {t} = useTranslate()
  useAdminUsers()
  const {isLoading} = useAdminUsersState()
  return (
    <Routes>
      <Route index element={
        <>
          <AdminHeader title={t('admin.menu.users','Пользователи')}/>
          <UsersFilterView/>
          {
            isLoading
              ? <LoadingSpinner/>
              : <UsersListView/>
          }
        </>} />
      <Route path="*" element={<Navigate to="/admin/users" />} />
    </Routes>
  );
}

export default Index;
