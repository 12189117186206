import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="22" height="22" viewBox="0 0 22 22" fill="none" {...props} >
          <path d="M9 14L5.92474 17.1137C5.49579 17.548 5.28131 17.7652 5.09695 17.7805C4.93701 17.7938 4.78042 17.7295 4.67596 17.6076C4.55556 17.4672 4.55556 17.162 4.55556 16.5515V14.9916C4.55556 14.444 4.10707 14.0477 3.5652 13.9683V13.9683C2.25374 13.7762 1.22378 12.7463 1.03168 11.4348C1 11.2186 1 10.9605 1 10.4444V5.8C1 4.11984 1 3.27976 1.32698 2.63803C1.6146 2.07354 2.07354 1.6146 2.63803 1.32698C3.27976 1 4.11984 1 5.8 1H13.2C14.8802 1 15.7202 1 16.362 1.32698C16.9265 1.6146 17.3854 2.07354 17.673 2.63803C18 3.27976 18 4.11984 18 5.8V10M18 21L15.8236 19.4869C15.5177 19.2742 15.3647 19.1678 15.1982 19.0924C15.0504 19.0255 14.8951 18.9768 14.7356 18.9474C14.5558 18.9143 14.3695 18.9143 13.9969 18.9143H12.2C11.0799 18.9143 10.5198 18.9143 10.092 18.6963C9.71569 18.5046 9.40973 18.1986 9.21799 17.8223C9 17.3944 9 16.8344 9 15.7143V13.2C9 12.0799 9 11.5198 9.21799 11.092C9.40973 10.7157 9.71569 10.4097 10.092 10.218C10.5198 10 11.0799 10 12.2 10H17.8C18.9201 10 19.4802 10 19.908 10.218C20.2843 10.4097 20.5903 10.7157 20.782 11.092C21 11.5198 21 12.0799 21 13.2V15.9143C21 16.8462 21 17.3121 20.8478 17.6797C20.6448 18.1697 20.2554 18.5591 19.7654 18.762C19.3978 18.9143 18.9319 18.9143 18 18.9143V21Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </Svg>
    );

export default Icon;
