import React, {useEffect} from 'react';
import AdminHeader from "../../components/AdminComponents/AdminHeader";
import FormView from "../../views/InterestsViews/FormView";
import ListInterests from "../../views/InterestsViews/ListInterests";
import {useProfileAction} from "../../store/profile/hooks";
import {useTranslate} from "@tolgee/react";

function AdminInterests() {
  const {t} = useTranslate()
  const {onFetchInterests} = useProfileAction()
  useEffect(() => {
    onFetchInterests()
  }, [onFetchInterests])
  return (
    <>
      <AdminHeader title={t('common.interests','Интересы')} isBack={true}/>
      <FormView/>
      <ListInterests/>
    </>
  );
}

export default AdminInterests;
