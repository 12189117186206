import {configureStore} from '@reduxjs/toolkit'
import {save, load, combineLoads} from 'redux-localstorage-simple';
import authSlice, {AuthState} from "./auth/authSlice";
import pendingSlice from "./pending/pendingSlice";
import profileSlice from "./profile/profileSlice";
import chatSlice from "./chat/chatSlice";
import eventsSlice from "./events/eventsSlice";
import servicesSlice from "./services/servicesSlice";
import wallSlice from "./wall/wallSlice";
import geoSlice from "./geo/geoSlice";
import cartSlice from "./cart/cartSlice";
import notificationsSlice from "./notifications/notificationsSlice";
import appSlice from "./app/appSlice";
import usersSlice from "./users/usersSlice";
import acquaintanceSlice from "./acquaintance/acquaintanceSlice";
import companySlice from "./company/companySlice";
import {initialState as companyInitialState} from "./company/companySlice";
import adminPaymentsSlice from "./adminPayments/adminPaymentsSlice";
import adminUsersSlice from "./adminUsers/adminUsersSlice";
import adminEventsSlice from "./adminEvents/adminEventsSlice";
import adminServicesSlice from "./adminServices/adminServicesSlice";
import adminSupportsSlice from "./adminSupports/adminSupportsSlice";
import adminCompaniesSlice from "./adminCompanies/adminCompaniesSlice";
import adminForbiddenWordsSlice from "./adminForbiddenWords/adminForbiddenWordsSlice";
import chatMiddleware from "./chat/middleware";

type MergedState = {
    auth: AuthState
}
const PERSISTED_KEYS: string[] = ['auth', 'company.selectedUserCompany']
const loadedState = load({states: PERSISTED_KEYS, preloadedState: {
        company: {
            ...companyInitialState
        }
    }}, ) as MergedState
// const loadedState = combineLoads(
//   load({ states: ["user"], namespace: "account_stuff" }),
//   load({ states: ["products", "categories"], namespace: "site_stuff" })
// ) as MergedState

export const store = configureStore({
    reducer: {
        auth: authSlice,
        pending: pendingSlice,
        geo: geoSlice,
        profile: profileSlice,
        notifications: notificationsSlice,
        chat: chatSlice,
        events: eventsSlice,
        services: servicesSlice,
        wall: wallSlice,
        cart: cartSlice,
        app: appSlice,
        users: usersSlice,
        company: companySlice,
        adminUsers: adminUsersSlice,
        adminEvents: adminEventsSlice,
        acquaintance: acquaintanceSlice,
        adminPayments: adminPaymentsSlice,
        adminServices: adminServicesSlice,
        adminSupports: adminSupportsSlice,
        adminCompanies: adminCompaniesSlice,
        adminForbiddenWords: adminForbiddenWordsSlice,
    },
    preloadedState: loadedState,
    devTools: true,
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        thunk: true,
    })
        .concat([save({states: PERSISTED_KEYS}), chatMiddleware])
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
