import { FormatIcu } from '@tolgee/format-icu';
import { Tolgee, DevTools, FormatSimple } from '@tolgee/react';

export function initTolgee() {
  const tolgee = Tolgee()
    .use(DevTools())
    .use(FormatIcu())
    .init({
      language: 'en',
      apiUrl: process.env.REACT_APP_TOLGEE_URL,
      apiKey: process.env.REACT_APP_TOLGEE_API_KEY,
      staticData: {
        en: () => import('../i18n/en.json'),
        'ru': () => import('../i18n/ru.json'),
        'lv': () => import('../i18n/lv.json'),
        // 'de-DE': () => import('../i18n/de-DE.json'),
      },
    });
  tolgee.run();
  return tolgee;
}
