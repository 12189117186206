import React from 'react';
import styled from "styled-components";
import ContentLoader from "react-content-loader";
import {SpinnerIcon} from "../../UI/Svg";

const LoadingBlockStyled = styled.div`
  display: flex;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.md} {
    display: none;
  }
`

const LoadingIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  svg {
    display: none;
    width: 44px;
    height: 44px;
    ${({theme}) => theme.mediaQueries.md} {
      display: block;
    }
  }
`

const skeletons = [...new Array(3)]
function HomeLoading() {
  return (
    <>
      {skeletons.map((_, id) => <div key={id}>
        <LoadingBlockStyled>
          <ContentLoader
            speed={2}
            width="100%"
            height={317}
            backgroundColor="#FFF"
            foregroundColor="#ecebeb"
            style={{ marginTop: 20, borderRadius: '1rem' }}
          >
            <rect x="0" y="0" rx="0" ry="0" width="100%" height="833" />
          </ContentLoader>
        </LoadingBlockStyled>
      </div>)}
      <LoadingIcon>
        <SpinnerIcon/>
      </LoadingIcon>
    </>
  );
}

export default HomeLoading;
