import React, {useEffect, useMemo, useState} from 'react';
import {ETextVariants} from "../../../UI/Text/types";
import {T, useTranslate} from "@tolgee/react";
import styled from "styled-components";
import Text from "../../../UI/Text";
import {useProfileAction, useProfileState} from "../../../store/profile/hooks";
import {Controller, useForm, useWatch} from "react-hook-form";
import {ProfileFormData, ProfileSettingsPayload} from "../../../store/profile/types";
import {Card} from "../../../components/Templates/Card";
import Input from "../../../UI/Input";
import {Radio} from "../../../UI/Input/Radio";
import {AcquaintancePurpose, AcquaintanceShowMyselfToGender} from "../../../types";
import {Link} from "react-router-dom";
import {Checkbox} from "../../../UI/Input/Checkbox";
import {useAppAction} from "../../../store/app/hooks";
import _ from "lodash";
import {Button, EButtonVariants} from "../../../UI/Button";
import DropdownCustom from "../../../UI/Dropdown/DropdownCustom";
import {useGeoAction, useGeoState} from "../../../store/geo/hooks";


const Title = styled(Text)`
  font-size: 22px;

  ${({theme}) => theme.mediaQueries.md} {
    display: none;
  }
`
const FormStyled = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 17px;
  align-items: flex-start;
`
const CardForm = styled(Card)`
  display: flex;
  width: 100%;
  max-width: 1070px;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 17px;
  height: 100%;
  flex-direction: column;
  overflow: visible;
`


const InputRow = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  flex-direction: column;
  gap: 10px;
  justify-content: flex-start;
`

const InputHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const Radios = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;
`

const LinkStyled = styled(Text)`
  color: ${({theme}) => theme.colors.mainPurple};
`

function Index() {
  const {t} = useTranslate();
  const {onFetchProfile, onUpdateProfile, onFetchInterests} = useProfileAction()
  const {profile, interests, isUpdateLoading} = useProfileState()
  const [interestsSelected, setInterestsSelected] = useState<number[]>(profile?.interests?.map((item) => item.id) ?? []);

  const {onFetchCountriesDb, onFetchCitiesDb, onClearCitiesDb} = useGeoAction()
  const {citiesDb, countriesDb} = useGeoState()

  const [selectedCountryId, setSelectedCountryId] = useState<string>(profile?.address?.country || '');

  useEffect(() => {
    onFetchCountriesDb()
  }, [onFetchCountriesDb])

  const interestsSelectedItems = useMemo(() => interests.filter((item) => _.includes(interestsSelected, item.id)), [interests, interestsSelected])

  useEffect(() => {
    onFetchProfile()
  }, [onFetchProfile])

  const {control, register, handleSubmit, setValue, formState: {errors}} = useForm<ProfileFormData>({
    defaultValues: {
      description: profile?.description ?? '',
      profession: profile?.profession ?? '',
      country: profile?.address?.country ?? '',
      district: profile?.address?.district ?? '',
      city: profile?.address?.city ?? '',
      inAcquaintances: profile?.inAcquaintances ?? false,
      acquaintancePurpose: profile?.acquaintancePurpose ?? AcquaintancePurpose.Chatting,
      acquaintanceShowMeGender: profile?.acquaintanceShowMeGender ?? AcquaintanceShowMyselfToGender.All,
      acquaintanceNotifications: profile?.acquaintanceNotifications ?? true,
    },
  });

  const country = useWatch({control, name: 'country', defaultValue: ''})
  const district = useWatch({control, name: 'district', defaultValue: ''})

  const countries = useMemo(() => {
    return _.uniqBy(countriesDb.map((item) => ({
      value: item.name,
      item: item.name,
    })), 'value')
  }, [countriesDb])

  useEffect(() => {

    if (country && district) {
      onFetchCitiesDb({
        country: country,
        district: district
      })
    }
    return () => {
      onClearCitiesDb()
    }
  }, [country, district, onClearCitiesDb, onFetchCitiesDb, onFetchCountriesDb, setValue])

  const selectedCountry = useMemo(() => countriesDb.filter((item) => item.name === selectedCountryId)[0] || null, [selectedCountryId, countriesDb])

  const states = useMemo(() => {
    if (selectedCountry) {
      setValue('country', selectedCountry.name)
      return _.uniqBy(selectedCountry.states.map((item) => ({
        value: item.name,
        item: item.name,
      })), 'value')
    }
    return []
  }, [selectedCountry, setValue])


  useEffect(() => {
    if (profile) {
      setInterestsSelected(profile?.interests?.map((item) => item.id) || []);
      setSelectedCountryId(profile?.address?.country ?? '')
      setValue('profession', profile?.profession ?? '');
      setValue('inAcquaintances', profile?.inAcquaintances ?? false);
      setValue('description', profile?.description ?? '');
      setValue('country', profile?.address?.country ?? '');
      setValue('district', profile?.address?.district ?? '');
      setValue('city', profile?.address?.city ?? '');
      setValue('acquaintancePointer', profile?.acquaintancePointer ?? 0);
      setValue('acquaintancePurpose', profile?.acquaintancePurpose ?? AcquaintancePurpose.Chatting);
      setValue('acquaintanceShowMeGender', profile?.acquaintanceShowMeGender ?? AcquaintanceShowMyselfToGender.All);
      setValue('acquaintanceNotifications', profile?.acquaintanceNotifications ?? true);
    }
  }, [profile, setValue]);

  const inAcquaintances: any = useWatch({control, name: 'inAcquaintances', defaultValue: false})

  const isInAcquaintance = useMemo(() => {
    if (typeof inAcquaintances === 'string') {
      return inAcquaintances === '1'
    }
    return inAcquaintances
  }, [inAcquaintances])

  const submitHandler = handleSubmit((data) => {
    const payload: ProfileSettingsPayload = {
      ...data,
      inAcquaintances: isInAcquaintance,
    };
    onUpdateProfile(payload)
  });


  const {onSetTitle} = useAppAction()
  useEffect(() => {
    onSetTitle(t('common.settings', 'Настройки'))
  }, [onSetTitle, t])


  return (
    <CardForm>
      <Title variant={ETextVariants.Heading} as={'h2'}><T keyName="settings">Настройки</T></Title>
      <FormStyled onSubmit={submitHandler}>
        <InputRow>
          <Input
            placeholder={t('acquaintanceSettings.fields.profession.placeholder',"Профессия")}
            wth={'100%'}
            label={t('acquaintanceSettings.fields.profession.label','Профессия')}
            error={errors.profession ? errors.profession.type === 'required' ? t('inputs.required',"This field is required") : errors.profession.message : undefined}
            {...register('profession', {required: true, pattern: /[a-zA-Zа-яА-Я0-9 ]{3,50}/})}
          />
        </InputRow>

        <InputRow>
          <Text variant={ETextVariants.Body16}>
            <T keyName="acquaintanceSettings.fields.inAcquaintances.label">Мой профиль</T>
          </Text>
          <Radios>
            <Radio label={t('acquaintanceSettings.fields.inAcquaintances.all','Видят все')} value={1} checked={isInAcquaintance}
                   id='inAcquaintances-true' {...register('inAcquaintances')} />
            <Radio label={t('acquaintanceSettings.fields.inAcquaintances.nobody','Не видит никто')} value={0} checked={!isInAcquaintance}
                   id='inAcquaintances-false' {...register('inAcquaintances')} />
          </Radios>
        </InputRow>
        <InputRow>
          <Text variant={ETextVariants.Body16}>
            <T keyName="acquaintanceSettings.fields.acquaintancePurpose.label">Хочу</T>
          </Text>
          <Radios>
            <Radio label={t('acquaintanceSettings.acquaintancePurpose.love','Найти любовь')} value={AcquaintancePurpose.Love}
                   id={AcquaintancePurpose.Love} {...register('acquaintancePurpose')} />
            <Radio label={t('acquaintanceSettings.acquaintancePurpose.chatting','Просто общаться')} value={AcquaintancePurpose.Chatting}
                   id={AcquaintancePurpose.Chatting} {...register('acquaintancePurpose')} />
            <Radio label={t('acquaintanceSettings.acquaintancePurpose.friends','Найти друзей')} value={AcquaintancePurpose.Friends}
                   id={AcquaintancePurpose.Friends} {...register('acquaintancePurpose')} />
          </Radios>
        </InputRow>
        <InputRow>
          <Text variant={ETextVariants.Body16}>
            <T keyName="acquaintanceSettings.fields.acquaintanceShowMeGender.label">Кого показывать</T>
          </Text>
          <Radios>
            <Radio label={t('acquaintanceSettings.fields.acquaintanceShowMeGender.male','Мужчин')} value={AcquaintanceShowMyselfToGender.M}
                   id={AcquaintanceShowMyselfToGender.M} {...register('acquaintanceShowMeGender')} />
            <Radio label={t('acquaintanceSettings.fields.acquaintanceShowMeGender.female','Женщин')} value={AcquaintanceShowMyselfToGender.F}
                   id={AcquaintanceShowMyselfToGender.F} {...register('acquaintanceShowMeGender')} />
            <Radio label={t('acquaintanceSettings.fields.acquaintanceShowMeGender.all','Всех')} value={AcquaintanceShowMyselfToGender.All}
                   id={AcquaintanceShowMyselfToGender.All} {...register('acquaintanceShowMeGender')} />
          </Radios>
        </InputRow>
        <InputRow>
          <InputHeader>
            <Text variant={ETextVariants.Body16}><T keyName="common.interests">Интересы</T></Text>
            <Link to="/acquaintance/interests">
              <LinkStyled variant={ETextVariants.Body16}>
                <T keyName="common.select">Выбрать</T>
              </LinkStyled>
            </Link>
          </InputHeader>
          <Input
            wth={'100%'} value={interestsSelectedItems.map((item) => t(`interest.list.${item.id}`, item.title)).join(', ')} onChange={() => null}/>
        </InputRow>
        <InputRow>
          <DropdownCustom isSearch
                          label={t("acquaintanceSettings.fields.country.label",'Страна')}
                          placeholder={t("acquaintanceSettings.fields.country.placeholder",'Страна')}
                          options={countries} value={selectedCountryId}
                          onSelect={(val) => {
                            setValue('district', '')
                            setValue('city', '')
                            setSelectedCountryId(String(val))
                          }}/>
        </InputRow>
        {country && <InputRow>
          <Controller control={control} name={'district'} render={({
                                                                     field: {onChange, value},
                                                                   }) => (
            <DropdownCustom isSearch
                            label={t("acquaintanceSettings.fields.district.label",'Область')}
                            placeholder={t("acquaintanceSettings.fields.district.placeholder",'Область')}
                            options={states} value={value}
                            onSelect={(val) => {
                              onChange(val)
                              setValue('city', '')
                            }}/>
          )}/>
        </InputRow>}
        {district && <InputRow>
          <Controller control={control} name={'city'} render={({
                                                                     field: {onChange, value},
                                                                   }) => (
            <DropdownCustom isSearch
                            label={t("acquaintanceSettings.fields.city.label",'Город')}
                            placeholder={t("acquaintanceSettings.fields.city.placeholder",'Город')}
                            options={citiesDb.map((city) => ({
              item: city,
              value: city,
            }))} value={value}
                            onSelect={(val) => {
                              onChange(val)
                            }}/>
          )}/>
        </InputRow>}
        <InputRow>
          <Checkbox label={t('common.notifications','Уведомления')}
                    {...register('acquaintanceNotifications')}
                    id="notification"/>
        </InputRow>
        <Button variant={EButtonVariants.Primary} $fullWidth disabled={isUpdateLoading}>
          {isUpdateLoading
            ? <T keyName="acquaintanceSettings.buttons.pending">Обновление...</T>
            : <T keyName="acquaintanceSettings.buttons.save">Сохранить</T>}
        </Button>
      </FormStyled>
    </CardForm>
  );
}

export default Index;
