import {useDispatch, useSelector} from "react-redux";
import {useCallback, useEffect, useMemo} from "react";
import {AppDispatch, RootState} from "../index";
import fetchUserChats from "./fetchUserChats";
import fetchCreateChat from "./fetchCreateChat";
import {useNavigate} from "react-router-dom";
import {
  clearCurrentChatMessage, clearCheckedMessages,
  disconnectSocket,
  joinRoom,
  leaveRoom, checkMessage,
  startConnecting,
  submitMessage, startTyping, stopTyping, reactEventInvitation, deleteChat, deleteMessage, toggleIsCompany, readMessage
} from "./chatSlice";
import fetchChat from "./fetchChat";
import fetchCreateCompanyChat from "./fetchCreateCompanyChat";
import fetchCreateGroupChat from "./fetchCreateGroupChat";
import {CreateGroupChatPayload} from "./types";
import _ from "lodash";

export const getChat = (state: RootState) => state.chat

export function useChatState() {
  return useSelector(getChat)
}

export function useChatAction() {
  const {currentChat} = useChatState()
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const onStartConnecting = useCallback(() => {
    console.log('start connecting')
    dispatch(startConnecting())
  }, [dispatch])
  const onClearCheckedMessages = useCallback(() => {
    dispatch(clearCheckedMessages())
  }, [dispatch])
  const onCheckMessage = useCallback((id: string) => {
    dispatch(checkMessage(id))
  }, [dispatch])
  const onJoinRoom = useCallback((id: string) => {
    dispatch(joinRoom(id))
  }, [dispatch])
  const onLeaveRoom = useCallback((id: string) => {
    dispatch(leaveRoom(id))
  }, [dispatch])
  const onStartTyping = useCallback((id: string,) => {
    dispatch(startTyping({chatId: id}))
  }, [dispatch])
  const onStopTyping = useCallback((id: string,) => {
    dispatch(stopTyping({chatId: id}))
  }, [dispatch])
  const onDeleteChat = useCallback((id: string,) => {
    dispatch(deleteChat({chatId: id}))
    // dispatch(leaveRoom(id))
  }, [dispatch])
  const onReadMessage = useCallback((id: string,) => {
    dispatch(readMessage({messageId: id}))
  }, [dispatch])
  const onReactEventInvitation = useCallback((id: string, accept: boolean) => {
    dispatch(reactEventInvitation({messageId: id, accept}))
  }, [dispatch])
  const onSubmitMessage = useCallback((id: string, message?: string, file?: string, forwardMessageId?: string) => {
    dispatch(submitMessage({chatId: id, message, forwardMessageId, files: file ? [file] : []}))
  }, [dispatch])

  const onForwardMessages = useCallback((chatId: string, forwardMessageIds: string[]) => {
    if (currentChat) {
      const currentChatMessages = currentChat.messages
      const msgs = currentChatMessages.filter((msg) => _.includes(forwardMessageIds, msg.id))
      const ids = msgs.map(({forward, id}) => forward?.id || id)
      for (const forwardMessageId of ids) {
        onSubmitMessage(chatId, undefined, undefined, forwardMessageId)
      }
      onClearCheckedMessages()
    }
  }, [currentChat, onClearCheckedMessages, onSubmitMessage])
  const onDeleteMessages = useCallback((ids: string[]) => {
    for (const id of ids) {
      dispatch(deleteMessage({messageId: id}))
    }
    onClearCheckedMessages()
  }, [dispatch, onClearCheckedMessages])

  const onDisconnectSocket = useCallback(() => {
    dispatch(disconnectSocket())
  }, [dispatch])
  const onFetchUserChats = useCallback(() => {
    dispatch(fetchUserChats())
  }, [dispatch])
  const onToggleIsCompany = useCallback((val: boolean) => {
    dispatch(toggleIsCompany(val))
  }, [dispatch])
  const onClearCurrentChatMessage = useCallback(() => {
    dispatch(clearCurrentChatMessage())
  }, [dispatch])
  const onFetchCreateChat = useCallback((id: number) => {
    dispatch(fetchCreateChat(id))
      .unwrap()
      .then(chatId => {
        onFetchUserChats()
        navigate(`/chat/${chatId}`)
      })
  }, [dispatch, navigate, onFetchUserChats])
  const onFetchCreateGroupChat = useCallback((data: CreateGroupChatPayload) => {
    dispatch(fetchCreateGroupChat(data))
      .unwrap()
      .then(chatId => {
        onFetchUserChats()
        navigate(`/chat/${chatId}`)
      })
  }, [dispatch, navigate, onFetchUserChats])
  const onCreateCompanyChat = useCallback((id: number) => {
    dispatch(fetchCreateCompanyChat(id))
      .unwrap()
      .then(chatId => {
        onFetchUserChats()
        navigate(`/chat/${chatId}`)
      })
  }, [dispatch, navigate, onFetchUserChats])
  const onFetchChat = useCallback((id: string) => {
    dispatch(fetchChat(id))
  }, [dispatch])
  return useMemo(() => ({
    onFetchUserChats,
    onFetchCreateChat,
    onClearCurrentChatMessage,
    onFetchChat,
    onStartConnecting,
    onDisconnectSocket,
    onJoinRoom,
    onLeaveRoom,
    onSubmitMessage,
    onCreateCompanyChat,
    onClearCheckedMessages,
    onCheckMessage,
    onForwardMessages,
    onStopTyping,
    onReadMessage,
    onStartTyping,
    onReactEventInvitation,
    onDeleteChat,
    onDeleteMessages,
    onFetchCreateGroupChat,
    onToggleIsCompany,
  }), [
    onFetchUserChats,
    onFetchCreateChat,
    onClearCurrentChatMessage,
    onFetchChat,
    onStartConnecting,
    onDisconnectSocket,
    onJoinRoom,
    onLeaveRoom,
    onSubmitMessage,
    onCreateCompanyChat,
    onClearCheckedMessages,
    onReadMessage,
    onCheckMessage,
    onForwardMessages,
    onStopTyping,
    onStartTyping,
    onReactEventInvitation,
    onDeleteChat,
    onDeleteMessages,
    onFetchCreateGroupChat,
    onToggleIsCompany,
  ])
}
