import React, {useMemo} from 'react';
import {ETextVariants} from "../../UI/Text/types";
import Text from "../../UI/Text";
import {currencyDesc} from "../../config/currency";
import {useCartAction, useCartState} from "../../store/cart/hooks";
import {useNavigate} from "react-router-dom";
import {ServiceResponse} from "../../store/adminServices/types";
import styled from "styled-components";
import FormCountComponent from "./FormCountComponent";
import {T, useTranslate} from "@tolgee/react";


const Price = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;

  & > * {
    white-space: nowrap;
  }
`

const PriceTitle = styled(Text)`
  color: ${({theme}) => theme.colors.mainGray};
`

const RowForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const FormCountStyled = styled(FormCountComponent)`
  width: fit-content;
  justify-content: flex-start;
`
function DataOneForm({maxCustomers, customers, price, currency}:ServiceResponse) {
  const {t} = useTranslate()
  const {dataOnePayment} = useCartState()
  const {onChangeDataOnePayment} = useCartAction()
  const leftTickets = useMemo(() => maxCustomers - customers.length, [maxCustomers, customers])
  if (!dataOnePayment) return null
  return (
    <RowForm>
      <FormCountStyled value={dataOnePayment.quantity} label={t('common.tickets','Билеты')} onIncrement={() => onChangeDataOnePayment({
        serviceId: dataOnePayment.serviceId,
        quantity: (maxCustomers === 0 || dataOnePayment.quantity + 1 < leftTickets) ? dataOnePayment.quantity + 1 : dataOnePayment.quantity
      })}
                       onDecrement={() => {
                         if (dataOnePayment.quantity - 1 >= 1) {
                           onChangeDataOnePayment({
                             serviceId: dataOnePayment.serviceId,
                             quantity: dataOnePayment.quantity - 1
                           })
                         }
                       }}/>
      <Price>
        <PriceTitle variant={ETextVariants.Body16}><T keyName="payment.priceTitle">К оплате</T></PriceTitle>
        <Text
          variant={ETextVariants.Heading}>{dataOnePayment?.quantity * price}
          <T keyName={`currencies.${currency}.symbol`}>{currencyDesc[currency].symbol}</T>
        </Text>
      </Price>
    </RowForm>
  );
}

export default DataOneForm;
