import React, {ReactNode, useCallback, useEffect, useState} from 'react';
import {QrReader} from 'react-qr-reader';
import styled from 'styled-components';
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import DropdownCustom from "../../UI/Dropdown/DropdownCustom";
import {MastercardIcon} from "../../UI/Svg";
import {Button, EButtonVariants} from "../../UI/Button";
import {PurchaseApi} from "../../api/purchase";
import {handle} from "../../api";
import {T, useTranslate} from "@tolgee/react";

export interface IModal {
  onClose: () => void,
}

const WrapModal = styled.div(({theme}) => {
  return {
    position: 'fixed',
    width: '100%',
    height: '100%',
    zIndex: '100002',
    left: 0,
    top: 0,
    background: theme.colors.transparentGray,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
});

const WinModal = styled.div(({theme}) => {
  return {
    width: '100%',
    maxWidth: 270,
    top: 0,
    right: 0,
    overflowY: 'hidden',
    overflowX: 'hidden',
    background: theme.colors.white,
    zIndex: 2,
    borderRadius: 15,
    paddingTop: 20,
  };
});

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Title = styled(Text)`
  font-size: 17px;
  font-weight: bold;
`

const Content = styled.div`
  width: 100%;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const ConfirmBlock = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.35);
  padding: 11px 0;
  display: flex;
  justify-content: center;
  gap: 10px;
`

const ConfirmText = styled(Text)`
  color: ${({theme}) => theme.colors.mainPurple};
  font-size: 17px;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  padding: 4px 16px;
`

const ResultBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 6px;
`

const cards: Array<{ item: ReactNode | string, value: string }> = [
  {item: 'Back Camera', value: 'environment'},
  {item: 'Front Camera', value: 'user'},
]

function ModalQRReader({onClose}: IModal) {
  const {t} = useTranslate()
  const bodyMain = document.querySelector('body');
  const [error, setError] = useState<string | null>(null);
  const [cameraSelect, setCameraSelect] = useState<string>('environment');
  const [tickerId, setTickerId] = useState<string | null>(null);
  const [isStartScan, setIsStartScan] = useState<boolean>(false);

  useEffect(() => {
    return () => setError(null)
  }, [])

  const onStampTicket = useCallback(async (tickerId: string) => {
    setError(null)
    if (tickerId) {
      const [_, stampErr] = await handle(PurchaseApi.putStampTicket(tickerId))
      if (stampErr) {
        setError(stampErr.message || stampErr)
      }
    }
  }, [])

  useEffect(() => {
    if (bodyMain) bodyMain.style.overflow = 'hidden';

    return () => {
      if (bodyMain) bodyMain.style.overflow = 'auto';
    };
  }, [bodyMain]);
  return (
    <WrapModal>
      <WinModal>
        <Header>
          <Title variant={ETextVariants.Heading} as={'h2'}>
            <T keyName="qrReaderModal.title">QRCode Reader</T>
            </Title>
        </Header>
        <Content>
          <DropdownCustom label={t('qrReaderModal.selectCamera','Выбор камеры')} placeholder={t('qrReaderModal.placeholder','Камера...')} options={cards} value={cameraSelect}
                          onSelect={(val) => setCameraSelect(String(val))}/>
          {!isStartScan &&
              <Button $fullWidth variant={EButtonVariants.Primary} onClick={() => setIsStartScan(true)}>
                  <T keyName="qrReaderModal.scan">Сканировать</T>
              </Button>}
          {(isStartScan && !tickerId) ? <QrReader
            constraints={{
              facingMode: cameraSelect, width: 270
            }}
            scanDelay={1000}
            onResult={(result, error) => {
              if (!!result) {
                setTickerId(result.getText());
                onStampTicket(result.getText())
              }

              if (!!error) {
                console.info(error);
              }
            }}
          /> : tickerId && <ResultBlock>
              <ConfirmText variant={ETextVariants.Body16}>
                {tickerId}
              </ConfirmText>
              <Button $fullWidth variant={EButtonVariants.Primary} onClick={() => {
                setTickerId(null)
                setIsStartScan(true)
              }}>
                  <T keyName="qrReaderModal.scanNext">Сканировать следующий</T></Button>
          </ResultBlock>}
          {error && <Text variant={ETextVariants.Error}>
            {error}
          </Text>}
        </Content>
        <ConfirmBlock>
          <ConfirmText variant={ETextVariants.Body16} onClick={onClose}>
            <T keyName="common.close">Закрыть</T>
          </ConfirmText>
        </ConfirmBlock>
      </WinModal>
    </WrapModal>
  );
}

export default ModalQRReader;
