import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled from "styled-components";
import {Container} from "../../components/Templates/Container";
import Input from "../../UI/Input";
import {useProfileAction, useProfileState} from "../../store/profile/hooks";
import {useNavigate, useParams} from "react-router-dom";
import {FilterIcon, GeoIcon, SearchIcon} from "../../UI/Svg";
import {useTranslate} from "@tolgee/react";
import LoadingProfileEvents from "../../views/ProfileViews/LoadingProfileEvents";
import ProfileEvent from "../../components/ProfileComponents/ProfileEvent";
import HomeMap from "../home/HomeMap";
import {ContainerMax} from "../../components/Templates/ContainerMax";

const Wrapper = styled(ContainerMax)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 30px;
  flex-direction: column;

  ${({theme}) => theme.mediaQueries.md} {
    gap: 0;
  }
`
const Settings = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  gap: 16px;
  flex: 1;
  width: 100%;
`

const Search = styled.div`
  display: flex;
  flex: 1;
  color: ${({theme}) => theme.colors.white};

  ${({theme}) => theme.mediaQueries.md} {
    background-color: ${({theme}) => theme.colors.white};
    padding: 10px 16px 16px;
  }
`

const InputStyled = styled(Input)`
  background: ${({theme}) => theme.colors.white};
  height: 56px;

  ${({theme}) => theme.mediaQueries.md} {
    background: ${({theme}) => theme.colors.bg};
    height: 40px;
  }
`

const ButtonIcon = styled.button`
  width: 56px;
  height: 56px;
  border-radius: 10px;
  border: none;
  background: ${({theme}) => theme.colors.white};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;

  &:active {
    opacity: .5;
  }

  svg {
    width: 24px;
    height: 24px;
  }

  ${({theme}) => theme.mediaQueries.md} {

    position: absolute;
    right: 16px;
    top: 11px;
    width: 32px;
    height: 32px;
  }
`

const List = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;
  flex-wrap: wrap;

  ${({theme}) => theme.mediaQueries.md} {
    justify-content: center;
  }
`

function ProfileEvents() {
  const navigate = useNavigate();
  const {t} = useTranslate();
  const [search, setSearch] = useState<string>('');
  const [isMap, setIsMap] = useState<boolean>(false);

  const {onFetchProfileById} = useProfileAction()
  const {isLoadingEvents, events} = useProfileState()
  const {userId} = useParams()

  const data = useMemo(() => {
    return events.filter((event) => event.title.toLowerCase().includes(search.toLowerCase()), [events, search])
  }, [events, search])

  useEffect(() => {
    if (userId !== undefined) {
      onFetchProfileById(userId)
    }
  }, [onFetchProfileById, userId])

  const goToMap = useCallback(() => {
    setIsMap(prev => !prev);
  }, []);
  return (
    <Wrapper>
      <Settings>
        <Search>
          <InputStyled
            placeholder={t('common.search', 'Поиск')}
            onChange={(e) => setSearch(e.target.value)}
            wth="100%"
            value={`${search}`}
            icon={
              <SearchIcon/>
            }
          />
        </Search>
        <ButtonIcon onClick={goToMap}>
          <GeoIcon/>
        </ButtonIcon>
      </Settings>
      <List>
        {
          isLoadingEvents
            ? <LoadingProfileEvents/>
            : isMap ? <HomeMap
              eventsData={data}
              position={[55.751244, 37.618423]}
              center={[55.751244, 37.618423]}
            /> : data.map((item) => <ProfileEvent key={item.id} {...item}/>)
        }
      </List>
    </Wrapper>
  );
}

export default ProfileEvents;
