import styled from "styled-components";
import Input from "../Input";
import {getTextStyle} from "../Text/utils";
import {ETextVariants} from "../Text/types";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
`

export const BlockValue = styled.div`
  width: 100%;
  position: relative;
  padding: 1rem 2rem;
  border-radius: 10px;
  background-color: transparent;
  border: 2px solid ${({theme}) => theme.colors.bg};
`

export const DropdownBlock = styled.div`
  position: absolute;
  bottom: -2px;
  transform: translateY(100%);
  left: 0;
  right: 0;
  z-index: 100;
`

export const DropdownInput = styled(Input)`
  width: 100%;
`

export const IconButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;

  svg {
    width: 10px;
    height: 10px;
  }
`

export const DropdownMenu = styled.div`
  width: 100%;
  margin-top: 4px;
  max-height: 258px;
  padding: 4px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0;
  border: 2px solid ${({theme}) => theme.colors.bg};

  box-shadow: 0 1px 24px 0 rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  background: ${({theme}) => theme.colors.white};
  overflow: auto;
`

export const DropdownWrapper = styled.div`
  width: 100%;
  position: relative;
  cursor: pointer;
`

export const DropdownItem = styled.div<{ $isSelected: boolean }>`
  padding: 15px 16px;
  ${({theme}) => getTextStyle(theme, ETextVariants.Body16)};
  cursor: pointer;
  width: 100%;
  background: ${({theme, $isSelected}) => $isSelected ? theme.colors.bg : theme.colors.white};

  &:hover {
    background: ${({theme}) => theme.colors.bg};
  }
`
