import React, {useCallback, useEffect} from 'react';
import styled from 'styled-components';
import {Container} from "../../components/Templates/Container";
import {ETextVariants} from "../../UI/Text/types";
import Text from "../../UI/Text";
import FormEventView from "../../views/CreateViews/FormEventView";
import {Card} from "../../components/Templates/Card";
import {useEventsAction, useEventsState} from "../../store/events/hooks";
import {useNavigate, useParams} from "react-router-dom";
import {T} from "@tolgee/react";
import {useProfileState} from "../../store/profile/hooks";

const ContainerStyled = styled(Container)`
`

const CardStyled = styled(Card)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  flex-direction: column;
  gap: 30px;
`

function EditEvent() {

  const navigate = useNavigate()
  const {id} = useParams()
  const {onFetchEvent} = useEventsAction()
  const {profile} = useProfileState()
  const {currentEvent, isLoadingEvent} = useEventsState()

  const fetchEventHandler = useCallback(() => {
    if (typeof id !== "undefined") {
      onFetchEvent(id)
    }
  }, [id, onFetchEvent])

  useEffect(() => {
    fetchEventHandler()
  }, [fetchEventHandler])

  useEffect(() => {
    if (profile && currentEvent?.author.id !== profile.id) {
      navigate('-1')
    }
  }, [profile, currentEvent, navigate])
  return (
    <ContainerStyled>
      <CardStyled>
        <Text variant={ETextVariants.Heading} as={'h2'}>
          <T keyName="common.editing">Редактирование</T>
        </Text>
        {(!isLoadingEvent && currentEvent) &&
            <FormEventView callback={fetchEventHandler} isCreate={false} initialData={currentEvent}/>}
      </CardStyled>
    </ContainerStyled>
  );
}

export default EditEvent;
