import React, {createContext, useMemo, useReducer} from "react";
import {UserEntity} from "../types";

interface ProfileState {
  profile: UserEntity | null
}

const initialState: ProfileState = {
  profile: null
}

export const themeReducer = (state: ProfileState, action: any) => {
  switch (action.type) {
    case 'setProfile' :
      return {
        ...state,
        profile: action.payload
      };
    case 'removeProfile' :
      return {
        ...state,
        profile: null
      };
    default:
      return state;
  }
};

export const ProfileContext = createContext<{
  profile: UserEntity | null,
  onSetProfile: (profile: UserEntity) => void,
  onRemoveProfile: () => void,
}>({
  profile: null,
  onSetProfile: (profile: UserEntity) => {},
  onRemoveProfile: () => {},
});

export default function ProfileProvider({children}: any) {
  const [profile, dispatch] = useReducer(themeReducer, {...initialState});
const onSetProfile = (_profile: UserEntity) => {
    dispatch({type: "setProfile", payload: _profile})
  };
const onRemoveProfile = () => {
    dispatch({type: "removeProfile"})
  };

  const ProviderValue = useMemo(() => ({
    profile: profile.profile,
    onSetProfile,
    onRemoveProfile,
  }), [profile.profile])

  return (
    <ProfileContext.Provider
      value={ProviderValue}
    >
      {children}
    </ProfileContext.Provider>
  )

}
