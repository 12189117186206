import React, {useMemo} from 'react';
import {useEventsState} from "../../store/events/hooks";
import {useLocation, useSearchParams} from "react-router-dom";
import {Marker} from "react-leaflet";
import {EventResponse} from "../../store/events/types";
import PopupMarker from "./PopupMarker";
import {ServiceResponse} from "../../store/adminServices/types";
import {useServicesState} from "../../store/services/hooks";
import PopupMarkerService from "./PopupMarkerService";
import L from "leaflet";
import markerPurple from "../../assets/img/marker-color.png";
import markerGray from "../../assets/img/marker-gary.png";

interface MapMarkersProps {
  activeMarker: string | null;
  handlerMarker: (id: string | null) => void;
  servicesData?: ServiceResponse[]
  setActiveMarker: React.Dispatch<React.SetStateAction<string | null>>;
  checkedEvent: React.MutableRefObject<any>;
}


export const iconPurple = L.icon({
  iconUrl: markerPurple,
  popupAnchor: [210, 60],
});

export const iconGray = L.icon({
  iconUrl: markerGray,
  popupAnchor: [210, 60],
});

function MapMarkers({
                      activeMarker,
                      handlerMarker,
                      setActiveMarker,
                      checkedEvent,
                      servicesData,
                    }: MapMarkersProps) {
  const {all} = useServicesState()
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams()
  const searchEventId = useMemo(() => {
    const isEvent = searchParams.get('is-event')
    const eventId = searchParams.get('id')
    if (isEvent === 'false' && eventId !== undefined) {
      return eventId
    }
    return null
  }, [searchParams])
  const services = useMemo(() => servicesData || all, [servicesData, all])
  return( <>{services?.filter((item) => !!item.address).map((item: ServiceResponse) => (
    <Marker
      key={item.id}
      position={[parseFloat(item.address?.latitude || '0'), parseFloat(item.address?.longitude || '0')]}
      icon={activeMarker ? activeMarker === `${item.id}` ? iconPurple : iconGray : iconPurple}
      ref={searchEventId === String(item.id) ? checkedEvent : null}
      eventHandlers={{click: () => handlerMarker(activeMarker === `${item.id}` ? null : `${item.id}`), popupclose: () => setActiveMarker(null)}}
    >
      <PopupMarkerService item={item}/>
    </Marker>
  ))}</>)
}

export default MapMarkers;
