import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {UserApi} from "../../api/user";
import {UserEntity} from "../../types";

const fetchUsers = createAsyncThunk<UserEntity[],
  void,
  {
    state: RootState
  }>(
  'users/fetchUsers',
  async (_, ThunkApi) => {
    const authState = ThunkApi.getState().auth
    const {loginSearch} = ThunkApi.getState().users
    if (authState.auth) {
      const [usersRes, usersErr] = await handle(UserApi.getUsersByLogin(loginSearch))
      if (usersRes) {
        return usersRes
      }
      if (usersErr) {
        throw {message: usersErr.message[0] || usersErr.message}
      }
    }
    throw {message: 'No user state'}
  }
)

export default fetchUsers
