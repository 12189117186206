import styled from "styled-components";
import {ButtonProps} from "./types";
import {getTextStyle} from "../Text/utils";
import {ETextVariants} from "../Text/types";


const Button = styled.button<ButtonProps>`
  ${({theme}) => getTextStyle(theme, ETextVariants.Button)};
  justify-content: center;
  gap: 10px;
  background-color: ${({theme, variant}) => theme.button[variant].backgroundColor};
  padding: 1rem 2rem;
  border-radius: 10px;
  display: flex;
  border: ${({theme, variant}) => theme.button[variant].border};
  cursor: pointer;
  width: ${({$fullWidth}) => $fullWidth ? "100%" : "auto"};
  color: ${({theme, variant}) => theme.button[variant].color};
  text-align: center;
  &:hover {
    background-color: ${({theme, variant}) => theme.button[variant].hover.backgroundColor};
    color: ${({theme, variant}) => theme.button[variant].hover.color};
  }
  &:active {
    background-color: ${({theme, variant}) => theme.button[variant].active.backgroundColor};
    color: ${({theme, variant}) => theme.button[variant].active.color};
    transform: translateY(2px);
  }
  &:disabled {
    cursor: not-allowed;
    background-color: ${({theme, variant}) => theme.button[variant].disabled.backgroundColor};
    color: ${({theme, variant}) => theme.button[variant].disabled.color};
  }
  
  & > * {
    z-index: 1;
  }

  svg path {
    transition: all .25s ease;
  }

  &:not(:disabled):hover {
    svg path {
    }
  }

`;

export default Button
