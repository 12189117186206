import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="20" height="24" viewBox="0 0 20 20" fill="none" {...props} >
          <path d="M14.9994 13.1974C16.2126 13.8069 17.2528 14.785 18.0121 16.008C18.1624 16.2502 18.2376 16.3713 18.2636 16.539C18.3164 16.8798 18.0834 17.2988 17.766 17.4336C17.6098 17.5 17.4341 17.5 17.0827 17.5M13.3327 9.6102C14.5675 8.99657 15.416 7.72238 15.416 6.25C15.416 4.77762 14.5675 3.50343 13.3327 2.8898M11.666 6.25C11.666 8.32107 9.98711 10 7.91604 10C5.84497 10 4.16604 8.32107 4.16604 6.25C4.16604 4.17893 5.84497 2.5 7.91604 2.5C9.98711 2.5 11.666 4.17893 11.666 6.25ZM2.13207 15.782C3.46066 13.7871 5.55719 12.5 7.91604 12.5C10.2749 12.5 12.3714 13.7871 13.7 15.782C13.9911 16.219 14.1366 16.4375 14.1198 16.7166C14.1068 16.9339 13.9643 17.2 13.7907 17.3313C13.5676 17.5 13.2609 17.5 12.6474 17.5H3.18467C2.57118 17.5 2.26444 17.5 2.04141 17.3313C1.86775 17.2 1.72528 16.9339 1.71224 16.7166C1.69548 16.4375 1.84101 16.219 2.13207 15.782Z" stroke="#6C27AB" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
        </Svg>
    );

export default Icon;
