import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {SubscriptionApi} from "../../api/subscription";


const fetchUnsubscribe = createAsyncThunk<void,
  void,
  {
    state: RootState
  }>(
  'profile/fetchUnsubscribe',
  async (_, ThunkApi) => {
    const authState = ThunkApi.getState().auth
    const {profileOther} = ThunkApi.getState().profile
    if (authState.auth && profileOther?.id) {
      const [, subscribeErr] = await handle(SubscriptionApi.delSubscribe(profileOther.id))
      if (subscribeErr) {
        throw {message: subscribeErr.message[0] || subscribeErr.message}
      }
      return
    }
    throw {message: 'No user state'}
  }
)

export default fetchUnsubscribe
