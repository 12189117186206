import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {CompanyApi} from "../../api/company";

const fetchDeleteCompany = createAsyncThunk<void,
  number,
  {
    state: RootState
  }>(
  'adminCompanies/fetchDeleteCompany',
  async (id, ThunkApi) => {
    const authState = ThunkApi.getState().auth
    if (authState.auth) {
      const [companyRes, companyErr] = await handle(CompanyApi.deleteCompany(id))
      if (companyRes) {
        return companyRes
      }
      if (companyErr) {
        throw {message: companyErr.message[0] || companyErr.message}
      }
      return
    }
    throw {message: 'No phone number'}
  }
)

export default fetchDeleteCompany
