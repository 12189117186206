import {ServiceResponse} from "../adminServices/types";
import {UserEntity} from "../../types";
import {ICompany, IUserCompany} from "../company/types";

export enum EPaymentMethod {
  Cash = 'cash',
  Online = 'online',
}

export enum EPaymentTarget {
  Self = "self",
  Gift = "gift",
}

export enum EPaymentStatus {
  Pending = "pending",
  AwaitCash = "awaitCash",
  Paid = "paid",
  Cancelled = "cancelled",
}

export interface ICartItem {
  id: number,
  quantity: number,
  favor: ServiceResponse,
}
export interface IPayment {
  id: string;
  total: number;
  target: EPaymentTarget;
  method: EPaymentMethod;
  status: EPaymentStatus;
  expiresOn: string;
  createdAt: string;
  user: UserEntity;
  targetUser: UserEntity;
  items: ICartItem[];
  company: IUserCompany;
}

export enum ETicketStatus {
  AwaitCash = "awaitCash",
  Available = "available",
  Used = "used",
}

export interface ITicket {
  id: number;
  user: UserEntity;
  favor: ServiceResponse;
  status: ETicketStatus;
  payment: IPayment;
  createdAt: string;
}
