import React from 'react';
import {useNavigate} from 'react-router-dom';
import {EventResponse} from "../../store/events/types";
import {Popup} from 'react-leaflet';
import styled from "styled-components";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {CalendarIcon, GeoGrayIcon, SmileIcon} from "../../UI/Svg";
import dayjs from "dayjs";
import 'dayjs/locale/ru';
import {T} from "@tolgee/react";
import {mediaUrl} from "../../api";

const EventImage = styled.div`
  width: 97px;
  height: 113px;
  border-radius: 10px;
  max-height: 121px;
  margin-right: 14px;
  overflow: hidden;
  display: flex;
  justify-content: center;

  img {
    height: 100%;
    width: auto;
  }
`

const Title = styled(Text)`
  max-height: 40px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`
const List = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
`

const Item = styled.li`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;

  svg {
    min-width: 24px;
    height: 24px;
  }
`

const ItemText = styled(Text)`
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  min-width: 150px;
`

const Members = styled(ItemText)`
  white-space: pre-wrap;
  color: ${({theme}) => theme.colors.mainPurple};
`


const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
  overflow: hidden;
  min-width: 200px;
  ${({theme}) => theme.mediaQueries.md} {
    max-width: 150px;
    min-width: unset;
  }
`


function PopupMarker({item}: { item: EventResponse }) {
  const navigate = useNavigate();
  const goToEvents = (id: string): void => {
    navigate(`/about-the-event/${id}`);
  };

  return (
    <div onClick={() => goToEvents(`${item.id}`)}>
      <Popup closeButton={false} minWidth={200}>
        <EventImage>
          <img src={item.photo ? `${mediaUrl}file/${item.photo.id}` : ''} alt=""/>
        </EventImage>
        <Content>
          <Title variant={ETextVariants.Body16}>{item.title}</Title>
          <List>
            <Item>
              <CalendarIcon/>
              <ItemText
                variant={ETextVariants.Body16}> {dayjs(item.date).format('D MMMM / HH:mm')}</ItemText>
            </Item>
            <Item>
              <GeoGrayIcon/>
              <ItemText variant={ETextVariants.Body16}>{item.address.fullString}</ItemText>
            </Item>
            <Item>
              <SmileIcon/>
              <Members variant={ETextVariants.Body16}>
                <T keyName="event.numberOfMembers2Short"
                   params={{numberOfMembers: item.members.length}}
                   defaultValue={`${item.members.length} участников`}
                />
              </Members>
            </Item>
          </List>
        </Content>
      </Popup>
    </div>
  );
}

export default PopupMarker;
