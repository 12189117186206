import React from 'react';
import AdminHeader from "../../../components/AdminComponents/AdminHeader";
import styled from "styled-components";
import {Link} from "react-router-dom";
import Text from "../../../UI/Text";
import {ETextVariants} from "../../../UI/Text/types";
import ServicesFilterView from "../../../views/AdminServicesViews/ServicesFilterView";
import ServicesListView from "../../../views/AdminServicesViews/ServicesListView";
import {useAdminServices, useAdminServicesState} from "../../../store/adminServices/hooks";
import LoadingSpinner from "../../../UI/LoadingSpinner";
import {useTranslate} from "@tolgee/react";

const LinkStyled = styled(Link)`
  ${Text} {
    text-decoration: underline;
    color: ${({theme}) => theme.colors.mainPurple};
  }
`

function Index() {
  const {t} = useTranslate()
  useAdminServices()
  const {isLoading} = useAdminServicesState()
  return (
    <>
      <AdminHeader title={t('common.services','Услуги')}/>
      <ServicesFilterView/>
      {
        isLoading
          ? <LoadingSpinner/>
          : <ServicesListView/>
      }
    </>
  );
}

export default Index;
