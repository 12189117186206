import React from 'react';
import styled from "styled-components";
import {T} from "@tolgee/react";

const Wrapper = styled.div`
  width: 100%;
  max-width: 100%;
  overflow: auto;
`

const Table = styled.table<{$countColumns: number}>`
  width: 100%;

  thead {
    collapse: n;

    tr {
      border-top: 1px solid #717171;
      &:last-child {
        border-bottom: 1px solid #717171;
      }
    }

    th {
      padding: 16px 10px;

      width: calc(${({$countColumns}) => 100 / $countColumns}%);
      color: ${({theme}) => theme.colors.black};
      font-weight: bold;
      text-align: left;
      font-size: 16px;

      &:first-child {
        padding-left: 40px;
        text-align: center;
        padding-right: 40px;
        width: 100px;
      }

      &:last-child {
        padding-right: 40px;
      }
    }
  }
  tr {
    border-top: 1px solid #DDDDDD;
    &:last-child {
      border-bottom: 1px solid #DDDDDD;
    }
  }

  tbody {
    tr {
      
    }

    td {
      font-size: 16px;
      width: calc(${({$countColumns}) => 100 / $countColumns}%);
      padding: 16px 10px;
      text-align: left;
      color: ${({theme}) => theme.colors.black};

      &:first-child {
        padding-left: 40px;
        width: 100px;
        text-align: center;
        padding-right: 40px;
      }

      &:last-child {
        padding-right: 40px;
      }
    }
  }
`

const ActionCell = styled.td`
  white-space: nowrap;
  width: unset;
`

const ActionBlock = styled.span`
  cursor: pointer;
  color: ${({theme}) => theme.colors.mainPurple};
  font-size: 16px;
  text-decoration: underline;
  margin-right: 80px;
`

function Index({heading, data, actions}: {heading: Array<{key: string, value: string}>, data: Array<{[key: string]: any}>, actions?: Array<{title: string, callback: (...props: any) => void, dataKey?: string}>}) {
  return (
    <Wrapper>
      <Table $countColumns={heading.length}>
        <thead>
        <tr>
          {
            heading.map((head, id) => <th key={id}>{head.value}</th>)
          }
          {actions && <th><T keyName="common.actions">Действия</T></th>}
        </tr>
        </thead>
        <tbody>
          {
            data.map((item, id) => (
              <tr key={`Row-${id}`}>
                {
                  heading.map((head, kid) => <td key={`Row-${id}-${kid}`}>{item[head.key]}</td>)
                }
                {actions && <ActionCell>
                  {actions.map((action, aid) => (
                    <ActionBlock key={`Row-${id}-${aid}`} onClick={() => {
                      if (action.dataKey) {
                        action.callback(item[action.dataKey])
                      } else {
                        action.callback()
                      }
                    }}>{action.title}</ActionBlock>
                  ))}
                </ActionCell>}
              </tr>
            ))
          }
        </tbody>
      </Table>
    </Wrapper>
  );
}

export default Index;
