import styled from "styled-components";

export const Col = styled.div<{ span?: number }>`
  position: relative;
  flex: ${({span}) => span ? '0 0 auto' : '1 0 0%'};
  width: ${({span}) => span ? `${(span / 12) * 100}%` : '100%'};
  max-width: 100%;
  //padding-left: calc(2rem / 2);
  //padding-right: calc(2rem / 2);
`
