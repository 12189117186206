import {useDispatch, useSelector} from "react-redux";
import {useCallback, useEffect, useMemo} from "react";
import {AppDispatch, RootState} from "../index";
import {changeFilter, changePage, removeCurrentEvent, resetFilter} from "./eventsSlice";
import {IEventsFilter} from "./types";
import fetchEvents from "./fetchEvents";
import fetchCategories from "./fetchCategories";
import fetchEvent from "./fetchEvent";
import fetchJoin from "./fetchJoin";
import fetchLeave from "./fetchLeave";
import fetchCreateCategory from "./fetchCreateCategory";
import {useGeoAction} from "../geo/hooks";
import fetchUpdateCategory from "./fetchUpdateCategory";
import fetchDeleteCategory from "./fetchDeleteCategory";

export const getEvents = (state: RootState) => state.events

export function useEventsAction() {
  const dispatch = useDispatch<AppDispatch>()

  const onChangePage = useCallback(() => {
    dispatch(changePage())
  }, [dispatch])
  const onFetchEvents = useCallback(() => {
    dispatch(fetchEvents())
  }, [dispatch])
  const onFetchEvent = useCallback((id: string) => {
    dispatch(fetchEvent(id))
  }, [dispatch])

  const onRemoveCurrentEvent = useCallback(() => {
    dispatch(removeCurrentEvent())
  }, [dispatch])

  const onFetchCategories = useCallback(() => {
    dispatch(fetchCategories())
  }, [dispatch])

  const onChangeFilter = useCallback((data: IEventsFilter) => {
    dispatch(changeFilter(data))
  }, [dispatch])

  const onResetFilter = useCallback((data?: IEventsFilter) => {
    dispatch(resetFilter(data))
  }, [dispatch])

  const onJoinEvent = useCallback((id: string) => {
    dispatch(fetchJoin(id))
      .unwrap()
      .then(() => {
        onFetchEvent(id)
      })
  }, [dispatch, onFetchEvent])

  const onCreateCategory = useCallback((title: string) => {
    dispatch(fetchCreateCategory(title))
      .unwrap()
      .then(() => {
        onFetchCategories()
      })
  }, [dispatch, onFetchCategories])

  const onUpdateCategory = useCallback((id: number, title: string) => {
    dispatch(fetchUpdateCategory({id, title}))
      .unwrap()
      .then(() => {
        onFetchCategories()
      })
  }, [dispatch, onFetchCategories])

  const onDeleteCategory = useCallback((id: number) => {
    dispatch(fetchDeleteCategory(id))
      .unwrap()
      .then(() => {
        onFetchCategories()
      })
  }, [dispatch, onFetchCategories])

  const onLeaveEvent = useCallback((id: string) => {
    dispatch(fetchLeave(id)).unwrap()
      .then(() => {
        onFetchEvent(id)
      })
  }, [dispatch, onFetchEvent])

  return useMemo(() => ({
    onChangeFilter,
    onResetFilter,
    onFetchEvents,
    onFetchCategories,
    onFetchEvent,
    onRemoveCurrentEvent,
    onJoinEvent,
    onLeaveEvent,
    onCreateCategory,
    onChangePage,
    onUpdateCategory,
    onDeleteCategory,
  }), [
    onChangeFilter,
    onResetFilter,
    onFetchEvents,
    onFetchCategories,
    onFetchEvent,
    onRemoveCurrentEvent,
    onJoinEvent,
    onLeaveEvent,
    onCreateCategory,
    onChangePage,
    onUpdateCategory,
    onDeleteCategory,
  ])
}

export function useEventsState() {
  return useSelector(getEvents)
}

export function useEvents() {
  const {filter} = useEventsState()
  const {onFetchEvents} = useEventsAction()
  const {onFetchCities, onClearCities} = useGeoAction()
  useEffect(() => {
    onFetchEvents()
  }, [filter, onFetchEvents])
  useEffect(() => {
    if (filter.country) {
      onFetchCities(filter.country.toLowerCase())
    } else {
      onClearCities()
    }
    return () => onClearCities()
  }, [filter.country])
}
