import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {AuthApi} from "../../api/auth";
import {UserEntity} from "../../types";

const fetchLogin = createAsyncThunk<{ user: UserEntity, token: string },
  string,
  {
    state: RootState
  }>(
  'auth/fetchLogin',
  async (code, ThunkApi) => {
    const authState = ThunkApi.getState().auth
    if (authState.auth) {
      const [authRes, authErr] = await handle(AuthApi.postLogin({code: parseInt(code), phoneNumber: authState.auth.phoneNumber}))
      if (authRes) {
        return authRes
      }
      if (authErr) {
        throw {message: authErr.message}
      }
    }
    throw {message: 'No phone number'}
  }
)

export default fetchLogin
