import styled from "styled-components";
import {Card} from "../../components/Templates/Card";
import Text from "../../UI/Text";
import Input from "../../UI/Input";
import ReactTextareaAutosize from "react-textarea-autosize";
import pngChecked from "../../assets/img/checked.svg";
import HomeMap from "../../app/home/HomeMap";

export const HomeMapStyled = styled(HomeMap)`
  padding: 0;
`

export const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: 100%;
`

export const InpuRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
`

export const ErrorForm = styled(Text)`

`

export const ImagePreview = styled.img`
  width: 100%;
  height: auto;
`

export const InputStyled = styled(Input)`
  background: ${({theme}) => theme.colors.white};
  height: 45px;
  border: 2px solid ${({theme}) => theme.colors.bg};
`

export const UploadStyled = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  gap: 10px;
  background: ${({theme}) => theme.colors.bg};

  svg {
    width: 20px;
    height: 20px;

  }
`

export const UploadText = styled(Text)`
  color: ${({theme}) => theme.colors.mainPurple};
  cursor: pointer;
`

export const FileTitle = styled(Text)`
  display: flex;
  justify-content: center;
`

export const TAMini = styled(Text)`
  position: absolute;
  z-index: 5;
  right: 20px;
  bottom: 6px;
`

export const TextAreaStyled = styled(ReactTextareaAutosize)`
  resize: none;
  padding: 12px 16px;
  border-radius: 12px;
  background: ${({theme}) => theme.colors.white};
  border: 2px solid ${({theme}) => theme.colors.bg};
  color: ${({theme}) => theme.colors.black};
  font-size: 14px;
`


export const StyledCheckbox = styled.input(() => {
  return {
    position: 'absolute',
    zIndex: -1,
    opacity: 0,
    '&:checked + label:before': {
      background: `url(${pngChecked}) center/cover no-repeat`,
    },
  };
});

export const Label = styled(Text)`
  display: inline-flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  user-select: none;
  cursor: pointer;
  &:before {
    content: '';
    display: inline-block;
    width: 24px;
    height: 24px;
    min-width: 24px;
    margin-right: 10px;
    border-radius: 50%;
    border: 1px solid ${({theme}) => theme.colors.lightGray};
  }
`

export const DeleteHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  background: ${({theme}) => theme.colors.bg};
  cursor: pointer;
`

