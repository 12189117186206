import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IPhoto, Option, UserEntity} from "../../types";
import {IInterest, ProfileEntity} from "./types";
import fetchUpdateProfile from "./fetchUpdateProfile";
import fetchProfile from "./fetchProfile";
import fetchSubscribe from "./fetchSubscribe";
import fetchUnsubscribe from "./fetchUnsubscribe";
import fetchProfileById from "./fetchProfileById";
import {EventResponse} from "../events/types";
import fetchMyEventsCreated from "./fetchMyEventsCreated";
import fetchProfileData from "./fetchProfileData";
import fetchInterests from "./fetchInterests";
import {ICompany} from "../company/types";
import fetchUnsubscribeCompany from "./fetchUnsubscribeCompany";
import fetchSubscribeCompany from "./fetchSubscribeCompany";

export interface ProfileState {
  profile: Option<ProfileEntity>;
  profileOther: Option<ProfileEntity>;
  events: EventResponse[];
  isUpdateLoading: boolean
  isLoading: boolean
  isLoadingEvents: boolean
  isSendData: boolean,
  subscribers: ProfileEntity[],
  subscriptions: ProfileEntity[],
  subscriptionsCompanies: ICompany[],
  photos: IPhoto[],
  interests: IInterest[]
  updateError: string | null
}

const initialState: ProfileState = {
  profile: null,
  profileOther: null,
  isLoading: true,
  isUpdateLoading: false,
  events: [],
  isLoadingEvents: true,
  isSendData: false,
  subscribers: [], subscriptions: [], subscriptionsCompanies: [], photos: [],
  interests: [],
  updateError: null
}

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    clearProfile: (state) => {
      state.profileOther = null
    },
    clearError: (state) => {
      state.updateError = null
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchUpdateProfile.pending, (state) => {
      state.isUpdateLoading = true
    })
    builder.addCase(fetchUpdateProfile.rejected, (state) => {
      state.isUpdateLoading = false
    })
    builder.addCase(fetchUpdateProfile.fulfilled, (state, action: PayloadAction<{profile: ProfileEntity, error: string | null }>) => {
      state.profile = action.payload.profile
      state.updateError = action.payload.error
      state.isUpdateLoading = false
    })
    builder.addCase(fetchInterests.fulfilled, (state, action: PayloadAction<IInterest[]>) => {
      state.interests = action.payload
    })
    builder.addCase(fetchUnsubscribe.pending, (state) => {
      state.isSendData = true
    })
    builder.addCase(fetchUnsubscribe.fulfilled, (state) => {
      state.isSendData = false
    })
    builder.addCase(fetchUnsubscribe.rejected, (state) => {
      state.isSendData = false
    })
    builder.addCase(fetchSubscribe.pending, (state) => {
      state.isSendData = true
    })
    builder.addCase(fetchSubscribe.fulfilled, (state) => {
      state.isSendData = false
    })
    builder.addCase(fetchSubscribe.rejected, (state) => {
      state.isSendData = false
    })
    builder.addCase(fetchUnsubscribeCompany.pending, (state) => {
      state.isSendData = true
    })
    builder.addCase(fetchUnsubscribeCompany.fulfilled, (state) => {
      state.isSendData = false
    })
    builder.addCase(fetchUnsubscribeCompany.rejected, (state) => {
      state.isSendData = false
    })
    builder.addCase(fetchSubscribeCompany.pending, (state) => {
      state.isSendData = true
    })
    builder.addCase(fetchSubscribeCompany.fulfilled, (state) => {
      state.isSendData = false
    })
    builder.addCase(fetchSubscribeCompany.rejected, (state) => {
      state.isSendData = false
    })
    builder.addCase(fetchProfile.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchProfile.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(fetchProfile.fulfilled, (state, action: PayloadAction<ProfileEntity>) => {
      state.profile = action.payload
      state.isLoading = false
    })
    builder.addCase(fetchProfileById.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchProfileById.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(fetchProfileById.fulfilled, (state, action: PayloadAction<ProfileEntity>) => {
      state.profileOther = action.payload
      state.isLoading = false
    })
    builder.addCase(fetchMyEventsCreated.pending, (state) => {
      state.isLoadingEvents = true
    })
    builder.addCase(fetchMyEventsCreated.rejected, (state) => {
      state.isLoadingEvents = false
    })
    builder.addCase(fetchMyEventsCreated.fulfilled, (state, action: PayloadAction<EventResponse[]>) => {
      state.events = action.payload
      state.isLoadingEvents = false
    })
    builder.addCase(fetchProfileData.pending, (state) => {
      state.isLoadingEvents = true
    })
    builder.addCase(fetchProfileData.rejected, (state) => {
      state.isLoadingEvents = false
    })
    builder.addCase(fetchProfileData.fulfilled, (state, action: PayloadAction<{ subscribers: UserEntity[], subscriptionsCompanies: ICompany[],subscriptions: UserEntity[], photos: IPhoto[], events: EventResponse[] }>) => {
      state.subscribers = action.payload.subscribers
      state.subscriptions = action.payload.subscriptions
      state.events = action.payload.events
      state.photos = action.payload.photos
      state.isLoadingEvents = false
    })
  }
})

export const {clearProfile, clearError} = profileSlice.actions
export default profileSlice.reducer
