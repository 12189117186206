import {axiosInstance} from "./index";
import {CreateGroupChatPayload} from "../store/chat/types";

export const ChatApi = {
    getUserChats: async () => await axiosInstance.get('chat/userChats'),
    getCompanyChats: async (id: number) => await axiosInstance.get(`chat/companyChats/${id}`),
    getDetailChat: async (id: string) => await axiosInstance.get(`chat/${id}`),
    postCreateGroupChat: async (data: CreateGroupChatPayload) => await axiosInstance.post(`chat/group`, data),
    postCreatePrivateChat: async (targetId: number) => await axiosInstance.post(`chat/private`, {targetId}),
    postCreateCompanyChat: async (companyId: number) => await axiosInstance.post(`chat/company`, {companyId}),
}
