import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Option, UserEntity} from "../../types";
import {AuthSmsEntity, LatLong, UserOldSession} from "./types";
import fetchSendSms from "./fetchSendSms";
import fetchLogin from "./fetchLogin";
import fetchRegister from "./fetchRegister";
import _ from "lodash";

export interface AuthState {
  user: Option<UserEntity>;
  oldSessions: UserOldSession[]
  isLogIn: boolean;
  auth: Option<AuthSmsEntity>;
  geoIp: Option<LatLong>;
  token: string | null
  isPending: boolean,
  error: string | null
  lang: string
}

const initialState: AuthState = {
  user: null,
  oldSessions: [],
  auth: null,
  isPending: false,
  isLogIn: false,
  geoIp: null,
  token: null,
  error: null,
  lang: 'en'
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
        state.user = null
        state.token = null
        state.auth = null
        state.isLogIn = false
    },
    setUserData: (state, action: PayloadAction<UserEntity | null>) => {
        state.user = action.payload
    },
    changeLanguage: (state, action: PayloadAction<string>) => {
        state.lang = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchSendSms.pending, (state) => {
      state.isPending = true
    })
    builder.addCase(fetchSendSms.fulfilled, (state, action: PayloadAction<{ phoneNumber: string, nextStep: string }>) => {
      state.auth = {
        phoneNumber: action.payload.phoneNumber,
      }
      state.isPending = false
    })
    builder.addCase(fetchLogin.pending, (state) => {
      state.isPending = true
      state.error = null
    })
    builder.addCase(fetchRegister.pending, (state) => {
      state.isPending = true
      state.error = null
    })
    builder.addCase(fetchLogin.fulfilled, (state, action: PayloadAction<{ user: UserEntity, token: string }>) => {
      const {user, token} = action.payload
      state.user = user
      state.token = token
      state.isLogIn = true
      state.isPending = false
      state.oldSessions = state.oldSessions || []
      const newUser: UserOldSession = {
        id: user.id,
        login: user.login,
        fullName: user.firstName ? `${user.firstName} ${user.lastName}` : '',
        phoneNumber: user.phoneNumber,
        avatarId: user.avatar?.id || undefined,
      }
      state.oldSessions = [newUser, ...state.oldSessions.filter(({phoneNumber}) => phoneNumber !== user.phoneNumber)]
    })
    builder.addCase(fetchRegister.fulfilled, (state, action: PayloadAction<{ user: UserEntity, token: string }>) => {
      const {user, token} = action.payload
      state.user = user
      state.token = token
      state.isLogIn = true
      state.isPending = false
      state.oldSessions = state.oldSessions || []
      const ids = state.oldSessions.map(({id}) => id)
      if (!_.includes(ids, user.id)) {
        const newUser: UserOldSession = {
          id: user.id,
          login: user.login,
          fullName: user.firstName ? `${user.firstName} ${user.lastName}` : '',
          phoneNumber: user.phoneNumber,
          avatarId: user.avatar?.id || undefined,
        }
        state.oldSessions = [newUser, ...state.oldSessions]
      }
    })
    builder.addCase(fetchLogin.rejected, (state, action: any) => {
      if (action.payload) {
        state.error = action.payload.message || "Some error: Try later!"
      } else {
        state.error = action.error.message
      }
      state.isPending = false
    })
    builder.addCase(fetchRegister.rejected, (state, action: any) => {
      if (action.payload) {
        state.error = action.payload.message || "Some error: Try later!"
      } else {
        state.error = action.error.message
      }
      state.isPending = false
    })
  }
})

export const {logout, setUserData, changeLanguage} = authSlice.actions
export default authSlice.reducer
