import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {EventsApi} from "../../api/events";

const fetchLeave = createAsyncThunk<void,
  string,
  {
    state: RootState
  }>(
  'events/fetchLeave',
  async (id, ThunkApi) => {
    const {auth} = ThunkApi.getState().auth
    if (auth ) {
      const [eventRes, eventErr] = await handle(EventsApi.putLeaveEvent(id))
      if (eventRes) {
        return
      }
      if (eventErr) {
        throw {message: eventErr.message[0] || eventErr.message}
      }
    }
    throw {message: 'No user state'}
  }
)

export default fetchLeave
