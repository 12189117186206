import React, {useEffect} from 'react';
import {useProfileAction, useProfileState} from "../../store/profile/hooks";
import CompanyView from "../../views/CompanyViews/CompanyView";
import {useCompanyAction, useCompanyState} from "../../store/company/hooks";
import LoadingCompany from "../../views/CompanyViews/LoadingCompany";
import {useWallAction} from "../../store/wall/hooks";

function MyCompanyPage() {
  const {selectedUserCompany, isPending} = useCompanyState()
  const {onFetchUserCompanies} = useCompanyAction()
  const {onResetWall} = useWallAction()
  useEffect(() => {
    return () => onResetWall()
  }, [])
  useEffect(() => {
    onFetchUserCompanies()
  }, [onFetchUserCompanies])
  if (!selectedUserCompany || isPending) {
    return (
        <LoadingCompany/>
    );
  }
  return <CompanyView {...selectedUserCompany} />
}

export default MyCompanyPage;
