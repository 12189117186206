import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {AcquaintanceApi} from "../../api/acquaintance";
import {UserEntity} from "../../types";

const fetchUserSympathies = createAsyncThunk<UserEntity[],
  void,
  {
    state: RootState
  }>(
  'acquaintance/fetchUserSympathies',
  async (_, ThunkApi) => {
    const authState = ThunkApi.getState().auth
    if (authState.auth) {
      const [sympathiesRes, sympathiesErr] = await handle(AcquaintanceApi.getUserSympathies())
      if (sympathiesRes) {
        return sympathiesRes
      }
      if (sympathiesErr) {
        throw {message: sympathiesErr.message[0] || sympathiesErr.message}
      }
    }
    throw {message: 'No sympathies state'}
  }
)

export default fetchUserSympathies
