import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {EventResponse} from "../events/types";
import {SubscriptionApi} from "../../api/subscription";
import {EventsApi} from "../../api/events";
import {WallApi} from "../../api/wall";
import {IPhoto, UserEntity} from "../../types";
import {ICompany} from "../company/types";



const fetchProfileData = createAsyncThunk<{subscribers: UserEntity[], subscriptions: UserEntity[], subscriptionsCompanies: ICompany[],photos: IPhoto[], events: EventResponse[] },
  void,
  {
    state: RootState
  }>(
  'profile/fetchProfileData',
  async (_, ThunkApi) => {
    const data: {subscribers: any[], subscriptions: any[], photos: any[],subscriptionsCompanies: ICompany[], events: EventResponse[] } ={
      subscribers: [],
      subscriptions: [],
      subscriptionsCompanies: [],
      photos: [],
      events: []
    }
    const authState = ThunkApi.getState().auth
    const {profile, profileOther} = ThunkApi.getState().profile
    const profileId = profileOther?.id || profile?.id
    if (authState.auth && profileId) {
      const [subscribersRes, subscribersErr] = await handle(SubscriptionApi.getSubscribers(profileId))
      const [subscriptionsRes, subscriptionsErr] = await handle(SubscriptionApi.getSubscriptions(profileId))
      const [subscriptionsCompanyRes, subscriptionsCompanyErr] = await handle(SubscriptionApi.getSubscriptionsCompany(profileId))
      const [eventsRes, eventsErr] = await handle(EventsApi.getEventsById(profileId))
      const [photosRes, photosErr] = await handle(WallApi.getWallPhotos(profileId))
      if (subscribersRes) data.subscribers = subscribersRes
      if (subscriptionsRes) data.subscriptions = subscriptionsRes
      if (subscriptionsCompanyRes) data.subscriptionsCompanies = subscriptionsCompanyRes
      if (eventsRes) data.events = eventsRes
      if (eventsRes) data.photos = photosRes
      if (subscribersErr) console.log({message: subscribersErr.message[0] || subscribersErr.message})
      if (subscriptionsErr) console.log({message: subscriptionsErr.message[0] || subscriptionsErr.message})
      if (subscriptionsCompanyErr) console.log({message: subscriptionsCompanyErr.message[0] || subscriptionsCompanyErr.message})
      if (eventsErr) console.log({message: eventsErr.message[0] || eventsErr.message})
      if (photosErr) console.log({message: photosErr.message[0] || photosErr.message})
      return data
    }
    throw {message: 'No user state'}
  }
)

export default fetchProfileData
