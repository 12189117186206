import {axiosInstance} from "./index";
import {IAdminServicesFilter, IPostCreateService, IPutCreateService} from "../store/adminServices/types";
import {IServicesFilter} from "../store/services/types";

export const ServicesApi = {
    getAdminServices: async (data: IAdminServicesFilter) => await axiosInstance.get('company/favor/all', {params: data}),
    getServices: async (data: IServicesFilter) => await axiosInstance.get('company/favor/all', {params: data}),
    getOneService: async (id: number) => await axiosInstance.get(`company/favor/${id}`, ),
    getCompanyServices: async (idCompany: number) => await axiosInstance.get(`company/${idCompany}/favor`),
    postCreateService: async (idCompany: number, data: IPostCreateService) => await axiosInstance.post(`company/${idCompany}/favor`, data ),
    putUpdateService: async (id: number, data: IPutCreateService) => await axiosInstance.put(`company/favor/${id}`, data ),
    deleteService: async (id: number) => await axiosInstance.delete(`company/favor/${id}` ),
    deleteAdminService: async (id: number) => await axiosInstance.delete(`company/favor/admin/${id}` ),
    putSetHeaderService: async (id: string, file: File) => await axiosInstance.put(`company/favor/${id}/header`, {file}, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    } ),
}
