import React from 'react';
import styled from "styled-components";
import {SpinnerIcon} from "../Svg";


const LoadingBlock = styled.div`
  width: 100%;
  height: 100%;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100px;
    height: 100px;
  }
`

function Index({...props}: any) {
  return (
    <LoadingBlock>
      <SpinnerIcon/>
    </LoadingBlock>
  );
}

export default Index;
