import React, {useCallback, useRef, useState} from 'react';
import styled from "styled-components";
import Text from "../../UI/Text";
import {FileUploader} from 'react-drag-drop-files';
import {ETextVariants} from "../../UI/Text/types";
import {Button, EButtonVariants} from "../../UI/Button";
import {useProfileAction} from "../../store/profile/hooks";
import {handle} from "../../api";
import {ProfileApi} from "../../api/profile";
import ReactTextareaAutosize from "react-textarea-autosize";
import {useCompanyState} from "../../store/company/hooks";
import {useWallAction} from "../../store/wall/hooks";
import {T, useTranslate} from "@tolgee/react";
import {WallApi} from "../../api/wall";
import {handleImageUpload} from "../../utils";

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  padding-top: 60px;
  z-index: 10;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    content: '';
    position: absolute;
    background: ${({theme}) => theme.colors.transparentGray};
    width: 100%;
    height: 100%;
    padding-bottom: 100%;
    top: 0;
  }

  ${({theme}) => theme.mediaQueries.md} {
    //position: absolute;
    border-radius: 0;
    width: 100%;
    height: 100%;
    padding-top: 0;
  }
`

const ModalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1076px;
  z-index: 100;
  background: ${({theme}) => theme.colors.white};
  gap: 20px;
  label {
    width: 100%;
  }

  ${({theme}) => theme.mediaQueries.sm} {
    max-width: 300px;
    gap: 0;
    flex-direction: column;
  }
`
const ModalInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 6px;
  padding: 20px;
  height: 346px;
  background: ${({theme}) => theme.colors.white};

  ${({theme}) => theme.mediaQueries.sm} {
    max-width: 300px;
    height: 150px;
  }
`
const Content = styled.div`
  border-radius: 8px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 60px;
  border: 1px dashed ${({theme}) => theme.colors.lightGray3};
  ${({theme}) => theme.mediaQueries.sm} {
  }
`

const Title = styled.p`
  font-size: 38px;
  display: inline;
  text-align: center;
  line-height: 1;
  font-weight: 500;
  color: ${({theme}) => theme.colors.lightGray3};
  ${({theme}) => theme.mediaQueries.sm} {
    display: none;
  }
`

const ErrorForm = styled(Text)`

`

const MainContent = styled.div`
  width: 100%;
  padding: 30px 30px 30px 0;
  display: flex;
  align-items: center;
  gap: 30px;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    padding: 20px;
  }
`

const ButtonStyled = styled(Button)`
  width: 346px;
  padding: 20.5px 50px;
  font-size: 20px;
  ${({theme}) => theme.mediaQueries.md} {
    width: auto;
  }
`
const TextAreaStyled = styled(ReactTextareaAutosize)`
  resize: none;
  padding: 11.5px 20px;
  border-radius: 10px;
  background: ${({theme}) => theme.colors.bg};
  border: none;
  color: ${({theme}) => theme.colors.black};
  font-size: 14px;
  width: 100%;
`


const fileTypes = ['JPEG', 'PNG', 'JPG'];

function ModalFirmPost({onClose}: {onClose: () => void }) {
  const {t} = useTranslate()
  const [error, setError] = useState<string | null>(null);
  const [text, setText] = useState<string>('');
  const [loadFile, setLoadFile] = useState<File | null>(null);
  const {selectedUserCompany} =useCompanyState()

  const modal = useRef(null)

  const onCloseHandler = useCallback((event: any) => {
    if (event.target === modal.current) {
      onClose()
    }
  }, [onClose])

  const {onFetchWallCompany} = useWallAction()

  const onSend = useCallback( async () => {
    const compressedFile = await handleImageUpload(loadFile)
    if (!selectedUserCompany || (text.trim().length === 0 && !compressedFile)) return

    const content = text.trim().length === 0 ? "" : text

    const [postRes, postErr] = await handle(ProfileApi.postCreateCompanyPost( selectedUserCompany.id, {body: content}))
    if (postRes) {
      if (compressedFile) {
        const [_, fileErr] = await handle(ProfileApi.putSetPhoto(postRes.id, compressedFile))

        if (fileErr) {
          if (!content) {
            await handle(WallApi.deletePost(postRes.id))
          }
          return
        }
      }

    }
    if (postErr) {
      console.log(postErr);
      return
    }
    setLoadFile(null)
    setText('')
    onFetchWallCompany(selectedUserCompany.id)
  }, [loadFile, onFetchWallCompany, selectedUserCompany, text]);

  return (
    <Wrapper ref={modal} onClick={onCloseHandler}>
      <ModalWrapper>
        <FileUploader
          handleChange={(file: File) => {
            setLoadFile(file)
            setError(null)
          }}
          name="file"
          types={fileTypes}
          onTypeError={(err: string) => setError(err)}
          maxSize="10"
          onSizeError={(file: string) => setError(file)}
        >
          <ModalInner>
            <Content>
              <Title><T keyName="modalPost.title">Бросьте файл для загрузки сюда</T></Title>
              <ButtonStyled variant={EButtonVariants.Primary}><T keyName="modalPost.button">Загрузить файл</T></ButtonStyled>
            {error === 'File type is not supported' && (
              <ErrorForm variant={ETextVariants.Error}>
                <T keyName="modalPost.errors.typeIsNotSupported">Такой тип файла не подходит, допустимые файлы формата JPEG,PNG!</T>
              </ErrorForm>
            )}
            {error === 'File size is too big' && (
              <ErrorForm variant={ETextVariants.Error}>
                <T keyName="modalPost.errors.sizeIsToBig">Большой размер файла, размер не должен превышать 10МБ!</T>
              </ErrorForm>
            )}

              {loadFile && (
                <Text variant={ETextVariants.Body16}>
                  {loadFile
                    ? <T keyName="modalPost.value"
                         defaultValue={`Имя обложки: ${loadFile.name}`}
                         params={{name: loadFile.name}}/>
                    : ''}
                </Text>
              )}
            </Content>
          </ModalInner>
        </FileUploader>
        <MainContent>
          <TextAreaStyled
            onChange={(e) => setText(e.target.value.substring(0, 2000))}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                onSend()
              }
            }}
            minRows={5}
            value={text}
            placeholder={t('modalPost.placeholder', "Что у вас нового?")}
          />
          <ButtonStyled onClick={onSend} $fullWidth variant={EButtonVariants.Primary} disabled={(!text && !loadFile)}>
            <T keyName="common.send">Отправить</T>
          </ButtonStyled>
        </MainContent>
      </ModalWrapper>
    </Wrapper>
  );
}

export default ModalFirmPost;
