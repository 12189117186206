import styled from "styled-components";

export const ContainerMax = styled.div`
  width: 100%;
  max-width: 1433px;
  margin-left: auto;
  margin-right: auto;
  gap: 20px;
  display: flex;

  ${({theme}) => theme.mediaQueries.max} {
    padding: 0 1.5rem;
  }
  ${({theme}) => theme.mediaQueries.md} {
    padding: 0;
  }
;)`;
