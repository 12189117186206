import {useDispatch, useSelector} from "react-redux";
import {useCallback, useEffect, useMemo} from "react";
import {AppDispatch, RootState} from "../index";
import {changeFilter, changePage, removeCurrentService, resetFilter} from "./servicesSlice";
import {IServicesFilter} from "./types";
import fetchServices from "./fetchServices";
import fetchCompanyServices from "./fetchCompanyServices";
import fetchService from "./fetchService";
import {useGeoAction} from "../geo/hooks";

export const getServices = (state: RootState) => state.services

export function useServicesAction() {
  const dispatch = useDispatch<AppDispatch>()

  const onChangePage = useCallback(() => {
    dispatch(changePage())
  }, [dispatch])
  const onFetchServices = useCallback(() => {
    dispatch(fetchServices())
  }, [dispatch])
  const onFetchCompanyServices = useCallback((companyId: number) => {
    dispatch(fetchCompanyServices(companyId))
  }, [dispatch])

  const onFetchService = useCallback((id: number) => {
    dispatch(fetchService(id))
  }, [dispatch])

  const onRemoveCurrentService = useCallback(() => {
    dispatch(removeCurrentService())
  }, [dispatch])

  const onChangeFilter = useCallback((data: IServicesFilter) => {
    dispatch(changeFilter(data))
  }, [dispatch])

  const onResetFilter = useCallback((data?: IServicesFilter) => {
    dispatch(resetFilter(data))
  }, [dispatch])

  // const onJoinService = useCallback((id: string) => {
  //   dispatch(fetchJoin(id))
  //     .unwrap()
  //     .then(() => {
  //       onFetchService(id)
  //     })
  // }, [dispatch, onFetchService])


  return useMemo(() => ({
    onChangeFilter,
    onResetFilter,
    onRemoveCurrentService,
    onFetchCompanyServices,
    onFetchServices,
    onFetchService,
    onChangePage
  }), [onChangeFilter,
    onResetFilter,
    onRemoveCurrentService,
    onFetchCompanyServices,
    onFetchServices,
    onFetchService,
    onChangePage
  ])
}

export function useServicesState() {
  return useSelector(getServices)
}

export function useServices() {
  const {filter} = useServicesState()
  const {onFetchServices} = useServicesAction()
  const {onFetchCities, onClearCities} = useGeoAction()
  useEffect(() => {
    onFetchServices()
  }, [filter, onFetchServices])

  useEffect(() => {
    if (filter.country) {
      onFetchCities(filter.country.toLowerCase())
    } else {
      onClearCities()
    }
    return () => onClearCities()
  }, [filter.country])
}
