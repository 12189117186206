import React, {useEffect} from 'react';
import {Navigate, Route, Routes } from 'react-router-dom';
import MyProfilePage from "./MyProfilePage";
import UserProfilePage from "./UserProfilePage";
import {useAppAction} from "../../store/app/hooks";
import {useTranslate} from "@tolgee/react";
import ProfileEvents from './ProfileEvents';
import Settings from "./settings";
import CreateFirmPage from "./settings/CreateFirmPage";
import {useCompanyState} from "../../store/company/hooks";
import CompanySettingsPage from "./settings/CompanySettingsPage";
import MyCompanyPage from "./MyCompanyPage";
import ProfileProvider from "../../contexts/ProfileContext";

function Index() {
  const {onSetTitle} = useAppAction()
  const {t} = useTranslate()
  const {selectedUserCompany} = useCompanyState()

  useEffect(() => {
    onSetTitle(t('navbar.profile', 'Профиль'))
  }, [onSetTitle, t])

  return (
    <Routes>
      <Route index element={selectedUserCompany ? <MyCompanyPage/> : <ProfileProvider><MyProfilePage /></ProfileProvider>} />
      <Route path="/events/:userId" element={<ProfileEvents />} />
      <Route path="/settings/*" element={selectedUserCompany ? <CompanySettingsPage/> : <Settings />} />
      <Route path="/firm-create" element={<CreateFirmPage />} />
      <Route path="/:userId" element={<ProfileProvider><UserProfilePage /></ProfileProvider>} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default Index;
