import React, {ReactNode, useCallback, useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {DotsIcon} from "../Svg";
import Text from "../Text";
import {ETextVariants} from "../Text/types";
import {T} from "@tolgee/react";

const ActionBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
`
const DotsButton = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  cursor: pointer;
`

const DotsModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: fit-content;
  padding: 8px 0;
  gap: 8px;
  background: ${({theme}) => theme.colors.white};
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  position: absolute;
  right: -5px;
  cursor: pointer;
  top: 36px;
  z-index: 100;

  &:after {
    content: "";
    position: absolute;
    left: 100%;
    top: 0;
    transform: translate(calc(-100% - 7px), -100%) rotate(180deg);
    border: 12px solid transparent;
    border-top: 20px solid ${({theme}) => theme.colors.white};
  }
`

const ActionItem = styled(Text)`
  padding: 8px 16px;
  width: 100%;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    background: ${({theme}) => theme.colors.lightWhite1};
  }
`

function Index({items}: {items: Array<{title: string | ReactNode, action: () => void}>}) {
  const dots = useRef<HTMLDivElement>(null)
  const [isShowDots, setIsShowDots] = useState<boolean>(false);
  const handleClickOutside = useCallback((e: any) => {
    const path = e.path || (e.composedPath && e.composedPath());
    if (!path.includes(dots.current)) {
      setIsShowDots(false)
    }
  }, [])
  useEffect(() => {
    const rootEl = document.getElementById('root')
    if (rootEl && window) {
      window.addEventListener('click', handleClickOutside, false);
      return () => {
        window.removeEventListener('click', handleClickOutside, false);
      }
    }
  }, [handleClickOutside])

  return (
    <ActionBlock ref={dots}>
      <DotsButton onClick={() => setIsShowDots(prev => !prev)}>
        <DotsIcon/>
      </DotsButton>
      {
        isShowDots && <DotsModal>
          {items.map((item, id) => <ActionItem key={`Action-${id}`} onClick={() => {
            item.action()
            setIsShowDots(false)
          }} variant={ETextVariants.Body16}>{item.title}</ActionItem>)}
          </DotsModal>
      }
    </ActionBlock>
  );
}

export default Index;
