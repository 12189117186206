import React, {useMemo} from 'react';
import AdminHeader from "../../../components/AdminComponents/AdminHeader";
import {Navigate, Route, Routes} from "react-router-dom";
import Text from "../../../UI/Text";
import {ETextVariants} from "../../../UI/Text/types";
import {useAdminCompanies, useAdminCompaniesState} from "../../../store/adminCompanies/hooks";
import FirmsFilterView from "../../../views/AdminFirmsViews/FirmsFilterView";
import FirmsListView from "../../../views/AdminFirmsViews/FirmsListView";
import AddFirmPage from "./AddFirmPage";
import ApproveFirmListPage from "./ApproveFirmListPage";
import EditFirmPage from "./EditFirmPage";
import LoadingSpinner from "../../../UI/LoadingSpinner";
import {T, useTranslate} from "@tolgee/react";

function Index() {
  useAdminCompanies()
  const {t} = useTranslate()
  const {companies, isLoading} = useAdminCompaniesState()

  return (
    <Routes>
      <Route index element={
        <>
          <AdminHeader title={t('admin.menu.firms', 'Фирмы')} rightContent={
            <Text variant={ETextVariants.Body16}>
              <T keyName="common.total" params={{total: companies.length}} defaultValue={`Всего ${companies.length}`}/>
            </Text>
          }/>
          <FirmsFilterView/>
          {
            isLoading
              ? <LoadingSpinner/>
              : <FirmsListView/>
          }
        </>}/>
      <Route path="/add" element={<AddFirmPage/>}/>
      <Route path="/edit/:id" element={<EditFirmPage/>}/>
      <Route path="/approve-list" element={<ApproveFirmListPage/>}/>
      <Route path="*" element={<Navigate to="/admin/firms"/>}/>
    </Routes>
  );
}

export default Index;
