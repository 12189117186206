import React, {ReactNode, useCallback, useEffect, useMemo, useState} from 'react';
import {UserEntity} from "../../types";
import {IChat} from "../../store/chat/types";
import styled from "styled-components";
import Input from "../../UI/Input";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {ArrowLeftIcon, CloseIcon, SearchIcon} from "../../UI/Svg";
import {useNavigate} from 'react-router-dom';
import UserItem from '../ProfileComponents/UserItem';
import {useTranslate} from "@tolgee/react";

export interface ModalUsersProps {
  onClose: () => void;
  users?: UserEntity[];
  title: ReactNode | string;
  createChat?: (id: number) => void;
  forwardingMessages?: (id: string) => void;
  chats?: IChat[];
}

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  padding-top: 60px;
  z-index: 100000;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    content: '';
    position: absolute;
    background: ${({theme}) => theme.colors.transparentGray};
    width: 100%;
    height: 100%;
    padding-bottom: 100%;
    top: 0;
  }

  ${({theme}) => theme.mediaQueries.md} {
    position: absolute;
    border-radius: 0;
    width: 100%;
    height: 100%;
    padding-top: 0;
    margin-bottom: 71px;
  }
`

const Modal = styled.div`
  min-height: 300px;
  max-width: 1191px;
  height: 84vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  top: 0;
  right: 0;
  background: ${({theme}) => theme.colors.bg};
  z-index: 2;
  border-radius: 10px;

  &::-webkit-scrollbar {
    width: 0;
  }

  ${({theme}) => theme.mediaQueries.xxl} {
    width: 968px;
  }

  ${({theme}) => theme.mediaQueries.lg} {
    width: 770px;
  }

  ${({theme}) => theme.mediaQueries.md} {
    border-radius: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    overflow: scroll;

  }
`
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid ${({theme}) => theme.colors.darkWhite};
  position: relative;
  gap: 20px;
  background: ${({theme}) => theme.colors.white};
  padding: 18px 32px 18px 70px;

  ${({theme}) => theme.mediaQueries.md} {
    justify-content: center;
    padding: 8px 32px 10px;
    border-bottom: none;
  }
`

const InputBlock = styled.div`
  display: flex;

  ${({theme}) => theme.mediaQueries.md} {
    display: none;
  }
`
const InputStyled = styled(Input)`
  height: 40px;

`

const CloseButton = styled.div`
  width: 20px;
  height: 20px;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    width: 12px;

    &:first-child {

      ${({theme}) => theme.mediaQueries.md} {
        display: none;
      }
    }

    &:nth-child(2) {

      display: none;

      ${({theme}) => theme.mediaQueries.md} {
        display: block;
      }
    }
  }

  ${({theme}) => theme.mediaQueries.md} {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 16px;

    svg {
      min-width: 24px;
    }
  }
`

const Content = styled.div`
  margin-top: 32px;
  display: flex;
  gap: 14px;
  width: 100%;
  padding: 0 16px 60px;

  ${({theme}) => theme.mediaQueries.md} {
    margin-top: 10px;
    padding-bottom: 0;
  }
`

const WrappScroll = styled.div`
  width: 100%;
  flex: 1;
  overflow: auto;
`

const WrapUsers = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 0fr;
  width: 100%;
  grid-column-gap: 14px;
  padding: 16px 35px;
  grid-row-gap: 14px;

  ${({theme}) => theme.mediaQueries.xxl} {
    grid-template-columns: repeat(4, 1fr);
  }

  ${({theme}) => theme.mediaQueries.lg} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${({theme}) => theme.mediaQueries.md} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px 0;
    gap: 2px;
  }
`

function ModalUsers({onClose, users, createChat, chats, forwardingMessages, title}: ModalUsersProps) {
  const navigate = useNavigate()
  const {t} = useTranslate()
  const [search, setSearch] = useState<string>('');
  const filteredUsers = useMemo(() => {
    return users?.filter((item) => {
      return `${item.firstName} ${item.lastName} ${item.login}`.toLowerCase().includes(search.toLowerCase())
    });
  }, [search, users]);

  const clickItem = useCallback((id: string) => {
    if (createChat) {
      createChat(parseInt(id));
    } else if (forwardingMessages) {
      forwardingMessages(id);
    } else {
      navigate(`/profile/${id}`);
      onClose();
    }
  }, [createChat, forwardingMessages, navigate, onClose]);
  const body = document.querySelector('body');

  useEffect(() => {
    if (body) body.style.overflow = 'hidden';

    return () => {
      if (body) body.style.overflow = 'auto';
    };
  }, [body]);

  return (
    <Wrapper>
      <Modal>
        <Header>
          <Text variant={ETextVariants.Heading}>
            {title}
          </Text>
          <InputBlock>
            <InputStyled
              placeholder={t('common.search', "Поиск")}
              wth="34.3rem"
              onChange={(e) => setSearch(e.target.value)}
              icon={<SearchIcon/>}
            />
          </InputBlock>
          <CloseButton onClick={onClose}>
            <CloseIcon/>
            <ArrowLeftIcon/>
          </CloseButton>
        </Header>
        <WrappScroll>
          <WrapUsers>
            {users
              ? !filteredUsers?.length
                ? users?.map((item) => {
                  return <UserItem key={item.id} profile={item} onClick={() => clickItem(`${item.id}`)}/>;
                })
                : filteredUsers?.map((item) => {
                  return <UserItem key={item.id} profile={item} onClick={() => clickItem(`${item.id}`)}/>;
                })
              : chats?.map((item) => {
                return <UserItem key={item.id} chat={item} onClick={() => clickItem(item.id)}/>;
              })}
          </WrapUsers>
        </WrappScroll>
      </Modal>
    </Wrapper>
  );
}

export default ModalUsers;
