import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {IWall,} from "./types";
import {handle} from "../../api";
import {WallApi} from "../../api/wall";



const fetchWallCompany = createAsyncThunk<IWall[],
  number,
  {
    state: RootState
  }>(
  'profile/fetchWallCompany',
  async (id, ThunkApi) => {
    const authState = ThunkApi.getState().auth
    if (authState.auth) {
      const [wallRes, wallErr] = await handle(WallApi.getWallCompany(id))
      if (wallRes) {
        return wallRes
      }
      if (wallErr) {
        throw {message: wallErr.message[0] || wallErr.message}
      }
    }
    throw {message: 'No user state'}
  }
)

export default fetchWallCompany
