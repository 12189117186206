import { DefaultTheme } from "styled-components";
import {breakpoints, mediaQueries, shadows, breakpointMap} from "./base";
import { lightColors } from "./colors";
import { lightPallets } from "./paletts";
import {light as text} from "../UI/Text/theme";
import {light as button} from "../UI/Button/theme";

const lightTheme: DefaultTheme = {
    isDark: false,
    colors: lightColors,
    breakpoints,
    breakpointsMap: breakpointMap,
    mediaQueries,
    shadows,
    text,
    pallets: lightPallets,
    button,

};

export default lightTheme;
