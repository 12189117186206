import React, {useMemo} from 'react';
import {useAuthState} from "../../store/auth/hooks";
import styled from "styled-components";
import noAvatar from "../../assets/img/no-avatar.png";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {IChat} from "../../store/chat/types";
import {mediaUrl} from "../../api";
import { getUserShortName } from '../../utils/user';
import { formatDistance } from 'date-fns';
import {getTitleChat} from "../../views/ChatViews/Contacts";
import {useNavigate} from "react-router-dom";
import {getPictureChat} from "../../views/ChatViews/HeaderDialogView";
import {useTranslate} from "@tolgee/react";
import {localeLangs} from "../../UI/DataPickerRange";
import en from "date-fns/locale/en-GB";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  gap: 16px;
  width: 100%;
`

const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 50px; 
  width: 50px; 
  height: 50px; 
  overflow: hidden;
  border-radius: 50%;
  background: #9b9b9b;
  img {
    width: auto;
    height: 100%;
  }
`

const DescriptionBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  flex: 1;
  overflow: hidden;

  ${({theme}) => theme.mediaQueries.xxl} {
    gap: 4px;
  }
`

const Title = styled(Text)`
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`
const MessageBlock = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  gap: 18px;
`

const Message = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({theme}) => theme.colors.lightGray5};
  max-width: 193px;
  ${({theme}) => theme.mediaQueries.xxl} {
    max-width: 163px;
  }
  ${({theme}) => theme.mediaQueries.xl} {
    max-width: 113px;
  }
`
const TimeText = styled(Text)`
  white-space: nowrap;
  color: ${({theme}) => theme.colors.lightGray5};
  position: relative;
  &:before {
    content: ''; 
    position: absolute;
    left: -8px;
    top: 50%;
    border-radius: 50%;
    transform: translate(-100%, -50%);
    width: 2px;  
    height: 2px;
    background: ${({theme}) => theme.colors.lightGray5};
  }
`

const CountBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: ${({theme}) => theme.colors.mainPurple};
  width: 22px;
  height: 22px;
  color: ${({theme}) => theme.colors.white};
  font-size: 14px;
`

function Contact(chat: IChat) {
  const {title, lastMessage, type}  = useMemo(() => chat, [chat]);
  const {user, lang} = useAuthState()
  const navigate = useNavigate()
  const currentTime = new Date();
  const {t} = useTranslate()

  const avatar = useMemo(() => {
    return getPictureChat(chat, user)
  }, [chat, user]);

  const lastMessageText = useMemo(() => {
    if (lastMessage) {
      let result = '';
      if (lastMessage.sender.id === user?.id) {
        result = t('chat.lastMessage.you','Вы: ');
      } else if (type === 'event' || type === 'group') {
        result = `${getUserShortName(lastMessage.sender)}.: `;
      }
      switch (lastMessage.type){
        case "company":
          if (lastMessage.text) {
            result += lastMessage.text
          } else {
            result += t('chat.lastMessage.forwardMessage',' пересланное сообщение')
          }
          break
        case "eventInviteAccepted":
          if (lastMessage.sender.id === user?.id) {
            result += t('chat.lastMessage.eventInviteAcceptedSender','приняли приглашение')
          } else {
            result += t('chat.lastMessage.eventInviteAccepted','принял(а) приглашение')
          }
          break
        case "eventInviteUnaccepted":
          if (lastMessage.sender.id === user?.id) {
            result += t('chat.lastMessage.eventInviteUnacceptedSender','отклонили приглашение')
          } else {
            result += t('chat.lastMessage.eventInviteUnaccepted','отклонил(а) приглашение')
          }
          break
        case "eventInvite":
          result += t('chat.lastMessage.eventInvite',' отправили приглашение на ивент')
          break
        case "eventInviteNotification":
          result += t('chat.lastMessage.eventInviteNotification',' отправили приглашение на ивент')
          break
        default:
          if (lastMessage.files?.length > 0) {
            result += t('chat.lastMessage.photo',' фотография')
          } else if (lastMessage.text === null) {
            result += t('chat.lastMessage.forwardMessage',' пересланное сообщение')
          } else {
            result += lastMessage.text
          }
      }
      return result
    }
    return t('chat.empty','Сообщений нет')
  }, [lastMessage, t, type, user?.id])


  return (
    <Wrapper onClick={() => navigate(`/chat/${chat.id}`)}>
      <Avatar>
        <img src={avatar ? `${mediaUrl}file/${avatar}` : noAvatar} alt=""/>
      </Avatar>
      <DescriptionBlock>
        <Title variant={ETextVariants.Heading} as={'h3'}>
          {title || getTitleChat(chat, user)}
        </Title>
        <MessageBlock>
          <Message variant={ETextVariants.Body14} as={'p'}>
            {lastMessageText}
          </Message>
          <TimeText variant={ETextVariants.Body14} as={'p'}>
            {lastMessage &&
              formatDistance(new Date(`${lastMessage?.date}`), currentTime, {
                addSuffix: true,
                locale: localeLangs[lang] || en,
              })}
          </TimeText>
        </MessageBlock>
      </DescriptionBlock>
      {chat.unreadMessagesNumber > 0 && <CountBlock>
        {chat.unreadMessagesNumber}
      </CountBlock>}
    </Wrapper>
  );
}

export default Contact;
