import React, {useCallback, useState} from 'react';
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import dayjs from "dayjs";
import {IWall} from "../../store/wall/types";
import styled from "styled-components";
import {handle, mediaUrl} from "../../api";
import imgEmptyAvatar from "../../assets/img/no-avatar.png";
import {GeoIcon} from "../../UI/Svg";
import {Button, EButtonVariants} from "../../UI/Button";
import {useCompanyState} from "../../store/company/hooks";
import {useNavigate} from "react-router-dom";
import ModalPostPhoto from "../Modals/ModalPostPhoto";
import {useWallAction} from "../../store/wall/hooks";
import {ProfileApi} from "../../api/profile";
import CreateEventModal from "../Modals/CreateEventModal";
import {T, useTranslate} from "@tolgee/react";
import {handleImageUpload} from "../../utils";


const Post = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  //padding: 16px 30px;

  ${({theme}) => theme.mediaQueries.md} {
    background: ${({theme}) => theme.colors.white};
    padding: 12px 16px;
  }
`

const PostHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;

`

const Author = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  cursor: pointer;
`

const AuthorImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;

  img {
    height: auto;
    width: 100%;
    max-width: unset;
  }
`

const TextName = styled(Text)`
  &:hover {
    text-decoration: underline;
  }
`

const TextPost = styled(Text)`
  color: ${({theme}) => theme.colors.mainGray};
`

const PostContent = styled.div`

`

const PostImageBlock = styled.div`
  display: flex;
  align-items: flex-start;

  ${({theme}) => theme.mediaQueries.md} {
    justify-content: center;
  }
`
const PostImage = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  max-height: 500px;
  height: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  max-width: 717px;
  overflow: hidden;

  ${({theme}) => theme.mediaQueries.xl} {
    max-width: 617px;
  }

  ${({theme}) => theme.mediaQueries.lg} {
    max-width: 517px;
  }

  ${({theme}) => theme.mediaQueries.md} {
    max-width: 417px;
  }

  ${({theme}) => theme.mediaQueries.sm} {
    max-width: 100%;
  }

  img {
    width: 100%;
  }
`

const ButtonStyled = styled(Button)`
  padding: 10px 20px;
  margin-top: 12px;
`

const TypePostStyled = styled.div`
  font-size: 14px;
  vertical-align: unset;
  display: flex;
  align-items: baseline;
  gap: 5px;
`

function CompanyPostComponent({item, companyId, ...props}: { item: IWall, companyId: number }) {
  const navigate = useNavigate()
  const {t} = useTranslate()
  const {selectedUserCompany} = useCompanyState()
  const [loadFile, setLoadFile] = useState<File | null>(null);
  const [isShowModal, setIsShowModal] = useState<boolean>(false);

  const [isShowErrorModal, setIsShowErrModal] = useState<boolean>(false);
  const [bodyModal, setBodyModal] = useState<{ title: string, body: string }>(
    {title: '', body: ''}
  );

  const {onFetchWallCompany} = useWallAction()

  const onSend = useCallback(async () => {
    const compressedFile = await handleImageUpload(loadFile)
    if (compressedFile !== null) {
      const [_, fileErr] = await handle(ProfileApi.putSetPhoto(item.id, compressedFile))

      if (fileErr) {
        setBodyModal({
          title: t('common.errorImage','Ошибка загрузки изображения'),
          body: fileErr.message
        });
        setIsShowErrModal(true);
      }
      setLoadFile(null)
      onFetchWallCompany(companyId)
    }
  }, [loadFile, item.id, onFetchWallCompany, companyId, t]);

  return (
    <>
      <Post {...props}>
        <PostHeader>
          <Author onClick={() => navigate(`/company/${item.company?.id}`)}>
            <AuthorImage>
              <img src={item.company?.avatar ? `${mediaUrl}file/${item.company?.avatar.id}` : imgEmptyAvatar}
                   alt=""/>
            </AuthorImage>
            <TextName variant={ETextVariants.Body14}>{item.company?.title}</TextName>
          </Author>
          <TextPost variant={ETextVariants.Body14}>
            <TypePostStyled>
              {item.photo && !item.body ? (
                <T keyName="companyWall.newPhoto">новое фото компании</T>
              ) : item.type === 'default' ?
                (<>
                    <T keyName="companyWall.companyPost">пост созданный компанией</T> <Text
                    variant={ETextVariants.Body14}>{dayjs(item.date).format('DD.MM.YYYY') === dayjs(new Date()).format('DD.MM.YYYY') ? (
                    dayjs(item.date).format('HH:mm')
                  ) : (
                    dayjs(item.date).format('DD.MM.YYYY HH:mm')
                  )}</Text></>
                ) : ''}
            </TypePostStyled>
          </TextPost>
          <TextName variant={ETextVariants.Body14}>
            {item.body &&
              item.body.split(' ')[1] === t("common.visit",'посещение') &&
              item.body.split(' ').splice(3).join(' ')}
          </TextName>
          {item.type === 'planEvent' && (
            <GeoIcon/>
          )}
        </PostHeader>
        <PostContent>
          {item.body && <TextName variant={ETextVariants.Body14}>
            {item.body}
          </TextName>}
          {
            item.photo
              ?
              <PostImageBlock>
                <PostImage>
                  <img src={`${mediaUrl}file/${item.photo.id}`} alt=""/>
                </PostImage>
              </PostImageBlock>
              : item.company?.id === selectedUserCompany?.id && <>
              {loadFile ? <>
                  <ButtonStyled onClick={onSend} $fullWidth variant={EButtonVariants.Secondary}>
                    <T keyName="common.send">Отправить</T>
                  </ButtonStyled>
                  {loadFile && (
                    <Text variant={ETextVariants.Body16}>
                      {loadFile
                        ? <T keyName="companyWall.fileName"
                             defaultValue={`Имя обложки: ${loadFile.name}`}
                             params={{name: loadFile.name}}/>
                        : ''}
                    </Text>
                  )}
                </>
                : <ButtonStyled onClick={() => setIsShowModal(true)} $fullWidth variant={EButtonVariants.Secondary}>+
                  <T keyName="companyWall.addPhoto">Добавить фото</T>
                  </ButtonStyled>}
            </>
          }
        </PostContent>
      </Post>
      {isShowModal && <ModalPostPhoto
          loadFile={loadFile}
          onClose={() => {
            setIsShowModal(false)
          }}
          onChange={(file) => {
            if (file) {
              setLoadFile(file)
              setIsShowModal(false)
            }
          }}
      />}
      {isShowErrorModal &&
          <CreateEventModal isModalClose={() => setIsShowErrModal(false)} title={bodyModal.title}
                            body={bodyModal.body}/>}
    </>
  );
}

export default CompanyPostComponent;
