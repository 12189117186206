import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled from "styled-components";
import {Card} from "../../components/Templates/Card";
import {Button, EButtonVariants} from "../../UI/Button";
import {T, useTranslate} from "@tolgee/react";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {useEventsAction, useEventsState} from "../../store/events/hooks";
import LoadingAbout from "../../views/AboutViews/LoadingAbout";
import {handle, mediaUrl} from "../../api";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {ArrowRightIcon, CalendarIcon, EditIcon, GeoGrayIcon, SmileIcon} from "../../UI/Svg";
import dayjs from "dayjs";
import imgEmptyAvatar from "../../assets/img/no-avatar.png";
import {useAuthState} from "../../store/auth/hooks";
import CreateEventModal from "../../components/Modals/CreateEventModal";
import ModalUsers from "../../components/Modals/ModalUsers";
import ModalReport from "../../components/Modals/ModalReport";
import {useAppAction} from "../../store/app/hooks";
import {EventsApi} from "../../api/events";
import {ContainerMax} from "../../components/Templates/ContainerMax";
import Dots from "../../UI/Dots";
import {useAcquaintanceState} from "../../store/acquaintance/hooks";
import {useProfileState} from "../../store/profile/hooks";
import {getUserName} from '../../utils/user';
import UserStatusComponent from "../../components/ProfileComponents/UserStatusComponent";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {getTextStyle} from "../../UI/Text/utils";

const ContainerStyled = styled(ContainerMax)`
  display: flex;
  align-items: flex-start;

  ${({theme}) => theme.mediaQueries.md} {
    flex-direction: column;
  }
`

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
  max-width: 1070px;

  ${({theme}) => theme.mediaQueries.md} {
    width: 100%;
    gap: 2px;
  }
`

const Aside = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
  width: 100%;
  max-width: 343px;

  ${({theme}) => theme.mediaQueries.md} {
    max-width: unset;
  }
`

const Buttons = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 30px 16px;
  gap: 20px;

  ${({theme}) => theme.mediaQueries.md} {
    background: transparent;
  }
`

const CardStyled = styled(Card)`
  display: flex;
  flex-direction: column;
  overflow: visible;

  ${({theme}) => theme.mediaQueries.md} {
    border-radius: 0;

    &:first-child {
      background: ${({theme}) => theme.colors.bg};
    }
  }
`
const CardProfile = styled(CardStyled)`
  margin-top: 22px;
  position: relative;
  gap: 16px;

  ${({theme}) => theme.mediaQueries.md} {
    margin-top: 8px;
  }
`

const IconArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
`

const ImageBlock = styled.div`
  width: 100%;
  height: 266px;
  border-radius: 18px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #9b9b9b;

  img {
    width: 100%;
    height: auto;
  }

  ${({theme}) => theme.mediaQueries.md} {
    height: 234px;

    img {
      height: 100%;
      width: auto;
    }
  }
`

const EventTitleBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const EventTitle = styled(Text)`

`

const List = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
`

const Item = styled.li`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;

  svg {
    min-width: 24px;
    width: 24px;
    height: 24px;
  }

  ${({theme}) => theme.mediaQueries.md} {
    svg {
      min-width: 20px;
      width: 20px;
      height: 20px;
    }
  }
`

const TextStyled = styled(Text)`
  ${({theme}) => theme.mediaQueries.md} {
    font-size: 12px;
  }
`
const Members = styled(TextStyled)`
  white-space: pre;
  color: ${({theme}) => theme.colors.mainPurple};
  overflow: hidden;
  cursor: pointer;
`

const UserBlock = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
`

const UserContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1px;
`

const UserAvatar = styled.div`
  height: 47px;
  width: 47px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #9b9b9b;

  img {
    width: 100%;
    height: auto;
  }
`

const PhoneText = styled.div`
  font-size: 16px;
`

const Categories = styled.div`
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  ${({theme}) => theme.mediaQueries.md} {
  }
`

const Category = styled(Text)`
  padding: 5px 10px;
  background: ${({theme}) => theme.colors.lightGray2};
  border-radius: 5px;
`

const ActionItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: flex-start;

  svg {
    width: 16px;
    height: 16px;

    path {
      stroke: ${({theme}) => theme.colors.black};
    }
  }
`

const LinkStyled = styled.div`
  ${({theme}) => getTextStyle(theme, ETextVariants.Body16)}
  cursor: pointer;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${({theme}) => theme.colors.mainPurple};

  ${({theme}) => theme.mediaQueries.md} {
    line-height: 1;
    font-size: 12px;
  }
`

function Index({isInvite}: { isInvite?: boolean }) {
  const {id} = useParams()
  const navigate = useNavigate()
  const {t} = useTranslate()
  const {user} = useAuthState()
  const {subscribers} = useProfileState()
  const {suggestion} = useAcquaintanceState()
  const {currentEvent, isLoadingEvent} = useEventsState()
  const {onFetchEvent, onRemoveCurrentEvent, onJoinEvent, onLeaveEvent, onChangeFilter} = useEventsAction()
  const [isShowReportModal, setIsShowReportModal] = useState<boolean>(false);
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [isShowMembersModal, setIsShowMembersModal] = useState<boolean>(false);
  const [isShowUsersModal, setIsShowUsersModal] = useState<boolean>(false);
  const [bodyModal, setBodyModal] = useState({title: '', body: ''});
  useEffect(() => {
    if (!suggestion && isInvite) {
      navigate('/events')
    }
  }, [isInvite, navigate, suggestion])

  const onInvite = useCallback(async (id: string) => {
    if (suggestion) {
      const [inviteRes, inviteErr] = await handle(EventsApi.postInviteEvent(id, suggestion.id))
      if (inviteRes) navigate('/acquaintance')
      if (inviteErr) console.log(inviteErr);
    }
  }, [navigate, suggestion])

  const onInviteUser = useCallback(async (userId: number) => {
    if (id !== undefined) {
      const [inviteRes, inviteErr] = await handle(EventsApi.postInviteEvent(id, userId))
      if (inviteRes) navigate('/chat')
      if (inviteErr) console.log(inviteErr);
    }
  }, [id, navigate])


  const {onSetTitle} = useAppAction()
  useEffect(() => {
    onSetTitle(t('common.event', 'Ивент'))
  }, [onSetTitle, t])

  const isMember = useMemo(() => {
    if (currentEvent && user) {
      return currentEvent.members.filter((member) => member.id === user.id).length > 0
    }
    return false
  }, [user, currentEvent])

  useEffect(() => {
    return () => onRemoveCurrentEvent()
  }, [])
  useEffect(() => {
    if (typeof id !== "undefined") {
      onFetchEvent(id)
    }
  }, [id, onFetchEvent])

  const {pathname} = useLocation()

  const linkForCopy = useMemo(() => {
    if (currentEvent) {
      return `${window.origin}${pathname}`
    }
    return ''
  }, [currentEvent, pathname])


  const authorItems = useMemo(() => {
    const items = [
      {title: <T keyName="event.complain">Пожаловаться</T>, action: () => setIsShowReportModal(true)},
      {
        title: <CopyToClipboard text={linkForCopy} onCopy={() => {
          setBodyModal({
            title: t('common.copied', 'Скопировано'),
            body: t('event.copied', 'Ссылка на ивент скопирована')
          })
          setIsShowModal(true)
        }}>
          <ActionItem>
            <Text
              variant={ETextVariants.Body16}>
              <T keyName="event.copyLink">Скопировать ссылку</T>
            </Text>
          </ActionItem>
        </CopyToClipboard>,
        action: () => {
        }
      }
    ]
    if (currentEvent && (currentEvent.author.id === user?.id)) {
      return [
        {
          title: <ActionItem><EditIcon/> <Text
            variant={ETextVariants.Body16}>
            <T keyName="common.edit">Редактировать</T>
          </Text></ActionItem>,
          action: () => navigate(`/edit/event/${currentEvent.id}`)
        },
      ]
    }
    return items
  }, [linkForCopy, currentEvent, user?.id, t, navigate])

  const isNavigate = useMemo(() => {
    if (currentEvent) {
      const now = new Date()
      const date = new Date(currentEvent.date)
      if (currentEvent.status === 'approved' && date.getTime() > now.getTime()) {
        return true
      }
    }
    return false
  }, [currentEvent])

  if (isLoadingEvent) {
    return (
      <ContainerStyled>
        <LoadingAbout/>
      </ContainerStyled>
    )
  }
  const FormatPhone = (): string | null => {
    const phone = currentEvent?.author.phoneNumber;
    const lenPhone = phone?.length;
    const tt = phone?.split('');
    if (lenPhone === 12 || lenPhone === 13) {
      tt?.splice(2, 0, ' (');
      tt?.splice(6, 0, ') ');
      tt?.splice(10, 0, '-');
      tt?.splice(13 + (lenPhone - 12), 0, '-');
    }
    return tt ? tt.join('') : null;
  };



  if (!currentEvent) return null

  return (
    <ContainerStyled>
      <Main>
        <CardStyled>
          <ImageBlock>
            <img src={currentEvent.photo ? `${mediaUrl}file/${currentEvent.photo.id}` : ''} alt=""/>
          </ImageBlock>
        </CardStyled>
        <CardStyled>
          <EventTitleBlock>
            <EventTitle variant={ETextVariants.Heading} as={'h2'}>{currentEvent.title}</EventTitle>
            <Dots
              items={authorItems}
            />
          </EventTitleBlock>

          <Categories>
            {currentEvent.categories.map((category) => <Category key={`category-${category.id}`}
                                                                 variant={ETextVariants.MiniDark}>
              <T keyName={`category.list.${category.id}`}>{category.title}</T>
            </Category>)}
          </Categories>
        </CardStyled>

        <CardStyled>
          <Text variant={ETextVariants.Body16}>{currentEvent.description}</Text>
        </CardStyled>

        <CardStyled>
          <List>
            <Item>
              <CalendarIcon/>
              <TextStyled
                variant={ETextVariants.Body16}> {dayjs(currentEvent.date).format('D MMMM / HH:mm')}</TextStyled>
            </Item>
            <Item>
              <GeoGrayIcon/>
              <LinkStyled onClick={() => {
                if (isNavigate) {
                  onChangeFilter({
                    latitude: Number(currentEvent.address.latitude),
                    longitude: Number(currentEvent.address.longitude)
                  })
                  navigate(`/events/map?is-event=${true}&id=${id}`)
                }
              }}>
                {currentEvent.address.fullString}
              </LinkStyled>
            </Item>
            <Item onClick={() => setIsShowMembersModal(true)}>
              <SmileIcon/>
              <Members variant={ETextVariants.Body16}>
                {currentEvent.maxMembers > 0
                  ? <T
                    keyName="event.numberOfMembers1"
                    params={{
                      numberOfMembers: currentEvent.members.length,
                      maxNumberOfMembers: currentEvent.maxMembers
                    }}
                    defaultValue={`${currentEvent.members.length} из ${currentEvent.maxMembers} участников уже присоединились`}
                  />
                  : <T keyName="event.numberOfMembers2"
                       params={{numberOfMembers: currentEvent.members.length}}
                       defaultValue={`${currentEvent.members.length} участников уже присоединились`}
                  />
                }
              </Members>
            </Item>
          </List>
        </CardStyled>

        <CardProfile onClick={() => navigate(`/profile/${currentEvent?.author.id}`)}>
          <UserBlock>
            <UserAvatar>
              <img
                src={currentEvent.author.avatar ? `${mediaUrl}file/${currentEvent.author.avatar.id}` : imgEmptyAvatar}
                alt=""/>
            </UserAvatar>
            <UserContent>
              <Text variant={ETextVariants.Heading}>{getUserName(currentEvent.author)}</Text>
              <Text variant={ETextVariants.Body16}><UserStatusComponent {...currentEvent.author} /></Text>
            </UserContent>
          </UserBlock>
          <PhoneText>{FormatPhone()}</PhoneText>
          <IconArrow>
            <ArrowRightIcon/>
          </IconArrow>
        </CardProfile>
      </Main>
      <Aside>
        <Buttons>
          {
            currentEvent.status === 'pending'
              ? <Text variant={ETextVariants.Body16}> <T keyName="event.pending">Ивент на модерации</T></Text>
              : currentEvent.status === 'rejected'
                ? <Text variant={ETextVariants.Body16}><T keyName="event.rejected">Ивент отклонен</T> </Text>
                : dayjs(currentEvent.date).isBefore(new Date())
                ? <Text variant={ETextVariants.Body16}><T keyName="event.passed">Ивент прошел</T> </Text>
                : isInvite
                  ?
                  <Button disabled={currentEvent.maxMembers > 0 && currentEvent.members.length >= currentEvent.maxMembers}
                          variant={EButtonVariants.Primary} onClick={() => {
                    if ((currentEvent.maxMembers > 0 && currentEvent.members.length < currentEvent.maxMembers) || currentEvent.maxMembers === 0) {
                      onInvite(`${currentEvent.id}`)
                    }
                  }} $fullWidth>
                    <T keyName="common.invite">Пригласить</T>
                  </Button>
                  :
                  !isMember
                    ?
                    <Button
                      disabled={currentEvent.maxMembers > 0 && currentEvent.members.length >= currentEvent.maxMembers}
                      variant={EButtonVariants.Primary} onClick={() => {
                      if ((currentEvent.maxMembers > 0 && currentEvent.members.length < currentEvent.maxMembers) || currentEvent.maxMembers === 0) {
                        onJoinEvent(`${currentEvent.id}`)
                      }
                    }} $fullWidth>
                      <T keyName="event.join">Присоединиться</T>
                    </Button>
                    :
                    <>
                      <Button variant={EButtonVariants.Primary} $fullWidth
                              onClick={() => onLeaveEvent(`${currentEvent.id}`)}>
                        <T keyName="event.exit">Выйти из ивента</T>
                      </Button>
                      {currentEvent.chat && <Button variant={EButtonVariants.Secondary} $fullWidth as={Link}
                                                    to={`/chat/${currentEvent.chat.id}`}>
                          <T keyName="event.chat">Чат события</T>
                      </Button>}
                      <Button
                        disabled={currentEvent.maxMembers > 0 && currentEvent.members.length >= currentEvent.maxMembers}
                        variant={EButtonVariants.Secondary} $fullWidth onClick={() => {
                        if ((currentEvent.maxMembers > 0 && currentEvent.members.length < currentEvent.maxMembers) || currentEvent.maxMembers === 0) {
                          setIsShowUsersModal(true)
                        }
                      }}>
                        <T keyName="event.inviteUser">Пригласить пользователя</T>
                      </Button>
                    </>
          }

        </Buttons>
      </Aside>
      {
        isShowMembersModal && <ModalUsers users={currentEvent.members} onClose={() => {
          setIsShowMembersModal(false)
        }} title={t("event.members", 'Участники')}/>
      }
      {
        isShowUsersModal && <ModalUsers users={subscribers} createChat={onInviteUser} onClose={() => {
          setIsShowUsersModal(false)
        }} title={t("event.inviteUser", 'Пригласить пользователя')}/>
      }
      {isShowReportModal && <ModalReport
          onClose={() => setIsShowReportModal(false)}
          setModal={() => setIsShowModal(false)}
          setBodyModal={setBodyModal}
      />}
      {isShowModal &&
          <CreateEventModal isModalClose={() => setIsShowModal(false)} title={bodyModal.title} body={bodyModal.body}/>}
    </ContainerStyled>
  );
}

export default Index;
