import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export type AppState = {
    title: string
    isShowCart: boolean
    isShowSearch: boolean
    isScroll: boolean
}

const initialState: AppState = {
    title: "Главная",
    isShowCart: false,
    isShowSearch: false,
    isScroll: false
}

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setTitle: (state, action: PayloadAction<string>) => {
            state.title = action.payload
        },
        toggleCart: (state) => {
            state.isShowCart = !state.isShowCart
        },
        toggleScroll: (state) => {
            state.isScroll = !state.isScroll
        },
        toggleSearch: (state) => {
            state.isShowSearch = !state.isShowSearch
        },
    },
})

export const {setTitle, toggleCart, toggleSearch,toggleScroll} = appSlice.actions
export default appSlice.reducer
