import { BasicModel, Lang, Option } from './common';
import { PictureEntity } from './picture';
import {IInterest} from "../store/profile/types";

export interface InterestEntity extends BasicModel {
  title: string;
}

export type UserSex = 'M' | 'F';
export type UserRole = 'user' | 'admin';

export interface IPhoto {

  id: string,
  fileName: string,
  path: string,
  mimetype: string
}

export interface IAvatar extends IPhoto{
}
export enum AcquaintancePurpose {
  Love = "love",
  Chatting = "chatting",
  Friends = "friends",
}

export enum AcquaintanceShowMyselfToGender {
  M = "M",
  F = "F",
  All = "all",
}

export interface UserEntity {
  id: number,
  phoneNumber: string,
  login: string,
  role: UserRole,
  firstName: Option<string>;
  lastName: Option<string>;
  email: Option<string>;
  sex: Option<UserSex>;
  age: Option<number>;
  city: Option<string>;
  description: Option<string>;
  profession: Option<string>;
  acquaintancePointer: number
  acquaintancePurpose: AcquaintancePurpose
  acquaintanceShowMeGender: AcquaintanceShowMyselfToGender
  acquaintanceNotifications: boolean
  inAcquaintances: boolean
  registeredAt: string
  onlineAt: string
  avatar: Option<IAvatar>;
  interests: IInterest[],
  sessionCompanyId: number
  customerId: null | string
  locale: string
}
