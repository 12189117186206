import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {CompanyApi} from "../../api/company";
import {ICompany} from "./types";
import {SubscriptionApi} from "../../api/subscription";
import {UserEntity} from "../../types";

const fetchSubscribers = createAsyncThunk<UserEntity[],
  number,
  {
    state: RootState
  }>(
  'company/fetchSubscribers',
  async (id, ThunkApi) => {
    const authState = ThunkApi.getState().auth
    if (authState.auth) {
      const [subscribersRes, subscribersErr] = await handle(SubscriptionApi.getSubscribersCompany(id))
      if (subscribersRes) {
        return subscribersRes
      }
      if (subscribersErr) {
        console.log('companyErr',subscribersRes, subscribersErr)
        throw {message: subscribersErr.message[0] || subscribersErr.message}
      }
    }
    throw {message: 'No phone number'}
  }
)

export default fetchSubscribers
