import React, {useMemo} from 'react';
import {useAuthState} from "../../store/auth/hooks";
import styled from "styled-components";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {useNavigate} from "react-router-dom";
import {useNotificationsState} from "../../store/notifications/hooks";
import { formatDistance } from 'date-fns';
import { ru } from 'date-fns/locale';
import {T, useTranslate} from "@tolgee/react";
import {localeLangs} from "../../UI/DataPickerRange";
import en from "date-fns/locale/en-GB";
import {ENotificationType} from "../../store/notifications/types";
import {useCompanyState} from "../../store/company/hooks";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  gap: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  width: 100%;
  padding-bottom: 13px;
`

const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px; 
  min-width: 50px; 
  height: 50px; 
  overflow: hidden;
  border-radius: 50%;
  background: #CC5151;
`

const DescriptionBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;

  ${({theme}) => theme.mediaQueries.xxl} {
    gap: 4px;
  }
`

const Title = styled(Text)`
  font-size: 16px;
  font-weight: 500;
`
const MessageBlock = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  gap: 18px;
  overflow: hidden;
  max-width: 100%;
`

const Message = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({theme}) => theme.colors.lightGray5};
  max-width: 193px;
  ${({theme}) => theme.mediaQueries.xxl} {
    max-width: 163px;
  }
  ${({theme}) => theme.mediaQueries.xl} {
    max-width: 113px;
  }
`
const TimeText = styled(Text)`
  white-space: nowrap;
  color: ${({theme}) => theme.colors.lightGray5};
  position: relative;
  &:before {
    content: ''; 
    position: absolute;
    left: -8px;
    top: 50%;
    border-radius: 50%;
    transform: translate(-100%, -50%);
    width: 2px;  
    height: 2px;
    background: ${({theme}) => theme.colors.lightGray5};
  }
`

function NotificationContact() {
  const {lang} = useAuthState()
  const {t} = useTranslate()
  const {selectedUserCompany} = useCompanyState()
  const navigate = useNavigate()
  const currentTime = new Date();
  const {notifications} = useNotificationsState()
  const notificationsFiltered = useMemo(() => {
    if (selectedUserCompany) {
      return notifications.filter(({type}) => type === ENotificationType.FavorBuying || type === ENotificationType.Text)
    }
    return notifications
  }, [notifications, selectedUserCompany])

  const lastNotification = useMemo(() => {
    if (notificationsFiltered.length > 0) {
      return notificationsFiltered[0]
    }
    return null
  }, [notificationsFiltered])

  return (
    <Wrapper onClick={() => navigate(`/chat/notification`)}>
      <Avatar>
      </Avatar>
      <DescriptionBlock>
        <Title variant={ETextVariants.Heading} as={'h3'}>
          <T keyName="common.notifications">Уведомления</T>
        </Title>
        <MessageBlock>
          <Message variant={ETextVariants.Body14} as={'p'}>
            {lastNotification?.text || t('chats.noNotifications','Уведомлений нет')}
          </Message>
          <TimeText variant={ETextVariants.Body14} as={'p'}>
            {lastNotification &&
              formatDistance(new Date(`${lastNotification?.date}`), currentTime, {
                addSuffix: true,
                locale: localeLangs[lang] || en,
              })}
          </TimeText>
        </MessageBlock>
      </DescriptionBlock>
    </Wrapper>
  );
}

export default NotificationContact;
