import React, {useCallback, useMemo} from 'react';
import styled from "styled-components";
import Table from "../../UI/Table";
import {useAdminPaymentsAction, useAdminPaymentsState} from "../../store/adminPayments/hooks";
import {Link} from 'react-router-dom';
import dayjs from "dayjs";
import {ServiceResponse} from "../../store/adminServices/types";
import {EPaymentMethod, EPaymentStatus} from "../../store/cart/types";
import {Button, EButtonVariants} from '../../UI/Button';
import {T, useTranslate} from "@tolgee/react";

const Wrapper = styled.div`
  flex-wrap: wrap;
  width: calc(100% + 80px);
  margin: 0 -40px;
  margin-top: 40px;
`

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: stretch;
  margin-top: 32px;
`

const StyledLink = styled(Link)`
  color: ${({theme}) => theme.colors.mainPurple};
  font-size: 16px;
  line-height: 1;
`

const StatusBlock = styled.div<{status: EPaymentStatus}>`
  display: flex;
  padding: 4px 8px;
  border-radius: 100px;
  font-size: 16px;
  width: fit-content;
  color: ${({status}) => {
    switch (status) {
      case EPaymentStatus.Paid:
        return '#5AB055'
      case EPaymentStatus.Cancelled:
        return '#E64646'
      default:
        return '#FF8A00'
    }
  }};
  background: ${({status}) => {
    switch (status) {
      case EPaymentStatus.Paid:
        return 'rgba(90,176,85,0.05)'
      case EPaymentStatus.Cancelled:
        return 'rgba(230,70,70,0.05)'
      default:
        return 'rgba(255,138,0,0.05)'
    }
  }};
`

export const getStatusTest = (status: string) => {
  switch (status) {
    case EPaymentStatus.AwaitCash:
      return 'Ожидание оплаты'
    case EPaymentStatus.Cancelled:
      return 'Отмена'
    case EPaymentStatus.Paid:
      return 'Оплачено'
    case EPaymentStatus.Pending:
      return 'Ожидание'
    default:
      return status
  }
}

function PaymentsCompanyListView() {
  const {t} = useTranslate()
  const {paymentsCompany, isLastPage, filter} = useAdminPaymentsState()
  const {onSelectService, onChangePage} = useAdminPaymentsAction()

  const data = useMemo(() => {
    const res: Array<{
      index: number,
      createdAt: string,
      service: ServiceResponse,
      method: EPaymentMethod,
      status: EPaymentStatus,
      commission: string,
    }> = []
    let id = (filter.page ? filter.page - 1 : 1) * (filter.limit || 20) + 1
    for (const payment of paymentsCompany.payments) {
      for (const item of payment.items) {
        const commission = (item.quantity * item.favor.price * 0.1).toFixed(2)
        const newItem = {
          index: id,
          createdAt: payment.createdAt,
          service: item.favor,
          method: payment.method,
          status: payment.status,
          commission: `${commission} $`,
        }
        res.push(newItem)
        id++
      }
    }
    return res
  }, [filter.limit, filter.page, paymentsCompany])

  return (
    <Wrapper>
        <Table
          heading={[
            {key: 'index', value: '№'},
            {key: 'createdAt', value: t('admin.companyPayments.createdAt', 'Дата')},
            {key: 'method', value: t('admin.companyPayments.method', 'Тип оплаты')},
            {key: 'title', value: t('admin.companyPayments.title', 'Услуга')},
            {key: 'address', value: t('admin.companyPayments.address', 'Адрес')},
            {key: 'status', value: t('admin.companyPayments.status', 'Статус')},
            {key: 'commission', value: t('admin.companyPayments.commission', 'Комиссия сервиса')},
          ]}
          data={data.map((payment) => ({
            ...payment,
            address: payment.service.address?.fullString || '',
            status: <StatusBlock status={payment.status}><T keyName={`paymentStatus.${payment.status}`}>{getStatusTest(payment.status)}</T></StatusBlock>,
            createdAt: dayjs(payment.createdAt).format('D MMMM YYYY / HH:mm'),
            title: <StyledLink onClick={() => onSelectService(payment.service)} to={`/admin/payments/service/${payment.service.id}`}>
              {payment.service.title}
            </StyledLink>
          }))}
        />
      <Buttons>
        <Button onClick={() => onChangePage(filter.page ? filter.page - 1 : 1)} variant={EButtonVariants.Primary} disabled={filter.page === 1}>
          <T keyName="pagination.prev">Предыдущая</T>
        </Button>
        <Button onClick={() => onChangePage(filter.page ? filter.page + 1 : 1)} variant={EButtonVariants.Primary} disabled={isLastPage}>
          <T keyName="pagination.next">Следующая</T>
        </Button>
      </Buttons>
    </Wrapper>
  );
}

export default PaymentsCompanyListView;
