import {useDispatch} from "react-redux";
import {AppDispatch} from "../index";
import {useCallback, useMemo} from "react";
import fetchSubscribeCompany from "../profile/fetchSubscribeCompany";
import fetchUnsubscribeCompany from "../profile/fetchUnsubscribeCompany";
import {useCompanyAction} from "./hooks";

export function useCompanySubscribe() {
  const dispatch = useDispatch<AppDispatch>()
  const {onFetchSubscribers} = useCompanyAction()

  const onSubscribeCompany = useCallback((id: number) => {
    dispatch(fetchSubscribeCompany(id))
      .unwrap()
      .then(() => onFetchSubscribers(id))
  }, [dispatch, onFetchSubscribers])
  const onUnsubscribeCompany = useCallback((id: number) => {
    dispatch(fetchUnsubscribeCompany(id))
      .unwrap()
      .then(() => onFetchSubscribers(id))
  }, [dispatch, onFetchSubscribers])

  return useMemo(() => ({
    onSubscribeCompany,
    onUnsubscribeCompany,
  }), [
    onSubscribeCompany,
    onUnsubscribeCompany,
  ])
}
