import React, {useEffect} from 'react';
import styled from "styled-components";
import {Container} from "../../components/Templates/Container";
import Text from "../../UI/Text";
import {Card} from "../../components/Templates/Card";
import {useAppAction} from "../../store/app/hooks";
import {T, useTranslate} from "@tolgee/react";
import {ETextVariants} from "../../UI/Text/types";
import {generateArrayFrom} from "../../utils";
import BlockTermsComponent from "../../components/PoliticsComponents/BlockTermsComponent";
import PostsRulesList from "../../components/PoliticsComponents/PostsRulesList";

const ContainerStyled = styled(Container)`
  display: flex;
  justify-content: flex-start;
  gap: 20px;
`

const Title = styled(Text)`
  font-size: 22px;
`

const CardStyled = styled(Card)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 17px;
  height: 100%;
  flex-direction: column;
  overflow: visible;
  width: 100%;
`

const DateText = styled(Text)`
  span   {
    font-style: inherit;
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    vertical-align: baseline;
    border-bottom: 1px solid ${({theme}) => theme.colors.black};
  }
`

const arrayOne = generateArrayFrom(1, 51)
const arrayTwo = generateArrayFrom(52, 2)

const LinkStyled = styled.a`
  color: ${({theme}) => theme.colors.mainPurple};
`

function Index() {
  const {onSetTitle} = useAppAction()
  const {t} = useTranslate()

  useEffect(() => {
    onSetTitle(t('terms.shortTitle'))
  }, [onSetTitle, t])

  return (
    <ContainerStyled>
      <CardStyled>
        <Title variant={ETextVariants.Heading} as={'h2'}><T keyName="terms.title"/></Title>
        <Text variant={ETextVariants.Body16}><T keyName="terms.description"/></Text>
        <BlockTermsComponent items={arrayOne.map(String)} dopParams={{
          '51': {
            a: <LinkStyled href={`mailto:Arturrr56986@gmail.com`} target="_blank" rel='noreferrer'/>,
          }
        }} />
        <BlockTermsComponent title={'geolocation'} items={arrayTwo.map(String)} />
        <BlockTermsComponent title={'posts'} items={['54']} dopContent={<PostsRulesList/>} />
        <BlockTermsComponent title={'liability'} items={['55', '56', '57']} />
        <BlockTermsComponent title={'change'} items={['58', '59']} />
        <DateText variant={ETextVariants.Body16}><T keyName="terms.publicationDate"/> <span>16.05.2024</span></DateText>
        <DateText variant={ETextVariants.Body16}><T keyName="terms.effectiveDate"/> <span>16.05.2024</span></DateText>
      </CardStyled>
    </ContainerStyled>
  )
}

export default Index;
