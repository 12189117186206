import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} >
          <path d="M21.3848 12.1924H3" stroke="#6C27AB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M12.1924 2.99999V21.3848" stroke="#6C27AB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </Svg>
    );

export default Icon;
