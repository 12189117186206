import React, {useEffect} from 'react';
import {useAppAction} from "../../store/app/hooks";
import {Navigate, Route, Routes} from "react-router-dom";
import CreatePaymentPage from "./CreatePaymentPage";
import CompletePaymentPage from "./CompletePaymentPage";
import PaymentCartPage from "./PaymentCartPage";
import {useTranslate} from "@tolgee/react";

function Index() {
  const {onSetTitle} = useAppAction()
  const {t} = useTranslate()

  useEffect(() => {
    onSetTitle(t('common.payment','Оплата'))
  }, [onSetTitle, t])

  return (
    <Routes>
      <Route path="/complete" element={<CompletePaymentPage />} />
      <Route path="/cart" element={<PaymentCartPage />} />
      <Route path="/one" element={<PaymentCartPage isAvailablePaymentCash={true} />} />
      <Route path="/:id" element={<CreatePaymentPage />} />
      <Route path="*" element={<Navigate to="/" replace={true}/>} />
    </Routes>
  )
}

export default Index;
