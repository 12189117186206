import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="53" height="52" viewBox="0 0 53 52" fill="none" {...props} >
          <path d="M21.5 33.5L13.8119 41.2842C12.7395 42.37 12.2033 42.9129 11.7424 42.9512C11.3425 42.9845 10.951 42.8237 10.6899 42.5191C10.3889 42.168 10.3889 41.4049 10.3889 39.8788V35.979C10.3889 34.6099 9.26768 33.6192 7.91301 33.4208V33.4208C4.63436 32.9406 2.05944 30.3656 1.57919 27.087C1.5 26.5464 1.5 25.9013 1.5 24.6111V13C1.5 8.79961 1.5 6.69941 2.31745 5.09507C3.0365 3.68386 4.18386 2.5365 5.59507 1.81745C7.19941 1 9.29961 1 13.5 1H32C36.2004 1 38.3006 1 39.9049 1.81745C41.3161 2.5365 42.4635 3.68386 43.1825 5.09507C44 6.69941 44 8.79961 44 13V23.5M44 51L38.559 47.2172C37.7941 46.6854 37.4117 46.4196 36.9955 46.2311C36.6261 46.0638 36.2377 45.942 35.8389 45.8685C35.3896 45.7857 34.9238 45.7857 33.9923 45.7857H29.5C26.6997 45.7857 25.2996 45.7857 24.23 45.2407C23.2892 44.7614 22.5243 43.9965 22.045 43.0557C21.5 41.9861 21.5 40.586 21.5 37.7857V31.5C21.5 28.6997 21.5 27.2996 22.045 26.23C22.5243 25.2892 23.2892 24.5243 24.23 24.045C25.2996 23.5 26.6997 23.5 29.5 23.5H43.5C46.3003 23.5 47.7004 23.5 48.77 24.045C49.7108 24.5243 50.4757 25.2892 50.955 26.23C51.5 27.2996 51.5 28.6997 51.5 31.5V38.2857C51.5 40.6154 51.5 41.7803 51.1194 42.6991C50.6119 43.9243 49.6386 44.8976 48.4134 45.4051C47.4946 45.7857 46.3297 45.7857 44 45.7857V51Z" stroke="#454545" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </Svg>
    );

export default Icon;
