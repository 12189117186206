import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} >
          <path d="M12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM7.612 16.0915C7.23534 15.6876 7.25744 15.0548 7.66135 14.6781C8.06527 14.3015 8.69805 14.3236 9.07471 14.7275C10.5813 16.3432 13.1125 16.4315 14.7281 14.9249C14.7962 14.8614 14.8619 14.7959 14.925 14.7284C15.3022 14.3251 15.9351 14.3039 16.3384 14.6812C16.7417 15.0585 16.7629 15.6913 16.3856 16.0946C16.2914 16.1953 16.1935 16.293 16.0921 16.3876C13.6686 18.6476 9.87194 18.515 7.612 16.0915ZM15.375 11.25C14.4775 11.25 13.75 10.5225 13.75 9.625C13.75 8.72754 14.4775 8 15.375 8C16.2725 8 17 8.72754 17 9.625C17 10.5225 16.2725 11.25 15.375 11.25ZM8.625 11.25C7.72754 11.25 7 10.5225 7 9.625C7 8.72754 7.72754 8 8.625 8C9.52246 8 10.25 8.72754 10.25 9.625C10.25 10.5225 9.52246 11.25 8.625 11.25Z" fill="#6C27AB"/>
        </Svg>
    );

export default Icon;
