import React, {useMemo} from 'react';
import AdminHeader from "../../../components/AdminComponents/AdminHeader";
import styled from "styled-components";
import {useAdminCompanies, useAdminCompaniesState} from "../../../store/adminCompanies/hooks";
import AdminFirm from "../../../components/AdminComponents/AdminFirm";
import {ECompanyStatus} from "../../../store/company/types";
import {useTranslate} from "@tolgee/react";

const List = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  gap: 20px;
  margin-top: 20px;
  flex-wrap: wrap;
`

function Index() {
  const {t} = useTranslate()
  useAdminCompanies()
  const {companies} = useAdminCompaniesState()
  const filterCompanies = useMemo(() => companies.filter((company) => company.status === ECompanyStatus.pending), [companies])
  return (
    <>
      <AdminHeader title={t('admin.firms.application', 'Заявки на добавление')}/>
      <List>
        {
          filterCompanies.map((company) => <AdminFirm key={company.id} {...company} />)
        }
      </List>
    </>
  );
}

export default Index;
