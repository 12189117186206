import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {UserApi} from "../../api/user";
import {IAdminUsersFilter} from "./types";
import {UserEntity} from "../../types";

const fetchUsers = createAsyncThunk<UserEntity[],
  void,
  {
    state: RootState
  }>(
  'adminUsers/fetchUsers',
  async (_, ThunkApi) => {
    const authState = ThunkApi.getState().auth
    const {filter} = ThunkApi.getState().adminUsers
    const filterData: any = {}
    for (const filterKey in filter) {
      const field = filterKey as keyof IAdminUsersFilter
      if (filter[field] !== undefined && filter[field]) {
        if (field === 'dateTo' || field === 'dateFrom') {
          const date = new Date(String(filter[field]))
          date.setDate(date.getDate() + 1)
          if (field === 'dateTo') {
            date.setUTCHours(23,59,59,999)
          } else {
            date.setUTCHours(0,0,0,0)
          }
          filterData[field] = date
        } else {
          filterData[field] = filter[field]
        }
      }
    }

    if (!filterData.country === undefined && filterData.city) {
      delete filterData.country
      delete filterData.city
    }
    if (authState.auth) {
      const [usersRes, usersErr] = await handle(UserApi.getUsers(filterData))
      if (usersRes) {
        return usersRes
      }
      if (usersErr) {
        throw {message: usersErr.message[0] || usersErr.message}
      }
    }
    throw {message: 'No user state'}
  }
)

export default fetchUsers
