import {axiosInstance} from "./index";
import {IAdminSupportsFilter} from "../store/adminSupports/types";

export const ReportApi = {
    postReport: async (id: string, body: string) => await axiosInstance.post(`report/${id}`, {body}),
    getReports: async (data: IAdminSupportsFilter) => await axiosInstance.get(`report`, {params: data}),
    getReportsById: async (id: string) => await axiosInstance.get(`report/${id}`),
    putApproveReport: async (id: string) => await axiosInstance.put(`report/${id}/approve`),
    putRejectReport: async (id: string) => await axiosInstance.put(`report/${id}/reject`),
}
