import React, {useEffect, useMemo, useState} from 'react';
import styled from "styled-components";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import Select from "../../UI/Select";
import {DataPickerRange} from "../../UI/DataPickerRange";
import {PlusIcon, SearchIcon} from "../../UI/Svg";
import Input from "../../UI/Input";
import {useEventsState} from "../../store/events/hooks";
import {useAdminCompaniesAction, useAdminCompaniesState} from "../../store/adminCompanies/hooks";
import {Button, EButtonVariants} from "../../UI/Button";
import {Link} from "react-router-dom";
import {useGeoState} from "../../store/geo/hooks";
import {T, useTranslate} from "@tolgee/react";

const Wrapper = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
`

const FilterWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

const ActionsBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
`

const Filters = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  position: relative;
`

const DataPickerRangeStyled = styled(DataPickerRange)`
  min-width: 178px;
`

const InputStyled = styled(Input)`
  background: ${({theme}) => theme.colors.white};
`

function FirmsFilterView() {
  const {t} = useTranslate()
  const {filter} = useAdminCompaniesState()
  const {onChangeFilter} = useAdminCompaniesAction()
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const [startDate, endDate] = useMemo(() => dateRange, [dateRange]);

  useEffect(() => {
    if (startDate) {
      onChangeFilter({dateFrom: startDate.toString()})
    } else {
      onChangeFilter({dateFrom: undefined})
    }
    if (endDate) {
      onChangeFilter({dateTo: endDate.toString()})
    } else {
      onChangeFilter({dateTo: undefined})
    }
  }, [startDate, endDate, onChangeFilter])

  return (
    <Wrapper>
      <Text variant={ETextVariants.Body16}><T keyName="admin.filter">Фильтр:</T></Text>
      <FilterWrapper>
      <Filters>
        <DataPickerRangeStyled
          setDateRange={setDateRange}
          startDate={startDate}
          endDate={endDate}
          bg
          isClearButton={true}
          isIcon={!startDate && !endDate}
          typeArrowPurple={true}
          mobileVersion={false}
        />
        <InputStyled
          placeholder={t('common.search', 'Поиск')}
          icon={<SearchIcon/>}
          value={filter.search}
          onChange={(event) => onChangeFilter({search: event.target.value})}
        />
      </Filters>
        <ActionsBlock>
          <Button as={Link} to={'/admin/firms/approve-list'} variant={EButtonVariants.Primary}>
            <T keyName="admin.firms.application">Заявки на добавление</T>
          </Button>
          <Button as={Link} to={'/admin/firms/add'} variant={EButtonVariants.Primary}>
            <T keyName="admin.firms.buttons.add">+ Добавить фирму</T>
          </Button>
        </ActionsBlock>
      </FilterWrapper>
    </Wrapper>
  );
}

export default FirmsFilterView;
