import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {IAdminPaymentsFilter} from "./types";
import {IPayment} from "../cart/types";
import {PurchaseApi} from "../../api/purchase";

const fetchServicePayments = createAsyncThunk<IPayment[],
  number,
  {
    state: RootState
  }>(
  'adminPayments/fetchServicePayments',
  async (id, ThunkApi) => {
    const authState = ThunkApi.getState().auth
    const {filter} = ThunkApi.getState().adminPayments
    const filterData: IAdminPaymentsFilter = {}
    for (const filterKey in filter) {
      const field = filterKey as keyof IAdminPaymentsFilter
      if (filter[field] !== undefined && filter[field] !== '') {
        // @ts-ignore
        filterData[field] = filter[field]
      }
    }
    if (authState.auth) {
      const [paymentsRes, paymentsErr] = await handle(PurchaseApi.getAllServicePayments(id, filterData))
      if (paymentsRes) {
        return paymentsRes
      }
      if (paymentsErr) {
        throw {message: paymentsErr.message[0] || paymentsErr.message}
      }
    }
    throw {message: 'No user state'}
  }
)

export default fetchServicePayments
