import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {InterestsApi} from "../../api/interests";


const fetchCreateInterests = createAsyncThunk<void,
  string,
  {
    state: RootState
  }>(
  'profile/fetchCreateInterests',
  async (title, ThunkApi) => {
    const authState = ThunkApi.getState().auth
    if (authState.auth) {
      const [_, interestsErr] = await handle(InterestsApi.postCreateInterest(title))
      if (interestsErr) {
        throw {message: interestsErr.message[0] || interestsErr.message}
      }
    } else {
      throw {message: 'No user state'}
    }
  }
)

export default fetchCreateInterests
