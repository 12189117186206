import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {EPaymentMethod, IPayment} from "./types";
import {PurchaseApi} from "../../api/purchase";

const fetchCreatePayment = createAsyncThunk<{ method: EPaymentMethod, isGift: boolean } | null,
  { method: EPaymentMethod, isOne: boolean },
  {
    state: RootState
  }>(
  'cart/fetchCreatePayment',
  async ({method, isOne}, ThunkApi) => {
    const authState = ThunkApi.getState().auth
    const {checkedItemsIds, phone, dataOnePayment} = ThunkApi.getState().cart
    let payments: IPayment[] | IPayment = []
    let res = {method, isGift: false}
    if (authState.auth) {
      if (phone) {
        const [paymentsRes, paymentsErr] = await handle(PurchaseApi.postBuyGift(checkedItemsIds, `+${phone}`))
        if (paymentsRes) {
          payments = paymentsRes
          res = {method, isGift: true}
        }
        if (paymentsErr) {
          throw {message: paymentsErr.message[0] || paymentsErr.message}
        }
      } else if (isOne && dataOnePayment !== null) {
        const [paymentsRes, paymentsErr] = await handle(PurchaseApi.postBuyOne(dataOnePayment.serviceId, method, dataOnePayment.quantity))
        if (paymentsRes) {
          payments = paymentsRes
          res = {method, isGift: false}
        }
        if (paymentsErr) {
          throw {message: paymentsErr.message[0] || paymentsErr.message}
        }
        res = {method, isGift: false}
      } else {
        const [paymentsRes, paymentsErr] = await handle(PurchaseApi.postBuySelf(checkedItemsIds, method))
        if (paymentsRes) {
          payments = paymentsRes
          res = {method, isGift: false}
        }
        if (paymentsErr) {
          throw {message: paymentsErr.message[0] || paymentsErr.message}
        }
      }
      if (method !== EPaymentMethod.Cash) {
        if (Array.isArray(payments)) {
          for (const payment of payments) {
            const [_, paymentErr] = await handle(PurchaseApi.putConfirmPayment(payment.id))
            if (paymentErr) console.log(payment.id, paymentErr)
          }
        } else {
          const [_, paymentErr] = await handle(PurchaseApi.putConfirmPayment(payments.id))
          if (paymentErr) console.log(payments.id, paymentErr)
        }
      }
      return res
    }
    throw {message: 'No user state'}
  }
)

export default fetchCreatePayment
