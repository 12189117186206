import styled from "styled-components";
import {TextProps} from "./types";

const Index = styled.div<TextProps>`
  display: inline;
  font-size: ${({theme, variant}) => theme.text[variant].fontSize};
  font-weight:  ${({theme, variant}) => theme.text[variant].fontWeight};
  font-style: ${({theme, variant}) => theme.text[variant].fontStyle};
  line-height: ${({theme, variant}) => theme.text[variant].lineHeight};
  letter-spacing: ${({theme, variant}) => theme.text[variant].letterSpacing};
  color: ${({theme, variant}) => theme.text[variant].color};
  ${({theme, variant}) => theme.text[variant].placeholder ? `&::placeholder: {${theme.text[variant].placeholder}` : ''}
`

export default Index
