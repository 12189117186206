import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {ForbiddenApi} from "../../api/forbidden";

const fetchPostWords = createAsyncThunk<void,
  string,
  {
    state: RootState
  }>(
  'adminForbiddenWords/fetchPostWords',
  async (word, ThunkApi) => {
    const authState = ThunkApi.getState().auth
    if (authState.auth) {
      const [wordsRes, wordsErr] = await handle(ForbiddenApi.postWord(word))
      if (wordsRes) {
        return
      }
      if (wordsErr) {
        throw {message: wordsErr.message[0] || wordsErr.message}
      }
    }
    throw {message: 'No user state'}
  }
)

export default fetchPostWords
