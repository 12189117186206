import React, {useEffect} from 'react';
import styled from "styled-components";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {Link, useLocation} from "react-router-dom";
import * as IconModule from "../../UI/Svg";
import {LogoutIcon, SettingsAdminIcon, SvgProps} from "../../UI/Svg";
import {adminLinks} from "../../config/adminLinks";
import {useAuthAction} from "../../store/auth/hooks";
import {useEventsAction} from "../../store/events/hooks";
import {useGeoAction} from "../../store/geo/hooks";
import {T} from "@tolgee/react";

const Icons = (IconModule as unknown) as { [key: string]: React.FC<SvgProps> };

const Wrapper = styled.div`
  width: 100%;
  padding: 40px 30px 150px;
  background: ${({theme}) => theme.colors.mainPurple};
  max-width: 324px;
  top: 0;
  left: 0;
  bottom: 0;
  position: fixed;
`

const Content = styled.div`
  gap: 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
`

const Title = styled(Text)`
  width: 100%;
  display: inline;
  text-align: center;
  font-size: 35px;
  color: ${({theme}) => theme.colors.white};
`

const Links = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
  flex: 1;
`
const LinksFooter = styled(Links)`
  flex: unset;
`

const LinkStyled = styled(Link)<{ $isActive: boolean }>`
  opacity: ${({$isActive}) => $isActive ? 1 : 0.5};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;

  ${Text} {
    color: ${({theme}) => theme.colors.white};
    font-weight: 500;
  }

  &:hover {
    opacity: 1;
  }
`

function Index() {
  const {onLogout} = useAuthAction()
  const pathname = useLocation().pathname
  const {onFetchCountries} = useGeoAction()

  useEffect(() => {
    onFetchCountries()
  }, [
    onFetchCountries,
  ])
  return (
    <Wrapper>
      <Content>
        <Title variant={ETextVariants.Heading} as='h1'>Trip House</Title>
        <Links>
          {
            adminLinks.map((link, id) => {
              const Icon = Icons[link.icon];
              const iconElement = <Icon/>;
              const isActive = link.href === pathname
              return (<LinkStyled $isActive={isActive} to={link.href} key={id}>
                {iconElement}
                <Text variant={ETextVariants.Body16}><T keyName={`admin.menu.${link.key}`}>{link.title}</T></Text>
              </LinkStyled>)
            })
          }
        </Links>
        <LinksFooter>
          <LinkStyled $isActive={false} to={'/admin/setting'}>
            <SettingsAdminIcon/>
            <Text variant={ETextVariants.Body16}>
              <T keyName="common.settings">Настройки</T>
            </Text>
          </LinkStyled>
          <LinkStyled $isActive={false} to={'/auth'} onClick={(event: any) => {
            event.preventDefault()
            onLogout()
          }}>
            <LogoutIcon/>
            <Text variant={ETextVariants.Body16}>
              <T keyName="common.exit">Выйти</T>
            </Text>
          </LinkStyled>
        </LinksFooter>
      </Content>
    </Wrapper>
  );
}

export default Index;
