import React from 'react';
import styled from "styled-components";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {CloudChatIcon} from "../../UI/Svg";
import {T} from "@tolgee/react";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`

const MainText = styled(Text)`
  text-align: center;
  display: inline-block;
`

const IconWrapper = styled.div`
  justify-content: center;
  margin-bottom: 15px;

  svg {
    width: 50px;
  }
`

const CreteText = styled(Text)`
  color: ${({theme}) => theme.colors.mainPurple};
  margin-left: 5px;
  cursor: pointer;
`

function NoChat({startCreateChat}: { startCreateChat: () => void }) {
  return (
    <Wrapper>
      <MainText variant={ETextVariants.Body16}>
        <IconWrapper>
          <CloudChatIcon/>
        </IconWrapper>
        <T
          keyName="chats.chooseChat"
          params={{
            br: <br/>
          }}
          defaultValue="Выберите чат <br /> или"
        />
        <CreteText variant={ETextVariants.Body16} onClick={startCreateChat}>
          <T keyName="chats.createNew">создайте новый</T>
        </CreteText>
      </MainText>
    </Wrapper>
  );
}

export default NoChat;
