import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {ITicket} from "./types";
import {PurchaseApi} from "../../api/purchase";

const fetchDeleteTicket = createAsyncThunk<ITicket[],
  number,
  {
    state: RootState
  }>(
  'cart/fetchDeleteTicket',
  async (id, ThunkApi) => {
    const authState = ThunkApi.getState().auth
    if (authState.auth) {
      const [ticketRes, ticketErr] = await handle(PurchaseApi.deleteTicket(id))
      if (ticketRes) {
        return ticketRes
      }
      if (ticketErr) {
        throw {message: ticketErr.message || ticketErr.message[0]}
      }
      return
    }
    throw {message: 'No user state'}
  }
)

export default fetchDeleteTicket
