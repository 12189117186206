import React from 'react';
import styled from "styled-components";
import {mediaUrl} from "../../api";
import {ICartItem} from "../../store/cart/types";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {CalendarIcon, ClockIcon, GeoGrayIcon, PriceIcon, TrashIcon} from "../../UI/Svg";
import dayjs from "dayjs";
import imgEmptyAvatar from "../../assets/img/no-avatar.png";
import {EButtonVariants} from "../../UI/Button";
import {useCartAction} from "../../store/cart/hooks";
import {
  CompanyBlock,
  CompanyLogo,
  Content,
  EventImage,
  Row,
  TitleBlock,
  Wrapper,
  CompanyDesc,
  ButtonsBlock,
  CompanyTitle,
  CompanySubtitle,
  DeleteButton,
  Item,
  Description,
  List,
  TextStyled, CheckboxItem, ItemRow, ItemButtonsBlock, ItemDesktopButtonsBlock
} from './styled';
import {currencyDesc} from "../../config/currency";
import FormCountComponent from "../ServicesComponents/FormCountComponent";
import pngChecked from "../../assets/img/checked.svg";
import {Link} from "react-router-dom";
import {T, useTranslate} from "@tolgee/react";


function CartItemComponent({item: {favor, quantity, id}, onCheck, isChecked}: { item: ICartItem, isChecked: boolean, onCheck: (id: number) => void }) {
  const {t} = useTranslate();
  const {onRemoveServiceCart, onUpdateCartItem} = useCartAction()
  return (
    <Wrapper>
      <ItemRow>
        <CheckboxItem onClick={() => onCheck(id)}>
          {isChecked && <img src={pngChecked} alt="Checked"/>}
        </CheckboxItem>
        <EventImage>
          {favor.header && <img src={favor.header ? `${mediaUrl}file/${favor.header.id}` : ''} alt=""/>}
        </EventImage>
        <Content>
          <TitleBlock >
            <Link to={`/service/${favor.id}`}><Text variant={ETextVariants.Heading} as={'h2'}>{favor.title}</Text></Link>
            {favor.company && <CompanyBlock to={`/company/${favor.company.id}`}>
                <CompanyLogo>
                    <img src={favor.company.avatar ? `${mediaUrl}file/${favor.company.avatar.id}` : imgEmptyAvatar}
                         alt=""/>
                </CompanyLogo>
                <CompanyDesc>
                    <CompanyTitle variant={ETextVariants.Body14} as={'h4'}>{favor.company.title}</CompanyTitle>
                    <CompanySubtitle><T keyName="cart.item.company">фирма</T></CompanySubtitle>
                </CompanyDesc>
            </CompanyBlock>}
          </TitleBlock>
          <Description variant={ETextVariants.Body16}>{favor.description}</Description>

          <List>
            {favor.expirationDate && <Item>
              <CalendarIcon/>
              <TextStyled
                variant={ETextVariants.Body16}> {dayjs(favor.expirationDate).format('D MMMM / HH:mm')}</TextStyled>
            </Item>}

            <Item>
              <PriceIcon/>
              <TextStyled
                variant={ETextVariants.Body16}>{favor.price}
                <T keyName={`currencies.${favor.currency}.symbol`}>{currencyDesc[favor.currency].symbol}</T>&nbsp;
                <T keyName="cart.item.price" >за человека</T>
                </TextStyled>
            </Item>
            {favor.address && <Item>
                <GeoGrayIcon/>
                <TextStyled variant={ETextVariants.Body16}>{favor.address.fullString}</TextStyled>
            </Item>}

          </List>

          <ItemDesktopButtonsBlock>
            <DeleteButton onClick={() => onRemoveServiceCart(id)}>
              <TrashIcon/>
            </DeleteButton>
            <FormCountComponent value={quantity} label={t('common.tickets', 'Билеты')} onIncrement={() => onUpdateCartItem({itemId: id, quantity: quantity + 1})}
                                onDecrement={() => {
                                  if (quantity - 1 >= 1) {
                                    onUpdateCartItem({
                                      itemId: id,
                                      quantity: quantity - 1
                                    })
                                  }
                                }}/>
          </ItemDesktopButtonsBlock>
        </Content>
      </ItemRow>

      <ItemButtonsBlock>
        <DeleteButton onClick={() => onRemoveServiceCart(id)}>
          <TrashIcon/>
        </DeleteButton>
        <FormCountComponent value={quantity} label={t('common.tickets', 'Билеты')} onIncrement={() => onUpdateCartItem({itemId: id, quantity: quantity + 1})}
                            onDecrement={() => {
                              if (quantity - 1 >= 1) {
                                onUpdateCartItem({
                                  itemId: id,
                                  quantity: quantity - 1
                                })
                              }
                            }}/>
      </ItemButtonsBlock>
    </Wrapper>
  );
}

export default CartItemComponent;
