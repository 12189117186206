import {createGlobalStyle} from 'styled-components';
import './_fonts.scss';
import {TripHouseTheme} from "../theme";

declare module 'styled-components' {
  export interface DefaultTheme extends TripHouseTheme {
  }
}


const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'SFUIDisplay';
    transition: all .25s ease;
    color: ${({theme}) => theme.colors.darkWhite};
  }

  html {
    scroll-behavior: smooth;
    font-size: 10px;
    height: 100%;
  }

  body {
    background: ${({theme}) => theme.colors.bg};
    height: 100%;

    img {
      height: auto;
      //max-width: 100%;
    }
  }

  #root {
    max-width: 100vw;
    min-height: 100vh;
    overflow-x: hidden;
    position: relative;
    padding: 0;
    ${({theme}) => theme.mediaQueries.md} {
      height: 100%;
      min-height: unset;
      //overflow: hidden;
    }
  }

`;

export default GlobalStyle;
