import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Link} from "react-router-dom";
import styled from "styled-components";
import {Card} from "../Templates/Card";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {EditIcon, GeoGrayIcon} from "../../UI/Svg";

import 'dayjs/locale/ru';
import {mediaUrl} from "../../api";
import {Button, EButtonVariants} from "../../UI/Button";
import {IUserCompany} from "../../store/company/types";
import {getUserShortName} from "../../utils/user";
import {useAdminCompaniesAction} from "../../store/adminCompanies/hooks";
import {T, useTranslate} from "@tolgee/react";

const Wrapper = styled(Card)`
  position: relative;
  width: 100%;
  max-width: calc(50% - 10px);
  display: flex;
  align-items: stretch;
  gap: 15px;
  flex-direction: column;

  ${({theme}) => theme.mediaQueries.md} {
    max-width: 100%;
  }
`

const CompanyImage = styled.div`
  display: flex;
  align-items: center;
  background: ${({theme}) => theme.colors.bg};
  justify-content: center;
  width: 148px;
  border-radius: 10px;
  overflow: hidden;
  height: 120px;

  img {
    width: auto;
    height: 100%;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;

  ${({theme}) => theme.mediaQueries.md} {
    gap: 7px;
  }
`

const Categories = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  ${({theme}) => theme.mediaQueries.md} {
  }
`

const Category = styled(Text)`
  padding: 5px 10px;
  background: ${({theme}) => theme.colors.lightGray2};
  border-radius: 5px;
`

const Description = styled(Text)`
  max-height: 60px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  width: 65%;

  ${({theme}) => theme.mediaQueries.md} {
  }
`

const List = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
`

const Item = styled.li`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;

  text-overflow: ellipsis;
  max-width: 180px;
  overflow: hidden;
  white-space: nowrap; 

  svg {
    min-width: 24px;
    height: 24px;
  }

  ${({theme}) => theme.mediaQueries.md} {
    svg {
      min-width: 20px;
      height: 20px;
    }
  }
`

const TextStyled = styled(Text)`
  ${({theme}) => theme.mediaQueries.md} {
    font-size: 12px;
  }
`
const Creator = styled(TextStyled)`
`

const CreatorLink = styled(Link)`
  color: ${({theme}) => theme.colors.mainPurple};
`

const Details = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 20px;
  margin-top: 15px;

  ${({theme}) => theme.mediaQueries.md} {
    grid-column-gap: 10px;
  }
`

const DetailBlock = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
  flex-direction: column;
`

const Label = styled(Text)`
  color: ${({theme}) => theme.colors.mainGray};
`

const Buttons = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 15px;
`

const ButtonStyled = styled(Button)`
  padding: 11.5px 16px;
  font-size: 14px;
`

const EditButton = styled(Link)`
  position: absolute;
  top: 24px;
  right: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    path {
      stroke: ${({theme}) => theme.colors.mainPurple};
    }
  }
`

function AdminService({id, description, address, owner, header, title}: IUserCompany) {
  const {t} = useTranslate()
  const linkRef = useRef(null)
  const {onApproveCompany, onRejectCompany} = useAdminCompaniesAction()

  const onApproveCompanyHandle = useCallback(() => {
    if (window.confirm(t('admin.firm.confirmApprove', 'Вы действительно хотите опубликовать компанию?'))) {
      onApproveCompany(id)
    }
  }, [t, id, onApproveCompany])
  const onRejectCompanyHandle = useCallback(() => {
    if (window.confirm(t('admin.firm.confirmReject', 'Вы действительно хотите отклонить компанию?'))) {
      onRejectCompany(id)
    }
  }, [t, id, onRejectCompany])

  return (
    <Wrapper>
      <EditButton to={`/admin/firms/edit/${id}`}>
        <EditIcon/>
      </EditButton>
      <Content>
        <Text variant={ETextVariants.Heading} as={'h2'}>{title}</Text>
        <Description variant={ETextVariants.Body16}>{description}</Description>
      </Content>
      <Details>
        <DetailBlock>
          <Label variant={ETextVariants.Body14}>
            <T keyName="admin.firm.details">Детали компании:</T>
          </Label>
          <List>
            <Item>
              <GeoGrayIcon/>
              <TextStyled variant={ETextVariants.Body16}>{address?.fullString}</TextStyled>
            </Item>
          </List>
          <Creator variant={ETextVariants.Body16}>
            <T keyName="admin.firm.creator">Создатель:</T> <CreatorLink ref={linkRef} to={`/profile/${owner.id}`}>{getUserShortName(owner)}</CreatorLink>
          </Creator>
        </DetailBlock>
        <DetailBlock>
          <Label variant={ETextVariants.Body14}>
            <T keyName="admin.firm.photo">Фото компании:</T>
          </Label>
          <CompanyImage>
            <img src={header ? `${mediaUrl}file/${header.id}` : ''} alt=""/>
          </CompanyImage>
        </DetailBlock>
        <DetailBlock>
          <Label variant={ETextVariants.Body14}/>
          <Buttons>
            <ButtonStyled onClick={onApproveCompanyHandle} variant={EButtonVariants.Success}>
              <T keyName="admin.firm.skip">Пропустить</T>
            </ButtonStyled>
            <ButtonStyled onClick={onRejectCompanyHandle} variant={EButtonVariants.Error}>
              <T keyName="common.delete">Удалить</T>
            </ButtonStyled>
          </Buttons>
        </DetailBlock>
      </Details>
    </Wrapper>
  );
}

export default AdminService;
