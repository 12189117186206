import {axiosInstance} from "./index";
import {CreateCompanyPayload, UpdateCompanyPayload} from "../store/company/types";
import {IAdminCompaniesFilter} from "../store/adminCompanies/types";

export const CompanyApi = {
  getCompanies: async () => await axiosInstance.get('company/my'),
  getAdminCompanies: async (filter: IAdminCompaniesFilter) => await axiosInstance.get('company/all', {params: filter}),
  getCompany: async (id: number) => await axiosInstance.get(`company/${id}`),
  deleteCompany: async (id: number) => await axiosInstance.delete(`company/${id}`),
  puRejectCompany: async (id: number) => await axiosInstance.put(`company/${id}/reject`),
  putApproveCompany: async (id: number) => await axiosInstance.put(`company/${id}/approve`),
  postCreate: async (data: CreateCompanyPayload) => await axiosInstance.post('company', data),
  postCreateAdmin: async (data: CreateCompanyPayload) => await axiosInstance.post('company/admin', data),
  putUpdate: async (data: UpdateCompanyPayload) => await axiosInstance.put(`company/${data.id}`, data),
  putSetAvatar: async (file: File, id: number) => await axiosInstance.put(`company/${id}/avatar`, {file}, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }),
  putSetHeader: async (file: File, id: number) => await axiosInstance.put(`company/${id}/header`, {file}, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }),
}
