export enum ETextVariants {
    Button,
    Body16,
    Body14,
    MiniGray,
    MiniDark,
    Heading,
    Error,
}

export type TextProps = {
    variant: ETextVariants;
    as?: "h1" | "p" | "div" | "label";
};

export type TextThemeVariant = {
    fontSize: string,
    fontWeight: number,
    fontStyle: string,
    lineHeight: string,
    letterSpacing: string,
    color: string,
    'placeholder'?: {
        color: string,
        opacity: number,
    },
};

export type TextTheme = {
    [key in ETextVariants]: TextThemeVariant;
};
