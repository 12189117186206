import React from 'react';
import styled from "styled-components";
import {Card} from "../../components/Templates/Card";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {useWallState} from "../../store/wall/hooks";
import UserPostComponent from "../../components/ProfileComponents/UserPostComponent";
import CompanyPostComponent from "../../components/ProfileComponents/CompanyPostComponent";
import {T} from "@tolgee/react";

const CardStyled = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 10px;

  ${({theme}) => theme.mediaQueries.md} {
    gap: 2px;
    background: transparent;
    padding: 0;
  }
`

const CompanyPostStyled = styled(CompanyPostComponent)`
  padding: 0;
  ${({theme}) => theme.mediaQueries.md} {
    padding: 12px 16px;
  }
`

const TextStyled = styled(Text)`
  ${({theme}) => theme.mediaQueries.md} {
    padding: 12px 16px;
  }
`

function ProfileWall() {
  const {wall} = useWallState()
  return (
    <>
      <CardStyled>
        {
          wall?.length
            ? wall.map((item) => {
              if (item.wallType === 'company' && item.company) {
                return <CompanyPostStyled key={item.id} item={item} companyId={item.company.id}/>
              } else {
                return <UserPostComponent key={item.id} item={item}/>
              }
            })
            : <TextStyled variant={ETextVariants.Body16}><T keyName="common.noNews">Новостей нет :(</T></TextStyled>
        }
      </CardStyled>
    </>
  );
}

export default ProfileWall;
