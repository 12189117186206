import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {ReportApi} from "../../api/report";

const fetchRejectReport = createAsyncThunk<void,
  string,
  {
    state: RootState
  }>(
  'adminSupports/fetchRejectReport',
  async (id, ThunkApi) => {
    const authState = ThunkApi.getState().auth
    if (authState.auth) {
      const [_, reportErr] = await handle(ReportApi.putRejectReport(id))
      if (reportErr) {
        throw {message: reportErr.message || reportErr }
      }
      return
    }
    throw {message: 'No user state'}
  }
)

export default fetchRejectReport
