import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IAdminCompaniesFilter} from "./types";
import fetchCompanies from "./fetchCompanies";
import {IUserCompany} from "../company/types";
import fetchCreateCompanyAdmin from "./fetchCreateCompanyAdmin";

export type AdminCompaniesState = {
    filter: IAdminCompaniesFilter,
    companies: IUserCompany[],
    isLoading: boolean,
    isPending: boolean,
}

const initialState: AdminCompaniesState = {
    filter: {},
    companies: [],
    isLoading: true,
    isPending: false,
}

export const adminCompaniesSlice = createSlice({
    name: 'adminCompanies',
    initialState,
    reducers: {
        changeFilter: (state, action: PayloadAction<IAdminCompaniesFilter>) => {
            state.filter = {...state.filter, ...action.payload}
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchCreateCompanyAdmin.pending, (state, ) => {
            state.isPending = true
        })
        builder.addCase(fetchCreateCompanyAdmin.rejected, (state, ) => {
            state.isPending = false
        })
        builder.addCase(fetchCreateCompanyAdmin.fulfilled, (state, ) => {
            state.isPending = false
        })
        builder.addCase(fetchCompanies.pending, (state, ) => {
            state.isLoading = true
        })
        builder.addCase(fetchCompanies.rejected, (state, ) => {
            state.isLoading = false
        })
        builder.addCase(fetchCompanies.fulfilled, (state, action: PayloadAction<IUserCompany[]>) => {
            state.companies = [...action.payload]
            state.isLoading = false
        })
    }
})

export const {changeFilter} = adminCompaniesSlice.actions
export default adminCompaniesSlice.reducer
