import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {EventsApi} from "../../api/events";
import {EventResponse, IEventsFilter} from "./types";
import dayjs from "dayjs";
import {sleep} from "../../utils";

const fetchEvents = createAsyncThunk<EventResponse[],
  void,
  {
    state: RootState
  }>(
  'events/fetchEvents',
  async (_, ThunkApi) => {
    const authState = ThunkApi.getState().auth
    const {filter} = ThunkApi.getState().events
    const filterData: any = {}
    for (const filterKey in filter) {
      const field = filterKey as keyof IEventsFilter
      if (field === 'radius' && filter[field] === '0') {
        continue
      }
      if (filter[field] !== undefined && filter[field]) {
        if (field === 'dateTo' || field === 'dateFrom') {
          const date = new Date(filter[field])
          date.setDate(date.getDate() + 1)
          if (field === 'dateTo') {
            date.setUTCHours(23,59,59,999)
          } else {
            date.setUTCHours(0,0,0,0)
          }
          filterData[field] = date
          // filterData[field] = dayjs(filter[field]).format("YYYY-MM-DD")
        } else if (field === 'radius') {
          // @ts-ignore
          filterData[field] = parseFloat(filter[field]) / 1000
        } else {
          filterData[field] = filter[field]
        }
      }
    }
    if (!filterData['radius'] || filterData['radius'] === '0') {
      delete filterData['latitude']
      delete filterData['longitude']
    }
    if (!filterData.country === undefined && filterData.city) {
      delete filterData.country
      delete filterData.city
    }
    if (authState.auth) {
      const [eventsRes, eventsErr] = await handle(EventsApi.getEvents(filterData))
      if (eventsRes) {
        await sleep(250)
        return eventsRes
      }
      if (eventsErr) {
        throw {message: eventsErr.message[0] || eventsErr.message}
      }
    }
    throw {message: 'No user state'}
  }
)

export default fetchEvents
