import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Navigate, useNavigate, useParams} from "react-router-dom";
import {useChatAction, useChatState} from "../../store/chat/hooks";
import styled from "styled-components";
import HeaderDialogView from "../../views/ChatViews/HeaderDialogView";
import InputDialog from "../../components/ChatComponents/InputDialog";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import Message from "../../components/ChatComponents/Message";
import _ from "lodash";
import ModalUsers from "../../components/Modals/ModalUsers";
import {IChatMessage} from "../../store/chat/types";
import MessageInvite from "../../components/ChatComponents/MessageInvite";
import {T} from "@tolgee/react";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;

  ${({theme}) => theme.mediaQueries.md} {
  }
`

const Body = styled.div`
  width: 100%;
  flex: 1;
  overflow: auto;

  ${({theme}) => theme.mediaQueries.md} {
    height: 50%;
  }
`

const BodyChat = styled.div<{ lengthChat: number }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: ${({lengthChat}) => lengthChat < 10 ? 'flex-start' : undefined};
  position: relative;
  width: 100%;
  //overflow: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  //gap: 20px;

  ${({theme}) => theme.mediaQueries.md} {
    padding-top: 40px;
  }
`

const Empty = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`


function Dialog() {
  const navigate = useNavigate()
  const {chatId: id} = useParams();
  const [isShowModal, setIsShowModal] = useState<boolean>(false);

  const {chats, currentChat, checkedMessages, isLoadingMessages} = useChatState()
  const {
    onFetchChat,
    onClearCurrentChatMessage,
    onClearCheckedMessages,
    onForwardMessages
  } = useChatAction()

  const currentChatMessages = useMemo(() => currentChat?.messages || [], [currentChat]);

  const currentFilteredChat = useMemo(() => id ? chats.filter((item) => item.id === id)[0] : null, [chats, id])

  useEffect(() => {
    if (currentChat) {
      if (!_.includes(chats.map(({id}) => id), currentChat.id)) {
        navigate('/chat')
      }
    }
  }, [chats, currentChat, navigate])

  useEffect(() => {
    return () => {
      onClearCheckedMessages()
    }
  }, [])

  useEffect(() => {
    if (id) {
      onFetchChat(id)
    }
    return () => {
      onClearCurrentChatMessage()
    }
  }, [id])
  const fictDevRef = useRef<HTMLDivElement | null>(null);


  const scrollBottom = (): void => {
    if (fictDevRef.current) {
      fictDevRef.current?.scrollIntoView({behavior: 'smooth'})
    }
  }

  useEffect(() => {
    scrollBottom()
  }, [currentChatMessages])

  const lastInvite: IChatMessage | null = useMemo(() => {
    const invites = currentChatMessages.filter((message) => message.type === 'eventInvite')
    if (invites.length > 0) {
      return invites[invites.length - 1]
    }
    return null
  }, [currentChatMessages])

  if (!currentChat) return null

  return (
    <>
      <Wrapper>
        {currentFilteredChat && <HeaderDialogView chat={currentFilteredChat} onShowModal={() => setIsShowModal(true)}/>}
        {
          lastInvite && <MessageInvite {...lastInvite}/>
        }
        <Body>
          <BodyChat lengthChat={currentChatMessages.length}>
            {
              currentChatMessages.length
                ? _.reverse([...currentChatMessages]).map((message, id) => (
                  <Message key={id} {...message}/>
                ))
                : <Empty>
                  <Text variant={ETextVariants.Body16}>
                    <T keyName="chat.empty">Сообщений нет</T>
                  </Text>
                </Empty>
            }
            <div ref={fictDevRef}/>
          </BodyChat>
        </Body>

        <InputDialog scrollBottom={scrollBottom} onShowModal={() => setIsShowModal(true)}/>
      </Wrapper>

      {
        isShowModal &&
          <ModalUsers forwardingMessages={(id: string) => {
            onForwardMessages(id, checkedMessages)
            setIsShowModal(false)
          }} title={'Переслать'}
                      chats={chats} onClose={() => setIsShowModal(false)}/>
      }
    </>
  );
}

export default Dialog;
