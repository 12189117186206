import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {AcquaintanceApi} from "../../api/acquaintance";
import {ILike} from "./types";

const fetchLikes = createAsyncThunk<ILike[],
  void,
  {
    state: RootState
  }>(
  'acquaintance/fetchLikes',
  async (_, ThunkApi) => {
    const authState = ThunkApi.getState().auth
    if (authState.auth) {
      const [likesRes, likesErr] = await handle(AcquaintanceApi.getUserLikes())
      if (likesRes) {
        return likesRes
      }
      if (likesErr) {
        throw {message: likesErr.message || likesErr.message[0]}
      }
    }
    throw {message: 'No likes state'}
  }
)

export default fetchLikes
