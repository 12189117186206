import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import styled from "styled-components";
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import {EmojiIcon, MicrophoneIcon, PauseIcon, PlusIcon, SendIcon, TrashIcon} from "../../UI/Svg";
import ReactTextareaAutosize from "react-textarea-autosize";
import {useParams} from "react-router-dom";
import {useChatAction, useChatState} from "../../store/chat/hooks";
import {handle} from "../../api";
import {FileApi} from "../../api/file";
import useRecorder from "../../hooks/useRecorder";
import {ETextVariants} from "../../UI/Text/types";
import Counter from "./Counter";
import Text from "../../UI/Text";
import Waveform from "./Waveform";
import {Button, EButtonVariants} from "../../UI/Button";
import _ from "lodash";
import {useAuthState} from "../../store/auth/hooks";
import {AudioRecorder, useAudioRecorder} from "react-audio-voice-recorder";
import CreateEventModal from "../Modals/CreateEventModal";
import {T, useTranslate} from "@tolgee/react";
import {handleImageUpload} from "../../utils";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 18px 12px;
  gap: 14px;
`

const ButtonIcon = styled.button`
  border: none;
  background: none;
  outline: none;
  padding: 4px;
  width: 28px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  height: 28px;

  svg {
    path {
      min-width: 28px;
      height: 28px;
    }
  }
`

const ButtonPlus = styled(ButtonIcon)`
  svg {
    path {
      stroke: ${({theme}) => theme.colors.mainPurple};
    }
  }
`


const TextAreaStyled = styled(ReactTextareaAutosize)`
  resize: none;
  padding: 6.5px 1.2px 7.5px 12px;
  background: ${({theme}) => theme.colors.bg};
  font-size: 16px;
  border: 1px solid ${({theme}) => theme.colors.lightGray6};
  color: ${({theme}) => theme.colors.black};
  width: 100%;
  border-radius: 18px;
`

const WrapEmojiModal = styled.div`
  position: absolute;
  right: 20px;
  bottom: 100px;
`

const InputFile = styled.input`
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: -100px;
  left: -100px;
`

const PreviewBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px 8px 54px;

  img {
    width: 50px;
  }
`

const PreviewImageWrapper = styled.div`
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: 50px;
  cursor: pointer;
  position: relative;
`
const DeleteHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  background: ${({theme}) => theme.colors.bg};
  cursor: pointer;
  opacity: .0;

  &:hover {
    opacity: .7;
  }
`

const TestStyled = styled(Text)`
  width: 100%;
`

const WaveformStyled = styled(Waveform)`
  width: 100%;
`

const Buttons = styled.div`
  padding: 16px;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${({theme}) => theme.colors.white};
  display: none;
  gap: 12px;
  align-items: stretch;
  justify-content: center;

  ${Button} {
    flex: 1;
    max-width: 300px;
  }

  ${({theme}) => theme.mediaQueries.md} {
    display: flex;
  }
`

function InputDialog({scrollBottom, onShowModal}: { scrollBottom: () => void, onShowModal: () => void }) {
  const {chatId: id} = useParams();
  const {t} = useTranslate()
  const {user} = useAuthState()
  const [textArea, setTextArea] = useState<string>('');
  const [isModalEmoji, setIsModalEmoji] = useState<boolean>(false);
  const [loadedFile, setLoadedFile] = useState<null | File>(null);
  const [voiceFile, setVoiceFile] = useState<null | File>(null);
  const {onSubmitMessage, onStopTyping, onStartTyping, onDeleteMessages} = useChatAction()
  const [voiceLocal, setVoiceLocal] = useState<any>(null);
  const [voiceUrl, setVoiceUrl] = useState('');
  const {checkedMessages, currentChat} = useChatState()


  const [isShowErrorModal, setIsShowErrModal] = useState<boolean>(false);
  const [bodyModal, setBodyModal] = useState<{ title: string, body: string }>(
    {title: '', body: ''}
  );

  useEffect(() => {
    if (id) {
      onStopTyping(id)
    }
  }, [id, onStopTyping])

  const [previewsFile, setPreviewsFile] = useState<null | string>(null);

  const inputRef = useRef<HTMLInputElement>(null)

  const addEmojiMessage = (e: any): void => {
    setTextArea((item) => `${item} ${e.native}`);
  };

  const onRemoveFile = () => {
    setPreviewsFile(null)
    setLoadedFile(null)
    if (inputRef.current) {
      inputRef.current.type = 'text'
      inputRef.current.type = 'file'
    }
  }

  useEffect(() => {
    if (voiceLocal) {
      const url = URL.createObjectURL(voiceLocal);
      setVoiceUrl(url);
      const fileLoad = new File([voiceLocal], 'voice', {type: 'audio/webm'})
      setVoiceFile(fileLoad)
    }
  }, [voiceLocal])

  const sendMessage = useCallback(async (): Promise<void> => {
    const compressedFile = await handleImageUpload(loadedFile)
    if ((textArea.trim().length || voiceFile || compressedFile) && id !== undefined) {
      let fileId: undefined | string = undefined
      try {
        const file = voiceFile || compressedFile
        if (file) {
          const [fileRes, fileErr] = await handle(FileApi.postFile(file))
          if (fileRes) fileId = fileRes.id
          if (fileErr) {
            setBodyModal({
              title: t('common.errorImage','Ошибка загрузки изображения'),
              body: fileErr.message
            });
            setIsShowErrModal(true);
          };
        }
      } catch (e) {
        console.log(e);
      }
      if (textArea.length || fileId) {
        onSubmitMessage(id, textArea, fileId)
      }
      setTextArea('');
      setVoiceLocal(null)
      setVoiceUrl('')
      setIsModalEmoji(false);
      scrollBottom();
      onRemoveFile()
    }
  }, [id, loadedFile, onSubmitMessage, scrollBottom, t, textArea, voiceFile]);

  const PressEnter = (e: any): void => {
    if (e.keyCode === 13) {
      if (e.shiftKey) {
        e.preventDefault();
        setTextArea((item) => `${item}\n`);
      } else {
        e.preventDefault();
        sendMessage();
      }
    }
  };

  const onChangeFile = (event: any) => {
    const files = event.target.files
    if (files.length > 0) {
      const file = files[0]
      if (file) {
        setLoadedFile(file)
        const srcImg = URL.createObjectURL(file)
        setPreviewsFile(srcImg)
      }
    }
  }

  const isCanDelete = useMemo(() => {
    if (currentChat && user) {
      const msg = currentChat.messages.find((msg) => _.includes(checkedMessages, msg.id) && msg.sender.id !== user.id)
      return !msg
    }
    return false
  }, [checkedMessages, currentChat, user])

  const onDelete = useCallback(() => {
    if (isCanDelete) onDeleteMessages(checkedMessages)
  }, [checkedMessages, isCanDelete, onDeleteMessages])

  const {
    startRecording,
    stopRecording,
    recordingBlob,
    isRecording,
  } = useAudioRecorder({
    noiseSuppression: true,
    echoCancellation: true,
  }, (exception => alert(exception.message)), {});

  const addAudioElement = (blob: any) => {
    const url = URL.createObjectURL(blob);
    setVoiceUrl(url);
    const fileLoad = new File([blob], 'voice', {type: 'audio/webm'})
    setVoiceFile(fileLoad)
  };

  useEffect(() => {
    if (!recordingBlob) return;
    addAudioElement(recordingBlob)
    // recordingBlob will be present at this point after 'stopRecording' has been called
  }, [recordingBlob])

  const {lang} = useAuthState()

  const shortLang = useMemo(() => lang?.split('-')[0] || 'en', [lang])


  return (
    <>
      {checkedMessages.length > 0 && <Buttons>
          <Button disabled={!isCanDelete} variant={EButtonVariants.Error} onClick={onDelete}>
              <T keyName="common.delete">Удалить</T>
          </Button>
          <Button variant={EButtonVariants.Primary} onClick={onShowModal}>
              <T keyName="chat.selectedHeader.buttons.resend">Переслать</T>
          </Button>
      </Buttons>}
      <InputFile ref={inputRef} onChange={onChangeFile} type={'file'}/>
      <Wrapper>
        {isRecording ?
          // null
          <>
            <TestStyled variant={ETextVariants.Body16}>
              <T keyName="chat.record">Запись:</T> <Counter record={isRecording}/>
            </TestStyled>
            <ButtonIcon onClick={() => {
              setVoiceUrl('')
              stopRecording()
            }}>
              <PauseIcon width={25} height={25}/>
            </ButtonIcon>
          </>
          : voiceUrl ?
            <>
              <ButtonPlus onClick={() => {
                setVoiceUrl('')
                setVoiceLocal(null)
              }}>
                <TrashIcon width={25} height={25}/>
              </ButtonPlus>
              <WaveformStyled audio={voiceUrl}/>
              <ButtonIcon onClick={sendMessage}>
                <SendIcon/>
              </ButtonIcon>
            </>
            : <><ButtonPlus onClick={() => inputRef.current?.click()}>
              <PlusIcon/>
            </ButtonPlus>
              <TextAreaStyled
                onChange={(e) => setTextArea(e.target.value)}
                onKeyDown={(e) => PressEnter(e)}
                onClick={() => setIsModalEmoji(false)}
                onFocus={() => {
                  if (id) {
                    onStartTyping(id)
                  }
                }}
                onBlur={() => {
                  if (id) {
                    onStopTyping(id)
                  }
                }}
                minRows={1}
                value={textArea}
                placeholder={t('chat.message',"Сообщение")}
              />
              <ButtonIcon onClick={sendMessage}>
                <SendIcon/>
              </ButtonIcon>
              <ButtonIcon onClick={() => setIsModalEmoji(prevState => !prevState)}>
                <EmojiIcon/>
              </ButtonIcon><ButtonIcon onClick={() => {
                startRecording()
              }}>
                {isRecording ? <PauseIcon width={25} height={25}/> : <MicrophoneIcon/>}
              </ButtonIcon>
            </>
        }
        {isModalEmoji && <WrapEmojiModal>
            <Picker
                data={data}
                onEmojiSelect={addEmojiMessage}
                locale={shortLang}
                previewPosition="none"
                searchPosition="none"
                theme="light"
            />
        </WrapEmojiModal>}
      </Wrapper>
      {previewsFile && <PreviewBlock>
          <PreviewImageWrapper onClick={onRemoveFile}>
              <img src={previewsFile} alt=""/>
              <DeleteHeader>
                  <TrashIcon/>
              </DeleteHeader>
          </PreviewImageWrapper>
      </PreviewBlock>}
      {isShowErrorModal &&
          <CreateEventModal isModalClose={() => setIsShowErrModal(false)} title={bodyModal.title} body={bodyModal.body}/>}
    </>
  );
}

export default InputDialog;
