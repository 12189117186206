import React, {useEffect} from 'react';
import styled from 'styled-components';
import QRCode from "react-qr-code";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {T} from "@tolgee/react";

export interface IModal {
  onClose: () => void,
  data: string
}

const WrapModal = styled.div(({ theme }) => {
  return {
    position: 'fixed',
    width: '100%',
    height: '100%',
    zIndex: '100002',
    left: 0,
    top: 0,
    background: theme.colors.transparentGray,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
});

const WinModal = styled.div(({ theme }) => {
  return {
    maxWidth: 270,
    top: 0,
    right: 0,
    overflowY: 'hidden',
    overflowX: 'hidden',
    background: theme.colors.white,
    zIndex: 2,
    borderRadius: 15,
    paddingTop: 20,
  };
});

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Title = styled(Text)`
  font-size: 17px;
  font-weight: bold;
`

const Content = styled.div`
  width: 100%;
  padding: 32px;
`

const ConfirmBlock = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.35);
  padding: 11px 0;
  display: flex;
  justify-content: center;
  gap: 10px;
`

const ConfirmText = styled(Text)`
  color: ${({theme}) => theme.colors.mainPurple};
  font-size: 17px;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  padding: 4px 16px;
`
function ModalQRCode({data, onClose}: IModal) {
  const bodyMain = document.querySelector('body');

  useEffect(() => {
    if (bodyMain) bodyMain.style.overflow = 'hidden';

    return () => {
      if (bodyMain) bodyMain.style.overflow = 'auto';
    };
  }, [bodyMain]);
  return (
    <WrapModal>
      <WinModal>
        <Header>
          <Title variant={ETextVariants.Heading} as={'h2'}>QRCode</Title>
        </Header>
        <Content>
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={data}
            viewBox={`0 0 256 256`}
          />
        </Content>
        <ConfirmBlock>
          <ConfirmText variant={ETextVariants.Body16} onClick={onClose}>
            <T keyName={'common.close'}>Закрыть</T>
          </ConfirmText>
        </ConfirmBlock>
      </WinModal>
    </WrapModal>
  );
}

export default ModalQRCode;
