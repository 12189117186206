import React, {useCallback, useEffect, useMemo} from 'react';
import {ETextVariants} from "../../UI/Text/types";
import {T, useTranslate} from "@tolgee/react";
import {useTimer} from "react-timer-hook";
import {useAuthAction, useAuthState} from "../../store/auth/hooks";
import Input from '../../UI/Input';
import {useForm} from "react-hook-form";
import {Col} from "../../components/Templates/Col";
import {Button, EButtonVariants} from "../../UI/Button";
import {ButtonDesc, Buttons, ContainerStyled,
  DescInput, FormStyled, FormWrapper, InputRow, LinkStyled, ResendButton, ResendRow, Title, TitleBlock} from "./styled";

function LoginAuthPage() {
  const {t} = useTranslate();
  const {auth, error} = useAuthState()
  const {register, handleSubmit} = useForm({
    defaultValues: {
      code: '',
    },
  });

  const time = useMemo(() => {
    const date = new Date()
    date.setSeconds(date.getSeconds() + 30);
    return date
  }, [])

  const {
    seconds,
    restart,
  } = useTimer({
    expiryTimestamp: time, autoStart: true
  });
  useEffect(() => {
    restart(time)
  }, [time])
  const {onSendSms, onLogin} = useAuthAction()

  const handleFormSubmit = ({ code }: { code: string }) => {
    onLogin(code);
  };

  const onResendSms = useCallback (() => {
    if (auth) {
      onSendSms(auth.phoneNumber);
    }
  }, [auth, onSendSms]);
  return (
    <FormWrapper>
      <ContainerStyled>
        <FormStyled onSubmit={handleSubmit(handleFormSubmit)}>
          <TitleBlock>
            <Title variant={ETextVariants.Heading}>
              <T keyName="auth.input.verificationCode">Проверочный код</T>
            </Title>
          </TitleBlock>
          <InputRow>
            <Col>
              <Input
                placeholder={t('auth.input.verificationCode', 'Проверочный код')}
                error={error || undefined}
                {...register('code', {required: true, maxLength: 4, minLength: 4})}
              />
            </Col>
            <Col>
              {seconds > 0 ? (
                <DescInput variant={ETextVariants.MiniGray}>
                  <T keyName="auth.resendCode" params={{second: seconds}}>
                    Код можно выслать повторно через 30 секунд
                  </T>
                </DescInput>
              ) : null}
            </Col>
          </InputRow>
          <ResendRow>
            <ResendButton type="button"
            disabled={!auth?.phoneNumber || seconds > 0}
            onClick={onResendSms}
                          variant={EButtonVariants.Secondary}><T keyName="auth.buttons.resendCode">Отправить код повторно</T></ResendButton>
          </ResendRow>
          <Buttons>
            <Button type="submit" variant={EButtonVariants.Primary} $fullWidth>
              <T keyName="auth.buttons.confirm">Подтвердить</T>
            </Button>
            <ButtonDesc variant={ETextVariants.MiniGray}>
              <T
                keyName="auth.termsOfUse"
                params={{
                  a1: <LinkStyled to="/terms"/>,
                  a2: <LinkStyled to="/politics"/>
                }}
                defaultValue={`Регистрируясь или авторизуясь, вы принимаете <LinkStyled to="/politics">Условия пользовательского соглашения</LinkStyled>, <LinkStyled to="#">Политики</LinkStyled> и даете Согласие на обработку персональных данных.`}
              />
            </ButtonDesc>
          </Buttons>
        </FormStyled>
      </ContainerStyled>
    </FormWrapper>
  );
}

export default LoginAuthPage;
