import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="33" height="33" viewBox="0 0 33 33" fill="none" {...props} >
          <path d="M16.5 28.875V26.125H26.125V6.875H16.5V4.125H26.125C26.8813 4.125 27.5289 4.3945 28.0679 4.9335C28.6069 5.4725 28.8759 6.11967 28.875 6.875V26.125C28.875 26.8813 28.606 27.5289 28.0679 28.0679C27.5298 28.6069 26.8822 28.8759 26.125 28.875H16.5ZM13.75 23.375L11.8594 21.3813L15.3656 17.875H4.125V15.125H15.3656L11.8594 11.6187L13.75 9.625L20.625 16.5L13.75 23.375Z" fill="black"/>
        </Svg>
    );

export default Icon;
