import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {ChatApi} from "../../api/chat";
import {CreateGroupChatPayload} from "./types";
import {FileApi} from "../../api/file";
import {handleImageUpload} from "../../utils";

const fetchCreateGroupChat = createAsyncThunk<string,
  CreateGroupChatPayload,
  {
    state: RootState
  }>(
  'chat/fetchCreateGroupChat',
  async (data, ThunkApi) => {
    const authState = ThunkApi.getState().auth
    if (authState.auth) {
      const compressedLoadFile = await handleImageUpload(data.loadFile)
      if (compressedLoadFile) {
        const [fileRes, fileErr] = await handle(FileApi.postFile(compressedLoadFile))
        if (fileRes) data.avatarId = fileRes.id
        if (fileErr) console.log(fileErr);
      }
      delete data.loadFile
      const [chatRes, chatErr] = await handle(ChatApi.postCreateGroupChat(data))
      if (chatRes) {
        return chatRes.id
      }
      if (chatErr) {
        throw {message: chatErr.message[0] || chatErr.message}
      }
    }
    throw {message: 'No phone number'}
  }
)

export default fetchCreateGroupChat
