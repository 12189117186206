import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {ServiceResponse} from "../adminServices/types";
import {ServicesApi} from "../../api/services";

const fetchCompanyServices = createAsyncThunk<ServiceResponse[],
  number,
  {
    state: RootState
  }>(
  'services/fetchCompanyServices',
  async (id, ThunkApi) => {
    const authState = ThunkApi.getState().auth
    if (authState.auth) {
      const [servicesRes, servicesErr] = await handle(ServicesApi.getCompanyServices(id))
      if (servicesRes) {
        return servicesRes
      }
      if (servicesErr) {
        throw {message: servicesErr.message[0] || servicesErr.message}
      }
    }
    throw {message: 'No user state'}
  }
)

export default fetchCompanyServices
