import axios, {AxiosInstance, AxiosResponse, InternalAxiosRequestConfig} from "axios";
import {AuthState} from "../store/auth/authSlice";

// dev
export const serverUrl = 'https://triphouse.lv/api'
const apiUrl = `${serverUrl}/`
const mediaUrl = `${serverUrl}/`

const axiosInstance: AxiosInstance = axios.create({
    baseURL: apiUrl,
});

axiosInstance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    const {store} = require('../store');
    const authState: AuthState = store.getState().auth
    config.headers['Accept-Language'] = authState.lang?.split('-')?.[0] || 'en';
    if (authState.token) {
        config.headers.Authorization = `Bearer ${authState.token}`;
    }
    return config
})

export const handle = (promise: Promise<AxiosResponse<any>>) => promise
        .then(data => ([data.data, undefined]))
        .catch(error => Promise.resolve([undefined, error?.response?.data || error?.response || error]))

export {axiosInstance, apiUrl, mediaUrl}
