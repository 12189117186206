import React from 'react';
import AdminHeader from "../../components/AdminComponents/AdminHeader";
import styled from "styled-components";
import {Link} from "react-router-dom";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import EventFilterView from "../../views/AdminHomeEvents/EventFilterView";
import EventsListView from "../../views/AdminHomeEvents/EventsListView";
import {useAdminEventsState} from "../../store/adminEvents/hooks";
import LoadingSpinner from "../../UI/LoadingSpinner";
import {T, useTranslate} from "@tolgee/react";

const LinkStyled = styled(Link)`
  ${Text} {
    text-decoration: underline;
    color: ${({theme}) => theme.colors.mainPurple};
  }
`

const LeftBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
`

function Index() {
  const {t} = useTranslate()
  const {isLoading} = useAdminEventsState()
  return (
    <>
      <AdminHeader title={t('admin.menu.events','Ивенты')} rightContent={<LeftBlock>
        <LinkStyled to={'/admin/categories'}><Text variant={ETextVariants.Body16}>
          <T keyName="common.categories">Категории</T>
        </Text></LinkStyled>
        <LinkStyled to={'/admin/interests'}><Text variant={ETextVariants.Body16}>
          <T keyName="common.interests">Интересы</T>
        </Text></LinkStyled>
      </LeftBlock>}/>
      <EventFilterView/>
      {
        isLoading
          ? <LoadingSpinner/>
          : <EventsListView/>
      }
    </>
  );
}

export default Index;
