import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {EventsApi} from "../../api/events";
import {IAdminEventsFilter} from "./types";
import {EventResponse} from "../events/types";

const fetchEvents = createAsyncThunk<EventResponse[],
  void,
  {
    state: RootState
  }>(
  'adminEvents/fetchEvents',
  async (_, ThunkApi) => {
    const authState = ThunkApi.getState().auth
    const {filter} = ThunkApi.getState().adminEvents
    const filterData: IAdminEventsFilter = {}
    for (const filterKey in filter) {
      const field = filterKey as keyof IAdminEventsFilter
      if (filter[field] !== undefined && filter[field]) {
        if (field === 'dateTo' || field === 'dateFrom') {
          const date = new Date(filter[field])
          date.setDate(date.getDate() + 1)
          if (field === 'dateTo') {
            date.setUTCHours(23, 59, 59, 999)
          } else {
            date.setUTCHours(0, 0, 0, 0)
          }
          filterData[field] = date
        } else {
          filterData[field] = filter[field]
        }
      }
    }
    if (!filterData.country && filterData.city) {
      delete filterData.country
      delete filterData.city
    }
    if (authState.auth) {
      const [eventsRes, eventsErr] = await handle(EventsApi.getAdminEvents(filterData))
      if (eventsRes) {
        return eventsRes
      }
      if (eventsErr) {
        throw {message: eventsErr.message[0] || eventsErr.message}
      }
    }
    throw {message: 'No user state'}
  }
)

export default fetchEvents
