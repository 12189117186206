import {MediaQueries, Breakpoints, Shadows, BreakpointsMap} from "./types";

export const breakpointMap: BreakpointsMap = {
    xs: 320,
    sm: 576,
    md: 768,
    lg: 968,
    xl: 1040,
    xxl: 1200,
    max: 1440,
};

export const breakpoints: Breakpoints = Object.values(breakpointMap).map((breakpoint) => `${breakpoint}px`);

export const mediaQueries: MediaQueries = {
    xs: `@media screen and (max-width: ${breakpointMap.xs}px)`,
    sm: `@media screen and (max-width: ${breakpointMap.sm}px)`,
    md: `@media screen and (max-width: ${breakpointMap.md}px)`,
    lg: `@media screen and (max-width: ${breakpointMap.lg}px)`,
    xl: `@media screen and (max-width: ${breakpointMap.xl}px)`,
    xxl: `@media screen and (max-width: ${breakpointMap.xxl}px)`,
    max: `@media screen and (max-width: ${breakpointMap.max}px)`,
    nav: `@media screen and (max-width: ${breakpointMap.lg}px)`,
};

export type FontWeightExpand = {
    thin: number;
    light: number;
    regular: number;
    medium: number;
    bold: number;
};
export type FontWeight = [number, number, number, number, number] & FontWeightExpand;

const fontWeight = [100, 300, 400, 500, 700] as FontWeight;
[fontWeight.thin, fontWeight.light, fontWeight.regular, fontWeight.medium, fontWeight.bold] = fontWeight;

export const shadows: Shadows= {
    // regular: "0px 1px 4px rgba(0, 0, 0, 0.18)",
};


export {fontWeight}
