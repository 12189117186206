import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {GeoApi} from "../../api/geo";

const fetchCities = createAsyncThunk<string[],
  string,
  {
    state: RootState
  }>(
  'geo/fetchCities',
  async (country, ThunkApi) => {
    const authState = ThunkApi.getState().auth
    if (authState.auth) {
      const [citiesRes, citiesErr] = await handle(GeoApi.getCities(country))
      if (citiesRes) {
        return citiesRes
      }
      if (citiesErr) {
        throw {message: citiesErr.message[0] || citiesErr.message}
      }
    }
    throw {message: 'No user state'}
  }
)

export default fetchCities
