import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {GeoApi} from "../../api/geo";

const fetchCountries = createAsyncThunk<string[],
  void,
  {
    state: RootState
  }>(
  'geo/fetchCountries',
  async (_, ThunkApi) => {
    const authState = ThunkApi.getState().auth
    if (authState.auth) {
      const [countriesRes, countriesErr] = await handle(GeoApi.getCountries())
      if (countriesRes) {
        return countriesRes
      }
      if (countriesErr) {
        throw {message: countriesErr.message[0] || countriesErr.message}
      }
    }
    throw {message: 'No user state'}
  }
)

export default fetchCountries
