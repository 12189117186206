import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props} >
          <rect width="20" height="20" rx="10" fill="#EAEAEA"/>
          <path d="M8 8H8.005M5 6.6L5 8.83726C5 9.08185 5 9.20414 5.02763 9.31923C5.05213 9.42127 5.09253 9.51881 5.14736 9.60828C5.2092 9.7092 5.29568 9.79568 5.46863 9.96863L9.30294 13.8029C9.89697 14.397 10.194 14.694 10.5365 14.8053C10.8377 14.9031 11.1623 14.9031 11.4635 14.8053C11.806 14.694 12.103 14.397 12.6971 13.8029L13.8029 12.6971C14.397 12.103 14.694 11.806 14.8053 11.4635C14.9031 11.1623 14.9031 10.8377 14.8053 10.5365C14.694 10.194 14.397 9.89697 13.8029 9.30294L9.96863 5.46863C9.79568 5.29568 9.7092 5.2092 9.60828 5.14736C9.51881 5.09253 9.42127 5.05213 9.31923 5.02763C9.20414 5 9.08185 5 8.83726 5L6.6 5C6.03995 5 5.75992 5 5.54601 5.10899C5.35785 5.20487 5.20487 5.35785 5.10899 5.54601C5 5.75992 5 6.03995 5 6.6ZM8.25 8C8.25 8.13807 8.13807 8.25 8 8.25C7.86193 8.25 7.75 8.13807 7.75 8C7.75 7.86193 7.86193 7.75 8 7.75C8.13807 7.75 8.25 7.86193 8.25 8Z" stroke="#5AB055" strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round"/>
        </Svg>
    );

export default Icon;
