import {axiosInstance} from "./index";

export const FileApi = {
    postFile: async (file: File) => await axiosInstance.post(`file`, {file}, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    } ),
    getFileById: async (id: string) => await axiosInstance.get(`file/${id}`, {responseType: 'arraybuffer'}),
}
