import React, {useEffect} from 'react';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import Navbar from "../Navbar";
import {useAuthState} from "../../store/auth/hooks";
import {useSinglePendingState} from "../../store/pending/hooks";
import Footer from "../Footer";
import styled from "styled-components";
import {useUserSelectedCompany} from "../../store/company/hooks";
import {useChatAction} from "../../store/chat/hooks";
import {useAppAction} from "../../store/app/hooks";
import {useTranslate} from "@tolgee/react";

const AppWrapper = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
`

const Wrapper = styled.div<{$isUserLogin: boolean}>`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.md} {
    //min-height: calc(100vh - 127px);
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    overflow: auto;
    overflow-x: hidden;
  };
`

function Index() {
  const navigate = useNavigate();
  const {t} = useTranslate()
  useUserSelectedCompany()
  const {pathname} = useLocation();
  const [firstSegment] = pathname.split('/').filter((item) => !!item);
  const {isLogIn, isPending} = useAuthState()
  useEffect(() => {
    console.log('isLogIn', isLogIn)
    if (firstSegment !== 'auth' && firstSegment !== 'politics' && firstSegment !== 'terms' && !isPending && !isLogIn) {
      navigate('/auth');
    }
  }, [firstSegment, isLogIn, isPending, navigate]);
  const {token} = useAuthState()
  const {onStartConnecting, onDisconnectSocket, onFetchUserChats} = useChatAction()

  const {onSetTitle} = useAppAction()
  useEffect(() => {
    onSetTitle(t('common.home','Главная'))
  }, [t])

  useEffect(() => {
    if (token) {
      onStartConnecting()
      onFetchUserChats()
    }
    return () => {
      onDisconnectSocket()
    }
  }, [token])
  return (
    <AppWrapper>
      <Navbar/>
      <Wrapper $isUserLogin={isLogIn}>
        <Outlet/>
      </Wrapper>
      <Footer/>
    </AppWrapper>
  );
}

export default Index;
