import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {CompanyApi} from "../../api/company";
import {IUserCompany} from "../company/types";
import {IAdminCompaniesFilter} from "./types";

const fetchCompanies = createAsyncThunk<IUserCompany[],
  void,
  {
    state: RootState
  }>(
  'adminCompanies/fetchCompanies',
  async (_, ThunkApi) => {
    const authState = ThunkApi.getState().auth
    const {filter} = ThunkApi.getState().adminCompanies
    if (authState.auth) {
      const filterData: IAdminCompaniesFilter = {}
      for (const filterKey in filter) {
        const field = filterKey as keyof IAdminCompaniesFilter
        if (filter[field] !== undefined && filter[field]) {
          if (field === 'dateTo' || field === 'dateFrom') {
            const date = new Date(filter[field])
            date.setDate(date.getDate() + 1)
            if (field === 'dateTo') {
              date.setUTCHours(23, 59, 59, 999)
            } else {
              date.setUTCHours(0, 0, 0, 0)
            }
            filterData[field] = date
          } else {
            filterData[field] = filter[field]
          }
        }
      }
      const [companiesRes, companiesErr] = await handle(CompanyApi.getAdminCompanies(filterData))
      if (companiesRes) {
        return companiesRes
      }
      if (companiesErr) {
        console.log('companiesErr',companiesRes, companiesErr)
        throw {message: companiesErr.message[0] || companiesErr.message}
      }
    }
    throw {message: 'No phone number'}
  }
)

export default fetchCompanies
