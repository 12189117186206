import styled from "styled-components";
import Text from "../../UI/Text";
import {Link} from "react-router-dom";
import {Card} from "../../components/Templates/Card";
import {Row} from "../../components/Templates/Row";
import {Button} from "../../UI/Button";
import {Container} from "../../components/Templates/Container";

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  margin-top: 24px;
  gap: 16px;
  ${({theme}) => theme.mediaQueries.md} {
    flex: 1;
    justify-content: flex-end;
  }
`

export const ButtonDesc = styled(Text)`
  width: 100%;
  font-size: 12px;
  a {
    font-size: 12px;
  }
`

export const LinkStyled = styled(Link)`
  color: ${({theme}) => theme.colors.mainPurple};
`

export const Carousel = styled(Card)`
  & .swiper-pagination-bullet {
    border-radius: 50%;
    width: .8rem;
    height: .8rem;
  }

  & .swiper-pagination-bullet-active {
    background-color: #6C27AB;
  }

  & .swiper-pagination {
    bottom: 0 !important;
  }

  ${({theme}) => theme.mediaQueries.md} {
    padding: 0;

    & .swiper-pagination {
      //bottom: -7px !important;
      bottom: -7px !important;
      height: 2rem;
    }
  }
`

export const CarouselMobile = styled(Carousel)`
  //position: absolute;
  display: none;
  //top: 50%;
  //max-height: 50%;
  //width: 90%;
  //transform: translateY(-50%);
  //height: auto;
  max-width: 100vw;
  overflow: hidden;
  ${({theme}) => theme.mediaQueries.md} {
    display: flex;
    //top: 60%;
    //max-height: 60%;
  }
`


export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${({theme}) => theme.mediaQueries.md} {
    flex: 1;
  }
`

export const TitleBlock = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
`

export const Title = styled(Text)`
`

export const InputRow = styled(Row)`
  margin-bottom: 1rem;
  flex-direction: column;
  flex: 0;
  gap: 16px;
`

export const DescInput = styled(Text)`
  margin-top: 10px;
  display: block;
`

export const ResendRow = styled(Row)`
  margin-top: 15px;
  justify-content: flex-start;
  margin-bottom: 50px;
  flex-direction: column;
  align-items: center;
  ${({theme}) => theme.mediaQueries.md} {
    flex: unset;
  }
`

export const ResendButton = styled(Button)`
  border: none;
`

export const ContainerStyled = styled(Container)`
  flex: 1;
  display: flex;
  flex-direction: column;
`
export const FormStyled = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
`
