import React, {useEffect, useMemo, useState} from 'react';
import styled from "styled-components";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import Select from "../../UI/Select";
import {useGeoAction, useGeoState} from "../../store/geo/hooks";
import {useAdminPaymentsAction, useAdminPaymentsState} from "../../store/adminPayments/hooks";
import {EPaymentMethod, EPaymentStatus} from "../../store/cart/types";
import {DataPickerRange} from "../../UI/DataPickerRange";
import Input from "../../UI/Input";
import {SearchIcon} from "../../UI/Svg";
import { getStatusTest } from './PaymentsCompanyListView';
import {T, useTranslate} from "@tolgee/react";

const Wrapper = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
`

const FilterWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

const Filters = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  position: relative;
`

const SelectStyled = styled(Select)`
  width: 186px;
  padding: 11px 16px;
  background-position: 90%;
`

const DataPickerRangeStyled = styled(DataPickerRange)`
  min-width: 178px;
`

const InputStyled = styled(Input)`
  background: ${({theme}) => theme.colors.white};
`

function PaymentsFilterView() {
  const {t} = useTranslate()
  const {filter} = useAdminPaymentsState()
  const {onChangeFilter} = useAdminPaymentsAction()
  const {cities, countries} = useGeoState()
  const {onClearCities, onFetchCities} = useGeoAction()
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const [startDate, endDate] = useMemo(() => dateRange, [dateRange]);


  useEffect(() => {
    if (startDate && endDate) {
      onChangeFilter({
        dateFrom: startDate.toString(),
        dateTo: endDate.toString()
      })
    } else if (startDate) {
      onChangeFilter({
        dateFrom: startDate.toString(),
      })
    } else if (endDate) {
      onChangeFilter({
        dateTo: endDate.toString()
      })
    } else {
      onChangeFilter({
        dateFrom: undefined,
        dateTo: undefined,
      })
    }
  }, [startDate, endDate, onChangeFilter])

  useEffect(() => {
    if (filter.country) {
      onFetchCities(filter.country.toLowerCase())
    } else {
      onChangeFilter({city: ''})
      onClearCities()
    }
    return () => {
      onChangeFilter({city: ''})
      onClearCities()
    }
  }, [filter.country, onChangeFilter, onClearCities, onFetchCities])

  return (
    <Wrapper>
      <Text variant={ETextVariants.Body16}><T keyName="admin.filter">Фильтр:</T></Text>
      <FilterWrapper>
        <Filters>
          <DataPickerRangeStyled
            setDateRange={setDateRange}
            startDate={startDate}
            endDate={endDate}
            bg
            isClearButton={true}
            isIcon={!startDate && !endDate}
            typeArrowPurple={true}
            mobileVersion={true}
          />

          <SelectStyled value={filter.method}
                        onChange={(event) => onChangeFilter({method: event.target.value})}
                        colorArrow>
            <option value="">
              <T keyName="filter.typePayment">Тип оплаты</T>
            </option>
            <option value={EPaymentMethod.Online}><T keyName={`paymentsMethod.${EPaymentMethod.Online}`}>{EPaymentMethod.Online}</T></option>
            <option value={EPaymentMethod.Cash}><T keyName={`paymentsMethod.${EPaymentMethod.Cash}`}>{EPaymentMethod.Cash}</T></option>
          </SelectStyled>
          <SelectStyled value={filter.country}
                        onChange={(event) => onChangeFilter({country: event.target.value})}
                        colorArrow>
            <option value="">
              <T keyName="filter.country">Страна</T>
            </option>
            {countries?.map((item, i) => (
              <option key={i} value={item}>{`${item}`}</option>
            ))}
          </SelectStyled>
          <SelectStyled value={filter.city}
                        onChange={(event) => onChangeFilter({city: event.target.value})}
                        colorArrow>
            <option value="">
              <T keyName="filter.city">Город</T>
            </option>
            {cities?.map((item, i) => (
              <option key={i} value={item}>{`${item}`}</option>
            ))}
          </SelectStyled>
          <SelectStyled value={filter.status}
                        onChange={(event) => onChangeFilter({status: event.target.value})}
                        colorArrow>
            <option value="">
              <T keyName="common.status">Статус</T>
            </option>
            <option value={EPaymentStatus.Paid}><T keyName={`paymentStatus.${EPaymentStatus.Paid}`}>{getStatusTest(EPaymentStatus.Paid)}</T></option>
            <option value={EPaymentStatus.Cancelled}><T keyName={`paymentStatus.${EPaymentStatus.Cancelled}`}>{getStatusTest(EPaymentStatus.Cancelled)}</T></option>
            <option value={EPaymentStatus.AwaitCash}><T keyName={`paymentStatus.${EPaymentStatus.AwaitCash}`}>{getStatusTest(EPaymentStatus.AwaitCash)}</T></option>
            <option value={EPaymentStatus.Pending}><T keyName={`paymentStatus.${EPaymentStatus.Pending}`}>{getStatusTest(EPaymentStatus.Pending)}</T></option>
          </SelectStyled>
          <InputStyled
            placeholder={t('common.search', "Поиск")}
            icon={<SearchIcon/>}
            value={filter.search}
            onChange={(event) => onChangeFilter({search: event.target.value})}
          />
        </Filters>
      </FilterWrapper>
    </Wrapper>
  );
}

export default PaymentsFilterView;
