import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {ProfileEntity} from "./types";
import {ProfileApi} from "../../api/profile";
import {handle} from "../../api";



const fetchProfileById = createAsyncThunk<ProfileEntity,
  string,
  {
    state: RootState
  }>(
  'profile/fetchProfileById',
  async (id, ThunkApi) => {
    const authState = ThunkApi.getState().auth
    if (authState.auth) {
      const [profileRes, profileErr] = await handle(ProfileApi.getProfileById(id))
      if (profileRes) {
        return profileRes
      }
      if (profileErr) {
        throw {message: profileErr.message[0] || profileErr.message}
      }
    }
    throw {message: 'No user state'}
  }
)

export default fetchProfileById
