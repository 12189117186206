import React from 'react';
import styled from 'styled-components';
import Text from "../Text";
import {ETextVariants} from "../Text/types";

interface TabsProps {
  tabs: { name: string, value: string }[],
  onSelect: (name: string) => void,
  value: string
}

const Wrapper = styled.div`
  width: 100%;
  background-color: ${({theme}) => theme.colors.bg};
  border-radius: 10px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  padding: 3px;
  gap: 15px;
`

const Tab = styled.div<{ isActive: boolean }>`
  border-radius: 8px;
  flex: 1;
  display: flex;
  justify-content: center;
  background-color: ${({theme, isActive}) => isActive ? theme.colors.white : theme.colors.bg};
  align-items: center;
  cursor: pointer;
  padding: 7px 16px;
  white-space: nowrap;
`

function Index({value, tabs, onSelect}: TabsProps) {
  return (
    <Wrapper>
      {
        tabs.map((tab,id) => <Tab onClick={() => {
          if (tab.value !== value) onSelect(tab.value)
        }} key={`Tab-${id}`} isActive={tab.value === value}>
          <Text variant={ETextVariants.Body16}>{tab.name}</Text>
        </Tab>)
      }
    </Wrapper>
  );
}

export default Index;
