import React, {useCallback, useEffect, useRef, useState} from 'react';
import {FileUploader} from 'react-drag-drop-files';
import {debounce} from "lodash";
import {Option} from "../../types";
import {ETextVariants} from "../Text/types";
import styled from "styled-components";
import "cropperjs/dist/cropper.css";
import Text from "../Text";
import {Cropper} from "react-cropper";
import {Button, EButtonVariants} from "../Button";
import {T, useTranslate} from "@tolgee/react";
import {useProfileState} from "../../store/profile/hooks";

interface ImageUploaderProps {
  onChange: (file: File | null) => void;
  url: string;
  title: string;
  fileTypes?: string[];
}

const ErrorForm = styled(Text)`

`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
`


const ImgProfile = styled.div`
  width: 100px;
  height: 100px;
  max-height: 350px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #9b9b9b;
  img {
    width: auto;
    height: 100%;
    max-width: none;
  }
`

const UploadBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  gap: 10px;
`

const Title = styled(Text)`
  color: ${({theme}) => theme.colors.mainPurple};
`

const CropperWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
`

const maxImageUploadSize = 10e6

function Index({onChange, url, fileTypes, title}: ImageUploaderProps) {
  const {t} = useTranslate()
  const [loadFile, setloadFile] = useState<File | null>(null);
  const [error, setError] = useState<Option<string>>('');
  const [cropView, setCropView] = useState<string | null>(null);
  const [isBase64, setIsBase64] = useState(true);
  const {updateError} = useProfileState()

  useEffect(() => {
    setError(updateError)
  }, [updateError])

  const cropperRef = useRef<HTMLImageElement>(null);

  const dataURLtoFile = (dataurl: any, filename: string): File => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };
  const handleChange = (file: File): void => {
    setError(null);
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onloadend = (e: any) => {
      setloadFile(e.target.result);
    };
  };

  const onSave = (): void => {
    const file = dataURLtoFile(isBase64, `${Date.now()}.png`);
    if (file.size > maxImageUploadSize) {
      setError(t("imageUploader.sizeToBig",'Большой размер файла, размер не должен превышать 10МБ!'));
      return;
    }
    onChange(file);
    setCropView(`${isBase64}`);
    setloadFile(null);
    setError(null);
  };

  const onCancel = (): void => {
    setloadFile(null);
    onChange(null);
  };

  const onCrop = useCallback(
    debounce((el: any) => {
      const imageElement: any = cropperRef?.current;
      const cropper: any = imageElement?.cropper;
      setIsBase64(cropper.getCroppedCanvas().toDataURL());
    }, 500),
    [],
  );
  return (
    <Wrapper>
      {!loadFile ? (<FileUploader handleChange={handleChange} name="file" types={fileTypes} onTypeError={setError}>
          <UploadBlock>
            <ImgProfile>
              <img src={cropView ?? url} alt=""/>
            </ImgProfile>
            <Title variant={ETextVariants.Body16}>
              {title}
            </Title>
          </UploadBlock>
        </FileUploader>)
        : <CropperWrapper>
          <Cropper
            src={`${loadFile}`}
            style={{height: 350, width: 305}}
            initialAspectRatio={16 / 9}
            guides={false}
            crop={onCrop}
            ref={cropperRef}
          />
          <Button type={'button'} onClick={onSave} variant={EButtonVariants.Primary} style={{margin: '1rem 0', width: '30rem'}}>
            <T keyName="imageUploader.save">Сохранить и продолжить</T>
          </Button>
          <Button type={'button'} onClick={onCancel} variant={EButtonVariants.Secondary} style={{width: '30rem'}}>
            <T keyName="common.cancel">Отмена</T>
          </Button>
        </CropperWrapper>
      }
      {error &&
          <ErrorForm variant={ETextVariants.Error}>{error}</ErrorForm>}
    </Wrapper>
  );
}

export default Index;
