export const adminLinks: Array<{icon: string, href: string, title: string, key:string}> = [
  {icon: 'EventIcon', href: '/admin', title: 'Ивенты', key:"events"},
  {icon: 'UsersIcon', href: '/admin/users', title: 'Пользователи', key:"users"},
  {icon: 'ServicesIcon', href: '/admin/services', title: 'Услуги фирм', key:"services"},
  {icon: 'FirmsIcon', href: '/admin/firms', title: 'Фирмы', key:"firms"},
  {icon: 'PaymentIcon', href: '/admin/payments', title: 'Платежи', key:"payments"},
  {icon: 'EditIcon', href: '/admin/firms/add', title: 'Добавление фирмы', key:"addingCompany"},
  {icon: 'SupportIcon', href: '/admin/support', title: 'Служба поддержки', key:"support"},
  {icon: 'FileIcon', href: '/admin/forbidden', title: 'Стоп слова', key:"stopWords"},
]
