import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import fetchCities from "./fetchCities";
import fetchCountries from "./fetchCountries";
import fetchCitiesDb from "./fetchCitiesDb";
import fetchCountriesDb from "./fetchCountriesDb";
import {IDBCountry} from "./types";

export interface GeoState {
  cities: string[],
  countries: string[],
  citiesDb: string[],
  countriesDb: IDBCountry[],
}

const initialState: GeoState = {
  cities: [],
  countries: [],
  citiesDb: [],
  countriesDb: [],
}

export const geoSlice = createSlice({
  name: 'geo',
  initialState,
  reducers: {
    clearCities: (state) => {
      state.cities = []
    },
    clearCitiesDb: (state) => {
      state.citiesDb = []
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchCities.fulfilled, (state, action: PayloadAction<string[]>) => {
      state.cities = action.payload
    })
      .addCase(fetchCountries.fulfilled, (state, action: PayloadAction<string[]>) => {
      state.countries = action.payload
    })
      .addCase(fetchCitiesDb.fulfilled, (state, action: PayloadAction<string[]>) => {
      state.citiesDb = action.payload
    })
      .addCase(fetchCountriesDb.fulfilled, (state, action: PayloadAction<IDBCountry[]>) => {
      state.countriesDb = action.payload
    })
  }
})

export const {clearCities, clearCitiesDb} = geoSlice.actions
export default geoSlice.reducer
