import {useDispatch, useSelector} from "react-redux";
import {useCallback, useEffect, useMemo} from "react";
import {AppDispatch, RootState} from "../index";
import fetchNotifications from "./fetchNotifications";

export const getNotifications = (state: RootState) => state.notifications

export function useNotificationsAction() {
  const dispatch = useDispatch<AppDispatch>()

  const onFetchNotifications = useCallback(() => {
    dispatch(fetchNotifications())
  }, [dispatch])

  return useMemo(() => ({
    onFetchNotifications,
  }), [
    onFetchNotifications,
  ])
}

export function useNotificationsState() {
  return useSelector(getNotifications)
}

export function useNotifications() {
  const {onFetchNotifications} = useNotificationsAction()
  useEffect(() => {
    onFetchNotifications()
  }, [onFetchNotifications])
}
