import React, {useMemo} from 'react';
import {useEventsState} from "../../store/events/hooks";
import {useLocation, useSearchParams} from "react-router-dom";
import {Marker} from "react-leaflet";
import {EventResponse} from "../../store/events/types";
import PopupMarker from "./PopupMarker";
import {iconGray, iconPurple} from "./MapMarkersServices";

interface MapMarkersProps {
  activeMarker: string | null;
  handlerMarker: (id: string | null) => void;
  eventsData?: EventResponse[]
  setActiveMarker: React.Dispatch<React.SetStateAction<string | null>>;
  checkedEvent: React.MutableRefObject<any>;
}

function MapMarkers({
                      activeMarker,
                      handlerMarker,
                      setActiveMarker,
                      checkedEvent,
                      eventsData,
                    }: MapMarkersProps) {
  const {all} = useEventsState()
  const { pathname } = useLocation();
  const path = pathname.split('/').filter((item) => !!item);
  const [searchParams] = useSearchParams()
  const searchEventId = useMemo(() => {
    const isEvent = searchParams.get('is-event')
    const eventId = searchParams.get('id')
    if (isEvent === 'true' && eventId !== undefined) {
      return eventId
    }
    return null
  }, [searchParams])
  const events = useMemo(() => eventsData || all, [eventsData, all])
  return( <>{events?.map((item: EventResponse) => (
    <Marker
      key={item.id}
      position={[parseFloat(item.address.latitude), parseFloat(item.address.longitude)]}
      icon={activeMarker ? activeMarker === `${item.id}` ? iconPurple : iconGray : iconPurple}
      ref={searchEventId === String(item.id) ? checkedEvent : null}
      eventHandlers={{click: () => handlerMarker(activeMarker === `${item.id}` ? null : `${item.id}`), popupclose: () => setActiveMarker(null)}}
    >
      <PopupMarker item={item}/>
    </Marker>
  ))}</>)
}

export default MapMarkers;
