import React, {useCallback, useState} from 'react';
import styled from "styled-components";
import {Card} from "../../components/Templates/Card";
import ReactTextareaAutosize from "react-textarea-autosize";
import {PaperClipIcon} from "../../UI/Svg";
import ModalPostPhoto from "../../components/Modals/ModalPostPhoto";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {useProfileAction} from "../../store/profile/hooks";
import {handle} from "../../api";
import {ProfileApi} from "../../api/profile";
import {Link} from "react-router-dom";
import ModalFirmPost from "../../components/Modals/ModalFirmPost";
import {T} from "@tolgee/react";

const CardStyled = styled(Card)`
  display: flex;
  width: 100%;
  gap: 10px;
  flex-direction: column;
  padding: 16px 30px;

  ${({theme}) => theme.mediaQueries.md} {
    padding: 15px;
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
`

const ButtonAttach = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  min-width: 40px;
  border-radius: 10px;
  cursor: pointer;
  height: 40px;
  background: ${({theme}) => theme.colors.bg};
  svg {
    path {
      stroke: ${({theme}) => theme.colors.mainPurple};
    }
  }
`

const LinkCreateService = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  width: 100%;
  cursor: pointer;
  padding: 12.5px 30px;
  background: ${({theme}) => theme.colors.bg};
  color: ${({theme}) => theme.colors.mainPurple};
  &:hover {
    color: ${({theme}) => theme.colors.white};
    background: ${({theme}) => theme.colors.mainPurple};
  }
`


function CreatePostOrServiceView() {
  const [isShowModal, setIsShowModal] = useState<boolean>(false);

  return (
    <CardStyled>
      <Row>
        <ButtonAttach onClick={() => setIsShowModal(true)}>
          <PaperClipIcon/>
        </ButtonAttach>
        <LinkCreateService to={'/create'}>
          <T keyName="company.buttons.addService">Добавить услугу</T>
        </LinkCreateService>
      </Row>

      {isShowModal && <ModalFirmPost
        onClose={() => setIsShowModal(false)}
      />}
    </CardStyled>
  );
}

export default CreatePostOrServiceView;
