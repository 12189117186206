import React, {useEffect} from 'react';
import {useProfileAction, useProfileState} from "../../store/profile/hooks";
import CompanyView from "../../views/CompanyViews/CompanyView";
import {useCompanyAction, useCompanyState} from "../../store/company/hooks";
import LoadingCompany from "../../views/CompanyViews/LoadingCompany";
import {useParams} from "react-router-dom";

function Index() {
  const {id} = useParams()
  const {selectedCompany, isPending} = useCompanyState()
  const {onFetchCompanyById, onFetchSubscribers} = useCompanyAction()
  useEffect(() => {
    if (id !== undefined) {
      onFetchCompanyById(parseInt(id, 10))
      onFetchSubscribers(parseInt(id, 10))
    }
  }, [id, onFetchCompanyById, onFetchSubscribers])
  if (!selectedCompany || isPending) {
    return (
      <LoadingCompany/>
    );
  }
  return <CompanyView {...selectedCompany} />
}

export default Index;
