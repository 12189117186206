import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {EventResponse, IEventCategory, IEventsFilter} from "./types";
import fetchCategories from "./fetchCategories";
import fetchEvents from "./fetchEvents";
import fetchEvent from "./fetchEvent";
import _ from "lodash";

export type EventsState = {
  all: EventResponse[],
  currentEvent: EventResponse | null,
  isLoading: boolean,
  isLoadingEvent: boolean,
  isLoadingMore: boolean,
  isEnd: boolean,
  filter: IEventsFilter
  categories: IEventCategory[];
}

const LIMIT = 20

const initialState: EventsState = {
  all: [],
  currentEvent: null,
  isLoading: true,
  isEnd: false,
  isLoadingMore: false,
  isLoadingEvent: true,
  filter: {limit: LIMIT, page: 1},
  categories: [],
}

export const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    changeFilter: (state, action: PayloadAction<IEventsFilter>) => {
      state.filter = {...state.filter, ...action.payload, page: 1}
      state.all = []
    },
    removeCurrentEvent: (state,) => {
      state.currentEvent = null
    },
    changePage: (state,) => {
      if (state.filter.page) {
        state.filter.page += 1
      } else {
        state.filter.page = 1
      }
    },
    resetFilter: (state, action: PayloadAction<IEventsFilter | undefined>) => {
      if (action.payload) {
        state.filter = action.payload
      } else {
        state.filter = initialState.filter
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchEvent.pending, (state) => {
      state.isLoadingEvent = true
    })
    builder.addCase(fetchEvent.fulfilled, (state, action: PayloadAction<EventResponse>) => {
      state.currentEvent = action.payload
      state.isLoadingEvent = false
    })
    builder.addCase(fetchEvent.rejected, (state) => {
      state.isLoadingEvent = false
    })
    builder.addCase(fetchCategories.fulfilled, (state, action: PayloadAction<IEventCategory[]>) => {
      state.categories = action.payload
    })
    builder.addCase(fetchEvents.fulfilled, (state, action: PayloadAction<EventResponse[]>) => {
      state.isEnd = action.payload.length < (state.filter.limit || LIMIT)
      state.all = _.uniqBy([...state.all, ...action.payload], 'id')
      state.isLoading = false
      state.isLoadingMore = false
    })
    builder.addCase(fetchEvents.pending, (state) => {
      if (state.filter.page && state.filter.page === 1) {
        state.isLoading = true
      } else {
        state.isLoadingMore = true
      }
    })
    builder.addCase(fetchEvents.rejected, (state) => {
      state.isLoading = false
      state.isLoadingMore = false
    })
  }
})

export const {changeFilter, resetFilter, removeCurrentEvent, changePage} = eventsSlice.actions
export default eventsSlice.reducer
