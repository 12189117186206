import React from 'react';
import styled from "styled-components";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {DeleteIcon, EditIcon} from "../../UI/Svg";
import {useEventsState} from "../../store/events/hooks";
import CategoryItem from "../../components/AdminComponents/CategoryItem";

const Wrapper = styled.div`
  width: calc(100% + 80px);
  display: flex;
  flex-direction: column;
  margin: 0 -40px;
  margin-top: 40px;
`


function ListCategories() {
  const {categories} = useEventsState()

  return (
    <Wrapper>
      {
        categories.map((item) => <CategoryItem key={item.id} {...item}/>)
      }
    </Wrapper>
  );
}

export default ListCategories;
