import React, {useCallback, useState} from 'react';
import {CreateCompanyPayload} from "../../store/company/types";
import {T, useTranslate} from "@tolgee/react";
import {IEventCategory} from "../../store/events/types";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {DeleteIcon, EditIcon} from "../../UI/Svg";
import {useEventsAction} from "../../store/events/hooks";
import styled from "styled-components";
import Input from "../../UI/Input";
import {Button, EButtonVariants} from "../../UI/Button";
import {IInterest} from "../../store/profile/types";
import {useProfileAction} from "../../store/profile/hooks";


const Item = styled.div`
  width: 100%;
  padding: 12px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #DDDDDD;

  &:last-child {
    border-bottom: 1px solid #DDDDDD;
  }
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: flex-end;
`

const ButtonIcon = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;

  svg {
    path {
      stroke: #747474;
    }
  }
`

const RowLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
`

function InterestItem({id, title}: IInterest) {
  const {t} = useTranslate()
  const {onFetchDeleteInterest, onFetchUpdateInterest} = useProfileAction()
  const [newTitle, setNewTitle] = useState<string>(title)
  const [isUpdate, setIsUpdate] = useState<boolean>(false)
  const onUpdate = useCallback(() => {
    if (isUpdate) {
      onFetchUpdateInterest(id, newTitle)
      setIsUpdate(false)
    }
  }, [isUpdate, onFetchUpdateInterest, id, newTitle])

  const onDelete = useCallback(() => {
    if (window.confirm(t('interest.delete', 'Вы действительно хотите удалить интерес?'))) {
      onFetchDeleteInterest(id)
    }
  }, [id, onFetchDeleteInterest, t])
  return (
    <Item>
      <RowLeft>
        <Text variant={ETextVariants.Body16}>ID {id}: </Text>
      {
        isUpdate
          ? <RowLeft>
            <Input value={newTitle} onChange={event => setNewTitle(event.target.value)}/>
            <Button variant={EButtonVariants.Primary} onClick={onUpdate}><T keyName={'common.done'}>Готово</T></Button>
          </RowLeft>
          : <Text variant={ETextVariants.Body16}>{title}</Text>
      }
      </RowLeft>
      <Actions>
        <ButtonIcon onClick={() => setIsUpdate(prevState => !prevState)}>
          <EditIcon/>
        </ButtonIcon>
        <ButtonIcon onClick={onDelete}>
          <DeleteIcon/>
        </ButtonIcon>
      </Actions>
    </Item>
  );
}

export default InterestItem;
