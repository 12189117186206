import {axiosInstance} from "./index";
import {ProfileSettingsPayload} from "../store/profile/types";

export const ProfileApi = {
    putUpdateProfile: async (data: ProfileSettingsPayload) => await axiosInstance.put('profile', data),
    putSetAddress: async (data: {

        country: string,
        district: string,
        city: string,
    }) => await axiosInstance.put('profile/address', data),
    putSetAvatar: async (file: File) => await axiosInstance.put(`profile/avatar`, {file}, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    } ),
    postCreateCompanyPost: async (id:number, data: {body: string}) => await axiosInstance.post(`wall/post/company/${id}`, data),
    postCreatePost: async (data: {body: string}) => await axiosInstance.post('wall/post', data),
    putSetPhoto: async (id: string, file: File) => await axiosInstance.put(`wall/post/${id}/photo`, {file}, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    } ),
    getProfile: async () => await axiosInstance.get('profile'),
    getProfileById: async (id: string ) => await axiosInstance.get(`profile/${id}`),
    getMyEventsCreated: async ( ) => await axiosInstance.get('event/my/created'),
    getPhotos: async ( ) => await axiosInstance.get('event/my/created'),
}
