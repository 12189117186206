import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IAdminEventsFilter} from "./types";
import {EventResponse} from "../events/types";
import fetchEvents from "./fetchEvents";

export type AdminEventsState = {
    filter: IAdminEventsFilter,
    events: EventResponse[],
    isLoading: boolean
    isLastPage: boolean
}

const LIMIT = 20

const initialState: AdminEventsState = {
    filter: {limit: LIMIT, page: 1},
    events: [],
    isLoading: true,
    isLastPage: false,
}

export const adminEventsSlice = createSlice({
    name: 'adminEvents',
    initialState,
    reducers: {
        changeFilter: (state, action: PayloadAction<IAdminEventsFilter>) => {
            state.filter = {...state.filter, ...action.payload}
        },
        changePage: (state, action: PayloadAction<number>) => {
            state.filter.page = action.payload
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchEvents.pending, (state, ) => {
            state.isLoading = true
        })
        builder.addCase(fetchEvents.rejected, (state, ) => {
            state.isLoading = false
        })
        builder.addCase(fetchEvents.fulfilled, (state, action: PayloadAction<EventResponse[]>) => {
            state.events = [...action.payload]
            state.isLoading = false
            state.isLastPage = action.payload.length < (state.filter.limit || LIMIT)
        })
    }
})

export const {changeFilter, changePage} = adminEventsSlice.actions
export default adminEventsSlice.reducer
