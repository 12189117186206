import React, {useCallback} from 'react';
import {UserOldSession} from "../../store/auth/types";
import styled from "styled-components";
import {Col} from "../Templates/Col";
import {Row} from "../Templates/Row";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import emptyAvatarImg from '../../assets/img/empty-avatar.png';
import {mediaUrl} from "../../api";
import imgEmptyAvatar from "../../assets/img/no-avatar.png";

interface ProfileCardProps {
  userSession?: UserOldSession;
  onSelect: (phoneNumber: string) => void;
}

const Wrapper = styled.div<{ isUserSession: boolean }>`
  border: .5px solid ${({theme}) => theme.colors.mainGray};
  border-radius: 2rem;
  padding: 3.7rem 4.8rem;
  margin-right: 2rem;
  height: 22.5rem;
  width: 19.2rem;
  margin-bottom: 1rem;
  cursor: ${({isUserSession}) => isUserSession ? 'pointer' : 'default'};
`

const RowAvatar = styled(Row)`
  margin-bottom: 1.6rem;
`
const RowName = styled(Row)`
  text-align: center;
`

const UserAvatar = styled.div`
  width: 9.6rem;
  height: 9.6rem;
  border-radius: 50%;
  overflow: hidden;
  background: #9b9b9b;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;

  img {
    width: auto;
    height: 100%;
  }
`

const UserName = styled(Text)`
  color: ${({theme}) => theme.colors.darkGray};
`

const EmptyUser = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  height: 100%;
`

function ProfileCard({userSession, onSelect}: ProfileCardProps) {
  const handleClick = useCallback(() => {
    if (userSession) {
      onSelect(userSession.phoneNumber);
    }
  }, [onSelect, userSession]);
  return (
    <Wrapper isUserSession={!!userSession} onClick={handleClick}>
      {
        userSession
          ? <><RowAvatar>
            <Col>
              <UserAvatar>
                <img src={userSession.avatarId ? `${mediaUrl}file/${userSession.avatarId}` : imgEmptyAvatar} alt={userSession.fullName}/>
              </UserAvatar>
            </Col>
          </RowAvatar>
            <RowName>
              <Col>
                <UserName variant={ETextVariants.Body16}>
                  {userSession.fullName || userSession.login}
                </UserName>
              </Col>
            </RowName>
          </>
          : <EmptyUser>
            <UserAvatar>
              <img src={emptyAvatarImg} alt='Empty'/>
            </UserAvatar>
          </EmptyUser>
      }
    </Wrapper>
  );
}

export default ProfileCard;
