import {createSlice, current, PayloadAction} from "@reduxjs/toolkit";
import fetchAddItem from "./fetchAddItem";
import {ICartItem, ITicket} from "./types";
import fetchCart from "./fetchCart";
import fetchClearCart from "./fetchClearCart";
import fetchRemoveService from "./fetchRemoveService";
import fetchUpdateItem from "./fetchUpdateItem";
import _ from "lodash";
import fetchTickets from "./fetchTickets";

export type ChatState = {
    isPending: boolean,
    isLoading: boolean,
    items: ICartItem[],
    checkedItemsIds: number[],
    phone: string,
    tickets: ITicket[],
    dataOnePayment: {serviceId: number, quantity: number} | null
}

const initialState: ChatState = {
    isPending: false,
    isLoading: false,
    items: [],
    checkedItemsIds: [],
    phone: '',
    tickets: [],
    dataOnePayment: null
}


export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        checkItem: (state, action: PayloadAction<number>) => {
            const id = action.payload
            const {checkedItemsIds} = current(state)
            if (_.includes(checkedItemsIds, id)) {
                state.checkedItemsIds = checkedItemsIds.filter(item => item !== id)
            } else {
                state.checkedItemsIds = [...checkedItemsIds, id]
            }
        },
        setCheckItem: (state, action: PayloadAction<number[]>) => {
            state.checkedItemsIds = action.payload
        },
        clearCheckedItems: (state) => {
            state.checkedItemsIds = []
        },
        changePhone: (state, action: PayloadAction<string>) => {
            state.phone = action.payload
        },
        changeDataOnePayment: (state, action: PayloadAction<{serviceId: number, quantity: number}>) => {
            state.dataOnePayment = action.payload
        },
        clearDataOnePayment: (state) => {
            state.dataOnePayment = null
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchAddItem.fulfilled, (state) => {
            state.isPending = false
        })
        builder.addCase(fetchAddItem.pending, (state) => {
            state.isPending = true
        })
        builder.addCase(fetchAddItem.rejected, (state) => {
            state.isPending = true
        })
        builder.addCase(fetchClearCart.fulfilled, (state) => {
            state.isPending = false
        })
        builder.addCase(fetchClearCart.pending, (state) => {
            state.isPending = true
        })
        builder.addCase(fetchClearCart.rejected, (state) => {
            state.isPending = true
        })
        builder.addCase(fetchUpdateItem.fulfilled, (state) => {
            state.isPending = false
        })
        builder.addCase(fetchUpdateItem.pending, (state) => {
            state.isPending = true
        })
        builder.addCase(fetchUpdateItem.rejected, (state) => {
            state.isPending = true
        })
        builder.addCase(fetchRemoveService.fulfilled, (state) => {
            state.isPending = false
        })
        builder.addCase(fetchRemoveService.pending, (state) => {
            state.isPending = true
        })
        builder.addCase(fetchRemoveService.rejected, (state) => {
            state.isPending = true
        })
        builder.addCase(fetchCart.fulfilled, (state, action: PayloadAction<ICartItem[]>) => {
            state.isLoading = false
            state.items = action.payload
        })
        builder.addCase(fetchCart.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchCart.rejected, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchTickets.fulfilled, (state, action: PayloadAction<ITicket[]>) => {
            state.isLoading = false
            state.tickets = action.payload
        })
        builder.addCase(fetchTickets.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchTickets.rejected, (state) => {
            state.isLoading = true
        })
    }
})

export const {setCheckItem, checkItem, clearCheckedItems, changePhone, changeDataOnePayment, clearDataOnePayment} = cartSlice.actions
export default cartSlice.reducer
