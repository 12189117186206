import React, {useCallback} from 'react';
import styled from "styled-components";
import Table from "../../UI/Table";
import {useAdminUsersAction, useAdminUsersState} from "../../store/adminUsers/hooks";
import {Button, EButtonVariants} from "../../UI/Button";
import dayjs from "dayjs";
import {T, useTranslate} from "@tolgee/react";

const Wrapper = styled.div`
  flex-wrap: wrap;
  width: calc(100% + 80px);
  margin: 0 -40px;
  margin-top: 40px;
`

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: stretch;
  margin-top: 32px;
`

function UsersListView() {
  const {users, isLastPage, filter} = useAdminUsersState()
  const {onChangePage} = useAdminUsersAction()
  const {t} = useTranslate()

  // const onDelete = useCallback((id: number) => {
  //   if (window.confirm(t('admin.users.confirmDelete','Вы действительно хотите удалить компанию?'))) {
  //     // onDeleteCompany(id)
  //   }
  // }, [t])
  return (
    <Wrapper>
        <Table
          heading={[
            {key: 'id', value: '№'},
            {key: 'login', value: t('admin.users.login','Никнейм')},
            {key: 'firstName', value: t('admin.users.firstName','Имя')},
            {key: 'lastName', value: t('admin.users.lastName','Фамилия')},
            {key: 'registeredAt', value: t('admin.users.registeredAt','Регистрация')},
          ]}
          // actions={[
          //   {title: 'Удалить', callback: onDelete, dataKey: 'id'},
          //   {title: 'Заблокировать', callback: onBlock, dataKey: 'id'},
          // ]}
          data={users.map((user) => ({
            ...user,
            registeredAt: dayjs(user.registeredAt).format('D MMMM YYYY')
          }))}
        />
      <Buttons>
        <Button onClick={() => onChangePage(filter.page ? filter.page - 1 : 1)} variant={EButtonVariants.Primary} disabled={filter.page === 1}>
          <T keyName="pagination.prev">Предыдущая</T>
        </Button>
        <Button onClick={() => onChangePage(filter.page ? filter.page + 1 : 1)} variant={EButtonVariants.Primary} disabled={isLastPage}>
          <T keyName="pagination.next">Следующая</T>
        </Button>
      </Buttons>
    </Wrapper>
  );
}

export default UsersListView;
