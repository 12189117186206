import React from 'react';
import styled from "styled-components";
import {Card} from "../../components/Templates/Card";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import 'dayjs/locale/ru';
import {useWallState} from "../../store/wall/hooks";
import CompanyPostComponent from "../../components/ProfileComponents/CompanyPostComponent";
import {T} from "@tolgee/react";

const CardStyled = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 0;
  width: 100%;

  ${({theme}) => theme.mediaQueries.md} {
    gap: 2px;
  }
`


function CompanyWall({id}: { id: number }) {
  const {wall} = useWallState()

  return (
    <>
      <CardStyled>
        {
          wall?.length
            ? wall.map((item) => (
              <CompanyPostComponent key={item.id} item={item} companyId={id}/>
            ))
            : <Text variant={ETextVariants.Body16}><T keyName="common.noNews">Новостей нет :(</T></Text>
        }
      </CardStyled>
    </>
  );
}

export default CompanyWall;
