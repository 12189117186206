import React, {useRef, useState} from 'react';
import styled from 'styled-components';
import {Card} from "../Templates/Card";
import {useAcquaintanceAction, useAcquaintanceState} from "../../store/acquaintance/hooks";
import {mediaUrl} from "../../api";
import imgEmptyAvatar from "../../assets/img/no-avatar.png";
import {HelperIcon} from "../../UI/Svg";
import {getUserShortName} from "../../utils/user";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {AcquaintancePurpose, UserEntity} from "../../types";
import {useSwipeable} from "react-swipeable";
import {T, useTranslate} from "@tolgee/react";

const Wrapper = styled.div`
  width: 100%;
  height: 695px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  ${({theme}) => theme.mediaQueries.md} {
    height: 487px;
  }
`
const WrapperCard = styled(Card)`
  width: 100%;
  max-width: 1070px;
  height: 695px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  touch-action: pan-y;
  padding: 0;

  ${({theme}) => theme.mediaQueries.md} {
    height: 487px;
  }
`

const UserImage = styled.img<{ $isShowMore: boolean }>`
  position: relative;
  top: 50%;
  left: 50%;
  pointer-events: none;
  transform: translate(-50%, -50%);

  height: auto;
  width: 100%;

  filter: blur(${({$isShowMore}) => $isShowMore ? '15px' : '0'});

  ${({theme}) => theme.mediaQueries.md} {
    height: 100%;
    width: auto;
  }
`

const InfoCircle = styled.div`
  position: absolute;
  top: 30px;
  left: 30px;
  cursor: pointer;
  filter: drop-shadow(0 0 2px black);

  svg {
    width: 24px;
    height: 24px;

    path {
      stroke: ${({theme}) => theme.colors.white};
    }
  }

  ${({theme}) => theme.mediaQueries.md} {
    right: 16px;
    left: unset;
    bottom: 46.5px;
    top: unset;
  }
`

const ShortDescription = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  bottom: 45px;
  left: 30px;

  ${({theme}) => theme.mediaQueries.md} {
    max-width: calc(100% - 50px);
    left: 16px;
    bottom: 16px;
  }
`
const MoreDescription = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  top: 100px;
  left: 30px;

  ${({theme}) => theme.mediaQueries.md} {
    top: 16px;
    left: 16px;
  }
`

const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
`

const NameTitle = styled.h2`
  color: ${({theme}) => theme.colors.white};
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.24px;
  text-shadow: 0 0 2px black;
`

const TextProfession = styled(Text)`
  color: ${({theme}) => theme.colors.white};
  text-shadow: 0 0 2px black;
`
const Categories = styled.div`
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  ${({theme}) => theme.mediaQueries.md} {
  }
`

const Category = styled(Text)`
  padding: 5px 10px;
  background: ${({theme}) => theme.colors.lightGray2};
  border-radius: 5px;
`

function ProfileComponent({suggestion}: { suggestion: UserEntity }) {
  const {t} = useTranslate()
  const {onFetchNextSuggestion, onLike, onUnLike} = useAcquaintanceAction()
  const [isShowMore, setIsShowMore] = useState<boolean>(false);

  const divRef = useRef<HTMLDivElement | null>(null)

  const handlers = useSwipeable({
    onSwiped: (eventData: any) => {
      const deltaX = eventData.deltaX
      const el = divRef.current
      if (el) {
        if (suggestion) {
          if (deltaX < -150) {
            onUnLike(suggestion.id)
          } else if (deltaX > 150) {
            onLike(suggestion.id)
          }
        }
        el.style.left = '50%'
      }
    },
    onSwiping: (eventData: any) => {
      const deltaX = eventData.deltaX
      const el = divRef.current
      if (el) {
        if (deltaX < 50 && deltaX > -50) el.style.left = `calc(50% + ${deltaX}px)`
      }
    },
    ...{
      delta: 1,                             // min distance(px) before a swipe starts. *See Notes*
      preventScrollOnSwipe: true,           // prevents scroll during swipe (*See Details*)
      trackTouch: true,                      // track touch input
      trackMouse: true,                     // track mouse input
      rotationAngle: 0,                      // set a rotation angle
      swipeDuration: Infinity,               // allowable duration of a swipe (ms). *See Notes*
      touchEventOptions: {passive: true},  // options for touch listeners (*See Details*)
    }
  });
  const refPassThrough = (el: any) => {
    handlers.ref(el);
    divRef.current = el;
  }

  return (
    <Wrapper>
      {suggestion && <WrapperCard {...handlers} ref={refPassThrough}>
          <UserImage $isShowMore={isShowMore} draggable={false}
                     src={suggestion.avatar ? `${mediaUrl}file/${suggestion.avatar.id}` : imgEmptyAvatar} alt=""/>
          <InfoCircle onClick={() => setIsShowMore(prevState => !prevState)}>
              <HelperIcon/>
          </InfoCircle>
        {isShowMore && <MoreDescription>
            <NameTitle>{suggestion?.city || t('acquaintanceSettings.noCity','Город не указан')}</NameTitle>
            <NameTitle>
                <T keyName="acquaintanceSettings.fields.acquaintancePurpose.label">Хочу</T> {!suggestion
              ? <T keyName="acquaintanceSettings.acquaintancePurpose.none">Не указано</T>
              : suggestion.acquaintancePurpose === AcquaintancePurpose.Friends
                ? <T keyName="acquaintanceSettings.acquaintancePurpose.friends">Найти друзей</T>
                : suggestion.acquaintancePurpose === AcquaintancePurpose.Chatting
                  ? <T keyName="acquaintanceSettings.acquaintancePurpose.chatting">Просто общаться</T>
                  : suggestion.acquaintancePurpose === AcquaintancePurpose.Love
                    ? <T keyName="acquaintanceSettings.acquaintancePurpose.love">Найти любовь</T>
                    : ''
            }
            </NameTitle>
            <NameTitle>{suggestion?.description || t('acquaintanceSettings.noDescription','Описание не указано')}</NameTitle>
        </MoreDescription>}
          <ShortDescription>
              <InfoBlock>
                  <NameTitle>{getUserShortName(suggestion)}, {suggestion?.age || t('acquaintanceSettings.noAge','Возраст не указан')}</NameTitle>
                  <TextProfession
                      variant={ETextVariants.Body16}>{suggestion?.profession || t('acquaintanceSettings.noProfession','Профессия не указана')}</TextProfession>
              </InfoBlock>

              <Categories>
                {suggestion.interests?.map((interest) => <Category key={`category-${interest.id}`}
                                                                   variant={ETextVariants.MiniDark}>
                  <T keyName={`interest.list.${interest.id}`}>{interest.title}</T>
                  </Category>)}
              </Categories>
          </ShortDescription>
      </WrapperCard>}
    </Wrapper>
  );
}

export default ProfileComponent;
