import {IPhoto, UserEntity} from "../../types";
import {IAddress} from "../events/types";

export enum ECompanyStatus {
  pending = 'pending',
  approved = 'approved',
  rejected = 'rejected',
}

export interface ICompany {
  id: number,
  title: string,
  site: null | string,
  description: string,
  address: IAddress | null,
  phoneNumber: string,
  email: string,
  taxPayerNumber: string,
  registrationNumber: string,
  showCompanyContacts: boolean,
  currency: string,
  owner: UserEntity,
  status: ECompanyStatus,
  header?: null | IPhoto
  avatar?: null | IPhoto,
  companyId: null | string
}

export interface IUserCompany extends ICompany {
  balance: number,
}

export interface FormCompanyData {
  title: string,
  description: string,
  phoneNumber: string,
  email: string,
  taxPayerNumber: string,
  registrationNumber: string,
  currency: string,
  site: string,
  showCompanyContacts: boolean,
  latitude: number,
  longitude: number
  ownerPhoneNumber?: string
  companyId?: string
}

export interface CreateCompanyPayload extends FormCompanyData {
  header?: null | File
  avatar?: null | File,
}

export interface UpdateCompanyPayload extends CreateCompanyPayload {
  id: number
}
