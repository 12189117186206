import React, {FormEvent, useState} from 'react';
import styled from "styled-components";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {T} from "@tolgee/react";
import {Col} from "../../components/Templates/Col";
import {getTextStyle} from "../../UI/Text/utils";
import {Row} from "../../components/Templates/Row";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {useAuthAction} from "../../store/auth/hooks";
import {Button, EButtonVariants} from '../../UI/Button';
import {ButtonDesc, Buttons, CarouselMobile, LinkStyled } from './styled';
import AuthCarousel from "../../components/AuthComponents/AuthCarousel";

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;

  ${({theme}) => theme.mediaQueries.md} {
    flex: 1;
  }
`

const FormStyled = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const TitleBlock = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
`

const Title = styled(Text)`
`

const PhoneInputBlock = styled(Col)`
  & .form-control {
    width: 100%;
    height: 4rem;
    padding: 1rem 2rem 1rem 5rem;
    border-radius: 10px;
    background-color: ${({theme}) => theme.colors.lightWhite0};
    border: 0;
    ${({theme}) => getTextStyle(theme, ETextVariants.Body16)};
  };

  & .flag-dropdown {
    border: none;
    border-radius: 0px;
    background-color: transparent !important;
  };

  & .selected-flag {
    background-color: transparent !important;
    padding-left: 2rem;
  };
`

const PhoneRow = styled(Row)`
  margin-bottom: 1rem;
  ${({theme}) => theme.mediaQueries.md} {
    flex: unset;
  }
`

const DescInput = styled(Text)`
  margin-top: 10px;
  display: block;
`

function MainAuthPage() {
  const [phoneNumberValue, setPhoneNumberValue] = useState<string>('');
  const {onSendSms} = useAuthAction()
  const handleFormSubmit = (e: FormEvent) => {
    e.preventDefault();
    onSendSms(`+${phoneNumberValue.replace(/[ ]/g, '')}`);
  };
  return (
    <FormWrapper>
      <FormStyled onSubmit={handleFormSubmit}>
        <TitleBlock>
          <Title variant={ETextVariants.Heading}>
            <T keyName="auth.enterAccount">Вход в аккаунт</T>
          </Title>
        </TitleBlock>
        <PhoneRow>
          <PhoneInputBlock>
            <Text variant={ETextVariants.Body16}><T keyName="auth.inputPhone">Введите номер телефона</T></Text>
            <PhoneInput
              country="lv"
              placeholder=""
              inputProps={{ name: 'phoneNumber' }}
              containerStyle={{ marginTop: 20 }}
              onEnterKeyPress={handleFormSubmit}
              value={phoneNumberValue}
              onChange={setPhoneNumberValue}
            />
            <DescInput variant={ETextVariants.MiniGray}>
              <T keyName="auth.sendSmsWithCode">Отправим вам СМС с кодом подтверждения.</T>
            </DescInput>
          </PhoneInputBlock>
        </PhoneRow>

        <CarouselMobile>
          <AuthCarousel/>
        </CarouselMobile>
        <Buttons>
          <Button type="submit" variant={EButtonVariants.Primary} $fullWidth>
            <T keyName="auth.buttons.login">Войти</T>
          </Button>
          <ButtonDesc variant={ETextVariants.MiniGray}>
            <T
              keyName="auth.termsOfUse"
              params={{
                a1: <LinkStyled to="/terms"/>,
                a2: <LinkStyled to="/politics"/>
              }}
              defaultValue={`Регистрируясь или авторизуясь, вы принимаете <LinkStyled to="/politics">Условия пользовательского соглашения</LinkStyled>, <LinkStyled to="#">Политики</LinkStyled> и даете Согласие на обработку персональных данных.`}
            />
          </ButtonDesc>
        </Buttons>
      </FormStyled>
    </FormWrapper>
  );
}

export default MainAuthPage;
