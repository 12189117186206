import {useDispatch, useSelector} from "react-redux";
import {useCallback, useMemo} from "react";
import {AppDispatch, RootState} from "../index";
import fetchNextSuggestion from "./fetchNextSuggestion";
import fetchLikes from "./fetchLikes";
import fetchUserMatches from "./fetchUserMatches";
import fetchUserSympathies from "./fetchUserSympathies";
import fetchPostLike from "./fetchPostLike";
import fetchDeleteUnLike from "./fetchDeleteUnLike";
import fetchSuggestions from "./fetchSuggestions";
import {setSuggestion} from "./acquaintanceSlice";
import {UserEntity} from "../../types";

export const getAcquaintance = (state: RootState) => state.acquaintance

export function useAcquaintanceAction() {
  const dispatch = useDispatch<AppDispatch>()

  const onSetSuggestion = useCallback((data: UserEntity) => {
    dispatch(setSuggestion(data))
  }, [dispatch])
  const onFetchNextSuggestion = useCallback(() => {
    dispatch(fetchNextSuggestion())
  }, [dispatch])
  const onFetchSuggestions = useCallback(() => {
    dispatch(fetchSuggestions())
  }, [dispatch])
  const onFetchLikes = useCallback(() => {
    dispatch(fetchLikes())
  }, [dispatch])
  const onFetchUserMatches = useCallback(() => {
    dispatch(fetchUserMatches())
  }, [dispatch])
  const onFetchUserSympathies = useCallback(() => {
    dispatch(fetchUserSympathies())
  }, [dispatch])

  const onFetchData = useCallback((isNext: boolean = false) => {
    dispatch(fetchLikes())
    dispatch(fetchUserMatches())
    dispatch(fetchUserSympathies())
    if (isNext) {
      dispatch(fetchNextSuggestion())
    } else {
      dispatch(fetchSuggestions())
    }
  }, [dispatch])

  const onLike = useCallback((id: number) => {
    dispatch(fetchPostLike(id))
      .unwrap()
      .then(() => onFetchData(true))
  }, [dispatch, onFetchData])
  const onUnLike = useCallback((id: number) => {
    dispatch(fetchDeleteUnLike(id))
      .unwrap()
      .then(() => onFetchData(true))
  }, [dispatch, onFetchData])
  return useMemo(() => ({
    onFetchNextSuggestion,
    onFetchLikes,
    onFetchUserMatches,
    onFetchUserSympathies,
    onFetchData,
    onLike,
    onUnLike,
    onFetchSuggestions,
    onSetSuggestion,
  }), [onFetchData,
    onFetchLikes,
    onFetchNextSuggestion,
    onFetchUserMatches,
    onFetchUserSympathies,
    onLike,
    onUnLike,
    onFetchSuggestions,
    onSetSuggestion])
}

export function useAcquaintanceState() {
  return useSelector(getAcquaintance)
}
