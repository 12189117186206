import {useDispatch, useSelector} from "react-redux";
import {useCallback, useMemo} from "react";
import {AppDispatch, RootState} from "../index";
import {useNavigate} from "react-router-dom";
import fetchCart from "./fetchCart";
import fetchCartTotal from "./fetchCartTotal";
import fetchAddItem from "./fetchAddItem";
import fetchRemoveService from "./fetchRemoveService";
import fetchClearCart from "./fetchClearCart";
import fetchUpdateItem from "./fetchUpdateItem";
import {
  changeDataOnePayment,
  changePhone,
  checkItem,
  clearCheckedItems,
  clearDataOnePayment,
  setCheckItem
} from "./cartSlice";
import fetchCreatePayment from "./fetchCreatePayment";
import {EPaymentMethod} from "./types";
import fetchTickets from "./fetchTickets";
import {useUpdatePaymentIntent} from "../../hooks/useUpdatePaymentIntent";
import Stripe from "stripe";
import fetchDeleteTicket from "./fetchDeleteTicket";

export const getCart = (state: RootState) => state.cart

export function useCartAction() {
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const {updatePaymentIntent} = useUpdatePaymentIntent()
  const onChangePhone = useCallback((val: string) => {
    dispatch(changePhone(val))
  }, [dispatch])
  const onClearDataOnePayment = useCallback(() => {
    dispatch(clearDataOnePayment())
  }, [dispatch])
  const onChangeDataOnePayment = useCallback((data: {serviceId: number, quantity: number}) => {
    dispatch(changeDataOnePayment(data))
  }, [dispatch])
  const onFetchUserCart = useCallback(() => {
    dispatch(fetchCart())
  }, [dispatch])
  const onFetchTickets = useCallback(() => {
    dispatch(fetchTickets())
  }, [dispatch])
  const onClearCheckedItems = useCallback(() => {
    dispatch(clearCheckedItems())
  }, [dispatch])
  const onFetchUserCartTotal = useCallback(() => {
    dispatch(fetchCartTotal())
  }, [dispatch])
  const onCheckItem = useCallback((id: number) => {
    dispatch(checkItem(id))
  }, [dispatch])
  const onSetCheckItem = useCallback((data: number[]) => {
    dispatch(setCheckItem(data))
  }, [dispatch])
  const onFetchCart = useCallback(() => {
    onFetchUserCart()
    onFetchTickets()
  }, [onFetchTickets, onFetchUserCart])
  const onAddCartItem = useCallback((data:{favorId: number, quantity: number, withSelect?: boolean}) => {
    dispatch(fetchAddItem(data))
      .unwrap()
      .then(() => {
        onFetchUserCart()
      })
  }, [dispatch, onFetchUserCart])
  const onUpdateCartItem = useCallback((data:{itemId: number, quantity: number}) => {
    dispatch(fetchUpdateItem(data))
      .unwrap()
      .then(() => {
        onFetchUserCart()
      })
  }, [dispatch, onFetchUserCart])
  const onRemoveServiceCart = useCallback((data: number) => {
    dispatch(fetchRemoveService(data))
      .unwrap()
      .then(onFetchUserCart)
  }, [dispatch, onFetchUserCart])
  const onDeleteTicket = useCallback((id: number) => {
    dispatch(fetchDeleteTicket(id))
      .unwrap()
      .then(onFetchTickets)
  }, [dispatch, onFetchTickets])
  const onClearCart = useCallback(() => {
    dispatch(fetchClearCart())
      .unwrap()
      .then(onFetchUserCart)
  }, [dispatch, onFetchUserCart])
  const onFetchCreatePayment = useCallback((data: {method: EPaymentMethod, isOne: boolean}, paymentIntentId?: string, updateData?: Stripe.PaymentIntentUpdateParams) => {
    dispatch(fetchCreatePayment(data))
      .unwrap()
      .then((res) => {
        if (paymentIntentId && updateData) {
          updatePaymentIntent(paymentIntentId, updateData)
        }
        onFetchCart()
        onClearDataOnePayment()
        if (res?.method === EPaymentMethod.Cash) {
          navigate('/payment/complete')}
      })
  }, [dispatch, navigate, onClearDataOnePayment, onFetchCart, updatePaymentIntent])
  return useMemo(() => ({
    onFetchUserCart,
    onFetchUserCartTotal,
    onAddCartItem,
    onRemoveServiceCart,
    onClearCart,
    onUpdateCartItem,
    onCheckItem,
    onClearCheckedItems,
    onChangePhone,
    onFetchCreatePayment,
    onFetchCart,
    onClearDataOnePayment,
    onChangeDataOnePayment,
    onSetCheckItem,
    onDeleteTicket,
  }), [
    onFetchUserCart,
    onFetchUserCartTotal,
    onAddCartItem,
    onRemoveServiceCart,
    onClearCart,
    onUpdateCartItem,
    onCheckItem,
    onClearCheckedItems,
    onChangePhone,
    onFetchCreatePayment,
    onFetchCart,
    onClearDataOnePayment,
    onChangeDataOnePayment,
    onSetCheckItem,
    onDeleteTicket,
  ])
}

export function useCartState() {
  return useSelector(getCart)
}
