/* eslint-disable react/no-unused-prop-types */
import {forwardRef, FC} from 'react';
import DatePicker from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import en from 'date-fns/locale/en-GB';
import lv from 'date-fns/locale/lv';
import styled from 'styled-components';

import 'react-datepicker/dist/react-datepicker.css';

import imgArrowDownGray from '../../assets/img/arrow-down-gray.png';
import imgArrowDownPurple from '../../assets/img/arrow-down-purple.png';

import './datapicker.style.css';
import {T} from '@tolgee/react';
import Text from "../Text";
import {ETextVariants} from "../Text/types";
import {useAuthState} from "../../store/auth/hooks";

export const localeLangs: {[key: string]: Locale} = {
  "en": en,
  "ru": ru,
}

interface TypeProps {
  setDateRange: (data: any) => void;
  startDate: any;
  endDate: any;
  typeArrowPurple: boolean;
  bg: boolean;
  isClearButton: boolean;
  mobileVersion: boolean;
  isIcon?: boolean
}

const InputDate = styled.div<{ bg: boolean; mobileVersion: boolean; $typeArrowPurple: boolean, isIcon: boolean }>`
  height: 4rem;
  border-radius: 1rem;
  border: 1px solid ${({theme, bg}) => !bg ? theme.colors.bg : theme.colors.white};
  padding: 1rem 30px 1rem 2rem;
  min-width: 14rem;
  background: ${({isIcon, $typeArrowPurple}) => isIcon ? `url(${!$typeArrowPurple ? imgArrowDownGray : imgArrowDownPurple}) no-repeat` : 'transparent'};
  background-position: 93% center;
  width: 100%;
  font-size: 1.6rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: ${({theme, bg}) => !bg ? theme.colors.bg : theme.colors.white};

  ${({theme}) => theme.mediaQueries.md} {
    ${({theme, bg, mobileVersion}) => mobileVersion && ` {
    background-color: ${!bg ? theme.colors.bg : theme.colors.white};
    padding: 1rem 1.6rem 1.3rem 1rem;
    border-radius: 0;
    height: 5.9rem;
  }`}
  }
`;

const PlaceholderStyled = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
`

// eslint-disable-next-line react/no-unstable-nested-components
const CustomInputDatePicker = forwardRef(({value, onClick, typeArrowPurple, bg, mobileVersion, isIcon, ...props}: any, ref: any) => (
  <InputDate
    className="example-custom-input"
    onClick={onClick}
    isIcon={isIcon}
    ref={ref}
    bg={bg}
    mobileVersion={mobileVersion}
    $typeArrowPurple={typeArrowPurple}
  >
    {value || (
      <PlaceholderStyled>
        <Text variant={ETextVariants.Body16}>
          <T keyName="filter.selectDate">Выберите дату</T>
        </Text>
      </PlaceholderStyled>
    )}
  </InputDate>
));

export const DataPickerRange: FC<TypeProps> = ({
                                                 isIcon = true,
                                                 setDateRange,
                                                 startDate,
                                                 endDate,
                                                 bg,
                                                 typeArrowPurple,
                                                 isClearButton,
                                                 mobileVersion,
                                                 ...props
                                               }) => {
  const {lang} = useAuthState()
  return (
    <DatePicker
      selectsRange
      startDate={startDate}
      endDate={endDate}
      onChange={(update: any) => {
        setDateRange(update);
      }}
      locale={localeLangs[lang] || en}
      // @ts-ignore
      wrapperClassName={props.className}
      dateFormat="P"
      isClearable={isClearButton}
      popperClassName="popperDataPickerRange"
      customInput={<CustomInputDatePicker isIcon={isIcon} bg={bg} typeArrowPurple={typeArrowPurple} mobileVersion={mobileVersion}
      />}
    />
  );
};
