import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
  <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} >
    <path
      d="M8.00065 1.33301C9.66817 3.15858 10.6158 5.5277 10.6673 7.99967C10.6158 10.4717 9.66817 12.8408 8.00065 14.6663M8.00065 1.33301C6.33313 3.15858 5.38548 5.5277 5.33398 7.99967C5.38548 10.4717 6.33313 12.8408 8.00065 14.6663M8.00065 1.33301C4.31875 1.33301 1.33398 4.31778 1.33398 7.99967C1.33398 11.6816 4.31875 14.6663 8.00065 14.6663M8.00065 1.33301C11.6826 1.33301 14.6673 4.31778 14.6673 7.99967C14.6673 11.6816 11.6826 14.6663 8.00065 14.6663M1.66733 5.99967H14.334M1.66732 9.99967H14.334"
      stroke="#9B9B9B" strokeLinecap="round" strokeLinejoin="round"/>
  </Svg>
);

export default Icon;
