import React, {useMemo} from 'react';
import {UserEntity} from "../../types";
import {formatDistance} from "date-fns";
import ru from "date-fns/esm/locale/ru/index";
import {useChatState} from "../../store/chat/hooks";

function UserStatusComponent({onlineAt, id}: UserEntity) {
  const {usersOnline} = useChatState()
  const status = useMemo(() => {
    const val = formatDistance(new Date(`${onlineAt}`), new Date(), {
      addSuffix: true,
      locale: ru,
    })
    return val
  }, [onlineAt])

  const isOnline = useMemo(() => usersOnline.find((user) => user.id === id), [usersOnline, id])

  return (
    <>{isOnline ? 'online' : status}</>
  )
}

export default UserStatusComponent;
