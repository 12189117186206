import React, {useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import {ContainerMax} from "../../components/Templates/ContainerMax";
import ProfileComponent from "../../components/AcquaintanceComponents/ProfileComponent";
import {Card} from "../../components/Templates/Card";
import {Link, Navigate, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {HeartsIcon} from "../../UI/Svg";
import {useAcquaintanceAction, useAcquaintanceState} from "../../store/acquaintance/hooks";
import AcquaintanceActionsComponent from "../../components/AcquaintanceComponents/AcquaintanceActionsComponent";
import ModalAcquaintanceUsers from "../../components/Modals/ModalAcquaintanceUsers";
import {useAppAction} from "../../store/app/hooks";
import ConfirmModal from "../../components/Modals/ConfirmModal";
import {useProfileAction} from "../../store/profile/hooks";
import {useAuthState} from "../../store/auth/hooks";
import MainPage from "./MainPage";
import Settings from "./settings";
import _ from "lodash";
import InterestsPage from "../profile/settings/InterestsPage";
import {T, useTranslate} from "@tolgee/react";

const ContainerStyled = styled(ContainerMax)`
  ${({theme}) => theme.mediaQueries.md} {
    flex: 1;
    flex-direction: column;
    gap: 0;
  }
`

const Main = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 1070px;

  ${({theme}) => theme.mediaQueries.md} {
    padding: 0 16px 50px;
    flex: 1;
    background: ${({theme}) => theme.colors.white};
  }
`

const Aside = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
  width: 100%;
  max-width: 343px;

  ${({theme}) => theme.mediaQueries.md} {
    display: none;
  }
`
const AsideCard = styled(Card)`
  padding: 10px 0;
  display: flex;
  flex-direction: column;
`

const Item = styled.div<{ $isActive: boolean }>`
  padding: 17px 20px;
  width: 100%;
  background: ${({theme, $isActive}) => $isActive ? `rgba(108, 39, 171, 0.10)` : theme.colors.white};
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;

  ${({theme}) => theme.mediaQueries.md} {
    padding: 10px 5px;
    width: fit-content;

    ${Text} {
      white-space: nowrap;
    }
  }
`

const MobileAside = styled.div`
  align-items: center;
  justify-content: center;
  display: none;
  gap: 5px;
  background: ${({theme}) => theme.colors.white};
  padding: 6px 10px;

  ${({theme}) => theme.mediaQueries.md} {
    display: flex;
  }
`

function Index() {
  const {t} = useTranslate()
  const navigate = useNavigate()
  const {onFetchData} = useAcquaintanceAction()
  const {onUpdateProfile, onFetchInterests} = useProfileAction()
  const {user} = useAuthState()
  const {likes, matches, sympathies} = useAcquaintanceState()
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [isShowConfirmModal, setIsShowConfirmModal] = useState<boolean>(false);

  const {pathname} = useLocation()

  useEffect(() => {
    if (!user?.inAcquaintances) {
      setIsShowConfirmModal(true)
    }
  }, [user])

  useEffect(() => {
    if (user) {
      onFetchData(!user.inAcquaintances)
    }
  }, [onFetchData, user])

  const [activeTab, setActiveTab] = useState<number>(0);

  useEffect(() => {
    setActiveTab(0)
  }, []);

  useEffect(() => {
    onFetchInterests()
  }, [onFetchInterests])

  const isSettings = useMemo(() => _.includes(pathname.split('/'), 'settings'), [pathname])
  const isInterests = useMemo(() => _.includes(pathname.split('/'), 'interests'), [pathname])

  useEffect(() => {
    if (isSettings) setActiveTab(4)
  }, [isSettings])
  useEffect(() => {
    if (isInterests) setActiveTab(5)
  }, [isInterests])

  const users = useMemo(() => {
    switch (activeTab) {
      case 1:
        return likes
      case 2:
        return sympathies
      case 3:
        return matches
      default:
        return []
    }
  }, [activeTab, likes, matches, sympathies])

  const modalTitle = useMemo(() => {
    switch (activeTab) {
      case 1:
        return t('acquaintance.meLiked', 'Мне понравились')
      case 2:
        return t('acquaintance.iLiked', 'Я понравился')
      case 3:
        return t('acquaintance.merge', 'Взаимно')
      default:
        return t('acquaintance.users', 'Пользователи')
    }
  }, [activeTab, t])

  return (
    <>
      <ContainerStyled>

        <Routes>
          <Route index element={<Main>
            <MobileAside>
              <Item onClick={() => {
                setIsShowModal(true)
                setActiveTab(1)
              }} $isActive={activeTab === 1}>
                <Text variant={ETextVariants.Body16}>
                  <T keyName="acquaintance.meLiked">Мне понравились</T>
                </Text>
              </Item>
              <Item onClick={() => {
                setIsShowModal(true)
                setActiveTab(3)
              }} $isActive={activeTab === 3}>
                <HeartsIcon/> <Text variant={ETextVariants.Body16}>
                <T keyName="acquaintance.iLiked">Взаимно</T>
              </Text>
              </Item>
              <Item onClick={() => {
                setIsShowModal(true)
                setActiveTab(2)
              }} $isActive={activeTab === 2}>
                <Text variant={ETextVariants.Body16}>
                  <T keyName="acquaintance.merge">Я понравился</T>
                </Text>
              </Item>
            </MobileAside>
            <MainPage onSetTab={setActiveTab}/>
          </Main>}/>
          <Route path="/settings" element={<Settings/>}/>
          <Route
            path="/interests"
            element={<InterestsPage/>}
          />
          <Route path="*" element={<Navigate to="/acquaintance"/>}/>
        </Routes>
        <Aside>
          <AsideCard>
            <Item onClick={() => {
              setActiveTab(0)
              navigate('/acquaintance')
            }} $isActive={activeTab === 0}>
              <Text variant={ETextVariants.Body16}>
                <T keyName="acquaintance.newsline">Лента</T></Text>
            </Item>
            <Item onClick={() => {
              setIsShowModal(true)
              setActiveTab(1)
            }} $isActive={activeTab === 1}>
              <Text variant={ETextVariants.Body16}>
                <T keyName="acquaintance.meLiked">Мне понравились</T>
              </Text>
            </Item>
            <Item onClick={() => {
              setIsShowModal(true)
              setActiveTab(2)
            }} $isActive={activeTab === 2}>
              <Text variant={ETextVariants.Body16}>
                <T keyName="acquaintance.iLiked">Взаимно</T>
              </Text>
            </Item>
            <Item onClick={() => {
              setIsShowModal(true)
              setActiveTab(3)
            }} $isActive={activeTab === 3}>
              <Text variant={ETextVariants.Body16}>
                <T keyName="acquaintance.merge">Я понравился</T>
              </Text>
            </Item>
            <Item onClick={() => {
              setActiveTab(4)
              navigate('/acquaintance/settings')
            }} $isActive={activeTab === 4}>
              <Text variant={ETextVariants.Body16}>
                <T keyName="common.settings">Настройки</T>
              </Text>
            </Item>
            <Item onClick={() => {
              setActiveTab(5)
              navigate('/acquaintance/interests')
            }} $isActive={activeTab === 5}>
              <Text variant={ETextVariants.Body16}>
                <T keyName="common.interests">Интересы</T>
              </Text>
            </Item>
          </AsideCard>
        </Aside>
      </ContainerStyled>

      {
        isShowModal &&
          <ModalAcquaintanceUsers isProfileItem={activeTab === 2 || activeTab === 3} users={users} onClose={() => {
            setIsShowModal(false)
            setActiveTab(isSettings ? 4 : isInterests ? 5 : 0)
          }} title={modalTitle}/>
      }
      {
        isShowConfirmModal && <ConfirmModal title={t('navbar.acquaintance', 'Знакомства')}
                                            body={t('acquaintanceModal.body', 'Вы перешли в раздел, где пользователи могут знакомиться друг с другом. Опубликовать вашу анкету?')}
                                            confirmButtonText={t('acquaintanceModal.button', "Опубликовать")}
                                            onConfirm={() => {
                                              onUpdateProfile({inAcquaintances: true})
                                              setIsShowConfirmModal(false)
                                            }}
                                            onReject={() => {
                                              setIsShowConfirmModal(false)
                                              navigate(-1)
                                            }}
          />
      }
    </>
  );
}

export default Index;
