import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IAdminSupportsFilter, IReport} from "./types";
import fetchReports from "./fetchReports";

export type AdminSupportsState = {
    filter: IAdminSupportsFilter,
    reports: IReport[],
    isLoading: boolean
}

const initialState: AdminSupportsState = {
    filter: {},
    reports: [],
    isLoading: true
}

export const adminSupportsSlice = createSlice({
    name: 'adminSupports',
    initialState,
    reducers: {
        changeFilter: (state, action: PayloadAction<IAdminSupportsFilter>) => {
            state.filter = {...state.filter, ...action.payload}
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchReports.pending, (state, ) => {
            state.isLoading = true
        })
        builder.addCase(fetchReports.rejected, (state, ) => {
            state.isLoading = false
        })
        builder.addCase(fetchReports.fulfilled, (state, action: PayloadAction<IReport[]>) => {
            state.reports = [...action.payload]
            state.isLoading = false
        })
    }
})

export const {changeFilter} = adminSupportsSlice.actions
export default adminSupportsSlice.reducer
