import React, {ReactNode, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import Text from "../Text";
import {ETextVariants} from "../Text/types";
import {
  BlockValue,
  DropdownBlock,
  DropdownInput,
  DropdownItem,
  DropdownMenu,
  DropdownWrapper,
  IconButton,
  Wrapper
} from './styled';
import {CloseIcon} from "../Svg";
import {useTranslate} from "@tolgee/react";

interface DropdownProps {
  placeholder?: string,
  value: string | number,
  label: string,
  options: Array<{ item: string | ReactNode, value: string | number }>,
  onSelect: (val: string | number) => void,
  isSearch?: boolean,
  onSearch?: (val: string) => void
  customSearch?: string
  disabled?: boolean
}

function DropdownCustom({options, label, value, placeholder, onSelect, isSearch, onSearch, customSearch, disabled, ...props}: DropdownProps) {
  const dropdown = useRef<HTMLDivElement>(null)
  const [isShowMenu, setIsShowMenu] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const {t} = useTranslate()

  const handleClickOutside = useCallback((e: any) => {
    const path = e.path || (e.composedPath && e.composedPath());
    if (!path.includes(dropdown.current)) {
      setIsShowMenu(false)
    }
  }, [])

  const selectedItem = useMemo(() => options.filter((opt) => opt.value === value)[0] || null, [options, value])
  const filterOptions = useMemo(() => options.filter((opt) => String(opt.item).toLowerCase().includes(search.toLowerCase())), [options, search])

  useEffect(() => {
    const rootEl = document.getElementById('root')
    if (rootEl && window) {
      window.addEventListener('click', handleClickOutside, false);
      return () => {
        window.removeEventListener('click', handleClickOutside, false);
      }
    }
  }, [handleClickOutside])

  return (
    <Wrapper ref={dropdown} {...props}>
      <Text variant={ETextVariants.Body16}>
        {label}
      </Text>
      <DropdownWrapper>
        <BlockValue onClick={() => {
          if (!disabled) setIsShowMenu(prev => !prev)
        }}>
          <Text variant={ETextVariants.Body16}>
            {selectedItem?.item || placeholder}
          </Text>
        </BlockValue>

        {isShowMenu && <DropdownBlock>
          {isSearch && <>
              <DropdownInput placeholder={t('common.search','Поиск')} type='text' value={customSearch || search}
                             onChange={event => {
                               if (!disabled) {
                                 if (onSearch) {
                                   onSearch(event.target.value)
                                 } else {
                                   setSearch(event.target.value)
                                 }
                               }
                             }}
              />
              <IconButton onClick={() => setIsShowMenu(false)}><CloseIcon/></IconButton>
          </>}
          {(filterOptions.length > 0 || options.length > 0) && <DropdownMenu>
            {
              filterOptions.length > 0
                ? filterOptions.map((item) => <DropdownItem key={item.value} $isSelected={item.value === value}
                                                            onClick={() => onSelect(item.value)}>{item.item}</DropdownItem>)
                : options.map((item) => <DropdownItem key={item.value} $isSelected={item.value === value}
                                                      onClick={() => onSelect(item.value)}>{item.item}</DropdownItem>)
            }
          </DropdownMenu>}
        </DropdownBlock>}
      </DropdownWrapper>
    </Wrapper>
  );
}

export default DropdownCustom;
