import React, {ReactNode} from 'react';
import styled from "styled-components";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {ArrowLeftIcon} from "../../UI/Svg";
import {useNavigate} from "react-router-dom";

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Title = styled(Text)`
  font-size: 35px;
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: flex-start;
  color: ${({theme}) => theme.colors.mainPurple};
`

const Left = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
`

const ButtonIcon = styled.div`
  cursor: pointer;
  svg {
    min-width: 24px;
    height: 24px;
  }
`

function AdminHeader({title, rightContent, isBack}: { title: string | ReactNode, rightContent?: ReactNode, isBack?: boolean }) {
  const navigate = useNavigate()
  return (
    <Header>
      <Left>
        {isBack && <ButtonIcon onClick={() => navigate(-1)}>
          <ArrowLeftIcon/>
        </ButtonIcon>}
        <Title variant={ETextVariants.Heading} as='h2'>{title}</Title>
      </Left>
      {rightContent}
    </Header>
  );
}

export default AdminHeader;
