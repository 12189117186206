import React from 'react';

import 'swiper/css';
import 'swiper/css/pagination';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Pagination} from 'swiper/modules';
import {AuthSlide} from "./index";
import authSlide1 from "../../assets/img/auth-slide1.png";
import {T} from "@tolgee/react";
import authSlide2 from "../../assets/img/auth-slide2.png";
import authSlide3 from "../../assets/img/auth-slide3.png";
import styled from "styled-components";
import 'swiper/css';
import 'swiper/css/pagination';

const swiperModules = [Autoplay, Pagination];
const swiperAutoplay = {delay: 10000};
const swiperPagination = {clickable: true};

const SlideImageOneStyled = styled.div`
  width: 307px;
  height: 288px;
  margin-bottom: 1.3rem;

  img {
    width: 100%;
    height: 100%;
  }

  ${({theme}) => theme.mediaQueries.md} {
    width: 190px;
    height: 190px;
  }
`


const SlideImageTwoStyled = styled.div`
  width: 285px;
  height: 303px;
  margin-bottom: 1.3rem;

  img {
    width: 100%;
    height: 100%;
  }

  ${({theme}) => theme.mediaQueries.md} {
    width: 200px;
    height: 200px;
  }
`


const SlideImageThreeStyled = styled.div`
  width: 273px;
  height: 273px;
  margin-bottom: 1.3rem;

  img {
    width: 100%;
    height: 100%;
  }

  ${({theme}) => theme.mediaQueries.md} {
    width: 200px;
    height: 200px;
  }
`


function AuthCarousel() {
  return (
    <Swiper
      modules={swiperModules}
      slidesPerView={1}
      autoplay={swiperAutoplay}
      pagination={swiperPagination}
      style={{
        width: '100%',
        '@media (maxWidth: 734px)': {
          top: '5%',
          height: '45rem',
          paddingBottom: '16px'
        },
      }}
    >
      <SwiperSlide>
        <AuthSlide img={<SlideImageOneStyled>
          <img src={authSlide1} alt=""/>
        </SlideImageOneStyled>}
                   title={<T keyName="authSlider.slideOne.title">Создавайте ивенты</T>}
                   description={
                     <T keyName="authSlider.slideOne.text">
                       Опишите мероприятие, место и время проведения. Новые люди уже спешат скорее
                       познакомиться!
                     </T>
                   }/>
      </SwiperSlide>
      <SwiperSlide>
        <AuthSlide img={<SlideImageTwoStyled>
          <img src={authSlide2} alt=""/>
        </SlideImageTwoStyled>}
                   title={<T keyName="authSlider.slideTwo.title">Заводите знакомства</T>}
                   description={
                     <T keyName="authSlider.slideTwo.text">
                       Выбирайте пользователя, приглашайте его на ивент или создайте свой только для вас.
                     </T>
                   }/>
      </SwiperSlide>
      <SwiperSlide>
        <AuthSlide img={<SlideImageThreeStyled>
          <img src={authSlide3} alt=""/>
        </SlideImageThreeStyled>}
                   title={<T keyName="authSlider.slideThree.title">Развивайте свои хобби</T>}
                   description={
                     <T keyName="authSlider.slideThree.text">
                       Множество направлений деятельности: от игры в футбол до обсуждения книг.
                     </T>
                   }/>
      </SwiperSlide>
    </Swiper>
  );
}

export default AuthCarousel;
