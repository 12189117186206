import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled from "styled-components";
import Input from "../../UI/Input";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {AddDNDIcon, ArrowLeftIcon, CloseIcon, PhoneIcon, PhotoIcon, SearchIcon, TrashIcon} from "../../UI/Svg";
import {useProfileState} from "../../store/profile/hooks";
import {useChatAction} from "../../store/chat/hooks";
import ChatMember from "../ProfileComponents/ChatMember";
import _ from 'lodash';
import pngChecked from "../../assets/img/checked.svg";
import {Button, EButtonVariants} from "../../UI/Button";
import {FileUploader} from "react-drag-drop-files";
import {T, useTranslate} from "@tolgee/react";

export interface ModalCreateChatProps {
  onClose: () => void;
}

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  padding-top: 60px;
  z-index: 100000;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    content: '';
    position: absolute;
    background: ${({theme}) => theme.colors.transparentGray};
    width: 100%;
    height: 100%;
    padding-bottom: 100%;
    top: 0;
  }

  ${({theme}) => theme.mediaQueries.md} {
    position: absolute;
    border-radius: 0;
    width: 100%;
    height: 100%;
    padding-top: 0;
    margin-bottom: 71px;
  }
`

const Modal = styled.div`
  min-height: 300px;
  max-width: 1191px;
  height: 84vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  top: 0;
  right: 0;
  background: ${({theme}) => theme.colors.bg};
  z-index: 2;
  border-radius: 10px;

  &::-webkit-scrollbar {
    width: 0;
  }

  ${({theme}) => theme.mediaQueries.xxl} {
    width: 968px;
  }

  ${({theme}) => theme.mediaQueries.lg} {
    width: 770px;
  }

  ${({theme}) => theme.mediaQueries.md} {
    background: ${({theme}) => theme.colors.white};
    width: 100%;
    top: 0;
    padding: 16px;
    height: 100%;
    bottom: 0;
    right: 0;
    left: 0;
    position: fixed;
    overflow: hidden;
  }
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid ${({theme}) => theme.colors.darkWhite};
  position: relative;
  gap: 20px;
  background: ${({theme}) => theme.colors.white};
  padding: 18px 50px 18px 70px;

  & > ${Text} {
    white-space: nowrap;
  }

  ${({theme}) => theme.mediaQueries.md} {
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: none;
    padding: 0 0 16px;
    gap: 16px;

    & > ${Text} {
      width: 100%;
      text-align: center;
    }
  }
`

const InputBlock = styled.div`
  display: flex;

`
const InputStyled = styled(Input)`
  height: 40px;

`

const CloseButton = styled.div`
  width: 20px;
  height: 20px;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    width: 12px;

    &:first-child {

      ${({theme}) => theme.mediaQueries.md} {
        display: none;
      }
    }

    &:nth-child(2) {

      display: none;

      ${({theme}) => theme.mediaQueries.md} {
        display: block;
      }
    }
  }

  ${({theme}) => theme.mediaQueries.md} {
    position: absolute;
    transform: none;
    top: 0;
    left: 0;

    svg {
      min-width: 24px;
    }
  }
`

const WrapUsers = styled.div`
  //display: grid;
  //grid-template-columns: repeat(5, 1fr);
  //grid-template-rows: 0fr;
  width: 100%;
  //grid-column-gap: 14px;
  padding: 16px 35px;
  //grid-row-gap: 14px;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;

    // ${({theme}) => theme.mediaQueries.xxl} {
  //   grid-template-columns: repeat(4, 1fr);
  // }
  //
    // ${({theme}) => theme.mediaQueries.lg} {
  //   grid-template-columns: repeat(3, 1fr);
  // }

  ${({theme}) => theme.mediaQueries.md} {
    border-top: 1px solid ${({theme}) => theme.colors.bg};
    background: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 0;
    gap: 16px;
    flex: 1;
    overflow: auto;
  }
`


const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
  overflow: hidden;
  align-items: flex-start;
`

const ChatMemberWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  max-width: calc(25%);
  justify-content: flex-end;
  flex-direction: row-reverse;

  ${({theme}) => theme.mediaQueries.md} {
    justify-content: space-between;
    flex-direction: row;
    padding-right: 4px;
    max-width: unset;
  }
`
export const CheckboxItem = styled.div`
  width: 24px;
  height: 24px;
  min-width: 24px;
  border-radius: 50%;
  user-select: none;
  margin-top: 10px;
  border: 1px solid ${({theme}) => theme.colors.lightGray};
  cursor: pointer;
`

const ButtonStyled = styled(Button)`
  width: 100%;
  max-width: 568px;
  margin: 0 auto;
`

const CreateForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`

const Desc = styled(Text)`
`

const RowForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
`


export const DeleteHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  position: absolute;
  opacity: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: ${({theme}) => theme.colors.bg};
  cursor: pointer;
`

export const UploadStyled = styled.div`
  width: 100%;
  display: flex;
  height: 56px;
  width: 56px;
  min-width: 56px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  gap: 10px;
  background: ${({theme}) => theme.colors.bg};

  svg {
    width: 24px;
    height: 24px;
  }

  &:hover {
    ${DeleteHeader} {
      opacity: 1;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
`

const ImagePreview = styled.img`
  height: 100%;
  width: auto;
`

const TitleInputStyled = styled.input`
  width: 100%;
  border: none;
  outline: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.24px;
  color: ${({theme}) => theme.colors.black};

  &::placeholder {
    color: ${({theme}) => theme.colors.lightGray5};
  }
`

const fileTypes = ['JPEG', 'PNG', 'JPG'];

function ModalCreateChat({onClose}: ModalCreateChatProps) {
  const {t} = useTranslate()
  const {subscribers} = useProfileState()
  const [search, setSearch] = useState<string>('');
  const [membersIds, setMembersIds] = useState<number[]>([]);
  const [step, setStep] = useState<number>(1);
  const [loadFile, setLoadFile] = useState<File | null>(null);
  const [title, setTitle] = useState<string>('');

  const filteredUsers = useMemo(() => {
    return subscribers?.filter((item) => {
      return `${item.firstName} ${item.lastName} ${item.login}`.toLowerCase().includes(search.toLowerCase())
    });
  }, [search, subscribers]);
  const {onFetchCreateGroupChat} = useChatAction()

  const onCreateChat = useCallback(() => {
    onFetchCreateGroupChat({
      members: membersIds,
      title,
      loadFile
    })
    onClose()
  }, [loadFile, membersIds, onClose, onFetchCreateGroupChat, title])

  const body = document.querySelector('body');

  useEffect(() => {
    if (body) body.style.overflow = 'hidden';

    return () => {
      if (body) body.style.overflow = 'auto';
    };
  }, [body]);

  const members = useMemo(() => {
    return subscribers.filter((user) => _.includes(membersIds, user.id))
  }, [membersIds, subscribers])

  const onCheckMember = (memberId: number) => setMembersIds(prevState => {
    if (_.includes(prevState, memberId)) {
      return prevState.filter((id) => id !== memberId)
    } else {
      return [...prevState, memberId]
    }
  })

  const users = useMemo(() => {
    if (step === 1) {
      if (subscribers) {
        if (filteredUsers?.length > 0) return filteredUsers
        return subscribers
      }
      return []
    } else {
      return members
    }
  }, [filteredUsers, members, step, subscribers])

  return (
    <Wrapper>
      <Modal>
        <Header>
          <Text variant={ETextVariants.Heading}>
            <T keyName="modalCreateChat.title">Создание чата</T>
          </Text>
          {step === 1 && <InputBlock>
              <InputStyled
                  placeholder={t('common.search', 'Поиск')}
                  wth="34.3rem"
                  onChange={(e) => setSearch(e.target.value)}
                  icon={<SearchIcon/>}
              />
          </InputBlock>}
          {step === 2 && <CreateForm>
              <RowForm>
                  <FileUploader
                      handleChange={(file: File) => setLoadFile(file)}
                      name="file"
                      types={fileTypes}
                    // onTypeError={(err: string) => onChangeFormError(err)}
                      maxSize="10"
                    // onSizeError={(file: string) => setLoadFile(file)}
                  >
                      <UploadStyled>
                        {loadFile ?
                          <>
                            <ImagePreview src={URL.createObjectURL(loadFile)}/>

                            <DeleteHeader onClick={() => setLoadFile(null)}>
                              <TrashIcon/>
                            </DeleteHeader>
                          </>
                          : <PhotoIcon/>}
                      </UploadStyled>
                  </FileUploader>
                  <TitleInputStyled value={title} type={'text'} onChange={event => setTitle(event.target.value)}
                                    placeholder={t('modalCreateChat.input.title','Название чата')}/>
              </RowForm>
              <Desc variant={ETextVariants.MiniGray}><T keyName="modalCreateChat.description">Введите название и при желании загрузите фотографию</T></Desc>
          </CreateForm>}
          <CloseButton onClick={() => {
            if (step === 1) {
              onClose()
            } else {
              setStep(1)
            }
          }}>
            <CloseIcon/>
            <ArrowLeftIcon/>
          </CloseButton>
        </Header>
        <Content>
          <WrapUsers>
            {
              users.map((item) => {
                const isChecked = _.includes(membersIds, item.id)
                return (<ChatMemberWrapper key={item.id} onClick={() => onCheckMember(item.id)}>
                    <ChatMember profile={item}/>
                    <CheckboxItem>
                      {isChecked && <img src={pngChecked} alt="Checked"/>}
                    </CheckboxItem>
                  </ChatMemberWrapper>
                );
              })
            }
          </WrapUsers>
          <ButtonStyled variant={EButtonVariants.Primary}
                        disabled={membersIds.length === 0 || (step === 2 && title === '')}
                        onClick={() => {
                          if (step === 1) {
                            setStep(2)
                          } else {
                            onCreateChat()
                            onClose()
                            setStep(1)
                          }
                        }}>
            <T keyName="modalCreateChat.buttons.next">Продолжить</T>
          </ButtonStyled>
        </Content>
      </Modal>
    </Wrapper>
  );
}

export default ModalCreateChat;
