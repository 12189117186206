import React, {Fragment, ReactNode} from 'react';
import styled from "styled-components";
import {ETextVariants} from "../../UI/Text/types";
import {T} from "@tolgee/react";
import Text from "../../UI/Text";
import {IPolitic} from "../../config/politics";

const Block = styled.div`
  width: 100%;
  flex-direction: column;
  display: flex;
  gap: 10px;
`

const Title = styled(Text)`
  font-size: 18px;
`

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 5px;
  list-style-position: inside;
  li > * {
    vertical-align: middle;
  }
  li {
    &::marker {
      margin-top: 10px;
      font-size: 16px;
    }
  }
`

const TextStyled = styled(Text)`
  width: 100%;
  text-align: justify;
  display: inline-block;
  vertical-align: baseline;
`

function BlockTermsComponent({title, items, dopContent, dopParams}: { title?: string, items: string[], dopContent?: ReactNode, dopParams?: {[key:string]: any | undefined} }) {
  return (
    <Block>
      {title && <Title variant={ETextVariants.Heading} as={'h4'}><T keyName={`terms.items.${title}`}/></Title>}
      <List>
        {
          items.map((item, id) => {
            return <TextStyled key={id} variant={ETextVariants.Body16} as={'li'}>{item}. <T
                keyName={`terms.items.${item}`} params={dopParams?.[item]}/></TextStyled>
          })
        }
        {dopContent}
      </List>
    </Block>
  );
}

export default BlockTermsComponent;
