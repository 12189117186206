import {createSlice, current, PayloadAction} from "@reduxjs/toolkit";
import {IChat, IChatDetail, IChatMessage} from "./types";
import fetchUserChats from "./fetchUserChats";
import {io, Socket} from "socket.io-client";
import _ from "lodash";
import {UserEntity} from "../../types";
import fetchChat from "./fetchChat";

// const socket = io('<ws://45.12.72.86:4100>', {
//     autoConnect: false,
// });

export type ChatState = {
  countUnread: number,
  isLoadingMessages: boolean,
  isLoading: boolean,
  chats: IChat[],
  checkedMessages: string[],
  isCompany: boolean
  currentChat: IChatDetail | null,
  currentChatMessages: IChatMessage[],
  unreadMessagesIds: string[],
  currentUsersTyping: { [chatId: string]: UserEntity[] },
  usersOnline: UserEntity[],
  isEstablishingConnection: boolean;
  isConnected: boolean;
  selectedMessage: null | string
}

const initialState: ChatState = {
  selectedMessage: null,
  countUnread: 0,
  isLoading: false,
  isCompany: false,
  isLoadingMessages: false,
  chats: [],
  usersOnline: [],
  currentChat: null,
  unreadMessagesIds: [],
  checkedMessages: [],
  currentChatMessages: [],
  isEstablishingConnection: false,
  isConnected: false,
  currentUsersTyping: {}
}


export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    disconnectSocket: (state) => {
      state.isConnected = false;
      state.isEstablishingConnection = false;
    },
    startConnecting: (state) => {
      state.isEstablishingConnection = true;
    },
    toggleIsCompany: (state, action: PayloadAction<boolean>) => {
      state.isCompany = action.payload;
    },
    connectionEstablished: (state) => {
      state.isConnected = true;
      state.isEstablishingConnection = true;
    },
    clearCurrentChatMessage: (state) => {
      state.currentChatMessages = []
      state.currentChat = null
    },
    joinRoom: (state, action: PayloadAction<string>) => {
      return;
    },
    readMessage: (state, action: PayloadAction<{ messageId: string }>) => {
      return;
    },
    leaveRoom: (state, action: PayloadAction<string>) => {
      return;
    },
    submitMessage: (state, action: PayloadAction<{ chatId: string, message?: string, files?: string[], forwardMessageId?: string }>) => {
      return;
    },
    startTyping: (state, action: PayloadAction<{ chatId: string }>) => {
      return;
    },
    stopTyping: (state, action: PayloadAction<{ chatId: string }>) => {
      return;
    },
    deleteChat: (state, action: PayloadAction<{ chatId: string }>) => {
      return;
    },
    deleteMessage: (state, action: PayloadAction<{ messageId: string }>) => {
      return;
    },
    reactEventInvitation: (state, action: PayloadAction<{ messageId: string, accept: boolean }>) => {
      return;
    },
    setUserOnline: (state, action: PayloadAction<UserEntity[]>) => {
      state.usersOnline = action.payload
    },
    setUserTyping: (state, action: PayloadAction<{ user: UserEntity, chatId: string }>) => {
      const {user, chatId} = action.payload
      if (chatId in state.currentUsersTyping) {
        const ids = state.currentUsersTyping[chatId].map(({id}) => id)
        if (!_.includes(ids, user.id)) {
          state.currentUsersTyping[chatId] = [...state.currentUsersTyping[chatId], user]
        }
      } else {
        state.currentUsersTyping[chatId] = [user]
      }
    },
    removeUserTyping: (state, action: PayloadAction<{ user: UserEntity, chatId: string }>) => {
      const {user, chatId} = action.payload
      const typing = {...current(state.currentUsersTyping)}
      if (chatId in typing) {
        const newData = typing[chatId].filter(({id}) => id !== user.id)
        if (newData.length > 0) {
          typing[chatId] = newData
        } else {
          delete typing[chatId]
        }
      }
      state.currentUsersTyping = typing
    },
    setChatList: (state, action: PayloadAction<IChat[]>) => {
      state.chats = action.payload
      state.countUnread = state.chats.reduce((acc, chat) => acc + chat.unreadMessagesNumber, 0)
    },
    updateUnreadChats: (state, action: PayloadAction<IChat[]>) => {
      if (action.payload.length > 0) {
        state.chats = state.chats.map((chat) => {
          const findChat = action.payload.find(({id}) => id === chat.id)
          if (findChat) {
            return findChat
          }
          return chat
        })
      } else {
        state.chats = state.chats.map((chat) => {
          return {
            ...chat,
            unreadMessagesNumber: 0
          }
        })
      }
      state.unreadMessagesIds = action.payload.map((chat) => chat.lastMessage.id)
      state.countUnread = state.chats.reduce((acc, chat) => acc + chat.unreadMessagesNumber, 0)
    },
    setUpdateChat: (state, action: PayloadAction<IChatDetail>) => {
      if (state.currentChat?.id === action.payload.id) {
        state.currentChat = action.payload
      }
      state.countUnread = state.chats.reduce((acc, chat) => acc + chat.unreadMessagesNumber, 0)
    },
    setMessage: (state, action: PayloadAction<IChatMessage>) => {
      if (state.currentChat?.id === action.payload.chat.id) {
        state.currentChat.messages = [action.payload, ...state.currentChat.messages]
      }
      const needIndex = _.findIndex(state.chats, (chat) => chat.id === action.payload.chat.id)
      const chats = [...state.chats]
      chats[needIndex].lastMessage = action.payload
      state.chats = chats
    },
    checkMessage: (state, action: PayloadAction<string>) => {
      const id = action.payload
      const {checkedMessages} = current(state)
      if (_.includes(checkedMessages, id)) {
        state.checkedMessages = checkedMessages.filter(item => item !== id)
      } else {
        state.checkedMessages = [...checkedMessages, id]
      }
    },
    clearCheckedMessages: (state) => {
      state.checkedMessages = []
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchUserChats.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchUserChats.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(fetchUserChats.fulfilled, (state, action: PayloadAction<IChat[]>) => {
      state.chats = action.payload
      state.isLoading = false
      state.countUnread = state.chats.reduce((acc, chat) => acc + chat.unreadMessagesNumber, 0)
    })
    builder.addCase(fetchChat.pending, (state) => {
      state.isLoadingMessages = true
    })
    builder.addCase(fetchChat.rejected, (state) => {
      state.isLoadingMessages = false
    })
    builder.addCase(fetchChat.fulfilled, (state, action: PayloadAction<IChatDetail>) => {
      state.currentChat = action.payload
      state.isLoadingMessages = false
      state.chats = state.chats.map((chat) => {
        if (chat.id === action.payload.id) {
          return {
            ...chat,
            unreadMessagesNumber: 0,
          }
        }
        return chat
      })
      state.countUnread = state.chats.reduce((acc, chat) => acc + chat.unreadMessagesNumber, 0)
    })
  }
})

export const {
  clearCheckedMessages,
  checkMessage,
  clearCurrentChatMessage,
  startConnecting,
  disconnectSocket,
  connectionEstablished,
  submitMessage,
  joinRoom,
  leaveRoom,
  setMessage,
  readMessage,
  setUpdateChat,
  updateUnreadChats,
  setChatList, startTyping, stopTyping,
  setUserTyping,
  removeUserTyping,
  reactEventInvitation,
  deleteChat,
  setUserOnline,
  deleteMessage,
  toggleIsCompany
} = chatSlice.actions
export default chatSlice.reducer
