import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
  <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} >
    <path d="M9.16329 1.75564L14.182 5.01778C14.3593 5.13305 14.448 5.19068 14.5122 5.26756C14.5691 5.33561 14.6118 5.41433 14.6379 5.4991C14.6673 5.59486 14.6673 5.70061 14.6673 5.91212V10.7999C14.6673 11.92 14.6673 12.4801 14.4493 12.9079C14.2576 13.2842 13.9516 13.5902 13.5753 13.7819C13.1475 13.9999 12.5874 13.9999 11.4673 13.9999H4.53398C3.41388 13.9999 2.85383 13.9999 2.426 13.7819C2.04968 13.5902 1.74372 13.2842 1.55197 12.9079C1.33398 12.4801 1.33398 11.92 1.33398 10.7999V5.91212C1.33398 5.70061 1.33398 5.59486 1.36343 5.4991C1.3895 5.41433 1.43222 5.33561 1.48908 5.26756C1.55332 5.19068 1.64199 5.13305 1.81933 5.01778L6.83801 1.75564M9.16329 1.75564C8.74246 1.4821 8.53205 1.34533 8.30534 1.29211C8.10494 1.24508 7.89637 1.24508 7.69597 1.29211C7.46925 1.34533 7.25884 1.4821 6.83801 1.75564M9.16329 1.75564L13.2914 4.43891C13.75 4.73698 13.9793 4.88602 14.0587 5.07501C14.1281 5.24018 14.1281 5.42633 14.0587 5.5915C13.9793 5.78049 13.75 5.92953 13.2914 6.2276L9.16329 8.91087C8.74247 9.18441 8.53205 9.32118 8.30534 9.37439C8.10494 9.42143 7.89637 9.42143 7.69597 9.37439C7.46925 9.32118 7.25884 9.18441 6.83801 8.91087L2.70989 6.22759C2.25133 5.92953 2.02204 5.78049 1.94263 5.5915C1.87324 5.42633 1.87324 5.24018 1.94263 5.07501C2.02204 4.88602 2.25133 4.73698 2.70989 4.43891L6.83801 1.75564M14.334 12.6666L9.90544 8.66659M6.09587 8.66659L1.66732 12.6666" stroke="#9B9B9B" strokeLinecap="round" strokeLinejoin="round"/>
  </Svg>
);

export default Icon;
