import React, {useState} from 'react';
import styled from "styled-components";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {Button, EButtonVariants} from "../../UI/Button";
import Input from "../../UI/Input";
import {useEventsAction} from "../../store/events/hooks";
import {useAdminForbiddenWordsAction} from "../../store/adminForbiddenWords/hooks";
import {T, useTranslate} from "@tolgee/react";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column; 
  margin-top: 40px;
  gap: 20px;
`

const FormBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
`

const InputStyled = styled(Input)`
  background: ${({theme}) => theme.colors.white};
  border: 1px solid ${({theme}) => theme.colors.mainPurple};
  min-width: 343px;
`

const ButtonStyled = styled(Button)`
  min-width: 226px;
`
function FormView() {
  const {t} = useTranslate()
  const [word, setWord] = useState<string>('');
  const {onCreateWord} = useAdminForbiddenWordsAction()
  return (
    <Wrapper>
      <Text variant={ETextVariants.Body16}><T keyName="admin.stopWords.inputWord">Введите слово</T></Text>
      <FormBlock>
        <InputStyled
          value={word}
          placeholder={t('inputs.text','Текст')}
          onChange={(event) => setWord(event.target.value)}
        />
        <ButtonStyled variant={EButtonVariants.Primary} onClick={() => {
          onCreateWord(word)
          setWord('')
        }}><T keyName="common.add">Добавить</T></ButtonStyled>
      </FormBlock>
    </Wrapper>
  );
}

export default FormView;
