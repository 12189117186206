import {useDispatch, useSelector} from "react-redux";
import {useCallback, useEffect, useMemo} from "react";
import {AppDispatch, RootState} from "../index";
import {IAdminForbiddenWordsFilter} from "./types";
import fetchWords from "./fetchWords";
import { changeFilter } from "./adminForbiddenWordsSlice";
import fetchPostWords from "./fetchPostWords";
import fetchDeleteWord from "./fetchDeleteWord";

export const getAdminForbiddenWords = (state: RootState) => state.adminForbiddenWords

export function useAdminForbiddenWordsAction() {
  const dispatch = useDispatch<AppDispatch>()
  const onChangeFilter = useCallback((data: IAdminForbiddenWordsFilter) => {
    dispatch(changeFilter(data))
  }, [dispatch])
  const onFetchWords = useCallback(() => {
    dispatch(fetchWords())
  }, [dispatch])
  const onCreateWord = useCallback((word: string) => {
    dispatch(fetchPostWords(word))
      .unwrap().then(() => onFetchWords())
  }, [dispatch, onFetchWords])
  const onDeleteWord = useCallback((id: number) => {
    dispatch(fetchDeleteWord(id))
      .unwrap().then(() => onFetchWords())
  }, [dispatch, onFetchWords])
  return useMemo(() => ({
    onChangeFilter,
    onFetchWords,
    onCreateWord,
    onDeleteWord,
  }), [
    onChangeFilter,
    onFetchWords,
    onCreateWord,
    onDeleteWord,
  ])
}

export function useAdminForbiddenWordsState() {
  return useSelector(getAdminForbiddenWords)
}

export function useAdminForbiddenWords() {
  const {filter} = useAdminForbiddenWordsState()
  const {onFetchWords} = useAdminForbiddenWordsAction()

  useEffect(() => {
    onFetchWords()
  }, [filter, onFetchWords])

}
