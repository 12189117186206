import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {T, useTranslate} from "@tolgee/react";
import {FileUploader} from "react-drag-drop-files";
import {AddDNDIcon, TrashIcon} from "../../UI/Svg";
import {DataPicker} from "../../UI/DataPicker";
import {Button, EButtonVariants} from "../../UI/Button";
import {useForm} from "react-hook-form";
import {handle, mediaUrl} from "../../api";
import CreateEventModal from "../../components/Modals/CreateEventModal";
import {
  FormStyled,
  InputStyled,
  InpuRow,
  UploadStyled,
  UploadText,
  ErrorForm,
  TextAreaStyled,
  TAMini,
  FileTitle,
  HomeMapStyled,
  ImagePreview,
  DeleteHeader
} from './styled'
import {ServicesApi} from "../../api/services";
import {useCompanyState} from "../../store/company/hooks";
import {IPostCreateService, ServiceResponse} from "../../store/adminServices/types";
import Dropdown from "../../UI/Dropdown";
import {currencyList} from "../../config/currency";
import Input from "../../UI/Input";
import {Checkbox} from "../../UI/Input/Checkbox";
import {handleImageUpload} from "../../utils";


const fileTypes = ['JPEG', 'PNG', 'JPG'];

interface initialState {
  position: [number, number],
  loadFile: null | File,
  textArea: string,
  startDate: null | Date,
  addressMap: any,
}

export const initialStateForm: initialState = {
  position: [0, 0],
  loadFile: null,
  textArea: '',
  startDate: null,
  addressMap: {country: '', city: '', detail: ''},
};


function FormServiceView({
                           isCreate = true,
                           initialData,
                           callback
                         }: { isCreate?: boolean, initialData?: ServiceResponse, callback?: () => void }) {
  const {t} = useTranslate();
  const textAreaRef = useRef<HTMLDivElement | null>(null);
  const refDnD = useRef<HTMLDivElement | null>(null);
  const addressRef = useRef<HTMLInputElement>(null);
  const {selectedUserCompany} = useCompanyState()
  const [currency, setCurrency] = useState<string>(initialData?.currency || '');
  const [isUnlimited, setIsUnlimited] = useState<boolean>(false);
  const [isNoDate, setIsNoDate] = useState<boolean>(false);
  const [isPending, setIsPending] = useState<boolean>(false);

  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [bodyModal, setBodyModal] = useState<{ title: string, body: string }>(
    {title: '', body: ''}
  );
  const {
    register,
    handleSubmit,
    reset,
    formState: {errors},
    setValue
  } = useForm({
    defaultValues: {
      price: initialData?.price || 0,
      maxCustomers: initialData?.maxCustomers ? String(initialData?.maxCustomers) : '0',
      title: initialData?.title || '',
      allowCashPayment: false,
    }
  });


  const initialMemo: initialState = useMemo(() => {
    if (initialData) {
      return {
        position: initialData.address ? [parseFloat(initialData.address?.latitude), parseFloat(initialData.address?.latitude)] : [0, 0],
        loadFile: null,
        textArea: initialData.description,
        startDate: initialData.expirationDate ? new Date(initialData.expirationDate) : null,
        addressMap: initialData.address ? {
          country: initialData.address.country,
          city: initialData.address.city,
          detail: initialData.address.fullString
        } : {country: '', city: '', detail: ''},
      }
    }
    return initialStateForm
  }, [initialData])


  const [formState, setFormState] = useState<{ data: initialState, error: string }>({
    data: initialMemo,
    error: ''
  });

  useEffect(() => {
    if (initialData) {
      setValue('price', initialData?.price || 0,)
      setValue('maxCustomers', initialData?.maxCustomers ? String(initialData?.maxCustomers) : '0')
      setValue('title', initialData?.title || '')
      setValue('allowCashPayment', initialData?.allowCashPayment || false)
      setFormState({
        data: {
          position: initialData.address ? [parseFloat(initialData.address?.latitude), parseFloat(initialData.address?.latitude)] : [0, 0],
          loadFile: null,
          textArea: initialData.description,
          startDate: initialData.expirationDate ? new Date(initialData.expirationDate) : null,
          addressMap: initialData.address ? {
            country: initialData.address.country,
            city: initialData.address.city,
            detail: initialData.address.fullString
          } : {country: '', city: '', detail: ''},
        },
        error: ''
      })
      setCurrency(initialData.currency)
      setIsNoDate(initialData.expirationDate === null)
      setIsUnlimited(initialData.maxCustomers === 0)
    }
  }, [initialData, setValue])

  useEffect(() => {
    if (isUnlimited) setValue('maxCustomers', '0')
  }, [setValue, isUnlimited])

  const onResetFormState = () => setFormState({
    data: initialStateForm,
    error: ''
  })

  const onChangeFormState = (key: string, val: any) => {
    setFormState(prevState => ({
      ...prevState,
      data: {
        ...prevState.data,
        [key]: val
      },
      error: ''
    }))
  }
  const onChangeFormError = (error: string) => {
    setFormState(prevState => ({
      ...prevState,
      error: error
    }))
  }

  const handleChange = (file: File): void => {
    onChangeFormState('loadFile', file)
  };

  useEffect(() => {
    const addressValue = addressRef.current;
    if (addressValue) addressValue.value = formState.data.addressMap.detail;
  }, [formState.data.addressMap.detail]);

  const onSubmit = useCallback(async (data: any): Promise<any> => {
    setIsPending(true)
    const errorHandler = (message: string): void => onChangeFormError(message);
    if (selectedUserCompany === null) {
      errorHandler(t('serviceForm.errors.company', 'Необходмо выбрать компанию в настройке профиля!'));
      setIsPending(false)
      return
    }
    onChangeFormError('');
    const maxCustomers = data.maxCustomers;


    const {loadFile, position, textArea, startDate, addressMap} = formState.data

    const compressedFile = await handleImageUpload(loadFile)
    if (!textArea.length) {
      errorHandler(t('serviceForm.errors.description', 'Введите описание услуги!'));
      textAreaRef.current?.scrollIntoView({behavior: 'smooth', block: 'start'});
      setIsPending(false)
      return;
    }
    if (maxCustomers > 999) {
      errorHandler(t('serviceForm.errors.max', 'Количество участвующих не должно быть больше 999!'));
      setIsPending(false)
      return;
    }
    if (maxCustomers < 1 && !isUnlimited) {
      errorHandler(t('serviceForm.errors.min', 'Количество участвующих не должно быть меньше 1!'));
      setIsPending(false)
      return;
    }
    if (!currency) {
      errorHandler(t('serviceForm.errors.currency', 'Выбериту валюту!'));
      setIsPending(false)
      return;
    }
    if (!addressRef.current?.value) {
      errorHandler(t('serviceForm.errors.location', 'Выберите место на карте!'));
      setIsPending(false)
      return;
    }

    const obj: IPostCreateService = {
      ...data,
      description: textArea,
      maxCustomers: Number(maxCustomers),
      expirationDate: startDate ? new Date(startDate) : null,
      latitude: position[0],
      longitude: position[1],
      currency,
    };

    if (isNoDate) {
      delete obj.expirationDate
    }

    const [serviceRes, serviceErr] = await handle((!isCreate && initialData)
      ? ServicesApi.putUpdateService(initialData.id, obj)
      : ServicesApi.postCreateService(selectedUserCompany.id, obj)
    )

    if (serviceErr) {
      setBodyModal({
        title: t('common.error', 'Ошибка'),
        body: (isCreate ? t('serviceForm.error.create', `Ошибка при создании,проверьте заполнение всех полей`)
          : t('serviceForm.error.update', 'Ошибка при редактировани,проверьте заполнение всех полей')) + ` ${serviceErr.message}`
      });
      setIsShowModal(true);
      setIsPending(false)
      console.log(serviceErr.message)
      return
    }

    if (serviceRes && compressedFile) {
      const [_, fileErr] = await handle(ServicesApi.putSetHeaderService(serviceRes.id, compressedFile))
      if (fileErr) {
        setBodyModal({
          title: t('common.errorImage', 'Ошибка загрузки изображения'),
          body: fileErr.message
        });
        onChangeFormState('loadFile', null)
        setIsShowModal(true);
        setIsPending(false)
        if (isCreate) await handle(ServicesApi.deleteService(serviceRes.id))
        return
      }
    }
    if (callback) callback()
    if (isCreate) {
      onResetFormState()
      reset();
    }
    setBodyModal({
      title: isCreate ? t('serviceForm.create.title', 'Услуга создана') : t('serviceForm.update.title', 'Услуга обновлена'),
      body: isCreate
        ? t('serviceForm.create.text', 'Ваша услуга находится на проверке. После согласования мы направим вам уведомление.')
        : t('serviceForm.update.text', 'Можете ее посмотреть у себя в профиле'),
    });
    setIsShowModal(true);
    setIsPending(false)
  }, [selectedUserCompany, formState.data, isUnlimited, currency, isNoDate, isCreate, initialData, callback, t, reset]);

  const options = useMemo(() => {
    return currencyList.map((item) => ({
      item: t(`currencies.${item.value}.title`, item.item),
      value: item.value
    }))
  }, [t])
  return (
    <>
      <FormStyled onSubmit={handleSubmit(onSubmit)}>
        <InpuRow>
          <Text variant={ETextVariants.Body16}>
            <T keyName="serviceForm.fields.name.label">Название услуги</T>
          </Text>
          <InputStyled
            placeholder={t('serviceForm.fields.name.placeholder', "Введите название услуги")}
            wth="100%"
            {...register('title', {
              required: true,
              pattern: /[a-zA-Zа-яА-Я0-9 ]{3,50}/,
            })}
            aria-invalid={errors.title ? 'true' : 'false'}
          />
          {errors.title &&
              <ErrorForm variant={ETextVariants.Error}>
                  <T keyName="serviceForm.error.title">Название должно быть не меньше трех символов</T>
              </ErrorForm>}
        </InpuRow>
        <InpuRow ref={refDnD}>
          <FileUploader
            handleChange={(file: File) => handleChange(file)}
            name="file"
            types={fileTypes}
            onTypeError={(err: string) => onChangeFormError(err)}
            maxSize="10"
            onSizeError={(file: string) => onChangeFormError(file)}
          >
            <UploadStyled>
              {formState.data.loadFile ?
                <ImagePreview src={URL.createObjectURL(formState.data.loadFile)}/>
                : initialData?.header ?
                  <>
                    <ImagePreview src={initialData.header ? `${mediaUrl}file/${initialData.header.id}` : ''}/>
                    <DeleteHeader>
                      <TrashIcon/>
                    </DeleteHeader>
                  </>
                  : <>
                    <AddDNDIcon/>
                    <UploadText
                      variant={ETextVariants.Body16}
                    >
                      <T keyName="serviceForm.fields.cover.title">Добавить обложку</T>
                    </UploadText>
                  </>}
            </UploadStyled>
          </FileUploader>
          {formState.error === 'File type is not supported' && (
            <ErrorForm variant={ETextVariants.Error}>
              <T keyName="serviceForm.fields.cover.errors.typeIsNotSupported">Такой тип файла не подходит, допустимые
                файлы формата JPEG,PNG!</T>
            </ErrorForm>
          )}
          {formState.error === 'File size is too big' && (
            <ErrorForm variant={ETextVariants.Error}>
              <T keyName="serviceForm.fields.cover.errors.sizeIsToBig">Большой размер файла, размер не должен превышать
                10МБ!</T>
            </ErrorForm>
          )}
          {formState.error === 'null' &&
              <ErrorForm variant={ETextVariants.Error}>
                  <T keyName="serviceForm.fields.cover.errors.null">Добавьте обложку!</T>
              </ErrorForm>}
          <FileTitle variant={ETextVariants.Body16}>
            {
              formState.data.loadFile
                ? <T keyName="serviceForm.fields.cover.value"
                     defaultValue={`Имя обложки: ${formState.data.loadFile.name}`}
                     params={{name: formState.data.loadFile.name}}/>
                : ''
            }
          </FileTitle>
        </InpuRow>
        <InpuRow>
          <Text variant={ETextVariants.Body16} ref={textAreaRef}>
            <T keyName="serviceForm.fields.description.label">Описание услуги</T>
          </Text>
          <TAMini variant={ETextVariants.MiniGray}>
            {formState.data.textArea.length}/2000
          </TAMini>
          <TextAreaStyled
            onChange={(e) => onChangeFormState('textArea', e.target.value.substring(0, 2000))}
            minRows={2}
            value={formState.data.textArea}
            placeholder={t('serviceForm.fields.description.placeholder', 'Описание услуги')}
          />
          {formState.error === t('serviceForm.errors.description', 'Введите описание услуги!') &&
              <ErrorForm variant={ETextVariants.Error}>{formState.error}</ErrorForm>}
        </InpuRow>
        <InpuRow>
          <Text variant={ETextVariants.Body16}>
            <T keyName="serviceForm.fields.maxNumbers.label">Количество людей</T>
          </Text>
          {!isUnlimited && <> <InputStyled
              placeholder={t('serviceForm.fields.maxNumbers.placeholder', '13')}
              wth="100%"
              type="number"
              {...register('maxCustomers', {required: true, valueAsNumber: true, min: 1})}
          />
            {formState.error === t('serviceForm.errors.max', 'Количество участвующих не должно быть больше 999!') &&
                <ErrorForm variant={ETextVariants.Error}>{formState.error}</ErrorForm>}
            {errors.maxCustomers?.type === 'min' &&
                <ErrorForm variant={ETextVariants.Error}>
                    <T keyName="serviceForm.errors.min">Количество участвующих не должно быть меньше 1!</T>
                </ErrorForm>}
          </>
          }
          <Checkbox label={t('serviceForm.fields.unlimited.label', 'Неограниченное количество участников')}
                    checked={isUnlimited} disabled={!isCreate}
                    onChange={event => setIsUnlimited(event.target.checked)} id="unlimited"/>
        </InpuRow>

        <InpuRow>
          <Text variant={ETextVariants.Body16}>
            <T keyName="serviceForm.fields.date.label">Дата и время услуги</T>
          </Text>
          {!isNoDate && <DataPicker
              startDate={formState.data.startDate}
              bg={true}
              typeArrowPurple={true} isClearButton={false}
              mobileVersion={false}
              setStartDate={(date: any) => onChangeFormState('startDate', date)}
          />
          }

          <Checkbox label={t('serviceForm.fields.nodate.label', 'Услуга не ограничена по времени')} checked={isNoDate}
                    disabled={!isCreate}
                    onChange={event => setIsNoDate(event.target.checked)} id="nodate"/>
        </InpuRow>

        <InpuRow>
          <Dropdown label={t('common.currency', 'Валюта')} placeholder={t('common.currency', 'Валюта')}
                    options={options} value={currency}
                    onSelect={(val) => setCurrency(String(val))} isDisabled={!isCreate}/>
          {formState.error === t('serviceForm.errors.currency', 'Выбериту валюту!') && (
            <ErrorForm variant={ETextVariants.Error}>{formState.error}</ErrorForm>
          )}
        </InpuRow>
        <InpuRow>
          <Input
            placeholder={t('serviceForm.fields.price.placeholder', 'Введите стоимость')}
            wth={'100%'}
            type="number"
            label={t('serviceForm.fields.price.label', 'Стоимость')}
            {...register('price', {
              valueAsNumber: true,
              required: true, min: 1
            })}
          />
          {errors.price?.type === 'min' &&
              <ErrorForm variant={ETextVariants.Error}>
                  <T keyName="serviceForm.error.price">Цена не может быть меньше 1</T>
              </ErrorForm>}
        </InpuRow>

        <InpuRow>
          <Checkbox
            label={t('common.allowCashPayment', 'Оплата на месте')}
            {...register('allowCashPayment')}
            id={'allowCashPayment'}
          />
        </InpuRow>
        <InpuRow>
          <Text variant={ETextVariants.Body16}>
            <T keyName="serviceForm.fields.location.label">Выберите место на карте</T>
          </Text>
          <HomeMapStyled
            createEvent
            position={formState.data.position}
            center={[55.751244, 37.618423]}
            setPosition={val => onChangeFormState('position', val)}
            setAddress={val => onChangeFormState('addressMap', val)}
          />
          {formState.error === t('serviceForm.errors.location', 'Выберите место на карте!') &&
              <ErrorForm variant={ETextVariants.Error}>{formState.error}</ErrorForm>}
        </InpuRow>
        <InpuRow>
          <Text variant={ETextVariants.Body16}>
            <T keyName="serviceForm.fields.location.valueLabel">Место проведения</T>

          </Text>
          <InputStyled
            placeholder={t('serviceForm.fields.location.placeholder', 'Например, Тверская улица, дом 15, кв 43')}
            wth="100%"
            ref={addressRef}
            disabled={!formState.data.addressMap.detail}
          />
        </InpuRow>

        {formState.error === t('serviceForm.errors.company', 'Необходмо выбрать компанию в настройке профиля') &&
            <ErrorForm variant={ETextVariants.Error}>{formState.error}</ErrorForm>}
        <Button disabled={!selectedUserCompany || isPending} variant={EButtonVariants.Primary} $fullWidth type="submit">
          {
            isPending
              ? <T keyName="common.pending">Ожидание</T>
              : !isCreate
                ? <T keyName="common.update">Обновить</T>
                : <T keyName="common.create">Создать</T>
          }
        </Button>
      </FormStyled>
      {isShowModal &&
          <CreateEventModal isModalClose={() => setIsShowModal(false)} title={bodyModal.title} body={bodyModal.body}/>}
    </>
  );
}

export default FormServiceView;
