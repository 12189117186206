import {AnchorHTMLAttributes, ButtonHTMLAttributes} from "react";

export enum EButtonVariants {
    Primary,
    Secondary,
    Success,
    Error,
}


type ButtonTypes = ButtonHTMLAttributes<HTMLButtonElement> | AnchorHTMLAttributes<HTMLAnchorElement> | any;

export type ButtonProps = {
    variant: EButtonVariants;
    $fullWidth?: boolean;
    as?: "a" | "button";
    href?: string;
    external?: boolean;
    isLoading?: boolean;
    disabled?: boolean;
} & ButtonTypes;

export type ButtonThemeVariant = {
    backgroundColor: string,
    border: string,
    color: string,
    hover: {
        backgroundColor: string,
        color: string,
    }
    active: {
        backgroundColor: string,
        color: string,
    }
    disabled: {
        backgroundColor: string
        color: string
    }
};

export type ButtonTheme = {
    [key in EButtonVariants]: ButtonThemeVariant;
};
