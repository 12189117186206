import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {IAdminPaymentsFilter, IPaymentsAll} from "./types";
import {PurchaseApi} from "../../api/purchase";

const fetchAllPayments = createAsyncThunk<IPaymentsAll[],
  void,
  {
    state: RootState
  }>(
  'adminPayments/fetchAllPayments',
  async (_, ThunkApi) => {
    const authState = ThunkApi.getState().auth
    const {filter} = ThunkApi.getState().adminPayments
    const filterData: any = {}
    for (const filterKey in filter) {
      const field = filterKey as keyof IAdminPaymentsFilter
      if (filter[field] !== undefined && filter[field]) {
        if (field === 'dateTo' || field === 'dateFrom') {

          const date = new Date(String(filter[field]))
          date.setDate(date.getDate() + 1)
          if (field === 'dateTo') {
            date.setUTCHours(23,59,59,999)
          } else {
            date.setUTCHours(0,0,0,0)
          }
          filterData[field] = date
        } else {
          filterData[field] = filter[field]
        }
      }
    }

    if (!filterData.country === undefined && filterData.city) {
      delete filterData.country
      delete filterData.city
    }

    if (authState.auth) {
      const [paymentsRes, paymentsErr] = await handle(PurchaseApi.getAllPayments(filterData))
      if (paymentsRes) {
        return paymentsRes
      }
      if (paymentsErr) {
        throw {message: paymentsErr.message[0] || paymentsErr.message}
      }
    }
    throw {message: 'No user state'}
  }
)

export default fetchAllPayments
