import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} >
          <path d="M11.3333 5.99935L7.33333 9.99935M7.33333 5.99935L11.3333 9.99935M1.81333 8.63935L4.69333 12.4793C4.928 12.7922 5.04533 12.9487 5.19403 13.0615C5.32574 13.1614 5.47488 13.236 5.63385 13.2814C5.81334 13.3327 6.00889 13.3327 6.4 13.3327H11.4667C12.5868 13.3327 13.1468 13.3327 13.5746 13.1147C13.951 12.9229 14.2569 12.617 14.4487 12.2407C14.6667 11.8128 14.6667 11.2528 14.6667 10.1327V5.86602C14.6667 4.74591 14.6667 4.18586 14.4487 3.75803C14.2569 3.38171 13.951 3.07575 13.5746 2.884C13.1468 2.66602 12.5868 2.66602 11.4667 2.66602H6.4C6.00889 2.66602 5.81334 2.66602 5.63385 2.71728C5.47488 2.76268 5.32574 2.83725 5.19403 2.93718C5.04533 3.05002 4.928 3.20646 4.69333 3.51935L1.81333 7.35935C1.6412 7.58885 1.55514 7.70361 1.52196 7.82964C1.49268 7.94088 1.49268 8.05781 1.52196 8.16906C1.55514 8.29509 1.6412 8.40984 1.81333 8.63935Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </Svg>
    );

export default Icon;
