import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {ProfileSettingsPayload} from "./types";
import {UserEntity} from "../../types";
import {ProfileApi} from "../../api/profile";
import {handle} from "../../api";

// @ts-ignore
const fetchUpdateProfile = createAsyncThunk<{profile: UserEntity, error: string | null } | RejectWithValue<any, any>,
  ProfileSettingsPayload,
  {
    state: RootState
  }>(
  'profile/fetchUpdateProfile',
  async (data) => {

    if (data.acquaintancePointer) {
      data.acquaintancePointer = (data.acquaintancePointer / 1000)
    }

    let error: string | null = null

    const [profileRes, profileErr] = await handle(ProfileApi.putUpdateProfile(data))
    let newProfileData = profileRes
    if (profileRes) {
      if (data.avatar) {
        const [avatarRes, avatarErr] = await handle(ProfileApi.putSetAvatar(data.avatar))
        if (avatarRes) newProfileData = avatarRes
        if (avatarErr) {
          error = `Failed: ${avatarErr.message}`
        }
      }
      if (data.district && data.city && data.country) {
        const [addressRes, addressErr] = await handle(ProfileApi.putSetAddress({
          country: data.country,
          district: data.district,
          city: data.city,
        }))
        if (addressRes) newProfileData = addressRes
        if (addressErr) console.log(addressErr)
      }
      return {profile: newProfileData, error}
    }
    if (profileErr) {
      throw profileErr
    }
  }
)

export default fetchUpdateProfile
