import {createSlice, current, PayloadAction} from "@reduxjs/toolkit";
import {UserEntity} from "../../types";
import fetchNextSuggestion from "./fetchNextSuggestion";
import {ILike} from "./types";
import fetchUserMatches from "./fetchUserMatches";
import fetchUserSympathies from "./fetchUserSympathies";
import fetchLikes from "./fetchLikes";
import fetchPostLike from "./fetchPostLike";
import fetchDeleteUnLike from "./fetchDeleteUnLike";
import fetchSuggestions from "./fetchSuggestions";

export interface AcquaintanceState {
  suggestion: UserEntity | null,
  suggestions: UserEntity[],
  likes: ILike[],
  sympathies: UserEntity[]
  matches: UserEntity[],
  isLoading: boolean
}

const initialState: AcquaintanceState = {
  suggestion: null,
  suggestions: [],
  isLoading: true,
  likes: [],
  sympathies: [],
  matches:[]
}

export const acquaintanceSlice = createSlice({
  name: 'acquaintance',
  initialState,
  reducers: {
    setSuggestion: (state, action: PayloadAction<UserEntity>) => {
      state.suggestion = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchPostLike.pending, (state) => {
      state.isLoading = true
    })

    builder.addCase(fetchPostLike.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(fetchDeleteUnLike.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchDeleteUnLike.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(fetchNextSuggestion.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchNextSuggestion.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(fetchNextSuggestion.fulfilled, (state, action: PayloadAction<UserEntity>) => {
      const {suggestions} = current(state)
      if (suggestions.length > 0) {
        state.suggestion = suggestions[0] || null
        const data = suggestions.slice(1)
        state.suggestions = [...data, action.payload]
      } else {
        state.suggestion = action.payload
      }
      state.isLoading = false
    })
    builder.addCase(fetchSuggestions.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchSuggestions.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(fetchSuggestions.fulfilled, (state, action: PayloadAction<UserEntity[]>) => {
      state.suggestions = action.payload.slice(1)
      state.suggestion = action.payload[0]
      state.isLoading = false
    })
    builder.addCase(fetchUserSympathies.fulfilled, (state, action: PayloadAction<UserEntity[]>) => {
      state.sympathies = action.payload
    })
    builder.addCase(fetchLikes.fulfilled, (state, action: PayloadAction<ILike[]>) => {
      state.likes = action.payload
    })
    builder.addCase(fetchUserMatches.fulfilled, (state, action: PayloadAction<UserEntity[]>) => {
      state.matches = action.payload
    })
  }
})

export const {setSuggestion} = acquaintanceSlice.actions
export default acquaintanceSlice.reducer
