import {useDispatch, useSelector} from "react-redux";
import {useCallback, useEffect, useMemo} from "react";
import {AppDispatch, RootState} from "../index";
import fetchCreateCompany from "./fetchCreateCompany";
import {CreateCompanyPayload, UpdateCompanyPayload} from "./types";
import {clearSelectedCompany, clearSelectedUserCompany, selectUserCompany} from "./companySlice";
import fetchUserCompanies from "./fetchUserCompanies";
import fetchUpdateCompany from "./fetchUpdateCompany";
import fetchCompanyById from "./fetchCompanyById";
import fetchSubscribers from "./fetchSubscribers";
import fetchSessionCompany from "./fetchSessionCompany";
import {useProfileAction} from "../profile/hooks";

export const getCompany = (state: RootState) => state.company

export function useCompanyAction() {
  const dispatch = useDispatch<AppDispatch>()
  const {onFetchProfile} = useProfileAction()
  const onSelectUserCompany = useCallback((data:number) => {
    dispatch(selectUserCompany(data))
  }, [dispatch])
  const onFetchSessionCompany = useCallback((id:number) => {
    dispatch(fetchSessionCompany(id))
      .unwrap()
      .then(() => {
        onSelectUserCompany(id)
        onFetchProfile()
      })
  }, [dispatch, onFetchProfile, onSelectUserCompany])
  const onFetchSubscribers = useCallback((data:number) => {
    dispatch(fetchSubscribers(data))
  }, [dispatch])
  const onClearSelectedUserCompany = useCallback(() => {
    dispatch(fetchSessionCompany(0))
      .unwrap()
      .then(() => {
        dispatch(clearSelectedUserCompany())
        onFetchProfile()
      })
  }, [dispatch, onFetchProfile])
  const onClearSelectedCompany = useCallback(() => {
    dispatch(clearSelectedCompany())
  }, [dispatch])
  const onFetchUserCompanies = useCallback(() => {
    dispatch(fetchUserCompanies())
  }, [dispatch])
  const onFetchCompanyById = useCallback((id: number) => {
    dispatch(fetchCompanyById(id))
  }, [dispatch])
  const onCreateCompany = useCallback((data:CreateCompanyPayload) => {
    dispatch(fetchCreateCompany(data))
      .unwrap()
      .then(onFetchUserCompanies).catch((error) => console.log(error))

  }, [dispatch, onFetchUserCompanies])
  const onUpdateCompany = useCallback((data:UpdateCompanyPayload) => {
    dispatch(fetchUpdateCompany(data))
      .unwrap()
      .then(onFetchUserCompanies).catch((error) => console.log(error))

  }, [dispatch, onFetchUserCompanies])
  return useMemo(() => ({
    onCreateCompany,
    onFetchUserCompanies,
    onSelectUserCompany,
    onClearSelectedUserCompany,
    onClearSelectedCompany,
    onUpdateCompany,
    onFetchCompanyById,
    onFetchSubscribers,
    onFetchSessionCompany,
  }), [
    onCreateCompany,
    onFetchUserCompanies,
    onSelectUserCompany,
    onClearSelectedUserCompany,
    onClearSelectedCompany,
    onUpdateCompany,
    onFetchCompanyById,
    onFetchSubscribers,
    onFetchSessionCompany,
  ])
}

export function useCompanyState() {
  return useSelector(getCompany)
}

export function useUserSelectedCompany() {
  const {selectedUserCompany, userCompanies} = useCompanyState()
  const {onSelectUserCompany} = useCompanyAction()
  useEffect(() => {
    if (userCompanies.length > 0 && selectedUserCompany) {
      onSelectUserCompany(selectedUserCompany.id)
    }
  }, [userCompanies])
}
