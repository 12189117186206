import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import styled from "styled-components";
import {ArrowLeftIcon, CloseIcon, SearchIcon, SpinnerIcon} from "../../UI/Svg";
import Input from "../../UI/Input";
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {useProfileAction, useProfileState} from "../../store/profile/hooks";
import ListItemEvent from "../HomeComponents/ListItemEvent";
import {handle} from "../../api";
import {EventsApi} from "../../api/events";
import {ProfileContext} from "../../contexts/ProfileContext";
import {T, useTranslate} from "@tolgee/react";

interface InviteModalProps {
  onClose: () => void;
}

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  padding-top: 60px;
  z-index: 10;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    content: '';
    position: absolute;
    background: ${({theme}) => theme.colors.transparentGray};
    width: 100%;
    height: 100%;
    padding-bottom: 100%;
    top: 0;
  }

  ${({theme}) => theme.mediaQueries.md} {
    position: absolute;
    border-radius: 0;
    width: 100%;
    height: 100%;
    padding-top: 0;
    margin-bottom: 71px;
  }
`

const Modal = styled.div`
  min-height: 300px;
  max-width: 968px;
  height: 84vh;
  width: 100%;
  overflow: hidden;
  top: 0;
  right: 0;
  background: ${({theme}) => theme.colors.bg};
  z-index: 2;
  border-radius: 10px;

  &::-webkit-scrollbar {
    width: 0;
  }

  ${({theme}) => theme.mediaQueries.lg} {
    width: 770px;
  }

  ${({theme}) => theme.mediaQueries.md} {
    border-radius: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    overflow: scroll;

  }
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 32px 18px 70px;
  width: 100%;
  border-bottom: 1px solid ${({theme}) => theme.colors.darkWhite};
  position: relative;
  gap: 20px;
  background: ${({theme}) => theme.colors.white};

  ${({theme}) => theme.mediaQueries.md} {
    justify-content: center;
    padding: 8px 32px 10px;
    border-bottom: none;
  }
`

const InputBlock = styled.div`
  display: flex;

  ${({theme}) => theme.mediaQueries.md} {
    display: none;
  }
`
const InputStyled = styled(Input)`
  height: 40px;

`

const CloseButton = styled.div`
  width: 20px;
  height: 20px;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    width: 24px;

    &:first-child {

      ${({theme}) => theme.mediaQueries.md} {
        display: none;
      }
    }

    &:nth-child(2) {

      display: none;

      ${({theme}) => theme.mediaQueries.md} {
        display: block;
      }
    }
  }

  ${({theme}) => theme.mediaQueries.md} {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 16px;
  }
`

const LoadingBlock = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 44px;
    height: 44px;
  }
`

const Content = styled.div`
  margin-top: 32px;
  display: flex;
  gap: 20px;
  width: 100%;
  padding: 0 16px 60px;

  ${({theme}) => theme.mediaQueries.md} {
    margin-top: 10px;
    padding-bottom: 0;
    flex-direction: column;
  }
`

function InviteModal({onClose}: InviteModalProps) {
  const {t} = useTranslate()
  const {events, isLoadingEvents} = useProfileState()
  const {profile} = useContext(ProfileContext)
  const {onFetchMyEventsCreated} = useProfileAction()
  const [activeInputSearch, setActiveInputSearch] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const filteredEvents = useMemo(() => {
    return events?.filter((item) => {
      return item.title.toLowerCase().includes(search.toLowerCase())
    });
  }, [search, events]);

  useEffect(() => {
    onFetchMyEventsCreated()
  }, [onFetchMyEventsCreated])

  const onInvite = useCallback(async (id: string) => {
    if (profile) {
      const [_, inviteErr] = await handle(EventsApi.postInviteEvent(id, profile.id))
      if (inviteErr) console.log(inviteErr);
    }
    onClose()
  }, [onClose, profile])
  return (
    <Wrapper>
      <Modal>
        <Header>
          <Text variant={ETextVariants.Heading}>
            <T keyName="inviteModal.title">Выберите ивент</T>
          </Text>
          <InputBlock>
            <InputStyled
              placeholder={t("common.search","Поиск")}
              wth="34.3rem"
              onClick={() => setActiveInputSearch(true)}
              onChange={(e) => setSearch(e.target.value)}
              icon={<SearchIcon/>}
            />
          </InputBlock>
          <CloseButton onClick={onClose}>
            <CloseIcon/>
            <ArrowLeftIcon/>
          </CloseButton>
        </Header>
        <Content>
          {isLoadingEvents
            ? <LoadingBlock>
              <SpinnerIcon/>
            </LoadingBlock>
            : <>
              {filteredEvents.map((item) => <ListItemEvent key={item.id} invite={onInvite} item={item}/>)}
            </>
          }
        </Content>
      </Modal>
    </Wrapper>
  );
}

export default InviteModal;
