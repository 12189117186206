import React, {useMemo} from 'react';
import {ENotificationType, INotification} from "../../store/notifications/types";
import styled from "styled-components";
import {Link} from "react-router-dom";
import dayjs from "dayjs";
import {getUserName} from "../../utils/user";
import {useAppAction} from "../../store/app/hooks";
import {T} from "@tolgee/react";

const Wrapper = styled.div`
  width: 100%;
  padding: 16px;
  border-radius: 10px;
  background: ${({theme}) => theme.colors.white};
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.12);
  position: relative;
`

const TextStyled = styled.div`
  color: #454545;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.3px;
  width: 100%;
  text-align: left;
  display: inline-block;
  vertical-align: sub;
`

const LinkStyled = styled(Link)`
  color: ${({theme}) => theme.colors.mainPurple};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  vertical-align: baseline;
  letter-spacing: 0.3px;
`

const ButtonCart = styled.div`
  color: ${({theme}) => theme.colors.mainPurple};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  vertical-align: baseline;
  cursor: pointer;
`

const TimeText = styled.div`
  color: rgba(0, 0, 0, 0.60);
  text-align: right;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.24px;
  position: absolute;
  bottom: 8px;
  right: 8px;
`

function NotificationItem({notification}: { notification: INotification }) {
  const {onToggleCart} = useAppAction()
  const text = useMemo(() => {
    switch (notification.type) {
      case ENotificationType.AcquaintanceLike:
        const arr = notification.text.split(getUserName(notification.connectedUser))
        return <>{arr[0]} <LinkStyled to={`/profile/${notification.connectedUser.id}`}>{getUserName(notification.connectedUser)}</LinkStyled></>
      case ENotificationType.Text:
        return notification.text
      case ENotificationType.EventApproval:
        const arrEvent = notification.text.split(notification.event.title)
        return <>{arrEvent[0]} <LinkStyled to={`/about-the-event/${notification.event.id}`}>{notification.event.title}</LinkStyled>{arrEvent[1]}</>
      case ENotificationType.EventInvitation:
        return notification.text
      case ENotificationType.FavorBuying:
        const arrBuying = notification.text.split(notification.connectedUser.login)
        if (notification.favor) {
          const arrFavor = arrBuying[1].split(notification.favor.title)
          return <>{arrBuying[0]} <LinkStyled
            to={`/profile/${notification.connectedUser.id}`}>{getUserName(notification.connectedUser)}</LinkStyled>
            {arrFavor[0]}
            <LinkStyled
              to={`/service/${notification.favor.id}`}>{notification.favor.title}</LinkStyled>
            {arrFavor[1]}
          </>
        }
        return <>{arrBuying[0]} <LinkStyled
          to={`/profile/${notification.connectedUser.id}`}>{getUserName(notification.connectedUser)}</LinkStyled>{arrBuying[1]}</>
      case ENotificationType.FavorGift:
        const arrGift = notification.text.split(notification.connectedUser.login)
        if (notification.favor) {
          const arrFavor = arrGift[1].split(notification.favor.title)
          return <>{arrGift[0]} <LinkStyled
            to={`/profile/${notification.connectedUser.id}`}>{getUserName(notification.connectedUser)}</LinkStyled>
            {arrFavor[0]}
            <LinkStyled
              to={`/service/${notification.favor.id}`}>{notification.favor.title}</LinkStyled>
            {arrFavor[1]}
            <ButtonCart onClick={onToggleCart}>
              <T keyName={'common.openCart'}>Открыть корзину</T>
            </ButtonCart>
          </>
        }
        return <>{arrGift[0]} <LinkStyled
          to={`/profile/${notification.connectedUser.id}`}>{getUserName(notification.connectedUser)}</LinkStyled>{arrGift[1]}</>
      default:
        return notification.text
    }
  }, [notification.connectedUser, notification.favor, notification.text, notification.type, notification.event])

  return (
    <Wrapper>
      <TextStyled>
        {text}
      </TextStyled>
      <TimeText>
        {dayjs(notification.date).format('HH:mm')}
      </TimeText>
    </Wrapper>
  );
}

export default NotificationItem;
