import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} >
          <rect width="24" height="24" rx="12" fill="#EDEDED"/>
          <path d="M17.25 10.8337H6.75M14.3333 6.16699V8.50033M9.66667 6.16699V8.50033M9.55 17.8337H14.45C15.4301 17.8337 15.9201 17.8337 16.2945 17.6429C16.6238 17.4751 16.8915 17.2074 17.0593 16.8781C17.25 16.5038 17.25 16.0138 17.25 15.0337V10.1337C17.25 9.15357 17.25 8.66352 17.0593 8.28918C16.8915 7.95989 16.6238 7.69218 16.2945 7.5244C15.9201 7.33366 15.4301 7.33366 14.45 7.33366H9.55C8.56991 7.33366 8.07986 7.33366 7.70552 7.5244C7.37623 7.69218 7.10852 7.95989 6.94074 8.28918C6.75 8.66352 6.75 9.15357 6.75 10.1337V15.0337C6.75 16.0138 6.75 16.5038 6.94074 16.8781C7.10852 17.2074 7.37623 17.4751 7.70552 17.6429C8.07986 17.8337 8.56991 17.8337 9.55 17.8337Z" stroke="#BCBCBC" strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round"/>
        </Svg>
    );

export default Icon;
