import React, {useEffect, useMemo, useState} from 'react';
import Text from "../../UI/Text";
import {ETextVariants} from "../../UI/Text/types";
import {SearchIcon} from "../../UI/Svg";
import {useAdminForbiddenWordsAction, useAdminForbiddenWordsState} from "../../store/adminForbiddenWords/hooks";
import styled from "styled-components";
import Select from "../../UI/Select";
import {DataPickerRange} from "../../UI/DataPickerRange";
import Input from "../../UI/Input";
import {T, useTranslate} from "@tolgee/react";

const Wrapper = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
`

const Filters = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  position: relative;
`

const SelectStyled = styled(Select)`
  width: 186px;
  padding: 11px 16px;
  background-position: 90%;
`

const DataPickerRangeStyled = styled(DataPickerRange)`
  min-width: 178px;
`

const InputStyled = styled(Input)`
  background: ${({theme}) => theme.colors.white};
`


function ForbiddenFilterView() {
  const {t} = useTranslate()
  const {filter} = useAdminForbiddenWordsState()
  const {onChangeFilter} = useAdminForbiddenWordsAction()
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const [startDate, endDate] = useMemo(() => dateRange, [dateRange]);

  useEffect(() => {
    if (startDate) {
      onChangeFilter({dateFrom: startDate.toString()})
    } else {
      onChangeFilter({dateFrom: undefined})
    }
    if (endDate) {
      onChangeFilter({dateTo: endDate.toString()})
    } else {
      onChangeFilter({dateTo: undefined})
    }
  }, [startDate, endDate, onChangeFilter])

  return (
    <Wrapper>
      <Text variant={ETextVariants.Body16}><T keyName="admin.filter">Фильтр:</T></Text>
      <Filters>
        <DataPickerRangeStyled
          setDateRange={setDateRange}
          startDate={startDate}
          endDate={endDate}
          bg
          isClearButton={false}
          typeArrowPurple={false}
          mobileVersion={false}
        />
        <InputStyled
          placeholder={t('common.search', "Поиск")}
          icon={<SearchIcon/>}
          value={filter.search}
          onChange={(event) => onChangeFilter({search: event.target.value})}
        />
      </Filters>
    </Wrapper>
  )
}

export default ForbiddenFilterView;
